import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import ViewFarmSiteManagementComponent from "../FarmSiteManagement/FarmSiteManagementComponent";
import ViewSiteManagementComponent from "../SiteManagement/SiteManagementComponent";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
export default function CompactSiteManagement(props) {
  const [viewAllSites, setViewAllSites] = useState(true);
  const [viewFarmSites, setViewFarmSites] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [mainSitesData,setMainSitesData] = useState([]);
  const [farmSitesData, setFarmSitesData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [loadingSites, setLoadingSites] = useState(true);

  useEffect(() => {
    getAllSitesData();
  }, []);
  const getAllSitesData = ()=>{
    MainService.viewAllSites(parentId, username)
    .then((res) => {
      setSitesData(res.sites);
      setMainSitesData(res.sites);
      setLoadingSites(false);
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  }
  
function deletSiteSubmitClcik (selectedSiteId, selectedSiteName, username){
 // console.log("childData:",childData);
  MainService.siteDeleteHandler(selectedSiteId, selectedSiteName, username)
  .then((res) => {
    //getAllSitesData();
    const filterData = sitesData.filter((item) => item.siteid !== selectedSiteId )
    setSitesData(filterData)
    
    return NotificationManager.success("Successfully Deleted");
    
  })
  .catch((err) => {
    return NotificationManager.error(err);
  });
}
  
function requestSearch(searchedVal){
  console.log("searched val:",searchedVal)
  const filteredRows = sitesData.filter((row) => {
    return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
  });
  setSitesData(filteredRows);
}

function requestFarmGridSearch(searchedVal){
  console.log("searched val:",searchedVal)
  if(searchedVal !== "")
  {
    const filteredRows = sitesData.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSitesData(filteredRows);
  }else{
    if(viewAllSites === true)
    {
      setSitesData(mainSitesData);
    }else{
      setSitesData(farmSitesData);
    }
    
  }
  
  
}


  const viewAllSitesClick = () => {
    setViewAllSites(true);
    setViewFarmSites(false);
    getAllSitesData();
  };
  const viewFarmManagementClick = () => {
    setViewAllSites(false);
    setViewFarmSites(true);
    MainService.viewAllFarms(username)
      .then((res) => {
        setSitesData(res.sites);
        setFarmSitesData(res.sites);
        setLoadingSites(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <h2>Site Management</h2>
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewAllSitesClick();
              }}
            >
              All Sites
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewFarmManagementClick();
              }}
            >
              Farm SiteManagement
            </Button>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "20px" }}>
        {<ViewSiteManagementComponent
         sites={sitesData} 
         deletSiteSubmitClcik={deletSiteSubmitClcik}
         requestSearch={requestSearch}
         requestFarmGridSearch={requestFarmGridSearch}
         loadingSites = {loadingSites}/>}
        
        {/* {viewAllSites && <ViewSiteManagementComponent sites={sitesData} />} */}

        {/* {viewFarmSites && <ViewFarmSiteManagementComponent />} */}
      </div>
    </>
  );
}
