import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import MainService from "../../Common/MainService";

import "./AttendanceComponent.css";



const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 250,
    zIndex: 99,
    float: "right",
  },
  formControlLabel: {
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgb(43, 144, 99)",
    marginTop: "-10px",
    marginLeft: "-10px",
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));



export default function AttendanceComponent() {

  
 
  
  
  useEffect(() => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
  return (
    <div>
      <h1>operator</h1>
    </div>
  );
}
