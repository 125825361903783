import React, { useEffect } from 'react';
import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import './booking.css';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import MainService from '../../Common/MainService';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { blue } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import { event } from 'react-ga';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: '#fff',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ViewOrders = () => {
  // console.log(props,"props.........///////////")
  const [bookingDetailsList, setBookingDetailsList] = useState([]);
  const [bookingCardDetails, setBookingCardDetails] = useState({});
  const [tripMenuList, setTripMenuList] = useState([]);
  const [driverMenuList, setDriverMenuList] = useState([]);
  const [tripdriverDatamapping, setTripdriverDatamapping] = useState({
    triplist: '',
    driverlist: '',
  });
  const [successAlret, setSuccessAlret] = useState(false);
  const [mapErrorAlret, setMapErrorAlret] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const {
    user: { orgid, orderId, parentId, username },
  } = JSON.parse(atob(localStorage.getItem('CBN')));

  const getOrderListInfo = async () => {
    // const data = await MainService.getOrderListInfo(username);
    const response = await MainService.getAllBookingDetailsApi(
      orgid,
      parentId,
      username
    );
    console.log('data******************************--------', response);
    setBookingDetailsList(response.data.bookings);
  };

  const fetchTripList = async () => {
    let urls = 'fromDate=01-01-2022&toDate=29-12-2090&start=0&limit=100';
    const { tripList } = await MainService.getTripInfo(
      parentId,
      username,
      urls
    );

    setTripMenuList(tripList);
  };

  const fetchDriverList = async () => {
    const { users } = await MainService.getDrivers(username);
    setDriverMenuList(users);
    console.log(users);
  };

  useEffect(() => {
    getOrderListInfo();
    fetchTripList();
    fetchDriverList();
  }, []);

  const sitedriverMapHandler = async (orderid, bookingid, name) => {
    console.log('click', bookingid, name);
    setBookingCardDetails({
      bookingId: bookingid,
      orderId: orderid,
      bookingTypeName: name,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTripdriverDatamapping({
      triplist: '',
      driverlist: '',
    });
    setMapErrorAlret(false);
  };

  // model menu onchange handler
  const mapListChangeHandler = (name, value) => {
    setTripdriverDatamapping((prev) => ({
      ...prev,
      [name]: value,
    }));
    // set
  };

  // trip and driver map handler
  const tripdriverMapHandler = async (buttonType) => {
    console.log('bookingCardDetails', tripdriverDatamapping);
    const { bookingId, orderId } = bookingCardDetails;
    const { triplist, driverlist } = tripdriverDatamapping;
    if (orderId === undefined) {
      setMapErrorAlret(true);
      return;
    }
    if (buttonType === 'Map') {
      let { tripResponse, driverResponse } = '';
      if (triplist) {
        tripResponse = await MainService.postTripMapRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          triplist
        );
      }
      if (driverlist) {
        driverResponse = await MainService.postDriverMapRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          driverlist
        );
      }
      if (tripResponse?.status === 200 || driverResponse?.status === 200) {
        setSuccessAlret(true);
        setTripdriverDatamapping({
          triplist: '',
          driverlist: '',
        });
      }
      console.log('tripResponse, driverResponse', tripResponse, driverResponse);
    } else {
      let { tripUnmapResponse, driverUnmapResponse } = '';
      if (triplist) {
        tripUnmapResponse = await MainService.postTripUnmappingRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          triplist
        );
      }
      if (driverlist) {
        driverUnmapResponse = await MainService.postDriverUnmappingRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          driverlist
        );
      }
      if (
        tripUnmapResponse?.status === 200 ||
        driverUnmapResponse?.status === 200
      ) {
        setSuccessAlret(true);
        setTripdriverDatamapping({
          triplist: '',
          driverlist: '',
        });
      }
    }
  };

  return (
    <>
      <div>
        <h3
          style={{
            fontSize: 'x-large',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#1976d2',
            marginTop: '2rem',
            borderBottomStyle: 'groove',
          }}
        >
          List of Bookings
        </h3>
      </div>
      <Fab
        color='primary'
        size={'large'}
        aria-label='add'
        cursor='pointer'
        title='Create Booking'
        onClick={() => history.push('./addBooking')}
        className={classes.fab}
      >
        <AddIcon></AddIcon>
      </Fab>

      <Box
        sx={{
          p: '1rem',
          borderRadius: '5px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1rem',
          border: 'black',
        }}
      >
        {bookingDetailsList.map((card, index) => {
          return (
            <Box
              sx={{
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                p: '1.5rem',
                borderRadius: '8px',
                cursor: 'pointer',
                width: '30%',
                border: 'none',
              }}
              key={index}
            >
              <p aria-disabled>
                <b style={{ color: '#1976d2' }}>Order Id:</b>
                {card.orderId}
              </p>

              <p aria-disabled>
                <b style={{ color: '#1976d2' }}>Booking Id:</b>
                {card.bookingid}
              </p>

              <p>
                <b>TimeStamp: </b>
                {card.timestamp}
              </p>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    sitedriverMapHandler(card.orderId, card.bookingid, 'Unmap')
                  }
                >
                  Unmap
                </Button>
                <Button
                  variant='outlined'
                  onClick={() =>
                    sitedriverMapHandler(card.orderId, card.bookingid, 'Map')
                  }
                >
                  Map
                </Button>
              </Box>
            </Box>
          );
        })}

        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                background: '#fff',
                minWidth: '32rem',
                borderRadius: '5px',
              }}
            >
              <Typography
                id='modal-modal-title'
                variant='h6'
                component='h2'
                textAlign='center'
                pb={1}
              >
                {bookingCardDetails.bookingTypeName} Booking
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                {' '}
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    textAlign='center'
                    pb={1}
                    sx={{ fontWeight: 500 }}
                  >
                    Order ID:
                  </Typography>
                  <Typography>{bookingCardDetails.orderId}</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    textAlign='center'
                    pb={1}
                    sx={{ fontWeight: 500 }}
                  >
                    Booking ID:
                  </Typography>
                  <Typography>{bookingCardDetails.bookingId}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id='triplist'>Trip List</InputLabel>
                    <Select
                      labelId='triplist'
                      id='triplist'
                      value={tripdriverDatamapping.triplist}
                      onChange={(event) =>
                        mapListChangeHandler('triplist', event.target.value)
                      }
                      MenuProps={MenuProps}
                    >
                      {tripMenuList.map((li) => (
                        <MenuItem key={li.tripId} value={li.tripId}>
                          {li.tripId}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* ******************** */}
                <Box>
                  <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id='triplist'>Driver List</InputLabel>
                    <Select
                      labelId='triplist'
                      id='triplist'
                      value={tripdriverDatamapping.driverlist}
                      onChange={(event) =>
                        mapListChangeHandler('driverlist', event.target.value)
                      }
                      MenuProps={MenuProps}
                    >
                      {driverMenuList.map((li) => (
                        <MenuItem key={li.username} value={li.username}>
                          {li.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant='outlined'
                    onClick={() =>
                      tripdriverMapHandler(bookingCardDetails.bookingTypeName)
                    }
                  >
                    {bookingCardDetails.bookingTypeName}
                  </Button>
                </Box>
              </Box>
              {successAlret && (
                <Box sx={{ pt: 1 }}>
                  <Alert severity='success'>
                    {bookingCardDetails.bookingTypeName} successfully.
                  </Alert>
                </Box>
              )}
              {mapErrorAlret && (
                <Box sx={{ pt: 1 }}>
                  <Alert severity='error'>Order id not found.</Alert>
                </Box>
              )}
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default ViewOrders;
