import React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/Info';
import CategoryIcon from '@material-ui/icons/Category';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StorageIcon from '@material-ui/icons/Storage';
import FeedbackIcon from '@material-ui/icons/Feedback';
import MoneyIcon from '@material-ui/icons/Money';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AppsIcon from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TenderManagementIcon from '@material-ui/icons/AutorenewRounded';
import TenderSiteManagementIcon from '@material-ui/icons/EditLocationRounded';
import PostManagementIcon from '@material-ui/icons/InsertCommentRounded';
import FarmSiteManagementIcon from '@material-ui/icons/PersonPinCircle';
import UserManagementIcon from '@material-ui/icons/PeopleAlt';
import SiteManagementIcon from '@material-ui/icons/PlaceRounded';
import LegacyAssetMappingIcon from '@material-ui/icons/PetsRounded';
import AssetOEMIcon from '@material-ui/icons/Person';
import UploadDocumentsIcon from '@material-ui/icons/CloudUpload';
import StatisticsIcon from '@material-ui/icons/Assessment';
import StatusIcon from '@material-ui/icons/QueryBuilder';
import SettingsIcon from '@material-ui/icons/Settings';
import SupportICon from '@material-ui/icons/ContactSupport';
import UserIcon from '@material-ui/icons/Person';
import ProductionManagerIcon from '@material-ui/icons/AccountBox';
import PeopleGroupIcon from '@material-ui/icons/Group';
import WorkerIcon from '@material-ui/icons/AccessibilityNew';
import BrokerIcon from '@material-ui/icons/ContactPhone';
import EventsIcon from '@material-ui/icons/Event';
import UpdateIcon from '@material-ui/icons/Update';
import PriceIcon from '@material-ui/icons/MoneyOutlined';
import BuyInputIcon from '@material-ui/icons/Storefront';
import AdviceIcon from '@material-ui/icons/EmojiObjects';
import DoctorIcon from '@material-ui/icons/AssignmentInd';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import WorkIcon from '@material-ui/icons/Work';
import PresentToAllTwoToneIcon from '@material-ui/icons/PresentToAllTwoTone';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ChecklistIcon from '@mui/icons-material/Checklist';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import sha256 from 'sha256';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useMediaPredicate } from 'react-media-hook';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Main from '../Common/MainService';
import 'react-notifications/lib/notifications.css';
import './MainPageComponent.css';
import AppData from '../../../data.json';
import MainService from '../Common/MainService';
import MainDrawer from './MainDrawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: '#FFF',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MainPageComponent(props) {
  var i;
  var postItems = [];
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState('');
  const [userTypeRole, setUserTypeRole] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  // const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [menuList, setMenuList] = React.useState([]);
  const [redirecTo, setRedirecTo] = React.useState('');
  const [showChangePwd, setShowChangePwd] = React.useState(false);
  const [currentMenu, setCurrentMenu] = React.useState(
    props.location.pathname.replace('/main', '')
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userTypeRoleProfile, setUserTypeRoleProfile] = useState('');

  const iconComponents = {
    FeaturedVideoIcon: FeaturedVideoIcon,
    AccountTreeIcon: AccountTreeIcon,
    WorkIcon: WorkIcon,
    PresentToAllTwoToneIcon: PresentToAllTwoToneIcon,
    DashboardIcon: DashboardIcon,
    CloudUploadIcon: CloudUploadIcon,
    CategoryIcon: CategoryIcon,
    AssignmentIndIcon: AssignmentIndIcon,
    AssignmentTurnedInIcon: AssignmentTurnedInIcon,
    AccessibilityNewIcon: AccessibilityNewIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    AssignmentIcon: AssignmentIcon,
    StorageIcon: StorageIcon,
    FeedbackIcon: FeedbackIcon,
    MoneyIcon: MoneyIcon,
    AssessmentIcon: AssessmentIcon,
    InfoIcon: InfoIcon,
    TenderManagementIcon: TenderManagementIcon,
    TenderSiteManagementIcon: TenderSiteManagementIcon,
    PostManagementIcon: PostManagementIcon,
    FarmSiteManagementIcon: FarmSiteManagementIcon,
    UserManagementIcon: UserManagementIcon,
    SiteManagementIcon: SiteManagementIcon,
    LegacyAssetMappingIcon: LegacyAssetMappingIcon,
    AssetOEMIcon: AssetOEMIcon,
    UploadDocumentsIcon: UploadDocumentsIcon,
    StatisticsIcon: StatisticsIcon,
    StatusIcon: StatusIcon,
    SettingsIcon: SettingsIcon,
    SupportICon: SupportICon,
    UserIcon: UserIcon,
    ProductionManagerIcon: ProductionManagerIcon,
    PeopleGroupIcon: PeopleGroupIcon,
    WorkerIcon: WorkerIcon,
    BrokerIcon: BrokerIcon,
    EventsIcon: EventsIcon,
    UpdateIcon: UpdateIcon,
    PriceIcon: PriceIcon,
    BuyInputIcon: BuyInputIcon,
    AdviceIcon: AdviceIcon,
    DoctorIcon: DoctorIcon,
    HealthAndSafetyIcon: HealthAndSafetyIcon,
    ChecklistIcon: ChecklistIcon,
  };

  const biggerThan639 = useMediaPredicate('(min-width: 639px)');
  useEffect(() => {
    if (biggerThan639) {
      setOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Main.getOrgId();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getProfile();
    getUserTypeRoleProfile();
    getUserTypeRole();
  }, []);

  React.useEffect(() => {
    if (userTypeValue !== '') getRoleAccessList();
  }, [userTypeValue, userTypeRole]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setCurrentMenu(props.location.pathname.replace('/main', ''));
  }, [props.location]);

  const postItemsFunction = (data) => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    for (i = 0; i < data.length; i++) {
      postItems.push({
        consumeradminid: 'consumer2',
        assetoemid: username,
        categorytype: data[i].categorytype,
        legacyassettype: data[i].legacyassettype,
        modelid: data[i].modelid,
        assetid: data[i].assetid,
        solutionid: data[i].assetdeveloperid,
        siteid: data[i].siteid,
        paymentmode: 'Online',
        assetpackage: 'Standard',
      });
    }
  };

  // temp*******
  const getProfile = () => {
    Main.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setIsLoading(false);
        getUserTypeRole(data[0].userid);
        //setUserId(data.userid);
        console.log('Profile res data0000000000000000:', data);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error('No profile data found');
      });
  };

  const getUserTypeRole = (props) => {
    console.log('getConsumerUserTypeRole in mainpage***********:', props);
    Main.getConsumerUserTypeRole(parentId, props)
      .then((data) => {
        console.log('getConsumerUserTypeRole in mainpage***********:', data);
        if (data.length > 0) {
          setUserTypeRole(data[0].name);
        } else {
          setUserTypeRole('NULL');
        }
      })
      .catch((e) => {
        setUserTypeRole(e);
      });
  };

  const getUserTypeRoleProfile = () => {
    Main.getConsumerUserTypeRoleProfile(parentId, username)
      .then((data) => {
        console.log('getConsumerUserTypeRole profile in mainpage:', data);
        setUserTypeRoleProfile(data);
      })
      .catch((e) => {
        setUserTypeRole(e);
      });
  };

  // const getUserTypeRole = () => {
  //   Main.getConsumerUserTypeRole(parentId, username)
  //     .then((data) => {
  //       console.log('getConsumerUserTypeRole', data);
  //       setUserTypeRole(data);
  //     })
  //     .catch((e) => {
  //       setUserTypeRole(e);
  //     });
  // };

  const getUserType = () => {
    const { user } = Main.getLocalData();
    const { username, parentId } = user;
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
        console.log('setUserTypeValue', data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getRoleAccessList = () => {
    const userType = userTypeValue;
    const userRoleType = userTypeRole;
    console.log('role type:', userRoleType);
    if (userType === 'Production-Manager') {
      const userRoleData = AppData['Production-Manager'];
      setMenuList(userRoleData);
    } else if (userType === 'Operator') {
      /*else if (userType === "Production-Manager") {
        if(userRoleType === "NULL"){
          const userRoleData = AppData["Production-Manager"][0]["NULL"];
          setMenuList(userRoleData);
        } else if(userRoleType === "BASIC"){
          const userRoleData = AppData["Production-Manager"][0]["BASIC"];
          setMenuList(userRoleData);
        } else if(userRoleType === "STANDARD"){
          const userRoleData = AppData["Production-Manager"][0]["STANDARD"];
          setMenuList(userRoleData);
        } else if(userRoleType === "PREFERRED"){
          const userRoleData = AppData["Production-Manager"][0]["PREFFERED"];
          setMenuList(userRoleData);
        } else if(userRoleType === "PREMIUM"){
          const userRoleData = AppData["Production-Manager"][0]["PREMIUM"];
          setMenuList(userRoleData);
        } else if(userRoleType === "ADMIN"){
          const userRoleData = AppData["Production-Manager"][0]["ADMIN"];
          setMenuList(userRoleData);
        }
      } */
      const userRoleData = AppData['Operator'];
      setMenuList(userRoleData);
    } else if (userType === 'Agent') {
      const userRoleData = AppData.role3;
      setMenuList(userRoleData);
    } else if (userType === 'AgriMiner') {
      const userRoleData = AppData.role4;
      setMenuList(userRoleData);
    } else if (userType === 'pico') {
      const userRoleData = AppData.role6;
      setMenuList(userRoleData);
    } else if (userType === 'Farmer') {
      const userRoleData = AppData.Farmer;
      setMenuList(userRoleData);
    } else if (userType === 'VCNExecutive') {
      const userRoleData = AppData.VCNExecutive;
      setMenuList(userRoleData);
    } else if (userType === 'ACNExecutive') {
      const userRoleData = AppData.ACNExecutive;
      setMenuList(userRoleData);
    } else if (userType === 'VCNDriver') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.VCNTransportUser[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.VCNTransportUser[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.VCNTransportUser[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.VCNTransportUser[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.VCNTransportUser[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.VCNTransportUser[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'ACNDriver') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.ACNTransportUser[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.ACNTransportUser[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.ACNTransportUser[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.ACNTransportUser[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.ACNTransportUser[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.ACNTransportUser[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'ACNManager') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.ACNLogisticsTransportManager[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.ACNLogisticsTransportManager[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData =
          AppData.ACNLogisticsTransportManager[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData =
          AppData.ACNLogisticsTransportManager[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.ACNLogisticsTransportManager[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.LogisticsTransportManager[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'VCNTrader') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.VCNTrader[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.VCNTrader[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.VCNTrader[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.VCNTrader[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.VCNTrader[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.VCNTrader[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'VCNFarmer') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.VCNFarmer[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.VCNFarmer[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.VCNFarmer[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.VCNFarmer[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.VCNFarmer[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.VCNFarmer[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Executive') {
      const userRoleData = AppData.Executive;
      setMenuList(userRoleData);
    } else if (userType === 'DeveloperQA') {
      const userRoleData = AppData.DeveloperQA;
      setMenuList(userRoleData);
    } else if (userType === 'SHG') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.SHG[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.SHG[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.SHG[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.SHG[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.SHG[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.SHG[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'FPO') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.FPO[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.FPO[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.FPO[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.FPO[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.FPO[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.FPO[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'ACNExporter') {
      if (userRoleType === 'NULL' || userRoleType === '') {
        const userRoleData = AppData.ACNExporter[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.ACNExporter[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.ACNExporter[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.ACNExporter[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.ACNExporter[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.ACNExporter[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'VCNStorageOperator') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.VCNStorageOperator[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.VCNStorageOperator[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.VCNStorageOperator[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.VCNStorageOperator[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.VCNStorageOperator[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.VCNStorageOperator[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Logistics-TransportUser') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.LogisticsTransportUser[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.LogisticsTransportUser[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.LogisticsTransportUser[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.LogisticsTransportUser[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.LogisticsTransportUser[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.LogisticsTransportUser[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Logistics-Manager') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.LogisticsTransportManager[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.LogisticsTransportManager[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.LogisticsTransportManager[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.LogisticsTransportManager[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.LogisticsTransportManager[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.LogisticsTransportManager[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'SiteOperator') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.SiteOperator[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.SiteOperator[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.SiteOperator[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.SiteOperator[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.SiteOperator[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.SiteOperator[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'CS') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.CS[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.CS[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.CS[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.CS[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.CS[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.CS[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'NGO') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.NGO[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.NGO[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.NGO[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.NGO[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.NGO[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.NGO[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Trader') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.Trader[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.Trader[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.Trader[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.Trader[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.Trader[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.Trader[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'GBRStore') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.GBRStore[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.GBRStore[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.GBRStore[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.GBRStore[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.GBRStore[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.GBRStore[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Trainer') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.Trainer[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.Trainer[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.Trainer[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.Trainer[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.Trainer[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.Trainer[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'FF') {
      const userRoleData = AppData.FF;
      setMenuList(userRoleData);
    } else if (userType === 'Site-Operator') {
      const userRoleData = AppData['Site-Operator'];
      setMenuList(userRoleData);
      if (userRoleType === 'NULL') {
        const userRoleData = AppData['Site-Operator'][0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData['Site-Operator'][0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData['Site-Operator'][0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData['Site-Operator'][0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData['Site-Operator'][0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData['Site-Operator'][0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'GBR-Store') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData['GBR-Store'][0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData['GBR-Store'][0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData['GBR-Store'][0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData['GBR-Store'][0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData['GBR-Store'][0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData['GBR-Store'][0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Trader') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.Trader[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.Trader[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.Trader[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.Trader[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.Trader[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.Trader[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'SupplyShop') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.SupplyShop[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.SupplyShop[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.SupplyShop[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.SupplyShop[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.SupplyShop[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.SupplyShop[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'InputRetailer') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.InputRetailer[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.InputRetailer[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.InputRetailer[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.InputRetailer[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.InputRetailer[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.InputRetailer[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'DSNExecutive') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.DSNExecutive[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.DSNExecutive[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.DSNExecutive[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.DSNExecutive[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.DSNExecutive[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.DSNExecutive[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'StoreManager') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.StoreManager[0]['ADMIN'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.StoreManager[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.StoreManager[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.StoreManager[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.StoreManager[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.StoreManager[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'StoreOperator') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.StoreOperator[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.StoreOperator[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.StoreOperator[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.StoreOperator[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.StoreOperator[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.StoreOperator[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'PlantExecutive') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.PlantExecutive[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.PlantExecutive[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.PlantExecutive[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.PlantExecutive[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.PlantExecutive[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.PlantExecutive[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'PlantManager') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.PlantManager[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.PlantManager[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.PlantManager[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.PlantManager[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.PlantManager[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.PlantManager[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'PlantOperator') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.PlantOperator[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.PlantOperator[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.PlantOperator[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.PlantOperator[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.PlantOperator[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.PlantOperator[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'Technician') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.Technician[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.Technician[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.Technician[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.Technician[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.Technician[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.Technician[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'EHS') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.EHSOperator[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.EHSOperator[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.EHSOperator[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.EHSOperator[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.EHSOperator[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.EHSOperator[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else if (userType === 'PlantQA-QC') {
      if (userRoleType === 'NULL') {
        const userRoleData = AppData.PlantQAQC[0]['NULL'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'BASIC') {
        const userRoleData = AppData.PlantQAQC[0]['BASIC'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'STANDARD') {
        const userRoleData = AppData.PlantQAQC[0]['STANDARD'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREFERRED') {
        const userRoleData = AppData.PlantQAQC[0]['PREFFERED'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'PREMIUM') {
        const userRoleData = AppData.PlantQAQC[0]['PREMIUM'];
        setMenuList(userRoleData);
      } else if (userRoleType === 'ADMIN') {
        const userRoleData = AppData.PlantQAQC[0]['ADMIN'];
        setMenuList(userRoleData);
      }
    } else {
      const userRoleData = AppData.role;
      setMenuList(userRoleData);
    }
  };
  const logout = () => {
    const userType = userTypeValue;

    console.log('userType:', userType);
    Main.doLogout();
    localStorage.setItem("Login" , true)


    if (userType === 3) {
      window.location.href = '/auth/3/signin';
    } else if (userType === 4) {
      window.location.href = '/auth/2/signin';
    } else if (userType === 5) {
      window.location.href = '/auth/5/signin';
    } else if (userType === 6) {
      window.location.href = '/auth/3/signin';
    } else {
      window.location.href = '/auth/3/signin';
      return;
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (Main.isLoggedIn() !== true) {
    logout();
    return <>Unauthorised.</>;
  }
  const toggleChangePassword = () => {
    setShowChangePwd(!showChangePwd);
  };

  const getAppBar = () => {
    return (
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {open === false && (
            <Typography variant='h6' style={{ color: '#FFF' }} noWrap>
              GBR
            </Typography>
          )}
          <div style={{ flexGrow: 1 }} />

          <div>
            {/*  <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon style={{ color: "#FFF" }} />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon style={{ color: "#FFF" }} />
              </Badge>
            </IconButton> */}

            <IconButton
              color='inherit'
              title='Change Password'
              onClick={toggleChangePassword}
            >
              <VpnKeyIcon style={{ color: '#FFF' }} />
            </IconButton>
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-haspopup='true'
              color='inherit'
              onClick={logout}
              title={username}
            >
              <ExitToAppIcon style={{ color: '#FFF' }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    );
  };
  const getMenuDrawer = () => {
    return (
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <h1 style={{ position: 'absolute', left: '15px', top: '-5px' }}>
            GBR
          </h1>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <AppsIcon /> : <AppsIcon />}
          </IconButton>
        </div>
        <Divider />

        {getMenuList()}
      </Drawer>
    );
  };

  const getMenuList = () => {
    return (
      <List>
        {menuList.map((menu, index) => {
          const IconTagName = iconComponents[menu.acc_icon || 'InfoIcon'];
          return (
            <ListItem
              selected={currentMenu === menu.acc_menuUrl}
              button
              key={index}
              onClick={() => setRedirecTo(`/main${menu.acc_menuUrl}`)}
            >
              <ListItemIcon title={menu.acc_menu}>
                <IconTagName />
              </ListItemIcon>
              <ListItemText primary={menu.acc_menu} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const doChangePwd = (evt) => {
    evt.preventDefault();
    const oldPwd = document.getElementById('oldPwd').value;
    const newPwd = document.getElementById('newPwd').value;
    const email = document.getElementById('mail').value;
    if (!oldPwd || !newPwd) {
      NotificationManager.error('Please enter valid input.');
      return;
    }
    const oldpassword = sha256(oldPwd);
    const password = oldpassword;
    const newpassword = sha256(newPwd);
    const pwd = newpassword;
    setIsLoading(true);
    Main.doChangePwd({
      username,
      email,
      oldpassword: password,
      newpassword: pwd,
    })
      .then((res) => {
        setIsLoading(false);
        NotificationManager.success('Password changed successfully.');
        toggleChangePassword();
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };
  const changePwdBody = (
    <Grid container spacing={0}>
      <Grid item xs={11} sm={5} md={4} lg={3} className='loginOuterContainer'>
        <div className='forgotPwdContainer'>
          <form onSubmit={doChangePwd}>
            <h2>Change Password</h2>

            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='mail'>Email *</InputLabel>
              <Input
                id='mail'
                type='mail'
                startAdornment={
                  <InputAdornment position='start'>
                    <i className='material-icons'> vpn_key </i>{' '}
                  </InputAdornment>
                }
                require='truse'
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='username'>Current Password *</InputLabel>
              <Input
                id='oldPwd'
                type='password'
                startAdornment={
                  <InputAdornment position='start'>
                    <i className='material-icons'> vpn_key </i>{' '}
                  </InputAdornment>
                }
                require='truse'
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='username'>New Password *</InputLabel>
              <Input
                id='newPwd'
                type='password'
                startAdornment={
                  <InputAdornment position='start'>
                    <i className='material-icons'> vpn_key </i>{' '}
                  </InputAdornment>
                }
                require='true'
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant='contained'
                  color='primary'
                  className='loginBtn'
                  type='submit'
                >
                  Reset
                </Button>
              )}{' '}
              {isLoading === true && (
                <Button
                  variant='contained'
                  color='primary'
                  className='loginBtn'
                  type='button'
                  disabled={true}
                >
                  <CircularProgress className='loginLoader' />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className='forgotPwdLink'
              onClick={toggleChangePassword}
              style={{
                marginBottom: '15px',
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  const mainDashboardItem = () => {
    console.log('props children', props);
    return (
      <main className='{classes.content}, dashboardMainContainer'>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    );
  };
  const reLoad = () => {
    window.location.reload();
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    return mobileOpen;
  };

  return (
    // <ThemeProvider theme={darkTheme}>
    <>
      <CssBaseline />
      <div className={classes.root}>
        {redirecTo === '' ? '' : <Redirect to={redirecTo} />}

        {/* {getAppBar()}

        {getMenuDrawer()}

        <main className="{classes.content}, dashboardMainContainer">
          <div className={classes.toolbar} />

          {props.children}

          
        </main> */}

        <MainDrawer
          getMenuListHandler={getMenuList}
          mainDashboardItemHandler={mainDashboardItem}
          reLoadHandler={reLoad}
          toggleChangePasswordHandler={toggleChangePassword}
          logoutHandler={logout}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Modal
          open={showChangePwd}
          onClose={toggleChangePassword}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          {changePwdBody}
        </Modal>

        <NotificationContainer />
      </div>
      {/* </ThemeProvider> */}
    </>
  );
}
