import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Input from '@material-ui/core/Input';
import { Box } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchBar from 'material-ui-search-bar';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOnOutlined';
import MainService from '../../Common/MainService';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './DashboardePageComponentForOperator.css';
import StarIcon from '@material-ui/icons/Star';
import CustomPopup from '../../Common/CustomPopup/CustomPopupComponent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: '#fff',
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
}));

export default function DashboardePageComponentForOperator() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [roleName, setRoleName] = useState('');
  const [roleResourcesList, setRoleResourcesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [listViewAssets, setListViewAssets] = useState(false);
  const [listViewSites, setListViewSites] = useState(false);
  const [gridViewAssets, setGridViewAssets] = useState(false);
  const [gridViewSites, setGridViewSites] = useState(true);
  const [gridViewSiteIssues, setGridViewSiteIssues] = useState(false);
  const [gridViewAssetIssues, setGridViewAssetIssues] = useState(false);
  const [gridViewSiteReports, setGridViewSiteReports] = useState(false);
  const [roleResourceAssetsList, setRoleResourceAssetsList] = useState([]);
  const [resourceId, setResourceId] = useState('');
  const [filterUsers, setFilterUsers] = useState([]);
  const [siteIssuesList, setSiteIssuesList] = useState([]);
  const [assetIssuesList, setAssetIssuesList] = useState([]);
  const [siteReportsList, setSiteReportsList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [searched, setSearched] = useState('');
  const [farmgridsearched, setFarmGridSearched] = useState('');
  const [page, setPage] = React.useState(0);
  const [siteIssuePopup, setSiteIssuePopup] = useState(false);
  const [attendancePopup, setAttendancePopup] = useState(false);
  const [assetIssuePopup, setAssetIssuePopup] = useState(false);
  const [assetHealthPopup, setAssetHealthPopup] = useState(false);
  const [assetReportPopup, setAssetReportPopup] = useState(false);
  const [patrolPopup, setPatrolPopup] = useState(false);
  const [assetFeedbackPopup, setAssetFeedbackPopup] = useState(false);
  const [assetEnvPopup, setAssetEnvPopup] = useState(false);
  const [siteEnvPopup, setSiteEnvPopup] = useState(false);
  const [assetLocationPopup, setAssetLocationPopup] = useState(false);
  const [assetMortalityPopup, setAssetMortalityPopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [selectedParentId, setSelectedParentId] = useState('');
  const [selectedModelType, setSelectedModelType] = useState('');
  const [selectedAssetGender, setSelectedAssetGender] = useState('');
  const [deviceTypeValue, setDeviceTypeValue] = useState('');

  const [assetEnvData, setAssetEnvData] = useState({});
  const [siteEnvData, setSiteEnvData] = useState({});
  const [responseCmdValue, setResponseCmdValue] = useState('');
  const [temperatureValue, setTemperatureValue] = useState('');
  const [RHValue, setRHValue] = useState('');
  const [soundValue, setSoundValue] = useState('');
  const [ppmValue, setPpmValue] = useState('');
  const [vibrationXValue, setVibrationXValue] = useState('');
  const [vibrationYValue, setVibrationYValue] = useState('');
  const [vibrationZValue, setVibrationZValue] = useState('');
  const [numberofPropertiesValue, setNumberofPropertiesValue] = useState(2);
  const [mobileNumberValue, setMobileNumberValue] = useState('');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedSiteName, setSelectedSiteName] = useState('');
  const [selectedSiteType, setSelectedSiteType] = useState('');
  const [selectedAssetType, setSelectedAssetType] = useState('');
  const [medicinedateValue, setMedicineDateValue] = useState('');
  const [vaccinationdateValue, setVaccinationDateValue] = useState('');
  const [treatmentDateValue, setTreatmentDateValue] = useState('');
  const [examineValue, setExamineValue] = useState('');
  const [suricalProcedureValue, setSuricalProcedureValue] = useState('');
  const [vaccinationTypeValue, setVaccinationTypeValue] = useState('');
  const [medicineTypeValue, setMedicineTypeValue] = useState('');
  const [diseaseTypeValue, setDiseaseTypeValue] = useState('');
  const [latitudeValue, setLatitudeValue] = useState('');
  const [longitudeValue, setLongitudeValue] = useState('');
  const [altitudeValue, setAltitudeValue] = useState('');
  const [dailyReportPopup, setDailyReportPopup] = useState(false);
  const [assetDailyReportPopup, setAssetDailyReportPopup] = useState(false);
  const [typeValue, setTypeValue] = useState('');
  const [nutritionRecordedValue, setNutritionRecordedValue] = useState('');
  const [attendanceRecordedValue, setAttendanceRecordedValue] = useState('');
  const [photoUploadedValue, setPhotoUploadedValue] = useState('');
  const [userTypeValue, setUserTypeValue] = useState('');
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subCategoryNameValue, setSubCategoryNameValue] = useState('');
  const [parentid, setParentId] = useState('');
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  /* Asset Feedback */
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState('');
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState('');
  const [deliveryExp, setDeliveryExp] = useState('');
  const [productExp, setProductExp] = useState('');
  const [supportExp, setSupportExp] = useState('');
  const [othersExp, setOthersExp] = useState('');

  /* Asset Mortality */
  const [causeofDeathValue, setCauseofDeathValue] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [breedPercentageValue, setBreedPercentageValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [birthDateValue, setBirthDateValue] = useState('');
  const [totalAgeValue, setTotalAgeValue] = useState('');

  /* Update Asset Status */
  const [selectedExistingStatus, setSelectedExistingStatus] = useState('');
  const [updateAssetStatusValue, setUpdateAssetStatusValue] = useState('');
  const [assetUpdateStatusPopup, setAssetUpdateStatusPopup] = useState('');

  /* Photo Upload */
  const [selectedCategoryName, setSelectedCategoryName] = useState('IMAGE');
  const [uploadPhotoPopup, setShowUploadPhotoPopup] = useState(false);
  const [uploadPhotoFile, setUploadPhotoFile] = useState(null);
  const [uploadPhotoFileDescription, setUploadPhotoFileDescription] =
    useState(null);
  const [uploadPhotoNameValue, setUploadPhotoNameValue] = useState('');
  const [priceValue, setPriceValue] = useState('');
  const [expiryDateValue, setExpiryDateValue] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  /* End of Photo Upload */
  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? 's' : ''}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? 's' : ''}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? 's' : ''}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? 's' : ''}, ${
      labels[othersRate]
    }`;
  }
  const [timeStamp, setTimeStamp] = useState('');
  /********** Nutrition ****************/
  const [showNutrition, setShowNutrition] = useState(false);
  const [getNutritions, setNutritions] = useState([]);

  const [showAddNutritionPopup, setAddNutritionPopup] = useState(false);
  const [getAddNutritionAssetid, setAddNutritionAssetid] = useState('');
  const [getAddNutritionAssetFriendlyName, setAddNutritionAssetFriendlyName] =
    useState('');
  const [getAddNutritionUserid, setAddNutritionUserid] = useState(username);
  const [getAddNutritionFeedType, setAddNutritionFeedType] = useState('');
  const [getAddNutritionSchedule, setAddNutritionSchedule] = useState('');
  const [getAddNutritionLatitude, setAddNutritionLatitude] = useState('');
  const [getAddNutritionLongitude, setAddNutritionLongitude] = useState('');
  const [getAddNutritionAltitude, setAddNutritionAltitude] = useState('');
  const [getAddNutritionDescription, setAddNutritionDescription] = useState('');

  const [showDeleteNutrition, setDeleteNutrition] = useState(false);
  const [getDeleteNutritionAssetid, setDeleteNutritionAssetid] = useState('');

  const [showUpdateAddNutritionPopup, setUpdateAddNutritionPopup] =
    useState(false);
  const [getUpdateAddNutritionAssetid, setUpdateAddNutritionAssetid] =
    useState('');
  const [
    getUpdateAddNutritionAssetFriendlyName,
    setUpdateAddNutritionAssetFriendlyName,
  ] = useState('');
  const [getUpdateAddNutritionUserid, setUpdateAddNutritionUserid] =
    useState('');
  const [getUpdateAddNutritionFeedType, setUpdateAddNutritionFeedType] =
    useState('');
  const [getUpdateAddNutritionSchedule, setUpdateAddNutritionSchedule] =
    useState('');
  const [getUpdateAddNutritionLatitude, setUpdateAddNutritionLatitude] =
    useState('');
  const [getUpdateAddNutritionLongitude, setUpdateAddNutritionLongitude] =
    useState('');
  const [getUpdateAddNutritionAltitude, setUpdateAddNutritionAltitude] =
    useState('');
  const [getUpdateAddNutritionDescription, setUpdateAddNutritionDescription] =
    useState('');
  const [
    getUpdateAddNutritionExistingFeedType,
    setUpdateAddNutritionExistingFeedType,
  ] = useState('');
  /********** End Of Nutrition ****************/
  /********** Birth ****************/
  const [getSiteData, setSiteData] = useState([]);

  const [showBirth, setShowBirth] = useState(false);
  const [showNewBirthInfoPopup, setNewBirthInfoPopup] = useState(false);
  const [getBirthInfos, setBirthInfos] = useState([]);

  const [getNewBirthAssetid, setNewBirthAssetid] = useState('');
  const [getNewBirthAssetFriendlyName, setNewBirthAssetFriendlyName] =
    useState('');
  const [getNewBirthUserid, setNewBirthUserid] = useState(username);
  const [getNewBirthUserName, setNewBirthUserName] = useState('');
  const [getNewBirthSiteid, setNewBirthSiteid] = useState('');
  const [getNewBirthSiteName, setNewBirthSiteName] = useState('');
  const [getNewBirthLotid, setNewBirthLotid] = useState('');
  const [getNewBirthLotName, setNewBirthLotName] = useState('');
  const [getNewBirthLegacyAssetType, setNewBirthLegacyAssetType] = useState('');
  const [getNewBirthModelid, setNewBirthModelid] = useState('');
  const [getNewBirthModelFriendlyName, setNewBirthModelFriendlyName] =
    useState('');
  const [getNewBirthModelType, setNewBirthModelType] = useState('');
  const [getNewBirthModelURL, setNewBirthModelURL] = useState('');
  const [getNewBirthDOB, setNewBirthDOB] = useState('');
  const [getNewBirthGender, setNewBirthGender] = useState('');
  const [getNewBirthMaleParent, setNewBirthMaleParent] = useState('');
  const [getNewBirthFemaleParent, setNewBirthFemaleParent] = useState('');
  const [getNewBirthParentModelid, setNewBirthParentModelid] = useState('');
  const [getNewBirthLatitude, setNewBirthLatitude] = useState('');
  const [getNewBirthLongitude, setNewBirthLongitude] = useState('');
  const [getNewBirthAltitude, setNewBirthAltitude] = useState('');
  const [getNewBirthDescription, setNewBirthDescription] = useState('');

  const [getDeleteBirthInfoPopup, setDeleteBirthInfoPopup] = useState(false);
  const [getDeleteBirthInfo, setDeleteBirthInfo] = useState('');

  const [showUpdateBirthInfoPopup, setUpdateBirthInfoPopup] = useState(false);
  const [getUpdateNewBirthAssetid, setUpdateNewBirthAssetid] = useState('');
  const [
    getUpdateNewBirthAssetFriendlyName,
    setUpdateNewBirthAssetFriendlyName,
  ] = useState('');
  const [getUpdateNewBirthUserid, setUpdateUpdateNewBirthUserid] = useState('');
  const [getUpdateNewBirthUserName, setUpdateNewBirthUserName] = useState('');
  const [getUpdateNewBirthSiteid, setUpdateNewBirthSiteid] = useState('');
  const [getUpdateNewBirthSiteName, setUpdateNewBirthSiteName] = useState('');
  const [getUpdateNewBirthLotid, setUpdateNewBirthLotid] = useState('');
  const [getUpdateNewBirthLotName, setUpdateNewBirthLotName] = useState('');
  const [getUpdateNewBirthLegacyAssetType, setUpdateNewBirthLegacyAssetType] =
    useState('');
  const [getUpdateNewBirthModelid, setUpdateNewBirthModelid] = useState('');
  const [
    getUpdateNewBirthModelFriendlyName,
    setUpdateNewBirthModelFriendlyName,
  ] = useState('');
  const [getUpdateNewBirthModelType, setUpdateNewBirthModelType] = useState('');
  const [getUpdateNewBirthModelURL, setUpdateNewBirthModelURL] = useState('');
  const [getUpdateNewBirthDOB, setUpdateNewBirthDOB] = useState('');
  const [getUpdateNewBirthGender, setUpdateNewBirthGender] = useState('');
  const [getUpdateNewBirthMaleParent, setUpdateNewBirthMaleParent] =
    useState('');
  const [getUpdateNewBirthFemaleParent, setUpdateNewBirthFemaleParent] =
    useState('');
  const [getUpdateNewBirthParentModelid, setUpdateNewBirthParentModelid] =
    useState('');
  const [getUpdateNewBirthLatitude, setUpdateNewBirthLatitude] = useState('');
  const [getUpdateNewBirthLongitude, setUpdateNewBirthLongitude] = useState('');
  const [getUpdateNewBirthAltitude, setUpdateNewBirthAltitude] = useState('');
  const [getUpdateNewBirthDescription, setUpdateNewBirthDescription] =
    useState('');
  /********** End Of Birth ****************/

  const getTimeStamp = () => {
    var date = new Date();
    var current_date =
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    var current_time =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    var date_time = current_date + ' ' + current_time;
    setTimeStamp(date_time);
    return date_time;
  };
  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCategory();
    getRoleNameofOperator();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ('geolocation' in navigator) {
      console.log('Available');
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        console.log('Altitude is :', position.coords.altitude);
        setLatitudeValue(position.coords.latitude.toString());
        setLongitudeValue(position.coords.longitude.toString());
      });
    } else {
      console.log('Not Available');
      setLatitudeValue('');
      setLongitudeValue('');
    }

    getAllSitesList();
  }, [setSiteData]);

  const getAllSitesList = () => {
    MainService.getAllSiteTypes()
      .then((res) => {
        setSiteData(res.sitetypes);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getRoleNameofOperator = () => {
    MainService.getRoleNameofOperator(parentId, username)
      .then((res) => {
        console.log('role resp:', res);
        setRoleName(res);
        getRoleResources(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getRoleResources = (roleName) => {
    MainService.getRoleResources(parentId, roleName)
      .then((res) => {
        console.log('resouces list:', res.role.resources);
        var filtered = res.role.resources.filter(function (el) {
          return el.resourceid !== '';
        });
        console.log('resouces list1:', filtered);
        setRoleResourcesList(filtered);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getRoleResourceAssets = (resourceId) => {
    MainService.getRoleResourceAssets(parentId, resourceId)
      .then((res) => {
        console.log('getRoleResourceAssets', res);
        setRoleResourceAssetsList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUserType = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const sitesListViewClick = () => {
    setListViewSites(true);
    setGridViewSites(false);
    setListViewAssets(false);
    setGridViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
  };
  const sitesGridViewClick = () => {
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
  };
  const assetsListViewClick = (resourceId) => {
    setResourceId(resourceId);
    setListViewSites(false);
    setGridViewSites(false);
    setListViewAssets(true);
    setGridViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
    getRoleResourceAssets(resourceId);
  };
  const assetsGridViewClick = (resourceId) => {
    setResourceId(resourceId);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
    getRoleResourceAssets(resourceId);
  };

  const siteIssuesClick = (siteId) => {
    setResourceId(siteId);
    setGridViewSiteIssues(true);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
    MainService.getSiteIssueData(parentId, siteId)
      .then((res) => {
        setSiteIssuesList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getCategory = () => {
    if (parentId !== '' && selectedCategoryName !== '') {
      MainService.getCategory('consumer2', selectedCategoryName)
        .then((data) => {
          console.log('selectedCategory id', data);
          if (data) {
            setSelectedCategoryId(data);
          } else {
            setSelectedCategoryId('');
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error('error');
        });
    }
  };
  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSubCategory(data[0].contentsubcategoryname);
          setsubCategoriesList(data);
          setIsLoading(false);
        } else {
          setsubCategoriesList(data);
          setSelectedSubCategory('');
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /* Birth Api's*/

  const birthInfoButtonClickHandler = (asset) => {
    setNewBirthAssetid(asset.assetId);
    setNewBirthSiteid(asset.siteId);

    getBirthDetailsHandler();
    setGridViewSiteReports(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowBirth(true);
    setShowNutrition(false);
  };

  const getBirthDetailsHandler = () => {
    setIsLoading(true);
    MainService.getBirthDetails(username)
      .then((res) => {
        setIsLoading(false);
        setBirthInfos(res.birthInfos);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createNewBirthInfo = () => {
    const birth = {
      assetid: getNewBirthAssetid,
      assetfriendlyname: getNewBirthAssetFriendlyName,
      parentid: parentId,
      userid: getNewBirthUserid,
      username: getNewBirthUserName,
      siteid: getNewBirthSiteid,
      sitename: getNewBirthSiteName,
      lotid: getNewBirthLotid,
      lotname: getNewBirthLotName,
      legacyassettype: getNewBirthLegacyAssetType,
      modelid: getNewBirthModelid,
      modelfriendlyname: getNewBirthModelFriendlyName,
      modeltype: getNewBirthModelType,
      modelurl: getNewBirthModelURL,
      dob: getNewBirthDOB,
      gender: getNewBirthGender,
      maleparent: getNewBirthMaleParent,
      femaleparent: getNewBirthFemaleParent,
      parentmodelid: getNewBirthParentModelid,
      latitude: getNewBirthLatitude,
      longitude: getNewBirthLongitude,
      altitude: getNewBirthAltitude,
      description: getNewBirthDescription,
    };
    const birthinfo = JSON.stringify(birth);

    MainService.addBirthInfo(birthinfo)
      .then((res) => {
        setNewBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success('birth info added');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const setNewBirthSiteidHandler = (e) => {
    setUpdateNewBirthSiteid(e.target.value);
    const siteidfilter = roleResourcesList.filter((sites) => {
      return sites.resourceid === e.target.value;
    });
    setUpdateNewBirthSiteName(siteidfilter[0].resourcename);
  };
  const deleteNewBirthInfoAssetId = (assetid) => {
    setDeleteBirthInfo(assetid);
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const deleteBirthInfoAsset = () => {
    MainService.deleteBirthInfo(getDeleteBirthInfo)
      .then((res) => {
        getBirthDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const updateBirthInfoData = (assetinfo) => {
    setUpdateNewBirthAssetid(assetinfo.assetid);
    setUpdateNewBirthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateUpdateNewBirthUserid(assetinfo.userid);
    setUpdateNewBirthUserName(assetinfo.username);
    setUpdateNewBirthSiteid(assetinfo.siteid);
    setUpdateNewBirthSiteName(assetinfo.sitename);
    setUpdateNewBirthLotid(assetinfo.lotid);
    setUpdateNewBirthLotName(assetinfo.lotname);
    setUpdateNewBirthLegacyAssetType(assetinfo.legacyassettype);
    setUpdateNewBirthModelid(assetinfo.modelid);
    setUpdateNewBirthModelFriendlyName(assetinfo.modelfriendlyname);
    setUpdateNewBirthModelType(assetinfo.modeltype);
    setUpdateNewBirthModelURL(assetinfo.modelurl);
    setUpdateNewBirthDOB(assetinfo.dob);
    setUpdateNewBirthGender(assetinfo.gender);
    setUpdateNewBirthMaleParent(assetinfo.maleparent);
    setUpdateNewBirthFemaleParent(assetinfo.femaleparent);
    setUpdateNewBirthParentModelid(assetinfo.parentmodelid);
    setUpdateNewBirthLatitude(assetinfo.latitude);
    setUpdateNewBirthLongitude(assetinfo.longitude);
    setUpdateNewBirthAltitude(assetinfo.altitude);
    setUpdateNewBirthDescription(assetinfo.description);

    setUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
  };

  const updateNewBirthInfoHandler = () => {
    const updatebirth = {
      assetid: getUpdateNewBirthAssetid,
      assetfriendlyname: getUpdateNewBirthAssetFriendlyName,
      siteid: getUpdateNewBirthSiteid,
      sitename: getUpdateNewBirthSiteName,
      lotid: getUpdateNewBirthLotid,
      lotname: getUpdateNewBirthLotName,
      legacyassettype: getUpdateNewBirthLegacyAssetType,
      modelid: getUpdateNewBirthModelid,
      modelfriendlyname: getUpdateNewBirthModelFriendlyName,
      modeltype: getUpdateNewBirthModelType,
      modelurl: getUpdateNewBirthModelURL,
      dob: getUpdateNewBirthDOB,
      gender: getUpdateNewBirthGender,
      maleparent: getUpdateNewBirthMaleParent,
      femaleparent: getUpdateNewBirthFemaleParent,
      parentmodelid: getUpdateNewBirthParentModelid,
      latitude: getUpdateNewBirthLatitude,
      longitude: getUpdateNewBirthLongitude,
      altitude: getUpdateNewBirthAltitude,
      description: getUpdateNewBirthDescription,
    };
    const updatebirthinfo = JSON.stringify(updatebirth);
    MainService.updateBirthInfo(updatebirthinfo)
      .then((res) => {
        setUpdateBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success('birth info updated');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Birth Api's*/

  /*Photo Upload */
  const uploadPhotoClick = (assetData) => {
    setSelectedAssetId(assetData.assetId);
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };
  const submitUploadPhotoClick = () => {
    if (uploadPhotoFile) {
      const uploadPhotoData = new FormData();
      uploadPhotoData.append('file', uploadPhotoFile);
      uploadPhotoData.append('mimetype', uploadPhotoFile.type);
      uploadPhotoData.append('categoryid', selectedCategoryId);
      uploadPhotoData.append('categoryname', selectedCategoryName);
      //uploadPhotoData.append("contentsubcategory", subCategoryNameValue);
      uploadPhotoData.append('contentname', uploadPhotoNameValue);
      uploadPhotoData.append('assetdeveloperid', parentId);
      uploadPhotoData.append('contentdescription', descriptionValue);
      uploadPhotoData.append('contentcreator', username);
      uploadPhotoData.append('contentformat', 'IMAGE');
      uploadPhotoData.append('price', priceValue);
      uploadPhotoData.append('filedescription', uploadPhotoFileDescription);
      uploadPhotoData.append('contentownerid', username);
      uploadPhotoData.append('unit', 'INR');
      uploadPhotoData.append('discountedprice', discountValue);
      uploadPhotoData.append('expirydate', expiryDateValue);
      uploadPhotoData.append('event', 'none');
      console.log('create uploadPhoto data:', uploadPhotoData);
      MainService.createContent(uploadPhotoData, parentId)
        .then((res) => {
          return NotificationManager.success('UploadPhoto uploaded');
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error('please choose file');
    }
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };

  const closeUploadPhotoModal = (
    <span
      onClick={() => {
        setShowUploadPhotoPopup(!uploadPhotoPopup);
      }}
    >
      X
    </span>
  );
  const titleUploadPhoto = 'Upload Photo';
  const uploadPhotoBody = (
    <div>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='categoryName'>Category Name :</InputLabel>
        <Input
          disabled
          id='categoryName'
          name='categoryName'
          type='text'
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>

      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='uploadPhotoName'>UploadPhoto Name :</InputLabel>
        <Input
          id='uploadPhotoName'
          name='uploadPhotoName'
          type='text'
          value={uploadPhotoNameValue}
          onChange={(e) => {
            setUploadPhotoNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant='standard'
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel htmlFor='contentOwner'>Content Owner</InputLabel>
        <Input
          disabled
          id='contentOwner'
          name='contentOwner'
          type='text'
          value={selectedAssetId}
        ></Input>
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='contentCreator'> Content Creator:</InputLabel>
        <Input
          disabled
          id='contentCreator'
          name='contentCreator'
          type='text'
          value={username}
        />
      </FormControl>

      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='zone'> Content Approver:</InputLabel>
        <Input
          disabled
          id='contentApprover'
          name='contentApprover'
          type='text'
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='doc'>file:</InputLabel>
        <Input
          id='doc'
          name='doc'
          type='file'
          onChange={(e) => {
            setUploadPhotoFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='description'> Description:</InputLabel>
        <Input
          id='description'
          name='description'
          type='text'
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant='contained'
          color='primary'
          className='registerBtn'
          type='submit'
          onClick={() => {
            submitUploadPhotoClick();
          }}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className='forgotPwdLink'
        onClick={() => {
          setShowUploadPhotoPopup(!uploadPhotoPopup);
        }}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          boxOrient: '0',
          display: 'block',
          fontSize: '20',
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* end of photo upload */
  const submitSiteIssueClick = () => {
    let issueData = {
      operatorid: username,
      solutionid: parentId,
      siteid: resourceId,
      issuedescription: descriptionValue,
    };
    const issueDataStringify = JSON.stringify(issueData);
    console.log('site issue data:', issueDataStringify);
    MainService.createSiteIssue(username, issueDataStringify)
      .then((res) => {
        NotificationManager.success(res);
        siteIssuesClick(resourceId);
      })
      .catch((err) => {
        NotificationManager.error('status not supported');
      });

    setSiteIssuePopup(!siteIssuePopup);
  };

  const siteReportsClick = (site) => {
    setResourceId(site.resourceid);

    getUserType();

    setDailyReportPopup(!dailyReportPopup);
  };

  const dailyReportSubmitClick = () => {
    let reportData = {
      parentid: parentId,
      userid: username,
      type: userTypeValue,
      assetid: resourceId,
      nutritionRecorded: nutritionRecordedValue,
      attendanceRecorded: attendanceRecordedValue,
      photoUploaded: photoUploadedValue,
    };
    console.log('daily report:', reportData);
    const reportDataStringify = JSON.stringify(reportData);
    MainService.dailyReport(reportDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setNutritionRecordedValue('');
    setAttendanceRecordedValue('');
    setPhotoUploadedValue('');
    setDailyReportPopup(!dailyReportPopup);
  };

  const attendanceClick = (asset) => {
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);

    setAttendancePopup(!attendancePopup);
  };
  const assetAttendanceSubmitClick = () => {
    let attendanceData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      parentid: selectedParentId,
      userid: username,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
    };
    const attendanceDataStringify = JSON.stringify(attendanceData);
    console.log('attendanceData:', attendanceDataStringify);
    MainService.addAttendanceLocation(attendanceDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAttendancePopup(!attendancePopup);
  };
  const assetIssueClick = (asset) => {
    setSelectedAssetType(asset.assetType);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedSiteId(asset.siteId);
    console.log('asset data:', asset);

    setGridViewSiteReports(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(true);
    setShowBirth(false);
    setShowNutrition(false);

    MainService.getAssetIssues(asset.solutionId, asset.siteId, asset.assetId)
      .then((res) => {
        if (res.length > 0) {
          setAssetIssuesList(res);
          setFilterUsers(res);
        } else {
          setAssetIssuesList([]);
          setFilterUsers([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetIssueSubmitClick = () => {
    let issueData = {
      operatorid: username,
      solutionid: selectedParentId,
      siteid: selectedSiteId,
      assetid: selectedAssetId,
      issuedescription: descriptionValue,
      timeStamp: getTimeStamp(),
    };
    const issueDataStringify = JSON.stringify(issueData);
    MainService.createAssetIssue(username, issueDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error('status not supported');
      });
    setAssetIssuePopup(!assetIssuePopup);
  };
  const assetHealthClick = (asset) => {
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedSiteId(asset.siteId);
    setSelectedAssetType(asset.assetType);
    setAssetHealthPopup(!assetHealthPopup);
  };
  const assetHealthSubmitClick = () => {
    let healthData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      assetdeveloperid: username,
      medicinedate: medicinedateValue,
      vaccinationdate: vaccinationdateValue,
      examine: examineValue,
      surgicalprocedure: suricalProcedureValue,
      vaccinationtype: vaccinationTypeValue,
      medicinetype: medicineTypeValue,
      diseasetype: diseaseTypeValue,
      treatmentdate: treatmentDateValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
      timestamp: getTimeStamp(),
    };
    const healthDataStringify = JSON.stringify(healthData);
    MainService.addAssetHealth(healthDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetHealthPopup(!assetHealthPopup);
  };
  const assetReportClick = (asset) => {
    getUserType();
    setSelectedAssetType(asset.assetType);
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setAssetDailyReportPopup(!assetDailyReportPopup);
  };
  const assetDailyReportSubmitClick = () => {
    let reportData = {
      parentid: selectedParentId,
      userid: username,
      type: userTypeValue,
      assetid: selectedAssetId,
      nutritionRecorded: nutritionRecordedValue,
      attendanceRecorded: attendanceRecordedValue,
      photoUploaded: photoUploadedValue,
    };
    console.log('daily report:', reportData);
    const reportDataStringify = JSON.stringify(reportData);
    MainService.dailyReport(reportDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error('status not supported');
      });
    setAssetDailyReportPopup(!assetDailyReportPopup);
  };
  const assetPatrolClick = (asset) => {
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setPatrolPopup(!patrolPopup);
  };

  const assetPatrolSubmitClick = () => {
    let patrolData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      parentid: selectedParentId,
      userid: username,
      mobilenumber: mobileNumberValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
    };

    const patrolDataStringify = JSON.stringify(patrolData);
    MainService.addAssetPatrolingDetails(patrolDataStringify)
      .then((res) => {
        NotificationManager.success(res['SUCCESS MESSAGE']);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setPatrolPopup(!patrolPopup);
  };

  const assetFeedbackClick = (asset) => {
    setSelectedParentId(asset.solutionId);
    setSelectedAssetId(asset.assetId);
    setSelectedAssetType(asset.assetType);
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };
  const assetFeedbackSubmitClick = () => {
    let addFeedback = {
      assetdeveloperid: selectedParentId,
      assetid: selectedAssetId,
      'how_would_you_rate_your_overall_asset_health?': customerExp,
      'how_satisfied_were_you_with_the_asset_growth?': deliveryExp,
      'how_satisfied_were_you_with_the_asset_activeness?': productExp,
      remark: remarks,
    };
    const addFeedbackData = JSON.stringify(addFeedback);
    MainService.addAssetFeedback(username, addFeedbackData)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };
  const backToHome = () => {
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteReports(false);
    setGridViewSiteIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
    setGridViewAssetIssues(false);
  };
  const backToAssets = () => {
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setGridViewSiteReports(false);
    setGridViewSiteIssues(false);
    setShowBirth(false);
    setShowNutrition(false);
    setGridViewAssetIssues(false);
  };
  const assetEnvClick = (asset) => {
    setSelectedAssetType(asset.assetType);
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedModelType(asset.modelId);
    if (
      selectedModelType === 'Apis-cerana-indica' ||
      selectedModelType === 'EUROPEAN-BEE' ||
      selectedModelType === 'EUROPIAN-BEE'
    ) {
      setNumberofPropertiesValue(4);
    } else if (
      selectedModelType === 'LOHI' ||
      selectedModelType === 'Black-Bengal'
    ) {
      setNumberofPropertiesValue(8);
    }
    setAssetEnvPopup(!assetEnvPopup);
  };
  const assetEnvSubmitClick = () => {
    if (numberofPropertiesValue === 2) {
      let assetEnvData = {
        siteid: selectedAssetId,
        operator: username,
        tenantId: parentId,
        deviceid: selectedAssetId,
        networkid: 'consumer2',
        assetid: selectedAssetId,
        devicetype: selectedAssetType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 2,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
        ],
      };
      setAssetEnvData(assetEnvData);
    } else if (numberofPropertiesValue === 4) {
      let assetEnvData = {
        siteid: selectedAssetId,
        operator: username,
        tenantId: parentId,
        deviceid: selectedAssetId,
        networkid: 'consumer2',
        assetid: selectedAssetId,
        devicetype: selectedAssetType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 4,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
        ],
      };
      setAssetEnvData(assetEnvData);
    } else if (numberofPropertiesValue === 5) {
      let assetEnvData = {
        siteid: selectedAssetId,
        operator: username,
        tenantId: parentId,
        deviceid: selectedAssetId,
        networkid: 'consumer2',
        assetid: selectedAssetId,
        devicetype: selectedAssetType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 5,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
        ],
      };
      setAssetEnvData(assetEnvData);
    } else if (numberofPropertiesValue === 6) {
      let assetEnvData = {
        siteid: selectedAssetId,
        operator: username,
        tenantId: parentId,
        deviceid: selectedAssetId,
        networkid: 'consumer2',
        assetid: selectedAssetId,
        devicetype: selectedAssetType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 6,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
          {
            property: 'CO2 Level (ppm)',
            value: ppmValue,
          },
        ],
      };
      setAssetEnvData(assetEnvData);
    } else if (numberofPropertiesValue === 8) {
      let assetEnvData = {
        siteid: selectedAssetId,
        operator: username,
        tenantId: parentId,
        deviceid: selectedAssetId,
        networkid: 'consumer2',
        assetid: selectedAssetId,
        devicetype: selectedAssetType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 8,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
          {
            property: 'vibration-x',
            value: vibrationXValue,
          },
          {
            property: 'vibration-y',
            value: vibrationYValue,
          },
          {
            property: 'vibration-z',
            value: vibrationZValue,
          },
        ],
      };
      setAssetEnvData(assetEnvData);
    }

    const assetEnvDataStringify = JSON.stringify(assetEnvData);
    MainService.operatorOption(
      assetEnvDataStringify,
      selectedSiteId,
      selectedAssetId,
      'PL',
      parentId,
      username
    )
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetEnvPopup(!assetEnvPopup);
  };
  const assetLocationClick = (asset) => {
    setSelectedAssetType(asset.assetType);
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedModelType(asset.modelId);
    setAssetLocationPopup(!assetLocationPopup);
  };
  const assetLocationSubmitClick = () => {
    let assetLocationData = {
      realm: parentId,
      siteid: selectedSiteId,
      operator: username,
      deviceId: selectedAssetId,
      tenantId: selectedParentId,
      assetId: selectedAssetId,
      geoLocation: {
        lat: latitudeValue,
        lng: longitudeValue,
      },
      ts: getTimeStamp(),
    };
    const assetLocationDataStringify = JSON.stringify(assetLocationData);
    MainService.operatorOption(
      assetLocationDataStringify,
      selectedSiteId,
      selectedAssetId,
      'LOC',
      parentId,
      username
    )
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetLocationPopup(!assetLocationPopup);
  };
  const assetMortalityClick = (asset) => {
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedModelType(asset.modelId);
    //setSelectedAssetGender(asset.gender);
    setAssetMortalityPopup(!assetMortalityPopup);
    const timestamp = Date.now(); // This would be the timestamp you want to format
    //const new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp)

    console.log(Math.floor(timestamp / 1000));
  };
  const assetMortalitySubmitClick = () => {
    const timestamp = Date.now(); // This would be the timestamp you want to format

    console.log(
      new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(timestamp)
    );
    let assetMortalityData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      parentid: selectedParentId,
      userid: username,
      modelid: selectedModelType,
      breedpercentage: breedPercentageValue,
      gender: genderValue,
      birthdate: birthDateValue,
      expireddate: expiryDateValue,
      totalage: totalAgeValue,
      currentvalue: currentValue,
      causeofdeath: causeofDeathValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
      timestamp: timestamp,
    };
    const assetMortalityDataStringify = JSON.stringify(assetMortalityData);
    MainService.addAssetMortality(assetMortalityDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetMortalityPopup(!assetMortalityPopup);
  };

  const siteEnvClick = (site) => {
    setSelectedSiteId(site.resourceid);

    MainService.siteInfo(site.resourceid)
      .then((res) => {
        console.log('site info :', res);
        setSelectedSiteName(res.sitename);
        setSelectedSiteType(res.subdivision);
        if (res.subdivision === 'null') {
          setNumberofPropertiesValue(4);
        } else if (
          res.subdivision === 'Farm' ||
          res.subdivision === 'District'
        ) {
          setNumberofPropertiesValue(5);
        } else if (res.subdivision === 'Hotel') {
          setNumberofPropertiesValue(6);
        } else {
          setNumberofPropertiesValue(4);
        }
      })
      .catch((e) => {
        NotificationManager.error(e);
      });

    setSiteEnvPopup(!siteEnvPopup);
  };
  const siteEnvSubmitClick = () => {
    if (numberofPropertiesValue === 2) {
      let siteEnvData1 = {
        tenantId: parentId,
        operator: username,
        deviceId: selectedSiteId,
        siteid: selectedSiteId,
        networkid: 'consumer2',
        devicetype: selectedSiteType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 2,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
        ],
      };
      //setSiteEnvData(siteEnvData1);
      siteEnvPostData(siteEnvData1);
    } else if (numberofPropertiesValue === 4) {
      let siteEnvData2 = {
        tenantId: parentId,
        operator: username,
        deviceId: selectedSiteId,
        siteid: selectedSiteId,
        networkid: 'consumer2',
        devicetype: selectedSiteType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 4,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
        ],
      };
      //setSiteEnvData(siteEnvData2);
      siteEnvPostData(siteEnvData2);
    } else if (numberofPropertiesValue === 5) {
      const siteEnvData3 = {
        siteid: selectedSiteId,
        networkid: 'consumer2',
        devicetype: selectedSiteType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 5,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
        ],
      };
      //setSiteEnvData(siteEnvData3);
      siteEnvPostData(siteEnvData3);
    } else if (numberofPropertiesValue === 6) {
      let siteEnvData4 = {
        tenantId: parentId,
        operator: username,
        deviceId: selectedSiteId,
        siteid: selectedSiteId,
        networkid: 'consumer2',
        devicetype: selectedSiteType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 6,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
          {
            property: 'CO2 Level (ppm)',
            value: ppmValue,
          },
        ],
      };
      //setSiteEnvData(siteEnvData4);
      siteEnvPostData(siteEnvData4);
    } else if (numberofPropertiesValue === 8) {
      let siteEnvData5 = {
        tenantId: parentId,
        operator: username,
        deviceId: selectedSiteId,
        siteid: selectedSiteId,
        networkid: 'consumer2',
        devicetype: selectedSiteType,
        responsecmd: responseCmdValue,
        updatedat: getTimeStamp(),
        NumberofProperties: 8,
        properties: [
          {
            property: 'latitude',
            value: latitudeValue,
          },
          {
            property: 'longitude',
            value: longitudeValue,
          },
          {
            property: 'temperature',
            value: temperatureValue,
          },
          {
            property: 'RH',
            value: RHValue,
          },
          {
            property: 'sound',
            value: soundValue,
          },
          {
            property: 'vibration-x',
            value: vibrationXValue,
          },
          {
            property: 'vibration-y',
            value: vibrationYValue,
          },
          {
            property: 'vibration-z',
            value: vibrationZValue,
          },
        ],
      };
      //setSiteEnvData(siteEnvData5);
      siteEnvPostData(siteEnvData5);
    }
  };
  const siteEnvPostData = (siteEnvDataAll) => {
    console.log('site env data1:', siteEnvDataAll);
    const siteEnvDataStringify = JSON.stringify(siteEnvDataAll);
    MainService.siteEnv(
      siteEnvDataStringify,
      selectedSiteId,
      parentId,
      username
    )
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setSiteEnvPopup(!siteEnvPopup);
  };

  const assetUpdateStatusClick = (assetId, status) => {
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(status);
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const assetUpdateStatusSubmitClick = () => {
    const assetStatusData = {
      status: updateAssetStatusValue,
    };
    const assetStatusDataStringify = JSON.stringify(assetStatusData);

    MainService.assetStatusUpdate(selectedAssetId, assetStatusDataStringify)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const handleClose = () => {};
  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRoleResourceAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRoleResourceAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched('');
    requestFarmGridSearch(farmgridsearched);
  };

  /* Nutrition Api's */

  const nutritionButtonClickHandler = (asset) => {
    setAddNutritionAssetid(asset.assetId);

    setGridViewSiteReports(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetIssues(false);
    setShowNutrition(true);
    setShowBirth(false);
    getNutritionHandler();
  };

  const getNutritionHandler = () => {
    setIsLoading(true);
    MainService.getNutrition(username)
      .then((res) => {
        setIsLoading(false);
        setNutritions(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const addNutritionHandler = () => {
    const addnutrition = {
      assetid: getAddNutritionAssetid,
      assetfriendlyname: getAddNutritionAssetFriendlyName,
      parentid: parentId,
      userid: getAddNutritionUserid,
      feedtype: getAddNutritionFeedType,
      schedule: getAddNutritionSchedule,
      latitude: getAddNutritionLatitude,
      longitude: getAddNutritionLongitude,
      altitude: getAddNutritionAltitude,
      description: getAddNutritionDescription,
    };
    const addnutritionstr = JSON.stringify(addnutrition);

    MainService.addNutrition(addnutritionstr)
      .then((res) => {
        setAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success('nutrition added');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const deleteNutririonAssetid = (assetid) => {
    setDeleteNutritionAssetid(assetid);
    setDeleteNutrition(true);
  };

  const deleteNutritionHandler = () => {
    MainService.deleteNutrition(getDeleteNutritionAssetid)
      .then((res) => {
        setDeleteNutrition(false);
        getNutritionHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateNutririonInfo = (assetinfo) => {
    setUpdateAddNutritionAssetid(assetinfo.assetid);
    setUpdateAddNutritionAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateAddNutritionUserid(assetinfo.userid);
    setUpdateAddNutritionFeedType(assetinfo.feedtype);
    setUpdateAddNutritionSchedule(assetinfo.schedule);
    setUpdateAddNutritionLatitude(assetinfo.latitude);
    setUpdateAddNutritionLongitude(assetinfo.longitude);
    setUpdateAddNutritionAltitude(assetinfo.altitude);
    setUpdateAddNutritionDescription(assetinfo.description);
    setUpdateAddNutritionExistingFeedType(assetinfo.feedtype);

    setUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
  };

  const updateNutritionHandler = () => {
    const updateNutrition = {
      assetid: getUpdateAddNutritionAssetid,
      assetfriendlyname: getUpdateAddNutritionAssetFriendlyName,
      feedtype: getUpdateAddNutritionFeedType,
      schedule: getUpdateAddNutritionSchedule,
      latitude: getUpdateAddNutritionLatitude,
      longitude: getUpdateAddNutritionLongitude,
      altitude: getUpdateAddNutritionAltitude,
      description: getUpdateAddNutritionDescription,
    };
    const updateNutritionStr = JSON.stringify(updateNutrition);

    MainService.updateNutrition(updateNutritionStr)
      .then((res) => {
        setUpdateAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success('nutrition info updated');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Nutrition Api's */
  return (
    <div>
      <React.Fragment>
        <div className='container' id='mainContentList'>
          {gridViewSiteIssues ? (
            <Fab
              color='primary'
              size={'large'}
              aria-label='add'
              title='Create Site Issue'
              className={classes.fab}
              onClick={() => {
                setSiteIssuePopup(!siteIssuePopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
          {gridViewAssetIssues ? (
            <Fab
              color='primary'
              size={'large'}
              aria-label='add'
              title='Create Asset Issue'
              className={classes.fab}
              onClick={() => {
                setAssetIssuePopup(!assetIssuePopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
          <div className='topHeadersWrapper'>
            <div className='topHeaders'>
              <Button
                startIcon={<ListIcon />}
                size='large'
                onClick={sitesListViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size='large'
                onClick={sitesGridViewClick}
              ></Button>
            </div>
          </div>
          {/* Assets */}
          {listViewAssets === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <br />
              {roleResourceAssetsList.length > 0 ? (
                <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableRow align='center'>
                          <TableCell align='center' colSpan={24}>
                            List Of "{resourceId}" Assets
                          </TableCell>
                        </TableRow>
                        <TableRow align='center'>
                          <TableCell align='center' colSpan={1}>
                            Asset Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Solution Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Site Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Category
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Asset Type
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Model Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Latitude
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Longitude
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Asset Status
                          </TableCell>
                          <TableCell align='center' colSpan={15}>
                            Operate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {roleResourceAssetsList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((operators, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align='center'>
                                  {operators.assetId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.solutionId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.siteId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.category}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.assetType}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.modelId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.latitude}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.longitude}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.assetStatus}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      attendanceClick(operators);
                                    }}
                                  >
                                    Attendance
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetIssueClick(operators);
                                    }}
                                  >
                                    Asset Issue
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetHealthClick(operators);
                                    }}
                                  >
                                    Asset Health
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetReportClick(operators);
                                    }}
                                  >
                                    Asset Report
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      birthInfoButtonClickHandler(operators);
                                    }}
                                  >
                                    Asset Birth
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      nutritionButtonClickHandler(operators);
                                    }}
                                  >
                                    Asset Nutrition
                                  </Button>
                                </TableCell>

                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetPatrolClick(operators);
                                    }}
                                  >
                                    Patrol
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetFeedbackClick(operators);
                                    }}
                                  >
                                    Asset Feedback
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      uploadPhotoClick(operators);
                                    }}
                                  >
                                    Upload Photo
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetEnvClick(operators);
                                    }}
                                  >
                                    Asset Env
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetLocationClick(operators);
                                    }}
                                  >
                                    Asset Location
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetMortalityClick(operators);
                                    }}
                                  >
                                    Asset Mortality
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetUpdateStatusClick(
                                        operators.assetId,
                                        operators.assetStatus
                                      );
                                    }}
                                  >
                                    Update Status
                                  </Button>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component='div'
                    count={roleResourceAssetsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No Operators Found</h4>
              )}
            </Box>
          ) : null}

          {gridViewAssets === true ? (
            <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              <h4>List Of "{resourceId}" Assets</h4>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {roleResourceAssetsList.length > 0 ? (
                  <div className='cardStyleForDashboardItems'>
                    {roleResourceAssetsList.map((ope, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Asset Id:</b>
                            <b>{ope.assetId}</b>
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {ope.solutionId}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {ope.siteId}
                          </p>
                          <p>
                            <b>Category:</b>
                            {ope.category}
                          </p>
                          <p>
                            <b>Asset Type:</b>
                            {ope.assetType}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {ope.modelId}
                          </p>
                          <p>
                            <b>Latitude:</b>
                            {ope.latitude}
                          </p>
                          <p>
                            <b>Longitude:</b>
                            {ope.longitude}
                          </p>
                          <p>
                            <b>Asset Status:</b>
                            {ope.assetStatus}
                          </p>
                          <div>
                            <h4 className='cardactiontitle'>Operate</h4>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                attendanceClick(ope);
                              }}
                            >
                              Attendance
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetIssueClick(ope);
                              }}
                            >
                              Asset Issue
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetHealthClick(ope);
                              }}
                            >
                              Asset Health
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetReportClick(ope);
                              }}
                            >
                              Asset Report
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                birthInfoButtonClickHandler(ope);
                              }}
                            >
                              Asset Birth
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                nutritionButtonClickHandler(ope);
                              }}
                            >
                              Asset Nutrition
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetPatrolClick(ope);
                              }}
                            >
                              Patrol
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetFeedbackClick(ope);
                              }}
                            >
                              Asset Feedback
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                uploadPhotoClick(ope);
                              }}
                            >
                              Upload Photo
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetEnvClick(ope);
                              }}
                            >
                              Asset Env
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetLocationClick(ope);
                              }}
                            >
                              Asset Location
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetMortalityClick(ope);
                              }}
                            >
                              Asset Mortality
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetUpdateStatusClick(
                                  ope.assetId,
                                  ope.assetStatus
                                );
                              }}
                            >
                              Update Status
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Assets Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Assets */}
          {/* Sites */}
          {listViewSites === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <div className='MuiGrid-root'>
                <div className='cardStyleForDashboardItems'>
                  <div className='cardStyleForDashboardItems'>
                    {roleResourcesList ? (
                      <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: 350 }}>
                          <Table stickyHeader aria-label='sticky table'>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={10} align='center'>
                                  List Of Sites
                                </TableCell>
                              </TableRow>
                              <TableRow align='center'>
                                <TableCell align='center' colSpan={1}>
                                  Site Id
                                </TableCell>
                                <TableCell align='center' colSpan={1}>
                                  Site Name
                                </TableCell>

                                <TableCell align='center' colSpan={4}>
                                  View Assets
                                </TableCell>
                                <TableCell align='center' colSpan={5}>
                                  Option
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {roleResourcesList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((site, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell align='center'>
                                        {site.resourceid}
                                      </TableCell>
                                      <TableCell align='center'>
                                        {site.resourcename}
                                      </TableCell>

                                      <TableCell align='center'></TableCell>
                                      <TableCell align='center'>
                                        <Button
                                          startIcon={<ListIcon />}
                                          size='large'
                                          onClick={() => {
                                            assetsListViewClick(
                                              site.resourceid
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align='center'>
                                        <Button
                                          startIcon={<GridIcon />}
                                          size='large'
                                          onClick={() => {
                                            assetsGridViewClick(
                                              site.resourceid
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align='center'></TableCell>
                                      <TableCell align='center'></TableCell>
                                      <TableCell align='center'>
                                        <Button
                                          size='small'
                                          variant='contained'
                                          onClick={() => {
                                            siteIssuesClick(site.resourceid);
                                          }}
                                        >
                                          Site Issue
                                        </Button>
                                      </TableCell>
                                      <TableCell align='center'>
                                        <Button
                                          size='small'
                                          variant='contained'
                                          onClick={() => {
                                            siteReportsClick(site);
                                          }}
                                        >
                                          Site Report
                                        </Button>
                                      </TableCell>
                                      <TableCell align='center'>
                                        <Button
                                          size='small'
                                          variant='contained'
                                          onClick={() => {
                                            siteEnvClick(site);
                                          }}
                                        >
                                          Site Env
                                        </Button>
                                      </TableCell>
                                      <TableCell align='center'></TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component='div'
                          count={roleResourcesList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>No Site List Found</h4>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          ) : null}

          {gridViewSites === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Sites</h3>
              <br />
              <div className='cardStyleForDashboardItems'>
                {roleResourcesList.length > 0 ? (
                  <div className='cardStyleForDashboardItems'>
                    {roleResourcesList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {site.resourceid}
                          </p>
                          <p>
                            <b>Site Name:</b>
                            {site.resourcename}
                          </p>
                          <h4 className='cardactiontitle'>View Assets</h4>
                          <div>
                            <Button
                              title='Assets List View'
                              startIcon={<ListIcon />}
                              size='large'
                              onClick={() => {
                                assetsListViewClick(site.resourceid);
                              }}
                            ></Button>

                            <Button
                              title='Assets Grid View'
                              startIcon={<GridIcon />}
                              size='large'
                              onClick={() => {
                                assetsGridViewClick(site.resourceid);
                              }}
                            ></Button>
                          </div>
                          <h4 className='cardactiontitle'>Options</h4>
                          <div>
                            <Button
                              size='small'
                              variant='contained'
                              onClick={() => {
                                siteIssuesClick(site.resourceid);
                              }}
                            >
                              Site Issue
                            </Button>{' '}
                            &nbsp;
                            <Button
                              size='small'
                              variant='contained'
                              onClick={() => {
                                siteReportsClick(site);
                              }}
                            >
                              Site Report
                            </Button>
                          </div>
                          &nbsp;
                          <div>
                            <Button
                              size='small'
                              variant='contained'
                              onClick={() => {
                                siteEnvClick(site);
                              }}
                            >
                              Site Env
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Sites */}
          {/* Site Issues */}
          {gridViewSiteIssues === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Site Issues</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {siteIssuesList ? (
                  <div className='cardStyleForDashboardItems'>
                    {siteIssuesList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Operator Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Issues Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Issues */}

          {/* Site Reports */}

          {/* {gridViewSiteReports === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of Site Reports</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className="cardStyleForDashboardItems">
                {siteReportsList.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {siteReportsList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {site.assetid}
                          </p>
                          <p>
                            <b>Operational Status:</b>
                            {site.operationalstatus}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.reportdescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Reports Found.</h4>
                )}
              </div>
            </Box>
          ) : null} */}
          {/* End of Site Reports */}

          {/* Asset Issues */}
          {gridViewAssetIssues === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Asset Issues</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToAssets();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {assetIssuesList ? (
                  <div className='cardStyleForDashboardItems'>
                    {assetIssuesList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Asset Id:</b>
                            {selectedAssetId}
                          </p>
                          <p>
                            <b>Asset Type:</b>
                            {selectedAssetType}
                          </p>
                          <p>
                            <b>Operator Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timeStamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Issues Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Issues */}
          {/************************ Birth ****************************/}
          {showBirth === true ? (
            <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
              <Fab
                color='primary'
                size={'large'}
                aria-label='add'
                title='Add Asset Birth'
                className={classes.fab}
                onClick={() => {
                  setNewBirthInfoPopup(!showNewBirthInfoPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className='topHeadersWrapper'>
                <div className='topHeaders'>
                  <Button
                    style={{ marginRight: '10px' }}
                    color='primary'
                    className='registerBtn'
                    variant='contained'
                    onClick={() => {
                      backToAssets();
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className='MuiGrid-root'>
                <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className='cardStyleForDashboardItems'>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className='cardStyleForDashboardItems'>
                      <h4 style={{ width: '100%', textAlign: 'center' }}>
                        VIEW BIRTH DETAILS
                      </h4>
                      {getBirthInfos.length > 0 ? (
                        <div className='cardStyleForDashboardItems'>
                          {getBirthInfos.map((assets, index) => (
                            <div
                              key={index}
                              className='cardStyleForDashboardItemsBody'
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {assets.assetid}
                                </p>
                                <p>
                                  <b>Male Parent:</b>
                                  {assets.maleparent}
                                </p>
                                <p>
                                  <b>Female Parent:</b>
                                  {assets.femaleparent}
                                </p>
                                <p>
                                  <b>D.O.B:</b>
                                  {assets.dob}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {assets.timestamp}
                                </p>
                                <div style={{ width: '100%', margin: '5px' }}>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => updateBirthInfoData(assets)}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() =>
                                      deleteNewBirthInfoAssetId(assets.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW BIRTH DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Birth ****************************/}
          {/************************ Nutrition ****************************/}
          {showNutrition === true ? (
            <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
              <Fab
                color='primary'
                size={'large'}
                aria-label='add'
                title='Add Nutrition'
                className={classes.fab}
                onClick={() => {
                  setAddNutritionPopup(!showAddNutritionPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className='topHeadersWrapper'>
                <div className='topHeaders'>
                  <Button
                    style={{ marginRight: '10px' }}
                    color='primary'
                    className='registerBtn'
                    variant='contained'
                    onClick={() => {
                      backToAssets();
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className='MuiGrid-root'>
                <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className='cardStyleForDashboardItems'>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className='cardStyleForDashboardItems'>
                      <h4 style={{ width: '100%', textAlign: 'center' }}>
                        VIEW NUTRITION DETAILS
                      </h4>
                      {getNutritions.length > 0 ? (
                        <div className='cardStyleForDashboardItems'>
                          {getNutritions.map((nutrition, index) => (
                            <div
                              key={index}
                              className='cardStyleForDashboardItemsBody'
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {nutrition.assetid}
                                </p>
                                <p>
                                  <b>Feed Type:</b>
                                  {nutrition.feedtype}
                                </p>
                                <p>
                                  <b>Schedule:</b>
                                  {nutrition.schedule}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {nutrition.timestamp}
                                </p>
                                <div style={{ width: '100%', margin: '5px' }}>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() =>
                                      updateNutririonInfo(nutrition)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() =>
                                      deleteNutririonAssetid(nutrition.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>NUTRITION DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Nutrition ****************************/}
          {/* Create Site Report */}
          <Dialog
            fullScreen={fullScreen}
            open={dailyReportPopup}
            onClose={() => {
              setDailyReportPopup(!dailyReportPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Site Report'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Parent Id:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>User Id:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Type:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={userTypeValue}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Nutrition Recorded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={nutritionRecordedValue}
                  onChange={(e) => {
                    setNutritionRecordedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Attendance Recorded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={attendanceRecordedValue}
                  onChange={(e) => {
                    setAttendanceRecordedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Photo Uploaded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={photoUploadedValue}
                  onChange={(e) => {
                    setPhotoUploadedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  dailyReportSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setDailyReportPopup(!dailyReportPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Create Site Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={siteIssuePopup}
            onClose={() => {
              setSiteIssuePopup(!siteIssuePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Site Issue'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Solution Id:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Operator Id:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  submitSiteIssueClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setSiteIssuePopup(!siteIssuePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={attendancePopup}
            onClose={() => {
              setAttendancePopup(!attendancePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Attendance Location'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Parent Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>User Id:</InputLabel>
                <Input id='siteId' name='siteId' type='text' value={username} />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='number'
                  pattern='[0-9]*'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='number'
                  pattern='[0-9]*'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='number'
                  pattern='[0-9]*'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetAttendanceSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAttendancePopup(!attendancePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Environment  */}
          <Dialog
            // fullScreen={fullScreen}
            open={assetEnvPopup}
            onClose={() => {
              setAssetEnvPopup(!assetEnvPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Environment'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Asset Type:</InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedAssetType}
                />
              </FormControl>

              {numberofPropertiesValue === 2 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 4 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 5 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 6 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>CO2 Level:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={ppmValue}
                      onChange={(e) => {
                        setPpmValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 8 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-X:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationXValue}
                      onChange={(e) => {
                        setVibrationXValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-Y:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationYValue}
                      onChange={(e) => {
                        setVibrationYValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-Z:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationZValue}
                      onChange={(e) => {
                        setVibrationZValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetEnvSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetEnvPopup(!assetEnvPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Location  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetLocationPopup}
            onClose={() => {
              setAssetLocationPopup(!assetLocationPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Location'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='number'
                  pattern='[0-9]*'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='number'
                  pattern='[0-9]*'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetLocationSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetLocationPopup(!assetLocationPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Asset Mortality  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetMortalityPopup}
            onClose={() => {
              setAssetMortalityPopup(!assetMortalityPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Mortality'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              {/* <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Gender:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={selectedAssetGender}
                  
                />
              </FormControl> */}

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='medicineDate'>Cause of Death:</InputLabel>
                <Input
                  id='medicineDate'
                  name='medicineDate'
                  type='text'
                  value={causeofDeathValue}
                  onChange={(e) => {
                    setCauseofDeathValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetMortalitySubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetMortalityPopup(!assetMortalityPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Asset Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={assetIssuePopup}
            onClose={() => {
              setAssetIssuePopup(!assetIssuePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Issue'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Parent Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Operator Id:</InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedSiteId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset type:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetType}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetIssueSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetIssuePopup(!assetIssuePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Health */}
          <Dialog
            fullScreen={fullScreen}
            open={assetHealthPopup}
            onClose={() => {
              setAssetHealthPopup(!assetHealthPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Health'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  disabled
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetFriendlyName'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  disabled
                  id='assetFriendlyName'
                  name='assetFriendlyName'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Type:</InputLabel>
                <Input
                  disabled
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetType}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetDeveloperId'>
                  AssetDeveloper Id:
                </InputLabel>
                <Input
                  id='assetDeveloperId'
                  name='assetDeveloperId'
                  type='text'
                  value={username}
                />
              </FormControl>
              {(selectedAssetType === 'Sheep' ||
                selectedAssetType === 'Goat' ||
                selectedAssetType === 'Milking-Cow') && (
                <div>
                  <InputLabel htmlFor='medicineDate'>Medicine Date:</InputLabel>
                  <Input
                    fullWidth={true}
                    className='loginFields'
                    id='medicineDate'
                    name='medicineDate'
                    type='date'
                    placeholder='25-12-2022'
                    value={medicinedateValue}
                    onChange={(e) => {
                      setMedicineDateValue(e.target.value);
                    }}
                  />

                  <InputLabel htmlFor='vaccinationDate'>
                    Vaccination Date:
                  </InputLabel>
                  <Input
                    fullWidth={true}
                    className='loginFields'
                    id='vaccinationDate'
                    name='vaccinationDate'
                    type='date'
                    value={vaccinationdateValue}
                    onChange={(e) => {
                      setVaccinationDateValue(e.target.value);
                    }}
                  />

                  <InputLabel htmlFor='treatmentDate'>
                    Treatment Date:
                  </InputLabel>
                  <Input
                    fullWidth={true}
                    className='loginFields'
                    id='treatmentDate'
                    name='treatmentDate'
                    type='date'
                    value={treatmentDateValue}
                    onChange={(e) => {
                      setTreatmentDateValue(e.target.value);
                    }}
                  />
                </div>
              )}

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='examine'>Examine:</InputLabel>
                <Input
                  id='examine'
                  name='examine'
                  type='text'
                  value={examineValue}
                  onChange={(e) => {
                    setExamineValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='surgicalProcedure'>
                  Surgical Procedure:
                </InputLabel>
                <Input
                  id='surgicalProcedure'
                  name='surgicalProcedure'
                  type='text'
                  value={suricalProcedureValue}
                  onChange={(e) => {
                    setSuricalProcedureValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='vaccinationType'>
                  Vaccination Type:
                </InputLabel>
                <Input
                  id='vaccinationType'
                  name='vaccinationType'
                  type='text'
                  value={vaccinationTypeValue}
                  onChange={(e) => {
                    setVaccinationTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='medicineType'>Medicine Type:</InputLabel>
                <Input
                  id='medicineType'
                  name='medicineType'
                  type='text'
                  value={medicineTypeValue}
                  onChange={(e) => {
                    setMedicineTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='diseaseType'>Disease Type:</InputLabel>
                <Input
                  id='diseaseType'
                  name='diseaseType'
                  type='text'
                  value={diseaseTypeValue}
                  onChange={(e) => {
                    setDiseaseTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='number'
                  pattern='[0-9]*'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='number'
                  pattern='[0-9]*'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='number'
                  pattern='[0-9]*'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetHealthSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetHealthPopup(!assetHealthPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Report */}
          <Dialog
            fullScreen={fullScreen}
            open={assetDailyReportPopup}
            onClose={() => {
              setAssetDailyReportPopup(!assetDailyReportPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Asset Report'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Parent Id:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>User Id:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset Type:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetType}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>User Type:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={userTypeValue}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Nutrition Recorded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={nutritionRecordedValue}
                  onChange={(e) => {
                    setNutritionRecordedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Attendance Recorded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={attendanceRecordedValue}
                  onChange={(e) => {
                    setAttendanceRecordedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <FormLabel id='readPermission'>Photo Uploaded :</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='readPermission'
                  name='readPermissionGroup'
                  value={photoUploadedValue}
                  onChange={(e) => {
                    setPhotoUploadedValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetDailyReportSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetDailyReportPopup(!assetDailyReportPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Patrol */}
          <Dialog
            fullScreen={fullScreen}
            open={patrolPopup}
            onClose={() => {
              setPatrolPopup(!patrolPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Patrol'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Parent Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Mobile Number:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={mobileNumberValue}
                  onChange={(e) => {
                    setMobileNumberValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>User Id:</InputLabel>
                <Input id='siteId' name='siteId' type='text' value={username} />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='number'
                  pattern='[0-9]*'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='number'
                  pattern='[0-9]*'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='number'
                  pattern='[0-9]*'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetPatrolSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setPatrolPopup(!patrolPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Asset Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={assetFeedbackPopup}
            onClose={() => {
              setAssetFeedbackPopup(!assetFeedbackPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Asset Feedback'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>UserName:</InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <Typography variant='body2' component='span'>
                how would you rate overall asset health?: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='customer-feedback'
                    value={customerRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextCustomer}
                    onChange={(event, customerRate) => {
                      setCustomerRate(customerRate);
                      setcustomerExp(labels[customerRate]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setCustomerRateHover(customerRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {customerRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          customerRateHover !== -1
                            ? customerRateHover
                            : customerRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate asset activeness?: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='product-feedback'
                    value={productRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextProduct}
                    onChange={(event, productRate) => {
                      setProductRate(productRate);
                      setProductExp(labels[productRate]);
                    }}
                    onChangeActive={(event, productRateHover) => {
                      setProductRateHover(productRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {productRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          productRateHover !== -1
                            ? productRateHover
                            : productRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate asset growth?: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='delivery-feedback'
                    value={value}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, value) => {
                      setValue(value);
                      setDeliveryExp(labels[value]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setHover(hover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </Typography>
              {/* <Typography variant="body2" component="span">
                how would you rate your overall experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="customer-feedback"
                    value={customerRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextCustomer}
                    onChange={(event, customerRate) => {
                      setCustomerRate(customerRate);
                      setcustomerExp(labels[customerRate]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setCustomerRateHover(customerRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {customerRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          customerRateHover !== -1
                            ? customerRateHover
                            : customerRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your product experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="product-feedback"
                    value={productRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextProduct}
                    onChange={(event, productRate) => {
                      setProductRate(productRate);
                      setProductExp(labels[productRate]);
                    }}
                    onChangeActive={(event, productRateHover) => {
                      setProductRateHover(productRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {productRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          productRateHover !== -1
                            ? productRateHover
                            : productRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your delivery experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="delivery-feedback"
                    value={value}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, value) => {
                      setValue(value);
                      setDeliveryExp(labels[value]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setHover(hover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your customer Support: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="support-feedback"
                    value={supportRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextSupport}
                    onChange={(event, supportRate) => {
                      setSupportRate(supportRate);
                      setSupportExp(labels[supportRate]);
                    }}
                    onChangeActive={(event, supportRateHover) => {
                      setSupportRateHover(supportRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {supportRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          supportRateHover !== -1
                            ? supportRateHover
                            : supportRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your other experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="others-feedback"
                    value={othersRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextOthers}
                    onChange={(event, othersRate) => {
                      setOthersRate(othersRate);
                      setOthersExp(labels[othersRate]);
                    }}
                    onChangeActive={(event, othersRateHover) => {
                      setOthersRateHover(othersRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {othersRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          othersRateHover !== -1 ? othersRateHover : othersRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography> */}
              <br />
              <Typography variant='body2' component='span'>
                Remarks: <br />
                <TextareaAutosize
                  minRows={5}
                  aria-label='minimum height'
                  placeholder='Any remarks'
                  style={{ minWidth: '260px' }}
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetFeedbackSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetFeedbackPopup(!assetFeedbackPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Site Environment  */}
          <Dialog
            fullScreen={fullScreen}
            open={siteEnvPopup}
            onClose={() => {
              setSiteEnvPopup(!siteEnvPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Site Environment'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedSiteId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Site Type:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedSiteType}
                />
              </FormControl>

              {numberofPropertiesValue === 2 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 4 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 5 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 6 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>CO2 Level:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={ppmValue}
                      onChange={(e) => {
                        setPpmValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
              {numberofPropertiesValue === 8 && (
                <div>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='number'
                      pattern='[0-9]*'
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='number'
                      pattern='[0-9]*'
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='latitude'>Temperature:</InputLabel>
                    <Input
                      id='latitude'
                      name='latitude'
                      type='text'
                      value={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Humidity:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={RHValue}
                      onChange={(e) => {
                        setRHValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Sound:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={soundValue}
                      onChange={(e) => {
                        setSoundValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-X:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationXValue}
                      onChange={(e) => {
                        setVibrationXValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-Y:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationYValue}
                      onChange={(e) => {
                        setVibrationYValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='longitude'>Vibration-Z:</InputLabel>
                    <Input
                      id='longitude'
                      name='longitude'
                      type='text'
                      value={vibrationZValue}
                      onChange={(e) => {
                        setVibrationZValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  siteEnvSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setSiteEnvPopup(!siteEnvPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Update Asset Status */}
          <Dialog
            fullScreen={fullScreen}
            open={assetUpdateStatusPopup}
            onClose={handleClose}
            aria-labelledby='assetReport'
          >
            <DialogTitle id='assetReport'>{'Update Asset Status'}</DialogTitle>
            <DialogContent>
              <h4>Update Asset Status </h4>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='status'>Existing Status:</p>
                <Input
                  type='text'
                  value={selectedExistingStatus}
                  /* onChange={(e) => {
                    setSelectedExistingStatus(e.target.value);
                  }} */
                />
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Update Status</InputLabel>
                <Select
                  id='selectedModel'
                  value={updateAssetStatusValue}
                  onChange={(event) =>
                    setUpdateAssetStatusValue(event.target.value)
                  }
                  label='Select Model'
                >
                  <MenuItem key='in-deprecation' value='in-deprecation'>
                    in-deprecation
                  </MenuItem>
                  <MenuItem key='lost' value='lost'>
                    lost
                  </MenuItem>
                  <MenuItem key='damaged' value='damaged'>
                    damaged
                  </MenuItem>
                  <MenuItem key='under-maintenance' value='under-maintenance'>
                    under-maintenance
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetUpdateStatusSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* New Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewBirthInfoPopup}
            aria-labelledby='craete Consumer User'
          >
            <DialogTitle id='createConsumerUser'>
              {'New Birth Info'}
            </DialogTitle>
            <DialogContent>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewBirthAssetid}
                    onChange={(e) => setNewBirthAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetid'>Asset ID :</InputLabel>
                <Input
                  id='assetid'
                  name='assetid'
                  type='text'
                  value={getNewBirthAssetid}
                  // onChange={(e) => {
                  //   setNewBirthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='friendlyName'>
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id='friendlyName'
                  name='friendlyName'
                  type='text'
                  value={getNewBirthAssetFriendlyName}
                  onChange={(e) => {
                    setNewBirthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='userid'>User ID :</InputLabel>
                <Input
                  disabled
                  id='userid'
                  name='userid'
                  type='text'
                  value={getNewBirthUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='Latitude'>User Name :</InputLabel>
                <Input
                  id='Latitude'
                  name='Latitude'
                  type='text'
                  value={getNewBirthUserName}
                  onChange={(e) => {
                    setNewBirthUserName(e.target.value);
                  }}
                />
              </FormControl>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site ID</InputLabel>
                  <Select
                    id="sitename"
                    value={getNewBirthSiteid}
                    onChange={(e) => setNewBirthSiteidHandler(e)}
                    label="sitename"
                  >
                    {roleResourcesList &&
                      roleResourcesList.map((sites, index) => (
                        <MenuItem key={`${index}`} value={sites.resourceid}>
                          {sites.resourcename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteid'>Site ID :</InputLabel>
                <Input
                  id='siteid'
                  name='siteid'
                  type='text'
                  value={getNewBirthSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='sitename'>Site Name :</InputLabel>
                <Input
                  id='sitename'
                  name='sitename'
                  type='text'
                  value={getNewBirthSiteName}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='lotid'>Lot ID :</InputLabel>
                <Input
                  id='lotid'
                  name='lotid'
                  type='text'
                  value={getNewBirthLotid}
                  onChange={(e) => {
                    setNewBirthLotid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='lotname'>Lot Name :</InputLabel>
                <Input
                  id='lotname'
                  name='lotname'
                  type='text'
                  value={getNewBirthLotName}
                  onChange={(e) => {
                    setNewBirthLotName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetType'>Legacy Asset Type :</InputLabel>
                <Input
                  id='assetType'
                  name='assetType'
                  type='text'
                  value={getNewBirthLegacyAssetType}
                  onChange={(e) => {
                    setNewBirthLegacyAssetType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelid'>Model ID :</InputLabel>
                <Input
                  id='modelid'
                  name='modelid'
                  type='text'
                  value={getNewBirthModelid}
                  onChange={(e) => {
                    setNewBirthModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelname'>
                  Model Friendly Name :
                </InputLabel>
                <Input
                  id='modelname'
                  name='modelname'
                  type='text'
                  value={getNewBirthModelFriendlyName}
                  onChange={(e) => {
                    setNewBirthModelFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modeltype'>Model Type :</InputLabel>
                <Input
                  id='modeltype'
                  name='modeltype'
                  type='text'
                  value={getNewBirthModelType}
                  onChange={(e) => {
                    setNewBirthModelType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelurl'>Model URL :</InputLabel>
                <Input
                  id='modelurl'
                  name='modelurl'
                  type='text'
                  value={getNewBirthModelURL}
                  onChange={(e) => {
                    setNewBirthModelURL(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                {/* <InputLabel htmlFor="dob">DOB :</InputLabel> */}
                <Input
                  id='dob'
                  name='dob'
                  type='date'
                  value={getNewBirthDOB}
                  onChange={(e) => {
                    setNewBirthDOB(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='gender'>Gender :</InputLabel>
                <Input
                  id='gender'
                  name='gender'
                  type='text'
                  value={getNewBirthGender}
                  onChange={(e) => {
                    setNewBirthGender(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='maleparent'>Male Parent :</InputLabel>
                <Input
                  id='maleparent'
                  name='maleparent'
                  type='text'
                  value={getNewBirthMaleParent}
                  onChange={(e) => {
                    setNewBirthMaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='femaleparent'>Female Parent :</InputLabel>
                <Input
                  id='femaleparent'
                  name='femaleparent'
                  type='text'
                  value={getNewBirthFemaleParent}
                  onChange={(e) => {
                    setNewBirthFemaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='parentmodelid'>
                  Parent Model Id :
                </InputLabel>
                <Input
                  id='parentmodelid'
                  name='parentmodelid'
                  type='text'
                  value={getNewBirthParentModelid}
                  onChange={(e) => {
                    setNewBirthParentModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='text'
                  value={getNewBirthLatitude}
                  onChange={(e) => {
                    setNewBirthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='text'
                  value={getNewBirthLongitude}
                  onChange={(e) => {
                    setNewBirthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude :</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='text'
                  value={getNewBirthAltitude}
                  onChange={(e) => {
                    setNewBirthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='address'>Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewBirthDescription}
                  onChange={(e) => {
                    setNewBirthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  createNewBirthInfo();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setNewBirthInfoPopup(!showNewBirthInfoPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Birth Info */}
          {/* Delete Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={getDeleteBirthInfoPopup}
            aria-labelledby='craete Consumer User'
          >
            <DialogTitle id='createConsumerUser'>
              {'Delete Birth Details'}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  deleteBirthInfoAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Birth Info */}
          {/* Update Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateBirthInfoPopup}
            aria-labelledby='craete Consumer User'
          >
            <DialogTitle id='createConsumerUser'>
              {'Update Birth Info'}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetid'>Asset ID :</InputLabel>
                <Input
                  id='assetid'
                  name='assetid'
                  type='text'
                  value={getUpdateNewBirthAssetid}
                  // onChange={(e) => {
                  //   setUpdateNewBirthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='friendlyName'>
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id='friendlyName'
                  name='friendlyName'
                  type='text'
                  value={getUpdateNewBirthAssetFriendlyName}
                  onChange={(e) => {
                    setUpdateNewBirthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='userid'>User ID :</InputLabel>
                <Input
                  disabled
                  id='userid'
                  name='userid'
                  type='text'
                  value={getUpdateNewBirthUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='Latitude'>User Name :</InputLabel>
                <Input
                  id='Latitude'
                  name='Latitude'
                  type='text'
                  value={getUpdateNewBirthUserName}
                  // onChange={(e) => {
                  //   setNewBirthUserName(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Site ID</InputLabel>
                <Select
                  id='sitename'
                  value={getUpdateNewBirthSiteid}
                  onChange={(e) => setNewBirthSiteidHandler(e)}
                  label='sitename'
                >
                  {roleResourcesList &&
                    roleResourcesList.map((sites, index) => (
                      <MenuItem key={`${index}`} value={sites.resourceid}>
                        {sites.resourcename}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='userid'>Site Name :</InputLabel>
                <Input
                  disabled
                  id='userid'
                  name='userid'
                  type='text'
                  value={getUpdateNewBirthSiteName}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='lotid'>Lot ID :</InputLabel>
                <Input
                  id='lotid'
                  name='lotid'
                  type='text'
                  value={getUpdateNewBirthLotid}
                  onChange={(e) => {
                    setUpdateNewBirthLotid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='lotname'>Lot Name :</InputLabel>
                <Input
                  id='lotname'
                  name='lotname'
                  type='text'
                  value={getUpdateNewBirthLotName}
                  onChange={(e) => {
                    setUpdateNewBirthLotName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetType'>Legacy Asset Type :</InputLabel>
                <Input
                  id='assetType'
                  name='assetType'
                  type='text'
                  value={getUpdateNewBirthLegacyAssetType}
                  onChange={(e) => {
                    setUpdateNewBirthLegacyAssetType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelid'>Model ID :</InputLabel>
                <Input
                  id='modelid'
                  name='modelid'
                  type='text'
                  value={getUpdateNewBirthModelid}
                  onChange={(e) => {
                    setUpdateNewBirthModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelname'>
                  Model Friendly Name :
                </InputLabel>
                <Input
                  id='modelname'
                  name='modelname'
                  type='text'
                  value={getUpdateNewBirthModelFriendlyName}
                  onChange={(e) => {
                    setUpdateNewBirthModelFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modeltype'>Model Type :</InputLabel>
                <Input
                  id='modeltype'
                  name='modeltype'
                  type='text'
                  value={getUpdateNewBirthModelType}
                  onChange={(e) => {
                    setUpdateNewBirthModelType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='modelurl'>Model URL :</InputLabel>
                <Input
                  id='modelurl'
                  name='modelurl'
                  type='text'
                  value={getUpdateNewBirthModelURL}
                  onChange={(e) => {
                    setUpdateNewBirthModelURL(e.target.value);
                  }}
                />
              </FormControl>

              <InputLabel htmlFor='dob'>DOB :</InputLabel>
              <Input
                fullWidth={true}
                className='loginFields'
                id='dob'
                name='dob'
                type='date'
                // value={getUpdateNewBirthDOB}
                defaultValue={getUpdateNewBirthDOB}
                onChange={(e) => {
                  setUpdateNewBirthDOB(e.target.value);
                }}
              />

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='gender'>Gender :</InputLabel>
                <Input
                  id='gender'
                  name='gender'
                  type='text'
                  value={getUpdateNewBirthGender}
                  onChange={(e) => {
                    setUpdateNewBirthGender(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='maleparent'>Male Parent :</InputLabel>
                <Input
                  id='maleparent'
                  name='maleparent'
                  type='text'
                  value={getUpdateNewBirthMaleParent}
                  onChange={(e) => {
                    setUpdateNewBirthMaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='femaleparent'>Female Parent :</InputLabel>
                <Input
                  id='femaleparent'
                  name='femaleparent'
                  type='text'
                  value={getUpdateNewBirthFemaleParent}
                  onChange={(e) => {
                    setUpdateNewBirthFemaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='parentmodelid'>
                  Parent Model Id :
                </InputLabel>
                <Input
                  id='parentmodelid'
                  name='parentmodelid'
                  type='text'
                  value={getUpdateNewBirthParentModelid}
                  onChange={(e) => {
                    setUpdateNewBirthParentModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='text'
                  value={getUpdateNewBirthLatitude}
                  onChange={(e) => {
                    setUpdateNewBirthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='text'
                  value={getUpdateNewBirthLongitude}
                  onChange={(e) => {
                    setUpdateNewBirthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude :</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='text'
                  value={getUpdateNewBirthAltitude}
                  onChange={(e) => {
                    setUpdateNewBirthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='address'>Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateNewBirthDescription}
                  onChange={(e) => {
                    setUpdateNewBirthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  updateNewBirthInfoHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Birth Info */}
          {/* New Nutrition details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddNutritionPopup}
            aria-labelledby='updateasset'
          >
            <DialogTitle id='updateasset'>New Nutrition details</DialogTitle>
            <DialogContent>
              <div>
                {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getAddNutritionAssetid}
                      onChange={(e) => setAddNutritionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='assetId'>Asset id:</InputLabel>
                  <Input
                    id='assetId'
                    name='assetId'
                    type='text'
                    value={getAddNutritionAssetid}
                    // onChange={(e)=>{setAddNutritionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='friendly'>
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id='friendly'
                    name='friendly'
                    type='text'
                    value={getAddNutritionAssetFriendlyName}
                    onChange={(e) => {
                      setAddNutritionAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='ExistingAssetStatus'>
                    User id:
                  </InputLabel>
                  <Input
                    id='ExistingAssetStatus'
                    name='ExistingAssetStatus'
                    type='text'
                    value={getAddNutritionUserid}
                  />
                </FormControl>
                <FormControl
                  variant='standard'
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Feed Type:</InputLabel>
                  <Select
                    id='feedtype'
                    name='feedtype'
                    type='text'
                    value={getAddNutritionFeedType}
                    onChange={(e) => {
                      setAddNutritionFeedType(e.target.value);
                    }}
                  >
                    <MenuItem key='Milk' value='Milk'>
                      Milk
                    </MenuItem>
                    <MenuItem key='Colstrum' value='Colstrum'>
                      Colstrum
                    </MenuItem>
                    <MenuItem key='Rough-Age' value='Rough-Age'>
                      Rough-Age
                    </MenuItem>
                    <MenuItem key='Concentrate' value='Concentrate'>
                      Concentrate
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant='standard'
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Schedule:</InputLabel>
                  <Select
                    id='Schedule'
                    name='Schedule'
                    type='text'
                    value={getAddNutritionSchedule}
                    onChange={(e) => {
                      setAddNutritionSchedule(e.target.value);
                    }}
                  >
                    <MenuItem key='Morning' value='Morning'>
                      Morning
                    </MenuItem>
                    <MenuItem key='Afternoon' value='Afternoon'>
                      Afternoon
                    </MenuItem>
                    <MenuItem key='Evening' value='Evening'>
                      Evening
                    </MenuItem>
                    <MenuItem key='Night' value='Night'>
                      Night
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
                  <Input
                    id='latitude'
                    name='latitude'
                    type='text'
                    value={getAddNutritionLatitude}
                    onChange={(e) => {
                      setAddNutritionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
                  <Input
                    id='longitude'
                    name='longitude'
                    type='text'
                    value={getAddNutritionLongitude}
                    onChange={(e) => {
                      setAddNutritionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='altitude'>Altitude :</InputLabel>
                  <Input
                    id='altitude'
                    name='altitude'
                    type='text'
                    value={getAddNutritionAltitude}
                    onChange={(e) => {
                      setAddNutritionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <p htmlFor='address'>Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getAddNutritionDescription}
                    onChange={(e) => {
                      setAddNutritionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  addNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setAddNutritionPopup(!showAddNutritionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* New Nutrition details */}

          {/* Delete Nutrition */}
          <Dialog
            fullScreen={fullScreen}
            open={showDeleteNutrition}
            aria-labelledby='Delete Nutrition'
          >
            <DialogTitle id='Delete Nutrition'>
              {'Delete Nutrition'}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  deleteNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setDeleteNutrition(!showDeleteNutrition);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Nutrition*/}

          {/* Update Nutrition Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateAddNutritionPopup}
            aria-labelledby='updateasset'
          >
            <DialogTitle id='updateasset'>Update Nutrition Info</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='assetId'>Asset id:</InputLabel>
                  <Input
                    id='assetId'
                    name='assetId'
                    type='text'
                    value={getUpdateAddNutritionAssetid}
                    // onChange={(e)=>{setUpdateAddNutritionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='friendly'>
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id='friendly'
                    name='friendly'
                    type='text'
                    value={getUpdateAddNutritionAssetFriendlyName}
                    onChange={(e) => {
                      setUpdateAddNutritionAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='ExistingAssetStatus'>
                    User id:
                  </InputLabel>
                  <Input
                    id='ExistingAssetStatus'
                    name='ExistingAssetStatus'
                    type='text'
                    value={getUpdateAddNutritionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='ExistingFeedtype'>
                    Existing Feed type:
                  </InputLabel>
                  <Input
                    id='ExistingFeedtype'
                    name='ExistingFeedtype'
                    type='text'
                    value={getUpdateAddNutritionExistingFeedType}
                  />
                </FormControl>
                <FormControl
                  variant='standard'
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Feed Type:</InputLabel>
                  <Select
                    id='feedtype'
                    name='feedtype'
                    type='text'
                    value={getUpdateAddNutritionFeedType}
                    onChange={(e) => {
                      setUpdateAddNutritionFeedType(e.target.value);
                    }}
                  >
                    <MenuItem key='Milk' value='Milk'>
                      Milk
                    </MenuItem>
                    <MenuItem key='Colstrum' value='Colstrum'>
                      Colstrum
                    </MenuItem>
                    <MenuItem key='Rough-Age' value='Rough-Age'>
                      Rough-Age
                    </MenuItem>
                    <MenuItem key='Concentrate' value='Concentrate'>
                      Concentrate
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant='standard'
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Schedule:</InputLabel>
                  <Select
                    id='Schedule'
                    name='Schedule'
                    type='text'
                    value={getUpdateAddNutritionSchedule}
                    onChange={(e) => {
                      setUpdateAddNutritionSchedule(e.target.value);
                    }}
                  >
                    <MenuItem key='Morning' value='Morning'>
                      Morning
                    </MenuItem>
                    <MenuItem key='Afternoon' value='Afternoon'>
                      Afternoon
                    </MenuItem>
                    <MenuItem key='Evening' value='Evening'>
                      Evening
                    </MenuItem>
                    <MenuItem key='Night' value='Night'>
                      Night
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
                  <Input
                    id='latitude'
                    name='latitude'
                    type='text'
                    value={getUpdateAddNutritionLatitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
                  <Input
                    id='longitude'
                    name='longitude'
                    type='text'
                    value={getUpdateAddNutritionLongitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <InputLabel htmlFor='altitude'>Altitude :</InputLabel>
                  <Input
                    id='altitude'
                    name='altitude'
                    type='text'
                    value={getUpdateAddNutritionAltitude}
                    onChange={(e) => {
                      setUpdateAddNutritionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className='loginFields'>
                  <p htmlFor='address'>Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getUpdateAddNutritionDescription}
                    onChange={(e) => {
                      setUpdateAddNutritionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  updateNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Nutrition Info */}
          <CustomPopup
            closeButton={closeUploadPhotoModal}
            isOpen={uploadPhotoPopup}
            closeModal={uploadPhotoPopup}
            content={uploadPhotoBody}
            title={titleUploadPhoto}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
