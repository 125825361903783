import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  GoogleMap,
  withGoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  withScriptjs,
  Polyline,
  // Circle,
} from "@react-google-maps/api";

const containerStyle = {
  width: "73vw",
  height: "90vh",
};

const center = {
  lat: 12.9716,
  lng: 77.5946,
};

const Map = ({
  getLocationHandler,
  routeLocationData,
  defaultRadius,
  locListData,
  tripRoute,
}) => {
  const mapRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [locationData, setLocationData] = useState([]);
  const [dposition, setdPosition] = useState({ lat: "", lng: "" });
  const [liveLocation, setLiveLocation] = useState(routeLocationData);
  const onLoad = useCallback(
    (ref) => {
      setSearchBox(ref);
    },
    [locationData]
  );

  const [radius, setRadius] = useState({ defaultRadius });

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPlacesChanged = useCallback(() => {
    setPosition({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });

    setLiveLocation([
      ...liveLocation,
      {
        geoLocation: {
          lat: searchBox.getPlaces()[0].geometry.location.lat(),
          lng: searchBox.getPlaces()[0].geometry.location.lng(),
        },
      },
    ]);
    setRadius(500000);
    if (getLocationHandler) {
      getLocationHandler(searchBox.getPlaces());
    }
  }, [searchBox]);

  const getBounds = (props) => {
    console.log("serserersesrser", props?.geoLocation);

    if (props?.geoLocation === undefined) {
      return;
    }
    const bounds = mapRef.current.getBounds();
    const position = new window.google.maps.LatLng(
      props?.geoLocation?.lat,
      props?.geoLocation?.lng
    );
    if (!bounds?.contains(position)) {
      mapRef.current.panTo(position);
    }
  };

  useEffect(() => {
    setLiveLocation(routeLocationData);

    getBounds(routeLocationData[0]);
  }, [routeLocationData]);

  const [directions, setDirections] = useState(null);
  const directionsServiceOptions = {
    origin: "Delhi",
    destination: "Bangalore",
    travelMode: "DRIVING",
  };
  const onDirectionsLoad = (directions) => {
    console.log("");
    setDirections(directions);
  };

  console.log("skdfjksdfjksdfjksdfj131", tripRoute);

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        // center={position.lat ? position : center}
        center={tripRoute ? tripRoute[0] : center}
        zoom={12}
    // defaultZoom={12}

        onLoad={mapLoad}
        googleMapsApiKey="AIzaSyBUU6cda6X52Et-RXcOQIJbFtykuHKwgs0"
        libraries={["places"]}
      >
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <input
            className="mapInput"
            type="text"
            placeholder="Enter a Location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        {console.log("locationsssssssssssss", liveLocation)}
        {routeLocationData?.map((li) => console.log("345345", li?.geoLocation))}
        {liveLocation?.map((li) => (
          <Marker key={li.geoLocation?.lat} position={li.geoLocation} />
        ))}
        <Marker position={position} />
        {directions && <DirectionsRenderer directions={directions} />}

       
        <Polyline
          path={tripRoute}
          geodesic={true}
          options={{
            strokeColor: "#ff2527",

            strokeOpacity: 0.75,

            strokeWeight: 2,
          }}
        />
      </GoogleMap>
    </>
  );
};

export default React.memo(Map);
