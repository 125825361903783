import React, { useEffect, useState } from "react";
import MainService from "../../../Common/MainService";
import "./bookingReq.css";
import Button from "@material-ui/core/Button";

const GetBookingReq = ({addBooking}) => {
  const [getBooking, setGetBooking] = useState([]);

  const getBookingReq = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem("GBR")));
    const data = await MainService.getBookingReq(username);
    // console.log(data, "get booking req..............");
    setGetBooking(data.bookingRequests);
    console.log(getBooking, "boookingsssssreqq.........................");
  };

  useEffect(() => {
    getBookingReq();
  }, []);

  return (
    <>
      <div className="container">
        <strong>
          <h3 className="mainHeader">GET BOOKING REQUEST</h3>
        </strong>
        <div className="row">
          <div className="col-sd-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>OrderID</th>
                  <th>TripID</th>
                  <th>Status</th>
                  <th>Freight Cost</th>
                  <th>Shipping Charge</th>
                  <th>Payment Mode </th>
                  <th>Payment Terms</th>
                  <th>Delivery Mode</th>
                  <th>Start Booking</th>
                </tr>
              </thead>
              <tbody>
                {getBooking.map((li) => {
                  return (
                    <tr>
                      <td>{li.orderid}</td>
                      <td>{li.tripid}</td>
                      <td>{li.status}</td>
                      <td>{li.freightcost}</td>
                      <td>{li.shippingcharge}</td>
                      <td>{li.paymentmode}</td>
                      <td>{li.paymentterms}</td>
                      <td>{li.deliverymode}</td>

                      <button
                        className="bookingBtn"
                        onClick={() => addBooking()}
                      >
                        START BOOKING
                      </button>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetBookingReq;
