import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import CowImg from "../../../images/cowImage.jpg";
import BeeImg from "../../../images/gbrHome/honeybee.webp";

export default function InvestableAssets() {
  return (
    <>
      {" "}
      <div className="pt-4" id="Bee">
        <HomeSubSection
          className1="aboutBgGrey"
          className3="image7"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
      Honey Bee Colonies"
          description="GBR Care is dedicated to supporting financial and government institutions in their efforts to enhance Corporate Social Responsibility (CSR) initiatives. We specialize in facilitating the distribution of honey bee colonies, promoting environmental sustainability and biodiversity. By collaborating with us, these organizations not only contribute to the preservation of ecosystems but also strengthen their commitment to social and environmental responsibility, fostering positive impacts within their communities.
                    "
          src={BeeImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div>
    </>
  );
}
