import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./AgriMinerComponent.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import ArrowImg from "../../images/arrow6.png";
import agriMinerImg from "../../images/indian-farmer-using-smartphone.jpg";
import step1 from "../../images/step1.png";
import step2 from "../../images/step2.png";
import step3 from "../../images/step3.png";
import step4 from "../../images/step4.png";
import step5 from "../../images/step5.png";
import benefit1 from "../../images/beneifit1.png";
import benefit2 from "../../images/beneifit2.png";
import benefit3 from "../../images/beneifit3.png";
import benefit4 from "../../images/beneifit4.png";
import benefit5 from "../../images/beneifit5.png";
import Button from "@material-ui/core/Button";
import StepCards from "../stepCards/StepCards";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default class AgriMinerComponent extends React.Component {
  

  stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Download, install and Login to the App.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Upload the Farm details.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Wait for the approval from GBR Care representative.",
      img: step3,
    },
    {
      title: "Step 4",
      bodycopy:
        "Once approved by GBR, you can check the earnings in your wallet. ",
      img: step4,
    },
    {
      title: "Step 5",
      bodycopy:
        "Withdraw money anytime from Wallet to your verified Bank Account.",
      img: step5,
    },
  ];

  benefits = [
    {
      img: benefit1,
      title: "Be Your Own Boss",
      bodycopy:
        "Work for yourself and have more control and flexibility over your work activities and finance.",
    },
    {
      img: benefit2,
      title: "Start earning with Zero Investment",
      bodycopy:
        "Download, install, open and register with GBR Care Platform to start earning without any investment.",
    },
    {
      img: benefit3,
      title: "Earn anytime from anywhere",
      bodycopy:
        "Download, install, open and register with GBR Care Platform to start earning from anywhere.",
    },
    {
      img: benefit4,
      title: "Contribute to farmer growth",
      bodycopy:
        "Your work helps to rebuild farmer communities and enables digital transformation in Agriculture.",
    },
    {
      img: benefit5,
      title: "Make more passive income than ever",
      bodycopy:
        "Easy and quick way to make passive income by reffering farmers and other GBR Care partners of your locality.",
    },
  ];

  render() {
    return (
      <>
        <HeaderComponent />
     
   <UpdatesBanner 
         bannerText={[
          "Start Earning With No Investment",
        ]}
className="AgriMinorContainer"
        bannerbodycopy="Earn round the clock by becoming AgriMiner"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&hl=en_US"
        DownloadText="Download App"
        marginfalse="true"/>

<Container>

<TitleDescription
  classname=""
  title="Who are an Agriminer"
  description={[
    "An Agriminer can be any individual such as Homemakers, Students, Retired Person, Farmers and more.",
  ]}
/>
</Container>


       
        <div className="agriminer-wrapper">
         
        <div className="subSectionWrapper main-wrapper2">
          <Container>
            <h4 className="benefit-title container-title  text-center">
              Agriminer Benefits
            </h4>

         

            <div className="section3BodyContainer benefit-container justify-content-md-center p-0">
              {this.benefits.map((e) => {
                return (
                  <StepCards
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                );
              })}
            </div>
          </Container>

          </div>
          {/* <Container
          className="InvestmentContainer SignUpasContainerWhite"
          style={{ paddingTop: 80 }}
        >
          <h4 className="container-title">AGRIMINER BENEFITS!</h4>
          <div className="gridItemContainer">
            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Be Your Own Boss</b>
                </h4>
                <p className="container-para">
                  Work for yourself and have more control and flexibility over
                  your work activities and finance.
                </p>
              </div>
            </div>

            <div className="gridItem ContainerWhite">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Start Earning with Zero Investment</b>
                </h4>
                <p className="container-para">
                  Download, install, open and register with GBR Platform to
                  start earning without any investment.
                </p>
              </div>
            </div>

            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Earn Anytime from anywhere</b>
                </h4>

                <p className="container-para">
                  Download, install, open and register with GBR Platform to
                  start earning from anywhere.
                </p>
              </div>
            </div>

            <div className="gridItem ContainerWhite">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Contribute to Farmer Growth</b>
                </h4>
                <p className="container-para">
                  Your work helps to rebuild farmer communities and enables
                  digital transformation in Agriculture
                </p>
              </div>
            </div>

            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b> MAKE MORE PASSIVE INCOME THAN EVER:</b> <br />
                </h4>
                <p className="container-para">
                  Easy and quick way to make passive income by refering farmers,
                  bucthers and other GBR partners of your locality
                </p>
              </div>
            </div>
          </div>
        </Container> */}

          <Container className=" agriminor-journey-container">
            <h4 className="container-title">Agriminer Journey</h4>

            <div className="section3BodyContainer justify-content-md-center p-0">
              {this.stepCardContent.map((e) => {
                return (
                  <StepCards
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                );
              })}
            </div>
          </Container>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod pt-0"
            description=" GBR Care enables individuals to earn
                extra earnings anytime, from anywhere by just uploading farm and
                processing outlet details through the app. Once these details
                gets authenticated and approved by GBR Care representative, you will
                get your commission in your application digital wallet and you
                can transfer from your digital wallet to bank account. In
                addition, GBR Care Platform empower rural and urban individuals to
                onboard GBR Care partners to create an extra earnings.
                "
            descriptionTitle="
        Sign up as Agriminer !"
            src={agriMinerImg}
            href4="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&hl=en_US"
            buttoncont1="Become a Agriminer"
            btn2="true"
          />
        </div>
        <FooterComponent />
      </>
    );
  }
}
