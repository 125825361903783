// import React from "react";

// import Icon1 from "../../images/gbrPN/agriculture-iot-with-rice-field-background-min.jpg";
// import Icon2 from "../../images/gbrPN/PNBanner.webp";
// import Icon3 from "../../images/gbrCBN/CBNBanner.webp";
// import Icon4 from "../../images/gbrCBN/CBNBanner.webp";

// import "./OurServices.css"

// import { Row, Col } from "react-bootstrap";
// import OurServiceCard from "./OurServiceCard";
// import { Container } from "@mui/material";

// export default function OurServices() {
//   const benefits = [
   
//     {
//       image: Icon1,
//       title: "Grow other colonies & Earn more",
//       bodycopy:
//         "Ipsum is simply dummy text of the printing and typesetting industry. Lorem",
//     },
//     {
//       image: Icon2,
//       title: "Access to Finance ",
//       bodycopy:
//         "Ipsum is simply dummy text of the printing and typesetting industry. Lorem",
//     },
//     {
//       image: Icon3,
//       title: "Access to Machinery ",
//       bodycopy:
//         "Access to machinery to create own branded product at the farm gate."
//     },
//     {
//         image: Icon4,
//         title: "Access to Machinery ",
//         bodycopy:
//           "Access to machinery to create own branded product at the farm gate."
//       },
//   ];
//   return (
//     <Container>
//     <div className="for-farmer-section3 subSectionWrapper">
//       <h2 className="title text-center mb-5 mb-lg-5 container-title">Farmers Benefits</h2>
//       <Row>
//         {benefits.map((e) => {
//           return (
//             <Col md={4}>
//               <OurServiceCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
//             </Col>
//           );
//         })}
//       </Row>
   
//     </div>
//     </Container>

//   );
// }


import React from "react";
import CBN from "../../images/gbrCBN/CBNBanner.webp";
import { NavLink } from "react-router-dom";
import "./OurServices.css"
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";

export default function OurServices() {
  const services = [
    // {
    //   title: "ABN",
    //   image: ABN,
    //   href: "/ABN",
    // },
    {
      title: "ARN",
      image: CBN,
      href: "/customized-arn-network",
    },
    // {
    //   title: "PN",
    //   image: PN,
    //   href: "/ProductionNetwork",
    // },
    // {
    //     title: "GTN",
    //     image: GTN,
    //     href: "/ExploreProducts",
    //   },
  ];

  return (
    <Container className="container for-farmer-section2 subSectionWrapper ">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        GBR Care ARN Network
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
