import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";




export default function AboutUsComponent() {
 

  return (
    <Grid container spacing={2}>
     <h2>About Us Component</h2>
    </Grid>
  );
}
