import React, { useState, useEffect } from 'react';

import { Box, Button } from '@mui/material';
import SellerProformaInvoiceManagement from './SellerProformaInvoiceManagement';
import SellerInvoiceManagement from './SellerInvoiceManagement';
import MainService from '../../../Common/MainService';

const SellerAccountManagement = () => {
  const [
    isSellerProformaInvoiceManagement,
    setSellerProformaInvoiceManagement,
  ] = useState(true);

  const [isInvoiceManagement, setIsInvoiceManagement] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  //Seller Proforma Invoice Management state handler
  const quotationManagementStatusHandler = () => {
    setSellerProformaInvoiceManagement(true);
    setIsInvoiceManagement(false);
  };

  //seller Invoice Management state handler

  const sellerInvoiceManagementState = () => {
    setIsInvoiceManagement(true);
    setSellerProformaInvoiceManagement(false);
  };

  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.7rem',
          mb: '1rem',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <Button
          variant={isSellerProformaInvoiceManagement ? 'contained' : 'outlined'}
          onClick={quotationManagementStatusHandler}
        >
          Proforma Invoice
        </Button>
        <Button
          variant={isInvoiceManagement ? 'contained' : 'outlined'}
          onClick={sellerInvoiceManagementState}
        >
          Tax Invoice
        </Button>
      </Box>

      {isSellerProformaInvoiceManagement && (
        <SellerProformaInvoiceManagement userDetails={userDetails} />
      )}
      {isInvoiceManagement && (
        <SellerInvoiceManagement userDetails={userDetails} />
      )}
    </Box>
  );
};

export default SellerAccountManagement;
