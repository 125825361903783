import React, { useState } from 'react';
import {
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CommanTable = ({
  columnsData,
  rowsData,
  tableType,
  taxTable,
  inputFieldChangeHandler,
  deleteItemHandler,
  addItemHandler,
  taxInputChangeHandler,
  taxData,
  image,
  saveButtonStatus,
  imageChangeHandler,
  imageFileChangeHandler,
  invoiceType,
  discountPriceChangeHandler,
  discPercent,
}) => {
  const { sGst = 0, cGst = 0, iGst = 0 } = taxData || {};
  const TAX_RATE = cGst + sGst + iGst;

  const ccyFormat = (num) => {
    return `${num.toFixed(2)}`;
  };

  const subtotal = (items) => {
    let sum = 0;
    for (const { quantity, price_per_unit } of items) {
      const price = quantity * price_per_unit || 0;
      sum += price;
    }
    return sum;
  };

  const invoiceSubtotal = subtotal(rowsData);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceSubtotal + invoiceTaxes;

  //temp
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTableRow = (rowData, index) => {
    return (
      <StyledTableRow
        key={index}
        sx={{
          // '&:last-child td, &:last-child th': { border: 0 },
          display: {
            xs: 'flex',
            md: 'table-row',
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        {columnsData.map((column) => {
          if (column.type === 'date') {
            return (
              <TableCell
                sx={{ width: { xs: 'auto', lg: '15rem' } }}
                key={column.id}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name={column.id}
                    value={dayjs(rowData[column.id])}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor:
                            saveButtonStatus && !rowData[column.id]
                              ? '#e35a5a'
                              : '#000',
                        },
                      },
                    }}
                    onChange={
                      inputFieldChangeHandler
                        ? (newValue) => {
                            const formattedValue =
                              dayjs(newValue).format('YYYY-MM-DD');
                            inputFieldChangeHandler(
                              index,
                              column.id,
                              formattedValue
                            );
                          }
                        : undefined
                    }
                  />
                </LocalizationProvider>
              </TableCell>
            );
          } else if (column.id === 'total') {
            return (
              <TableCell
                component='th'
                scope='row'
                key={column.id}
                sx={{
                  display: {
                    xs: 'flex',
                    md: 'table-cell',
                  },
                  gap: '0.5rem',
                }}
              >
                <Typography sx={{ display: { xs: 'flex', md: 'none' } }}>
                  Total:{' '}
                </Typography>
                <Typography
                  id={column.id}
                  placeholder={`Please enter ${column.label}`}
                  variant='standard'
                  name={column.id}
                  type={column.type}
                  sx={{
                    flex: 1,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {(
                    rowData['price_per_unit'] * rowData['quantity'] || 0
                  ).toFixed(2)}
                </Typography>
              </TableCell>
            );
          } else if (
            column.id === 'logo' ||
            column.id === 'qrCodeImg' ||
            column.id === 'sup_sign_Img'
          ) {
            return (
              <TableCell align='center' key={column.id}>
                <Box
                  sx={{
                    height: '5rem',
                    width: column.id === 'sup_sign_Img' ? '15rem' : '5rem',
                    border: '1px solid',
                    // border:
                    //   saveButtonStatus &&
                    //   !image[column.id] &&
                    //   column.id !== 'qrCodeImg'
                    //     ? '1px solid #e35a5a'
                    //     : '1px solid',
                    display: 'flex',
                    mx: 'auto',
                    color: saveButtonStatus && !image ? '#e35a5a' : '',
                    borderRadius: '5px',
                    overflow: 'hidden',
                  }}
                >
                  {image[column.id] && (
                    <img
                      src={image[column.id]}
                      style={{ width: '100%', height: '100%' }}
                      onClick={() => imageChangeHandler(column.id)}
                    />
                  )}

                  {!image[column.id] && (
                    <label
                      style={{
                        display: 'flex',
                        padding: '1px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        cursor: 'pointer',
                      }}
                      htmlFor={column.id}
                    >
                      Please choose {column.label}
                    </label>
                  )}

                  <input
                    id={column.id}
                    type='file'
                    name={column.id}
                    style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      display: 'none',
                    }}
                    onChange={(event) =>
                      imageFileChangeHandler(event, column.id)
                    }
                  />
                </Box>
              </TableCell>
            );
          } else if (column.type === 'option') {
            return (
              <TableCell key={column.id}>
                <Button
                  id='fade-button'
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    color:
                      saveButtonStatus && !rowData[column.id] ? '#e35a5a' : '',
                  }}
                >
                  {rowData[column.id]
                    ? rowData[column.id]
                    : 'Select Package Type'}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {column.field.map((item) => {
                    return (
                      <MenuItem
                        key={item}
                        onClick={
                          inputFieldChangeHandler
                            ? () => {
                                inputFieldChangeHandler(
                                  index,
                                  column.id,
                                  item.toLowerCase()
                                );
                                handleClose();
                              }
                            : undefined
                        }
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </TableCell>
            );
          } else if (column.type === 'srno') {
            return (
              <TableCell
                component='th'
                scope='row'
                key={column.id}
                sx={{ textAlign: 'center' }}
              >
                <Typography>{rowData[column.id]}</Typography>
              </TableCell>
            );
          } else {
            return (
              <TableCell component='th' scope='row' key={column.id}>
                <TextField
                  error={
                    !rowData[column.id] &&
                    saveButtonStatus &&
                    !['shipVia', 'shippingTerms'].includes(column.id)
                  }
                  id={column.id}
                  label={`Please enter ${column.label}`}
                  placeholder={`Please enter ${column.label}`}
                  variant='standard'
                  name={column.id}
                  value={rowData[column.id]?.toString()}
                  type={column.type}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    flex: 1,
                    width: column.id === 'purchaseOrderID' ? 'auto' : '100%',

                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                  onChange={
                    inputFieldChangeHandler
                      ? (event) =>
                          inputFieldChangeHandler(
                            index,
                            column.id,
                            event.target.value,
                            column.type
                          )
                      : undefined
                  }
                />
              </TableCell>
            );
          }
        })}
        {tableType && (
          <TableCell sx={{ textAlign: 'center' }}>
            <DeleteIcon
              sx={{
                cursor: 'pointer',
                color: '#e35a5a',
              }}
              onClick={() => deleteItemHandler(rowData, index)}
            />
          </TableCell>
        )}
      </StyledTableRow>
    );
  };

  const subTotal = (subtotal, total) => {
    const CGst = (subtotal * (cGst || 0)) / 100;
    const SGst = (subtotal * (sGst || 0)) / 100;
    const IGst = (subtotal * (iGst || 0)) / 100;
    const discountVal = (subtotal * (discPercent?.discPercent || 0)) / 100;
    const calculatedTotal = subtotal + CGst + SGst + IGst;

    const priceAfterDiscount = calculatedTotal - discountVal;

    return (
      <>
        <Table sx={{ width: { xs: '100%', md: '50%', marginLeft: 'auto' } }}>
          <TableBody>
            <StyledTableRow>
              {invoiceType && (
                <TableCell colSpan={2}>Subtotal Value without tax: </TableCell>
              )}
              {!invoiceType && (
                <TableCell colSpan={2}>Subtotal Value without tax</TableCell>
              )}
              <TableCell align='right'>{ccyFormat(subtotal)}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell>C_GST</TableCell>
              <TableCell
                align='center'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  align='center'
                  id='cGst'
                  placeholder={`C_GST input`}
                  variant='standard'
                  name='cGst'
                  value={cGst.toString()}
                  type='number'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    width: '65%',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '& input': { textAlign: 'center' },
                  }}
                  onChange={
                    inputFieldChangeHandler
                      ? (event) =>
                          taxInputChangeHandler('cGst', event.target.value)
                      : undefined
                  }
                />
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  %
                </Typography>
              </TableCell>
              <TableCell align='right'>{ccyFormat(CGst)}</TableCell>
            </StyledTableRow>

            <StyledTableRow>
              <TableCell>S_GST</TableCell>
              <TableCell
                align='center'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  align='center'
                  id='sGst'
                  placeholder={`S_GST input`}
                  variant='standard'
                  name='sGst'
                  value={sGst.toString()}
                  type='number'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    width: '65%',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '& input': { textAlign: 'center' },
                  }}
                  onChange={
                    inputFieldChangeHandler
                      ? (event) =>
                          taxInputChangeHandler('sGst', event.target.value)
                      : undefined
                  }
                />
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  %
                </Typography>
              </TableCell>
              <TableCell align='right'>{ccyFormat(SGst)}</TableCell>
            </StyledTableRow>

            <StyledTableRow>
              <TableCell>I_GST</TableCell>
              <TableCell
                align='center'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  align='center'
                  id='iGst'
                  placeholder={`C_GST input`}
                  variant='standard'
                  name='iGst'
                  value={iGst.toString()}
                  type='number'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    width: '65%',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '& input': { textAlign: 'center' },
                  }}
                  onChange={
                    inputFieldChangeHandler
                      ? (event) =>
                          taxInputChangeHandler('iGst', event.target.value)
                      : undefined
                  }
                />
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  %
                </Typography>
              </TableCell>
              <TableCell align='right'>{ccyFormat(IGst)}</TableCell>
            </StyledTableRow>

            <StyledTableRow>
              <TableCell colSpan={2}>Total GST</TableCell>
              <TableCell align='right' sx={{ display: 'flex', gap: '5px' }}>
                <Typography sx={{ display: 'inline-block' }}>%</Typography>
                <Typography sx={{ display: 'inline-block' }}>
                  {' '}
                  {TAX_RATE}
                </Typography>
              </TableCell>
            </StyledTableRow>

            {invoiceType && (
              <StyledTableRow>
                <TableCell colSpan={2}>
                  SubTotal Value with {TAX_RATE} % of total GST
                </TableCell>
                <TableCell align='right'>
                  <Typography> {ccyFormat(calculatedTotal)}</Typography>
                </TableCell>
              </StyledTableRow>
            )}
            {invoiceType && (
              <StyledTableRow>
                <TableCell>Discount of </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <TextField
                    align='center'
                    id='discPercent'
                    placeholder={`Discount input`}
                    variant='standard'
                    name='discPercent'
                    value={discPercent?.discPercent?.toString()}
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    sx={{
                      width: '65%',
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                      '& input': { textAlign: 'center' },
                    }}
                    onChange={
                      inputFieldChangeHandler
                        ? (event) =>
                            discountPriceChangeHandler(
                              'discPercent',
                              event.target.value
                            )
                        : undefined
                    }
                  />
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    %
                  </Typography>
                </TableCell>

                <TableCell align='right' sx={{ width: '10rem' }}>
                  &#9472; {ccyFormat(discountVal)}
                </TableCell>
              </StyledTableRow>
            )}
            {invoiceType && (
              <StyledTableRow>
                <TableCell>Total payable Amount by customer</TableCell>
                <TableCell align='right' colSpan={2}>
                  {ccyFormat(priceAfterDiscount)}
                </TableCell>
              </StyledTableRow>
            )}
            {!invoiceType && (
              <StyledTableRow>
                <TableCell>Total Purchase Order price</TableCell>
                <TableCell align='right' colSpan={2}>
                  {ccyFormat(calculatedTotal)}
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mb: '1rem' }}>
      <Table aria-label='simple table'>
        <TableHead
          sx={{
            background: '#1976d236',
            display: { xs: 'none', md: 'table-header-group' },
          }}
        >
          <TableRow>
            {columnsData.map((column) => (
              <TableCell
                key={column.id}
                align={
                  ['logo', 'sup_sign_Img', 'qrCodeImg'].includes(column.id)
                    ? 'center'
                    : 'left'
                }
              >
                {column.label}
              </TableCell>
            ))}
            {tableType && <TableCell></TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>{rowsData.map(renderTableRow)}</TableBody>
      </Table>

      {taxTable && subTotal(invoiceSubtotal, invoiceTotal)}
      {tableType && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '1rem',
            mt: '1rem',
          }}
        >
          <AddIcon
            color='primary'
            sx={{ cursor: 'pointer', border: '1px solid' }}
            onClick={addItemHandler}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default CommanTable;
