import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from "react-google-maps";

//import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import "./DashboardComponentForExecutive.css";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import readyForWhitelabelImg from "../../../../resources/img/readyForWhitelabel.png";
import whitelabelImg from "../../../../resources/img/whitelabel.png";
import sellableImg from "../../../../resources/img/sellable.jpg";
import soldImg from "../../../../resources/img/sold.png";
import leasedImg from "../../../../resources/img/leased.jpg";
import Map from "../../Common/googleMap/Map";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // set last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
}));

export default function DashboardComponentForExecutive() {
  const classes = useStyles();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(true);
  const [allSites, setAllSites] = useState([]);
  const [marker_position, setmarker_position] = useState({
    lat: 17.093426,
    lng: 86.655773,
  });
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [leasedList, setLeasedList] = useState([]);
  const [readyForWhiteLabelList, setReadyForWhiteLabelList] = useState([]);
  const [sellableList, setSellableList] = useState([]);
  const [soldList, setSoldList] = useState([]);
  const [whiteLabelledList, setWhiteLabelledList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [allMarkers, setAllMarkers] = useState([]);
  const [markersList, setMarkersList] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [assestsMarker, setAssestsMarker] = useState([]);

  /*************** Site Management Map *************/
  const AssetsGoogleMap = withGoogleMap(() => {
  return (
    <GoogleMap
      key={markersList.index}
      defaultZoom={4}
      defaultCenter={marker_position}
      center={marker_position}
    >
      {markersList.map((value, index) => {
        return value && (
          <Marker
            key={index}
            position={{
              lat: parseFloat(value.lat),
              lng: parseFloat(value.log),
            }}>              
          </Marker>
        ) 
      })}
    </GoogleMap>
  );
});

  useEffect(() => {
    getAssetVerticalsIdList();
  }, []);

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getDashboardData();
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedVerticalsId, selectedCategory]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalName(data[0].friendlyName);
          setVerticalsIdList(data);
        } else {
          setSelectedVerticalsId("");
          setIsLoading(false);
          setVerticalsIdList([]);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),"consumer2")
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setCategoryTypesList([]);
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getDashboardData = () => {
    if(selectedVerticalsId !== "" && selectedCategory !== "")
    {
      MainService.getExecutiveDashboardData(selectedVerticalsId, selectedCategory)
      .then((res) => {
        console.log("dashboard data:", res);
        setIsLoading(false);
        setAllSites(res);
        setTagsList(res[0].tags);
        setLeasedList(res[0].Leased);
        setReadyForWhiteLabelList(res[0].ReadyForWhiteLabel);
        setSellableList(res[0].Sellable);
        setSoldList(res[0].Sold);
        setWhiteLabelledList(res[0].Whitelabelled);

        const obj = res[0].tags.reduce((accumulator, currentValue) => {
          const filteredData = res[0][currentValue].filter((item) =>
            Object.values(item).every((li) => li !== 'null')
          );
          if (filteredData.length > 0) {
            const filterProperties = filteredData.map(({ lat, log }) => ({
              geoLocation: {
                lat: parseFloat(lat),
                lng: parseFloat(log),
              },
            }));
            accumulator.push(...filterProperties);
          }
          return accumulator;
        }, []);
        setAssestsMarker(obj);
      })
      .catch((err) => {
        console.log("error ", err)
        return NotificationManager.error(err);
      });
    }
   
  };

  const markerSelectHandler = (props) => {
    console.log('props', props);
    const data = props
      .map((item) => {
        if (item.lat !== 'null' && item.log !== 'null') {
          return {
            geoLocation: {
              lat: parseFloat(item.lat),
              lng: parseFloat(item.log),
            },
          };
        }
      })
      .filter(Boolean);

    setAssestsMarker(data);
  };

  const categoryTypeHandler = (event) => {
    setSelectedCategory(event.target.value);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
  };

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  categoryTypeHandler(event);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="MuiGrid-root">
          <Paper>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className="imgContainer"
                      onClick={() =>
                        markerSelectHandler(readyForWhiteLabelList)
                      }
                    >
                      <img src={readyForWhitelabelImg} />
                      <br />
                      <font size="2">
                        Ready For <br />
                        Whitelabel({readyForWhiteLabelList.length})
                      </font>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className="imgContainer"
                      onClick={() => markerSelectHandler(whiteLabelledList)}
                    >
                      <img src={whitelabelImg} />
                      <br />
                      <font size="2">
                        Whitelabel({whiteLabelledList.length})
                        <br />
                      </font>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className="imgContainer"
                      onClick={() => markerSelectHandler(sellableList)}
                    >
                      <img src={sellableImg} />
                      <br />
                      <font size="2">
                        Sellable({sellableList.length})
                        <br />
                      </font>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className="imgContainer"
                      onClick={() => markerSelectHandler(soldList)}
                    >
                      <img src={soldImg} />
                      <br />
                      <font size="2">
                        Sold({soldList.length}) <br />
                      </font>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      className="imgContainer"
                      onClick={() => markerSelectHandler(leasedList)}
                    >
                      <img src={leasedImg} />
                      <br />
                      <font size="2">
                        Leased({leasedList.length})
                        <br />
                      </font>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div>
          
          {/* <AssetsGoogleMap
            containerElement={
              <div style={{ height: `500px`, width: "100%" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          /> */}
          <Map routeLocationData={assestsMarker}></Map>
          
        </div>
      </div>
    </React.Fragment>
  );
}
