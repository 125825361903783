import React from "react";
import HoneyAsset from "../../images/installation-bee-hives-new-location.jpg";
import FinancialSupport from "../../images/coin-wooden-table.jpg";
import ProcessingSupport from "../../images/processingService/banner.jpg";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export default function MoneyMakingAssets() {
  const services = [
    // {
    //   title: "Community",
    //   image: Community,
    //   href: "/Community",
    // },
    {
      title: "Profit-generating Service",
      image: HoneyAsset,
      href: "/Profit-generating-Services",
      customPropValue: "storage",
    },
    {
      title: "Financial Service",
      image: FinancialSupport,
      href: "/financial-services",
      customPropValue: "storage",

    },
    {
      title: "Business Growth Service",
      image: ProcessingSupport,
      href: "/GrowthService",
      customPropValue: "FF",
    },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => (
          <Col md={4} key={e.title}>
            <NavLink
              to={{ pathname: e.href, state: { customProp: e.customPropValue } }}
            >
              <div className="image-container mb-4">
                <img className="" src={e.image} alt="Investment asset" />
              </div>
              <h3 className="text-center container-heading1 mt-0 mb-md-0 mb-4 text-decoration-none">
                {e.title}
              </h3>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
}
