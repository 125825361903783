import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import Map from '../../googlemap/Map';

import MainService from '../../../Common/MainService';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VehicleStatus = () => {
  const [position, setPosition] = useState({ lat: 28.70406, lng: 77.102493 });
  const [markers, setMarkers] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState('');
  const {
    user: { username },
  } = JSON.parse(atob(localStorage.getItem('CBN')));

  useEffect(() => {
    const newSocket = io(
      'http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001'
    );
    newSocket.on('connect', () => {
      console.log(
        'Connected to server!************',
        `tt/solution/${username}/site/${vehicleDetails.siteId}/asset/${vehicleDetails.assetId}/LOC`
      );
    });

    newSocket.on(
      `tt/solution/consumer2/site/${vehicleDetails.siteId}/asset/${vehicleDetails.assetId}/LOC`,
      (data) => {
        console.log('skdfjksdfj', data);
        console.log(JSON.parse(data.payload).geoLocation);
        setMarkers([{ geoLocation: JSON.parse(data.payload).geoLocation }]);
      }
    );

    return () => {
      newSocket.disconnect();
    };
  }, [markers, vehicleDetails.assetId]);

  const getVehicleList = async () => {
    const data = await MainService.getVehicleList(username);
    setVehicle({ vehicle: data.assets });
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  console.log('vech', vehicle);

  const mapListChangeHandler = (name, value) => {
    // setVehicleDetails(value);

    // const filterData = vehicle?.vehicle?.filterData(
    //   (item) => item.assetId === value
    // );
    const filterData = vehicle.vehicle.filter((item) => item.assetId === value);
    setVehicleDetails({
      assetId: filterData[0].assetId,
      siteId: filterData[0].siteId,
    });
    console.log(vehicleDetails);
  };

  return (
    <Box>
      {/* {JSON.stringify(vehicle)} */}

      <h3
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 'medium',
          fontWeight: 'bold',
          color: 'blue',
          borderBottom: 'groove',
        }}
      >
        Vehicle Status
      </h3>

      <Box>
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='triplist'>Select Vehicle</InputLabel>
          <Select
            labelId='triplist'
            id='triplist'
            value={vehicleDetails.assetId}
            onChange={(event) =>
              mapListChangeHandler('driverlist', event.target.value)
            }
            // MenuProps={MenuProps}
          >
            {vehicle?.vehicle?.map((li) => (
              <MenuItem key={li.assetId} value={li.assetId}>
                {li.assetId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Map routeLocationData={markers} />
    </Box>
  );
};

export default VehicleStatus;
