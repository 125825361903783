import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Input from '@material-ui/core/Input';
import { Box } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchBar from 'material-ui-search-bar';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOnOutlined';
import MainService from '../../Common/MainService';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from 'react-google-maps';
import socketIOClient from 'socket.io-client';
import StarIcon from '@material-ui/icons/Star';
import CustomPopup from '../../Common/CustomPopup/CustomPopupComponent';
import Map from '../../Common/googleMap/Map';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 10,
    color: '#fff',
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
}));

export default function DashboardForPMComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [roleName, setRoleName] = useState('');
  const [roleResourcesList, setRoleResourcesList] = useState([]);
  const [filteredRolesList, setFilteredRolesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [listViewAssets, setListViewAssets] = useState(false);
  const [listViewSites, setListViewSites] = useState(false);
  const [gridViewAssets, setGridViewAssets] = useState(false);
  const [gridViewSites, setGridViewSites] = useState(true);
  const [gridViewSiteIssues, setGridViewSiteIssues] = useState(false);
  const [listViewSiteIssues, setListViewSiteIssues] = useState(false);
  const [gridViewSiteAlarms, setGridViewSiteAlarms] = useState(false);
  const [listViewSiteAlarms, setListViewSiteAlarms] = useState(false);
  const [gridViewAssetIssues, setGridViewAssetIssues] = useState(false);
  const [gridViewSiteReports, setGridViewSiteReports] = useState(false);
  const [gridViewAssetReports, setGridViewAssetReports] = useState(false);
  const [roleResourceAssetsList, setRoleResourceAssetsList] = useState([]);
  const [resourceId, setResourceId] = useState('');
  const [resourceName, setResourceName] = useState('');
  const [filterUsers, setFilterUsers] = useState([]);
  const [siteIssuesList, setSiteIssuesList] = useState([]);
  const [assetIssuesList, setAssetIssuesList] = useState([]);
  const [siteReportsList, setSiteReportsList] = useState([]);
  const [assetReportsList, setAssetReportsList] = useState([]);
  const [sitealarmsList, setSiteAlarmsList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [searched, setSearched] = useState('');
  const [farmgridsearched, setFarmGridSearched] = useState('');
  const [assetSearched, setAssetSearched] = useState('');
  const [page, setPage] = React.useState(0);
  const [siteIssuePopup, setSiteIssuePopup] = useState(false);
  const [siteAlarmPopup, setSiteAlarmPopup] = useState(false);
  const [attendancePopup, setAttendancePopup] = useState(false);
  const [assetIssuePopup, setAssetIssuePopup] = useState(false);
  const [assetHealthPopup, setAssetHealthPopup] = useState(false);
  const [assetReportPopup, setAssetReportPopup] = useState(false);
  const [patrolPopup, setPatrolPopup] = useState(false);
  const [assetFeedbackPopup, setAssetFeedbackPopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedParentId, setSelectedParentId] = useState('');
  const [mobileNumberValue, setMobileNumberValue] = useState('');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [medicinedateValue, setMedicineDateValue] = useState('');
  const [vaccinationdateValue, setVaccinationDateValue] = useState('');
  const [treatmentDateValue, setTreatmentDateValue] = useState('');
  const [examineValue, setExamineValue] = useState('');
  const [suricalProcedureValue, setSuricalProcedureValue] = useState('');
  const [vaccinationTypeValue, setVaccinationTypeValue] = useState('');
  const [medicineTypeValue, setMedicineTypeValue] = useState('');
  const [diseaseTypeValue, setDiseaseTypeValue] = useState('');
  const [latitudeValue, setLatitudeValue] = useState('');
  const [longitudeValue, setLongitudeValue] = useState('');
  const [altitudeValue, setAltitudeValue] = useState('');
  const [siteReportPopup, setSiteReportPopup] = useState(false);
  const [operationalStatusValue, setOperationalStatusValue] = useState('');
  const [reportDescriptionValue, setReportDescriptionValue] = useState('');
  const [assetSiteReportPopup, setAssetSiteReportPopup] = useState(false);
  const [typeValue, setTypeValue] = useState('');
  const [nutritionRecordedValue, setNutritionRecordedValue] = useState('');
  const [attendanceRecordedValue, setAttendanceRecordedValue] = useState('');
  const [photoUploadedValue, setPhotoUploadedValue] = useState('');
  const [userTypeValue, setUserTypeValue] = useState('');
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subCategoryNameValue, setSubCategoryNameValue] = useState('');
  const [parentid, setParentId] = useState('');
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  /* Asset Feedback */
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState('');
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState('');
  const [deliveryExp, setDeliveryExp] = useState('');
  const [productExp, setProductExp] = useState('');
  const [supportExp, setSupportExp] = useState('');
  const [othersExp, setOthersExp] = useState('');

  /* Photo Upload */
  const [selectedCategoryName, setSelectedCategoryName] = useState('image');
  const [uploadPhotoPopup, setShowUploadPhotoPopup] = useState(false);
  const [uploadPhotoFile, setUploadPhotoFile] = useState(null);
  const [uploadPhotoFileDescription, setUploadPhotoFileDescription] =
    useState(null);
  const [uploadPhotoNameValue, setUploadPhotoNameValue] = useState('');
  const [priceValue, setPriceValue] = useState('');
  const [expiryDateValue, setExpiryDateValue] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  /* End of Photo Upload */

  /* Satrt of Create Site Alarm */

  const [selectedVerticalsId, setSelectedVerticalsId] = useState('');
  const [selectedVerticalName, setSelectedVerticalName] = useState('');
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState('');
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [alarmCategoryValue, setAlarmCategoryValue] = useState('');
  const [alarmNameValue, setAlarmNameValue] = useState('');
  /* End of Site Alarm */
  /* Start of Asset Alarm */
  const [gridViewAssetAlarms, setGridViewAssetAlarms] = useState(false);
  const [assetAlarmsList, setAssetAlarmsList] = useState([]);
  const [assetAlarmPopup, setAssetAlarmPopup] = useState(false);
  /* End of Asset Alarm */
  /* Asset Mortality */
  const [assetMortalityList, setAssetMortalityList] = useState([]);
  const [gridViewAssetMortality, setGridViewAssetMortality] = useState(false);

  /* Create Form Site */
  const [getListOfCategories, setListOfCategories] = useState([]);
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [getSiteName, setSiteName] = useState('');
  const [getMailId, setMailId] = useState('');
  const [getPhone, setPhone] = useState('');
  const [getSiteType, setSiteType] = useState('');
  const [getSiteData, setSiteData] = useState([]);
  const [getFarmerUserName, setFarmerUserName] = useState('');
  const [showCreateSitePopup, setShowCreateSitePopup] = useState(false);
  const [showCreateSiteDialog, setShowCreateSiteDialog] = useState(false);

  const [nodeEmail, setNodeEmail] = useState('');
  const [nodeMobileNumber, setNodePhoneNumber] = useState('');
  const [nodeUserName, setNodeUserName] = useState('');
  const [nodeType, setNodeType] = useState('');
  const [getNodeId, setNodeId] = useState('');
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState('');
  /* End Create Form Site */

  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? 's' : ''}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? 's' : ''}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? 's' : ''}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? 's' : ''}, ${
      labels[othersRate]
    }`;
  }

  /* Asset Live Monoitoring */
  const [markers, setMarkers] = useState([]);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState('');
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [assetPostionList, setAssetPositionList] = useState({});
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState('');
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [timeStamp, setTimeStamp] = useState('');

  const getTimeStamp = () => {
    var date = new Date();
    var current_date =
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    var current_time =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    var date_time = current_date + ' ' + current_time;
    setTimeStamp(date_time);
    return date_time;
  };

  useEffect(() => {
    getCategory();
    getRoleNameofOperator();
    getAssetVerticalsIdList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    getModelsList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList('consumer2')
      .then((data) => {
        console.log('asset verticals:', data);
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalName(data[0].friendlyName);

          setVerticalsIdList(data);
        } else {
          setSelectedVerticalsId('');
          setSelectedVerticalName('');
          setVerticalsIdList([]);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== '') {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(' ', ''),
        'consumer2'
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory('');
            setCategoryTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== '' && selectedCategory !== '') {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(' ', ''),
        selectedCategory.replace(' ', ''),
        'consumer2'
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes('');
            setAssertTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getModelsList = () => {
    if (
      selectedVerticalsId !== '' &&
      selectedCategory !== '' &&
      selectedAssertTypes !== ''
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(' ', ''),
        selectedCategory.replace(' ', ''),
        selectedAssertTypes.replace(' ', ''),
        'consumer2'
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel('');
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
  };

  const getRoleNameofOperator = () => {
    MainService.getRoleNameofOperator(parentId, username)
      .then((res) => {
        console.log('role resp:', res);
        setRoleName(res);
        getRoleResources(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getRoleResources = (roleName) => {
    MainService.getRoleResources(parentId, roleName)
      .then((res) => {
        console.log('resouces list:', res.role.resources);
        var filtered = res.role.resources.filter(function (el) {
          return el.resourcename !== '';
        });
        setRoleResourcesList(filtered);
        setFilteredRolesList(filtered);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getRoleResourceAssets = (resourceId) => {
    MainService.getRoleResourceAssets(parentId, resourceId)
      .then((res) => {
        console.log('getRoleResourceAssets', res);
        setRoleResourceAssetsList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* Create Site */
  /* Create Node & Site */

  const getAllSitesList = () => {
    MainService.getAllSiteTypes('consumer2')
      .then((res) => {
        setSiteData(res.sitetypes);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteCreateHandler = () => {
    var siteDetails = {
      farmerusername: getFarmerUserName,
      parentid: username,
      sitename: getSiteName,
      mailid: getFarmerUserName,
      phone: getPhone,
      sitetype: getSiteType,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: assetVerticalFriendlyNameValue,
    };

    var siteBody = [];
    for (var property in siteDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(siteDetails[property]);
      siteBody.push(encodedKey + '=' + encodedValue);
    }
    siteBody = siteBody.join('&');
    MainService.createSite(siteBody)
      .then((res) => {
        return NotificationManager.success('Site Created');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteCreatePopUp(!getSitePopUp);
    getRoleResources(roleName);
  };
  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );

  const siteCreationTitle = 'Create New Site';
  const siteModelBody = (
    <div>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='farmerName'> Farmer User Name:</InputLabel>
        <Input
          id='farmerName'
          name='farmerName'
          type='text'
          value={getFarmerUserName}
          onChange={(e) => {
            setFarmerUserName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='mailid'> Mail ID:</InputLabel>
        <Input
          disabled
          id='mailid'
          name='mailid'
          type='text'
          value={getFarmerUserName}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='phone'>Phone Number</InputLabel>
        <Input
          id='phone'
          name='phone'
          type='text'
          value={getPhone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='sitename'> Site Name:</InputLabel>
        <Input
          id='sitename'
          name='sitename'
          type='text'
          value={getSiteName}
          onChange={(e) => {
            setSiteName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='parentid'> Parent Id:</InputLabel>
        <Input
          disabled
          id='parentid'
          name='parentid'
          type='text'
          value={username}
        />
      </FormControl>
      <FormControl
        variant='standard'
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id='assetVerticalID'
          name='assetVerticalID'
          type='text'
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsIdHandler(e);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Asset Vertical not found</h4>
          )}
        </Select>
      </FormControl>

      <FormControl
        variant='standard'
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Type</InputLabel>
        <Select
          id='selectedModel'
          value={getSiteType}
          onChange={(event) => setSiteType(event.target.value)}
          label='Select Model'
        >
          {getSiteData &&
            getSiteData.map((idObj, index) => (
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant='contained'
          color='primary'
          className='registerBtn'
          type='submit'
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className='forgotPwdLink'
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          boxOrient: '0',
          display: 'block',
          fontSize: '20',
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Of Create Node & Site */

  /* Start of Site Creation with Node Id */

  const createSitWithNodeId = () => {
    setShowCreateSitePopup(!showCreateSitePopup);
  };
  const siteAndNodeCreateHandler = () => {
    var details = {
      nodeid: getNodeId,
      parentid: username,
      sitename: getSiteName,
      mailid: nodeEmail,
      phone: nodeMobileNumber,
      sitetype: nodeType,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: assetVerticalFriendlyNameValue,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    MainService.createSiteWithNodeId(formBody)
      .then((res) => {
        setShowCreateSiteDialog(!showCreateSiteDialog);
        return NotificationManager.success('Site and Node Created');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getRoleResources(roleName);
  };
  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
  };

  const submitForNode = () => {
    MainService.getNodeInfoBasedOnNodeId(getNodeId)
      .then((data) => {
        if (data) {
          setNodeUserName(data.username);
          setNodePhoneNumber(data.phone);
          setNodeEmail(data.email);
          setNodeType(data.nodetype);
        } else {
          return NotificationManager.error('invalid node id');
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });

    setShowCreateSitePopup(!showCreateSitePopup);
    setShowCreateSiteDialog(!showCreateSiteDialog);
  };
  const closeSiteWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSitePopup(!showCreateSitePopup);
      }}
    >
      X
    </span>
  );

  const closeSiteCreationWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSiteDialog(!showCreateSiteDialog);
      }}
    >
      X
    </span>
  );
  const siteAndNodeCreationTitle = 'Create New Node & Site';

  const siteCreationWithNodeModelBody = (
    <div>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='parentId'> Node ID:</InputLabel>
        <Input
          id='nodeid'
          name='nodeid'
          type='text'
          value={getNodeId}
          onChange={(e) => setNodeId(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant='contained'
          color='primary'
          className='registerBtn'
          type='submit'
          onClick={submitForNode}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className='forgotPwdLink'
        onClick={() => {
          setShowCreateSitePopup(!showCreateSitePopup);
        }}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          boxOrient: '0',
          display: 'block',
          fontSize: '20',
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  const siteCreationModelBody = (
    <div>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='parentId'> Node ID:</InputLabel>
        <Input
          disabled
          id='parentId'
          name='parentId'
          type='text'
          value={getNodeId}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='farmerName'> Farmer User Name:</InputLabel>
        <Input
          id='farmerName'
          name='farmerName'
          type='text'
          value={nodeUserName}
          //onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='mailid'> Mail ID:</InputLabel>
        <Input
          id='mailid'
          name='mailid'
          type='text'
          value={nodeEmail}
          //onChange={(e) => setMailId(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='phone'>Phone Number</InputLabel>
        <Input
          id='phone'
          name='phone'
          type='text'
          value={nodeMobileNumber}
          //onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='sitename'> Site Name:</InputLabel>
        <Input
          id='sitename'
          name='sitename'
          type='text'
          value={getSiteName}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='sitename'> Parent Id:</InputLabel>
        <Input
          id='sitename'
          name='sitename'
          type='text'
          value={username}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl
        variant='standard'
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id='assetVerticalID'
          name='assetVerticalID'
          type='text'
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsIdHandler(e);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Asset Vertical not found</h4>
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='siteType'>Site Type</InputLabel>
        <Input id='siteType' name='siteType' type='text' value={nodeType} />
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant='contained'
          color='primary'
          className='registerBtn'
          type='submit'
          onClick={siteAndNodeCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className='forgotPwdLink'
        onClick={() => {
          setShowCreateSiteDialog(!showCreateSiteDialog);
        }}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          boxOrient: '0',
          display: 'block',
          fontSize: '20',
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End of Site Creation with Node Id */

  /* End Of Create Site */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUserType = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const sitesListViewClick = (site) => {
    setResourceName(site.resourcename);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(true);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    getRoleResourceAssets(site.resourceid);
  };
  const sitesGridViewClick = (site) => {
    setResourceName(site.resourcename);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    getRoleResourceAssets(site.resourceid);
  };
  const assetsListViewClick = (site) => {
    setResourceName(site.resourcename);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(true);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    getRoleResourceAssets(site.resourceid);
  };
  const assetsGridViewClick = (site) => {
    setResourceName(site.resourcename);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    getRoleResourceAssets(site.resourceid);
  };

  const getCategory = () => {
    if (parentId !== '' && selectedCategoryName !== '') {
      MainService.getCategory('consumer2', selectedCategoryName)
        .then((data) => {
          if (data) {
            setSelectedCategoryId(data);
          } else {
            setSelectedCategoryId('');
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error('error');
        });
    }
  };
  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSubCategory(data[0].contentsubcategoryname);
          setsubCategoriesList(data);
          setIsLoading(false);
        } else {
          setsubCategoriesList(data);
          setSelectedSubCategory('');
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  const submitSubCategoryDeleteClick = () => {
    MainService.deleteContentSubCategory(subCategoryNameValue.replace(' ', ''))
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  const handleClose = () => {
    setSubCategoryDialog(!subcategorydialog);
  };
  const submitSubCategoryClick = (evt) => {
    if (!subCategoryNameValue) {
      NotificationManager.warning('Please enter Sub-Category Name');
      return;
    }
    let subCategoryData = {
      categoryid: selectedCategoryId,
      categoryname: selectedCategoryName,
      contentsubcategoryname: subCategoryNameValue,
      parentid: parentId,
      contentsubcategorycreator: username,
    };
    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    getSubCategories();
    setSubCategoryDialog(!subcategorydialog);
    console.log('subCategoryData:', subCategoryDataString);
  };
  /*Photo Upload */
  const uploadPhotoClick = () => {
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };
  const submitUploadPhotoClick = () => {
    if (uploadPhotoFile) {
      const uploadPhotoData = new FormData();
      uploadPhotoData.append('file', uploadPhotoFile);
      uploadPhotoData.append('mimetype', uploadPhotoFile.type);
      uploadPhotoData.append('categoryid', selectedCategoryId);
      uploadPhotoData.append('categoryname', selectedCategoryName);
      //uploadPhotoData.append("contentsubcategory", subCategoryNameValue);
      uploadPhotoData.append('contentname', uploadPhotoNameValue);
      uploadPhotoData.append('assetdeveloperid', parentId);
      uploadPhotoData.append('contentdescription', descriptionValue);
      uploadPhotoData.append('contentcreator', username);
      uploadPhotoData.append('contentformat', 'DOC');
      uploadPhotoData.append('price', priceValue);
      uploadPhotoData.append('filedescription', uploadPhotoFileDescription);
      uploadPhotoData.append('contentownerid', username);
      uploadPhotoData.append('unit', 'INR');
      uploadPhotoData.append('discountedprice', discountValue);
      uploadPhotoData.append('expirydate', expiryDateValue);
      uploadPhotoData.append('event', 'none');
      console.log('create uploadPhoto data:', uploadPhotoData);
      MainService.createContent(uploadPhotoData, parentId)
        .then((res) => {
          return NotificationManager.success('UploadPhoto uploaded');
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error('please choose file');
    }

    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };

  const closeUploadPhotoModal = (
    <span
      onClick={() => {
        setShowUploadPhotoPopup(!uploadPhotoPopup);
      }}
    >
      X
    </span>
  );
  const titleUploadPhoto = 'Upload Photo';
  const uploadPhotoBody = (
    <div>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='categoryName'>Category Name :</InputLabel>
        <Input
          disabled
          id='categoryName'
          name='categoryName'
          type='text'
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>

      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='uploadPhotoName'>UploadPhoto Name :</InputLabel>
        <Input
          id='uploadPhotoName'
          name='uploadPhotoName'
          type='text'
          value={uploadPhotoNameValue}
          onChange={(e) => {
            setUploadPhotoNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant='standard'
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel htmlFor='contentOwner'>Content Owner</InputLabel>
        <Input
          disabled
          id='contentOwner'
          name='contentOwner'
          type='text'
          value={username}
        ></Input>
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='contentCreator'> Content Creator:</InputLabel>
        <Input
          disabled
          id='contentCreator'
          name='contentCreator'
          type='text'
          value={username}
        />
      </FormControl>

      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='zone'> Content Approver:</InputLabel>
        <Input
          disabled
          id='contentApprover'
          name='contentApprover'
          type='text'
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='doc'>file:</InputLabel>
        <Input
          id='doc'
          name='doc'
          type='file'
          onChange={(e) => {
            setUploadPhotoFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className='loginFields'>
        <InputLabel htmlFor='description'> Description:</InputLabel>
        <Input
          id='description'
          name='description'
          type='text'
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="price"> Price:</InputLabel>
        <Input
          id="price"
          name="price"
          type="text"
          value={priceValue}
          onChange={(e) => {
            setPriceValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="expiryDate"> Expiry Date:</InputLabel>
        <Input
          id="expiryDate"
          name="expiryDate"
          type="date"
          value={expiryDateValue}
          onChange={(e) => {
            setExpiryDateValue(e.target.value);
          }}
        />
      </FormControl> 
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="discount"> Discount:</InputLabel>
        <Input
          id="discount"
          name="discount"
          type="text"
          value={discountValue}
          onChange={(e) => {
            setDiscountValue(e.target.value);
          }}
        />
      </FormControl>
      
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="fileDescription">file Description:</InputLabel>
        <Input
          id="fileDescription"
          name="fileDescription"
          type="file"
          onChange={(e) => {
            setUploadPhotoFileDescription(e.target.files[0]);
          }}
        />
      </FormControl> */}
      <br />
      <FormControl>
        <Button
          variant='contained'
          color='primary'
          className='registerBtn'
          type='submit'
          onClick={() => {
            submitUploadPhotoClick();
          }}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className='forgotPwdLink'
        onClick={() => {
          setShowUploadPhotoPopup(!uploadPhotoPopup);
        }}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          boxOrient: '0',
          display: 'block',
          fontSize: '20',
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* end of photo upload */
  const siteIssuesClick = (siteId) => {
    setResourceId(siteId);
    setGridViewSiteIssues(true);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    MainService.siteIssue(parentId, siteId)
      .then((res) => {
        setSiteIssuesList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const siteAlarmsClick = (siteId) => {
    setResourceId(siteId);
    setGridViewSiteAlarms(true);
    setGridViewAssetAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    MainService.getSiteAlarms(parentId, siteId)
      .then((res) => {
        setSiteAlarmsList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetAlarmsClick = (assetId) => {
    setSelectedAssetId(assetId);
    setGridViewAssetAlarms(true);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    MainService.getAssetAlarms(parentId, assetId)
      .then((res) => {
        setAssetAlarmsList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetMortalityClick = () => {
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(true);
    setAssetMonitoringView(false);
    MainService.getAssetMortality(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          setAssetMortalityList(res);
        } else {
          setAssetMortalityList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const submitSiteIssueClick = () => {
    let issueData = {
      operatorid: username,
      solutionid: parentId,
      siteid: resourceId,
      issuedescription: descriptionValue,
    };
    const issueDataStringify = JSON.stringify(issueData);
    console.log('site issue data:', issueDataStringify);
    MainService.createSiteIssue(username, issueDataStringify)
      .then((res) => {
        NotificationManager.success(res);
        siteIssuesClick(resourceId);
      })
      .catch((err) => {
        NotificationManager.error('status not supported');
      });

    setSiteIssuePopup(!siteIssuePopup);
  };

  const submitSitealarmClick = () => {
    let alarmData = {
      source: parentId,
      assetverticalid: selectedVerticalsId,
      assetverticalname: selectedVerticalName,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      siteid: resourceId,
      alarmcategory: alarmCategoryValue,
      alarmname: alarmNameValue,
    };

    const alarmDataStringify = JSON.stringify(alarmData);
    MainService.addSiteAlarm(parentId, alarmDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });

    setSiteAlarmPopup(!siteAlarmPopup);
  };

  const submitAssetAlarmClick = () => {
    let alarmData = {
      source: parentId,
      assetverticalid: selectedVerticalsId,
      assetverticalname: selectedVerticalName,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      siteid: resourceId,
      assetid: selectedAssetId,
      alarmcategory: alarmCategoryValue,
      alarmname: alarmNameValue,
    };

    const alarmDataStringify = JSON.stringify(alarmData);
    MainService.addAssetAlarm(parentId, alarmDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });

    setAssetAlarmPopup(!assetAlarmPopup);
  };

  const siteReportsClick = (site) => {
    setResourceId(site.resourceid);
    setResourceName(site.resourcename);

    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(true);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    MainService.getSiteReports(parentId, site.resourceid)
      .then((res) => {
        if (res.length > 0) {
          console.log('site reports list', res);
          setSiteReportsList(res);
        } else {
          setSiteReportsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteReportSubmitClick = () => {
    let reportData = {
      solutionid: parentId,
      siteid: resourceId,
      operationalstatus: operationalStatusValue,
      reportdescription: reportDescriptionValue,
      timestamp: getTimeStamp(),
    };
    MainService.recordReport(reportData, username, 'Site')
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteReportPopup(!siteReportPopup);
  };

  const attendanceClick = (asset) => {
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);

    setAttendancePopup(!attendancePopup);
  };
  const assetAttendanceSubmitClick = () => {
    let attendanceData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      parentid: selectedParentId,
      userid: username,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
    };
    const attendanceDataStringify = JSON.stringify(attendanceData);
    MainService.addAttendanceLocation(attendanceDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAttendancePopup(!attendancePopup);
  };
  const assetIssueClick = (asset) => {
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedSiteId(asset.siteId);
    console.log('asset data:', asset);

    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(true);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);

    MainService.getAssetIssues(asset.solutionId, asset.siteId, asset.assetId)
      .then((res) => {
        console.log('get aset issues:', res);
        setAssetIssuesList(res);
        setFilterUsers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetIssueSubmitClick = () => {
    let issueData = {
      operatorid: username,
      solutionid: selectedParentId,
      siteid: selectedSiteId,
      assetid: selectedAssetId,
      issuedescription: descriptionValue,
    };
    const issueDataStringify = JSON.stringify(issueData);
    MainService.createAssetIssue(username, issueDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error('status not supported');
      });
    setAssetIssuePopup(!assetIssuePopup);
  };
  const assetHealthClick = (asset) => {
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setSelectedSiteId(asset.siteId);
    console.log('asset data:', asset);
    setAssetHealthPopup(!assetHealthPopup);
  };
  const assetHealthSubmitClick = () => {
    let healthData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      assetdeveloperid: username,
      medicinedate: medicinedateValue,
      vaccinationdate: vaccinationdateValue,
      examine: examineValue,
      surgicalprocedure: suricalProcedureValue,
      vaccinationtype: vaccinationTypeValue,
      medicinetype: medicineTypeValue,
      diseasetype: diseaseTypeValue,
      treatmentdate: treatmentDateValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
    };
    const healthDataStringify = JSON.stringify(healthData);
    MainService.addAssetHealth(healthDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetHealthPopup(!assetHealthPopup);
  };
  const assetReportClick = (asset) => {
    setSelectedModelId(asset.modelId);
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);

    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(true);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
    MainService.assetReports(parentId, asset.siteId, asset.assetId)
      .then((res) => {
        console.log('Asset reports list', res);
        if (res.length > 0) {
          setAssetReportsList(res);
        } else {
          setAssetReportsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetReportSubmitClick = () => {
    let reportData = {
      solutionid: parentId,
      siteid: resourceId,
      modelid: selectedModelId,
      assetid: selectedAssetId,
      operationalstatus: operationalStatusValue,
      reportdescription: reportDescriptionValue,
      timestamp: getTimeStamp(),
    };
    MainService.recordReport(reportData, username, 'Asset')
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetReportPopup(!assetReportPopup);
  };
  const assetPatrolClick = (asset) => {
    setSelectedSiteId(asset.siteId);
    setSelectedAssetId(asset.assetId);
    setSelectedParentId(asset.solutionId);
    setPatrolPopup(!patrolPopup);
  };

  const assetPatrolSubmitClick = () => {
    let patrolData = {
      assetid: selectedAssetId,
      assetfriendlyname: selectedAssetId,
      parentid: selectedParentId,
      userid: username,
      mobilenumber: mobileNumberValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: descriptionValue,
    };

    const patrolDataStringify = JSON.stringify(patrolData);
    MainService.addAssetPatrolingDetails(patrolDataStringify)
      .then((res) => {
        NotificationManager.success(res['SUCCESS MESSAGE']);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setPatrolPopup(!patrolPopup);
  };

  const assetFeedbackClick = (asset) => {
    setSelectedParentId(asset.solutionId);
    setSelectedAssetId(asset.assetId);
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };
  const assetFeedbackSubmitClick = () => {
    let addFeedback = {
      assetdeveloperid: selectedParentId,
      assetid: selectedAssetId,
      ' how_would_you_rate_your_overall_customer_experience?': customerExp,
      ' how_satisfied_were_you_with_the_timeliness_of_delivery?': deliveryExp,
      'how_satisfied_were_you_with_the_product?': productExp,
      'how_satisfied_were_you_with_the_customer_support?': supportExp,
      'would_you_recommend_our_product_or_service_to_others?': othersExp,
      remark: remarks,
    };
    const addFeedbackData = JSON.stringify(addFeedback);
    MainService.addAssetFeedback(username, addFeedbackData)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };
  const backToHome = () => {
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
  };
  const backToAssets = () => {
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(false);
  };
  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRoleResourceAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRoleResourceAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setAssetSearched('');
    requestFarmGridSearch(assetSearched);
  };

  const requestSitesGridSearch = (searchedVal) => {
    const filteredRows = filteredRolesList.filter((row) => {
      return row.resourcename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRoleResourcesList(filteredRows);
  };

  const cancelSitesGridSearch = () => {
    setFarmGridSearched('');
    requestSitesGridSearch(farmgridsearched);
  };

  /* Asset Live Monitoring */

  const assetMonitoringHandler = (assetId, lat1, lng1) => {
    setSelectedAssetId(assetId);
    setGridViewAssetAlarms(false);
    setGridViewSiteAlarms(false);
    setListViewSiteAlarms(false);
    setGridViewSiteIssues(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetReports(false);
    setGridViewAssetMortality(false);
    setAssetMonitoringView(true);

    MainService.assetCurrentLocation(parentId, username, assetId)
      .then((res) => {
        console.log('current Location monitor:', res);
        setSelectedAssetId(res.assetId);
        setAssetMonitoringStatus(res.status);
        setMarkers([...markers, { geoLocation: res.geoLocation }]);
        setAssetMonitorTimestamp(res.ts);

        if (marker_position !== undefined) {
          const ENDPOINT = window.socketUrl;
          const socket = socketIOClient(ENDPOINT);
          socket.on('FromAPI', (data) => {
            console.log(JSON.parse(data.payload).geoLocation);
            setMarkers([
              ...markers,
              { geoLocation: JSON.parse(data.payload).geoLocation },
            ]);
          });
          return () => {
            socket.disconnect();
          };
        }
      })
      .catch((e) => {
        return NotificationManager.error('location not found');
      });
  };
  const getLocationHandler = (props) => {
    console.log(props);
  };

  const AssetLiveGoogleMap = withGoogleMap(() => {
    return marker_position.lat === 0 && marker_position.lng === 0 ? (
      <GoogleMap
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker>
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker
          position={{
            lat: parseFloat(marker_position.lat),
            lng: parseFloat(marker_position.lng),
          }}
        >
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>

        {Object.entries(assetPostionList).map(([key, value]) => {
          return value ? (
            <Marker position={{ lat: value.lat, lng: value.lng }}>
              <InfoWindow options={{ disableAutoPan: false }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            <Marker
              position={{
                lat: parseFloat(marker_position.lat),
                lng: parseFloat(marker_position.lng),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          );
        })}
      </GoogleMap>
    );
  });

  /* End of Asset Monitoring */
  return (
    <div>
      <React.Fragment>
        <div className='container' id='mainContentList'>
          {/* {gridViewAssetReports ? (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create Site Issue"
              className={classes.fab}
              onClick={() => {
                setAssetReportPopup(!assetReportPopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
          {gridViewSiteReports ? (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create Site Issue"
              className={classes.fab}
              onClick={() => {
                setSiteReportPopup(!siteReportPopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
          {gridViewSiteIssues ? (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create Site Issue"
              className={classes.fab}
              onClick={() => {
                setSiteIssuePopup(!siteIssuePopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )} 
          {gridViewAssetIssues ? (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create Asset Issue"
              className={classes.fab}
              onClick={() => {
                setAssetIssuePopup(!assetIssuePopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}*/}
          {gridViewSiteAlarms ? (
            <Fab
              color='primary'
              size={'large'}
              aria-label='add'
              title='Create Site Alarm'
              className={classes.fab}
              onClick={() => {
                setSiteAlarmPopup(!siteAlarmPopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
          {gridViewAssetAlarms ? (
            <Fab
              color='primary'
              size={'large'}
              aria-label='add'
              title='Create Asset Alarm'
              className={classes.fab}
              onClick={() => {
                setAssetAlarmPopup(!assetAlarmPopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}

          {listViewSites || gridViewSites ? (
            <>
              <div className='cardStyleForDashboardItemsBody'>
                <h4>Total Sites : {roleResourcesList.length}</h4>
              </div>
            </>
          ) : (
            <></>
          )}
          {listViewAssets || gridViewAssets ? (
            <>
              <div className='cardStyleForDashboardItemsBody'>
                <h4>Total Assets : {roleResourceAssetsList.length}</h4>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Assets */}
          {listViewAssets === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <br />
              {roleResourceAssetsList.length > 0 ? (
                <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableRow align='center'>
                          <TableCell align='center' colSpan={19}>
                            List Of "{resourceName}" Assets
                          </TableCell>
                        </TableRow>
                        <TableRow align='center'>
                          <TableCell align='center' colSpan={1}>
                            Asset Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Solution Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Site Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Category
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Asset Type
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Model Id
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Latitude
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Longitude
                          </TableCell>
                          <TableCell align='center' colSpan={1}>
                            Asset Status
                          </TableCell>
                          <TableCell align='center' colSpan={10}>
                            Operate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {roleResourceAssetsList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((operators, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align='center'>
                                  {operators.assetId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.solutionId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.siteId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.category}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.assetType}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.modelId}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.latitude}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.longitude}
                                </TableCell>
                                <TableCell align='center'>
                                  {operators.assetStatus}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      attendanceClick(operators);
                                    }}
                                  >
                                    Attendance
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetIssueClick(operators);
                                    }}
                                  >
                                    Asset Issue
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetHealthClick(operators);
                                    }}
                                  >
                                    Asset Health
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetReportClick(operators);
                                    }}
                                  >
                                    Asset Report
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetMortalityClick();
                                    }}
                                  >
                                    Asset Mortality
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetPatrolClick(operators);
                                    }}
                                  >
                                    Patrol
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      assetFeedbackClick(operators);
                                    }}
                                  >
                                    Asset Feedback
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    style={{ marginRight: '10px' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      uploadPhotoClick(operators);
                                    }}
                                  >
                                    Upload Photo
                                  </Button>
                                </TableCell>

                                <TableCell></TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component='div'
                    count={roleResourceAssetsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No Assets Found</h4>
              )}
            </Box>
          ) : null}

          {gridViewAssets === true ? (
            <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={assetSearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              <h4>List Of "{resourceName}" Assets</h4>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {roleResourceAssetsList.length > 0 ? (
                  <div className='cardStyleForDashboardItems'>
                    {roleResourceAssetsList.map((ope, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Asset Id:</b>
                            <b>{ope.assetId}</b>
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {ope.solutionId}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {ope.siteId}
                          </p>
                          <p>
                            <b>Category:</b>
                            {ope.category}
                          </p>
                          <p>
                            <b>Asset Type:</b>
                            {ope.assetType}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {ope.modelId}
                          </p>
                          <p>
                            <b>Latitude:</b>
                            {ope.latitude}
                          </p>
                          <p>
                            <b>Longitude:</b>
                            {ope.longitude}
                          </p>
                          <p>
                            <b>Asset Status:</b>
                            {ope.assetStatus}
                          </p>
                          <div>
                            <h4 className='cardactiontitle'>Operate</h4>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                attendanceClick(ope);
                              }}
                            >
                              Attendance
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetIssueClick(ope);
                              }}
                            >
                              Asset Issue
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetHealthClick(ope);
                              }}
                            >
                              Asset Health
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetReportClick(ope);
                              }}
                            >
                              Asset Report
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetPatrolClick(ope);
                              }}
                            >
                              Patrol
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetFeedbackClick(ope);
                              }}
                            >
                              Asset Feedback
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                uploadPhotoClick(ope);
                              }}
                            >
                              Upload Photo
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetMortalityClick();
                              }}
                            >
                              Asset Mortality
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() => {
                                assetAlarmsClick(ope.assetId);
                              }}
                            >
                              Asset Alarm
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: '10px' }}
                              size='small'
                              variant='contained'
                              onClick={() =>
                                assetMonitoringHandler(
                                  ope.assetId,
                                  ope.latitude,
                                  ope.longitude
                                )
                              }
                            >
                              Asset Monitoring
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Assets Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />
              <Map
                routeLocationData={markers}
                getLocationHandler={getLocationHandler}
              ></Map>
            </Box>
          ) : null}
          {/* End of Assets */}
          {/* Sites */}
          {/* {listViewSites || gridViewSites ? (
            <>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Node&Site Creation"
                className={classes.fab}
                onClick={() => createSiteHandler()}
              >
                <AddIcon ></AddIcon>
              </Fab>
              <Fab
                style={{ marginBottom: "60px" }}
                color="primary"
                size={"large"}
                aria-label="add"
                title="Site Creation With Node"
                className={classes.fab}
                onClick={() => createSitWithNodeId()}
              >
                <AddIcon ></AddIcon>
              </Fab>
            </>
          ) : (
            <></>
          )} */}

          {listViewSites === true ? (
            <>
              {' '}
              {isLoading ? (
                <div className='cardStyleForDashboardItems'>
                  <CircularProgress />
                </div>
              ) : (
                <Box sx={{ width: '100%', marginBottom: 30 }}>
                  <SearchBar
                    value={farmgridsearched}
                    onChange={(searchVal) => requestSitesGridSearch(searchVal)}
                    onCancelSearch={() => cancelSitesGridSearch()}
                  />
                  <div className='topHeadersWrapper'>
                    <div className='topHeaders'>
                      <Button
                        startIcon={<ListIcon />}
                        size='large'
                        onClick={sitesListViewClick}
                      ></Button>
                      <Button
                        startIcon={<GridIcon />}
                        size='large'
                        onClick={sitesGridViewClick}
                      ></Button>
                    </div>
                  </div>
                  <div className='MuiGrid-root'>
                    <div className='cardStyleForDashboardItems'>
                      <div className='cardStyleForDashboardItems'>
                        {roleResourcesList ? (
                          <Paper sx={{ width: '100%' }}>
                            <TableContainer sx={{ maxHeight: 350 }}>
                              <Table stickyHeader aria-label='sticky table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell colSpan={10} align='center'>
                                      List Of Sites
                                    </TableCell>
                                  </TableRow>
                                  <TableRow align='center'>
                                    <TableCell align='center' colSpan={1}>
                                      Site Name
                                    </TableCell>
                                    <TableCell align='center' colSpan={1}>
                                      Site Id
                                    </TableCell>
                                    <TableCell align='center' colSpan={1}>
                                      Site Type
                                    </TableCell>
                                    <TableCell align='center' colSpan={4}>
                                      View Assets
                                    </TableCell>
                                    <TableCell align='center' colSpan={4}>
                                      Option
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {roleResourcesList
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((site, index) => {
                                      return (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={index}
                                        >
                                          <TableCell align='center'>
                                            {site.resourcename}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {site.resourceid}
                                          </TableCell>
                                          <TableCell align='center'>
                                            {site.resourcetype}
                                          </TableCell>
                                          <TableCell align='center'></TableCell>
                                          <TableCell align='center'>
                                            <Button
                                              startIcon={<ListIcon />}
                                              size='large'
                                              onClick={() => {
                                                assetsListViewClick(site);
                                              }}
                                            ></Button>
                                          </TableCell>
                                          <TableCell align='center'>
                                            <Button
                                              startIcon={<GridIcon />}
                                              size='large'
                                              onClick={() => {
                                                assetsGridViewClick(site);
                                              }}
                                            ></Button>
                                          </TableCell>
                                          <TableCell align='center'></TableCell>
                                          <TableCell align='center'></TableCell>
                                          <TableCell align='center'>
                                            <Button
                                              size='small'
                                              variant='contained'
                                              onClick={() => {
                                                siteIssuesClick(
                                                  site.resourceid
                                                );
                                              }}
                                            >
                                              Site Issue
                                            </Button>
                                          </TableCell>
                                          <TableCell align='center'>
                                            <Button
                                              size='small'
                                              variant='contained'
                                              onClick={() => {
                                                siteReportsClick(site);
                                              }}
                                            >
                                              Site Report
                                            </Button>
                                          </TableCell>
                                          <TableCell align='center'></TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component='div'
                              count={roleResourcesList.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </Paper>
                        ) : (
                          <h4>No Site List Found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </>
          ) : null}

          {gridViewSites === true ? (
            <>
              {' '}
              {isLoading ? (
                <div className='cardStyleForDashboardItems'>
                  <CircularProgress />
                </div>
              ) : (
                <Box sx={{ width: '100%', marginBottom: 30 }}>
                  <SearchBar
                    value={farmgridsearched}
                    onChange={(searchVal) => requestSitesGridSearch(searchVal)}
                    onCancelSearch={() => cancelSitesGridSearch()}
                  />
                  <h3>List Of Sites</h3>
                  <div className='topHeadersWrapper'>
                    <div className='topHeaders'>
                      <Button
                        startIcon={<ListIcon />}
                        size='large'
                        onClick={sitesListViewClick}
                      ></Button>
                      <Button
                        startIcon={<GridIcon />}
                        size='large'
                        onClick={sitesGridViewClick}
                      ></Button>
                    </div>
                  </div>
                  <br />
                  <div className='cardStyleForDashboardItems'>
                    {roleResourcesList.length > 0 ? (
                      <div className='cardStyleForDashboardItems'>
                        {roleResourcesList.map((site, index) => (
                          <div
                            key={index}
                            className='cardStyleForDashboardItemsBody'
                          >
                            <span>
                              <p>
                                <b>Site Name:</b>
                                {site.resourcename}
                              </p>
                              <p>
                                <b>Site Id:</b>
                                {site.resourceid}
                              </p>
                              <p>
                                <b>Site Type:</b>
                                {site.resourcetype}
                              </p>
                              <h4 className='cardactiontitle'>View Assets</h4>
                              <div>
                                <Button
                                  title='Assets List View'
                                  startIcon={<ListIcon />}
                                  size='large'
                                  onClick={() => {
                                    assetsListViewClick(site);
                                  }}
                                ></Button>

                                <Button
                                  title='Assets Grid View'
                                  startIcon={<GridIcon />}
                                  size='large'
                                  onClick={() => {
                                    assetsGridViewClick(site);
                                  }}
                                ></Button>
                              </div>
                              <h4 className='cardactiontitle'>Options</h4>
                              <div>
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    siteIssuesClick(site.resourceid);
                                  }}
                                >
                                  Site Issue
                                </Button>{' '}
                                &nbsp;
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    siteReportsClick(site);
                                  }}
                                >
                                  Site Report
                                </Button>
                              </div>
                              &nbsp;
                              <div>
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    siteAlarmsClick(site.resourceid);
                                  }}
                                >
                                  Site Alarm
                                </Button>
                              </div>
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>No Site Found.</h4>
                    )}
                  </div>
                </Box>
              )}
            </>
          ) : null}
          {/* End of Sites */}
          {/* Site Issues */}
          {gridViewSiteIssues === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Site Issues</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {siteIssuesList ? (
                  <div className='cardStyleForDashboardItems'>
                    {siteIssuesList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Operator Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Issues Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Issues */}
          {/* Site Alarms */}
          {gridViewSiteAlarms === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Site Alarms</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {sitealarmsList ? (
                  <div className='cardStyleForDashboardItems'>
                    {sitealarmsList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Alarm Id:</b>
                            {site.alarmid}
                          </p>
                          <p>
                            <b>Source Id:</b>
                            {site.source}
                          </p>
                          <p>
                            <b>Assetvertical Id:</b>
                            {site.assetverticalid}
                          </p>
                          <p>
                            <b>Assetvertical Name:</b>
                            {site.assetverticalname}
                          </p>
                          <p>
                            <b>Category:</b>
                            {site.category}
                          </p>
                          <p>
                            <b>Legacyasset Type:</b>
                            {site.legacyassettype}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Alarm Type:</b>
                            {site.alarmtype}
                          </p>
                          <p>
                            <b>Alarm Category:</b>
                            {site.alarmcategory}
                          </p>
                          <p>
                            <b>Alarm Name:</b>
                            {site.alarmname}
                          </p>
                          <p>
                            <b>Alarm Status:</b>
                            {site.status}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Alarms Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Alarms */}

          {/* Site Reports */}

          {gridViewSiteReports === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Site Reports</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className='cardStyleForDashboardItems'>
                {siteReportsList.length > 0 ? (
                  <div className='cardStyleForDashboardItems'>
                    {siteReportsList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Operational Status:</b>
                            {site.operationalstatus}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.reportdescription}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Reports Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Reports */}
          {/* Asset Reports */}

          {gridViewAssetReports === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Asset Reports</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToAssets();
                }}
              >
                Back To Assets
              </Button>
              <div className='cardStyleForDashboardItems'>
                {assetReportsList.length > 0 ? (
                  <div className='cardStyleForDashboardItems'>
                    {assetReportsList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {site.assetid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Operational Status:</b>
                            {site.operationalstatus}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.reportdescription}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Reports Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Reports */}
          {/* Asset Issues */}
          {gridViewAssetIssues === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Asset Issues</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToAssets();
                }}
              >
                Back To Assets
              </Button>
              <div className='cardStyleForDashboardItems'>
                {assetIssuesList ? (
                  <div className='cardStyleForDashboardItems'>
                    {assetIssuesList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Operator Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Issues Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Issues */}
          {/* Asset Mortality */}
          {gridViewAssetMortality === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Asset Mortality</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToAssets();
                }}
              >
                Back To Assets
              </Button>
              <div className='cardStyleForDashboardItems'>
                {assetMortalityList ? (
                  <div className='cardStyleForDashboardItems'>
                    {assetMortalityList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Operator Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Mortality Details Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Mortality */}
          {/* Asset Alarms */}
          {gridViewAssetAlarms === true ? (
            <Box sx={{ width: '100%', marginBottom: 30 }}>
              <h3>List Of Asset Alarms</h3>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  backToAssets();
                }}
              >
                Back to Assets
              </Button>
              <div className='cardStyleForDashboardItems'>
                {assetAlarmsList ? (
                  <div className='cardStyleForDashboardItems'>
                    {assetAlarmsList.map((site, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Alarm Id:</b>
                            {site.alarmid}
                          </p>
                          <p>
                            <b>Source Id:</b>
                            {site.source}
                          </p>
                          <p>
                            <b>Assetvertical Id:</b>
                            {site.assetverticalid}
                          </p>
                          <p>
                            <b>Assetvertical Name:</b>
                            {site.assetverticalname}
                          </p>
                          <p>
                            <b>Category:</b>
                            {site.category}
                          </p>
                          <p>
                            <b>Legacyasset Type:</b>
                            {site.legacyassettype}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {site.assetid}
                          </p>
                          <p>
                            <b>Alarm Type:</b>
                            {site.alarmtype}
                          </p>
                          <p>
                            <b>Alarm Category:</b>
                            {site.alarmcategory}
                          </p>
                          <p>
                            <b>Alarm Name:</b>
                            {site.alarmname}
                          </p>
                          <p>
                            <b>Alarm Status:</b>
                            {site.status}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Alarms Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Alarms */}
          {/* Create Site Report */}
          <Dialog
            fullScreen={fullScreen}
            open={siteReportPopup}
            onClose={() => {
              setSiteReportPopup(!siteReportPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Site Report'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Solution Id:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteName'>Site Name:</InputLabel>
                <Input
                  disabled
                  id='siteName'
                  name='siteName'
                  type='text'
                  value={resourceName}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Operational Status:</InputLabel>
                <Select
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={operationalStatusValue}
                  onChange={(e) => {
                    setOperationalStatusValue(e.target.value);
                  }}
                >
                  <MenuItem value='Ready For Onboard'>
                    Ready For Onboard
                  </MenuItem>
                  <MenuItem value='Ready For Whitelabel'>
                    Ready For Whitelabel
                  </MenuItem>
                  <MenuItem value='Whitelabelled'>Whitelabelled</MenuItem>
                  <MenuItem value='In-qa-Progress'>In-qa-Progress</MenuItem>
                  <MenuItem value='In-qa-Success'>In-qa-Success</MenuItem>
                  <MenuItem value='In-qa-Fail'>In-qa-Fail</MenuItem>
                  <MenuItem value='Sellable'>Sellable</MenuItem>
                  <MenuItem value='Sold'>Sold</MenuItem>
                  <MenuItem value='Leased'>Leased</MenuItem>
                  <MenuItem value='Damaged'>Damaged</MenuItem>
                  <MenuItem value='Lost'>Lost</MenuItem>
                  <MenuItem value='Under-Maintenance'>
                    Under-Maintenance
                  </MenuItem>
                  <MenuItem value='Deboard'>Deboard</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Report Description:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={reportDescriptionValue}
                  onChange={(e) => {
                    setReportDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  siteReportSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setSiteReportPopup(!siteReportPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Create Site Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={siteIssuePopup}
            onClose={() => {
              setSiteIssuePopup(!siteIssuePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Site Issue'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Solution Id:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Operator Id:</InputLabel>
                <Input
                  disabled
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  submitSiteIssueClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setSiteIssuePopup(!siteIssuePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Create Site Alarm */}
          <Dialog
            fullScreen={fullScreen}
            open={siteAlarmPopup}
            onClose={() => {
              setSiteAlarmPopup(!siteAlarmPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Site Alarm'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Source:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={parentId}
                />
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVertical Id:</InputLabel>
                <Select
                  id='assetVerticalID'
                  name='assetVerticalID'
                  type='text'
                  value={selectedVerticalsId}
                  onChange={(event) => {
                    setSelectedVerticalsIdHandler(event);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Input
                  disabled
                  id='avName'
                  name='avName'
                  type='text'
                  value={selectedVerticalName}
                />
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  id='selectedCategory'
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                  label='Select Category'
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id='selectedAssertTypes'
                  value={selectedAssertTypes}
                  onChange={(event) => {
                    setSelectedAssertTypes(event.target.value);
                  }}
                  label='Select Type'
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id='selectedModel'
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  label='Select Model'
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Alarm Category:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={alarmCategoryValue}
                  onChange={(e) => {
                    setAlarmCategoryValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Alarm Name:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={alarmNameValue}
                  onChange={(e) => {
                    setAlarmNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  submitSitealarmClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setSiteAlarmPopup(!siteAlarmPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={attendancePopup}
            onClose={() => {
              setAttendancePopup(!attendancePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Attendance Location'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Parent Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>User Id:</InputLabel>
                <Input id='siteId' name='siteId' type='text' value={username} />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='text'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='text'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='text'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetAttendanceSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAttendancePopup(!attendancePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* create sub-category */}
          <Dialog
            fullScreen={fullScreen}
            open={subcategorydialog}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Content Sub-Category'}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetdeveloperid'>
                  {' '}
                  Assetdeveloper Id:
                </InputLabel>
                <Input
                  disabled
                  id='assetdeveloperid'
                  name='assetdeveloperid'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel htmlFor='category'>Category</InputLabel>
                <Input
                  disabled
                  id='category'
                  name='category'
                  type='text'
                  value={selectedCategoryId}
                ></Input>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='subCategoryName'>
                  Sub-Category Name :
                </InputLabel>
                <Input
                  id='subCategoryName'
                  name='subCategoryName'
                  type='text'
                  value={subCategoryNameValue}
                  onChange={(e) => {
                    setSubCategoryNameValue(e.target.value);
                  }}
                ></Input>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={(e) => {
                  submitSubCategoryClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setSubCategoryDialog(!subcategorydialog);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* delete sub-category */}
          <Dialog
            fullScreen={fullScreen}
            open={deleteSubcategoryDialog}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Delete Content Sub-Category'}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetdeveloperid'>
                  {' '}
                  Assetdeveloper Id:
                </InputLabel>
                <Input
                  disabled
                  id='assetdeveloperid'
                  name='assetdeveloperid'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel htmlFor='category'>Category</InputLabel>
                <Input
                  disabled
                  id='category'
                  name='category'
                  type='text'
                  value={selectedCategoryId}
                ></Input>
              </FormControl>
              <FormControl fullWidth={true}>
                <InputLabel>choose Sub-Category</InputLabel>
                <Select
                  id='selectedSubCategory'
                  value={subCategoryNameValue}
                  onChange={(e) => {
                    setSubCategoryNameValue(e.target.value);
                  }}
                  label='Select Sub-Category'
                >
                  {subCategoriesList &&
                    subCategoriesList.map((idObj, index) => (
                      <MenuItem
                        key={`${index} `}
                        value={idObj.contentsubcategoryname}
                      >
                        {idObj.contentsubcategoryname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={(e) => {
                  submitSubCategoryDeleteClick();
                }}
              >
                Delete
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={assetIssuePopup}
            onClose={() => {
              setAssetIssuePopup(!assetIssuePopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Issue'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Parent Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>Operator Id:</InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedSiteId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetIssueSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetIssuePopup(!assetIssuePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Health */}
          <Dialog
            fullScreen={fullScreen}
            open={assetHealthPopup}
            onClose={() => {
              setAssetHealthPopup(!assetHealthPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Health'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  disabled
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetFriendlyName'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  disabled
                  id='assetFriendlyName'
                  name='assetFriendlyName'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetDeveloperId'>
                  AssetDeveloper Id:
                </InputLabel>
                <Input
                  id='assetDeveloperId'
                  name='assetDeveloperId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='medicineDate'>Medicine Date:</InputLabel>
                <Input
                  id='medicineDate'
                  name='medicineDate'
                  type='date'
                  placeholder='25-12-2022'
                  value={medicinedateValue}
                  onChange={(e) => {
                    setMedicineDateValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='vaccinationDate'>
                  Vaccination Date:
                </InputLabel>
                <Input
                  id='vaccinationDate'
                  name='vaccinationDate'
                  type='date'
                  value={vaccinationdateValue}
                  onChange={(e) => {
                    setVaccinationDateValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='treatmentDate'>Treatment Date:</InputLabel>
                <Input
                  id='treatmentDate'
                  name='treatmentDate'
                  type='date'
                  value={treatmentDateValue}
                  onChange={(e) => {
                    setTreatmentDateValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='examine'>Examine:</InputLabel>
                <Input
                  id='examine'
                  name='examine'
                  type='text'
                  value={examineValue}
                  onChange={(e) => {
                    setExamineValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='surgicalProcedure'>
                  Surgical Procedure:
                </InputLabel>
                <Input
                  id='surgicalProcedure'
                  name='surgicalProcedure'
                  type='text'
                  value={suricalProcedureValue}
                  onChange={(e) => {
                    setSuricalProcedureValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='vaccinationType'>
                  Vaccination Type:
                </InputLabel>
                <Input
                  id='vaccinationType'
                  name='vaccinationType'
                  type='text'
                  value={vaccinationTypeValue}
                  onChange={(e) => {
                    setVaccinationTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='medicineType'>Medicine Type:</InputLabel>
                <Input
                  id='medicineType'
                  name='medicineType'
                  type='text'
                  value={medicineTypeValue}
                  onChange={(e) => {
                    setMedicineTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='diseaseType'>Disease Type:</InputLabel>
                <Input
                  id='diseaseType'
                  name='diseaseType'
                  type='text'
                  value={diseaseTypeValue}
                  onChange={(e) => {
                    setDiseaseTypeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='text'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='text'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='text'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetHealthSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetHealthPopup(!assetHealthPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Asset Report */}
          <Dialog
            fullScreen={fullScreen}
            open={assetReportPopup}
            onClose={handleClose}
            aria-labelledby='assetReport'
          >
            <DialogTitle id='assetReport'>{'Add Asset Report'}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedSiteId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Model Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedModelId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Asset Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Operational Status:</InputLabel>
                <Select
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={operationalStatusValue}
                  onChange={(e) => {
                    setOperationalStatusValue(e.target.value);
                  }}
                >
                  <MenuItem value='Ready For Onboard'>
                    Ready For Onboard
                  </MenuItem>
                  <MenuItem value='Ready For Whitelabel'>
                    Ready For Whitelabel
                  </MenuItem>
                  <MenuItem value='Whitelabelled'>Whitelabelled</MenuItem>
                  <MenuItem value='In-qa-Progress'>In-qa-Progress</MenuItem>
                  <MenuItem value='In-qa-Success'>In-qa-Success</MenuItem>
                  <MenuItem value='In-qa-Fail'>In-qa-Fail</MenuItem>
                  <MenuItem value='Sellable'>Sellable</MenuItem>
                  <MenuItem value='Sold'>Sold</MenuItem>
                  <MenuItem value='Leased'>Leased</MenuItem>
                  <MenuItem value='Damaged'>Damaged</MenuItem>
                  <MenuItem value='Lost'>Lost</MenuItem>
                  <MenuItem value='Under-Maintenance'>
                    Under-Maintenance
                  </MenuItem>
                  <MenuItem value='Deboard'>Deboard</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Report Description:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={reportDescriptionValue}
                  onChange={(e) => {
                    setReportDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetReportSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setAssetReportPopup(!assetReportPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Patrol */}
          <Dialog
            fullScreen={fullScreen}
            open={patrolPopup}
            onClose={() => {
              setPatrolPopup(!patrolPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Add Asset Patrol'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Parent Id:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={selectedParentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Mobile Number:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={mobileNumberValue}
                  onChange={(e) => {
                    setMobileNumberValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>User Id:</InputLabel>
                <Input id='siteId' name='siteId' type='text' value={username} />
              </FormControl>

              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='latitude'>Latitude:</InputLabel>
                <Input
                  id='latitude'
                  name='latitude'
                  type='text'
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='longitude'>Longitude:</InputLabel>
                <Input
                  id='longitude'
                  name='longitude'
                  type='text'
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='altitude'>Altitude:</InputLabel>
                <Input
                  id='altitude'
                  name='altitude'
                  type='text'
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <p htmlFor='describe'>Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetPatrolSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setPatrolPopup(!patrolPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Asset Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={assetFeedbackPopup}
            onClose={() => {
              setAssetFeedbackPopup(!assetFeedbackPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Asset Feedback'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='operatorId'>
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id='operatorId'
                  name='operatorId'
                  type='text'
                  value={username}
                />
              </FormControl>
              <Typography variant='body2' component='span'>
                how would you rate your overall experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='customer-feedback'
                    value={customerRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextCustomer}
                    onChange={(event, customerRate) => {
                      setCustomerRate(customerRate);
                      setcustomerExp(labels[customerRate]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setCustomerRateHover(customerRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {customerRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          customerRateHover !== -1
                            ? customerRateHover
                            : customerRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate your product experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='product-feedback'
                    value={productRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextProduct}
                    onChange={(event, productRate) => {
                      setProductRate(productRate);
                      setProductExp(labels[productRate]);
                    }}
                    onChangeActive={(event, productRateHover) => {
                      setProductRateHover(productRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {productRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          productRateHover !== -1
                            ? productRateHover
                            : productRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate your delivery experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='delivery-feedback'
                    value={value}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, value) => {
                      setValue(value);
                      setDeliveryExp(labels[value]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setHover(hover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate your customer Support: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='support-feedback'
                    value={supportRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextSupport}
                    onChange={(event, supportRate) => {
                      setSupportRate(supportRate);
                      setSupportExp(labels[supportRate]);
                    }}
                    onChangeActive={(event, supportRateHover) => {
                      setSupportRateHover(supportRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {supportRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          supportRateHover !== -1
                            ? supportRateHover
                            : supportRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant='body2' component='span'>
                how would you rate your other experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='others-feedback'
                    value={othersRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextOthers}
                    onChange={(event, othersRate) => {
                      setOthersRate(othersRate);
                      setOthersExp(labels[othersRate]);
                    }}
                    onChangeActive={(event, othersRateHover) => {
                      setOthersRateHover(othersRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                  {othersRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          othersRateHover !== -1 ? othersRateHover : othersRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <br />
              <Typography variant='body2' component='span'>
                Remarks: <br />
                <TextareaAutosize
                  minRows={5}
                  aria-label='minimum height'
                  placeholder='Any remarks'
                  style={{ minWidth: '260px' }}
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  assetFeedbackSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetFeedbackPopup(!assetFeedbackPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Create Asset Alarm */}
          <Dialog
            fullScreen={fullScreen}
            open={assetAlarmPopup}
            onClose={() => {
              setAssetAlarmPopup(!assetAlarmPopup);
            }}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Create Asset Alarm'}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px' }}>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='solutionId'>Source:</InputLabel>
                <Input
                  disabled
                  id='solutionId'
                  name='solutionId'
                  type='text'
                  value={parentId}
                />
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVertical Id:</InputLabel>
                <Select
                  id='assetVerticalID'
                  name='assetVerticalID'
                  type='text'
                  value={selectedVerticalsId}
                  onChange={(event) => {
                    setSelectedVerticalsIdHandler(event);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Input
                  disabled
                  id='avName'
                  name='avName'
                  type='text'
                  value={selectedVerticalName}
                />
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  id='selectedCategory'
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                  label='Select Category'
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id='selectedAssertTypes'
                  value={selectedAssertTypes}
                  onChange={(event) => {
                    setSelectedAssertTypes(event.target.value);
                  }}
                  label='Select Type'
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant='standard'
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id='selectedModel'
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  label='Select Model'
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Site Id:</InputLabel>
                <Input
                  disabled
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='assetId'>Asset Id:</InputLabel>
                <Input
                  disabled
                  id='assetId'
                  name='assetId'
                  type='text'
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Alarm Category:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={alarmCategoryValue}
                  onChange={(e) => {
                    setAlarmCategoryValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className='loginFields'>
                <InputLabel htmlFor='siteId'>Alarm Name:</InputLabel>
                <Input
                  id='siteId'
                  name='siteId'
                  type='text'
                  value={alarmNameValue}
                  onChange={(e) => {
                    setAlarmNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                className='registerBtn'
                variant='contained'
                onClick={() => {
                  submitAssetAlarmClick();
                }}
              >
                Submit
              </Button>
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  setAssetAlarmPopup(!assetAlarmPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <CustomPopup
            closeButton={closeUploadPhotoModal}
            isOpen={uploadPhotoPopup}
            closeModal={uploadPhotoPopup}
            content={uploadPhotoBody}
            title={titleUploadPhoto}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
