import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SearchBar from '../../../../Containers/SearchBar/SearchBar';
import MainService from '../../Common/MainService';
import { NotificationManager } from 'react-notifications';

const ViewBuyerRequest = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [searched, setSearched] = useState('');
  const [filteredBuyers, setFilteredBuyers] = useState([]);
  const [sellersList, setSellersList] = useState([]);
  const [buyersList, setBuyersList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedId, setSelectedId] = useState('');
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [existingStatus, setSelectedExistingStatus] = useState('');
  const [UpdateRequestDialog, setUpdateRequestDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [newStatusValue, setNewStatusValue] = useState('');
  const [filteredSellers, setFilteredSellers] = useState([]);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  useEffect(() => {
    // getAllAssetSellers();
    // getAllAssetRequests();
  }, []);

  // const getAllAssetSellers = () => {
  //   MainService.getAssetSellers()
  //     .then((res) => {
  //       setSellersList(res);
  //       setFilteredSellers(res);
  //     })
  //     .catch((err) => {
  //       return NotificationManager.error(err);
  //     });
  // };
  const getAllAssetRequests = () => {
    MainService.getAllAssetRequests(username)
      .then((res) => {
        setBuyersList(res);
        setFilteredBuyers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredBuyers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSellersList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const UpdateRequestDialogClick = (id, assetId, existingStatus) => {
    setSelectedId(id);
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(existingStatus);
    setUpdateRequestDialog(!UpdateRequestDialog);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  const updateRequestStatusSubmit = () => {
    let updateStatusData = {
      status: newStatusValue,
    };
    const updateStatusDataString = JSON.stringify(updateStatusData);
    MainService.updateAssetRequestStatusForAd(
      updateStatusDataString,
      selectedId,
      selectedAssetId
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateRequestDialog(!UpdateRequestDialog);
  };

  return (
    <>
      <Box value={value} index={2}>
        <Paper sx={{ width: '100%' }}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow align='center'>
                  <TableCell
                    className='headerFontSize'
                    align='center'
                    colSpan={12}
                  >
                    All Asset Requests
                  </TableCell>
                </TableRow>
                <TableRow align='center'>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Id
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Solution Id
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Legacy Asset Type
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Model Id
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Asset Id
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Service Package
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={1}
                  >
                    Asset Request Status
                  </TableCell>
                  <TableCell
                    className='subHeaderFontSize'
                    align='center'
                    colSpan={2}
                  >
                    Option
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buyersList.length > 0 ? (
                  buyersList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((requests, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align='center'>{requests.id}</TableCell>
                          <TableCell align='center'>
                            {requests.solutionid}
                          </TableCell>
                          <TableCell align='center'>
                            {requests.legacyassettype}
                          </TableCell>
                          <TableCell align='center'>
                            {requests.modelid}
                          </TableCell>
                          <TableCell align='center'>
                            {requests.assetid}
                          </TableCell>
                          <TableCell align='center'>
                            {requests.servicepackage}
                          </TableCell>
                          <TableCell align='center'>
                            {requests.status}
                          </TableCell>
                          <TableCell align='center'>
                            <Button
                              size='small'
                              variant='contained'
                              onClick={() => {
                                UpdateRequestDialogClick(
                                  requests.id,
                                  requests.assetid,
                                  requests.status
                                );
                              }}
                            >
                              Update Request
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow align='center'>
                    <TableCell align='center' colSpan={12}>
                      Asset Requests Not Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={buyersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <Dialog
          fullScreen={fullScreen}
          open={UpdateRequestDialog}
          onClose={handleClose}
          aria-labelledby='updateRequest'
        >
          <DialogTitle id='updateRequest'>
            Update Asset Request Status
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='id'>ID :</InputLabel>
              <Input
                disabled
                id='id'
                name='id'
                type='text'
                value={selectedId}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='assetId'>Asset ID :</InputLabel>
              <Input
                disabled
                id='assetId'
                name='assetId'
                type='text'
                value={selectedId}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='existingStatus'>
                Existing Asset Request Status :
              </InputLabel>
              <Input
                disabled
                id='existingStatus'
                name='existingStatus'
                type='text'
                value={existingStatus}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='assetId'>Update Asset Request Status</InputLabel>
              <Select
                id='assetId'
                name='assetId'
                value={newStatusValue}
                onChange={(e) => {
                  setNewStatusValue(e.target.value);
                }}
              >
                <MenuItem value='in-progress'>In-Progress</MenuItem>
                <MenuItem value='in-shipment'>In-Shipment</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                updateRequestStatusSubmit();
              }}
            >
              Update
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setUpdateRequestDialog(!UpdateRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ViewBuyerRequest;
