import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import sha256 from "sha256";
import { NotificationManager } from "react-notifications";
import "./SigninPageComponent.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// import MainService from "../../../CBN/Main/Common/MainService";
// import MainService from "../../../CBN/components/Main/Common/MainService";
import MainService from "../../../Main/components/Main/Common/MainService";
import { NavLink } from "react-router-dom";

export default class SigninPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loginObj: {
        // cbnId: localStorage.getItem("clubusername")",
        cbnId: "NewAssetDeveloper2@gmail.com",
        username: "",
        password: "",
      },
      userTypes: ["FOOD-PROCESSOR", "AssetDeveloper"],
      joinwithus: [],
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    }
  }

  joinClubValues(userTypes) {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState((prevState) => ({
          joinwithus: [...prevState.joinwithus, res.users].flatMap(
            (num) => num
          ),
        }));
      })
      .catch((err) => console.log("err", err));
  }

  doLogin = (evt) => {
    evt.preventDefault();
    // if (!this.state.loginObj.cbnId) {
    //   NotificationManager.warning("Please enter Parent Id");
    //   return;
    // }
    if (!this.state.loginObj.username) {
      NotificationManager.warning("Please enter Username/Email");
      return;
    }
    if (!this.state.loginObj.password) {
      NotificationManager.warning("Please enter Password.");
      return;
    }

    if (this.props.match.params.code < 3 || this.props.match.params.code > 9) {
      NotificationManager.error("Invalid access type.");
      return;
    }

    const { cbnId, username, password } = this.state.loginObj;
    const encPwd = sha256(password);
    MainService.doLogin(cbnId, username, encPwd)
      .then((res) => {
        MainService.getConsumerUserInfo(cbnId, username)
          .then(() => {
            this.setState({
              isLoading: false,
            });
            NotificationManager.success("Login successfully.");
            setTimeout(() => {
              window.location.href = "/main";
            }, 1000);
          })
          .catch((e) => {
            console.log("Error msg in signin page:", e);
            this.setState({
              isLoading: false,
            });
            return NotificationManager.error(e);
          });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        return NotificationManager.error(e);
      });
  };
  goToForgotPwd = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/forgot"
    );
  };
  goToSignup = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/signup"
    );
  };
  goToHome = (evt) => {
    this.props.history.push("/");
  };
  onFieldChange = (evt) => {
    let loginObj = this.state.loginObj;
    loginObj[evt.target.name] = evt.target.value;
    this.setState({
      loginObj: loginObj,
    });
    console.log("abn id is:", this.state.loginObj);
  };

  clickHandler = () => {
    if (localStorage.getItem("Login") === "true") {
      this.props.history.push("/");
      localStorage.setItem("Login", false);
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const userType1 = localStorage.getItem("userType");
    const data = userType1 ? { customData: userType1 } : {};
    const _code = parseInt(this.props.match.params.code);

    const _class =
      _code === 3
        ? "investment"
        : _code === 4
        ? "veterinary"
        : _code === 6
        ? "grower"
        : _code === 7
        ? "buyer"
        : _code === 8
        ? "agent"
        : _code === 10
        ? "farmer"
        : _code === 11
        ? "technologyprovider"
        : _code === 12
        ? "insuranceprovider"
        : _code === 13
        ? "logisticsprovider"
        : _code === 9
        ? "merchant"
        : _code === 16
        ? "driver"
        : _code === 17
        ? "offtaker"
        : _code === 18
        ? "farmerAdvisor"
        : _code === 19
        ? "machineryProvider"
        : _code === 20
        ? "nutritionCompanies"
        : _code === 21
        ? "storageProvider"
        : _code === 22
        ? "inputCompany"
        : _code === 23
        ? "transporter"
        : "default";

    return (
      <Container maxWidth={false} className={"signinPageContainer " + _class}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={11}
            sm={5}
            md={4}
            lg={3}
            className="loginOuterContainer"
          >
            <div className="loginContainer position-relative">
              <div className="closeBtn position-absolute mx-auto">
                <span className="iconCnt closeCnt">
                  <i onClick={this.clickHandler} className="material-icons">
                    close
                  </i>
                </span>
              </div>
              <form onSubmit={this.doLogin}>
                <h3> Grow Big & Reap </h3>

                {}
                {/* <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="cbnId">CBN Id*</InputLabel>
                  <Select
                    id="cbnId"
                    name="cbnId"
                    value={this.state.loginObj.cbnId}
                    onChange={this.onFieldChange}
                    label="Select Admin"
                  >
                    {this.state.joinwithus &&
                      this.state.joinwithus.map((idObj, index) => (
                        <MenuItem
                          key={`${index} `}
                          value={idObj.username}
                        >
                          {idObj.username}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="cbnId"> CBN Id * </InputLabel>{" "}
                  <Input
                    disabled
                    id="cbnId"
                    name="cbnId"
                    //type="email" //done by srine for using this invalid usetrname
                    type="text"
                    value={this.state.loginObj.cbnId}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> face </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username"> Username * </InputLabel>{" "}
                  <Input
                    id="username"
                    name="username"
                    //type="email" //done by srine for using this invalid usetrname
                    type="text"
                    value={this.state.loginObj.username}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> face </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="password"> Password * </InputLabel>{" "}
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={this.state.loginObj.password}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> vpn_key </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControlLabel
                  className="rememberMe"
                  control={<Checkbox value="rememberMe"  />}
                  label="Remember me on this computer"
                />

                <FormControl fullWidth={true}>
                  {this.state.isLoading === false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="submit"
                      disabled={
                        (this.state.username !== "") &
                        (this.state.password !== "")
                          ? false
                          : true
                      }
                    >
                      Sign in
                    </Button>
                  )}{" "}
                  {this.state.isLoading === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className="loginLoader" />
                    </Button>
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToForgotPwd}
                >
                  Forgot Password ?
                </FormControl>
                {_code === 8 ? (
                  ""
                ) : (
                  <FormControl
                    fullWidth={true}
                    className="forgotPwdLink"
                    // onClick={this.goToSignup}
                  >
                   <NavLink className="backBtn" to={{ pathname: "/auth/" + this.props.match.params.code + "/signup", state:  data  }}>
 Not registered ? Click to Register{" "}</NavLink>
                   
                  </FormControl>
                )}
                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToHome}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  Back Home ?
                </FormControl>
              </form>{" "}
            </div>{" "}
          </Grid>{" "}
        </Grid>
      </Container>
    );
  }
}
