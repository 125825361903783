import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import img4 from "../../images/double-check.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function ForAgriTransporterContribution() {
  const benefits = [
    {
      img: img1,
      title: "Support for Agri-entrepreneurs",
      bodycopy: " Agri-transporters can play a role in supporting agri-entrepreneurs by providing them access to larger markets. This can empower agri-entrepreneurs to scale up their operations, leading to increased incomes and economic stability.",
    },
    // {
    //   img: img2,
    //   title: "Improve Economy",
    //   bodycopy: "Investable trade in Agriculture bring a cascading increase in Agricultural production and  improves the GDP.",
    // },
    {
      img: img3,
      title: "Diversification of Rural Economy",
      bodycopy:
        "The presence of agri-transportation services can contribute to the diversification of the rural economy. It provides individuals with alternative employment options beyond traditional agricultural activities.",
    },
  ];
  return (
    <div className=" subSectionWrapper">
      <h2 className="text-center container-title">
        Agri-Transporter Contribution
      </h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
