import React from 'react';
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
  CCardGroup,
} from '@coreui/react';

import { CChart } from '@coreui/react-chartjs';

import MainService from '../../Common/MainService';
import './ManagerDashboard.css';

class ManagerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetCount: {},
      deviceCount: {},
      driversInfo: '',
      tripInfo: {},
      tripPlannerInfo: {},
      logisticManagerCount: {},
      mainserviceInfo: MainService.getLocalData(),
    };
  }

  componentDidMount() {
    this.getAssetsCountInfo();
    this.getDeviceCountInfo();
    this.getDriversInfo();
    this.getTripPlannerInfo();
    this.getMonthlyTrips();
    this.getLogisticManagerCount();
  }

  // vehicles count
  getAssetsCountInfo = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));

    const { assetCount } = await MainService.getAssetsCountInfo(username);

    this.setState({ assetCount: assetCount });

    console.log(this.state.assetCount, '.....assetCount...............');
  };

  // devices count
  getDeviceCountInfo = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));

    const { deviceCount } = await MainService.getDeviceCountInfo(username);
    this.setState({ deviceCount: deviceCount });
    console.log(this.state.deviceCount, 'getdevicescount...........');
  };

  // drivers count.....
  getDriversInfo = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const {
      userCount: { totalCount },
    } = await MainService.getDriversInfo(username);
    this.state.driversInfo = totalCount;
    console.log(this.state.driversInfo, 'driver1///////');
  };

  getTripPlannerInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { locationCount, routeCount, tripCount } =
      await MainService.getTripPlannerInfo(parentId, username);
    console.log(locationCount, 'locationCount........');
    this.setState({
      tripPlannerInfo: { locationCount, routeCount, tripCount },
    });
  };

  getMonthlyTrips = async () => {
    console.log('56666666666666666666666666666');
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    console.log('paaaaaaaaaaaaa', parentId);

    const data = await MainService.getMonthlyTrips(parentId, username);
    this.setState({ tripInfo: data });
    console.log(this.state.tripInfo, 'summaryy////////////////');
  };

  getLogisticManagerCount = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { userCount } = await MainService.getLogisticManagerCount(username);
    // console.log(LmCount, "count.................")
    this.setState({ logisticManagerCount: userCount });
  };

  render() {
    return (
      <>
        <CCardGroup columns className='main_container'>
          <CCard className='main_container-card'>
            <CCardHeader style={{ fontSize: '30px', fontWeight: 'inherit' }}>
              Vehicle Status
            </CCardHeader>
            <CCardBody>
              <CChart
                style={{ width: '300px' }}
                type='polarArea'
                data={{
                  labels: ['Active', 'InActive'],
                  datasets: [
                    {
                      data: [
                        this.state.assetCount.activeCount,
                        this.state.assetCount.inactiveCount,
                      ],

                      backgroundColor: [
                        '#4BC0C0',
                        '#FFCE56',
                        '#E7E9ED',
                        '#36A2EB',
                      ],
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCard>

          <CCard className='main_container-card'>
            <CCardHeader style={{ fontSize: '30px', fontWeight: 'inherit' }}>
              Fleet Summary
            </CCardHeader>
            <CCardBody>
              <CChart
                style={{ width: '500px', height: '300px' }}
                type='bar'
                data={{
                  // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                  datasets: [
                    {
                      label: 'Monthly Trips',
                      backgroundColor: '#4BC0C0',

                      data: this.state.tripInfo,
                    },
                  ],
                }}
                labels='months'
              />
            </CCardBody>
          </CCard>
        </CCardGroup>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm='5'>
                <h4
                  id='traffic'
                  className='card-title mb-0'
                  style={{ fontSize: '30px', fontWeight: 'inherit' }}
                >
                  {' '}
                  Fleet Info
                </h4>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className='text-center main_container--fleetInfoFooter'>
              <CCol md sm='12' className='mb-sm-2 mb-0'>
                <div className='text-muted'>Vehicles</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.assetCount.totalCount}
                </strong>
              </CCol>
              <CCol md sm='12' className='mb-sm-2 mb-0 d-md-down-none'>
                <div className='text-muted'>Devices</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.deviceCount.totalCount}
                </strong>
              </CCol>

              <CCol md sm='12' className='mb-sm-2 mb-0 d-md-down-none'>
                <div className='text-muted'>Drivers</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.driversInfo}
                </strong>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm='5'>
                <h4
                  id='traffic'
                  className='card-title mb-0'
                  style={{ fontSize: '30px', fontWeight: 'inherit' }}
                >
                  {' '}
                  Trip Info
                </h4>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className='text-center main_container--fleetInfoFooter'>
              <CCol md sm='12' className='mb-sm-2 mb-0'>
                <div className='text-muted'>Locations</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.tripPlannerInfo.locationCount}
                </strong>
              </CCol>
              <CCol md sm='12' className='mb-sm-2 mb-0 d-md-down-none'>
                <div className='text-muted'>Routes</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.tripPlannerInfo.routeCount}
                </strong>
              </CCol>

              <CCol md sm='12' className='mb-sm-2 mb-0 d-md-down-none'>
                <div className='text-muted'>Trips</div>
                <strong style={{ color: '#0a9ea3', fontSize: 'large' }}>
                  {this.state.tripPlannerInfo.tripCount}
                </strong>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </>
    );
  }
}

export default ManagerDashboard;
