import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForAAMC.css";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AAMCServices from "./AAMCServices";
import AAMCBenefits from "./AAMCBenefits";
import AAMCJourney from "./AAMCJourney";
import AAMCContribution from "./AAMCContribution";
export default function ForAAMC() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="AAMCBanner text-white"
        bannerText={[
          "Want to Manage Assets and Create your own network",
          <br className="d-lg-block d-none"></br>,
          "of Rural-Entrepreneur",
        ]}
        buttonText2="Sign in"
        href2="http://abn.gbrfarming.com/"

        buttonText3="Sign up"
        href3="http://abn.gbrfarming.com/auth/3/signup"
      />
      <Container>
        <TitleDescription
          classname="mb-0"
          title="What is an Agri Business"
          description="An Agri Business is a specialized financial institution that focuses on the management, investment, and optimization of agricultural assets. These assets typically encompass various aspects of the agricultural industry, such as farmland, crops, livestock, agricultural equipment, and agricultural commodities. The core mission of an Agri Asset Management Company is to assist clients, which can include investors, farmers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets."
        />
              </Container>

        <AAMCServices />
        <Container>

        <AAMCBenefits />
        <AAMCContribution/>
        <AAMCJourney />
        </Container>

      <FooterComponent />
    </>
  );
}
