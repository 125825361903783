import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./ViewCarriersComponent.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ViewCarriersComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [logisticProvidersList, setLogisticProvidersList] = useState([]);
  const [logisticProviderUserId, setLogisticProviderUserId] = useState("");
  const [logisticProviderUserName, setLogisticProviderUserName] = useState("");
  const [logisticProviderAddress, setLogisticProviderAddress] = useState("");
  const [logisticProviderCity, setLogisticProviderCity] = useState("");
  const [logisticProviderFirstName, setLogisticProviderFirstName] =
    useState("");
  const [logisticProviderLastName, setLogisticProviderLastName] = useState("");
  const [logisticProviderPhone, setLogisticProviderPhone] = useState("");
  const [logisticProviderEnterpriceId, setLogisticProviderEnterpriceId] =
    useState("");
  const [logisticProviderUserType, setLogisticProviderUserType] = useState("");
  const [logisticProviderEmail, setLogisticProviderEmail] = useState("");
  const [logisticProviderDomain, setLogisticProviderDomain] = useState("");
  const [logisticProviderState, setLogisticProviderState] = useState("");
  const [logisticProviderNominee, setLogisticProviderNominee] = useState("");
  const [logisticProviderPancard, setLogisticProviderPancard] = useState("");
  const [logisticProviderLongitude, setLogisticProviderLongitude] =
    useState("");
  const [logisticProviderLatitude, setLogisticProviderLatitude] = useState("");
  const [logisticProviderMessage, setLogisticProviderMessage] = useState("");
  const [logisticProviderStatus, setLogisticProviderStatus] = useState("");
  const { user } = MainService.getLocalData();
  const { username } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailValue, setEmailValue] = React.useState("");
  const [messageValue, setMessageValue] = React.useState("");
  const [logisticProviderEmailDialog, setLogisticProviderEmailDialog] =
    React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [logisticProviderSMSDialog, setLogisticProviderSMSDialog] =
    React.useState(false);
  const [phoneNumerValue, setPhoneNumerValue] = React.useState("");
  const [
    selectedLogisticProviderUsername,
    setSelectedLogisticProviderUserName,
  ] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredLogisticProviders, setFilteredLogisticProviders] = useState(
    []
  );
  const [searched, setSearched] = useState("");
  const [logisticProviderInfoDialog, setLogisticProviderInfoDialog] =
    useState(false);
  const [listView, setListView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [viewAllClick, setViewAllClick] = useState(true);
  const [viewAssociatedClick, setViewAssociatedClick] = useState(false);

  useEffect(() => {
    getAllLogisticProviders();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All AssetOEMs */
  const getAllLogisticProviders = () => {
    MainService.getLogisticProviders()
      .then((res) => {
        setLogisticProvidersList(res);
        setFilteredLogisticProviders(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* Logistic Provider info Dialog */
  const logisticProviderViewInfoDialogClick = (logicProvider) => {
    MainService.getLogisticProviderInfo(logicProvider)
      .then((res) => {
        setLogisticProviderUserId(res[0].userid);
        setLogisticProviderUserName(res[0].username);
        setLogisticProviderFirstName(res[0].firstname);
        setLogisticProviderLastName(res[0].lastname);
        setLogisticProviderEnterpriceId(res[0].enterpriseid);
        setLogisticProviderUserType(res[0].usertype);
        setLogisticProviderEmail(res[0].email);
        setLogisticProviderDomain(res[0].domain);
        setLogisticProviderPhone(res[0].phone);
        setLogisticProviderAddress(res[0].address);
        setLogisticProviderCity(res[0].city);
        setLogisticProviderState(res[0].state);
        setLogisticProviderNominee(res[0].nominee);
        setLogisticProviderPancard(res[0].pancard);
        setLogisticProviderLongitude(res[0].longitude);
        setLogisticProviderLatitude(res[0].latitude);
        setLogisticProviderMessage(res[0].message);
        setLogisticProviderStatus(res[0].status);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setLogisticProviderInfoDialog(!logisticProviderInfoDialog);
  };

  const logisticProviderSendEmailClick = (selectedUser) => {
    setSelectedLogisticProviderUserName(selectedUser);
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };
  const sendEmailClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("email Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };

  const sendSMSClick = () => {
    let smsData = {
      sms: messageValue,
    };
    const smsDataString = JSON.stringify(smsData);
    MainService.sendSMS(phoneNumerValue, smsDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  const logisticProviderSendMessageClick = (selectedUser) => {
    setSelectedLogisticProviderUserName(selectedUser);
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredLogisticProviders.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setLogisticProvidersList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
    setMapView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
    setMapView(false);
  };
  const mapViewClick = () => {
    setListView(false);
    setGridView(false);
    setMapView(true);
  };
  const AssociatedLogisticProvidersClick = () => {
    setViewAllClick(false);
    setViewAssociatedClick(true);
  };
  const AllLogisticProvidersClick = () => {
    setViewAllClick(true);
    setViewAssociatedClick(false);
  };

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          <Button
            startIcon={<MapIcon />}
            size="large"
            onClick={mapViewClick}
          ></Button>
          &nbsp;
          {viewAllClick && (
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={AssociatedLogisticProvidersClick}
            >
              View Associated Logistic Providers
            </Button>
          )}
          {viewAssociatedClick && (
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={AllLogisticProvidersClick}
            >
              View All Logistic Providers
            </Button>
          )}
        </div>
        {viewAllClick && (
          <div>
            <h2>View Carriers(Logistic Providers)</h2>

            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  style={{ width: "100%" }}
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of Logistic Providers
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          View
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logisticProvidersList.length > 0 ? (
                        logisticProvidersList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((logisticProviders, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {logisticProviders.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {logisticProviders.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderViewInfoDialogClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendEmailClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Email
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendMessageClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Message
                                  </Button>
                                </TableCell>
                                
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    /*  onClick={() => {
                                   logisticProviderSendMessageClick(
                                     logisticProviders.username
                                   );
                                 }} */
                                  >
                                    Feedback
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            LogisticsProviders Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={logisticProvidersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {logisticProvidersList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {logisticProvidersList.map(
                          (logisticProviders, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>User Id:</b>
                                  {logisticProviders.userid}
                                </p>
                              </span>
                              <p>
                                <b>User Name:</b>
                                {logisticProviders.username}
                              </p>
                              <h4 className="cardactiontitle">View</h4>
                              <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderViewInfoDialogClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  View Info
                                </Button>                                
                              </div>
                              <div>
                                <h4 className="cardactiontitle">Operate</h4>
                              </div>
                              <div>                               
                                <h4 className="cardactiontitle">Actions</h4>
                                <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendEmailClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Email
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendMessageClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Msssage
                                </Button>
                                </div>
                                &nbsp;
                                <div>
                                
                                <Button type="button" variant="contained">
                                  Send Feedback
                                </Button>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4>Logistic Providers not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            {mapView && (
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={4}
                    center={defaultCenter}
                  >
                    <MarkerClusterer options={options} averageCenter={true}>
                      {(clusterer) =>
                        logisticProvidersList.map((markerObj, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(markerObj.latitude),
                                lng: parseFloat(markerObj.longitude),
                              }}
                              clusterer={clusterer}
                              onClick={() => onSelect(markerObj)}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>

                    {selected.latitude && selected.longitude && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <CardContent>
                          <Typography variant="body2" color="textSecondary">
                            <b>ID:</b> {selected.siteid}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <b> Name:</b>Site{selected.sitename}
                          </Typography>
                        </CardContent>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            )}
          </div>
        )}

        {viewAssociatedClick && (
          <div>
            <h2>View Associated Carriers(Logistic Providers)</h2>
            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  style={{ width: "100%" }}
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of Associated Logistic Providers
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          View
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logisticProvidersList.length > 0 ? (
                        logisticProvidersList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((logisticProviders, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {logisticProviders.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {logisticProviders.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderViewInfoDialogClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendEmailClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Email
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendMessageClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Message
                                  </Button>
                                </TableCell>
                                
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    /*  onClick={() => {
                                   logisticProviderSendMessageClick(
                                     logisticProviders.username
                                   );
                                 }} */
                                  >
                                    Feedback
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            Associated LogisticsProviders Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={logisticProvidersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {logisticProvidersList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {logisticProvidersList.map(
                          (logisticProviders, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>User Id:</b>
                                  {logisticProviders.userid}
                                </p>
                              </span>
                              <p>
                                <b>User Name:</b>
                                {logisticProviders.username}
                              </p>
                              <h4 className="cardactiontitle">View</h4>
                              <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderViewInfoDialogClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  View Info
                                </Button>                                
                              </div>
                              <div>
                                <h4 className="cardactiontitle">Operate</h4>
                              </div>
                              <div>                               
                                <h4 className="cardactiontitle">Actions</h4>
                                <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendEmailClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Email
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendMessageClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Msssage
                                </Button>
                                </div>
                                &nbsp;
                                <div>
                                
                                <Button type="button" variant="contained">
                                  Send Feedback
                                </Button>
                                </div>
                                </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4>Associated Logistic Providers not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            {mapView && (
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={4}
                    center={defaultCenter}
                  >
                    <MarkerClusterer options={options} averageCenter={true}>
                      {(clusterer) =>
                        logisticProvidersList.map((markerObj, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(markerObj.latitude),
                                lng: parseFloat(markerObj.longitude),
                              }}
                              clusterer={clusterer}
                              onClick={() => onSelect(markerObj)}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>

                    {selected.latitude && selected.longitude && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <CardContent>
                          <Typography variant="body2" color="textSecondary">
                            <b>ID:</b> {selected.siteid}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <b> Name:</b>Site{selected.sitename}
                          </Typography>
                        </CardContent>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            )}
          </div>
        )}

        {/* Send SMS */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderSMSDialog}
          onClose={handleClose}
          aria-labelledby="sendSMS"
        >
          <DialogTitle id="sendSMS">Send SMS</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={selectedLogisticProviderUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Mobile Number:</InputLabel>
              <Input
                id="phone"
                name="phone"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendSMSClick();
              }}
            >
              Send
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Email */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderEmailDialog}
          onClose={handleClose}
          aria-labelledby="sendEmail"
        >
          <DialogTitle id="sendEmail">{"Send Email"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={selectedLogisticProviderUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subject">Subject:</InputLabel>
              <Input
                id="subject"
                name="subject"
                type="text"
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendEmailClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View Info */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderInfoDialog}
          onClose={handleClose}
          aria-labelledby="viewInfoDialog"
        >
          <DialogTitle id="viewInfoDialog">
            {"View Logistic Provider Info"}
          </DialogTitle>
          <DialogContent>
            <p>
              <b>User Id:</b>
              {logisticProviderUserId}
            </p>
            <p>
              <b>User Name:</b>
              {logisticProviderUserName}
            </p>
            <p>
              <b>First Name:</b>
              {logisticProviderFirstName}
            </p>
            <p>
              <b>Last Name:</b>
              {logisticProviderLastName}
            </p>
            <p>
              <b>Enterprise Id:</b>
              {logisticProviderEnterpriceId}
            </p>
            <p>
              <b>UserType:</b>
              {logisticProviderUserType}
            </p>
            <p>
              <b>Email:</b>
              {logisticProviderEmail}
            </p>
            <p>
              <b>Domain:</b>
              {logisticProviderDomain}
            </p>
            <p>
              <b>Phone:</b>
              {logisticProviderPhone}
            </p>
            <p>
              <b>Address:</b>
              {logisticProviderAddress}
            </p>
            <p>
              <b>City:</b>
              {logisticProviderCity}
            </p>
            <p>
              <b>State:</b>
              {logisticProviderState}
            </p>
            <p>
              <b>Nominee:</b>
              {logisticProviderNominee}
            </p>
            <p>
              <b>Pancard:</b>
              {logisticProviderPancard}
            </p>
            <p>
              <b>Longitude:</b>
              {logisticProviderLongitude}
            </p>
            <p>
              <b>Latitude:</b>
              {logisticProviderLatitude}
            </p>
            <p>
              <b>Message:</b>
              {logisticProviderMessage}
            </p>
            <p>
              <b>Status:</b>
              {logisticProviderStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderInfoDialog(!logisticProviderInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
