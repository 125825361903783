import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import ViewFarmerNodesComponent from "../FarmerNodes/ViewFarmerNodesComponent";
import ViewFarmerSitesComponent from "../FarmerSites/ViewFarmerSitesComponent";

export default function GrowerFarmerComponent(props) {
  const [viewFarmerNodes, setViewFarmerNodes] = useState(true);
  const [viewFarmerSites, setViewFarmerSites] = useState(false);
  

  const viewFarmerNodesClick = () => {
    setViewFarmerNodes(true);
    setViewFarmerSites(false);

   
  };
  const viewFarmerSitesClick = () => {
    setViewFarmerNodes(false);
    setViewFarmerSites(true);

  };

  

  return (
    <>
      <div className="container" id="mainContentList">
        <h2>Farmer Nodes and Sites</h2>
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFarmerNodesClick();
                  }}
                >
                  Nodes
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFarmerSitesClick();
                  }}
                >
                  Sites
                </Button>
              </div>
              
            </div>
          </div>
        
      </div>

      <div style={{ paddingTop: "20px" }}>
        {viewFarmerNodes && <ViewFarmerNodesComponent />}
        {viewFarmerSites && <ViewFarmerSitesComponent />}
        
      </div>
    </>
  );
}
