import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
// import AgriEntrepenurBenefits from "../AgriEntrepenurBenefits/AgriEntrepenurBenefits";
// import AgriEnrepenurJourney from "../AgriEnrepenurJourney/AgriEnrepenurJourney";
import CTA from "../CTA/CTA";

import FarmerFranchiseJourney from "./FarmerFranchiseJourney";
// import AgriEntreprenuerServices from "./AgriEntreprenuerServices";
import "./FarmerFranchise.css";
import FarmerFranchiseServices from "./FarmerFranchiseServices";
export default function ForFarmerFranchise() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="FarmerFranchiseBanner"
        bannerText={[
          "Manage growers and farms to build a Trusted",
          <br className="d-lg-block d-none"></br>,
          " Brand and Boost Your Income",
        ]}
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "FF");
        }}
        // roleType="NULL"
        DlBtn ="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />

      <Container>
        <TitleDescription
        classname="mb-0"
        classname2="mb-0"
          title="Discovering Farmer Franchise"
          description="GBR Farming's innovative approach involves transforming individual farmers into Farmer Franchises, empowering them to expand and manage farm sites and growers within their network. These Farmer Franchise partners take on the dual role of cultivating and overseeing the growth of farm sites while also managing the growers associated with them. This unique system not only enables farmers to scale up their operations but also fosters community-driven agricultural development, creating a mutually beneficial ecosystem that promotes sustainable farming practices and economic growth."
        />
  

        <FarmerFranchiseServices/>
        {/* <AgriEntrepenurBenefits /> */}

        <FarmerFranchiseJourney />

        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "FF");
            }}
            href="/auth/3/signup"
            heading="Become a Farmer Franchise and Cultivate Success Together!"
            btnText="Register Now"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
