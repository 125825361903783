import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/cool.png";
import prod2 from "../../images/substance.png";
import prod4 from "../../images/dry.png";
import { Container } from "react-bootstrap";
// import "./ExploreProducts.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import RawProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import "./MarketPlaceQuote.css";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
const MarketPlaceQuote = () => {
  const [quoteDetails, setQuoteDetails] = useState();
  useEffect(() => {
    test();
  }, []);
  const loc = localStorage.getItem("GlobalAccess");
  const[blogData, setBlogData] = useState([])
  const navData = [
    { to: "/onMarketplace", link: "Buy" },
    { to: "/onMarketplaceSell", link: "Sell" },
    { to: "/onMarketplaceRFQ", link: "RFQ" },
  ];
  const [rawProduct, setRawProduct] = useState(true);
  const [semiProduct, setSemiProduct] = useState(false);
  const [proccessedProduct, setproccessedproduct] = useState(false);
  const [userType, setUserType] = useState(["FOOD-PROCESSOR"]);

  const rawProductHandler = () => {
    setRawProduct(true);
    setSemiProduct(false);
    setproccessedproduct(false);
  };

  const semiProductHandler = () => {
    setSemiProduct(true);
    setRawProduct(false);
    setproccessedproduct(false);
  };
  const processedProductHandler = () => {
    setproccessedproduct(true);
    setRawProduct(false);
    setSemiProduct(false);
  };

  const test = () => {
    fetch(
      "http://172.16.1.28:8080/tradeHub/get-all-request-ids?network-id=consumer2&status=open&request-type=agri-buy-products-rfq-req&page-no=0&page-size=10",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + loc,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const quoteIds = data.map((item) => item.rfqId);
        const requests = quoteIds.map((rfqId) => {
          const detailsUrl = `http://172.16.1.28:8080/tradeHub/get-request-details?id=${rfqId}`;
          return fetch(detailsUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + loc,
            },
          }).then((res) => res.json());
        });

        return Promise.all(requests);
      })
      .then((details) => {
        console.log(details, "Details for each quoteId");
        setQuoteDetails(details);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  console.log(quoteDetails, "quoteDetailsquoteDetailsquoteDetails");
 
  return (
    <>
      <HeaderComponent className="noShadow" />
      <SecondaryNavbar props={navData} />
      <UpdatesBanner
        className="onMarketplaceBanner text-white"
        bannerTitle="Are you a B2B buyer"
        bannerbodycopy="Start asking for best quote for organic products with GBR Farming"
        buttonText4="Sign up"
        href3="http://abn.gbrfarming.com/auth/3/signup"
        href="#RFQ"
        buttonText5="View existing RFQ"
      />
      <div className="explore-product-wrapper subSectionWrapper " id="RFQ">
        <h2 className="container-title text-center">Quotes</h2>
        <Container>
          <Row>
            {quoteDetails && quoteDetails.length != 0 ? (
              <>
                {quoteDetails?.map((e) => (
                  <>
                    <Col md={4}>
                      <div className="quote-card">
                        <p>
                          Category:{" "}
                          {e?.requestDetails?.productDetails?.productCategory}
                        </p>
                        <p>
                          Sub-category:{" "}
                          {
                            e?.requestDetails?.productDetails
                              ?.productSubCategory
                          }
                        </p>
                        <p>
                          Origin: {e?.requestDetails?.productDetails?.origin}
                        </p>
                        <p>
                          Qunantity:{" "}
                          {e?.requestDetails?.productDetails?.quantity}
                        </p>
                        <a
                          className="text-white text-decoration-none d-block"
                          href="http://abn.gbrfarming.com/auth/3/signup"
                          target="_blank"
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-success mx-lg-0 mx-auto "
                          >
                            Raise Quote
                          </button>
                        </a>
                      </div>
                    </Col>
                  </>
                ))}
              </>
            ) : (
              <div className="card">
                <p className="card-body container-heading2">
                  <b>No Quotes Found</b>
                </p>
              </div>
            )}
          </Row>
          {/* <div className="paginationComponent">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={this.state.prodPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      // onPageChange={this.handlePageClick}
                      containerClassName={"pagination"
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div> */}
        </Container>
      </div>
      <FooterComponent />
    </>
  );
};

export default MarketPlaceQuote;
