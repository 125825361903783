import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import OperatorImg from "../../../images/gbrPN/PNImg3.webp";
import QAImg from "../../../images/gbrPN/PNImg2.webp";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import ExecutiveImg from "../../../images/gbrPN/PNImg4.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import HeaderComponent from "../Header/HeaderComponent";
import NetworkBanner from "../NetworkBanner/NetworkBanner";

import FooterComponent from "../Footer/FooterComponent";
import IAABenefit from "../IAABenefit/IAABenefit";
import IAAManagement from "../IAAManagement/IAAManagement";
import PNServices from "../../OurServices/PNServices";
import ExploreTechnologySection1 from "../../ExploreTechnology/ExploreTechnologySection1";

export default function ProductionNetwork() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner bannerTitle="Production Network for Digital Transformation in Farming sector" bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification" className="pnbg" />
      <div className="subSectionWrapper pb-0">
      <ExploreTechnologySection1/>
      </div>
      <PNServices/>
      {/* <IAABenefit />
      <IAAManagement /> */}
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">Production Network Team </h2>
        {/* <h4 className="container-title text-center mx-4">
        Production Network (PN) - Investable Asset Management network for MSMEs
      </h4>
      <p className="container-para text-center mx-md-0 mx-4 mb-4 ">MSME can Build, Own, Manage & Operate Production Network</p> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
              "
          descriptionTitle="
              Farmer Role"
          src={FarmerImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />

        {/* <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod"
        descriptionTitle="
              Farmer Franchise"
        description="GBR Farming is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
        src={FarmersFranchiseImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        href1="auth/3/signin"
        buttoncont1="Sign In"
      /> */}
        <HomeSubSection
          className1="aboutBgGrey"
          className3="image12"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
              QA Role"
          description="GBR Farming is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={QAImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image13"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
              "
          descriptionTitle="
              Operator Role"
          src={OperatorImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image14"
          descriptionTitle="
              Executive Role"
          description="GBR Farming is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={ExecutiveImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image15"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
              "
          descriptionTitle="
              Production Manager Role"
          src={ProductionManagerImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />


<HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="imageStrCommittee"
          descriptionTitle="
              Steering committee (Team) Role"
          description="GBR Farming is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={ExecutiveImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          href4='http://cbn.gbrapp.com/'
          buttoncont1='Sign In'
        />
      </div>
      <FooterComponent />
    </>
  );
}
