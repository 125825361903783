import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./RoleManagement.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function RoleManagementComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consumerUsersList, setConsumerUsersList] = useState([]);

  const [allRolesList, setAllRolesList] = useState([]);
  const [parentid, setParentId] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createRoleDialog, setCreateRoleDialog] = useState(false);
  const [updateRoleDialog, setUpdateRoleDialog] = useState(false);
  const [roleExistingResourcesDialog, setRoleExistingResourcesDialog] =
    useState(false);
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
  const [allSitesList, setAllSitesList] = useState([]);
  const [filteredSitesList, setFilteredSitesList] = useState([]);
  const [writePermissionValue, setWritePermissionValue] = useState("No");
  const [readPermissionValue, setReadPermissionValue] = useState("No");
  const [executePermissionValue, setExecutePermissionValue] = useState("No");
  const [resourceNameValue, setResourceNameValue] = useState("");
  const [siteChecked, setSiteChecked] = useState(false);
  const [resourceIdValue, setResourceIdValue] = useState("");
  const [resourceTypeValue, setResourceTypeValue] = useState("");
  const [roleNameValue, setRoleNameValue] = useState("");
  const [existingRoleData, setExistingRoleData] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [roleDeleteDialog, setRoleDeleteDialog] = useState(false);
  const [roleMapDialog, setRoleMapDialog] = useState(false);
  const [consumerUserNameValue, setConsumerUserNameValue] = useState("");
  const [allMappedRolesList, setAllMappedRolesList] = useState([]);
  const [mappedRolesView, setMappedRolesView] = useState(false);
  const [allRolesView, setAllRolesView] = useState(true);
  const [updateMappedRoleDialog, setUpdateMappedRoleDialog] = useState(false);
  const [roleMappedDeleteDialog, setRoleMappedDeleteDialog] = useState(false);
  const [filteredRolesList, setFilteredRolesList] = useState([]);
  const [filteredMappedRolesList, setFilteredMappedRolesList] = useState([]);
  const [filteredConsumerUsersList, setFilteredConsumerUsersList] = useState(
    []
  );
  const [value, setValue] = React.useState(0);
  const [searched, setSearched] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [roleResourcesList, setRoleResourcesList] = useState([]);

  const [roleClickForMap, setRoleClickForMap] = useState(false);
  const [consumerClickForMap, setConsumerClickForMap] = useState(false);
  const [existingRoleName, setExistingRoleName] = useState("");
  const [resourcesUpdateArray, setResourcesUpdateArray] = useState([]);
  const [resourcesUpdateObj, setResourcesUpdateObj] = useState({});
  const [tempObj, setTempObj] = useState([]);

  useEffect(() => {
    getAllConsumerUsers();
    getAllRolesList();
    getAllSitesForRoleNames();
    getAllMappedRoles();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All consumerUsers */
  const getAllConsumerUsers = () => {
    MainService.getAllConsumerUsers(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          setConsumerUsersList(res);
          setFilteredConsumerUsersList(res);
        } else {
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All consumerUsers */
  const getAllSitesForRoleNames = () => {
    MainService.viewAllSites(parentId, username)
      .then((res) => {
        console.log("viewAllSites", res);
        setAllSitesList(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getAllMappedRoles = () => {
    MainService.getMappedRoles(parentId, username)
      .then((res) => {
        setAllMappedRolesList(res);
        setFilteredMappedRolesList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* view all roles  */
  const getAllRolesList = () => {
    setIsLoading(true);
    MainService.getAllRoles(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          setIsLoading(false);
          setAllRolesList(res);
          setFilteredRolesList(res);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* end of view all roles requests */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* consumer IAM */

  const roleExistingResourcesClick = (rolename) => {
    getAllSitesForRoleNames();
    if (roleExistingResourcesDialog === false) {
      setRoleExistingResourcesDialog(!roleExistingResourcesDialog);
    }

    setSelectedRoleName(rolename);
    MainService.getRoleResources(username, rolename)
      .then((res) => {
        console.log("resouces list:", res["role"]["resources"]);
        setRoleResourcesList(res["role"]["resources"]);
        setExistingRoleData(res["role"]["resources"]);
        setSelectedRoleName(res["role"]["rolename"]);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    let filterdArray = [];
    if(existingRoleData.length !== 0)
    {
      for (var i = allSitesList.length - 1; i >= 0; i--) {
        for (var j = 0; j < existingRoleData.length; j++) {
          if (
            allSitesList[i] &&
            allSitesList[i].siteid === existingRoleData[j].resourceid
          ) {
            allSitesList.splice(i, 1);
            filterdArray = allSitesList;
          }
          
        }
      }
      setFilteredSitesList(filterdArray);
    }
    else{
      setFilteredSitesList(allSitesList);
    }
    
  };

  const roleDeleteDialogClick = (rolename) => {
    setSelectedRoleName(rolename);

    setRoleDeleteDialog(!roleDeleteDialog);
  };
  const roleDeleteSubmitClick = () => {
    MainService.deleteRole(username, selectedRoleName)
      .then((res) => {
        getAllRolesList();
        return NotificationManager.success("role profile deleted successfully");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setRoleDeleteDialog(!roleDeleteDialog);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const createRoleSubmit = () => {
    let createRoleData = {
      role: {
        rolename: roleNameValue,
        resources: {
          resourcetype: resourceTypeValue,
          resourceid: resourceIdValue,
          resourcename: resourceNameValue,
          readpermission: readPermissionValue,
          executepermission: executePermissionValue,
          writepermissions: writePermissionValue,
        },
      },
    };
    const roleStringifyData = JSON.stringify(createRoleData);
    console.log("roleStringifyData:", roleStringifyData);
    MainService.createRole(roleStringifyData, username, roleNameValue)
      .then((res) => {
        getAllRolesList();
        setResourceTypeValue("");
        setResourceNameValue("");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setCreateRoleDialog(!createRoleData);
  };

  const roleUpdateDialogClick = (rolename) => {
    setSelectedRoleName(rolename);
    roleExistingResourcesClick(rolename);
    setRoleExistingResourcesDialog(false);
    setUpdateRoleDialog(!updateRoleDialog);
  };
  const existingResourceUnmapClick = (resourceId) => {
    MainService.unmapExistingResourceFromRole(
      username,
      selectedRoleName,
      resourceId
    )
      .then((res) => {
        roleExistingResourcesClick(selectedRoleName);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* Update Role Profile with checkboxes */

  const checkboxHandler = (e) => {
    setResourceNameValue(e.target.value);
    let catname = allSitesList.filter((data) => {
      return data.sitename === e.target.value;
    });
    setResourceIdValue(catname[0].siteid);
    if (e.target.checked) {
      const obj = {
        resourcename: e.target.value,
        resourceid: catname[0].siteid,
        readpermission: "No",
        writepermissions: "No",
        executepermission: "No",
      };
      setTempObj([...tempObj, obj]);
      console.log("checked", tempObj);
    } else {
      const filterData = tempObj.filter(
        (item) => item.resourcename !== e.target.value
      );
      setResourceIdValue("");
      setTempObj(filterData);
      console.log("tempObj", filterData);
    }
  };
  

  const readRadioBtnHandler = (e) => {

    const name = e.currentTarget.name.split("-")[1];
    const value = e.target.value;

    tempObj.map((item) => {
      if (item.resourcename === e.currentTarget.name.split("-")[0]) {
        console.log("itemlist", item);
        item[name] = value ? "Yes" : "No";
      }
    });
   
  };

  const updateRoleSubmit = () => {
    console.log("tempObj", tempObj);
    let roleUpdateData = {
      resources: tempObj,
    };
    console.log("roleUpdateData:", roleUpdateData);
    if (resourceIdValue !== "") {
      MainService.updateRole(roleUpdateData, username, selectedRoleName)
        .then((res) => {
          getAllRolesList();

          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setUpdateRoleDialog(!updateRoleDialog);
    } else {
      NotificationManager.error("Please select site");
    }
    setTempObj([]);
  };
  /* End of Update Role Profile with checkboxes */
  const mapRoleClickForRoleManagement = () => {
    setRoleMapDialog(!roleMapDialog);
  };

  const roleMapSubmitClick = () => {
    let mapRoleData = {
      parentid: username,
      username: consumerUserNameValue,
      role: roleNameValue,
    };

    console.log("mapRoleData", mapRoleData);
    MainService.mapRole(mapRoleData, username)
      .then((res) => {
        getAllRolesList();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setRoleMapDialog(!roleMapDialog);
  };

  const viewAllRolesClick = () => {
    setAllRolesView(!allRolesView);
    setMappedRolesView(!mappedRolesView);
  };

  const roleMappedUpdateDialogClick = (consumerUserName, rolename) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedRoleName(rolename);
    setUpdateMappedRoleDialog(!updateMappedRoleDialog);
  };
  const updateMappedRoleSubmit = () => {
    MainService.updateRoleOfUser(
      username,
      selectedConsumerUserName,
      selectedRoleName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateMappedRoleDialog(!updateMappedRoleDialog);
    getAllMappedRoles();
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredRolesList.filter((row) => {
      return row.rolename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllRolesList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const requestSearchForMAppedRoles = (searchedVal) => {
    const filteredRows = filteredMappedRolesList.filter((row) => {
      return row.role.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllMappedRolesList(filteredRows);
  };
  const cancelSearchForMappedRoles = () => {
    setSearched("");
    requestSearchForMAppedRoles(searched);
  };

  

  return (
    <>
      <div className="container" id="mainContentList">
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Create Role Profile"
            className={classes.fab}
            onClick={() => {
              setCreateRoleDialog(!createRoleDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <Grid>
              <div style={{ float: "right" }}>
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={listViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={gridViewClick}
                ></Button>
              </div>
            </Grid>
            {allRolesView ? (
              <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    mapRoleClickForRoleManagement();
                  }}
                >
                  Map Role Profile
                </Button>
              </div>
            ) : (
              <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    viewAllRolesClick();
                  }}
                >
                  View All Roles
                </Button>
              </div>
            )}
          </div>
          {listView && (
            <div>
              {allRolesView ? (
                <Paper sx={{ width: "100%" }}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell
                            className="headerFontSize"
                            align="center"
                            colSpan={12}
                          >
                            Role Profiles List
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Role Profile Name
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={2}
                          >
                            Operate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allRolesList.length > 0 ? (
                          allRolesList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((roles, index) => {
                              return (
                                <TableRow hover tabIndex={-1} key={index}>
                                  <TableCell align="center">
                                    {roles.rolename}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        roleDeleteDialogClick(roles.rolename);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        roleExistingResourcesClick(
                                          roles.rolename
                                        );
                                      }}
                                    >
                                      Resources
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow align="center">
                            <TableCell align="center" colSpan={12}>
                              Roles Not Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={allRolesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <></>
              )}
            </div>
          )}
          {gridView && (
            <div>
              {allRolesView ? (
                <div>
                  <h2>Role Profiles List</h2>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <div className="cardStyleForDashboardItems">
                    {allRolesList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {allRolesList.map((roles, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <p>
                              <b>Role Profile Name:</b>
                              {roles.rolename}
                            </p>
                            <div>
                              <h4 className="cardactiontitle">Operate</h4>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  roleExistingResourcesClick(roles.rolename);
                                }}
                              >
                                Resources
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  roleDeleteDialogClick(roles.rolename);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>Roles not found.</h4>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </Box>

        {/* create New Role Profile*/}
        <Dialog
          fullScreen={fullScreen}
          open={createRoleDialog}
          onClose={handleClose}
          aria-labelledby="craete New Role Profile"
        >
          <DialogTitle id="createNewRole">
            {"Create New Role Profile"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">AssetDeveloper Id :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Profile Name :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="resourceType">Resource Type</InputLabel>
              <Select
                id="resourceType"
                name="resourceType"
                value={resourceTypeValue}
                onChange={(e) => {
                  setResourceTypeValue(e.target.value);
                }}
              >
                <MenuItem selected value="SOLUTION">
                  SOLUTION
                </MenuItem>
                <MenuItem value="SITE">SITE</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="resourceName">Resource Name :</InputLabel>
              <Select
                id="resourceName"
                name="resourceName"
                type="text"
                value={resourceNameValue}
                onChange={(e) => {
                  setResourceNameValue(e.target.value);
                  let catname = allSitesList.filter((data) => {
                    return data.sitename === e.target.value;
                  });
                  setResourceIdValue(catname[0].siteid);
                }}
              >
                {(resourceTypeValue === "SOLUTION" ||
                  resourceTypeValue === "solution") && <></>}

                {(resourceTypeValue === "SITE" ||
                  resourceTypeValue === "site") &&
                  allSitesList.length > 0 &&
                  allSitesList.map((sites, index) => (
                    <MenuItem key={`${index} `} value={sites.sitename}>
                      {sites.sitename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="readPermission">Read Permission</FormLabel>
              <RadioGroup
                row
                aria-labelledby="readPermission"
                name="readPermissionGroup"
                value={readPermissionValue}
                onChange={(e) => {
                  setReadPermissionValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="writePermission">Write Permission</FormLabel>
              <RadioGroup
                row
                aria-labelledby="writePermission"
                name="writePermissionGroup"
                value={writePermissionValue}
                onChange={(e) => {
                  setWritePermissionValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="executePermission">Execute Permission</FormLabel>
              <RadioGroup
                row
                aria-labelledby="executePermission"
                name="executePermission"
                value={executePermissionValue}
                onChange={(e) => {
                  setExecutePermissionValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setResourceTypeValue("");
                setResourceNameValue("");
                setCreateRoleDialog(!createRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Existing Role Profile Resources */}
        <Dialog
          fullScreen={fullScreen}
          open={roleExistingResourcesDialog}
          onClose={handleClose}
          aria-labelledby="existingResources"
        >
          <DialogTitle id="existingResources">
            {"Existing Resources"}
          </DialogTitle>
          <DialogContent>
            <h4>Existing Role Profile Resources</h4>
            {roleResourcesList.map((resources, index) => (
              <p key={index}>
                <b>Site ID{index + 1}:</b> {resources.resourceid} --&gt;
                {resources.resourcename}
                <span>
                  <IconButton size="large">
                    <DeleteIcon
                      style={{ color: "red" }}
                      fontSize="small"
                      onClick={() => {
                        existingResourceUnmapClick(resources.resourceid);
                      }}
                    />
                  </IconButton>
                </span>
              </p>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleUpdateDialogClick(selectedRoleName);
              }}
            >
              Site Assignment
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleExistingResourcesDialog(!roleExistingResourcesDialog);
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
        {/* update Role Profile */}
        <Dialog
          fullScreen={fullScreen}
          open={updateRoleDialog}
          onClose={handleClose}
          aria-labelledby="Update Role Profile"
        >
          <DialogTitle id="updateRole">{"Update Role Profile"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="resourceType">Resource Type</InputLabel>
              <Select
                id="resourceType"
                name="resourceType"
                value={resourceTypeValue}
                onChange={(e) => {
                  setResourceTypeValue(e.target.value);
                }}
              >
                <MenuItem selected value="solution">
                  SOLUTION
                </MenuItem>
                <MenuItem value="site">SITE</MenuItem>
              </Select>
            </FormControl>
            {resourceTypeValue === "solution" && <></>}
            {(resourceTypeValue === "SITE" || resourceTypeValue === "site")&&
              filteredSitesList.length > 0 &&
              filteredSitesList.map((sites, index) => (
                <div className="cardStyleForDashboardItems" key={index}>
                  <div className="cardStyleForDashboardItemsBody">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"${index}"}
                          value={sites.sitename}
                          onChange={(e) => checkboxHandler(e)}
                        ></Checkbox>
                      }
                      label={sites.sitename}
                    />
                    <p>
                      <b> Site Name:</b>
                      {sites.sitename}
                    </p>
                    <p>
                      <b> Site Type:</b>
                      {sites.sitetype}
                    </p>
                    <div className="cardStyleForDashboardItemsBody">
                      <FormControl>
                        <FormLabel id={`ReadPermission${index}`}>
                          Read Permission
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby={`ReadPermission${index}`}
                          name={`${sites.sitename}-readpermission`}
                          defaultValue="false"
                          onChange={(e) => readRadioBtnHandler(e)}
                        >
                          <FormControlLabel
                            control={<Radio value="true" />}
                            label="Yes"
                          />

                          <FormControlLabel
                            key={index}
                            control={<Radio value="false" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl>
                        <FormLabel id={`WritePermission${index}`}>
                          Write Permission
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby={`WritePermission${index}`}
                          name={`${sites.sitename}-writepermissions`}
                          defaultValue="false"
                          onChange={(e) => readRadioBtnHandler(e)}
                        >
                          <FormControlLabel
                            control={<Radio value="true" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            control={<Radio value="false" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl>
                        <FormLabel id={`ExecutePermission${index}`}>
                          Execute Permission
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby={`ExecutePermission${index}`}
                          name={`${sites.sitename}-executepermission`}
                          defaultValue="false"
                          onChange={(e) => readRadioBtnHandler(e)}
                        >
                          <FormControlLabel
                            control={<Radio value="true" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            control={<Radio value="false" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ))}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setTempObj([]);
                setUpdateRoleDialog(!updateRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Role Profile*/}
        <Dialog
          fullScreen={fullScreen}
          open={roleDeleteDialog}
          onClose={handleClose}
          aria-labelledby="deleteRole"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Role Profile"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleDeleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleDeleteDialog(!roleDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Map Role Profile*/}
        <Dialog
          fullScreen={fullScreen}
          open={roleMapDialog}
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="mapRole"
        >
          <DialogTitle id="mapRole">{"Map Role Profile"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="consumerUserName">
                Consumer User Name :
              </InputLabel>

              <Select
                id="consumerUserName"
                name="consumerUserName"
                type="text"
                value={consumerUserNameValue}
                onChange={(e) => {
                  setConsumerUserNameValue(e.target.value);
                }}
              >
                {consumerUsersList.length > 0 &&
                  consumerUsersList.map((consumerUsers, index) => (
                    <MenuItem key={`${index} `} value={consumerUsers.username}>
                      {consumerUsers.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Profile Name :</InputLabel>
              <Select
                id="roleName"
                name="roleName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              >
                {allRolesList.length > 0 &&
                  allRolesList.map((roles, index) => (
                    <MenuItem key={`${index} `} value={roles.rolename}>
                      {roles.rolename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleMapSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleMapDialog(!roleMapDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* update Mapped Role Profile  */}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateMappedRoleDialog}
          onClose={handleClose}
          aria-labelledby="Update Mapped Role Profile"
        >
          <DialogTitle id="updateMappedRole">
            {"Update Mapped Role Profile"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingResourceName">
                Consumer User Name :
              </InputLabel>
              <Input
                disabled
                id="existingResourceName"
                name="existingResourceName"
                type="text"
                value={selectedConsumerUserName}
              ></Input>
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingResourceName">
                Role Profile Name :
              </InputLabel>
              <Select
                id="existingResourceName"
                name="existingResourceName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              >
                <MenuItem selected>
                  {"Current Role Profile--> "}
                  {selectedRoleName}
                </MenuItem>
                {allRolesList.length > 0 &&
                  allRolesList.map((sites, index) => (
                    <MenuItem key={`${index} `} value={sites.id}>
                      {sites.rolename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateMappedRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateMappedRoleDialog(!updateMappedRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
