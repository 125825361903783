import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./AboutusComponent.css";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function AboutBanner() {
  return (
   <UpdatesBanner className="about-us-banner" bannerbodycopy1="We provide easy access to care products" bannerTitle="We are Agri-healthcare Company"/>
  );
}
