import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import img4 from "../../images/double-check.png";
import Container from "@material-ui/core/Container";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AAMCBenefits() {
  const benefits = [
    {
      img: img1,
      title: "New Revenue Stream",
      bodycopy: "Introducing a new revenue stream to boost your bottom line and increase profitability.",
    },
    {
      img: img2,
      title: "Broadening the Scope",
      bodycopy: "Broadening the scope to unlock diverse revenue streams and fuel sustainable growth.",
    },
    // {
    //   img: img3,
    //   title: "Create Jobs",
    //   bodycopy:
    //     "Investable trade concept for agriculture asset class will help to create more jobs",
    // },
    // {
    //   img: img4,
    //   title: "Easy Capital Access",
    //   bodycopy:
    //     "Alternate options for Agri-Entreprenuer to raise investment and Investor to invest in tangible assets",
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>
  );
}
