import React from "react";
import Iaa1 from "../../images/IMA/conveyor.svg";
import Iaa2 from "../../images/IMA/production (1).svg";
import Iaa3 from "../../images/IMA/processing-system (1).svg";
import Iaa4 from "../../images/IMA/tank (1).svg";
import { Row, Col } from "react-bootstrap";
import "./IAABlock.css";
import { Button } from "@material-ui/core";

export default function IAMBlock({producers,section}) {
  let Image
  if (section == "processing"){
     Image = [
      { img: Iaa3,href: "#ProcessingMachinery"  },
      { img: Iaa4, href: "#StorageMachinery" },
      { img: Iaa1, href: "#FillingMachinery"   },

  
    ];
  }else{
     Image = [
      { img: Iaa3,href: "#ProcessingMachinery"  },
      { img: Iaa4, href: "#StorageMachinery" },
      { img: Iaa1, href: "#FillingMachinery"   },
  
    ];
  }



 
  return (
 
    <>
    {
      !producers ?  <div className="Iaa-container Ima-container mb-5">
      <h2 className="text-center m-0"></h2>

      <Row className="justify-content-center">
        {Image.map((e) => {
          return (
            <Col md={4} className="md-md-0 mb-3">
              <a href={e.href}>
                <div
                  className={`${
                    e.href
                      ? "imageHover Iaa-Cards  Iaa-Cards--mod d-flex justify-content-center align-items-center"
                      : "Iaa-Cards Iaa-Cards--mod d-flex justify-content-center align-items-center card-deactivated"
                  }`}
                >
                  {" "}
                  <img
                    className={`${e.href && "imgCursor filteredimg"}`}
                    src={e.img}
                    alt="icon"
                  />
                </div>
              </a>
            </Col>
          );
        })}
      </Row>
    </div>: 
     <div className="Iaa-container Ima-container mb-5">
     <h2 className="text-center mb-0"></h2>

     <Row className="justify-content-center">
       {Image.map((e) => {
         return (
           <Col md={4} className="md-md-0 mb-3">
             
               <div
                 className= " Iaa-Cards  Iaa-Cards--mod d-flex justify-content-center align-items-center"
               >
                 {" "}
                 <img
                   className={`${e.href && "imgCursor filteredimg"}`}
                   src={e.img}
                   alt="icon"
                 />
               </div>
           </Col>
         );
       })}
     </Row>
     <Button variant="contained" color="primary">
          <a href="/auth/3/cbnsignup">Join </a>
        </Button>
   </div>
    }</>
   
  );
}
