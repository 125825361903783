import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import "./FinancialService.css";




export default function FinancialService(props) {
  const customPropValue = props.location?.state?.customProp ;

  const commonProps = {
    className1: "aboutBgGrey",
  };
  
  const ffSection = (

    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="    GBR Farming extends a diverse range of financial services to agri-storage providers, empowering them to optimize operations and achieve financial sustainability in the agricultural ecosystem.
  "
  
  />





  );
  
  const ffSection1 = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Care offers a specialized loan service designed to support agri-retailers, facilitating access to necessary funding. This service aims to enhance financial stability and growth opportunities within the agricultural retail sector."
  
  />
  );
  const CollectivesSection = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Care extends its expertise in financial support by providing a specialized loan service tailored for collectives. Through this service, GBR Care facilitates access to crucial funding for collectives, enabling them to pursue their agricultural ventures with confidence. Whether it's for expanding operations, investing in equipment, or enhancing infrastructure, GBR Care's loan service empowers collectives to achieve their goals and drive sustainable growth."
  
  />
  );
  const growerSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR Farming facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Farming is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."
  
  />
  );
  
  const PMSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR Farming offers dedicated loan services to agri-contract manufacturers, providing crucial financial support for machinery acquisition and operational optimization in the agricultural industry.
  "
  
  />
  );
  







  
  const LPSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Farming enables agri-transporters to access tailored loan services, providing essential financial support for optimizing their transportation operations within the agricultural sector.

  "
  
  />
  );
  return (
    
    <>

    <HeaderComponent/>
    <UpdatesBanner
        className="financialSupportServiceBanner"
        bannerText={["Financial Services"]}
      />
      <div className="subSectionWrapper" >
      {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="GBR Farming facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Farming is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}

{customPropValue === "farming" && growerSection }
      {customPropValue === "storage" ? ffSection : null}
      {customPropValue === "processing" && PMSection}
      {customPropValue === "selling" ? ffSection1 : null}
      {customPropValue === "transport" ? LPSection : null}
      {customPropValue === "collectives" ? CollectivesSection : null}
      
      </div>
    <FooterComponent/>
    </>
  )
}
