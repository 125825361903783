import React, { useState, useEffect, memo, Fragment } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function UploadContent({
  props,
  modelShow,
  parent,
  closeModel,
  reloadComponent,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  /* Upload Content */
  const [postFile, setPostFile] = useState(null);
  const [getCategoryNameValueForForm, setCategoryNameValueForForm] =
    useState("");
  const [getCategoryName, setCategoryName] = useState("");
  const [subCategoryNameValueForForm, setSubCategoryNameValueForForm] =
    useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [getExpiryDateValue, setExpiryDateValue] = useState("");
  const [getContentName, setContentName] = useState("");

  const [getListOfCategories, setListOfCategories] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);

  useEffect(() => {
    getCategorys();
  }, [setListOfCategories]);

  useEffect(() => {
    if (!getCategoryNameValueForForm) return;
    getSubCategories(parent, getCategoryNameValueForForm);
  }, [getCategoryNameValueForForm]);

  /* Upload Content */

  const getCategorys = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        let filterValues = [
          "MILK",
          "HONEY",
          "MEAT",
          "ADVISORY",
          "YOUTUBE",
          "UPDATE",
          "NEWS",
          "CHECKLIST",
          "VIDEO",
          "AUDIO",
          "ANIMAL-AnimalFeed-TENDERS",
          "ANIMAL-LivestockFeed-TENDERS",
          "Tenders",
          "BUY-INPUT",
          "PRICE-DISCOVERY",
          "SITE-UPDATES",
          "LIVESTOCK",
          "IMAGE",
          "POST",
          "JOBS",
          "ADVERTISEMENTS",
          "PITCH-DECK",
          "GOVTSCHEMES",
          "PROJECTREPORT",
          "PRODUCTS",
          "BRANDING",
        ];
        let filterData = res.filter((data, index) => {
          return !filterValues.includes(data.categoryname);
        });
        setCategoryNameValueForForm(filterData[0].categoryid);
        setCategoryName(filterData[0].categoryname);
        setListOfCategories(filterData);
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  };

  const getSubCategories = (name, id) => {
    MainService.getSubCategoriesForAD(name, id)
      .then((data) => {
        if (data.length > 0) {
          setSubCategoryNameValueForForm(data[0].contentsubcategoryname);
          setsubCategoriesList(data);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setCategoryNameValueForFormHandler = (e) => {
    setCategoryNameValueForForm(e.target.value);
    const categoryNameVale = getListOfCategories.filter((data) => {
      return data.categoryid.includes(e.target.value);
    });
    setCategoryName(categoryNameVale[0].categoryname);
  };

  const submitUploadContent = () => {
    const postData = new FormData();
    postData.append("file", postFile);
    postData.append("categoryid", getCategoryNameValueForForm);
    postData.append("categoryname", getCategoryName.toUpperCase());
    postData.append("contentname", getContentName);
    postData.append("consumeradminid", props.consumeradminid);
    postData.append("contentdescription", descriptionValue);
    postData.append("contentcreator", props.businessid);
    postData.append("mimetype", "image/*");
    postData.append("contentformat", "IMAGE");
    postData.append("price", props.productcost);
    postData.append("productid", props.productid);
    postData.append("contentownerid", props.productid);
    postData.append("expirydate", getExpiryDateValue);
    postData.append(
      "contentsubcategory",
      subCategoryNameValueForForm.toUpperCase()
    );
    postData.append("unit", props.unit);
    postData.append("discountedprice", props.discountedprice);

    MainService.uploadProductContents(postData, parent, username)
      .then((res) => {
        closeModel();
        reloadComponent();
        return NotificationManager.success("Content Uploaded");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  /* EOD Upload Content */

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Upload Content"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="selectcategory">Select Category:</InputLabel>
            <Select
              id="selectcategory"
              name="selectcategory"
              type="text"
              value={getCategoryNameValueForForm}
              onChange={(e) => {
                setCategoryNameValueForFormHandler(e);
              }}
            >
              {getListOfCategories &&
                getListOfCategories.map((idObj, index) => (
                  <MenuItem key={`${index} `} value={idObj.categoryid}>
                    {idObj.categoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="subCategoryName">
              Select Sub-Category:
            </InputLabel>
            <Select
              id="subCategoryName"
              name="subCategoryName"
              type="text"
              value={subCategoryNameValueForForm}
              onChange={(e) => {
                setSubCategoryNameValueForForm(e.target.value);
              }}
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="productid">Product Id :</InputLabel>
            <Input
              id="productid"
              name="productid"
              type="text"
              value={props.productid}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="postName">Product Name :</InputLabel>
            <Input
              id="postName"
              name="postName"
              type="text"
              value={getContentName}
              onChange={(e) => {
                setContentName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth={true}>
            <InputLabel htmlFor="contentOwner">Content Owner</InputLabel>
            <Input
              id="contentOwner"
              name="contentOwner"
              type="text"
              value={props.productid}
            ></Input>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="contentUploader">
              {" "}
              Content Uploader:
            </InputLabel>
            <Input
              id="contentUploader"
              name="contentUploader"
              type="text"
              value={props.businessid}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
            <Input
              id="contentApprover"
              name="contentApprover"
              type="text"
              value={props.consumeradminid}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="description"> Description:</InputLabel>
            <Input
              id="description"
              name="description"
              type="text"
              value={descriptionValue}
              onChange={(e) => {
                setDescriptionValue(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="price"> Price :</InputLabel>
            <Input
              id="price"
              name="price"
              type="text"
              value={props.productcost}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="discountprice"> Discounted Price:</InputLabel>
            <Input
              id="discountprice"
              name="discountprice"
              type="text"
              value={props.discountedprice}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="expiryDate"> Expiry Date:</InputLabel>
            <Input
              id="expiryDate"
              name="expiryDate"
              type="date"
              value={getExpiryDateValue}
              onChange={(e) => {
                setExpiryDateValue(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="unit"> Unit:</InputLabel>
            <Input id="unit" name="unit" type="text" value={props.unit} />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="doc">file:</InputLabel>
            <Input
              id="doc"
              name="doc"
              type="file"
              onChange={(e) => {
                setPostFile(e.target.files[0]);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              submitUploadContent();
            }}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={closeModel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(UploadContent);
