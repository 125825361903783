var baseUrl_login = window.location.protocol+'//'+window.location.hostname+':3001/rest/v1/';
var baseUrl_signup = window.location.protocol+'//'+window.location.hostname+':3002/rest/v1/';
var baseUrl_roleaccess = window.location.protocol+'//'+window.location.hostname+':3003/rest/v1/';

let ConsumerAdmin;
let AssetDeveloper;

if(process.env.REACT_APP_DEVELOPMENT_ENV === "production"){
  window.newBaseUrlOEM = "https://content.gbrservice.com/";
  window.newBaseUrl_oem = "https://content.gbrservice.com/";
  window.proxyUrl = "https://content.gbrservice.com/";
  window.gbrServiceUrl = "https://iam.gbrservice.com/";
  window.newBaseUrl = "https://iam.gbrservice.com/";
  window.sendRequestUrl = "http://15.206.178.137:8080/"
  Window.baseUrl="https://registry.gbrservice.com/";

  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
  AssetDeveloper =process.env.REACT_APP_ASSET_DEVELOPER_PRODUCTION;
}else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging"){
  window.newBaseUrlOEM = "https://proxystaging.gbrservice.com/";
  window.newBaseUrl_oem = "https://proxystaging.gbrservice.com/";
  window.proxyUrl = "https://proxystaging.gbrservice.com/";
  // window.gbrServiceUrl = "http://35.153.176.60:8080/";
  // window.newBaseUrl = 'http://35.153.176.60:8080/';
  window.gbrServiceUrl = "  https://iamstaging.gbrservice.com/";
  window.newBaseUrl = '  https://iamstaging.gbrservice.com/';
  window.baseUrl = "https://registrystaging.gbrservice.com/";

  window.sendRequestUrl = 'https://registrystaging.gbrservice.com/';

ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
AssetDeveloper =process.env.REACT_APP_ASSET_DEVELOPER_STAGING;

}

// Global Access API

/* window.newBaseUrl = "http://13.234.37.125:8080";
window.newBaseUrl_oem = "http://13.233.122.53:8080";
window.gbrServiceUrl = "http://um.gbrservice.com";
window.proxyUrl = "http://proxy.gbrservice.com"; */

// window.liveMonitorUrl = "http://54.185.221.117:8902/";
// window.assetCurrentLocationUrl = "http://54.185.221.117:8902/";
// window.liveEnvUrl = "http://54.185.221.117:8901/";
// window.tradingServer = "http://35.154.32.232:8080/";
// window.qrcodeBaseUrl = "http://gbrfarming.in:5000/";
// window.otpLead = "http://13.232.216.219:3333/";
// window.liveMonitorUrl = 'http://54.185.221.117:8902/';
// window.socketUrl =
//   'http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001';
// window.assetCurrentLocationUrl = 'http://54.185.221.117:8902/';
// window.liveEnvUrl = 'http://54.185.221.117:8901/';
// //invoice server

// window.invoiceBaseURL = 'http://54.185.221.117:8903/';





window.liveMonitorUrl = 'https://trackstaging.gbrservice.com/';
window.socketUrl =
  'http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001';
window.assetCurrentLocationUrl = 'https://trackstaging.gbrservice.com/';
window.liveEnvUrl = 'https://datastaging.gbrservice.com/';
window.invoiceBaseURL = 'https://billstaging.gbrservice.com/';
window.otpLead = "https://otpauth.gbrapp.com/";
window.tradingServer = "https://proxystaging.gbrservice.com/";
window.qrcodeBaseUrl = "https://qrstaging.gbrservice.com/";
window.liveMonitorUrl = "https://trackstaging.gbrservice.com/";
window.assetCurrentLocationUrl = "https://trackstaging.gbrservice.com/";
window.liveEnvUrl = "https://datastaging.gbrservice.com/";


window.newServerContent = "https://content.gbrservice.com/"

/*******************************  OLD SERVER API ******************************** */
// window.newBaseUrlOEM = "http://54.167.97.129:8080/";
// window.newBaseUrl_oem = "http://54.167.97.129:8080/";
// window.proxyUrl = "http://54.167.97.129:8080/";
// window.gbrServiceUrl = "http://44.197.120.123:8080/";
// window.newBaseUrl = 'http://44.197.120.123:8080/';


/*******************************  NEW SERVER API ******************************** */
// window.newBaseUrlOEM = "https://content.gbrservice.com/";
// window.newBaseUrl_oem = "https://content.gbrservice.com/";
// window.proxyUrl = "https://content.gbrservice.com/";
// window.gbrServiceUrl = "https://iam.gbrservice.com/";
// window.newBaseUrl = "https://iam.gbrservice.com/";

/********************************* NEW SERVER API ENDS********************************** */


// window.liveMonitorUrl = "http://35.89.191.242:8055/";
// window.socketUrl = "http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001";
// window.assetCurrentLocationUrl = "http://35.89.191.242:8055/";





// window.tradingServer = 'http://35.154.32.232:8080/';
// window.qrcodeBaseUrl = 'http://gbrfarming.in:5000/';




/*****************************new server api******************************/ 








window.appConfig = {
    // "getSiteEnvInfo":window.liveEnvUrl+"ems/livedata/",
    // "getAssetEnvInfo": window.liveEnvUrl+"ems/livedata/",
    // "getMortalityInfo":window.proxyUrl+"EAP/getMortalityInfo/",
    // "getRoleResourceAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
    // "assetFeedbacks": window.newBaseUrl_oem+"QMS-Gw/assetFeedbacks/",
    // "addSuperRole":window.gbrServiceUrl+"AuthBackEndGw/addRole/",
    // "getSuperRoles":window.gbrServiceUrl+"AuthBackEndGw/getRoleList/",
    // "updateSuperRole":window.gbrServiceUrl+"AuthBackEndGw/updateRoleDesc/",
    // "deleteSuperRole":window.gbrServiceUrl+"AuthBackEndGw/deleteRoleId/",
    // "updateAssetInfo": window.proxyUrl+"EAP/updateAssetInfo/",
    // "assetReports": window.newBaseUrl_oem+"QMS-Gw/assetReports/",
    // "getCompliances": window.proxyUrl+"QMS-Gw/getAllCompliances/",
    // "communityUsersInfo" : window.newBaseUrl+"RegisterGateway/userInfo/",
    // "getProductsBasedonCategoriId" : window.proxyUrl+"AgriProductProxy/productlist/",
    // "viewListProductionFarmers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getAllUserTypes":window.gbrServiceUrl+"AuthBackEndGw/getConsumerUserTypes/",
    // "getConsumerUserTypeRole" : window.gbrServiceUrl+"AuthBackEndGw/getRole/",
    // "assetComplaints":window.newBaseUrl_oem+"QMS-Gw/assetComplaints",
    // "siteCompliance":window.newBaseUrl_oem+"QMS-Gw/complaints/",
    // "settingsInfo":window.newBaseUrl_oem+"QMS-Gw/getSettingsInfo/",
    // "getHealthStatusBasedOnAssetId":window.newBaseUrl_oem+"AnalyticsProxy/assetHealthStatus/",
    // "getAttendanceLocationInfo":window.newBaseUrl_oem+"EAP/getAttendanceLocationInfo/",
    // "getSiteReports":window.newBaseUrl_oem+"QMS-Gw/reports/",
    // "siteIssue":window.proxyUrl+"QMS-Gw/issues/",
    // "getGlobalUsers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getSHG":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "updateWhitelabelCost":window.newBaseUrl_oem+"EAP/updateAssetStatus/",
    // "updateSellingCost":window.proxyUrl+"EAP/addSellingCost/",
    // "unmapExistingResourceFromRole":window.gbrServiceUrl+"AuthBackEndGw/deleteResource/",
    // "getCategoryContentsBasedonCategoryid":window.proxyUrl+"ContentGw/contentlist/",
    // "getAllMSMEConsumerUsers" : window.newBaseUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "unmapUserRole":window.gbrServiceUrl+"AuthBackEndGw/unmapConsumerUserRole/",
    // "GetAgriProductProxeyDataBasedOnModel" : window.newBaseUrl_oem+"AgriProductProxy/allproducts/",
    // "getAllAssetsBasedonModel":window.proxyUrl+"EAP/getSolutionAssets/",
    // "addSiteAlarm":window.newBaseUrl_oem+"AdvisoryProxy/sitealarms/",
    // "getSiteAlarms":window.newBaseUrl_oem+"AdvisoryProxy/sitealarms/",
    // "getAssetAlarms":window.newBaseUrl_oem+"AdvisoryProxy/assetalarms/",
    // "getAssetsBasedonAll":window.newBaseUrl_oem+"EAP/getReadyforWhitelabelAssets/",
    // "DeleteProductItem"  : window.newBaseUrl_oem+"AgriProductProxy/productItem/",
    // "GetProductItems"  : window.newBaseUrl_oem+"AgriProductProxy/productItems/",
    // "ProductVideoURL"  : window.newBaseUrl_oem+"AgriProductProxy/updateProductVideoUrl/",
    // "UpdateLabCertificateURL"  : window.newBaseUrl_oem+"AgriProductProxy/updateLabUrl/",
    // "UpdateHistoryURL"  : window.newBaseUrl_oem+"AgriProductProxy/updateHistoryUrl/",
    // "DecrementItemCount"  : window.newBaseUrl_oem+"AgriProductProxy/decrementItemCount/",
    // "IncrementItemCount" : window.newBaseUrl_oem+"AgriProductProxy/incrementItemCount/",
    // "UpdateItemCount" : window.newBaseUrl_oem+"AgriProductProxy/updateItemCount/",
    // "UpdateProductRating" : window.newBaseUrl_oem+"AgriProductProxy/updateRatings/",
    // "UpdateProductCost" : window.newBaseUrl_oem+"AgriProductProxy/updateProductCost/",
    // "UpdateProductDiscount" : window.newBaseUrl_oem+"AgriProductProxy/updateDiscountPrice/",
    // "UpdateProductStatus" : window.newBaseUrl_oem+"AgriProductProxy/updateStatus/",
    // "UpdateProductQrURL" : window.newBaseUrl_oem+"AgriProductProxy/updateProductQRCode/",
    // "UpdateProductCertificateURL" : window.newBaseUrl_oem+"AgriProductProxy/updateQAUrl/",
    // "UpdateProductImageURL" : window.newBaseUrl_oem+"AgriProductProxy/updateProductImageUrl/",
    // "UploadProductCertificate" :  window.newBaseUrl_oem+"AgriProductProxy/uploadProductCertificate/",
    // "UploadProductContent" : window.newBaseUrl_oem+"AgriProductProxy/uploadProductContent/",
    // "assetCurrentLocation":window.assetCurrentLocationUrl+"livemonitoring/data/live/tenant/",
    // "getRoleResources":window.newBaseUrl+"AuthBackEndGw/getResources/",
    // "getQrCodeForModel":window.newBaseUrl_oem+"QR-Code-Proxy/modelqrcode/",
    // "getQrCodeForAsset":window.newBaseUrl_oem+"QR-Code-Proxy/assetqrcode/",
    // "getQrCodeForSite":window.newBaseUrl_oem+"QR-Code-Proxy/siteqrcode/",
    // "getProcessHealthStatusExe":window.newBaseUrl_oem+"AnalyticsProxy/getProcessHealthStatus/",
    // "getAssetHealthStatusExe":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetsHealthStatus/",
    // "getAssetStatisticsExe":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetStatistics/",
    // "AgriProductProxey" : window.newBaseUrl_oem+"AgriProductProxy/productInfo/",
    // "AgriProductProxeyItem" : window.newBaseUrl_oem+"AgriProductProxy/productItem/",
    // "AgriProductProxeyDelete" : window.newBaseUrl_oem+"AgriProductProxy/product/",
    // "GetAgriProductProxeyData" : window.newBaseUrl_oem+"AgriProductProxy/products/",
    // "GenerateProductQRCode" : window.newBaseUrl_oem+"QR-Code-Proxy/generateProduct/",
    // "getAssetStatisticsExe":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetStatistics/",
    // "assetCurrentLocation":window.liveMonitorUrl+"livemonitoring/data/live/tenant/",
    // "addAssetFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "getAssetIssues":window.newBaseUrl_oem+"QMS-Gw/assetIssues/",
    // "createAssetIssue":window.newBaseUrl_oem+"QMS-Gw/createIssue/",
    // "liveMonitoringData": window.socketUrl+"livemonitoring/data/live/",
    // "getProcessHealthStatusEx":window.newBaseUrl_oem+"AnalyticsProxy/getProcessHealthStatus/",
    // "getAssetHealthStatusEx":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetsHealthStatus/",
    // "getAssetStatisticsEx":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetStatistics/",
    // "getDashboardDataEx":window.newBaseUrl_oem+"AnalyticsProxy/getDashboardData/",
    // "getModels":window.newBaseUrl_oem+"EAP/getModel/",
    // "getModelInfo": window.proxyUrl+"EAP/modeldetails/",
    // "generateQrCode": window.qrcodeBaseUrl+"generate",
    // "generateSiteQrCode": window.newBaseUrl_oem+"QR-Code-Proxy/generateSite/",
    // "generateModelQrCode": window.newBaseUrl_oem+"QR-Code-Proxy/generateModel/",
    // "generateQrCodeWithId": window.newBaseUrl_oem+"QR-Code-Proxy/generateWithId/",
    // "generateBulkQrCodes":window.qrcodeBaseUrl+"generateBulk",
    // "downloadQrCode":window.qrcodeBaseUrl+"download?file=",
    // "createAccessToken":window.gbrServiceUrl+"AuthBackEndGw/token/",
    // "getToken": window.newBaseUrl+"AuthBackEndGw/getUserToken/",
    // "resetUserPassword": window.newBaseUrl+"AuthBackEndGw/resetPassword/",
    // "forgetUsername": window.newBaseUrl+"AuthBackEndGw/forgetUsername/",
    // "forgetPassword": window.newBaseUrl+"AuthBackEndGw/forgetPassword/",
    // "registerUser": window.newBaseUrl+"RegisterGateway/registerUser/",
    // "consumerRequestInfo": window.newBaseUrl+"RegisterGateway/consumerRequestInfo/",
    // "getUserType:":window.newBaseUrl+"AuthBackEndGw/getUserType/",
    // "getConsumerUserType":window.newBaseUrl+"AuthBackEndGw/getUserType/",
    // "getStepperCounter":window.gbrServiceUrl+"RegisterGateway/userJourneyDetails/",
    // "getAllAssetsOfAssetDeveloper": window.proxyUrl+"EAP/getSolutionAssets/",
    // "getAllRoles":window.gbrServiceUrl+"AuthBackEndGw/roles/",
    // "getAllConsumerUsers":window.gbrServiceUrl+"AuthBackEndGw/consumerUser/",
    // "getMappedRoles":window.gbrServiceUrl+"AuthBackEndGw/getUsersRoles/",
    // "viewAllSites":window.proxyUrl+"EAP/allsites/",
    // "deleteContent":window.newBaseUrl_oem+"ContentGw/content/",
    // "createMapping":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
    // "createUnMapping":window.newBaseUrl_oem+"EAP/unmapSiteAsset/",
    // "assetStatusUpdate":window.proxyUrl+"EAP/updateAssetStatus/",
    // "assetDelete":window.proxyUrl+"EAP/deleteAsset/",
    // "getAllSiteTypes":window.proxyUrl+"EAP/getSiteTypes/",
    // "getUnsignedAgreements":window.proxyUrl+"ContentGw/contentList/",
    // "viewAllTenderSites":window.proxyUrl+"EAP/allsites/",
    // "mappedTenderLegacyAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
    // "getAllTenderAssetsData":window.proxyUrl+"EAP/getSolutionAssets/",
    // "assetMapingtoTenderSite":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
    // "getAllTenderSiteTypes":window.proxyUrl+"EAP/getSiteTypes/",
    // "createTenderSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/createsite/",
    // "createSiteWithNodeId":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/sitecreationinfo/",
    // "viewListOfOperators":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getRole":window.newBaseUrl+"AuthBackEndGw/getRole/",
    // "getRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
    // "getAssetsOfSite":window.proxyUrl+"EAP/siteAssets/",
    // "viewListOfDeveloperQA":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getDevRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
    // "getDevRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
    // "getDevAssetsOfSite":window.proxyUrl+'EAP/siteAssets/',
    // "viewListOfExecutives":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getConsumerInfoRequest":window.newBaseUrl+"RegisterGateway/userInfo/",
    // "Production-Team":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getTeamRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
    // "getTeamRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
    // "viewListProductionManagers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "viewListOfProductionTeams":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getProductManagerRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
    // "getRoleProductionManagersResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
    // "viewListOfFarmers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getFarmerNodes":window.proxyUrl+"EAP/nodeSpecifications/",
    // "allApprovalRequests":window.proxyUrl+"FarmerProxy/approvalrequest/",
    // "allGrowRequests":window.proxyUrl+"FarmerProxy/growrequest/",
    // "allSellRequests":window.proxyUrl+"FarmerProxy/sellrequest/",
    // "allLoanRequests":window.proxyUrl+"FarmerProxy/loanrequest/",
    // "allVetVisitRequests":window.proxyUrl+"FarmerProxy/vetvisitrequest/",
    // "allBuyInputRequests":window.proxyUrl+"FarmerProxy/buyinputrequest/",
    // "allStoreRequests":window.proxyUrl+"FarmerProxy/storerequest/",
    // "allAdvisoryRequests":window.proxyUrl+"FarmerProxy/advisoryrequest/",
    // "setNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
    // "viewFarmerInfo":window.newBaseUrl+"RegisterGateway/consumerRequestInfo/",
    // "approvalReqStatus":window.proxyUrl+"FarmerProxy/updateApprovalRequest/",
    // "deleteContentSubCategory":window.proxyUrl+"ContentGw/deleteContentSubCategory/",
    // "growReqStatus":window.proxyUrl+"FarmerProxy/updateGrowRequest/",
    // "sellReqStatus":window.proxyUrl+"FarmerProxy/updateSellRequest/",
    // "LoanReqStatus":window.proxyUrl+"FarmerProxy/updateLoanRequest/",
    // "VetReqStatus":window.proxyUrl+"FarmerProxy/updateVetVisitRequest/",
    // "BuyReqStatus":window.proxyUrl+"FarmerProxy/updateBuyInputRequest/",
    // "StoreReqStatus":window.proxyUrl+"FarmerProxy/updateStoreRequest/",
    // "viewSettingsInfo":window.newBaseUrl_oem+"QMS-Gw/getSettingsInfo/",
    // "mappedLegacyAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
    // "getAllAssetsData":window.proxyUrl+"EAP/getSolutionAssets/",
    // "assetMapingtoSite":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
    // "siteDeleteHandler":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/deletesite/",
    // "siteRating":window.newBaseUrl_oem+"QMS-Gw/ratings/",
    // "createSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/sitecreation/",
    // "addSiteRating":window.newBaseUrl_oem+"QMS-Gw/ratings/",
    // "siteInfo":window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/siteInfo/",
    // "updateSiteInfo":window.proxyUrl+"EAP/updateSiteInfo/",
    // "viewAllFarms":window.proxyUrl+"EAP/getallsites/",
    // "viewAllFarmRelatedAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
    // "viewFarmMapRelatedAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
    // "farmsDropDownList":window.proxyUrl+"EAP/getallsites/",
    // "getConsumerNodeInfo":window.proxyUrl+"EAP/nodeSpecifications/",
    // "updateConsumerNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
    // "viewNodeStatus":window.proxyUrl+"EAP/getNodeStatus/",
    // "createConsumerUserOfIndividual":window.gbrServiceUrl+"AuthBackEndGw/userCreation/",
    // "deleteConsumerUserIndividual":window.gbrServiceUrl+"AuthBackEndGw/userDeletion/",
    // "cancelGrowRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "cancelSellRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "cancelLoanRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "cancelVetRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "resetConsumerUserPasswordIndividual":window.newBaseUrl+"AuthBackEndGw/createCredential/",
    // "updateConsumerUserInfoIndividual":window.newBaseUrl+"AuthBackEndGw/updateConsumerUserInfo/",
    // "ViewAssetOnboardings":window.newBaseUrl_oem+"EAP/getReadyForOnboardAssets/",
    // "updateReadyForOnboardAssetStatus":window.newBaseUrl_oem+"EAP/updateAssetStatus/",
    // "AttendanceLocationHistory":window.newBaseUrl_oem+"EAP/getAttendanceLocations/",
    // "NewAttendanceLocation":window.newBaseUrl_oem+"EAP/addAttendanceLocation/",
    // "DeleteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/deleteAttendanceLocation/",
    // "UpdateAttendanceLocation":window.newBaseUrl_oem+"EAP/updateAttendanceLocation/",
    // "getBirthDetails":window.newBaseUrl_oem+"EAP/getBirthInfos/",
    // "getBirthInfo":window.newBaseUrl_oem+"EAP/getBirthInfo/",
    // "addBirthInfo":window.newBaseUrl_oem+"EAP/addBirthInfo/",
    // "deleteBirthInfo":window.newBaseUrl_oem+"EAP/deleteBirthInfo/",
    // "updateBirthInfo":window.newBaseUrl_oem+"EAP/updateBirthInfo/",
    // "getHealthRecords":window.newBaseUrl_oem+"EAP/getHealthRecord/",
    // "addHealthRecords":window.newBaseUrl_oem+"EAP/addHealthRecord/",
    // "deleteHealthRecord":window.newBaseUrl_oem+"EAP/deleteHealthRecord/",
    // "updateHealthRecord":window.newBaseUrl_oem+"EAP/updateHealthRecord/",
    // "getNutrition":window.newBaseUrl_oem+"EAP/getNutritions/",
    // "addNutrition":window.newBaseUrl_oem+"EAP/addNutrition/",
    // "deleteNutrition":window.newBaseUrl_oem+"EAP/deleteNutrition/",
    // "updateNutrition":window.newBaseUrl_oem+"EAP/updateNutritionInfo/",
    // "getWeighingDetails":window.newBaseUrl_oem+"EAP/getWeighingDetails/",
    // "addWeighingDetails":window.newBaseUrl_oem+"EAP/addWeighingDetails/",
    // "deleteWeighingDetails":window.newBaseUrl_oem+"EAP/deleteWeighingDetails/",
    // "updateWeighingDetails":window.newBaseUrl_oem+"EAP/updateWeighingDetails/",
    // "getParturitionDetails":window.newBaseUrl_oem+"EAP/getParturitionDetails/",
    // "addParturitionDetails":window.newBaseUrl_oem+"EAP/addParturitionDetails/",
    // "deleteParturitionDetails":window.newBaseUrl_oem+"EAP/deleteParturitionDetails/",
    // "updateParturitionDetails":window.newBaseUrl_oem+"EAP/updateParturitionInfo/",
    // "getMortalityDetails":window.newBaseUrl_oem+"EAP/getMortalityDetails/",
    // "addMortalityDetails":window.newBaseUrl_oem+"EAP/addMortalityDetails/",
    // "deleteMortalityDetails":window.newBaseUrl_oem+"EAP/deleteMortalityDetails/",
    // "updateMortalityDetails":window.newBaseUrl_oem+"EAP/updateMortalityInfo/",
    // "getAssetOptionalStatusDetails":window.newBaseUrl_oem+"EAP/getAssetOperationalStatus/",
    // "deleteAssetOperationalStatusDetails":window.newBaseUrl_oem+"EAP/deleteAssetOperationalStatus/",
    // "updateAssetOperationalStatusDetails":window.newBaseUrl_oem+"EAP/updateAssetOperationalStatusInfo/",
    // "getLegacyAssetInfo":window.newBaseUrl_oem+"EAP/solutionAssetInfo/",
    // "getSellingCostDetails":window.proxyUrl+"EAP/getSellingCostDetails/",
    // "getSitePatrollingDetails":window.proxyUrl+"EAP/getPatrollingInfo/",
    // "deleteSitePatrolingDetails":window.newBaseUrl_oem+"EAP/deletePatrollingDetails/",
    // "updateSitePatrolingDetails":window.newBaseUrl_oem+"EAP/updatePatrollingInfo/",
    // "getAssetPatrollingDetails":window.proxyUrl+"EAP/getPatrollingInfo/",
    // "deleteAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/deletePatrollingDetails/",
    // "updateAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/updatePatrollingInfo/",
    // "siteFeedbackDetails":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "assetFeedbackDetails":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "siteIssueCreate":window.proxyUrl+"QMS-Gw/createIssue/",
    // "getSiteIssueData":window.proxyUrl+"QMS-Gw/issues/",
    // "dailyReportDetails":window.newBaseUrl_oem+"EAP/dailyReportList/",
    // "createDailyReport":window.newBaseUrl_oem+"EAP/dailyReport/",
    // "getGroupNutritionData":window.proxyUrl+"EAP/getNutritions/",
    // "addNutritionData":window.proxyUrl+"EAP/addNutrition/",
    // "deleteGroupNutrition":window.proxyUrl+"EAP/deleteNutrition/",
    // "updateGroupNutrition":window.proxyUrl+"EAP/updateNutritionInfo/",
    // "siteAttendanceLocationHistory":window.newBaseUrl_oem+"EAP/getAttendanceLocations/",
    // "siteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/addAttendanceLocation/",
    // "siteDeleteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/deleteAttendanceLocation/",
    // "siteUpdateAttendanceLocation":window.newBaseUrl_oem+"EAP/updateAttendanceLocation/",
    // "siteGrowRequests":window.proxyUrl+"FarmerProxy/growrequest/",
    // "addGrowRequest":window.proxyUrl+"FarmerProxy/growRequest/",
    // "siteGrowReqStatus":window.proxyUrl+"FarmerProxy/updateGrowRequest/",
    // "siteCancelGrowReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "siteSellRequests":window.proxyUrl+"FarmerProxy/sellrequest/",
    // "siteSellReqStatus":window.proxyUrl+"FarmerProxy/updateSellRequest/",
    // "siteCancelSellReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "siteLoanRequests":window.proxyUrl+"FarmerProxy/loanrequest/",
    // "siteVetVisitRequests":window.proxyUrl+"FarmerProxy/vetvisitrequest/",
    // "siteLoanReqStatus":window.proxyUrl+"FarmerProxy/updateLoanRequest/",
    // "siteCancelLoanReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "siteVetVisitReqStatus":window.proxyUrl+"FarmerProxy/updateLoanRequest/",
    // "siteCancelVetVisitReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
    // "assetIssueCreate":window.proxyUrl+"QMS-Gw/createIssue/",
    // "getAssetIssueData":window.proxyUrl+"QMS-Gw/assetIssues/",
    // "getNodeInfoBasedOnNodeId":window.proxyUrl+"EAP/getNodeInfo/",
    // "getMyCategoryTender":window.proxyUrl+"ContentGw/contentlist/",
    // "getListOfCategorys":window.proxyUrl+"ContentGw/getCategories/",
    // "getCategoryRelatedSubCategories":window.proxyUrl+"ContentGw/getAllContentSubCategories/",

    // "getConsumerUserInfoForAD":window.newBaseUrl+"RegisterGateway/userInfo/",
    // "getSellerInfo":window.newBaseUrl+"RegisterGateway/userInfo/",
    // "getAllSites":window.proxyUrl+"EAP/allsites/",
    // "getCategory":window.proxyUrl+"ContentGw/getCategoryId/",
    // "getSubCategoriesForAD":window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    // "getAllContentsList":window.proxyUrl+"ContentGw/contentList/",
    // "getCategoryTender":window.proxyUrl+"ContentGw/contentlist/",
    // "getTendersByDistricts":window.proxyUrl+"ContentGw/getSiteContent/",
    // "getTendersByOrganization":window.proxyUrl+"ContentGw/contentList/",
    // "getWhiteLabelAssetsForAD":window.proxyUrl+"EAP/getSolutionAssets/",
    // "createLegacyAsset":window.proxyUrl+"EAP/createAsset/",
    // "createContentSubCategory":window.proxyUrl+"ContentGw/contentsubcategory/",
    // "createAssetOEM":window.newBaseUrl+"RegisterGateway/registerUser/",
    // "createConsumerUser":window.gbrServiceUrl+"AuthBackEndGw/userCreation/",
    // "resetConsumerUserPassword":window.newBaseUrl+"AuthBackEndGw/createUserCredential/",
    // "updateConsumerUserInfo":window.newBaseUrl+"AuthBackEndGw/updateConsumerUserInfo/",
    // "deleteConsumerUser":window.gbrServiceUrl+"AuthBackEndGw/userDeletion/",
    // "getParentId":window.gbrServiceUrl+"AuthBackEndGw/getParentId/",
    // "getAllDistricts":window.proxyUrl+"EAP/allsites/",
    // "createContent":window.proxyUrl+"ContentGw/content/",
    // "uploadKYC":window.newBaseUrl_oem+"Contract-Upload-Gw/uploadDocument/",
    // "sendEmail":window.proxyUrl+"NotificationUA/sendEmail/",
    // "sendSMS":window.proxyUrl+"NotificationUA/sendSms/",
    // "updateWhiteLabelStatus":window.proxyUrl+"TradingGw/updatewhitelabelRequest/",
    // "updateNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
    // "addNodeToFarmer":window.proxyUrl+"EAP/nodeSpecification/",
    // "addGrowerRequest":window.proxyUrl+"FarmerProxy/growRequest/",
    // "addSellRequest":window.proxyUrl+"FarmerProxy/sellRequest/",
    // "addLoanRequest":window.proxyUrl+"FarmerProxy/loanRequest/",
    // "addVetRequest":window.proxyUrl+"FarmerProxy/vetvisitRequest/",
    // "getAssetOEMSofConsumerAdmin":window.newBaseUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "customerSupport":window.newBaseUrl_oem+"QMS-Gw/settings/",
    // "getLogisticProviders":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getAssetDistributors":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getAssetSellers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getAllAssetRequests":window.proxyUrl+"TradingGw/getbuyrequests/",
    // "getClinicAdmins":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
    // "getAllWhiteLabelRequests":window.newBaseUrl_oem+"TradingGw/getwhitelabelrequests/",
    // "getAssetOEMInfo":window.newBaseUrl+"RegisterGateway/consumerRequestInfo/",
    // "getLogisticProviderInfo":window.newBaseUrl+"RegisterGateway/userInfo/",
    // "getAssetHealthStatus":window.newBaseUrl_oem+"AnalyticsProxy/assetHealthStatus/",
    // "getProcessHealthStatus":window.newBaseUrl_oem+"AnalyticsProxy/processHealthStatus/",
    // "getComplaints":window.newBaseUrl_oem+"QMS-Gw/getComplaint/",
    // "getKYCDocuments":window.newBaseUrl_oem+"ContractGw/getKycDocument/",
    // "getAllUnsignedAgreementsForAD":window.newBaseUrl_oem+"ContractGw/getDocument/",
    // "getAllSignedAgreementsForAD":window.newBaseUrl_oem+"ContractGw/getDocument/",
    // "trackTheOrder":window.proxyUrl+"TradingGw/getbuyrequests/",
    // "getNodeInfo":window.proxyUrl+"EAP/nodeSpecifications/",
    // "getFarmerNodeInfo":window.newBaseUrl+"RegisterGateway/consumerUserRequestInfo/",
    // "viewNodesOfFarmer":window.proxyUrl+"EAP/nodeSpecifications/",
    // "getRoleProfileBasedOnRoleName":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
    // "createCustomerCare":window.newBaseUrl_oem+"QMS-Gw/addSettingsInfo/",
    // "updateAssetRequestStatusForAd":window.tradingServer+"TradingServer/updateBuyRequest/",
    // "createRole":window.gbrServiceUrl+"AuthBackEndGw/roleCreation/",
    // "updateCustomerCare":window.newBaseUrl_oem+"QMS-Gw/updateSettingsInfo/",
    // "deleteCustomerCare":window.newBaseUrl_oem+"QMS-Gw/deleteSettingsInfo/",
    // "deleteRole":window.gbrServiceUrl+"AuthBackEndGw/deleteConsumerRole/",
    // "deleteTenderSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/deletesite/",
    // "updateRole":window.gbrServiceUrl+"AuthBackEndGw/roleUpdate/",
    // "updateRoleOfUser":window.gbrServiceUrl+"AuthBackEndGw/updateMappedConsumerRole/",
    // "mapRole":window.gbrServiceUrl+"AuthBackEndGw/mapUserRole/",
    // "uploadAgreementForAD":window.newBaseUrl_oem+"Contract-Upload-Gw/updDocument/",
    // "uploadAgreement":window.proxyUrl+"Cloud-Storage-Gw/content/",
    // "getFarmUpdates":window.proxyUrl+"ContentGw/contentList/",
    // "getFarmUpdatesFromAll":window.proxyUrl+"ContentGw/contentsList/",
    // "getCategoriesBasedOnOEM":window.proxyUrl+"ContentGw/getAllCategories/",
    // "getSubCategoriesForOEM":window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    // "getUserJourney":window.gbrServiceUrl+"RegisterGateway/userJourneyDetails/",
    // "addAssetOparationalStatusDetails":window.newBaseUrl_oem+"EAP/addOperationalStatus/",
    // "addSellingCostDetails":window.proxyUrl+"EAP/addSellingCost/",
    // "addSitePatrolingDetails":window.newBaseUrl_oem+"EAP/addPatrollingDetails/",
    // "addAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/addPatrollingDetails/",

    // //"getWhiteLabelStatus": window.newBaseUrl_oem+"TradingGw/whitelabelstatus/", 
    
    // "getSolutionsIdList": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/solutions/",
    // "getAssetVerticalsIdList": window.newBaseUrl_oem+"EAP/getAssetVerticals/",
    // "getModelsList":window.newBaseUrl_oem+"EAP/models/",
    // "getCategoryTypesList": window.newBaseUrl_oem+"EAP/categoryType/",
    // "getAssetTypesList":window.newBaseUrl_oem+"EAP/assetType/",
    // // "getAssetTypes": window.newBaseUrl_oem+"EAP/assetTypes/", //done by srini
    // // "getSitesList": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",
    // "getSitesList": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/sites/",
    // "getSitesAssetsList": window.newBaseUrl_oem+"EAP/siteAssets/",
    // "getAssetInfo": window.newBaseUrl_oem+"EAP/solutionAssetInfo/",
    // "sendWhitelabelRequest": window.newBaseUrl_oem+"TradingGw/whitelabelRequest/",
    // "getSolutionAssets": window.newBaseUrl_oem+"EAP/getSolutionAssets/",
    // "getSolutionFeedbacks": window.newBaseUrl_oem+"QMS-Gw/feedbacks/",
    // "getSiteComplaints": window.newBaseUrl_oem+"QMS-Gw/complaints/",
    // "getDashboardData": window.newBaseUrl_oem+"AnalyticsProxy/getAssetOEMDashboardData/",
    // "getAssetsCount": window.newBaseUrl_oem+"AnalyticsProxy/getAssetsCount/",
    // //"getWhiteLabelAsserts": window.newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
    // "getAssetOEMData": window.newBaseUrl_oem+"AnalyticsProxy/getAssetOEMData/",
    // "getProfitSummary": window.newBaseUrl_oem+"AnalyticsProxy/getProfitSummary/",
    // "getStatementSummary": window.newBaseUrl_oem+"AnalyticsProxy/getStatementSummary/",
    // "getProfitStatementSummary": window.newBaseUrl_oem+"AnalyticsProxy/getProfitStatementSummary/",
    // "getAllSolutionsSite": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/solutionSites/",
    // "getAllAssociatedSolutionSites":window.newBaseUrl_oem+"EAP/associatedSites/",
    // "getWalletBalance": window.newBaseUrl_oem+"Wallet-Payment-Gw/walletBalance/",
    // "getAllSolutions": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/enterprise/allSolutions/",
    // "getAllAssociatedSolutions":window.newBaseUrl_oem+"EAP/associatedAssetDevelopers/",
    // "getBankDetails": window.newBaseUrl_oem+"Wallet-Payment-Gw/bankDetails/",
    // // "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
    // "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
    // "addBankDetails": window.newBaseUrl_oem+"Wallet-Payment-Gw/bankdetails/",
    
    // "paymentHistory": window.newBaseUrl_oem+"Wallet-Payment-Gw/paymentHistory/",
    // // "getFarmUpdates": window.newBaseUrl_oem+"ContentGw/getapps/",
    // "registerConsumerUser": window.newBaseUrl+"RegisterGateway/registerConsumerUser/",
    // // Done by Srini
    // "myAssetsList": window.newBaseUrl_oem+"EAP/OEMAssets/",
    // "getSitesDetails":window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/getSiteDetails/",
    // "getAssetDetails":window.newBaseUrl_oem+"EAP/assetDetails/",
    // "getWhiteLabelAssets": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",//These are sites only
    // "getGrowerDetails":  window.newBaseUrl+"RegisterGateway/assetdeveloperDetails/",
    // "getModelDetails":window.newBaseUrl_oem+"AnalyticsProxy/getModelDetails/",
    // "whiteLabelAssetsBasedonSite":window.newBaseUrl_oem+"EAP/getReadyforWhitelabelAsset/",
    // "whiteLabelAssetsBasedonAssetDeveloper":window.newBaseUrl_oem+"EAP/getReadyforWhitelabelAssets/",
    // "whiteLabelAssetsBasedonModel":window.newBaseUrl_oem+"EAP/readyforWhitelabelAssets/",
    // "addToCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
    // "findCartItems":window.newBaseUrl_oem+"TradingGw/investmentCart/",
    // "deleteAssetFromCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
    // "deleteModelFromCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
    // "getApps": window.newBaseUrl_oem+"ContentGw/getapps/",
    // "assetDeveloperFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "siteFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "assetFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "modelFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    // "schemeFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    
    // "uploadKyc":window.newBaseUrl_oem+"Contract-Upload-Gw/uploadDocument/",
    // "uploadAgreement":window.newBaseUrl_oem+"Contract-Upload-Gw/updDocument/",
    // "likeCount":window.newBaseUrl_oem+"ContentGw/updatelikecount/",
    // "deleteNominee": window.newBaseUrl+"OpUserServer/userserver/deleteNominee/",
    // "getNomineeList": window.newBaseUrl+"AuthBackEndGw/nomineeDetails/",
    // "updateNominee":window.newBaseUrl+"AuthBackEndGw/updateNomineeDetails/",
    // "addNominee": window.newBaseUrl+"AuthBackEndGw/addNominee/",
    // "updateUserInfo": window.newBaseUrl+"AuthBackEndGw/updateConsumerUser/",
    // "updateBankDetails":window.newBaseUrl_oem+"Wallet-Payment-Gw/updateBankdetails/",
    // "getUserImag":window.newBaseUrl+"AuthBackEndGw/getUserImage/",
    // "uploadUSerImage":window.newBaseUrl+"AuthBackEndGw/uploadImage/",
    // "getInvestedModels":window.newBaseUrl_oem+"EAP/investedmodels/",
    // "addMoneyToWallet":window.newBaseUrl_oem+"Wallet-Payment-Gw/moneyToWallet/",
    // "withdrawMoneyFromWallet":window.newBaseUrl_oem+"Wallet-Payment-Gw/withdrawMoneyFromWallet/",
    // "getUnsignedAgreements":window.newBaseUrl_oem+"ContractGw/getDocument/",
    // "getSignedAgreements": window.newBaseUrl_oem+"ContractGw/getDocument/",
    // "getOrderList":window.newBaseUrl_oem+"TradingGw/oemwhitelabelstatus/",
    
    // "preMatureSale":window.newBaseUrl_oem+"EAP/updateAssetStatus/",
    // "createPaymentLink":window.newBaseUrl_oem+"Wallet-Payment-Gw/payment_links/",
    // "makePayment":window.newBaseUrl_oem+"TradingGw/schemeWhitelabelRequest/",
    // "bookNowPayment":window.newBaseUrl_oem+"TradingGw/whitelabelRequest/",
    // "bankStatus":window.newBaseUrl_oem+"Wallet-Payment-Gw/getBankStatus/",


    /************************  Website URL's **************************************/
"productBasedonBrand": window.newBaseUrlOEM + 'AgriProductProxy/getproducts/' + ConsumerAdmin,
    "productList":window.newBaseUrlOEM + 'AgriProductProxy/getproductlist/' ,
    "getMobileNumber": window.baseUrl + 'RegisterGateway/userInfo/',
    "weatherUpdate": window.proxyUrl+"EAP/getallsites/",
    "getOtp" : window.otpLead+"otp/lead",
    "validateOtp" : window.otpLead+"validateOtp",
    "resendOTP" : window.otpLead+"resendOtp",
    "getsubCategoriesBasedonAdminandCategory" : window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    "PublicAccessToken" : window.gbrServiceUrl+"AuthBackEndGw/token/",
    "TokenRelam" : ConsumerAdmin,
    "Relam" : ConsumerAdmin+'/',
    "AssetDeveloperValue" :  AssetDeveloper + '/',
    "getProductsBasedonAdminUser" : window.newBaseUrlOEM+"AgriProductProxy/allproducts/",
    "getProducts" : window.newBaseUrlOEM+"AgriProductProxy/product/",
    "allConsumerUsreswithAssetType" : window.newBaseUrl_oem+"ApplicationSpecificGw/getAllConsumerUsers/",
    "allConsumerUsres" : window.newBaseUrl+"OpUserService/getAllConsumerUsers/",
    "getAssetVerticals" : window.newBaseUrlOEM+"EAP/getAssetVerticals/",
    "getCategories" : window.newBaseUrlOEM+"EAP/categoryType/",
    "getAssetType" : window.newBaseUrlOEM+"EAP/assetType/",
    "getModel" :  window.newBaseUrlOEM+"EAP/models/",
    "productLikeCount" : window.newBaseUrlOEM+"AgriProductProxy/updateLikeCount/",
    "getContentCategories" : window.proxyUrl+"ContentGw/getCategories/",
    "getContentList" :  window.proxyUrl+"ContentGw/contentlist/",
    "getAllContentOwnersBasedOnConsumerUser" :  window.proxyUrl+"ContentGw/getAllContentOwners/",
    "getContentListBasedOnConsumerUserandContentOwner" : window.proxyUrl+"ContentGw/contentlist/",
    "getContentBasedOnSiteId" :  window.proxyUrl+"ContentGw/getContent/",
    "getAllSitesBasedOnConsumerUser" : window.proxyUrl+"ContentGw/getAllSites/",
    "getSiteContent" : window.proxyUrl+"ContentGw/getSiteContent/",
    "getContentSubcategories" : window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    "getFarmUpdates": window.proxyUrl+"ContentGw/contentsList/",
    "getProductsBasedonCategoryId" : window.newBaseUrlOEM+"AgriProductProxy/product/",
    // "getProductsBasedonCategoryId" : window.newServerContent+"AgriProductProxy/product/",

    "getFarmUpdatesLists" : window.proxyUrl+"ContentGw/contentsList/",
    "getAssetTypeBasedOnAssetDeveleper" : window.newBaseUrl_oem+"EAP/assetTypes/",
    "getAllContentOwnersBasedOnCategory" : window.proxyUrl+"ContentGw/consumer2/contentowners/",
    "getSubCategoriesBasedonCategoryID" : window.proxyUrl+"ContentGw/",

    "userInfo": window.baseUrl+"RegisterGateway/consumerRequestInfo/",
    
    "contentLikeCount" : window.newBaseUrlOEM+"ContentGw/updatelikecount/",
    "getContentCreator" : window.proxyUrl+ "ContentGw/",
    "getContentonContentCreator" : window.proxyUrl+ "ContentGw/contentlist/",
    "sendRequest": window.sendRequestUrl+"AgriBusinessRegistryService/register/",



    "getRoleType" : window.newBaseUrl + "AuthBackEndGw/getRole/",
    "getRoleTypeResource" : window.newBaseUrl + "AuthBackEndGw/getResources/",


    /* ************* CBN URL's ************************** */
//    cbn:{
//     "unmapExistingResourceFromRole":window.gbrServiceUrl+"AuthBackEndGw/deleteResource/",
//     "getSHG":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "communityUsersInfo" : window.newBaseUrl+"RegisterGateway/userInfo/",
//     "assetCurrentLocation":window.assetCurrentLocationUrl+"livemonitoring/data/live/tenant/",
//     "getGlobalUsers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "siteEnv": window.proxyUrl+"IOB-Proxy/api/v1/solution/",
//     "recordReport": window.newBaseUrl_oem+"QMS-Gw/recordReport/",
//     "recordCompliance": window.proxyUrl+"QMS-Gw/recordCompliance/",
//     "addAssetMortality":window.proxyUrl+"EAP/addMortalityDetails/",
//     "getAssetMortality":window.proxyUrl+"EAP/getMortalityDetails/",
//     "settingsInfo":window.newBaseUrl_oem+"QMS-Gw/getSettingsInfo/",
//     "operatorOption": window.proxyUrl+"IOB-Proxy/api/v1/solution/",
//     "getAllUserTypes":window.gbrServiceUrl+"AuthBackEndGw/getConsumerUserTypes/",
//     "getConsumerUserTypeRole" : window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "addAssetAlarm":window.newBaseUrl_oem+"AdvisoryProxy/assetalarms/",
//     "getAssetAlarms":window.newBaseUrl_oem+"AdvisoryProxy/assetalarms/",
//     "addSiteAlarm":window.newBaseUrl_oem+"AdvisoryProxy/sitealarms/",
//     "getSiteAlarms":window.newBaseUrl_oem+"AdvisoryProxy/sitealarms/",
//     "getQrCodeForAsset":window.newBaseUrl_oem+"QR-Code-Proxy/assetqrcode/",
//     "addAssetFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "dailyReport": window.newBaseUrl_oem+"EAP/dailyReport/",
//     "addPatrol":window.proxyUrl+"EAP/addPatrollingDetails/",
//     "addAssetReport":window.newBaseUrl_oem+"EAP/dailyReport/",
//     "addAssetHealth":window.proxyUrl+"EAP/addHealthRecord/",
//     "createAssetIssue":window.newBaseUrl_oem+"QMS-Gw/createIssue/",
//     "getAssetIssues":window.newBaseUrl_oem+"QMS-Gw/assetIssues/",
//     "addAttendanceLocation":window.proxyUrl+"EAP/addAttendanceLocation/",
//     "getExecutiveDashboardData":window.newBaseUrl_oem+"AnalyticsProxy/getDashboardData/",
//     "getProcessHealthStatusExe":window.newBaseUrl_oem+"AnalyticsProxy/getProcessHealthStatus/",
//     "getAssetHealthStatusExe":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetsHealthStatus/",
//     "getAssetStatisticsExe":window.newBaseUrl_oem+"AnalyticsProxy/getExecutiveAssetStatistics/",

//     "getSiteReports":window.newBaseUrl_oem+"QMS-Gw/reports/",
//     "createSiteIssue":window.newBaseUrl_oem+"QMS-Gw/createIssue/",
//     "siteIssue":window.proxyUrl+"QMS-Gw/issues/",
//     "getRoleResourceAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
//     "getRoleResources":window.newBaseUrl+"AuthBackEndGw/getResources/",
//     "getRoleNameofOperator":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "updateOperatorProfileInfo":window.gbrServiceUrl+"AuthBackEndGw/updateConsumerUser/",
   
//     "generateQrCode": window.qrcodeBaseUrl+"generate",
//     "generateSiteQrCode": window.qrcodeBaseUrl+"generateSite",
//     "generateQrCodeWithId": window.qrcodeBaseUrl+"generateWithId",
//     "generateBulkQrCodes":window.qrcodeBaseUrl+"generateBulk",
//     "downloadQrCode":window.qrcodeBaseUrl+"download?file=",
//     "createAccessToken":window.gbrServiceUrl+"AuthBackEndGw/token/",
//     "getToken": window.newBaseUrl+"AuthBackEndGw/getUserToken/",
//     "resetUserPassword": window.newBaseUrl+"AuthBackEndGw/resetPassword/",
//     "forgetUsername": window.newBaseUrl+"AuthBackEndGw/forgetUsername/",
//     "forgetPassword": window.newBaseUrl+"AuthBackEndGw/forgetPassword/",
//     "registerUser": window.newBaseUrl+"RegisterGateway/registerUser/",
//     "consumerRequestInfo": window.newBaseUrl+"RegisterGateway/consumerRequestInfo/",
//     "getUserType:":window.newBaseUrl+"AuthBackEndGw/getUserType/",
//    "getConsumerUserType":window.newBaseUrl+"AuthBackEndGw/getUserType/",
//     "getStepperCounter":window.gbrServiceUrl+"RegisterGateway/userJourneyDetails/",
//     "getAllAssetsOfAssetDeveloper": window.proxyUrl+"EAP/getSolutionAssets/",
//     "getAllRoles":window.gbrServiceUrl+"AuthBackEndGw/roles/",
//     "getAllConsumerUsers":window.gbrServiceUrl+"AuthBackEndGw/consumerUser/",
//     "getMappedRoles":window.gbrServiceUrl+"AuthBackEndGw/getUsersRoles/",
//     "viewAllSites":window.proxyUrl+"EAP/allsites/",
//     "deleteContent":window.newBaseUrl_oem+"ContentGw/content/",
//     "createMapping":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
//     "createUnMapping":window.newBaseUrl_oem+"EAP/unmapSiteAsset/",
//     "assetStatusUpdate":window.proxyUrl+"EAP/updateAssetStatus/",
//     "assetDelete":window.proxyUrl+"EAP/deleteAsset/",
//     "getAllSiteTypes":window.proxyUrl+"EAP/getSiteTypes/",
//     "getUnsignedAgreements":window.proxyUrl+"ContentGw/contentList/",
//     "viewAllTenderSites":window.proxyUrl+"EAP/allsites/",
//     "mappedTenderLegacyAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
//     "getAllTenderAssetsData":window.proxyUrl+"EAP/getSolutionAssets/",
//     "assetMapingtoTenderSite":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
//     "getAllTenderSiteTypes":window.proxyUrl+"EAP/getSiteTypes/",
//     "createTenderSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/createsite/",
//     "createSiteWithNodeId":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/sitecreationinfo/",
//     "viewListOfOperators":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "getRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
//     "getAssetsOfSite":window.proxyUrl+"EAP/siteAssets/",
//     "viewListOfDeveloperQA":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getDevRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "getDevRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
//     "getDevAssetsOfSite":window.proxyUrl+'EAP/siteAssets/',
//     "viewListOfExecutives":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getConsumerInfoRequest":window.newBaseUrl+"RegisterGateway/userInfo/",
//     "Production-Team":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getTeamRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "getTeamRoleResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
//     "viewListProductionManagers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "viewListOfProductionTeams":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getProductManagerRole":window.gbrServiceUrl+"AuthBackEndGw/getRole/",
//     "getRoleProductionManagersResourceId":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
//     "viewListOfFarmers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getFarmerNodes":window.proxyUrl+"EAP/nodeSpecifications/",
//     "allApprovalRequests":window.proxyUrl+"FarmerProxy/approvalrequest/",
//     "allGrowRequests":window.proxyUrl+"FarmerProxy/growrequest/",
//     "allSellRequests":window.proxyUrl+"FarmerProxy/sellrequest/",
//     "allLoanRequests":window.proxyUrl+"FarmerProxy/loanrequest/",
//     "allVetVisitRequests":window.proxyUrl+"FarmerProxy/vetvisitrequest/",
//     "allBuyInputRequests":window.proxyUrl+"FarmerProxy/buyinputrequest/",
//     "allStoreRequests":window.proxyUrl+"FarmerProxy/storerequest/",
//     "allAdvisoryRequests":window.proxyUrl+"FarmerProxy/advisoryrequest/",
//     "setNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
//     "viewFarmerInfo":window.newBaseUrl+"RegisterGateway/consumerUserRequestInfo/",
//     "approvalReqStatus":window.proxyUrl+"FarmerProxy/updateApprovalRequest/",
//     "deleteContentSubCategory":window.proxyUrl+"ContentGw/deleteContentSubCategory/",
//     "growReqStatus":window.proxyUrl+"FarmerProxy/updateGrowRequest/",
//     "sellReqStatus":window.proxyUrl+"FarmerProxy/updateSellRequest/",
//     "LoanReqStatus":window.proxyUrl+"FarmerProxy/updateLoanRequest/",
//     "VetReqStatus":window.proxyUrl+"FarmerProxy/updateVetVisitRequest/",
//     "BuyReqStatus":window.proxyUrl+"FarmerProxy/updateBuyInputRequest/",
//     "StoreReqStatus":window.proxyUrl+"FarmerProxy/updateStoreRequest/",
//     "viewSettingsInfo":window.newBaseUrl_oem+"QMS-Gw/getSettingsInfo/",
//     "mappedLegacyAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
//     "getAllAssetsData":window.proxyUrl+"EAP/getSolutionAssets/",
//     "assetMapingtoSite":window.newBaseUrl_oem+"EAP/maplegacyassettosite/",
//     "siteDeleteHandler":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/deletesite/",
//     "siteRating":window.newBaseUrl_oem+"QMS-Gw/ratings/",
//     "createSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/sitecreation/",
//     "addSiteRating":window.newBaseUrl_oem+"QMS-Gw/ratings/",
//     "siteInfo":window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/siteInfo/",
//     "updateSiteInfo":window.proxyUrl+"EAP/updateSiteInfo/",
//     "viewAllFarms":window.proxyUrl+"EAP/getallsites/",
//     "viewAllFarmRelatedAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
//     "viewFarmMapRelatedAssets":window.newBaseUrl_oem+"EAP/siteAssets/",
//     "farmsDropDownList":window.proxyUrl+"EAP/getallsites/",
//     "getConsumerNodeInfo":window.proxyUrl+"EAP/nodeSpecifications/",
//     "updateConsumerNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
//     "viewNodeStatus":window.proxyUrl+"EAP/getNodeStatus/",
//     "createConsumerUserOfIndividual":window.gbrServiceUrl+"AuthBackEndGw/userCreation/",
//     "deleteConsumerUserIndividual":window.gbrServiceUrl+"AuthBackEndGw/userDeletion/",
//     "cancelGrowRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelSellRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelLoanRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelVetRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelBuyInputRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelStoreRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "cancelAdvisoryRequest":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "resetConsumerUserPasswordIndividual":window.newBaseUrl+"AuthBackEndGw/createCredential/",
//     "updateConsumerUserInfoIndividual":window.gbrServiceUrl+"AuthBackEndGw/updateConsumerUser/",
//     "ViewAssetOnboardings":window.newBaseUrl_oem+"EAP/getReadyForOnboardAssets/",
//     "updateReadyForOnboardAssetStatus":window.newBaseUrl_oem+"EAP/updateAssetStatus/",
//     "AttendanceLocationHistory":window.newBaseUrl_oem+"EAP/getAttendanceLocations/",
//     "NewAttendanceLocation":window.newBaseUrl_oem+"EAP/addAttendanceLocation/",
//     "DeleteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/deleteAttendanceLocation/",
//     "UpdateAttendanceLocation":window.newBaseUrl_oem+"EAP/updateAttendanceLocation/",
//     "getBirthDetails":window.newBaseUrl_oem+"EAP/getBirthInfos/",
//     "addBirthInfo":window.newBaseUrl_oem+"EAP/addBirthInfo/",
//     "deleteBirthInfo":window.newBaseUrl_oem+"EAP/deleteBirthInfo/",
//     "updateBirthInfo":window.newBaseUrl_oem+"EAP/updateBirthInfo/",
//     "getHealthRecords":window.newBaseUrl_oem+"EAP/getHealthRecord/",
//     "addHealthRecords":window.newBaseUrl_oem+"EAP/addHealthRecord/",
//     "deleteHealthRecord":window.newBaseUrl_oem+"EAP/deleteHealthRecord/",
//     "updateHealthRecord":window.newBaseUrl_oem+"EAP/updateHealthRecord/",
//     "getNutrition":window.newBaseUrl_oem+"EAP/getNutritions/",
//     "addNutrition":window.newBaseUrl_oem+"EAP/addNutrition/",
//     "deleteNutrition":window.newBaseUrl_oem+"EAP/deleteNutrition/",
//     "updateNutrition":window.newBaseUrl_oem+"EAP/updateNutritionInfo/",
//     "getWeighingDetails":window.newBaseUrl_oem+"EAP/getWeighingDetails/",
//     "addWeighingDetails":window.newBaseUrl_oem+"EAP/addWeighingDetails/",
//     "deleteWeighingDetails":window.newBaseUrl_oem+"EAP/deleteWeighingDetails/",
//     "updateWeighingDetails":window.newBaseUrl_oem+"EAP/updateWeighingDetails/",
//     "getParturitionDetails":window.newBaseUrl_oem+"EAP/getParturitionDetails/",
//     "addParturitionDetails":window.newBaseUrl_oem+"EAP/addParturitionDetails/",
//     "deleteParturitionDetails":window.newBaseUrl_oem+"EAP/deleteParturitionDetails/",
//     "updateParturitionDetails":window.newBaseUrl_oem+"EAP/updateParturitionInfo/",
//     "getMortalityDetails":window.newBaseUrl_oem+"EAP/getMortalityDetails",
//     "addMortalityDetails":window.newBaseUrl_oem+"EAP/addMortalityDetails",
//     "deleteMortalityDetails":window.newBaseUrl_oem+"EAP/deleteMortalityDetails",
//     "updateMortalityDetails":window.newBaseUrl_oem+"EAP/updateMortalityInfo",
//     "getAssetOptionalStatusDetails":window.newBaseUrl_oem+"EAP/getAssetOperationalStatus",
//     "deleteAssetOperationalStatusDetails":window.newBaseUrl_oem+"EAP/deleteAssetOperationalStatus",
//     "updateAssetOperationalStatusDetails":window.newBaseUrl_oem+"EAP/updateAssetOperationalStatusInfo",
//     "getLegacyAssetInfo":window.newBaseUrl_oem+"EAP/solutionAssetInfo/",
//     "getSellingCostDetails":window.proxyUrl+"EAP/getSellingCostDetails/",
//     "getSitePatrollingDetails":window.newBaseUrl_oem+"EAP/getPatrollingDetails/",
//     "deleteSitePatrolingDetails":window.newBaseUrl_oem+"EAP/deletePatrollingDetails/",
//     "updateSitePatrolingDetails":window.newBaseUrl_oem+"EAP/updatePatrollingInfo/",
//     "getAssetPatrollingDetails":window.newBaseUrl_oem+"EAP/getPatrollingDetails/",
//     "deleteAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/deletePatrollingDetails/",
//     "updateAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/updatePatrollingInfo/",
//     "siteFeedbackDetails":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "assetFeedbackDetails":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "siteIssueCreate":window.proxyUrl+"QMS-Gw/createIssue/",
//     "getSiteIssueData":window.proxyUrl+"QMS-Gw/issues/",
//     "dailyReportDetails":window.newBaseUrl_oem+"EAP/getDailyReport/",
//     "createDailyReport":window.newBaseUrl_oem+"EAP/dailyReport/",
//     "getGroupNutritionData":window.proxyUrl+"EAP/getNutritions/",
//     "addNutritionData":window.proxyUrl+"EAP/addNutrition/",
//     "deleteGroupNutrition":window.proxyUrl+"EAP/deleteNutrition/",
//     "updateGroupNutrition":window.proxyUrl+"EAP/updateNutritionInfo/",
//     "siteAttendanceLocationHistory":window.newBaseUrl_oem+"EAP/getAttendanceLocations/",
//     "siteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/addAttendanceLocation/",
//     "siteDeleteNewAttendanceLocation":window.newBaseUrl_oem+"EAP/deleteAttendanceLocation/",
//     "siteUpdateAttendanceLocation":window.newBaseUrl_oem+"EAP/deleteAttendanceLocation/",
//     "siteGrowRequests":window.proxyUrl+"FarmerProxy/growrequest/",
//     "addGrowRequest":window.proxyUrl+"FarmerProxy/growRequest/",
//     "siteGrowReqStatus":window.proxyUrl+"FarmerProxy/updateGrowRequest/",
//     "siteCancelGrowReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "siteSellRequests":window.proxyUrl+"FarmerProxy/sellrequest/",
//     "siteSellReqStatus":window.proxyUrl+"FarmerProxy/updateSellRequest/",
//     "siteCancelSellReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "siteLoanRequests":window.proxyUrl+"FarmerProxy/loanrequest/",
//     "siteLoanReqStatus":window.proxyUrl+"FarmerProxy/updateLoanRequest/",
//     "siteCancelLoanReqStatus":window.proxyUrl+"FarmerProxy/cancelRequest/",
//     "assetIssueCreate":window.proxyUrl+"QMS-Gw/createIssue/",
//     "getAssetIssueData":window.proxyUrl+"QMS-Gw/assetIssues/",
//     "getNodeInfoBasedOnNodeId":window.proxyUrl+"EAP/getNodeInfo/",
//     "getMyCategoryTender":window.proxyUrl+"ContentGw/contentlist/",
//     "getListOfCategorys":window.proxyUrl+"ContentGw/getCategories/",
//     "getCategoryRelatedSubCategories":window.proxyUrl+"ContentGw/getAllContentSubCategories/",

//     "getConsumerUserInfoForAD":window.newBaseUrl+"RegisterGateway/userInfo/",
//     "getSellerInfo":window.newBaseUrl+"RegisterGateway/userInfo/",
//     "getAllSites":window.proxyUrl+"EAP/allsites/",
//     "getCategory":window.proxyUrl+"ContentGw/getCategoryId/",
//     "getSubCategoriesForAD":window.proxyUrl+"ContentGw/getAllContentSubCategories/",
//     "getAllContentsList":window.proxyUrl+"ContentGw/contentList/",
//     "getCategoryTender":window.proxyUrl+"ContentGw/contentlist/",
//     "getTendersByDistricts":window.proxyUrl+"ContentGw/getSiteContent/",
//     "getTendersByOrganization":window.proxyUrl+"ContentGw/contentList/",
//     "getWhiteLabelAssetsForAD":window.proxyUrl+"EAP/getSolutionAssets/",
//     "createLegacyAsset":window.proxyUrl+"EAP/createAsset/",
//     "createContentSubCategory":window.proxyUrl+"ContentGw/contentsubcategory/",
//     "createAssetOEM":window.newBaseUrl+"RegisterGateway/registerUser/",
//     "createConsumerUser":window.gbrServiceUrl+"AuthBackEndGw/userCreation/",
//     "resetConsumerUserPassword":window.gbrServiceUrl+"AuthBackEndGw/createUserCredential/",
//     "updateConsumerUserInfo":window.newBaseUrl+"AuthBackEndGw/updateConsumerUserInfo/",
//     "deleteConsumerUser":window.gbrServiceUrl+"AuthBackEndGw/userDeletion/",
//     "getParentId":window.gbrServiceUrl+"AuthBackEndGw/getParentId/",
//     "getAllDistricts":window.proxyUrl+"EAP/allsites/",
//     "createContent":window.proxyUrl+"ContentGw/content/",
//     "uploadKYC":window.newBaseUrl_oem+"Contract-Upload-Gw/uploadDocument/",
//     "sendEmail":window.proxyUrl+"NotificationUA/sendEmail/",
//     "sendSMS":window.proxyUrl+"NotificationUA/sendSms/",
//     "updateWhiteLabelStatus":window.proxyUrl+"TradingGw/updatewhitelabelRequest/",
//     "updateNodeStatus":window.proxyUrl+"EAP/updateNodeStatus/",
//     "addNodeToFarmer":window.proxyUrl+"EAP/nodeSpecification/",
//     "addGrowerRequest":window.proxyUrl+"FarmerProxy/growRequest/",
//     "addSellRequest":window.proxyUrl+"FarmerProxy/sellRequest/",
//     "addLoanRequest":window.proxyUrl+"FarmerProxy/loanRequest/",
//     "addVetRequest":window.proxyUrl+"FarmerProxy/vetvisitRequest/",
//     "addBuyInputRequest":window.proxyUrl+"FarmerProxy/buyinputRequest/",
//     "addStoreRequest":window.proxyUrl+"FarmerProxy/storeRequest/",
//     "addAdvisoryRequest":window.proxyUrl+"FarmerProxy/advisoryRequest/",
//     "getAssetOEMSofConsumerAdmin":window.newBaseUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "customerSupport":window.newBaseUrl_oem+"QMS-Gw/settings/",
//     "getLogisticProviders":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getAssetDistributors":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getAssetSellers":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getAllAssetRequests":window.proxyUrl+"TradingGw/getbuyrequests/",
//     "getClinicAdmins":window.gbrServiceUrl+"AuthBackEndGw/getAllConsumerUsers/",
//     "getAllWhiteLabelRequests":window.newBaseUrl_oem+"TradingGw/getwhitelabelrequests/",
//     "getAssetOEMInfo":window.newBaseUrl+"RegisterGateway/consumerRequestInfo/",
//     "getLogisticProviderInfo":window.newBaseUrl+"RegisterGateway/userInfo/",
//     "getAssetHealthStatus":window.newBaseUrl_oem+"AnalyticsProxy/assetHealthStatus/",
//     "getProcessHealthStatus":window.newBaseUrl_oem+"AnalyticsProxy/processHealthStatus/",
//     "getComplaints":window.newBaseUrl_oem+"QMS-Gw/getComplaint/",
//     "getKYCDocuments":window.newBaseUrl_oem+"ContractGw/getKycDocument/",
//     "getAllUnsignedAgreementsForAD":window.newBaseUrl_oem+"ContractGw/getDocument/",
//     "getAllSignedAgreementsForAD":window.newBaseUrl_oem+"ContractGw/getDocument/",
//     "trackTheOrder":window.proxyUrl+"TradingGw/getbuyrequests/",
//     "getNodeInfo":window.proxyUrl+"EAP/nodeSpecifications/",
//     "getFarmerNodeInfo":window.newBaseUrl+"RegisterGateway/consumerUserRequestInfo/",
//     "viewNodesOfFarmer":window.proxyUrl+"EAP/getNodeSpecification/",
//     "getRoleProfileBasedOnRoleName":window.gbrServiceUrl+"AuthBackEndGw/getResources/",
//     "createCustomerCare":window.newBaseUrl_oem+"QMS-Gw/addSettingsInfo/",
//     "updateAssetRequestStatusForAd":window.tradingServer+"TradingServer/updateBuyRequest/",
//     "createRole":window.gbrServiceUrl+"AuthBackEndGw/roleCreation/",
//     "updateCustomerCare":window.newBaseUrl_oem+"QMS-Gw/updateSettingsInfo/",
//     "deleteCustomerCare":window.newBaseUrl_oem+"QMS-Gw/deleteSettingsInfo/",
//     "deleteRole":window.gbrServiceUrl+"AuthBackEndGw/deleteConsumerRole/",
//     "deleteTenderSite":window.proxyUrl+"OP-BackEnd-Gateway/opgateway/deletesite/",
//     "updateRole":window.gbrServiceUrl+"AuthBackEndGw/roleUpdate/",
//     "updateRoleOfUser":window.gbrServiceUrl+"AuthBackEndGw/updateMappedConsumerRole/",
//     "mapRole":window.gbrServiceUrl+"AuthBackEndGw/mapUserRole/",
//     "uploadAgreementForAD":window.newBaseUrl_oem+"Contract-Upload-Gw/updDocument/",
//     "uploadAgreement":window.proxyUrl+"Cloud-Storage-Gw/content/",
//     "getFarmUpdates":window.proxyUrl+"ContentGw/contentList/",
//     "getFarmUpdatesFromAll":window.proxyUrl+"ContentGw/contentsList/",
//     "getCategoriesBasedOnOEM":window.proxyUrl+"ContentGw/getAllCategories/",
//     "getSubCategoriesForOEM":window.proxyUrl+"ContentGw/getAllContentSubCategories/",
//     "getUserJourney":window.gbrServiceUrl+"RegisterGateway/userJourneyDetails/",
//     "addAssetOparationalStatusDetails":window.newBaseUrl_oem+"EAP/addOperationalStatus/",
//     "addSellingCostDetails":window.proxyUrl+"EAP/addSellingCost/",
//     "addSitePatrolingDetails":window.newBaseUrl_oem+"EAP/addPatrollingDetails/",
//     "addAssetPatrolingDetails":window.newBaseUrl_oem+"EAP/addPatrollingDetails/",

//     //"getWhiteLabelStatus": window.newBaseUrl_oem+"TradingGw/whitelabelstatus/", 
    
//     "getSolutionsIdList": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/solutions/",
//     "getAssetVerticalsIdList": window.newBaseUrl_oem+"EAP/assetVerticals/",
//     "getModelsList":window.newBaseUrl_oem+"EAP/getModel/",
//     "getCategoryTypesList": window.newBaseUrl_oem+"EAP/categoryTypes/",
//     "getAssetTypesList":window.newBaseUrl_oem+"EAP/assetTypes/",
//     // "getAssetTypes": window.newBaseUrl_oem+"EAP/assetTypes/", //done by srini
//     // "getSitesList": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",
//     "getSitesList": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/sites/",
//     "getSitesAssetsList": window.newBaseUrl_oem+"EAP/siteAssets/",
//     "getAssetInfo": window.newBaseUrl_oem+"EAP/solutionAssetInfo/",
//     "sendWhitelabelRequest": window.newBaseUrl_oem+"TradingGw/whitelabelRequest/",
//     "getSolutionAssets": window.newBaseUrl_oem+"EAP/getSolutionAssets/",
//     "getSolutionFeedbacks": window.newBaseUrl_oem+"QMS-Gw/feedbacks/",
//     "getSiteComplaints": window.newBaseUrl_oem+"QMS-Gw/complaints/",
//     "getDashboardData": window.newBaseUrl_oem+"AnalyticsProxy/getAssetOEMDashboardData/",
//     "getAssetsCount": window.newBaseUrl_oem+"AnalyticsProxy/getAssetsCount/",
//     //"getWhiteLabelAsserts": window.newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
//     "getAssetOEMData": window.newBaseUrl_oem+"AnalyticsProxy/getAssetOEMData/",
//     "getProfitSummary": window.newBaseUrl_oem+"AnalyticsProxy/getProfitSummary/",
//     "getStatementSummary": window.newBaseUrl_oem+"AnalyticsProxy/getStatementSummary/",
//     "getProfitStatementSummary": window.newBaseUrl_oem+"AnalyticsProxy/getProfitStatementSummary/",
//     "getAllSolutionsSite": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/solutionSites/",
//     "getAllAssociatedSolutionSites":window.newBaseUrl_oem+"EAP/associatedSites/",
//     "getWalletBalance": window.newBaseUrl_oem+"Wallet-Payment-Gw/walletBalance/",
//     "getAllSolutions": window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/enterprise/allSolutions/",
//     "getAllAssociatedSolutions":window.newBaseUrl_oem+"EAP/associatedAssetDevelopers/",
//     "getBankDetails": window.newBaseUrl_oem+"Wallet-Payment-Gw/bankDetails/",
//     // "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
//     "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
//     "addBankDetails": window.newBaseUrl_oem+"Wallet-Payment-Gw/bankdetails/",
    
//     "paymentHistory": window.newBaseUrl_oem+"Wallet-Payment-Gw/paymentHistory/",
//     // "getFarmUpdates": window.newBaseUrl_oem+"ContentGw/getapps/",
//     "registerConsumerUser": window.newBaseUrl+"RegisterGateway/registerConsumerUser/",
//     // Done by Srini
//     "myAssetsList": window.newBaseUrl_oem+"EAP/OEMAssets/",
//     "getSitesDetails":window.newBaseUrl_oem+"OP-BackEnd-Gateway/opgateway/getSiteDetails/",
//     "getAssetDetails":window.newBaseUrl_oem+"EAP/assetDetails/",
//     "getWhiteLabelAssets": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",//These are sites only
//     "getGrowerDetails":  window.newBaseUrl+"RegisterGateway/assetdeveloperDetails/",
//     "getModelDetails":window.newBaseUrl_oem+"AnalyticsProxy/getModelDetails/",
//     "whiteLabelAssetsBasedonSite":window.newBaseUrl_oem+"EAP/getReadyforWhitelabelAsset/",
//     "whiteLabelAssetsBasedonAssetDeveloper":window.newBaseUrl_oem+"EAP/getReadyforWhitelabelAssets/",
//     "whiteLabelAssetsBasedonModel":window.newBaseUrl_oem+"EAP/readyforWhitelabelAssets/",
//     "addToCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
//     "findCartItems":window.newBaseUrl_oem+"TradingGw/investmentCart/",
//     "deleteAssetFromCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
//     "deleteModelFromCart":window.newBaseUrl_oem+"TradingGw/investmentCart/",
//     "getApps": window.newBaseUrl_oem+"ContentGw/getapps/",
//     "assetDeveloperFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "siteFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "assetFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "modelFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
//     "schemeFeedback":window.newBaseUrl_oem+"QMS-Gw/recordFeedback/",
    
//     "uploadKyc":window.newBaseUrl_oem+"Contract-Upload-Gw/uploadDocument/",
//     "uploadAgreement":window.newBaseUrl_oem+"Contract-Upload-Gw/updDocument/",
//     "likeCount":window.newBaseUrl_oem+"ContentGw/updatelikecount/",
//     "deleteNominee": window.newBaseUrl+"OpUserServer/userserver/deleteNominee/",
//     "getNomineeList": window.newBaseUrl+"AuthBackEndGw/nomineeDetails/",
//     "updateNominee":window.newBaseUrl+"AuthBackEndGw/updateNomineeDetails/",
//     "addNominee": window.newBaseUrl+"AuthBackEndGw/addNominee/",
//     "updateUserInfo": window.newBaseUrl+"AuthBackEndGw/updateConsumerUser/",
//     "updateBankDetails":window.newBaseUrl_oem+"Wallet-Payment-Gw/updateBankdetails/",
//     "getUserImag":window.newBaseUrl+"AuthBackEndGw/getUserImage/",
//     "uploadUSerImage":window.newBaseUrl+"AuthBackEndGw/uploadImage/",
//     "getInvestedModels":window.newBaseUrl_oem+"EAP/investedmodels/",
//     "addMoneyToWallet":window.newBaseUrl_oem+"Wallet-Payment-Gw/moneyToWallet/",
//     "withdrawMoneyFromWallet":window.newBaseUrl_oem+"Wallet-Payment-Gw/withdrawMoneyFromWallet/",
//     "getUnsignedAgreements":window.newBaseUrl_oem+"ContractGw/getDocument/",
//     "getSignedAgreements": window.newBaseUrl_oem+"ContractGw/getDocument/",
//     "getOrderList":window.newBaseUrl_oem+"TradingGw/oemwhitelabelstatus/",
    
//     "preMatureSale":window.newBaseUrl_oem+"EAP/updateAssetStatus/",
//     "createPaymentLink":window.newBaseUrl_oem+"Wallet-Payment-Gw/payment_links/",
//     "makePayment":window.newBaseUrl_oem+"TradingGw/schemeWhitelabelRequest/",
//     "bookNowPayment":window.newBaseUrl_oem+"TradingGw/whitelabelRequest/",
//     "bankStatus":window.newBaseUrl_oem+"Wallet-Payment-Gw/getBankStatus/",
//    }


getGrandParent: window.gbrServiceUrl + 'AuthBackEndGw/getParent/',
settingsInfoBasedonParent: window.newBaseUrl_oem + 'QMS-Gw/settings/',
/* invoice genration code */
createQutation: `${window.invoiceBaseURL}gendocApi/v1/`,
createPurchaseOrder: `${window.invoiceBaseURL}gendocApi/v1/`,
createProformaInvoice: `${window.invoiceBaseURL}gendocApi/v1/`,
createTaxInvoice: `${window.invoiceBaseURL}gendocApi/v1/`,
/* Market apis */
DeleteProductItem: window.newBaseUrl_oem + 'AgriProductProxy/productItem/',
GetProductItems: window.newBaseUrl_oem + 'AgriProductProxy/productItems/',
ProductVideoURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateProductVideoUrl/',
UpdateLabCertificateURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateLabUrl/',
UpdateHistoryURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateHistoryUrl/',
DecrementItemCount:
  window.newBaseUrl_oem + 'AgriProductProxy/decrementItemCount/',
IncrementItemCount:
  window.newBaseUrl_oem + 'AgriProductProxy/incrementItemCount/',
UpdateItemCount: window.newBaseUrl_oem + 'AgriProductProxy/updateItemCount/',
UpdateProductRating:
  window.newBaseUrl_oem + 'AgriProductProxy/updateRatings/',
UpdateProductCost:
  window.newBaseUrl_oem + 'AgriProductProxy/updateProductCost/',
UpdateProductDiscount:
  window.newBaseUrl_oem + 'AgriProductProxy/updateDiscountPrice/',
UpdateProductStatus: window.newBaseUrl_oem + 'AgriProductProxy/updateStatus/',
UpdateProductQrURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateProductQRCode/',
UpdateProductCertificateURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateQAUrl/',
UpdateProductImageURL:
  window.newBaseUrl_oem + 'AgriProductProxy/updateProductImageUrl/',
UploadProductCertificate:
  window.newBaseUrl_oem + 'AgriProductProxy/uploadProductCertificate/',
UploadProductContent:
  window.newBaseUrl_oem + 'AgriProductProxy/uploadProductContent/',
/* End of market apis */

getSitesBasedOnEmail: window.newBaseUrl_oem + 'EAP/sitelist/',
/*Start of logistic-Manager:*/
getAssetsCountInfo: window.newBaseUrl_oem + 'EAP/getAssetsCount/',
getDeviceCountInfo:
  window.newBaseUrl_oem + 'DMBackEndGw/DMDevice/getDevicesCount/',
getDriversInfo: window.newBaseUrl + 'AuthBackEndGw/getUsersCount/',
getTripPlannerInfo: window.liveMonitorUrl + 'tripplanner/',
getLocationInfo: window.liveMonitorUrl + 'tripplanner/',
getRouteInfo: window.liveMonitorUrl + 'tripplanner/',
getTripInfo: window.liveMonitorUrl + 'tripplanner/',
addLocationData: window.liveMonitorUrl + 'tripplanner/',
getOrderListInfo: window.proxyUrl + 'BookingProxy/allBookings/consumer2/',
getVehicleList: window.proxyUrl + 'EAP/getSolutionAssets/',
getDrivers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getRoute: window.liveMonitorUrl + 'tripplanner/',
createBooking: window.proxyUrl + 'BookingProxy/createBooking/consumer2/',
addRouteApi: window.liveMonitorUrl + 'tripplanner/',
addTripApi: window.liveMonitorUrl + 'tripplanner/',
getVehicleLocation: window.liveMonitorUrl + 'livemonitoring/data/live/',
getVehicleTrip: window.liveMonitorUrl + 'tripplanner/',
geofenceDetails: window.liveMonitorUrl + 'geofence/',
createGeofence: window.liveMonitorUrl + 'geofence/',
delLocation: window.liveMonitorUrl + 'tripplanner/',
getMonthlyTrips: window.liveMonitorUrl + 'tripplanner/',
delRoute: window.liveMonitorUrl + 'tripplanner/',
delTrip: window.liveMonitorUrl + 'tripplanner/',
transportManagerlist:
  window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getAllUsers: window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getLogisticManagerCount:
  window.gbrServiceUrl + 'AuthBackEndGw/getUsersCount/',
getBookingReq: window.proxyUrl + 'BookingProxy/bookingRequest/',
mapWithTrip: window.proxyUrl + 'BookingProxy/mapBookingIdWithTrip/consumer2/',

postTripMapRequest:
  window.window.newBaseUrl_oem + 'BookingProxy/mapBookingIdWithTrip/',
postTripUnmapReques:
  window.window.newBaseUrl_oem + 'BookingProxy/unmapBookingIdFromTrip/',
postDriverMapRequest:
  window.window.newBaseUrl_oem +
  'BookingProxy/mapBookingIdWithTransportUser/',
postDriverUnmapRequest:
  window.window.newBaseUrl_oem +
  'BookingProxy/unmapBookingIdFromTransportUser/',
getBookingDetails:
  window.window.newBaseUrl_oem + 'BookingProxy/getBookingDetails/',
/*End of Logistic-Manager    */

/*start of transportuser*/

getTripDetailsDriver: window.liveMonitorUrl + 'tripplanner/consumer2/',
getMyBooking: window.proxyUrl + 'BookingProxy/assignedBookings/consumer2/',
getDriverSchedule: window.liveMonitorUrl + 'tripplanner/consumer2/',
getDriverOrder: window.newBaseUrl_oem + `BookingProxy/getOrders/consumer2/`,
getAllOrderCount:
  window.newBaseUrl_oem + `BookingProxy/getOrdersCount/consumer2/`,
getMyorderDetails:
  window.newBaseUrl_oem + `TradingGw/getBuyRequestStatus/consumer2/`,
PostMyOrderStatus:
  window.newBaseUrl_oem + `TradingGw/updateLegacyAssetOrderStatus/consumer2/`,
getMyRoute: window.liveMonitorUrl + `tripplanner/consumer2/`,
getUserType: window.newBaseUrl + `AuthBackEndGw/getUserType/`,
getWeeklyDriverSchedule: window.liveMonitorUrl + 'tripplanner/consumer2/',
/*end of transportuser*/
generateSiteQrCode:
  window.newBaseUrl_oem + 'QR-Code-Proxy/generateSite/consumer2/',
/* Start of product apis */
AgriProductProxey: window.newBaseUrl_oem + 'AgriProductProxy/productInfo/',
AgriProductProxeyItem:
  window.newBaseUrl_oem + 'AgriProductProxy/productItem/',
AgriProductProxeyDelete: window.newBaseUrl_oem + 'AgriProductProxy/product/',
GetAgriProductProxeyData:
  window.newBaseUrl_oem + 'AgriProductProxy/products/',
getAllAssetsBasedonModel: window.proxyUrl + 'EAP/getSolutionAssets/',
GetAgriProductProxeyDataBasedOnModel:
  window.newBaseUrl_oem + 'AgriProductProxy/allproducts/',
/* end of product apis */
siteVetVisitRequests: window.proxyUrl + 'FarmerProxy/vetvisitrequest/',
siteEnv: window.proxyUrl + 'IOB-Proxy/api/v1/consumer2/solution/',
operatorOption: window.proxyUrl + 'IOB-Proxy/api/v1/consumer2/solution/',
marketOwners: window.proxyUrl + 'EAP/getallsites/',
marketPriceRequests: window.proxyUrl + 'ContentGw/contentlist/',
AssetCertifiedConsultant:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
contentInfoBasedOnRequestId:
  window.proxyUrl + 'ContentGw/contentInformation/',
updateNode: window.newBaseUrl_oem + 'EAP/update/node/',
deleteNode: window.newBaseUrl_oem + 'EAP/delete/node/',
assetReports: window.newBaseUrl_oem + 'QMS-Gw/assetReports/',
getHealthStatusBasedOnAssetId:
  window.newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
getAttendanceLocationInfo:
  window.newBaseUrl_oem + 'EAP/getAttendanceLocationInfo/',
assetFeedbacks: window.newBaseUrl_oem + 'QMS-Gw/assetFeedbacks/',
getMortalityInfo: window.proxyUrl + 'EAP/getMortalityInfo/',
getSiteEnvInfo: window.liveEnvUrl + 'ems/livedata/',
getAssetEnvInfo: window.liveEnvUrl + 'ems/livedata/',
unmapExistingResourceFromRole:
  window.gbrServiceUrl + 'AuthBackEndGw/deleteResource/',
getSHG: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
communityUsersInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
assetCurrentLocation:
  window.assetCurrentLocationUrl + 'livemonitoring/data/live/',
getGlobalUsers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',

recordReport: window.newBaseUrl_oem + 'QMS-Gw/recordReport/',
recordCompliance: window.proxyUrl + 'QMS-Gw/recordCompliance/',
addAssetMortality: window.proxyUrl + 'EAP/addMortalityDetails/',
getAssetMortality: window.proxyUrl + 'EAP/getMortalityDetails/',
settingsInfo: window.newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',

getAllUserTypes: window.gbrServiceUrl + 'AuthBackEndGw/getConsumerUserTypes/',
// getConsumerUserTypeRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getConsumerUserTypeRoleProfile:
  window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getConsumerUserTypeRole:
  window.gbrServiceUrl + 'AuthBackEndGw/getRoleKeycloak/',
addAssetAlarm: window.newBaseUrl_oem + 'AdvisoryProxy/assetalarms/',
getAssetAlarms: window.newBaseUrl_oem + 'AdvisoryProxy/assetalarms/',
addSiteAlarm: window.newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
getSiteAlarms: window.newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
getQrCodeForAsset: window.newBaseUrl_oem + 'QR-Code-Proxy/assetqrcode/',
addAssetFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
dailyReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
addPatrol: window.proxyUrl + 'EAP/addPatrollingDetails/',
addAssetReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
addAssetHealth: window.proxyUrl + 'EAP/updateHealthRecord/',
createAssetIssue: window.newBaseUrl_oem + 'QMS-Gw/createIssue/',
getAssetIssues: window.newBaseUrl_oem + 'QMS-Gw/assetIssues/',
addAttendanceLocation: window.proxyUrl + 'EAP/addAttendanceLocation/',
getExecutiveDashboardData:
  window.newBaseUrl_oem + 'AnalyticsProxy/getDashboardData/',
getProcessHealthStatusExe:
  window.newBaseUrl_oem + 'AnalyticsProxy/getProcessHealthStatus/',
getAssetHealthStatusExe:
  window.newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetsHealthStatus/',
getAssetStatisticsExe:
  window.newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetStatistics/',

getSiteReports: window.newBaseUrl_oem + 'QMS-Gw/reports/',
createSiteIssue: window.newBaseUrl_oem + 'QMS-Gw/createIssue/',
siteIssue: window.proxyUrl + 'QMS-Gw/issues/',
getRoleResourceAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
getRoleResources: window.newBaseUrl + 'AuthBackEndGw/getResources/',
getRoleNameofOperator: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
updateOperatorProfileInfo:
  window.gbrServiceUrl + 'AuthBackEndGw/updateConsumerUser/',
GenerateProductQRCode:
  window.newBaseUrl_oem + 'QR-Code-Proxy/generateProduct/',
generateQrCode: window.qrcodeBaseUrl + 'generate',
generateQrCodeWithId: window.qrcodeBaseUrl + 'generateWithId',
generateBulkQrCodes: window.qrcodeBaseUrl + 'generateBulk',
downloadQrCode: window.qrcodeBaseUrl + 'download?file=',
createAccessToken: window.gbrServiceUrl + 'AuthBackEndGw/token/',
getToken: window.newBaseUrl + 'AuthBackEndGw/getUserToken/',
resetUserPassword: window.newBaseUrl + 'AuthBackEndGw/resetPassword/',
forgetUsername: window.newBaseUrl + 'AuthBackEndGw/forgetUsername/',
forgetPassword: window.newBaseUrl + 'AuthBackEndGw/forgetPassword/',
registerUser: window.newBaseUrl + 'RegisterGateway/registerUser/',
consumerRequestInfo:
  window.sendRequestUrl  + 'RegisterGateway/consumerRequestInfo/',
'getUserType:': window.newBaseUrl + 'AuthBackEndGw/getUserType/',
getConsumerUserType: window.newBaseUrl + 'AuthBackEndGw/getUserType/',
getStepperCounter:
  window.gbrServiceUrl + 'RegisterGateway/userJourneyDetails/',
getAllAssetsOfAssetDeveloper: window.proxyUrl + 'EAP/getSolutionAssets/',
getAllRoles: window.gbrServiceUrl + 'AuthBackEndGw/roles/',
getAllConsumerUsers: window.gbrServiceUrl + 'AuthBackEndGw/consumerUser/',
getMappedRoles: window.gbrServiceUrl + 'AuthBackEndGw/getUsersRoles/',
viewAllSites: window.proxyUrl + 'EAP/allsites/',
deleteContent: window.newBaseUrl_oem + 'ContentGw/content/',
createMapping: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
createUnMapping: window.newBaseUrl_oem + 'EAP/unmapSiteAsset/',
assetStatusUpdate: window.proxyUrl + 'EAP/updateAssetStatus/',
assetDelete: window.proxyUrl + 'EAP/deleteAsset/',
getAllSiteTypes: window.proxyUrl + 'EAP/getSiteTypes/',
getUnsignedAgreements: window.proxyUrl + 'ContentGw/contentList/',
viewAllTenderSites: window.proxyUrl + 'EAP/allsites/',
mappedTenderLegacyAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
getAllTenderAssetsData: window.proxyUrl + 'EAP/getSolutionAssets/',
assetMapingtoTenderSite: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
getAllTenderSiteTypes: window.proxyUrl + 'EAP/getSiteTypes/',
createTenderSite:
  window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/createsite/',
createSiteWithNodeId:
  window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreationinfo/',
viewListOfOperators:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
getAssetsOfSite: window.proxyUrl + 'EAP/siteAssets/',
viewListOfDeveloperQA:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getDevRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getDevRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
getDevAssetsOfSite: window.proxyUrl + 'EAP/siteAssets/',
viewListOfExecutives:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getConsumerInfoRequest: window.newBaseUrl + 'RegisterGateway/userInfo/',
'Production-Team':
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getTeamRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getTeamRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
viewListProductionManagers:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
viewListOfProductionTeams:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getProductManagerRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
getRoleProductionManagersResourceId:
  window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
viewListOfFarmers:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getFarmerNodes: window.proxyUrl + 'EAP/nodeSpecifications/',
allApprovalRequests: window.proxyUrl + 'FarmerProxy/approvalrequest/',
allGrowRequests: window.proxyUrl + 'FarmerProxy/growrequest/',
allSellRequests: window.proxyUrl + 'FarmerProxy/sellrequest/',
allLoanRequests: window.proxyUrl + 'FarmerProxy/loanrequest/',
allVetVisitRequests: window.proxyUrl + 'FarmerProxy/vetvisitrequest/',
allBuyInputRequests: window.proxyUrl + 'FarmerProxy/buyinputrequest/',
allStoreRequests: window.proxyUrl + 'FarmerProxy/storerequest/',
allAdvisoryRequests: window.proxyUrl + 'FarmerProxy/advisoryrequest/',
setNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
viewFarmerInfo:
  window.newBaseUrl + 'RegisterGateway/consumerUserRequestInfo/',
approvalReqStatus: window.proxyUrl + 'FarmerProxy/updateApprovalRequest/',
deleteContentSubCategory:
  window.proxyUrl + 'ContentGw/deleteContentSubCategory/',
growReqStatus: window.proxyUrl + 'FarmerProxy/updateGrowRequest/',
sellReqStatus: window.proxyUrl + 'FarmerProxy/updateSellRequest/',
LoanReqStatus: window.proxyUrl + 'FarmerProxy/updateLoanRequest/',
VetReqStatus: window.proxyUrl + 'FarmerProxy/updateVetVisitRequest/',
BuyReqStatus: window.proxyUrl + 'FarmerProxy/updateBuyInputRequest/',
StoreReqStatus: window.proxyUrl + 'FarmerProxy/updateStoreRequest/',
viewSettingsInfo: window.newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',
mappedLegacyAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
getAllAssetsData: window.proxyUrl + 'EAP/getSolutionAssets/',
assetMapingtoSite: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
siteDeleteHandler:
  window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
siteRating: window.newBaseUrl_oem + 'QMS-Gw/ratings/',
createSite: window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreation/',
addSiteRating: window.newBaseUrl_oem + 'QMS-Gw/ratings/',
siteInfo: window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/siteInfo/',
updateSiteInfo: window.proxyUrl + 'EAP/updateSiteInfo/',
viewAllFarms: window.proxyUrl + 'EAP/getallsites/',
viewAllFarmRelatedAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
viewFarmMapRelatedAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
farmsDropDownList: window.proxyUrl + 'EAP/getallsites/',
getConsumerNodeInfo: window.proxyUrl + 'EAP/nodeSpecifications/',
updateConsumerNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
viewNodeStatus: window.proxyUrl + 'EAP/getNodeStatus/',
createConsumerUserOfIndividual:
  window.gbrServiceUrl + 'AuthBackEndGw/userCreation/',
deleteConsumerUserIndividual:
  window.gbrServiceUrl + 'AuthBackEndGw/userDeletion/',
cancelGrowRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelSellRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelLoanRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelVetRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelBuyInputRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelStoreRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
cancelAdvisoryRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
resetConsumerUserPasswordIndividual:
  window.newBaseUrl + 'AuthBackEndGw/createCredential/',
updateConsumerUserInfoIndividual:
  window.gbrServiceUrl + 'AuthBackEndGw/updateConsumerUserInfo/',
ViewAssetOnboardings: window.newBaseUrl_oem + 'EAP/getReadyForOnboardAssets/',
updateReadyForOnboardAssetStatus:
  window.newBaseUrl_oem + 'EAP/updateAssetStatus/',
AttendanceLocationHistory:
  window.newBaseUrl_oem + 'EAP/getAttendanceLocations/',
NewAttendanceLocation: window.newBaseUrl_oem + 'EAP/addAttendanceLocation/',
DeleteNewAttendanceLocation:
  window.newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
UpdateAttendanceLocation:
  window.newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
getBirthDetails: window.newBaseUrl_oem + 'EAP/getBirthInfos/',
addBirthInfo: window.newBaseUrl_oem + 'EAP/addBirthInfo/',
deleteBirthInfo: window.newBaseUrl_oem + 'EAP/deleteBirthInfo/',
updateBirthInfo: window.newBaseUrl_oem + 'EAP/updateBirthInfo/',
getHealthRecords: window.newBaseUrl_oem + 'EAP/getHealthRecord/',
addHealthRecords: window.newBaseUrl_oem + 'EAP/addHealthRecord/',
deleteHealthRecord: window.newBaseUrl_oem + 'EAP/deleteHealthRecord/',
updateHealthRecord: window.newBaseUrl_oem + 'EAP/updateHealthRecord/',
getNutrition: window.newBaseUrl_oem + 'EAP/getNutritions/',
addNutrition: window.newBaseUrl_oem + 'EAP/addNutrition/',
deleteNutrition: window.newBaseUrl_oem + 'EAP/deleteNutrition/',
updateNutrition: window.newBaseUrl_oem + 'EAP/updateNutritionInfo/',
getWeighingDetails: window.newBaseUrl_oem + 'EAP/getWeighingDetails/',
addWeighingDetails: window.newBaseUrl_oem + 'EAP/addWeighingDetails/',
deleteWeighingDetails: window.newBaseUrl_oem + 'EAP/deleteWeighingDetails/',
updateWeighingDetails: window.newBaseUrl_oem + 'EAP/updateWeighingDetails/',
getParturitionDetails: window.newBaseUrl_oem + 'EAP/getParturitionDetails/',
addParturitionDetails: window.newBaseUrl_oem + 'EAP/addParturitionDetails/',
deleteParturitionDetails:
  window.newBaseUrl_oem + 'EAP/deleteParturitionDetails/',
updateParturitionDetails:
  window.newBaseUrl_oem + 'EAP/updateParturitionInfo/',
getMortalityDetails: window.newBaseUrl_oem + 'EAP/getMortalityDetails',
addMortalityDetails: window.newBaseUrl_oem + 'EAP/addMortalityDetails',
deleteMortalityDetails: window.newBaseUrl_oem + 'EAP/deleteMortalityDetails',
updateMortalityDetails: window.newBaseUrl_oem + 'EAP/updateMortalityInfo',
getAssetOptionalStatusDetails:
  window.newBaseUrl_oem + 'EAP/getAssetOperationalStatus',
deleteAssetOperationalStatusDetails:
  window.newBaseUrl_oem + 'EAP/deleteAssetOperationalStatus',
updateAssetOperationalStatusDetails:
  window.newBaseUrl_oem + 'EAP/updateAssetOperationalStatusInfo',
getLegacyAssetInfo: window.newBaseUrl_oem + 'EAP/solutionAssetInfo/',
getSellingCostDetails: window.proxyUrl + 'EAP/getSellingCostDetails/',
getSitePatrollingDetails: window.newBaseUrl_oem + 'EAP/getPatrollingDetails/',
deleteSitePatrolingDetails:
  window.newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
updateSitePatrolingDetails:
  window.newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
getAssetPatrollingDetails:
  window.newBaseUrl_oem + 'EAP/getPatrollingDetails/',
deleteAssetPatrolingDetails:
  window.newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
updateAssetPatrolingDetails:
  window.newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
siteFeedbackDetails: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
assetFeedbackDetails: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
siteIssueCreate: window.proxyUrl + 'QMS-Gw/createIssue/',
getSiteIssueData: window.proxyUrl + 'QMS-Gw/issues/',
dailyReportDetails: window.newBaseUrl_oem + 'EAP/dailyReportList/',
createDailyReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
getGroupNutritionData: window.proxyUrl + 'EAP/getNutritions/',
addNutritionData: window.proxyUrl + 'EAP/addNutrition/',
deleteGroupNutrition: window.proxyUrl + 'EAP/deleteNutrition/',
updateGroupNutrition: window.proxyUrl + 'EAP/updateNutritionInfo/',
siteAttendanceLocationHistory:
  window.newBaseUrl_oem + 'EAP/getAttendanceLocations/',
siteNewAttendanceLocation:
  window.newBaseUrl_oem + 'EAP/addAttendanceLocation/',
siteDeleteNewAttendanceLocation:
  window.newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
siteUpdateAttendanceLocation:
  window.newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
siteGrowRequests: window.proxyUrl + 'FarmerProxy/growrequest/',
addGrowRequest: window.proxyUrl + 'FarmerProxy/growRequest/',
siteGrowReqStatus: window.proxyUrl + 'FarmerProxy/updateGrowRequest/',
siteCancelGrowReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
siteSellRequests: window.proxyUrl + 'FarmerProxy/sellrequest/',
siteSellReqStatus: window.proxyUrl + 'FarmerProxy/updateSellRequest/',
siteCancelSellReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
siteLoanRequests: window.proxyUrl + 'FarmerProxy/loanrequest/',
siteLoanReqStatus: window.proxyUrl + 'FarmerProxy/updateLoanRequest/',
siteCancelLoanReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
assetIssueCreate: window.proxyUrl + 'QMS-Gw/createIssue/',
getAssetIssueData: window.proxyUrl + 'QMS-Gw/assetIssues/',
getNodeInfoBasedOnNodeId: window.proxyUrl + 'EAP/getNodeInfo/',
getMyCategoryTender: window.proxyUrl + 'ContentGw/contentlist/',
getListOfCategorys: window.proxyUrl + 'ContentGw/getCategories/',
getCategoryRelatedSubCategories:
  window.proxyUrl + 'ContentGw/getAllContentSubCategories/',

getConsumerUserInfoForAD: window.newBaseUrl + 'RegisterGateway/userInfo/',
getSellerInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
getAllSites: window.proxyUrl + 'EAP/allsites/',
getCategory: window.proxyUrl + 'ContentGw/getCategoryId/',
getSubCategoriesForAD:
  window.proxyUrl + 'ContentGw/getAllContentSubCategories/',
getAllContentsList: window.proxyUrl + 'ContentGw/contentList/',
getCategoryTender: window.proxyUrl + 'ContentGw/contentlist/',
getTendersByDistricts: window.proxyUrl + 'ContentGw/getSiteContent/',
getTendersByOrganization: window.proxyUrl + 'ContentGw/contentList/',
getWhiteLabelAssetsForAD: window.proxyUrl + 'EAP/getSolutionAssets/',
createLegacyAsset: window.proxyUrl + 'EAP/createAsset/',
createContentSubCategory: window.proxyUrl + 'ContentGw/contentsubcategory/',
createAssetOEM: window.newBaseUrl + 'RegisterGateway/registerUser/',
createConsumerUser: window.gbrServiceUrl + 'AuthBackEndGw/userCreation/',
resetConsumerUserPassword:
  window.gbrServiceUrl + 'AuthBackEndGw/createUserCredential/',
updateConsumerUserInfo:
  window.newBaseUrl + 'AuthBackEndGw/updateConsumerUserInfo/',
deleteConsumerUser: window.gbrServiceUrl + 'AuthBackEndGw/userDeletion/',
getParentId: window.gbrServiceUrl + 'AuthBackEndGw/getParentId/',
getAllDistricts: window.proxyUrl + 'EAP/allsites/',
createContent: window.proxyUrl + 'ContentGw/content/',
uploadKYC: window.newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
sendEmail: window.proxyUrl + 'NotificationUA/sendEmail/',
sendSMS: window.proxyUrl + 'NotificationUA/sendSms/',
updateWhiteLabelStatus:
  window.proxyUrl + 'TradingGw/updatewhitelabelRequest/',
updateNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
addNodeToFarmer: window.proxyUrl + 'EAP/nodeSpecification/',
addGrowerRequest: window.proxyUrl + 'FarmerProxy/growRequest/',
addSellRequest: window.proxyUrl + 'FarmerProxy/sellRequest/',
addLoanRequest: window.proxyUrl + 'FarmerProxy/loanRequest/',
addVetRequest: window.proxyUrl + 'FarmerProxy/vetvisitRequest/',
addBuyInputRequest: window.proxyUrl + 'FarmerProxy/buyinputRequest/',
addStoreRequest: window.proxyUrl + 'FarmerProxy/storeRequest/',
addAdvisoryRequest: window.proxyUrl + 'FarmerProxy/advisoryRequest/',
getAssetOEMSofConsumerAdmin:
  window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
customerSupport: window.newBaseUrl_oem + 'QMS-Gw/settings/',
getLogisticProviders:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getAssetDistributors:
  window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getAssetSellers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getAllAssetRequests: window.proxyUrl + 'TradingGw/getbuyrequests/',
getClinicAdmins: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
getAllWhiteLabelRequests:
  window.newBaseUrl_oem + 'TradingGw/getwhitelabelrequests/',
getAssetOEMInfo: window.newBaseUrl + 'RegisterGateway/consumerRequestInfo/',
getLogisticProviderInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
getAssetHealthStatus:
  window.newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
getProcessHealthStatus:
  window.newBaseUrl_oem + 'AnalyticsProxy/processHealthStatus/',
getComplaints: window.newBaseUrl_oem + 'QMS-Gw/getComplaint/',
getKYCDocuments: window.newBaseUrl_oem + 'ContractGw/getKycDocument/',
getAllUnsignedAgreementsForAD:
  window.newBaseUrl_oem + 'ContractGw/getDocument/',
getAllSignedAgreementsForAD:
  window.newBaseUrl_oem + 'ContractGw/getDocument/',
trackTheOrder: window.proxyUrl + 'TradingGw/getbuyrequests/',
getNodeInfo: window.proxyUrl + 'EAP/nodeSpecifications/',
getFarmerNodeInfo:
  window.newBaseUrl + 'RegisterGateway/consumerUserRequestInfo/',
viewNodesOfFarmer: window.proxyUrl + 'EAP/getNodeSpecification/',
getRoleProfileBasedOnRoleName:
  window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
createCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/addSettingsInfo/',
updateAssetRequestStatusForAd:
  window.proxyUrl + 'TradingGw/updateBuyRequest/',
createRole: window.gbrServiceUrl + 'AuthBackEndGw/roleCreation/',
updateCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/updateSettingsInfo/',
deleteCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/deleteSettingsInfo/',
deleteRole: window.gbrServiceUrl + 'AuthBackEndGw/deleteConsumerRole/',
deleteTenderSite:
  window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
updateRole: window.gbrServiceUrl + 'AuthBackEndGw/roleUpdate/',
updateRoleOfUser:
  window.gbrServiceUrl + 'AuthBackEndGw/updateMappedConsumerRole/',
mapRole: window.gbrServiceUrl + 'AuthBackEndGw/mapUserRole/',
uploadAgreementForAD:
  window.newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
uploadAgreement: window.proxyUrl + 'Cloud-Storage-Gw/content/',
getFarmUpdates: window.proxyUrl + 'ContentGw/contentList/',
getFarmUpdatesFromAll: window.proxyUrl + 'ContentGw/contentsList/',
getCategoriesBasedOnOEM: window.proxyUrl + 'ContentGw/getAllCategories/',
getSubCategoriesForOEM:
  window.proxyUrl + 'ContentGw/getAllContentSubCategories/',
getUserJourney: window.gbrServiceUrl + 'RegisterGateway/userJourneyDetails/',
addAssetOparationalStatusDetails:
  window.newBaseUrl_oem + 'EAP/addOperationalStatus/',
addSellingCostDetails: window.proxyUrl + 'EAP/addSellingCost/',
addSitePatrolingDetails: window.newBaseUrl_oem + 'EAP/addPatrollingDetails/',
addAssetPatrolingDetails: window.newBaseUrl_oem + 'EAP/addPatrollingDetails/',

//"getWhiteLabelStatus": window.newBaseUrl_oem+"TradingGw/whitelabelstatus/",

getSolutionsIdList:
  window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutions/',
getAssetVerticalsIdList: window.newBaseUrl_oem + 'EAP/getAssetVerticals/',
getModelsList: window.newBaseUrl_oem + 'EAP/models/',
getCategoryTypesList: window.newBaseUrl_oem + 'EAP/categoryType/',
getAssetTypesList: window.newBaseUrl_oem + 'EAP/assetType/',
// "getAssetTypes": window.newBaseUrl_oem+"EAP/assetTypes/", //done by srini
// "getSitesList": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",
getSitesList: window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/sites/',
getSitesAssetsList: window.newBaseUrl_oem + 'EAP/siteAssets/',
getAssetInfo: window.newBaseUrl_oem + 'EAP/solutionAssetInfo/',
sendWhitelabelRequest: window.newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
getSolutionAssets: window.newBaseUrl_oem + 'EAP/getSolutionAssets/',
getSolutionFeedbacks: window.newBaseUrl_oem + 'QMS-Gw/feedbacks/',
getSiteComplaints: window.newBaseUrl_oem + 'QMS-Gw/complaints/',
getDashboardData:
  window.newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMDashboardData/',
getAssetsCount: window.newBaseUrl_oem + 'AnalyticsProxy/getAssetsCount/',
//"getWhiteLabelAsserts": window.newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
getAssetOEMData: window.newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMData/',
getProfitSummary: window.newBaseUrl_oem + 'AnalyticsProxy/getProfitSummary/',
getStatementSummary:
  window.newBaseUrl_oem + 'AnalyticsProxy/getStatementSummary/',
getProfitStatementSummary:
  window.newBaseUrl_oem + 'AnalyticsProxy/getProfitStatementSummary/',
getAllSolutionsSite:
  window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutionSites/',
getAllAssociatedSolutionSites: window.newBaseUrl_oem + 'EAP/associatedSites/',
getWalletBalance: window.newBaseUrl_oem + 'Wallet-Payment-Gw/walletBalance/',
getAllSolutions:
  window.newBaseUrl_oem +
  'OP-BackEnd-Gateway/opgateway/enterprise/allSolutions/',
getAllAssociatedSolutions:
  window.newBaseUrl_oem + 'EAP/associatedAssetDevelopers/',
getBankDetails: window.newBaseUrl_oem + 'Wallet-Payment-Gw/bankDetails/',
// "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
getKycDocument: window.newBaseUrl_oem + 'ContractGw/getKycDocument/',
addBankDetails: window.newBaseUrl_oem + 'Wallet-Payment-Gw/bankdetails/',

paymentHistory: window.newBaseUrl_oem + 'Wallet-Payment-Gw/paymentHistory/',
// "getFarmUpdates": window.newBaseUrl_oem+"ContentGw/getapps/",
registerConsumerUser:
  window.newBaseUrl + 'RegisterGateway/registerConsumerUser/',
// Done by Srini
myAssetsList: window.newBaseUrl_oem + 'EAP/OEMAssets/',
getSitesDetails:
  window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/getSiteDetails/',
getAssetDetails: window.newBaseUrl_oem + 'EAP/assetDetails/',
getWhiteLabelAssets: window.newBaseUrl_oem + 'AnalyticsProxy/getsites/', //These are sites only
getGrowerDetails:
  window.newBaseUrl + 'RegisterGateway/assetdeveloperDetails/',
getModelDetails: window.newBaseUrl_oem + 'AnalyticsProxy/getModelDetails/',
whiteLabelAssetsBasedonSite:
  window.newBaseUrl_oem + 'EAP/getReadyforWhitelabelAsset/',
whiteLabelAssetsBasedonAssetDeveloper:
  window.newBaseUrl_oem + 'EAP/getReadyforWhitelabelAssets/',
whiteLabelAssetsBasedonModel:
  window.newBaseUrl_oem + 'EAP/readyforWhitelabelAssets/',
addToCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
findCartItems: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
deleteAssetFromCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
deleteModelFromCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
getApps: window.newBaseUrl_oem + 'ContentGw/getapps/',
assetDeveloperFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
siteFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
assetFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
modelFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
schemeFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',

uploadKyc: window.newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
uploadAgreement: window.newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
likeCount: window.newBaseUrl_oem + 'ContentGw/updatelikecount/',
deleteNominee: window.newBaseUrl + 'OpUserServer/userserver/deleteNominee/',
getNomineeList: window.newBaseUrl + 'AuthBackEndGw/nomineeDetails/',
updateNominee: window.newBaseUrl + 'AuthBackEndGw/updateNomineeDetails/',
addNominee: window.newBaseUrl + 'AuthBackEndGw/addNominee/',
updateUserInfo: window.newBaseUrl + 'AuthBackEndGw/updateConsumerUser/',
updateBankDetails:
  window.newBaseUrl_oem + 'Wallet-Payment-Gw/updateBankdetails/',
getUserImag: window.newBaseUrl + 'AuthBackEndGw/getUserImage/',
uploadUSerImage: window.newBaseUrl + 'AuthBackEndGw/uploadImage/',
getInvestedModels: window.newBaseUrl_oem + 'EAP/investedmodels/',
addMoneyToWallet: window.newBaseUrl_oem + 'Wallet-Payment-Gw/moneyToWallet/',
withdrawMoneyFromWallet:
  window.newBaseUrl_oem + 'Wallet-Payment-Gw/withdrawMoneyFromWallet/',
getUnsignedAgreements: window.newBaseUrl_oem + 'ContractGw/getDocument/',
getSignedAgreements: window.newBaseUrl_oem + 'ContractGw/getDocument/',
getOrderList: window.newBaseUrl_oem + 'TradingGw/oemwhitelabelstatus/',

preMatureSale: window.newBaseUrl_oem + 'EAP/updateAssetStatus/',
createPaymentLink: window.newBaseUrl_oem + 'Wallet-Payment-Gw/payment_links/',
makePayment: window.newBaseUrl_oem + 'TradingGw/schemeWhitelabelRequest/',
bookNowPayment: window.newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
bankStatus: window.newBaseUrl_oem + 'Wallet-Payment-Gw/getBankStatus/',
}

window.googleAnalytics = {
    accountId: 185833573,
    accountName: 'Unaas',
    propertyId: 256711934,
    propertyName : 'Test-Property'
}
