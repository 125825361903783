import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../../WebSite/HomeSubSection/HomeSubSection";
import "./CareServices.css";

export default function CareServices() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner bannerText="Care Service" className="care-service-bg" />
      <div className="main-wrapper2">
        <div className="subSectionWrapper">
          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="Advisory & Training"
            description="GBR Care's advisory service is tailored to meet the unique needs of farmers, offering expert guidance on animal, plant, and field health care. Alongside personalized recommendations and best practices, GBR Care provides comprehensive training sessions. Agricultural specialists equip farmers with the knowledge and skills needed to optimize the health and productivity of livestock, crops, fields, and beekeeping operations. Whether it's disease prevention strategies, crop management techniques, soil health improvement measures, or apiary management, GBR Care's advisory service empowers farmers to enhance agricultural sustainability, profitability, and resilience."
            // href4="https://web.gbrapp.com/agrocare-marketplace"
            // buttoncont1="Explore"
          />

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards"
            className3="fieldSupportService1"
            description="GBR Care excels in agricultural solutions, offering specialized Asset Relocation Support that encompasses the smooth transfer of colonies and livestock. Our experienced team ensures a seamless process, prioritizing the well-being of assets during relocation for the success of agricultural ventures."
            descriptionTitle="Asset Relocation Support"
          />
          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            className3="fieldSupportService2"
            descriptionTitle="Nutrients & supplements support"
            description="GBR Care is dedicated to supporting Farmers by providing essential supplements and nutrients. Our comprehensive range ensures optimal health for both livestock and plants, empowering Farmers to enhance productivity and cultivate thriving agricultural ecosystems. With GBR Care, Farmers have a reliable partner committed to the well-being and success of their agricultural endeavors."
            // href4="https://web.gbrapp.com/agrocare-marketplace"
            // buttoncont1="Explore"
          />
          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards"
            className3="fieldSupportService3"
            descriptionTitle="Buy Back Support"
            description="GBR Care offers a buy-back service where they provide Farmers with the necessary resources, such as seeds or materials, to cultivate specific crops. Once the crops are ready, GBR Care purchases the yield from the Farmers, ensuring a reliable market and income source for them. This mutually beneficial arrangement supports Farmers and promotes sustainable agricultural practices."
          />

          <HomeSubSection
            className3="veterinary-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="Veterinary"
            description="GBR Care offers a veterinary service tailored to the needs of farmers, providing on-site checkups and medical care for livestock. Veterinary professionals dispatched by GBR Care conduct thorough examinations and provide necessary treatments to ensure the health and well-being of animals. This service not only promotes animal welfare but also supports farmers in maintaining the productivity and profitability of their livestock operations. By offering accessible and reliable veterinary care, GBR Care contributes to the sustainable growth of the agricultural sector."
            // href4="https://web.gbrapp.com/agrocare-marketplace"
            // buttoncont1="Explore"
          />
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
