import React from "react";
import "react-awesome-slider/dist/styles.css";

import HomeBanner from "../HomeBanner/HomeBanner";
// import ChallengesSection from "./ChallengesSection/ChallengesSection";
// import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";
import MSMEServices from "../MSMEServices/MSMEServices";
import MSMEChallenges from "../MSMEChallenges/MSMEChallenges";
import MSMEBenefits from "../MSMEBenefits/MSMEBenefits";
import MSMEContribution from "../MSMEContribution/MSMEContribution";
import { Container } from "@material-ui/core";
import CTA from "../../CTA/CTA";
import HomeServices from "../HomeServices/HomeServices";
import MSMECoustomerBenefit from "../MSMECoustomerBenefit/MSMECoustomerBenefit";
// import OurServices from "../OurServices/OurServices";

export default function LandingPage() {
  return (
    <>
      <HeaderComponent />
      <HomeBanner />
      <div className="">
        <Container>
          <MSMEServices />
        </Container>{" "}
      </div>
      <div className="main-wrapper2">
        <MSMEChallenges />
      </div>
      <SolutionEcosystem
        type="MSME"
        bodycopy="DTN as Digital Transformation Network & Monetizing platform to  monetize the unutilized capacity to address Agri-MSME challenge."
      />
     
      {/* <Container>
        {" "}
        <HomeServices />
      </Container> */}
      <div className="main-wrapper2">
        <MSMEBenefits />
      </div>
     

      {/* <Container>
        <MSMEContribution />
      </Container> */}

      <div className=" subSectionWrapper">
        <Container>
          <CTA
            data="present"
            href="/auth/3/signup"
            heading=" Join the digital transformation now and unlock unprecedented growth and profitability!"
            btnText="Register Now"
          />
        </Container>
      </div>

      <FooterComponent />
    </>
  );
}
