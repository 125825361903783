import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./SuperRoleManagement.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function SuperRoleManagementComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consumerUsersList, setConsumerUsersList] = useState([]);

  const [allRolesList, setAllRolesList] = useState([]);
  const [parentid, setParentId] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createRoleDialog, setCreateRoleDialog] = useState(false);
  const [updateRoleDialog, setUpdateRoleDialog] = useState(false);
  const [roleExistingResourcesDialog, setRoleExistingResourcesDialog] =
    useState(false);
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
  const [allSitesList, setAllSitesList] = useState([]);
  const [writePermissionValue, setWritePermissionValue] = useState(false);
  const [readPermissionValue, setReadPermissionValue] = useState(false);
  const [executePermissionValue, setExecutePermissionValue] = useState(false);
  const [resourceNameValue, setResourceNameValue] = useState("");
  const [resourceIdValue, setResourceIdValue] = useState("");
  const [resourceTypeValue, setResourceTypeValue] = useState("");
  const [roleNameValue, setRoleNameValue] = useState("");
  const [roleDescriptionValue, setRoleDescriptionValue] = useState("");
  const [existingRoleData, setExistingRoleData] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [roleDeleteDialog, setRoleDeleteDialog] = useState(false);
  const [roleMapDialog, setRoleMapDialog] = useState(false);
  const [consumerUserNameValue, setConsumerUserNameValue] = useState("");
  const [allMappedRolesList, setAllMappedRolesList] = useState([]);
  const [mappedRolesView, setMappedRolesView] = useState(false);
  const [allRolesView, setAllRolesView] = useState(true);
  const [updateMappedRoleDialog, setUpdateMappedRoleDialog] = useState(false);
  const [roleMappedDeleteDialog, setRoleMappedDeleteDialog] = useState(false);
  const [filteredRolesList, setFilteredRolesList] = useState([]);
  const [filteredMappedRolesList, setFilteredMappedRolesList] = useState([]);
  const [filteredConsumerUsersList, setFilteredConsumerUsersList] = useState(
    []
  );
  const [value, setValue] = React.useState(0);
  const [searched, setSearched] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [roleResourcesList, setRoleResourcesList] = useState([]);

  const [roleClickForMap, setRoleClickForMap] = useState(false);
  const [consumerClickForMap, setConsumerClickForMap] = useState(false);
  const [existingRoleName, setExistingRoleName] = useState("");
  useEffect(() => {
    getAllConsumerUsers();
    getAllRolesList();
    getAllSitesForRoleNames();
    getAllMappedRoles();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All consumerUsers */
  const getAllConsumerUsers = () => {
    MainService.getAllConsumerUsers(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          setConsumerUsersList(res);
          setFilteredConsumerUsersList(res);
        } else {
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All consumerUsers */
  const getAllSitesForRoleNames = () => {
    MainService.viewAllSites(parentId, username)
      .then((res) => {
        setAllSitesList(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getAllMappedRoles = () => {
    MainService.getMappedRoles(parentId, username)
      .then((res) => {
        setAllMappedRolesList(res);
        setFilteredMappedRolesList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* view all roles  */
  const getAllRolesList = () => {
    setIsLoading(true);
    MainService.getSuperRoles(username)
      .then((res) => {
        console.log("roles res:", res);
        if (res.length > 0) {
          setIsLoading(false);
          setAllRolesList(res);
          setFilteredRolesList(res);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* end of view all roles requests */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* consumer IAM */

  const roleExistingResourcesClick = (rolename) => {
    setSelectedRoleName(rolename);
    MainService.getRoleResources(username, rolename)
      .then((res) => {
        console.log("resouces list:", res["role"]["resources"]);
        setRoleResourcesList(res["role"]["resources"]);
        setExistingRoleData(res["role"]["resources"]);
        setSelectedRoleName(res["role"]["rolename"]);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setRoleExistingResourcesDialog(!roleExistingResourcesDialog);
  };

  const roleDeleteDialogClick = (roleid) => {
    setSelectedRoleId(roleid);

    setRoleDeleteDialog(!roleDeleteDialog);
  };
  const roleDeleteSubmitClick = () => {
    if(selectedRoleId !== undefined)
    {
      MainService.deleteSuperRole(username, selectedRoleId)
      .then((res) => {
        getAllRolesList();
        return NotificationManager.success("role deleted successfully");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    }
   
    setRoleDeleteDialog(!roleDeleteDialog);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const createRoleSubmit = () => {
    let createRoleData = {
      name: roleNameValue,
      description: roleDescriptionValue,
    };
    const roleStringifyData = JSON.stringify(createRoleData);

    MainService.addSuperRole(roleStringifyData, username)
      .then((res) => {
        getAllRolesList();
        setResourceTypeValue("");
        setResourceNameValue("");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setCreateRoleDialog(!createRoleData);
  };

  const roleUpdateDialogClick = (role) => {
    setRoleNameValue(role.name);
    setRoleDescriptionValue(role.description);
    setSelectedRoleId(role.id);
    setUpdateRoleDialog(!updateRoleDialog);
  };
  
  const updateRoleSubmit = () => {
    let roleUpdateData = {
      name: roleNameValue,
      description: roleDescriptionValue,
    };
    const roleUpdateDataStringify = JSON.stringify(roleUpdateData);
    MainService.updateSuperRole(roleUpdateDataStringify, username, selectedRoleId)
      .then((res) => {
        getAllRolesList();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateRoleDialog(!updateRoleDialog);
  };
  const mapRoleClickForRoleManagement = () => {
    setRoleMapDialog(!roleMapDialog);
  };

  const roleMapSubmitClick = () => {
    let createRoleData = {
      name: roleNameValue,
      description: roleDescriptionValue,
    };
    const roleStringifyData = JSON.stringify(createRoleData);

    MainService.addSuperRole(roleStringifyData, consumerUserNameValue)
      .then((res) => {
        getAllRolesList();
        setResourceTypeValue("");
        setResourceNameValue("");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setRoleMapDialog(!roleMapDialog);
  };

  const viewAllRolesClick = () => {
    setAllRolesView(!allRolesView);
    setMappedRolesView(!mappedRolesView);
  };

  const roleMappedUpdateDialogClick = (consumerUserName, rolename) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedRoleName(rolename);
    setUpdateMappedRoleDialog(!updateMappedRoleDialog);
  };
  const updateMappedRoleSubmit = () => {
    MainService.updateRoleOfUser(
      username,
      selectedConsumerUserName,
      selectedRoleName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateMappedRoleDialog(!updateMappedRoleDialog);
    getAllMappedRoles();
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredRolesList.filter((row) => {
      return row.rolename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllRolesList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const requestSearchForMAppedRoles = (searchedVal) => {
    const filteredRows = filteredMappedRolesList.filter((row) => {
      return row.role.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllMappedRolesList(filteredRows);
  };
  const cancelSearchForMappedRoles = () => {
    setSearched("");
    requestSearchForMAppedRoles(searched);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Create Role"
            className={classes.fab}
            
            onClick={() => {
              setRoleMapDialog(!roleMapDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <Grid>
              <div style={{ float: "right" }}>
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={listViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={gridViewClick}
                ></Button>
              </div>
            </Grid>
            {/* {allRolesView ? (
              <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    mapRoleClickForRoleManagement();
                  }}
                >
                  Map Role
                </Button>
              </div>
            ) : (
              <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    viewAllRolesClick();
                  }}
                >
                  View All Roles
                </Button>
              </div>
            )} */}
          </div>
          {listView && (
            <div>
              {allRolesView ? (
                <Paper sx={{ width: "100%" }}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell
                            className="headerFontSize"
                            align="center"
                            colSpan={11}
                          >
                            Roles List
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Role Name
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Role Id
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Role Description
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Cpntainer Id
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Client Role
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={1}
                          >
                            Composite
                          </TableCell>
                          <TableCell
                            className="subHeaderFontSize"
                            align="center"
                            colSpan={2}
                          >
                            Operate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allRolesList.length > 0 ? (
                          allRolesList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((roles, index) => {
                              return (
                                <TableRow hover tabIndex={-1} key={index}>
                                  <TableCell align="center">
                                    {roles.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {roles.id}
                                  </TableCell>
                                  <TableCell align="center">
                                    {roles.description}
                                  </TableCell>
                                  <TableCell align="center">
                                    {roles.containerId}
                                  </TableCell>
                                  <TableCell align="center">
                                    {roles.clientRole}
                                  </TableCell>
                                  <TableCell align="center">
                                    {roles.composite}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        roleUpdateDialogClick(roles);
                                      }}
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        roleDeleteDialogClick(roles.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow align="center">
                            <TableCell align="center" colSpan={12}>
                              Roles Not Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={allRolesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <></>
              )}
            </div>
          )}
          {gridView && (
            <div>
              {allRolesView ? (
                <div>
                  <h2>Roles List</h2>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <div className="cardStyleForDashboardItems">
                    {allRolesList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {allRolesList.map((roles, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <p>
                              <b>Role Name:</b>
                              {roles.name}
                            </p>
                            <p>
                              <b>Role Id:</b>
                              {roles.id}
                            </p>
                            <p>
                              <b>Role Description:</b>
                              {roles.description}
                            </p>
                            <p>
                              <b>Container Id:</b>
                              {roles.containerId}
                            </p>
                            <p>
                              <b>Client Role:</b>
                              {roles.clientRole}
                            </p>
                            <p>
                              <b>Composite:</b>
                              {roles.composite}
                            </p>
                            <div>
                              <h4 className="cardactiontitle">Operate</h4>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  roleUpdateDialogClick(roles);
                                }}
                              >
                                Update
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  roleDeleteDialogClick(roles.id);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>Roles not found.</h4>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </Box>

        {/* create New Role */}
        <Dialog
          fullScreen={fullScreen}
          open={createRoleDialog}
          onClose={handleClose}
          aria-labelledby="craete New Role "
        >
          <DialogTitle id="createNewRole">{"Create New Role"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">AssetDeveloper Id :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Name :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Description :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleDescriptionValue}
                onChange={(e) => {
                  setRoleDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setResourceTypeValue("");
                setResourceNameValue("");
                setCreateRoleDialog(!createRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
       
        {/* update Role */}
        <Dialog
          fullScreen={fullScreen}
          open={updateRoleDialog}
          onClose={handleClose}
          aria-labelledby="Update Role"
        >
          <DialogTitle id="updateRole">{"Update Role"}</DialogTitle>
          <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Name :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Description :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleDescriptionValue}
                onChange={(e) => {
                  setRoleDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateRoleDialog(!updateRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Role */}
        <Dialog
          fullScreen={fullScreen}
          open={roleDeleteDialog}
          onClose={handleClose}
          aria-labelledby="deleteRole"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Role"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleDeleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleDeleteDialog(!roleDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Create and Map Role */}
        <Dialog
          fullScreen={fullScreen}
          open={roleMapDialog}
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="mapRole"
        >
          <DialogTitle id="mapRole">{"Create and Map Role"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="consumerUserName">
                Consumer User Name :
              </InputLabel>

              <Select
                id="consumerUserName"
                name="consumerUserName"
                type="text"
                value={consumerUserNameValue}
                onChange={(e) => {
                  setConsumerUserNameValue(e.target.value);
                }}
              >
                {consumerUsersList.length > 0 &&
                  consumerUsersList.map((consumerUsers, index) => (
                    <MenuItem key={`${index} `} value={consumerUsers.username}>
                      {consumerUsers.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role  Name :</InputLabel>
              <Select
                id="roleName"
                name="roleName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              >
                {/* {allRolesList.length > 0 &&
                  allRolesList.map((roles, index) => (
                    <MenuItem key={`${index} `} value={roles.name}>
                      {roles.name}
                    </MenuItem>
                  ))} */}
                  <MenuItem value="NULL">NULL</MenuItem>
                  <MenuItem value="BASIC">BASIC</MenuItem>
                  <MenuItem value="STANDARD">STANDARD</MenuItem>
                  <MenuItem value="PREFERRED">PREFFERED</MenuItem>
                  <MenuItem value="PREMIUM">PREMIUM</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Description :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleDescriptionValue}
                onChange={(e) => {
                  setRoleDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleMapSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleMapDialog(!roleMapDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* update Mapped Role   */}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateMappedRoleDialog}
          onClose={handleClose}
          aria-labelledby="Update Mapped Role "
        >
          <DialogTitle id="updateMappedRole">
            {"Update Mapped Role "}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingResourceName">
                Consumer User Name :
              </InputLabel>
              <Input
                disabled
                id="existingResourceName"
                name="existingResourceName"
                type="text"
                value={selectedConsumerUserName}
              ></Input>
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingResourceName">
                Role Name :
              </InputLabel>
              <Select
                id="existingResourceName"
                name="existingResourceName"
                type="text"
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              >
                <MenuItem selected>
                  {"Current Role --> "}
                  {selectedRoleName}
                </MenuItem>
                {allRolesList.length > 0 &&
                  allRolesList.map((sites, index) => (
                    <MenuItem key={`${index} `} value={sites.id}>
                      {sites.rolename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateMappedRoleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateMappedRoleDialog(!updateMappedRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
