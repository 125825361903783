import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import testData from '../../../test.json';
import GenrateNewReport from '../Common/genrateReport/GenrateNewReport';

const Report = () => {
  const [showReportDataList, setShowReportDataList] = useState(false);
  const [showNewReport, setShowNewReport] = useState(true);
  const [columnData, setColumnData] = useState([]);
  const [reportDataList, setReportDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    setIsLoading(true);
    const fetchRowData = async () => {
      try {
        const tempRowData = testData.map((obj, index) => ({
          id: index + 1,
          ...obj,
        }));
        setReportDataList(tempRowData);
      } catch (error) {
        console.error('Error fetching row data:', error);
      } finally {
        if (mountedRef.current) {
          setIsLoading(false);
        }
      }
    };

    setColumnData(columns);
    fetchRowData();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Sl No.', width: 70 },
      {
        field: 'reportid',
        headerName: 'Report Id',
        flex: 1,
      },
      { field: 'date', headerName: 'Date', flex: 1 },
      {
        field: 'button',
        headerName: 'Action',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <Button
              variant='outlined'
              color='primary'
              onClick={() => rowDataHandler(params)}
            >
              View
            </Button>
          );
        },
      },
    ],
    []
  );

  const rowDataHandler = ({ row }) => {
    console.log('props', row);
  };

  // genrate new report
  const genrateNewReportHandler = () => {
    setShowReportDataList(false);
    setShowNewReport(true);
  };

  return (
    <>
      {showReportDataList && (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.6rem' }}>Report List</Typography>
            <Button variant='outlined' onClick={genrateNewReportHandler}>
              Create New Report
            </Button>
          </Box>

          <Box sx={{ width: '100%' }}>
            {!isLoading && (
              <DataGrid
                rows={reportDataList}
                columns={columnData}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                isRowSelectable={() => false}
              />
            )}
          </Box>
        </Box>
      )}

      {showNewReport && (
        <Box>
          <GenrateNewReport />
        </Box>
      )}
    </>
  );
};

export default Report;
