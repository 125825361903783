import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

import CTA from "../CTA/CTA";
import { Container } from "@material-ui/core";
import TitleDescription from "../TitleDescription/TitleDescription";

import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import './ForAgriTransporter.css';
import ForAgriTransporterBenefits from "./ForAgriTransporterBenefits";
import ForAgriTransporterServices from "./ForAgriTransporterServices";
import ForAgriTransporterContribution from "./ForAgriTransporterContribution";
import ForAgriTransporterJourney from "./ForAgriTransporterJourney";
import ForAgriTransporterChallenges from "./ForAgriTransporterChallenges";
export default function ForAgriTransporter() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agri-transporter text-white"
        bannerText={[
          "Want to become Agri-Transporter to  ",
          <br className="d-md-block d-none"></br>,
          "monetize transportation",
        ]}
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Driver");
        }}
        roleType="NULL"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />
      <ForAgriTransporterChallenges/>
      <SolutionEcosystem
        type="AT"
        bodycopy=" System and Process to convert Progressive Agri-Transporter into Transport Entrepreneurs to
          address their needs."
      />
      <div>
        <div className="main-wrapper2 subSectionWrapper mb-0">
          <Container>
            <TitleDescription
              titleClass="text-start"
              classname2="text-start"
              classname="mb-0 mt-0 "
              title="Who are an Agri-Transporters"
              description="
              Agri-transporters are individuals or companies involved in the transportation of agricultural products, facilitating the movement of crops, livestock, and other agricultural goods from farms to processing facilities, markets, or distribution centers. They form a critical component of the agricultural supply chain, employing various transportation modes such as trucks, 3 wheelers and 4 wheelers to ensure the efficient and timely delivery of products. The role of agri-transporters is vital in supporting the overall agricultural industry by contributing to the seamless movement of goods across different stages of the local supply chain."
            />

            {/* <AgriBusinessCard /> */}
          </Container>
        </div>
        <Container>
          <ForAgriTransporterServices />
        </Container>
        <div className="main-wrapper2">
          <ForAgriTransporterBenefits />
        </div>
        <div className="subSectionWrapper">
          <Container>
            <ForAgriTransporterJourney />
          </Container>
        </div>
        <div className="main-wrapper2">
          <Container>
            <ForAgriTransporterContribution />
          </Container>
        </div>

     
      </div>
<Container>
      <div className="subSectionWrapper">
            <CTA
              onClick={() => {
                localStorage.setItem("userType", "Driver");
              }}
              href="/auth/3/signup"
              heading="Monetize your transportation capacity with GBR Farming for increased earnings and agricultural opportunities!"
              btnText="Register Now"
            />
          </div>
          </Container>
      <FooterComponent />
    </>
  );
}
