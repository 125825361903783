import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./OnMarketplaceSell.css";


export default function OnMarketplaceSell(){

    const navData = [{to:"/onMarketplace", link:"Buy"},
    {to:"/onMarketplaceSell", link:"Sell"},
    {to:"/onMarketplaceRFQ", link:"RFQ"}
  ]
    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling Organic products with GBR Farming"
         buttonText4="Sign up"
         buttonText6 = "Sell on ONDC"
         href6 = "/ComingSoon"
         href3="http://abn.gbrfarming.com/auth/3/signup"
        />
        <FooterComponent/>
        </>
    )
}