import React, { useEffect } from 'react';
import { useState } from 'react';
import OrderDetails from '../TripOrders/TripOrders';
import TripDetail from '../TripDetails/TripDetail';
import { Box, Button, Divider, Typography } from '@mui/material';
import MainService from '../../../../Common/MainService';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid black',
    padding: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    textAlign: 'center',
    border: 'none',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3F51B5',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  button: {
    backgroundColor: 'grey',
    color: '#fff',
    borderRadius: '5px',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#3ECF8E',
    },
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: 400,
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',

    position: 'absolute',
    left: '40%',
    top: '10%',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

function formatDateforApiCall(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
}

const UpcomingTrips = ({ startDate, endDate, store, handleBack }) => {
  const classes = useStyles();
  const [orderData, setOrderData] = useState([]);
  const [cardData, setCardData] = useState('');
  const [showPage, setShowPage] = useState(false);
  const [start_date, setSdate] = useState(startDate);
  const [end_date, setEdate] = useState(endDate);
  const [dash, setDash] = useState(false);
  const [trip, setTrip] = useState('');
  const [calen, setCalen] = useState(false);
  const [getToDash, setGetToDash] = useState(false);
  const [data, setData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const [tripDeatail, setTripDetail] = useState(false);
  const [orderDetials, setOrderDetails] = useState(false);
  const [open, setOpen] = useState(false);

  const getDriverSchedule = async () => {
    const urls = `fromDate=${formatDateforApiCall(
      start_date
    )}&toDate=${formatDateforApiCall(end_date)}&start=0&limit=80`;
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const data = await MainService.getDriverSchedule(username, urls);

    //  setApicall(data)
    setOrderData(data.tripList);
  };

  useEffect(() => {
    setSdate(startDate);
    setEdate(endDate);
  }, []);

  useEffect(() => {
    getDriverSchedule();
  }, []);

  const getTripDetailsDriver = async (trip) => {
    const data = await MainService.getTripDetailsDriver(trip);
    setData(data);
  };
  const handleClick = (props) => {
    console.log(props,'propssssssssssssssss..............')
    setCalen(true);
    setCardData(props);
    setShowPage(false);
    setTripDetail(false);
    setDash(true);
    setOrderDetails(true);
    setGetToDash(true);
    getTripDetailsDriver(props);
  };
  const backHandler = () => {
    setShowPage(true);
    setOrderDetails(false);
    setTripDetail(false);
    setCalen(false);
    setGetToDash(false);
  };
  const originalDateString = `${startDate}`;
  const originalDate = new Date(
    originalDateString.split('-').reverse().join('-')
  );

  const tripDeatailHandler = (props) => {
    setCalen(true);
    setTrip(props);
    setDash(true);
    setTripDetail(true);
    setShowPage(true);
    setOpen(true);
    getTripDetailsDriver(props);
    setCalen(false);
  };
  const handleDateForm = (e) => {
    setDash(true);
    setShowPage(true);
    e.preventDefault();
    getDriverSchedule();
  };
  const handlerBackAgain = () => {
    setCalen(!calen);
    setDash(true);
    handleBack();
  };

  const closeModelHandler = () => {
    setOpen(false);
    setCalen(false);
  };

  return (
    <div>
      {!getToDash && (
        <Button onClick={() => handlerBackAgain()}>
          <ArrowBackOutlinedIcon />
        </Button>
      )}

      <Box
        sx={{
          width: '70vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // minHeight: "100vh",
        }}
      >
        {!calen && (
          <form
            onSubmit={handleDateForm}
            style={{
              boxShadow:
                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
              background: 'aliceblue',
              borderRadius: '8px',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <strong
              style={{
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                borderRadius: '5px',
                margin: '2rem',
                border: '1px solid',

                height: '2rem',
                padding: '0.5rem',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                width: '11rem',
              }}
            >
              CHOOSE A DATE
            </strong>

            <div>
              <strong>
                <label htmlFor=''>Start Date:</label>
              </strong>
              <input
                style={{
                  height: '2rem',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem',
                }}
                type='date'
                value={start_date}
                onChange={(e) => setSdate(e.target.value)}
              />
            </div>

            <div>
              <strong>
                <label htmlFor=''>End Date:</label>
              </strong>
              <input
                style={{
                  height: '2rem',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem',
                }}
                type='date'
                value={end_date}
                onChange={(e) => setEdate(e.target.value)}
              />
            </div>

            <Button type='submit' variant='contained' sx={{ margin: '1rem' }}>
              SUBMIT
            </Button>
          </form>
        )}
      </Box>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '20px',
          gap: '0.5rem',
        }}
      >
        {!dash &&
          store.tripList !== undefined &&
          store.tripList.map((ele) => (
            <div
              key={ele.tripId}
              style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease',
                transform: 'scale(1)',
                display: 'flex',
                flex: 1,
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '20px',
                maxWidth: '320px',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              <h1
                style={{
                  margin: 0,
                  fontSize: '24px',
                  color: 'black',
                  textTransform: 'capitalize',
                }}
              >
                {ele.tripId}
              </h1>
              <hr
                style={{
                  width: '80%',
                  margin: '20px 0',
                  borderColor: 'grey',
                }}
              />
              <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <Button
                  variant='outlined'
                  onClick={() => tripDeatailHandler(ele.tripId)}
                >
                  TRIP DETAILS
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => handleClick(ele.tripId)}
                >
                  TRIP ORDERS
                </Button>
              </div>
            </div>
          ))}
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        {showPage &&
          orderData.length > 0 &&
          orderData.map((ele) => (
            <div
              key={ele.tripId}
              style={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease',
                transform: 'scale(1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                flex: '0 0 30.33%',
                position: 'relative',
              }}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              <div style={{ marginBottom: '20px' }}>
                <h1
                  style={{
                    margin: 0,
                    fontSize: '24px',
                    color: '#3ECF8E',
                    textTransform: 'capitalize',
                    textAlign: 'center',
                  }}
                >
                  {ele.tripId}
                </h1>
                <hr style={{ borderColor: '#DDE1E5' }} />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor:
                      ele.status === 'COMPLETED'
                        ? '#3ECF8E'
                        : ele.status === 'INPROGRESS'
                        ? '#f7b500'
                        : '#f44336',
                    marginRight: '5px',
                  }}
                />
                <strong> {ele.status.toUpperCase()}</strong>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  sx={{ marginBottom: '1rem' }}
                  variant='outlined'
                  onClick={() => tripDeatailHandler(ele.tripId)}
                >
                  TRIP DETAILS
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => handleClick(ele.tripId)}
                >
                  TRIP ORDERS
                </Button>
              </div>
            </div>
          ))}

        <Modal
          open={open}
          onClose={() => closeModelHandler()}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box className={classes.modalContent}>
            <div className={classes.closeButton}>
              <Button onClick={() => closeModelHandler()}>
                <CloseOutlinedIcon />
              </Button>
            </div>

            <TripDetail data={data} />
          </Box>
        </Modal>

        {orderDetials && (
          <OrderDetails
            routeData={data}
            backHandler={backHandler}
            cardData={cardData}
          />
        )}
      </div>
    </div>
  );
};

export default UpcomingTrips;
