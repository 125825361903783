import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 300;

const MainDrawer = ({
  window,
  getMenuListHandler,
  mainDashboardItemHandler,
  reLoadHandler,
  toggleChangePasswordHandler,
  logoutHandler,
  handleDrawerToggle,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawerHandler = () => {
    setIsDrawerOpen(!handleDrawerToggle());
  };

  const drawer = (
    <div>
      <Toolbar
        sx={{
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          fontSize: '20px',
          letterSpacing: '1px',
          textTransform: 'uppercase',
        }}
      >
        GBR
      </Toolbar>
      {/* {console.log(menuList)} */}
      <Divider />
      {getMenuListHandler()}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background:'#3ECF8E'
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              // onClick={handleDrawerToggle}
              onClick={toggleDrawerHandler}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              GBR
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            {' '}
            <IconButton color='inherit' title='Refresh' onClick={reLoadHandler}>
              <CachedIcon style={{ color: '#FFF' }} />
            </IconButton>
            <IconButton
              color='inherit'
              title='Change Password'
              onClick={toggleChangePasswordHandler}
            >
              <VpnKeyIcon style={{ color: '#FFF' }} />
            </IconButton>
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-haspopup='true'
              color='inherit'
              onClick={logoutHandler}
            >
              <ExitToAppIcon style={{ color: '#FFF' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          container={container}
          variant='temporary'
          open={isDrawerOpen}
          // onClose={handleDrawerToggle}
          onClick={toggleDrawerHandler}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        position='fixed'
        sx={{
          width: {
            xs: '100%',
            sm: `calc(100% - ${drawerWidth}px)`,
          },
          ml: { sm: `${drawerWidth}px` },
          p: { sm: 2 },
          height: '100%',
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
        }}
      >
        {mainDashboardItemHandler()}
      </Box>
    </Box>
  );
};

MainDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainDrawer;
