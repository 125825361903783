import React, { useEffect } from "react";
import { useState } from "react";
// import "./orderStatus.css";
import MainService from "../../../Common/MainService";
import { Snackbar, IconButton, Button, Modal } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { Box } from "@mui/material";
// import TripDetail from "./TripDetails/TripDetail";
// import ConsciousnessChecker from "./ConsiousnessChecker/ConsciousnessChecker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const Test = ({
  detail,
  data,
  backPrevious,
  orderStatusHandler,
  closeModelHandle,
}) => {
  const [open, setOpen] = useState(false);
  const [game, setGame] = useState(false);
  const [oopen, setOopen] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const backOrderStatus = () => {
    setThirdShow(true);
    backPrevious();
  };

  const PostMyOrderStatus = async () => {
    const data = { status: selectedOption };

    const datta = await MainService.PostMyOrderStatus(detail, data);
  };

  const [thirdPage, setThirdPage] = useState([]);

  const [thirdShow, setThirdShow] = useState(false);

  const getMyorderDetails = async () => {
    const data = await MainService.getMyorderDetails(detail);

    setThirdPage(data.buyrequeststatus);
  };

  const handleShow = () => {
    setThirdShow(!thirdShow);
  };

  useEffect(() => {
    getMyorderDetails();
  }, []);

  const handleOpen = async () => {
    setOpen(true);
    PostMyOrderStatus().then((res) => {
      getMyorderDetails();
      orderStatusHandler(selectedOption, detail.orderid);

      closeModelHandle();
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleGame = () => {
    setOopen(true);
  };
  const closeModelHandler = () => {
    setOopen(false);
  };

  const gameOf = () => {
    setThirdShow(true);
  };
  return (
    <>
      <div>
        {!thirdShow && thirdPage.length >= 1 && (
          <TableContainer>
             <div style={{display:'flex',justifyContent:'center', borderBottom:'1px solid'}}>
            <strong>
              <h3>ORDER DETAILS</h3>
            </strong>
          </div>
            
            <Table>
             
              <TableBody>
            
                <TableRow>
                  <TableCell>
                    <strong>ORDER ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].orderid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>ASSET DEVELOPER ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].assetdevelopersiteid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>CONSUMER ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].consumeradminid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>DISTRIBUTOR ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].distributorsiteid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>LEGACY ASSET TYPE</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].legacyassettype}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>ASSET ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].assetid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>STATUS</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>MODEL ID</strong>
                  </TableCell>
                  <TableCell>{thirdPage[0].modelid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>UPDATE STATUS</strong>
                  </TableCell>
                  <TableCell>
                    <select
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      style={{ width: "120px", padding: "5px" }}
                    >
                      <option value="">select</option>
                      <option value="unloaded">unloaded</option>
                      <option value="dispatch">dispatch</option>
                      <option value="loaded">loaded</option>
                      <option value="in transit">in transit</option>
                      <option value="delivered">delivered</option>
                    </select>
                    &nbsp;&nbsp;
                    <Button
                      variant="outlined"
                      onClick={handleOpen}
                      
                    >
                      UPDATE STATUS
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Status updated!"
          action={
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    </>
  );
};
export default Test;
