import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriRetailerService from "./AgriRetailerService";
import AgriRetailerBenefits from "./AgriRetailerBenefits";
import AgriRetailerChallenge from "./AgriRetailerChallenge";
import AgriRetailersJourney from "./AgriRetailersJourney";
import AgriRetailerContribution from "./AgriRetailerContribution";
import AgriRetailerPricing from "../pricingTabs/AgriRetailerPricing";
import OurServices from "../OurServices/OurServices";
import CBNNetworkProducts from "../WebSite/CBNNetork/CBNNetworkProducts";
export default function AgriRetailers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriretailer-bg"
        bannerText={[
          "Want to become an agri-retailer to    ",
          <br className="d-lg-block d-none"></br>,
          " to sell inputs to our farmers ",
        ]}
        roleType="NULL"
        buttonText="Start selling"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Agri-Retailer");
        }}
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />
            <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            titleClass="text-start"
            classname2="text-start"
            classname="mb-0 mt-0 "
            title="Who are an Agri-Retailers"
            // description="GBR Farming's agri-retailers play a pivotal role in the agricultural value chain by acting as intermediaries. These retailers specialize in selling essential agri-inputs to agri-entrepreneurs, leveraging their own selling capacity. GBR Farming's agri-retailers focus on maximizing their own distribution capabilities and market presence to effectively monetize the sale of agricultural inputs. This strategic approach enables them to enhance profitability and establish a robust position in the agri-business landscape, contributing to the overall success of GBR Farming in the agricultural market.
            // "
            description="GBR Care's agri-retailers are pivotal in the agricultural value chain, specializing in selling agri-inputs to farmers. Beyond distribution, they actively manage and advise on crop selection and agronomic practices, contributing to improved yields. By strategically maximizing distribution and market presence, these retailers enhance profitability and establish a strong position in the agri-business landscape, highlighting their crucial role in GBR Care's overall success in the agricultural market."
            description2 = "Farm Machinery Retailers | Nutrition Retailers | Agri Input Retailers | Farmers' Advisors"


         />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <AgriRetailerChallenge />
      <SolutionEcosystem
        type="AR"
        bodycopy="Ecosystem for agri-retailers to sell inputs to farmers."
      />
      <OurServices/>

      <Container>
      <AgriRetailerService />
      </Container>
      <CBNNetworkProducts/>
      <div className="main-wrapper2">
        <AgriRetailerBenefits />
      </div>

      {/* <div className="subSectionWrapper">
       
          <GrowerBenefits />
   
      </div> */}
     
      
          <div className="subSectionWrapper">
          <Container>
          <AgriRetailersJourney/>
          </Container>
        </div>
        <div className="main-wrapper2">
          <Container>
          <AgriRetailerContribution/>
          </Container>
        </div>
      <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <AgriRetailerPricing />
        </Container>
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Agri-Retailer");
            }}
            href="/auth/3/signup"
            heading="Elevate Your Agricultural Expertise Join Us as a Agri-Retailer!"
            btnText="Register Now"
          />
        </div>
      </Container>


      <FooterComponent />
    </>
  );
}
