import React from 'react'

const VCNTraderDashboard = () => {
  return (
    <div>
      VCN  trader dashboard
      
    </div>
  )
}

export default VCNTraderDashboard;
