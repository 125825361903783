import { Container } from "@material-ui/core";
import React, { useState } from "react";
import Tabs from "react-simply-tabs";
import ABNCBN from "../../ABN/ABNCBN";
import InvestableAssets from "../InvestableAssets/InvestableAssets";
import InvestableMachinery from "../InvestableMachinery/InvestableMachinery";
import "./IAATab.css";

export default function IAATab({ customProp }) {
  const [activeTabIndex, setTabIndex] = useState(0);

  return (
    <div className="Iaa-tabs Iaa-tabs--mod ">
      <h2 className="container-title text-center">Asset-Management Services</h2>
      {customProp === "storage" && <p className="container-para mx-4 text-center mb-5">
        Gbr Farming has introduced a new scheme called Profit-generating assets
        (IAA & IMA), <br></br> for Agri-Storage Companies to generate extra income
      </p>}
      {customProp === "processing" && <p className="container-para mx-4 text-center mb-5">
        Gbr Farming has introduced a new scheme called Profit-generating assets
        (IMA), <br></br> for Agri-Contract Manufacturers to generate extra income
      </p>}      {(customProp === "farming"  || customProp === "selling") && <p className="container-para mx-4 text-center mb-5">
        Gbr Farming has introduced a new scheme called Profit-generating assets
        (IAA ), <br></br> for Agri-Entrepreneur to generate extra income
      </p>}

      {customProp === "processing" || customProp === "storage" ? (
        <Tabs
  id="test"
  activeTabIndex={activeTabIndex}
  activeControlProps={{
    className: "active",
  }}
  onRequestChange={setTabIndex}
  controls={
    customProp === "processing"
      ? [
          <button type="button" title="" className="Iaa-tab-buttons">
            IMA
          </button>,
        ]
      : [
          <button type="button" title="" className="Iaa-tab-buttons">
            IAA
          </button>,
          <button type="button" title="" className="Iaa-tab-buttons">
            IMA
          </button>,
        ]
  }
>
  {customProp === "storage" && (

      <div className="">
        <ABNCBN IAA="true" />
        <InvestableAssets />
      </div>
      )}
      <div id="ExploreIAA" className="main-wrapper2--mod">
        <ABNCBN IAM="true" section="processing" />
        <InvestableMachinery section="processing" />
      </div>
  
 
{/* 
  {customProp === "processing" && (
    <div id="ExploreIAA" className="main-wrapper2--mod">
      <ABNCBN IAM="true" />
      <InvestableMachinery section="processing" />
    </div>
  )} */}
</Tabs>


      ) 
      
      
      : (
        <Tabs
          id="test"
          activeTabIndex={activeTabIndex}
          activeControlProps={{
            className: "active",
          }}
          onRequestChange={setTabIndex}
          controls={[
            <button type="button" title="" className="Iaa-tab-buttons">
              IAA
            </button>,
            // <button type="button" title="" className="Iaa-tab-buttons">
            //   IMA
            // </button>,
          ]}
        >
          <div className="">
            <ABNCBN IAA="true" />
            <InvestableAssets />
          </div>

          {/* <div id="ExploreIAA" className=" main-wrapper2--mod">
           <ABNCBN IAM="true" />
           <InvestableMachinery />
         </div> */}
        </Tabs>
      )}
    </div>
  );
}
