import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./AssetService.css";
import IAATab from "../WebSite/IAATab/IAATab";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import CTA from "../CTA/CTA";
import { Container } from "react-bootstrap";

const farmingCapacity = (

  
  <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="moneyMakingSupport1"
    descriptionTitle="Monetize Farming Capacity"
    description="GBR Farming's Monetize Farming Capacity service is a pivotal offering, enabling agri-entrepreneurs to significantly augment their income by managing assets of third party (agri-investor). This innovative approach empowers them to unlock their full agricultural potential while promoting sustainable farming practices."
  />
);

const storageCapacity = (
  <HomeSubSection
    className1="aboutBgGrey"
    className="rowReverseAbout--mod borderedCards borderedCards--mod"
    className3="moneyMakingSupport2"
    descriptionTitle="Monetize Storage Capacity"
    description="Monetize Storage Capacity is a valuable service provided by GBR Farming, allowing agri-storage-entrepreneurs to generate additional income by sharing their storage facilities. This innovative approach enables them to optimize their storage space utilization while boosting their financial resources."
  />
);

const processingCapacity = (
  <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport3"
    description="GBR Farming's Monetize Processing Capacity service empowers Agri-Contract Manufacturer
    to optimize their equipment, producing top-quality goods for both local and global brands. By connecting skilled professionals with customers, we foster a mutually beneficial eco-system that not only boosts revenue but also encourages productive collaborations within the agriculture and production industry."
    descriptionTitle="Monetize Processing Capacity"
  />
);

const sellingCapacity =  (
  <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport3"
    description="GBR Farming's Monetize Processing Capacity service empowers Agri-retailers to optimize their equipment, producing top-quality goods for both local and global brands. By connecting skilled professionals with customers, we foster a mutually beneficial eco-system that not only boosts revenue but also encourages productive collaborations within the agriculture and production industry."
    descriptionTitle="Monetize Selling Capacity"
  />
);

const TransportCapacity =  (
  <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="transportSupport"
    description="GBR Farming enables agri-transporters to monetize their transportation capacity effectively. The platform serves as a marketplace, connecting transporters with MSMEs and streamlining the allocation of resources. This service enhances operational efficiency and allows agri-transporters to generate revenue by meeting the specific needs of the agricultural supply chain. Overall, GBR Farming plays a crucial role in facilitating the monetization of transportation services for agri-transporters.
    "
    descriptionTitle="Monetize Transportation Capacity"
  />
);



const AssetService = () => {
  const location = useLocation();
  const [customProp, setCustomProp] = useState("");

  useEffect(() => {
    // Extract customProp from location state
    const { state } = location;
    if (state && state.customProp) {
      setCustomProp(state.customProp);
    }
  }, [location]);





  let userType
  let headingText

  if (customProp === "storage"){
    headingText = "Want to become an agri-storage entrepreneur to create Trusted brand and earn extra income"
    userType = "FF"
  } else if(customProp === "farming"){
    headingText = "Want to become an approved agri-entrepreneur of GBR Farming eco-system to earn extra income"
    userType = "Grower"
  }else if(customProp === "selling"){
    headingText = "Want to become an approved agri-entrepreneur of GBR Farming eco-system to earn extra income"
    userType = "FF"
  }
  
  else if(customProp === "processing"){
    headingText = "Want to become an agri-food contract manufacurer to do Contract Manufacturing to monetize unutilised capacity"
    userType = "Production-Manager"
  }
  else if(customProp === "transport"){
    headingText = "Want to become Agri-Transporter  to monetize transportation capacity"
    userType = "Driver"
  }
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner className={customProp} bannerText={["Agriculture as a New Investment class"]} />
      <div className="subSectionWrapper">
        <h2 className="container-title text-center">Profit-generating Services</h2>
        {customProp === "storage"  && storageCapacity}
        {customProp === "farming" && farmingCapacity}
        {customProp === "processing" && processingCapacity}
        {customProp === "selling" && sellingCapacity}
        {customProp === "transport" && TransportCapacity}


    { (customProp != "storage" && customProp != "transport" )  &&  <div id="MoneyMakingAssets" className="subSectionWrapper pb-0">
          <IAATab customProp={customProp} />
        </div>}

        <Container className="subSectionWrapper">
        <CTA
            onClick={() => {
              localStorage.setItem("userType", userType);
            }}
            href="/auth/3/signup"
            heading= {headingText}
            btnText="Register Now"
          
          />
        </Container>
      </div>
      <FooterComponent />
    </>
  );
};

export default AssetService;
