import React from 'react';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import PNBenefits from '../WebSite/Benefits/PNBenefits';
import CBN from "../../images/12345.gif";


export default function ExploreTechnologySection1() {
  return (
        <div className=" pb-0" id="Technology">
          <h2 className="container-title text-center">Our Technology</h2>
          <div className=" ">
            <HomeSubSection
              fontBold="true"
              className="rowReverseAbout align-items-center"
              listItems={<PNBenefits />}
              description="MSME can Build their Own Community network (Virtual Clubs) to trade locally and find the best sourcing partners"
              descriptionTitle=" Production Network (PN)"
              src={CBN}
              grid="true"
            />
          </div>
        </div> 
  )
}
