import React from "react";
import Iaa1 from "../../images/plantcare/fertilizer.svg";
import Iaa2 from "../../images/plantcare/pesticide.svg";
import Iaa3 from "../../images/plantcare/pesticide (1).svg";


import { Row, Col } from "react-bootstrap";
import {Container} from "@material-ui/core";

export default function PlantCare() {
  const Image = [
    { img: Iaa1, title:"Fertilizers"},
    { img: Iaa2, title:"Pesticide" },
    { img: Iaa3, title:"Insecticide" }
  ];
  return (
    
    <>
   
        <Container className="mb-5">

          <Row className="justify-content-center">
          {Image.map((e, index) => (
            <Col lg={3} md={4} sm={6} className="md-md-0 mb-3" key={index}>
              {e.enabledProd ? (
                  <div className="imageHover Iaa-Cards Iaa-Cards-mod card-activated">
                    <img className="imgCursor mb-3" src={e.img} alt="icon" />
                    <h6 className="text-center">{e.title}</h6>
                  </div>
              
              ) : (
                <div className="Iaa-Cards Iaa-Cards-mod d-flex justify-content-center align-items-center flex-column card-deactivated">
                  <img  className="mb-3" src={e.img} alt="icon" />
                  <h6>{e.title}</h6>
                </div>
              )}
            </Col>
          ))}
        </Row>
        </Container>
    
      
    
    </>
  );
}
