import React from "react";
import "./PricingCards.css";
import { Button } from "react-bootstrap";
import tick from "../../images/tick1.png";
import { NavLink } from "react-router-dom";

export default function PricingCards({
  title,
  price,
  annually,
  btnText,
  features,
  href,
  newTab,
  onClick,
  customText,
  data
}) {
  console.log(data, "((((((((((((((((((((((((((((((((((")
  return (
    <div className="pricing-card mb-md-0 mb-3 w-100">
      <div className="pricing-card-title">{title}</div>
      <p className="pricing-card-subtitle">
        A standard plan designed to meet your creative needs.
      </p>
      {price && <><p className="pricing-card-price mb-0">
        {price} INR <span>/month</span>
      </p>
      <p className="pricing-card-frequency mb-4">
        {annually} INR Billed annually
      </p></>}
{customText && <p className="pricing-card-price mb-4">
{customText}  
      </p>}

      {  !newTab ?   <NavLink text-white onClick={onClick}  to={{ pathname: href, state: { roleType: data } }}><Button variant="contained" color="primary" className="w-100 mb-3">
         {btnText} </Button></NavLink>  
     :
     <a text-white href= {href} target="_blank" >
       <Button variant="contained" color="primary" className="w-100 mb-3">
       {btnText}

  
    </Button></a>  

 }
     

      <ul className="list-unstyled">
        {features.map((e, i) => {
          return (
            <li className="d-flex">
              <img src={tick} alt="icon" className="me-2" />
              {e}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
