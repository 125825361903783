import React, { useState, useEffect } from 'react';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import Geocode from 'react-geocode';
import MainService from '../../../Common/MainService';
import LiveMonitorMap from '../../livemonitormap/LiveMonitorMap';

const google = window.google;

Geocode.setApiKey('YOUR_API_KEY');
Geocode.setLanguage('en');
Geocode.setRegion('es');
Geocode.enableDebug();

const GeofenceDetails = (props) => {
  const [searchValue, setSearchValue] = useState(null);
  const [address, setAddress] = useState(null);
  const [map_type, setMap_type] = useState(null);
  const [geo, setGeo] = useState({});
  const [polyPath, setPolyPath] = useState([]);
  const [marker_position, setMarker_position] = useState({
    lat: 12.9362082,
    lng: 77.6883844,
  });
  const [drawing_type, setDrawing_type] = useState('');
  const [defaultRadius, setDefaultRadius] = useState(5000);
  const [vehicleId, setVehicleId] = useState('');
  const [currentMarker, setCurrentMarker] = useState([]);
  const [shapes, setShapes] = useState({
    markers: [],
    polylines: [],
    circles: [],
    rectangles: [],
    polygons: [],
  });
  const [polygonData, setPolygonData] = useState([]);
  const [tempGeoData, setTempGeoData] = useState([]);
  const [tempShape, setTempShape] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getGeofenceLocation();
  }, []);

  const data = localStorage.getItem('CBN');
  const {
    user: { parentId, username },
  } = JSON.parse(atob(localStorage.getItem('CBN')));

  const onSubmit = (value) => {
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setMarker_position({ lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
    setSearchValue(value);
  };

  const getAddress = (value) => {
    setAddress(value);
  };

  const geoVal = (val) => {
    setGeo(val);
  };

  const setMapType = (evt) => {
    setMap_type(evt.target.value);
  };

  const polyPathValue = (lat, lng) => {
    if (drawing_type == 'Polygon') {
      let polyPath = [...polyPath];
      polyPath.push({ lat: lat, lng: lng });
      setPolyPath(polyPath);
    } else {
      let currentPath = { lat, lng };
      setMarker_position(currentPath);
    }
  };

  const clearDrawing = () => {
    // console.log(event,'event.....')

    setPolyPath([]);
    setDefaultRadius(0);
  };

  const undoDrawing = () => {
    let polyPath = [...polyPath];
    polyPath.pop();
    setPolyPath(polyPath);
  };

  const submitForm = async () => {
    if (
      !drawing_type ||
      (drawing_type !== 'circle' && drawing_type !== 'polygon')
    ) {
      console.error('Invalid drawing_type');
      // Display an error message to the user
      return;
    }
    const vehicle = props.history.location.pathname.split('/');
    const vehicleId = vehicle[vehicle.length - 1];

    const rec = {
      fencingType: drawing_type == 'Polygon' ? 'polygon' : 'circular',
      circularFencing: {
        geoLocation: {
          lat: currentMarker?.geoLocation?.lat,
          lng: currentMarker?.geoLocation?.lng,
        },
        radius: defaultRadius,
      },
      polygonFencing: {
        geoLocation: {
          lat: 0,
          lng: 0,
        },
        geoLocations: shapes.polylines,
      },
      vehicleGroupId: 'default-group',
    };
    console.log('reeeeeeeeeeeeeeeeeeeeeeeeeeeeee', rec);
    if (drawing_type === 'circle') {
      const data = await MainService.createGeofence(
        parentId,
        username,
        rec,
        vehicleId
      );
      // props.history.push('/main/vehicle');
      props.history.push('/main/main/vehicle');
    } else if (drawing_type === 'polygon') {
      const data = await MainService.createGeofence(
        parentId,
        username,
        rec,
        vehicleId
      );
      props.history.push('/main/main/vehicle');
      console.log('poly data', data);
    }
  };

  const setDrawingType = (event) => {
    setDrawing_type(event.target.value);
  };

  const changeDefaultRadius = (evt) => {
    setDefaultRadius(evt.target.value);
  };

  const onPlaceSelected = (place) => {
    const addressArray = place.address_components;
    let address = '';
    addressArray.map((each_name) => {
      address += ' ' + each_name.long_name;
    });

    let currentPath = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      setAddress(results[0].formatted_address);
      setSearchValue(address);
    });

    this.setState({
      marker_position: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
      geo: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    });

    setMarker_position({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setGeo({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const radiusChangeHandler = (event) => {
    setDefaultRadius(event.target.value * 1000);
  };

  const getLocationHandler = (props) => {
    console.log('rpoerueoi', props);
    const geo = {
      lat: props[0]?.geometry?.location?.lat(),
      lng: props[0]?.geometry?.location?.lng(),
    };
    setCurrentMarker({ geoLocation: geo });
  };

  const getNewRadius = (props) => {
    setDefaultRadius(props);
  };

  const getGeofenceLocation = async () => {
    console.log('datagetGeofenceLocationgetGeofenceLocation', username);

    const vehicle = props.history.location.pathname.split('/');
    const vehicleId = vehicle[vehicle.length - 1];
    if (vehicleId) {
      const data = await MainService.geofenceDetails(
        parentId,
        username,
        vehicleId
      );
      console.log('datagetGeofenceLocationgetGeofenceLocation', data);

      setCurrentMarker(data.circularFencing);
      setDefaultRadius(parseFloat(data.circularFencing.radius));
      console.log('polygon', data.polygonFencing.geoLocation);
      setTempGeoData(data);
    }
  };

  const selectShapeData = (shapeData, shapeType) => {
    console.log('skdfjksdf', shapeType);
    if (shapeType === 'polygon') {
      setPolygonData(shapeData?.geoLocations);
    } else if (shapeType === 'circle') {
      setPolygonData({
        geoLocation: {
          lat: parseFloat(shapeData?.geoLocation?.lat),
          lng: parseFloat(shapeData?.geoLocation?.lng),
        },
      });
      setDefaultRadius(shapeData?.radius);
    }
  };

  const handleShapeAdd = (shape, type) => {
    if (drawing_type !== 'polygon') {
      return;
    }

    setShapes((prevState) => {
      const newShapes = {
        ...prevState,
        [type]: [...prevState[type], shape],
      };
      setTempShape(shape);
      if (type === 'polylines') {
        const polylines = newShapes[type];
        const polylineCoords = polylines.reduce((coords, polyline) => {
          const path = polyline.getPath();
          const pathCoords = path.getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));
          return [...coords, ...pathCoords];
        }, []);
        return {
          ...newShapes,
          polylines: polylineCoords,
        };
      }

      return newShapes;
    });
  };

  const handlePolyShapeAdd = (props) => {
    // this.setState({
    //   polygonData: props,
    //   shapes: { polylines: props },
    // });
    setPolygonData(props);
    setShapes({ polylines: props });
  };

  const clearShapes = (event) => {
    event.preventDefault();

    const shapes = [tempShape];
    console.log('shapes', shapes);
    // Remove all shapes from the map
    if (shapes[0]) {
      console.log('shapes', shapes[0]);
      shapes.forEach((polyline) => polyline.setMap(null));
    }
    setShapes({
      markers: [],
      polylines: [],
      circles: [],
      rectangles: [],
      polygons: [],
    });
    setPolygonData([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    setAnchorEl(null);
    if (type === 'circle') {
      setDrawing_type('circle');
      selectShapeData(tempGeoData?.circularFencing, 'circle');
    } else if (type === 'polygon') {
      setDrawing_type('polygon');
      selectShapeData(tempGeoData?.polygonFencing, 'polygon');
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            mt: 2,
            '&>*': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box sx={{ gap: '1rem', display: 'flex' }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box>
                <Button
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant='outlined'
                >
                  {drawing_type ? drawing_type : 'Select Option'}
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => handleClose('circle')}>
                    Circle
                  </MenuItem>
                  <MenuItem onClick={() => handleClose('polygon')}>
                    Polygon
                  </MenuItem>
                </Menu>
              </Box>

              <Box>
                {drawing_type !== 'polygon' ? (
                  <Button variant='outlined' onClick={clearDrawing}>
                    Clear Drawing
                  </Button>
                ) : (
                  <Button
                    variant='outlined'
                    onClick={(event) => clearShapes(event)}
                  >
                    Clear Drawing
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant='text'
              onClick={() => {
                props.history.push('/main/main/vehicle');
                //  this.props.target.history.push('/vehicle')
              }}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={submitForm}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      {drawing_type === 'circle' && (
        <Box sx={{ marginTop: '1rem' }}>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            placeholder='enter radius'
            type='number'
            value={parseInt(defaultRadius / 1000)}
            onChange={radiusChangeHandler}
          />
        </Box>
      )}

      <Box sx={{ marginTop: '1rem' }}>
        <LiveMonitorMap
          routeLocationData={[currentMarker]}
          defaultRadius={defaultRadius}
          getLocationHandler={getLocationHandler}
          getNewRadius={getNewRadius}
          drawing_type={drawing_type}
          onShapeAdd={handleShapeAdd}
          clearShapes={clearShapes}
          polyShapes={polygonData || []}
          handlePolyShapeAdd={handlePolyShapeAdd}
        />
      </Box>
    </>
  );
};

export default GeofenceDetails;
