import React, { useEffect, useState } from 'react';
import SearchBar from '../../../../../Containers/SearchBar/SearchBar';
import MatTable from '../../../../../Containers/Table/Table';
import MainService from '../../../Common/MainService';
import { Box, Button, Menu, MenuItem } from '@mui/material';

const fields = [
  '',
  'Vehicle Id',
  'Vehicle Status',
  'Mapped',
  'Fencing',
  'Last Modified',
];

const VehicleManagement = (props) => {
  const [vehicle, setVehicle] = useState(false);
  const [settings_modal, setSettings_modal] = useState(false);
  const [confirm_delete, setConfirm_delete] = useState(false);
  const [selected_items, setSelected_items] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState('');
  const [vehicleStatus, setVehicleStatus] = useState('active');
  const [modifyDataLabel, setModifyDataLabel] = useState('');
  const [action_items, setaction_items] = useState([
    {
      link: `/geofence`,
      name: 'Modify',
      onDelete: false,
    },
  ]);
  const [vehicleDetails, setVehicleDetails] = useState({ assets: [] });

  useEffect(() => {
    getVehicleList(0);
  }, []);

  const getVehicleList = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { assets } = await MainService.getVehicleList(username);
    setVehicleDetails({ assets });
  };

  const onSelect = (item) => {
    if (selected_items === item) {
      setSelected_items('');
    } else {
      setSelected_items(item);
    }
  };

  const handleClick = () => {
    props.history.push(`/main/geofence/${selected_items}`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <SearchBar
          placeholder={'Search Location'}
          // onSubmit={this.getSearchLocation.bind(this)}
        />
        <Box>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='outlined'
            disabled={selected_items === ''}
          >
            Modify fencing
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <MatTable
          fields={fields}
          rows={vehicleDetails.assets}
          keys={['assetId', '', '', '', '']}
          total={vehicleDetails.assetId}
          unique='assetId'
          onSelect={onSelect}
          change_page={getVehicleList}
          selected={selected_items}
        />
      </Box>
      {/* <Box
        sx={{
          marginTop: '1rem',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <Button
          onClick={loadMoreLoc}
          sx={{
            color: '#fff',
            background: '#1976d2',
            ':hover': {
              bgcolor: '#167add',
              color: '#fff',
            },
          }}
        >
          Load More{' '}
        </Button>
        <Fab
          color='primary'
          size={'large'}
          aria-label='add'
          cursor='pointer'
          title='Add Location'
          variant='outlined'
          onClick={() => locHandler()}
          // className={classes.myStyle}
        >
          <AddIcon />
        </Fab>
      </Box> */}
    </>
  );
};

export default VehicleManagement;
