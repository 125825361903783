import React, { useEffect, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import InvoiceTable from '../components/InvoiceTable';
import SupplyListHeader from '../components/SupplyListHeader';
import dayjs from 'dayjs';
import UserForm from '../components/UserForm';
import CommanTable from '../components/CommanTable';
import { placeOrderRequestApi } from '../utils/fetchapirequest';
import MainService from '../../MainService';
import { uniqueNumber } from '../utils/genrateUniqueNumber';

const tempPurchaseOrderDetails = [
  { id: 'quotationId', label: 'Quotation ID' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'buyer_email', label: 'Buyer Email' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempPurchaseDetails = [
  { id: 'logo', label: 'Logo', type: 'logo' },
  { id: 'purchaseOrderID', label: 'Purchase order ID' },
];

const tempQuotationDetails = [
  {
    purchaseOrderID: 'PUR-' + uniqueNumber(),
    purchaseOrderDate: formattedValue,
  },
];

const tempBuyerData = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempSupplier = {
  supplierName: '',
  supplierContact: '',
  sup_email: '',
  sup_gstin: '',
  sup_panNo: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
};

const tempPurchaseDescriptionColumnData = [
  { id: 'description', label: 'Description' },
  { id: 'quantity', label: 'Quantity', type: 'number', width: 100 },
  { id: 'unit', label: 'Unit', width: 50 },
  { id: 'reqDeliveryDate', label: 'Requested Delivery Date', type: 'date' },
  {
    id: 'price_per_unit',
    label: 'Requested Rate per Unit',
    type: 'number',
    width: 100,
  },
  { id: 'total', label: 'Total', type: 'number', width: 200 },
];

const tempPurchaseDescriptionRowData = [
  {
    description: '',
    quantity: '',
    unit: '',
    price_per_unit: '',
    reqDeliveryDate: '',
  },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];
const tempTermConditionValue = [];

const PoManagement = ({ userDetails }) => {
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [purchaseOrderDetils, setPurchaseOrderDetils] = useState([]);
  const [newPurchaseOrderStatus, setNewPurchaseOrderStatus] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [tempImage, setTempImage] = useState({});

  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);

  const [purchaseOrderItemDetails, setPurchaseOrderItemDetails] = useState(
    tempPurchaseDescriptionRowData
  );

  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  useEffect(() => {
    const purchaseOrderID = 'PUR-' + uniqueNumber();
    setPurchaseOrderDetils([
      {
        purchaseOrderID: purchaseOrderID,
        purchaseOrderDate: formattedValue,
      },
    ]);
  }, [newPurchaseOrderStatus]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setBuyerDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails]);

  //image file change handler
  const imageFileChangeHandler = (event, columnId) => {
    const { name, files } = event.target;

    if (!files[0]) {
      setImageFile((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[columnId];
        return updatedData;
      });
      setTempImage((prevData) => {
        const updatedData = { ...prevData };
        updatedData[columnId] = ''; // Set a default image or empty string
        return updatedData;
      });
      return;
    }
    setImageFile((prevData) => ({
      ...prevData,
      [columnId]: files[0],
    }));
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setTempImage((prevData) => ({
        ...prevData,
        [columnId]: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const imageChangeHandler = (columnId) => {
    const fileInput = document.getElementById(columnId);
    fileInput.click();
  };

  //add new purchase order handler
  const addNewPurchaseOrderHandler = () => {
    setNewPurchaseOrderStatus(true);
  };

  //purchase order details change handler
  const purchaseOrderDetailsChangeHandler = (index, field, value) => {
    setPurchaseOrderDetils((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;
    console.log(name, value);

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  //Purchase order item detials handler
  const purchaseOrderItemChangehandler = (index, field, value, type) => {
    const parsedValue = type === 'number' ? parseFloat(value) : value;

    setPurchaseOrderItemDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: parsedValue,
          };
        }
        return item;
      })
    );
  };

  //Deleting description item handler
  const deleteItemHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  //purchase order add item handler
  const addPurchaseOrderItemHandler = () => {
    const data = {
      description: '',
      quantity: '',
      unit: '',
      price_per_unit: '',
      reqDeliveryDate: '',
    };
    setPurchaseOrderItemDetails([...purchaseOrderItemDetails, data]);
  };

  //save purchase order handler
  const savePurchaseOrderHandler = async () => {
    console.log('click');
    setTimeout(() => {
      setFormSubmitError(false);
    }, 3000);

    const isSecondObjectEmpty = (obj) =>
      Object.values(obj).some((value) => value === '');

    const isItemEmpty = (item) =>
      Object.values(item).some(
        (value) =>
          value === '' ||
          (typeof value === 'number' && isNaN(value)) ||
          (typeof value === 'string' && value === 'Invalid Date')
      );

    const isSupplierDetailsEmpty = isSecondObjectEmpty(supplierDetails);
    const isPurchaseOrderItemDetailsEmpty =
      purchaseOrderItemDetails.some(isItemEmpty);

    const isPurchaseOrderDetailsEmpty = !purchaseOrderDetils[0].purchaseOrderID;

    console.log('isBuyerDetailsEmpty', buyerDetails);

    //test
    const isBuyerDetailsNameEmpty = buyerDetails.buyerName === '';
    const isBuyerDetailsContactEmpty =
      buyerDetails.buyer_cell.buyer_cell1 === '';
    const isBuyerDetailsEmailEmpty = buyerDetails.buyer_email === '';
    const isBuyerDetailsGSTEmpty = buyerDetails.buyer_gstin === '';
    const isBuyerDetailsPanEmpty = buyerDetails.buyer_panNo === '';
    const isBuyerDetailsAddress1Empty = buyerDetails.buyerAdressline1 === '';
    const isBuyerDetailsAddress2Empty = buyerDetails.buyerAdressline2 === '';
    const isBuyerDetailsAddress3Empty = buyerDetails.buyerAdressline3 === '';

    if (
      isBuyerDetailsNameEmpty ||
      isBuyerDetailsContactEmpty ||
      isBuyerDetailsEmailEmpty ||
      isBuyerDetailsGSTEmpty ||
      isBuyerDetailsPanEmpty ||
      isBuyerDetailsAddress1Empty ||
      isBuyerDetailsAddress2Empty ||
      isBuyerDetailsAddress3Empty ||
      isSupplierDetailsEmpty ||
      isPurchaseOrderItemDetailsEmpty ||
      isPurchaseOrderDetailsEmpty
    ) {
      // Handle empty fields error, display a message or perform appropriate action
      setSaveButtonStatus(true);
      setFormSubmitError(true);
      return;
    }

    const data = {
      items: purchaseOrderItemDetails,
      supplierDetails,
      buyerDetails,
      purchaseOrderDetails: purchaseOrderDetils[0],
      consigneeDetails: {
        consigneeContactNo: buyerDetails.buyer_cell.buyer_cell1,
        consigneeAdressline1: buyerDetails.buyerAdressline1,
        consigneeAdressline2: buyerDetails.buyerAdressline2,
        consigneeAdressline3: buyerDetails.buyerAdressline3,
      },
      termsConditions: [...termsConditions.map((obj) => obj.term)],
    };

    const contentInfo = {
      contentname: `${purchaseOrderDetils[0].purchaseOrderID}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: 'Purchase order.',
      expirydate: purchaseOrderDetils[0].purchaseOrderDate,
      assetdeveloperid: 'consumer2',
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('file', imageFile.logo);
    console.log('click', imageFile, data);

    // const response = await placeOrderRequestApi(
    //   `/genDoc/v1/createPurchaseOrder`,
    //   formData,
    //   purchaseOrderDetils,
    //   buyerDetails,
    //   setPurchaseOrderList
    // );

    const response = await MainService.createPurchaseOrderApi(
      `${parentId}/${username}`,
      orgid,
      suborgid,
      formData,
      username
    );

    const tempPurchaseOrderList = {
      viewLink: response.data.LocationUrl,
      quotationId: response.data.contenId,
    };

    if (response.status === 200) {
      handleDownload(response.data.LocationUrl, response.data.contenId);
      setFormSubmitError(false);
      setShowAlert(true);
      setPurchaseOrderList((prevList) => [...prevList, tempPurchaseOrderList]);

      setTimeout(() => {
        cancelPurchaseOrderHandler();
      }, 3000);
    } else {
      setFormSubmitError(true);
    }
    console.log(response);
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  //cancel purchase order handler
  const cancelPurchaseOrderHandler = () => {
    setSaveButtonStatus(false);
    setShowAlert(false);
    setFormSubmitError(false);
    setPurchaseOrderDetils(tempQuotationDetails);
    setNewPurchaseOrderStatus(false);
    setImageFile({});
    setTempImage({});
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setPurchaseOrderItemDetails(tempPurchaseDescriptionRowData);
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      {!newPurchaseOrderStatus && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Purchase Order Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />

          <InvoiceTable
            columnData={tempPurchaseOrderDetails}
            rowDataList={purchaseOrderList}
          />

          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewPurchaseOrderHandler}>
                Add New Purchase Order
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}

      {newPurchaseOrderStatus && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Purchase Order
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
            }}
          >
            <CommanTable
              columnsData={tempPurchaseDetails}
              rowsData={purchaseOrderDetils}
              image={tempImage}
              saveButtonStatus={saveButtonStatus}
              imageChangeHandler={imageChangeHandler}
              imageFileChangeHandler={imageFileChangeHandler}
              inputFieldChangeHandler={purchaseOrderDetailsChangeHandler}
            />
          </Box>
          <Box>
            <UserForm
              saveButtonStatus={saveButtonStatus}
              buyerDetails={buyerDetails}
              supplierDetails={supplierDetails}
              userInputBuyerChangehandler={(event) =>
                formUserInputChangeHandler(event, setBuyerDetails)
              }
              userInputSupplierChangehandler={(event) =>
                formUserInputChangeHandler(event, setSupplierDetails)
              }
            />

            <Box
              component='form'
              sx={{
                mb: '1.2rem',
              }}
              noValidate
              autoComplete='off'
            >
              <Box
                sx={{
                  mb: '1.2rem',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                  borderRadius: '5px',
                  padding: { xs: '0.5rem', md: '1rem' },
                }}
              >
                <CommanTable
                  columnsData={tempPurchaseDescriptionColumnData}
                  rowsData={purchaseOrderItemDetails}
                  tableType={'editable'}
                  inputFieldChangeHandler={purchaseOrderItemChangehandler}
                  // deleteItemHandler={deletePurchaseOrderItemHandler}
                  deleteItemHandler={(rowsData, index) =>
                    deleteItemHandler(
                      index,
                      purchaseOrderItemDetails,
                      setPurchaseOrderItemDetails,
                      true
                    )
                  }
                  addItemHandler={addPurchaseOrderItemHandler}
                  saveButtonStatus={saveButtonStatus}
                />
                <CommanTable
                  columnsData={tempTermConditionLabel}
                  rowsData={termsConditions}
                  tableType={'editable'}
                  addItemHandler={addTermConditionHandler}
                  inputFieldChangeHandler={termsConditionsChangeHandler}
                  deleteItemHandler={(rowData, index) =>
                    deleteItemHandler(
                      index,
                      termsConditions,
                      setTermsConditions,
                      false
                    )
                  }
                />
              </Box>
              {/* <Box>
                <Stack>
                  <ListItem
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button>Cancel</Button>
                    <Button>Save</Button>
                  </ListItem>
                </Stack>
              </Box> */}
            </Box>
          </Box>
          {showAlert && (
            <Alert severity='success' sx={{ mt: '0.5rem' }}>
              Quotation successfully submitted.
            </Alert>
          )}
          {formSubmitError && (
            <Alert severity='error' sx={{ mt: '0.5rem' }}>
              Please fill the required field.
            </Alert>
          )}
          <Box>
            <Stack>
              <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={cancelPurchaseOrderHandler}>Cancel</Button>
                <Button onClick={savePurchaseOrderHandler}>Save</Button>
              </ListItem>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PoManagement;
