import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UploadContent from "../ModelProductManagement/UploadContent";
import UploadCertificate from "../ModelProductManagement/UploadCertificate";
import Rating from "@material-ui/lab/Rating";
import { countBy } from "lodash";
import ContentSubCategoryCreate from "../ModelProductManagement/ContentSubCategoryCreate";
import DeleteSubCategoryOfContent from "../ReusableComponents/DeleteSubCategoryOfContent";
import "./Market.css";
import { Box, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function MarketProcessedProducts() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [productIdValue, setProductIdValue] = useState("");
  const [productFriendlyNameValue, setProductFriendlyNameValue] = useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [getProductCostValue, setProductCostValue] = useState();
  const [getUnitValue, setUnitValue] = useState("");
  const [getDiscountValue, setDiscountValue] = useState("");
  const [getDiscountedPrice, setDiscountedPrice] = useState(0);
  const [getCountery, setCountery] = useState("");
  const [getModelFriendlyName, setModelFriendlyName] = useState("");
  const [setFilterProducts, getFilterProducts] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  // const [parentid, setParentId] = useState("");
  const [getProductsData, setProductsData] = useState([]);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [getProductItemModel, setProductItemModel] = useState(false);
  /* Add Product Item */
  const [getItemConsumerAdminId, setItemConsumerAdminId] = useState("");
  const [getItemBusinessId, setItemBusinessId] = useState("");
  const [getItemProductId, setItemProductId] = useState("");
  const [getItemProductItems, setItemProductItems] = useState("");
  /* Delete Product'S */
  const [getDeleteProduct, setDeleteProduct] = useState(false);
  const [getDeleteProductBusinessId, setDeleteProductBusinessId] = useState("");
  const [getDeleteProductId, setDeleteProductId] = useState("");
  const [getDeleteAdminId, setDeleteAdminId] = useState("");

  /* Upload Content */
  const [getUploadContentModel, setUploadContentModel] = useState(false);
  const [getUploadcontentProps, setUploadcontentProps] = useState([]);

  /* Upload Certificate */
  const [getUploadCertificateModel, setUploadCertificateModel] =
    useState(false);
  const [getUploadCertificateProps, setUploadCertificateProps] = useState([]);

  /* Update Product Image */
  const [getImageUpdateModel, setImageUpdateModel] = useState(false);
  const [getUpdateImageAdminId, setUpdateImageAdminId] = useState("");
  const [getUpdateImageBusinessId, setUpdateImageBusinessId] = useState("");
  const [getUpdateImageProductId, setUpdateImageProductId] = useState("");
  const [getUpdateImageURL, setUpdateImageURL] = useState("");

  /* Update Product Certificate */
  const [getCertificateUpdateModel, setCertificateUpdateModel] =
    useState(false);

  /* Update Product QR Code */
  const [getQRUpdateModel, setQRUpdateModel] = useState(false);

  /* Update Product Status */
  const [getStatusUpdateModel, setStatusUpdateModel] = useState(false);

  /* Update Discount */
  const [getDiscountUpdateModel, setDiscountUpdateModel] = useState(false);
  const [getDiscountOriginalPrice, setDiscountOriginalPrice] = useState("");
  const [getDiscount, setDiscount] = useState("");
  const [getDiscountNewPrice, setDiscountNewPrice] = useState("");

  /* Update Product Cost */
  const [getProductCostUpdateModel, setProductCostUpdateModel] =
    useState(false);

  /* Update Product Cost */
  const [getRatingUpdateModel, setRatingUpdateModel] = useState(false);
  const [value, setValue] = useState(1);
  const [hover, setHover] = useState(-1);

  /* Update Item Count */
  const [getItemCountUpdateModel, setItemCountUpdateModel] = useState(false);

  /** Update Product History URL */
  const [getHistoryURLUpdateModel, setHistoryURLUpdateModel] = useState(false);

  /**Update Lab Certificate */
  const [getLabCertificateUpdateModel, setLabCertificateUpdateModel] =
    useState(false);

  /** Product Video */
  const [getVideoUpdateModel, setVideoUpdateModel] = useState(false);

  /** Product Items */
  const [getProductItemUpdateModel, setProductItemUpdateModel] =
    useState(false);
  const [getProductItemsStr, setProductItemsStr] = useState([]);

  /** Get Categorys */
  const [getCategoriesList, setCategoriesList] = useState("");
  const [getCategoriesNameList, setCategoriesNameList] = useState("");
  const [getSubCategoriesList, setSubCategoriesList] = useState([]);
  const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
  const [getSubCategoryApiFilter, setSubCategoryApiFilter] = useState("");

  const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
  const [getSubCategoryModel, setSubCategoryModel] = useState(false);
  const [getDeleteSubCategoryModel, setDeleteSubCategoryModel] =
    useState(false);
  const userType  = localStorage.getItem("userType");

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterProducts.filter((row) => {
      return row.category.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterProducts.filter((row) => {
      return row.category.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    if (
      !parentId ||
      !username ||
      !selectedVerticalsId ||
      !selectedCategory ||
      !selectedAssertTypes ||
      !selectedModel ||
      !getCategoriesList
    )
      return;
    getAllModelProducts();
    setSubCategoryApiFilter("");
  }, [
    parentId,
    username,
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
    getCategoriesList,
  ]);

  useEffect(() => {
    if (!username || !getCategoriesList) return;
    subCategoryList();
  }, [username, getCategoriesList]);

  const categorysList = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        const filterCategory = res.filter((data) => {
          return data.categoryname.includes("PROCESSED-PRODUCTS");
        });
        setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
        setCategoriesList(filterCategory[0].categoryid);
        setCategoriesNameList(filterCategory[0].categoryname);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  useMemo(() => {
    if (!username) return;
    categorysList();
  }, []);

  const subCategoryList = () => {
    MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
      .then((res) => {
        if (res.length > 0) {
          setSubCategoryForForm(res[0].contentsubcategoryname);
          setSubCategoriesList(res);
        } else {
          setSubCategoriesList(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  // const getParentId = () => {
  //   MainService.getParentId(username)
  //     .then((data) => {
  //       if (data.length > 0) {
  //         setParentId(data);
  //       }
  //     })
  //     .catch((e) => {
  //       return NotificationManager.error(e);
  //     });
  // };

  // useMemo(() => {
  //   if (!username) return;
  //   getParentId();
  // }, [username]);

  /* Upload Content */

  const uploadContentHandler = (product) => {
    setUploadContentModel(!getUploadContentModel);
    setUploadcontentProps(product);
  };

  const closeContentHandler = () => {
    setUploadContentModel(!getUploadContentModel);
  };

  /* EOD Upload Content */

  /** Create Product Certificate */

  const uploadCertificateHandler = (product) => {
    setUploadCertificateProps(product);
    setUploadCertificateModel(!getUploadCertificateModel);
  };

  const closeContentCertificateHandler = () => {
    setUploadCertificateModel(!getUploadCertificateModel);
  };
  /** EOD Create Product Certificate */

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        const filerFriendlyName = ["MACHINERY", "TRANSPORTATION"];
        const filterData = data.filter((val, index) => {
          return !filerFriendlyName.includes(val.friendlyName);
        });
        if (filterData.length > 0) {
          setSelectedVerticalsId(filterData[0].assetVerticalId);
          setAssetVerticalFriendlyNameValue(filterData[0].friendlyName);
          setVerticalsIdList(filterData);
        } else {
          setSelectedVerticalsId("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    if (!getCategoriesList) return;
    getAssetVerticalsIdList();
  }, [getCategoriesList]);

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getAssetTypesList();
  }, [selectedCategory]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelFriendlyName(data[0].modelfriendlyname);
            setModelsList(data);
          } else {
            setSelectedModel("");

            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getModelsList();
  }, [selectedAssertTypes]);

  /** Get Products */
  const getAllModelProducts = (Type) => {
    if (Type === "List") {
      setListView(true);
      setGridView(false);
    } else if (Type === "Grid") {
      setListView(false);
      setGridView(true);
    }
    MainService.getModelProductsBasedOnModel(
      parentId,
      username,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes,
      selectedModel,
      getCategoriesList
    )
      .then((data) => {
        setProductsData(data.products);
        getFilterProducts(data.products);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const getAllModelProductsOfSubCaegory = (subcatval) => {
    setSubCategoryApiFilter(subcatval);
    MainService.getModelProductsBasedOnContentSubcategory(
      parentId,
      username,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes,
      selectedModel,
      getCategoriesList,
      subcatval
    )
      .then((data) => {
        setProductsData(data.products);
        getFilterProducts(data.products);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Get Products */

  /* Create Product */

  const createProductHandler = () => {
    if (productIdValue === "") {
      return NotificationManager.warning("Please Enter Product Model Id");
    }
    let createProductData = {
      consumeradminid: "consumer2",
      businessid: username,
      assetverticalid: selectedVerticalsId,
      assetverticalname: assetVerticalFriendlyNameValue,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      modelfriendlyname: getModelFriendlyName,
      assetid: "null",
      assetfriendlyname: "null",
      productid: productIdValue,
      productfriendlyname: productFriendlyNameValue,
      productcost: Number(getProductCostValue),
      unit: getUnitValue,
      discount: getDiscountValue,
      discountedprice: Number(getDiscountedPrice),
      country: getCountery,
      state: "null",
      district: "null",
      categoryname: getCategoriesNameList.toUpperCase(),
    };
    const modelProductData = JSON.stringify(createProductData);
    MainService.addModelProduct(
      parentId,
      username,
      getCategoriesList,
      getSubCategoryForForm,
      modelProductData
    )
      .then((res) => {
        setShowAssetPopup(!assetPopup);
        getAllModelProducts();
        return NotificationManager.success("product info stored");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const addProductsClickHandler = () => {
    setShowAssetPopup(!assetPopup);
  };

  const setDiscountValueHandler = (e) => {
    setDiscountValue(e.target.value);
    const price = Math.ceil(
      getProductCostValue - (getProductCostValue * e.target.value) / 100
    );
    setDiscountedPrice(price);
  };

  const closeAgreementModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );

  const titleAgreement = "Create Product";

  const agreementBody = (
    <Box>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryname">Category:</InputLabel>
        <Input
          id="categoryname"
          name="categoryname"
          type="text"
          value={getCategoriesNameList}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel>SubCategory:</InputLabel>
        <Select
          id="SubCategory"
          name="SubCategory"
          type="text"
          value={getSubCategoryForForm}
          onChange={(e) => {
            setSubCategoryForForm(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {getSubCategoriesList.length > 0 ? (
            getSubCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))
          ) : (
            <h4>Sub-Category Not Found</h4>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="businessid">Business ID:</InputLabel>
        <Input
          disabled
          id="businessid"
          name="businessid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="productid">Product ID :</InputLabel>
        <Input
          id="productid"
          name="productid"
          type="text"
          placeholder="5533222"
          value={productIdValue}
          onChange={(e) => {
            setProductIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="ProductFriendlyName">
          Product Friendly Name :
        </InputLabel>
        <Input
          id="ProductFriendlyName"
          name="ProductFriendlyName"
          placeholder="5533222"
          type="text"
          value={productFriendlyNameValue}
          onChange={(e) => {
            setProductFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsId(e.target.value);
            let verticalFriendlyName = verticalsIdList.filter((val) => {
              return val.assetVerticalId === e.target.value;
            });
            setAssetVerticalFriendlyNameValue(
              verticalFriendlyName[0].friendlyName
            );
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Sector not found</h4>
          )}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalfriendlyname">
          {" "}
          Asset Vertical Friendly Name:
        </InputLabel>
        <Input
          id="assetverticalfriendlyname"
          name="assetverticalfriendlyname"
          type="text"
          value={assetVerticalFriendlyNameValue}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel>Category Type*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel>Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel>Model ID</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => {
            setSelectedModel(event.target.value);
            let modelFriendlyName = modelList.filter((val) => {
              return val.modelid === event.target.value;
            });
            setModelFriendlyName(modelFriendlyName[0].modelfriendlyname);
          }}
          label="Select Model"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelfriendlyname">
          {" "}
          Model Friendly Name:
        </InputLabel>
        <Input
          id="modelfriendlyname"
          name="modelfriendlyname"
          type="text"
          value={getModelFriendlyName}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="productcost"> Product Cost:</InputLabel>
        <Input
          id="productcost"
          name="productcost"
          type="text"
          value={getProductCostValue}
          onChange={(e) => {
            setProductCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="unit"> Unit:</InputLabel>
        <Input
          id="unit"
          name="unit"
          type="text"
          value={getUnitValue}
          onChange={(e) => {
            setUnitValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="discount"> Discount:</InputLabel>
        <Input
          id="discount"
          name="discount"
          type="text"
          value={getDiscountValue}
          onChange={(e) => {
            setDiscountValueHandler(e);
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="discountprice"> Discounted Price:</InputLabel>
        <Input
          disabled
          id="discountprice"
          name="discountprice"
          type="text"
          value={getDiscountedPrice}
          //   onChange={(e) => {
          //     setDiscountedPrice(e.target.value);
          //   }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true} className="loginFields">
        <InputLabel htmlFor="country"> Country:</InputLabel>
        <Input
          id="country"
          name="country"
          type="text"
          value={getCountery}
          onChange={(e) => {
            setCountery(e.target.value);
          }}
        />
      </FormControl>
      <Box className="buttonBox">
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => createProductHandler()}
        >
          Create
        </Button>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="error"
          className="registerBtn"
          type="submit"
          onClick={() => {
            setShowAssetPopup(!assetPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );

  /* EOD Create Product */

  /* ** Create Product Item ** */
  const createProductItemHandler = (product) => {
    setProductItemModel(!getProductItemModel);
    setItemConsumerAdminId(product.consumeradminid);
    setItemBusinessId(product.businessid);
    setItemProductId(product.productid);
  };

  const submitProductItemHandler = () => {
    var productItem = {
      consumeradminid: getItemConsumerAdminId,
      businessid: getItemBusinessId,
      productid: getItemProductId,
      productitems: getItemProductItems,
      assetid: "null",
      assetriendlyname: "null",
    };
    const prodItemStr = JSON.stringify(productItem);
    MainService.addModelProductItem(parentId, prodItemStr, getItemBusinessId, username)
      .then((res) => {
        setProductItemModel(!getProductItemModel);
        getAllModelProducts();
        return NotificationManager.success("product item stored");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* ** EOD Create Product Item ** */

  /*** Delete Product ***/
  const deleteProductData = (adminid, businessid, productid) => {
    setDeleteProduct(!getDeleteProduct);
    setDeleteProductBusinessId(businessid);
    setDeleteProductId(productid);
    setDeleteAdminId(adminid);
  };

  const productDeleteHandler = () => {
    MainService.deleteModelProductItem(
      parentId,
      getDeleteProductBusinessId,
      getDeleteProductId,
      username
    )
      .then((res) => {
        setDeleteProduct(!getDeleteProduct);
        getAllModelProducts();
        return NotificationManager.success("product deleted");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /*** EOD Delete Product ***/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /** Generate QR **/
  const generateQRHandler = (product) => {
    const qrData = {
      iwidth: Number(300),
      fcolor: "000",
      bcolor: "FFFF",
      cadminid: product.consumeradminid,
      assetverticalid: product.assetverticalid,
      avname: product.assetverticalname,
      acategory: product.category,
      atype: product.legacyassettype,
      modelid: product.modelid,
      productid: product.productid,
      link: "https://www.gbrservice.com/",
      bname: product.businessid,
    };
    const qrString = JSON.stringify(qrData);
    MainService.generateQRCodeForProduct(parentId, qrString, userType, username)
      .then((res) => {
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Generate QR **/

  /** Update Image Url */
  const updateImageUrlHandler = (product) => {
    setImageUpdateModel(!getImageUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateImageClick = () => {
    const imageUrl = {
      imagelink: getUpdateImageURL,
    };
    const data = JSON.stringify(imageUrl);

    MainService.updateProductImageURL(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setImageUpdateModel(!getImageUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Image Url */

  /** Update Certificate */
  const updateCertificateUrlHandler = (product) => {
    setCertificateUpdateModel(!getCertificateUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateCertificateClick = () => {
    const certificateUrl = {
      qalink: getUpdateImageURL,
    };
    const data = JSON.stringify(certificateUrl);

    MainService.updateProductCertificateURL(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setCertificateUpdateModel(!getCertificateUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Certificate */

  /** Update QR Code */
  const updateQRCodeUrlHandler = (product) => {
    setQRUpdateModel(!getQRUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateQRClick = () => {
    const qrUrl = {
      qrcodelink: getUpdateImageURL,
    };
    const data = JSON.stringify(qrUrl);

    MainService.updateProductQRURL(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setQRUpdateModel(!getQRUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update QR Code */

  /** Update Status */
  const updateStatusHandler = (product) => {
    setStatusUpdateModel(!getStatusUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateStatusClick = () => {
    const statusUpdate = {
      status: getUpdateImageURL,
    };
    const data = JSON.stringify(statusUpdate);

    MainService.updateStatusOfProduct(
      username,
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data
    )
      .then((res) => {
        setStatusUpdateModel(!getStatusUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Status */

  /** Update Discount Price */
  const updateDiscountHandler = (product) => {
    setDiscountUpdateModel(!getDiscountUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
    setDiscountOriginalPrice(product.productcost);
  };

  const setDiscountHandler = (e) => {
    setDiscount(e.target.value);
    const price = Math.ceil(
      getDiscountOriginalPrice -
        (getDiscountOriginalPrice * e.target.value) / 100
    );
    setDiscountNewPrice(price);
  };

  const updateDiscountClick = () => {
    const updateDiscount = {
      discount: getDiscount,
      discountedprice: getDiscountNewPrice,
    };
    const data = JSON.stringify(updateDiscount);

    MainService.updateProductDiscountedPrice(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setDiscountUpdateModel(!getDiscountUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  /** EOD Update Discount Price */

  /** Update Product Cost */
  const updateProductCostHandler = (product) => {
    setProductCostUpdateModel(!getProductCostUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateProductCostClick = () => {
    const prodCost = {
      productcost: Number(getUpdateImageURL),
    };
    const data = JSON.stringify(prodCost);

    MainService.updateProductCost(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setProductCostUpdateModel(!getProductCostUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Product Cost */

  /** Update Product Rating */
  const updateRatingHandler = (product) => {
    setRatingUpdateModel(!getRatingUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const labels = {
    1: "Poor",
    2: "Ok",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  const updateRatingClick = () => {
    const rating = {
      ratings: Number(value),
    };
    const data = JSON.stringify(rating);

    MainService.updateProductRating(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setRatingUpdateModel(!getRatingUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Product Rating */

  /** Update Item Count */
  const updateItemCountHandler = (product) => {
    setItemCountUpdateModel(!getItemCountUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateItemCountClick = () => {
    const countItem = {
      itemcount: Number(getUpdateImageURL),
    };
    const data = JSON.stringify(countItem);

    MainService.updateProductItemCount(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setItemCountUpdateModel(!getItemCountUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Update Item Count */

  /** Decrement, Increment Item Count */
  const incrementItemCountHandler = (product) => {
    MainService.incrementItemCount(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const decrementItemCountHandler = (product) => {
    MainService.decrementItemCount(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /**EOD Decrement and Increment */

  /** Update Product History URL */
  const productHistoryURLHandler = (product) => {
    setHistoryURLUpdateModel(!getHistoryURLUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateHistoryURLClick = () => {
    const historyUrl = {
      historylink: getUpdateImageURL,
    };
    const data = JSON.stringify(historyUrl);

    MainService.updateProductHistoryURL(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setHistoryURLUpdateModel(!getHistoryURLUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /**EOD Update Product History URL */

  /**Update Lab Certificate */
  const updateLabCertificateHandler = (product) => {
    setLabCertificateUpdateModel(!getLabCertificateUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const updateLabCertificateClick = () => {
    const labCertificate = {
      lablink: getUpdateImageURL,
    };
    const data = JSON.stringify(labCertificate);

    MainService.updateLabCertificate(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setLabCertificateUpdateModel(!getLabCertificateUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /**EOD Lab Certificate */

  /** Product Video */
  const productVideoHandler = (product) => {
    setVideoUpdateModel(!getVideoUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };

  const productVideoUrlClick = () => {
    const productVideo = {
      videolink: getUpdateImageURL,
    };
    const data = JSON.stringify(productVideo);

    MainService.productVideo(
      parentId,
      getUpdateImageBusinessId,
      getUpdateImageProductId,
      data,
      username
    )
      .then((res) => {
        setVideoUpdateModel(!getVideoUpdateModel);
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Product Video */

  /** Product Items */
  const productItemsHandler = (product) => {
    MainService.getProductItems(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        setProductItemUpdateModel(true);
        setProductItemsStr(res.productitems);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /**EOD Product Items*/

  /** Delete Item */
  const deleteItemHandler = (item) => {
    MainService.deleteProductItem(
      parentId,
      item.businessid,
      item.productid,
      item.productitems,
      username
    )
      .then((res) => {
        productItemsHandler(item);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /** EOD Delete Item */

  /** Create Sub-Category */
  const createSubCategoryModel = (data) => {
    setSubCategoryModel(!getSubCategoryModel);
  };

  const closeSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };

  const deleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };

  const closeDeleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  /** EOD Create Sub-Category */

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Fab
          color="primary"
          size={iconWidthRes ? "small" : "large"}
          aria-label="add"
          title="List"
          className={classes.fab}
          onClick={() => addProductsClickHandler()}
        >
          <AddIcon></AddIcon>
        </Fab>

        <Box sx={{ mb: "1rem", width: "100%" }}>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryModel();
            }}
          >
            Sub-Categories
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(e) => {
                  setSelectedVerticalsId(e.target.value);
                }}
                label="Select Verticals"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
                md: "auto",
              },
              my: "1rem",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                getAllModelProducts("Grid");
              }}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                getAllModelProducts("List");
              }}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            ></Button>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
                md: "auto",
              },
              mb: "2rem",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sub-Category
              </InputLabel>
              <Select
                id="Sub-Category"
                value={getSubCategoryApiFilter}
                onChange={(e) => {
                  getAllModelProductsOfSubCaegory(e.target.value);
                }}
                label="Sub-Category"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {getSubCategoriesList.length > 0 ? (
                  getSubCategoriesList.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj.contentsubcategoryname}
                    >
                      {idObj.contentsubcategoryname}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sub-Categories Not Found</h4>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box>
          {getListView === true ? (
            <Box>
              <Box sx={{ m: "0" }}>
                {isLoading ? (
                  <Box className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                    {getProductsData.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={8}>
                                  <h2 className="cardactiontitle">
                                    ** LIST OF PROCESS PRODUCTS **
                                  </h2>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Business Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Product Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Product Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Discount
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Country
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Category
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                  Action'S
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {getProductsData.length > 0 ? (
                                getProductsData
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((products, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {products.businessid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {products.productid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {products.productcost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {products.discount}
                                        </TableCell>
                                        <TableCell align="center">
                                          {products.country}
                                        </TableCell>
                                        <TableCell align="center">
                                          {products.category}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            type="button"
                                            variant="contained"
                                            onClick={() => {
                                              createProductItemHandler(
                                                products
                                              );
                                            }}
                                          >
                                            Add Item
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            type="button"
                                            variant="contained"
                                            onClick={() => {
                                              // createProductItemHandler(products)
                                            }}
                                          >
                                            QR Code
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Products Not Found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={getProductsData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Products Not Found.</h4>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : null}

          {getGridView === true ? (
            <Box sx={{ m: "0" }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              {isLoading ? (
                <Box className="cardStyleForDashboardItems">
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <h2 className="cardactiontitle">
                    ** LIST OF PROCESS PRODUCTS **
                  </h2>
                  {getProductsData.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getProductsData.map((product, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Vertical Id:</b>
                              {product.assetverticalid}
                            </p>
                            <p>
                              <b>Asset Vertical Name:</b>
                              {product.assetverticalname}
                            </p>
                            <p>
                              <b>Asset Type:</b>
                              {product.legacyassettype}
                            </p>
                            <p>
                              <b>Model Id:</b>
                              {product.modelid}
                            </p>
                            <p>
                              <b>Asset Model Friendly Name:</b>
                              {product.modelfriendlyname}
                            </p>
                            <p>
                              <b>Business Id:</b>
                              {product.businessid}
                            </p>
                            <p>
                              <b>Product Model Id:</b>
                              {product.productid}
                            </p>
                            <p>
                              <b>Product Model Friendly Name:</b>
                              {product.productfriendlyname}
                            </p>
                            <p>
                              <b>Product Model Price:</b>
                              {product.productcost}
                            </p>
                            <p>
                              <b>Discount:</b>
                              {product.discount}
                            </p>
                            <p>
                              <b>Discounted Price:</b>
                              {product.discountedprice}
                            </p>
                            <p>
                              <b>Unit:</b>
                              {product.unit}
                            </p>
                            <p>
                              <b>Country:</b>
                              {product.country}
                            </p>
                            <p>
                              <b>Category:</b>
                              {product.category}
                            </p>
                            <p>
                              <b>Item Count:</b>
                              {product.itemcount}
                            </p>
                            <p>
                              <b>Product Category:</b>
                              {product.categoryname}
                            </p>
                            <p>
                              <b>Subcategory:</b>
                              {product.contentsubcategory}
                            </p>
                          </span>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                createProductItemHandler(product);
                              }}
                            >
                              Add Item
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                deleteProductData(
                                  product.consumeradminid,
                                  product.businessid,
                                  product.productid
                                );
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            {(() => {
                              if (
                                product.productqrcodeurl === "" ||
                                product.productqrcodeurl === "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      generateQRHandler(product);
                                    }}
                                  >
                                    QR Code
                                  </Button>
                                );
                              }
                            })()}
                            {(() => {
                              if (
                                !product.productimageurl ||
                                product.productimageurl === "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      uploadContentHandler(product);
                                    }}
                                  >
                                    Content
                                  </Button>
                                );
                              }
                            })()}
                          </Box>
                          <Box className="buttonBox">
                            {(() => {
                              if (
                                !product.qacertificateurl ||
                                product.qacertificateurl === "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      uploadCertificateHandler(product);
                                    }}
                                  >
                                    Certificate
                                  </Button>
                                );
                              }
                            })()}
                            {(() => {
                              if (
                                !product.productqrcodeurl ||
                                product.productqrcodeurl !== "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                  >
                                    <a
                                      href={`${product.productqrcodeurl}`}
                                      target="_blank"
                                      download
                                    >
                                      Download QR
                                    </a>
                                  </Button>
                                );
                              }
                            })()}
                          </Box>
                          <Box className="buttonBox">
                            {(() => {
                              if (
                                !product.productimageurl ||
                                product.productimageurl !== "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      updateImageUrlHandler(product);
                                    }}
                                  >
                                    Image Update
                                  </Button>
                                );
                              }
                            })()}
                            {(() => {
                              if (
                                !product.qacertificateurl ||
                                product.qacertificateurl !== "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      updateCertificateUrlHandler(product);
                                    }}
                                  >
                                    Certificate Update
                                  </Button>
                                );
                              }
                            })()}
                          </Box>
                          <Box className="buttonBox">
                            {(() => {
                              if (
                                !product.productqrcodeurl ||
                                product.productqrcodeurl !== "null"
                              ) {
                                return (
                                  <Button
                                    className="buttonsize"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      updateQRCodeUrlHandler(product);
                                    }}
                                  >
                                    QR Code
                                  </Button>
                                );
                              }
                            })()}
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateStatusHandler(product);
                              }}
                            >
                              Status
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateProductCostHandler(product);
                              }}
                            >
                              Cost
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateDiscountHandler(product);
                              }}
                            >
                              Discount
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateRatingHandler(product);
                              }}
                            >
                              Rating
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateItemCountHandler(product);
                              }}
                            >
                              Item Count
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                incrementItemCountHandler(product);
                              }}
                            >
                              IncrementItem
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                decrementItemCountHandler(product);
                              }}
                            >
                              DecrementItem
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                productHistoryURLHandler(product);
                              }}
                            >
                              History
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateLabCertificateHandler(product);
                              }}
                            >
                              Lab Certificate
                            </Button>
                          </Box>
                          <Box className="buttonBox">
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                productVideoHandler(product);
                              }}
                            >
                              Video
                            </Button>
                            <Button
                              className="buttonsize"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                productItemsHandler(product);
                              }}
                            >
                              Items
                            </Button>
                          </Box>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Model Products Not Found.</h4>
                  )}
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>

      <UploadContent
        props={getUploadcontentProps}
        modelShow={getUploadContentModel}
        parent={parentId}
        closeModel={closeContentHandler}
        reloadComponent={getAllModelProducts}
      />

      <UploadCertificate
        props={getUploadCertificateProps}
        modelShow={getUploadCertificateModel}
        parent={parentId}
        closeModel={closeContentCertificateHandler}
        reloadComponent={getAllModelProducts}
      />

      <ContentSubCategoryCreate
        props={getCreateSubCategoryProps}
        modelShow={getSubCategoryModel}
        closeModel={closeSubCategoryModel}
        reloadComponent={subCategoryList}
      />

      <DeleteSubCategoryOfContent
        subCategories={getSubCategoriesList}
        modelShow={getDeleteSubCategoryModel}
        closeModel={closeDeleteSubCategoryModel}
        reloadComponent={subCategoryList}
        createSubCategoryModel={createSubCategoryModel}
      />

      {/* Create Product Item */}
      <Dialog
        fullScreen={fullScreen}
        open={getProductItemModel}
        //   onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Product Item"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="consumeradminid">
              {" "}
              Consumer Admin Id:
            </InputLabel>
            <Input
              disabled
              id="consumeradminid"
              name="consumeradminid"
              type="text"
              value={getItemConsumerAdminId}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth={true}>
            <InputLabel htmlFor="businessid">Business Id</InputLabel>
            <Input
              disabled
              id="businessid"
              name="businessid"
              type="text"
              value={getItemBusinessId}
            ></Input>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="productid">Product Id :</InputLabel>
            <Input
              id="productid"
              name="productid"
              type="text"
              value={getItemProductId}
            ></Input>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="productitems">Product Item :</InputLabel>
            <Input
              id="productitems"
              name="productitems"
              type="text"
              value={getItemProductItems}
              onChange={(e) => {
                setItemProductItems(e.target.value);
              }}
            ></Input>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              submitProductItemHandler();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setProductItemModel(!getProductItemModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Create Product Item */}

      {/* Delete Product */}
      <Dialog
        fullScreen={fullScreen}
        open={getDeleteProduct}
        //   onClose={handleClose}
        aria-labelledby="deleteContentDialog"
      >
        <DialogTitle id="deleteContentDialog">{"Delete Content"}</DialogTitle>
        <DialogContent>Are you sure want to delete?</DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              productDeleteHandler();
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDeleteProduct(!getDeleteProduct);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Delete Product */}

      {/* Image Update */}
      <Dialog
        fullScreen={fullScreen}
        open={getImageUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Update Image"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="imgLink">Image Link:</InputLabel>
            <Input
              id="imgLink"
              name="imgLink"
              type="text"
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateImageClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setImageUpdateModel(!getImageUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Image Update */}

      {/* Certificate Update */}
      <Dialog
        fullScreen={fullScreen}
        open={getCertificateUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Certificate"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="imgLink">Certificate Link:</InputLabel>
            <Input
              id="imgLink"
              name="imgLink"
              type="text"
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateCertificateClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCertificateUpdateModel(!getCertificateUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Certificate Update */}

      {/* QR Update */}
      <Dialog
        fullScreen={fullScreen}
        open={getQRUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update QR Code"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="imgLink">QR Code Link:</InputLabel>
            <Input
              id="imgLink"
              name="imgLink"
              type="text"
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateQRClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setQRUpdateModel(!getQRUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD QR Update */}

      {/* Update Status */}
      <Dialog
        fullScreen={fullScreen}
        open={getStatusUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Status"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="selectcategory">Select Status:</InputLabel>
            <Select
              id="selectcategory"
              name="selectcategory"
              type="text"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            >
              <MenuItem key="Product-available" value="Product-available">
                Product-available
              </MenuItem>
              <MenuItem key="Not-Available" value="Not-Available">
                Not-Available
              </MenuItem>
              <MenuItem key="Verified" value="Verified">
                Verified
              </MenuItem>
              <MenuItem key="Non-Verified" value="Non-Verified">
                Non-Verified
              </MenuItem>
              <MenuItem key="FFM" value="FFM">
                FFM
              </MenuItem>
              <MenuItem key="Coming-Soon" value="Coming-Soon">
                Coming-Soon
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateStatusClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setStatusUpdateModel(!getStatusUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update Status*/}

      {/* Discount Update */}
      <Dialog
        fullScreen={fullScreen}
        open={getDiscountUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Discount"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="orgprice">Original Price:</InputLabel>
            <Input
              id="orgprice"
              name="orgprice"
              type="text"
              value={getDiscountOriginalPrice}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="orgprice">Discount:</InputLabel>
            <Input
              id="orgprice"
              name="orgprice"
              type="text"
              value={getDiscount}
              onChange={(e) => {
                setDiscountHandler(e);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="orgprice">Discounted Price:</InputLabel>
            <Input
              id="orgprice"
              name="orgprice"
              type="text"
              value={getDiscountNewPrice}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateDiscountClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDiscountUpdateModel(!getDiscountUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Discount Update */}

      {/* Update Product Cost */}
      <Dialog
        fullScreen={fullScreen}
        open={getProductCostUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Product Cost"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="imgLink">Update Product Cost:</InputLabel>
            <Input
              id="imgLink"
              name="imgLink"
              type="text"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateProductCostClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setProductCostUpdateModel(!getProductCostUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update Product Cost*/}

      {/* Update Rating */}
      <Dialog
        fullScreen={fullScreen}
        open={getRatingUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Rating"}
        </DialogTitle>
        <DialogContent>
          <Rating
            name="hover-feedback"
            value={value}
            precision={1}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {value !== null && (
            <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateRatingClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setRatingUpdateModel(!getRatingUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update Rating */}

      {/* Update Item Count */}
      <Dialog
        fullScreen={fullScreen}
        open={getItemCountUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Item Count"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="itemcount">Update Item Count:</InputLabel>
            <Input
              id="itemcount"
              name="itemcount"
              type="number"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateItemCountClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setItemCountUpdateModel(!getItemCountUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update Item Count*/}

      {/* Update History URL */}
      <Dialog
        fullScreen={fullScreen}
        open={getHistoryURLUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update History URL"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="historyurl">Update History URL:</InputLabel>
            <Input
              id="historyurl"
              name="historyurl"
              type="text"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateHistoryURLClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setHistoryURLUpdateModel(!getHistoryURLUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update History URL*/}

      {/* Update Lab Certificate */}
      <Dialog
        fullScreen={fullScreen}
        open={getLabCertificateUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Lab Certificate"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="labcertificate">
              Lab Certificate URL:
            </InputLabel>
            <Input
              id="labcertificate"
              name="labcertificate"
              type="text"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              updateLabCertificateClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setLabCertificateUpdateModel(!getLabCertificateUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD Update Lab Certificate*/}

      {/* Product Video */}
      <Dialog
        fullScreen={fullScreen}
        open={getVideoUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Product Video"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="labcertificate">Product Video URL:</InputLabel>
            <Input
              id="labcertificate"
              name="labcertificate"
              type="text"
              value={getUpdateImageURL}
              onChange={(e) => {
                setUpdateImageURL(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              productVideoUrlClick();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setVideoUpdateModel(!getVideoUpdateModel);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD  Product Video*/}

      {/* Product Items */}
      <Dialog
        fullScreen={fullScreen}
        open={getProductItemUpdateModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Product Items"}
        </DialogTitle>
        <DialogContent>
          <div className="cardStyleForDashboardItems">
            {getProductItemsStr.length > 0 ? (
              <div className="cardStyleForDashboardItems">
                {getProductItemsStr.map((item, index) => (
                  <div key={index} className="cardStyleForDashboardItemsBody">
                    <span>
                      <p>
                        <b>Admin:</b>
                        {item.consumeradminid}
                      </p>
                      <p>
                        <b>Asset Developer:</b>
                        {item.businessid}
                      </p>
                      <p>
                        <b>Product Id:</b>
                        {item.productid}
                      </p>
                      <p>
                        <b>Product Item:</b>
                        {item.productitems}
                      </p>
                    </span>
                    <div style={{ margin: "5px", width: "100%" }}>
                      <Button
                        style={{ margin: "5px", width: "50%" }}
                        type="button"
                        variant="contained"
                        onClick={() => {
                          deleteItemHandler(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <h4>Product Item'S Not Found.</h4>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setProductItemUpdateModel(!getProductItemUpdateModel);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* EOD  Product Items*/}

      <CustomPopup
        closeButton={closeAgreementModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={agreementBody}
        title={titleAgreement}
      />
    </>
  );
}
