import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import sha256 from "sha256";
import {
  NotificationManager,
} from "react-notifications";

// import MainService from "../../Main/Common/MainService";
import MainService from "../../Main/components/Main/Common/MainService";

import "./SigninPageComponent.css";

export default class SigninPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loginObj: {
        enterpriseId: "",
        username: "",
        password: "",
      },
    };
  }
  doLogin = (evt) => {
    evt.preventDefault();

    if (!this.state.loginObj.orgid) {
      NotificationManager.warning("Please enter Org Id.");
      return;
    }
    if (!this.state.loginObj.username) {
      NotificationManager.warning("Please enter Username/Email");
      return;
    }
    if (!this.state.loginObj.password) {
      NotificationManager.warning("Please enter Password.");
      return;
    }

    if (this.props.match.params.code === 10) {
      NotificationManager.error("Invalid access type.");
      return;
    }

    const { username, password } = this.state.loginObj;
    const encPwd = sha256(password);
  //  const encPwd = password;
    MainService.doLogin(username, encPwd)
      .then((res) => {
        MainService.getConsumerUserInfo(username).then(() => {
          this.setState({
            isLoading: false,
          });
          NotificationManager.success("Login successfully.");
          setTimeout(() => {
            window.location.href = "/main";
          }, 1000);
        }).catch((e) => {
          this.setState({
            isLoading: false,
          });
          return NotificationManager.error(e);
        })
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        return NotificationManager.error(e);
      });
  };
  goToForgotPwd = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmerforgot"
    );
  };
  goToSignup = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmersignup"
    );
  };
  goToHome = (evt) => {
    this.props.history.push("/");
  };
  onFieldChange = (evt) => {
    let loginObj = this.state.loginObj;
    loginObj[evt.target.name] = evt.target.value;
    this.setState({
      loginObj: loginObj,
    });
  };
  render() {
    const _code = parseInt(this.props.match.params.code);
    const _class =
      _code === 10
        ? "farmer"
        : "default";

    return (
      <Container maxWidth={false} className={"signinPageContainer " + _class}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={11}
            sm={5}
            md={4}
            lg={3}
            className="loginOuterContainer"
          >
            <div className="loginContainer">
              <form onSubmit={this.doLogin}>
                <h2> Grow Big & Reap </h2>

                {}

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="orgid"> Org Id * </InputLabel>{" "}
                  <Input
                    id="orgid"
                    name="orgid"
                    type="text"
                    value={this.state.loginObj.orgid}
                    startAdornment={
                      <InputAdornment position="start">
                         <AssignmentIndIcon />
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username"> Username * </InputLabel>{" "}
                  <Input
                    id="username"
                    name="username"
                    type="email"
                    value={this.state.loginObj.username}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> face </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="password"> Password * </InputLabel>{" "}
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={this.state.loginObj.password}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> vpn_key </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControlLabel
                  className="rememberMe"
                  control={<Checkbox value="rememberMe" color="primary" />}
                  label="Remember me on this computer"
                />

                <FormControl fullWidth={true}>
                  {this.state.isLoading === false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="submit"
                      disabled={
                        (this.state.username !== "") &
                        (this.state.password !== "")
                          ? false
                          : true
                      }
                    >
                      Sign in
                    </Button>
                  )}{" "}
                  {this.state.isLoading === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className="loginLoader" />
                    </Button>
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToForgotPwd}
                >
                  Forgot Password ?
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToSignup}
                >
                  Not registered ? Click to Signup{" "}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToHome}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  Back Home ?
                </FormControl>
              </form>{" "}
            </div>{" "}
          </Grid>{" "}
        </Grid>

      </Container>
    );
  }
}
