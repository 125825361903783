import React from 'react';
import { CForm, CRow, CCol, CCard, CCardHeader } from '@coreui/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Geocode from 'react-geocode';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfirmModal from '../../../../../Containers/Modal/ConfirmModal';
import { Alert } from '@material-ui/lab';
import MainService from '../../../Common/MainService';
import Map from '../../googlemap/Map';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

const google = window.google;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey('AIzaSyBNr25rzdeU_keu0Lde9DX4Wo_PShuyYEk');

// set response language. Defaults to english.
Geocode.setLanguage('en');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('es');

// Enable or disable logs. Its optional.
Geocode.enableDebug();

// Get address from latidude & longitude.

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

class AddTrip extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      searchValue: undefined,
      address: null,
      map_type: null,
      geo: {},
      polyPath: [],
      marker_position: { lat: 12.9362082, lng: 77.6883844 },
      drawing_type: 'Polygon',
      locationTag: 'school',
      activeGeofence: false,
      isEmpty: true,
      drivers: { users: [] },
      locations: { locList: [] },

      routeData: { routeList: [] },
      routes: [],
      locationPath: {
        errors: {}, //form validation errors.
      },
      selectedRoutes: [],
      vehicles: { assets: [] },
      places: [],
      show: false,
      type: '',
      fromDate: '',
      toDate: '',
      dataSchedule: [],
      open: false,
      message: '',
      editPage: false,
      tenantId: localStorage.getItem('tenantId'),
      location: [],
    };
    this.props = { ...props };
  }

  componentDidMount() {
    this.getLocationInfo();
    this.getRouteInfo();
    this.getDrivers();
    this.getVehicleList();

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.setState({
      fromDate:
        firstDay.getDate() +
        '-' +
        firstDay.getMonth() +
        '-' +
        firstDay.getFullYear(),
      toDate:
        lastDay.getDate() +
        '-' +
        lastDay.getMonth() +
        '-' +
        lastDay.getFullYear(),
    });
  }

  // Fetch All the location
  getLocationInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { locList } = await MainService.getLocationInfo(parentId,username);
    this.setState({ locations: { locList } });
    console.log('locaaaaaaaaaaaaaaaaaaaaaa', this.state.locations);
  };

  //Fetch All Routes
  getRouteInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { routeList } = await MainService.getRouteInfo(parentId, username);
    this.setState({ routeData: { routeList } });
  };

  //Fetch All Drivers
  getDrivers = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { users } = await MainService.getDrivers(username);
    // console.log(, " getDriverszzzzzzzzzzzz")
    this.setState({ drivers: { users } });
    console.log(this.state.drivers, 'getallDrivers...//////');
  };

  //Fetch All Vehicle
  getVehicleList = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { assets } = await MainService.getVehicleList(username);
    this.setState({ vehicles: { assets } });
    console.log(this.state.vehicles, 'getallvehicles....////');
  };

  onSubmit(value) {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ marker_position: { lat, lng }, geo: { lat, lng } });
      },
      (error) => {
        console.error(error);
      }
    );
    this.setState({ searchValue: value });
  }

  getAddress(value) {
    this.setState({ address: value });
  }

  activateGeofence() {
    this.setState({ activeGeofence: !this.state.activeGeofence });
  }

  changeLocationTag(event) {
    const { name, value } = event.target;
    const { routeList } = this.state.routeData;
    const filterData = routeList.filter((item) => item.startLocId === value);

    const hashmap = filterData.reduce((acc, item) => {
      acc[item.routeId] = item;
      return acc;
    }, {});

    if (name === 'end') {
      if (!hashmap[value]) {
        const filteredItem = routeList.find((item) => item.endLocId === value);
        this.setState({
          selectedRoutes: [...this.state.selectedRoutes, filteredItem],
        });

        return filteredItem;
      }
    }
  }

  selectDriver(val) {
    const selectedLoc = { ...this.state.locationPath };
    const selectedDriver = val;
    if (selectedDriver) {
      selectedLoc['username'] = selectedDriver.username;
    } else {
      selectedLoc['username'] = null;
    }

    this.setState({ locationPath: selectedLoc });
  }

  selectVehicle(val) {
    const selectedLoc = { ...this.state.locationPath };
    const selectedVehicle = val;
    if (selectedVehicle) {
      selectedLoc['assetId'] = selectedVehicle.assetId;
    } else {
      selectedLoc['assetId'] = null;
    }
    this.setState({ locationPath: selectedLoc });
    console.log(
      this.state.locationpath,
      'locationPATH///..........................'
    );
  }

  setTime(evt) {
    const date = new Date(evt.target.value);
    const month = date.getMonth() + 1;
    const selectedLoc = { ...this.state.locationPath };
    selectedLoc['startTime'] = evt.target.value;
    selectedLoc['startTimeTemp'] =
      date.getDate() +
      '-' +
      month +
      '-' +
      date.getFullYear() +
      'T' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds() +
      '.000';

    this.setState({ locationPath: selectedLoc });
  }

  submitForm = async (evt) => {
    evt.preventDefault();
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    if (!this.state.selectedRoutes || this.state.selectedRoutes.length === 0) {
      this.setState({ open: true, message: 'Please select a route' });
      return false;
    }

    if (!this.state.selectedRoutes[0]?.routeId) {
      this.setState({ open: true, message: 'Please enter routeId' });
      return false;
    }

    if (!this.state.locationPath.username) {
      this.setState({ open: true, message: 'Please enter driverId' });
      return false;
    }

    if (!this.state.locationPath.startTimeTemp) {
      this.setState({ open: true, message: 'Please enter startTime' });
      return false;
    }

    // if (!this.state.locationPath.endTime) {
    //   this.setState({ open: true, message: "Please enter endTime" });
    //   return false;
    // }

    if (!this.state.locationPath.assetId) {
      this.setState({ open: true, message: 'Please enter VehicleId' });
      return false;
    }

    const data = {
      driverId: this.state.locationPath.username,
      geoFenceDist: 0,
      routeId: this.state.selectedRoutes[0]?.routeId,
      startTime: this.state.locationPath.startTimeTemp,
      vehicleId: this.state.locationPath.assetId,
    };

    const dataa = await MainService.addTripApi(parentId, username, data);
    this.props.tripHandler();
  };

  getLatLng(lat, lng) {
    let places = [...this.state.places];
    console.log(places);
    places.splice(places.length - 2, 0, { latitude: lat, longitude: lng });
    console.log(places);
    this.setState({ places });
  }

  onPlaceSelected = (place) => {
    const addressArray = place.address_components;
    let address = '';
    addressArray.map((each_name) => {
      address += ' ' + each_name.long_name;
    });

    let currentPath = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      this.setState({
        address: results[0].formatted_address,
        searchValue: address,
      });
    });

    this.setState({
      marker_position: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
      geo: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    });
  };

  closeModal() {
    this.setState({ show: false });
  }

  getLocationHandler = (props) => {
    console.log('addtripppppppppppppp..............');
  };

  render() {
    const { open, message } = this.state;
    return (
      <>
        <Snackbar open={open}>
          <Alert severity='error'>{message}</Alert>
        </Snackbar>

        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '1rem',
              mt: 2,
              '&>*': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <Box>
              <Button variant='text' onClick={() => this.props.routeHandler()}>
                Cancel
              </Button>
              <Button variant='outlined' onClick={this.submitForm.bind(this)}>
                Submit
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              mt: 2,
              gap: 2,
              flexWrap: 'wrap',
              '&>*': { flex: '0 0 30%', maxWidth: '33%' },
            }}
          >
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  onChange={this.changeLocationTag.bind(this)}
                  name='start'
                  value={this.state.locationPath.start}
                  inputProps={{
                    id: 'age-native-required1',
                  }}
                >
                  <option value=''>Start Location</option>
                  {this.state.locations.locList.map((loc) => (
                    <option value={loc.locId}>{loc.tagName}</option>
                  ))}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  onChange={this.changeLocationTag.bind(this)}
                  name='end'
                  value={this.state.locationPath.end}
                  inputProps={{
                    id: 'age-native-required',
                  }}
                >
                  <option value=''>End Location</option>
                  {this.state.locations.locList.map((loc) => (
                    <option value={loc.locId}>{loc.tagName}</option>
                  ))}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>

            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  value={this.state.locationPath.routeId}
                  onChange={this.changeLocationTag.bind(this)}
                  name='routeId'
                  inputProps={{
                    id: 'age-native-required',
                  }}
                >
                  <option value=''>Route List ---</option>
                  {this.state.selectedRoutes.map((routes) => (
                    <option value={routes?.routeId}>{routes?.routeId}</option>
                  ))}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <TextField
                  id='datetime-local'
                  label='Start Time'
                  type='datetime-local'
                  name='start'
                  onChange={this.setTime.bind(this)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.locationPath.startTime}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <TextField
                  id='datetime-local'
                  label='End Time'
                  type='datetime-local'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <Autocomplete
                  id='combo-box-demo'
                  options={this.state.drivers.users}
                  getOptionLabel={(option) => option.username}
                  onChange={(event, value) => this.selectDriver(value)}
                  value={
                    this.state.drivers.users.find(
                      (v) => v.username === this.state.locationPath.username
                    ) || {}
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Driver'
                      variant='outlined'
                    />
                  )}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <Autocomplete
                  id='combo-box-demo'
                  options={this.state.vehicles.assets}
                  getOptionLabel={(option) => option.assetId}
                  name='vehicleId'
                  onChange={(event, value) => this.selectVehicle(value)}
                  value={
                    this.state.vehicles.assets.find(
                      (v) => v.assetId === this.state.locationPath.assetId
                    ) || {}
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Vehicle'
                      variant='outlined'
                    />
                  )}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>

            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button variant='outlined'>View Schedule</Button>
            </Box> */}
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={this.activateGeofence.bind(this)}
                    checked={this.state.activeGeofence}
                    sx={{ paddingLeft: '-5px' }}
                  />
                }
                label='Geofence'
                labelPlacement='start'
              />
            </Box>
          </Box>

          <div style={{ marginTop: '2px', border: '1px solid' }}>
            <Map
              getLocationHandler={this.getLocationHandler}
              routeLocationData={this.state.location}
              tripRoute={this.state.selectedRoutes[0]?.geoPath}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: '65vh' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              isMarkerShown
              mapHeight={{
                width: '100%',
                height: '47vh',
              }}
            />
          </div>
        </Box>
      </>
    );
  }
}
export default AddTrip;
