import React, { lazy, useState, useEffect, Component } from 'react';
import MatTable from '../../../../../Containers/Table/Table';
import SearchBar from '../../../../../Containers/SearchBar/SearchBar';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

import MainService from '../../../Common/MainService';
import './location.css';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Button,
  Fab,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import { unset } from 'lodash';

const fields = ['', 'ID', 'Tag', 'Address', 'Geo-Coordinates', 'Created'];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: 'white',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  color: 'black',
  p: 4,
};

const LocationManagement = ({ locHandler }) => {
  const [displayLocation, setDisplayLocation] = useState(false);
  const [locations, setLocations] = useState({ locList: [] });
  const [settings_modal, setSettings_modal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selected_items, setSelected_items] = useState('');
  const [visible, setVisible] = useState(10);
  const [viewLocation, setViewLocation] = useState(false);
  const [viewRoute, setViewRoute] = useState(false);
  const [viewTrips, setViewTrips] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);

  useEffect(() => {
    getLocationInfo();
  }, []);

  const findItem = () => {
    console.log('item');
    const items = locations?.locList;
    const filterData = items.filter((item) => item.locId !== selected_items);
    setLocations({ locList: filterData });
    setOpen(false);
  };

  const delLocation = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    let selectedItems = selected_items;
    console.log('deletereeeee', selectedItems);
    const data = await MainService.delLocation(
      parentId,
      username,
      selectedItems
    );
    console.log('delete item data', data);
    if (data.status === 200) {
      findItem();
    }
    // console.log(data, "heloooooooooooooo////////////////////");
  };

  const onSelect = (item) => {
    console.log('onselect********** ', item);
    if (selected_items === item) {
      setSelected_items('');
    } else {
      setSelected_items(item);
    }
  };

  const getLocationInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { locList, locCount } = await MainService.getLocationInfo(
      parentId,
      username
    );

    locList.map((item) => {
      item.geoLoc =
        'Latitude:' +
        item.geoLocation?.lat +
        ' ' +
        'Longitude: ' +
        item.geoLocation?.lng;
      item.fullAddress = item.address?.city;
    });
    setLocations({ locCount, locList });
  };

  const loadMoreLoc = () => {
    setVisible(visible + 10);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === 'delete') {
      setOpen(true);
      console.log('itemssssssssssss', this.state);
    }
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <SearchBar
          placeholder={'Search Location'}
          // onSubmit={this.getSearchLocation.bind(this)}
        />
        <Box>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='outlined'
            disabled={selected_items === ''}
          >
            ACTION
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <MatTable
          fields={fields}
          rows={locations.locList.slice(0, visible)}
          keys={[
            'locId',
            'tagName',
            'fullAddress',
            'geoLoc',
            'lastModifiedDate',
          ]}
          total={locations.locCount}
          onSelect={onSelect}
          unique='locId'
          selected={selected_items}
          change_page={getLocationInfo}
        />
      </Box>
      <Box
        sx={{
          marginTop: '1rem',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <Button
          onClick={loadMoreLoc}
          sx={{
            color: '#fff',
            background: '#1976d2',
            ':hover': {
              bgcolor: '#167add',
              color: '#fff',
            },
          }}
        >
          Load More{' '}
        </Button>
        <Fab
          color='primary'
          size={'large'}
          aria-label='add'
          cursor='pointer'
          title='Add Location'
          variant='outlined'
          onClick={() => locHandler()}
          // className={classes.myStyle}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Modal
        open={open}
        // onClose={this.setState({ open: false })}
        onClose={closeModelHandler}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />

          <Box sx={{ marginTop: '0.5rem', display: 'flex', gap: 1 }}>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={delLocation}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LocationManagement;
