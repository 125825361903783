import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import "react-awesome-slider/dist/styles.css";
import "./BusinessPartner.css";

import step1 from "../../images/pstep1.png";
import step2 from "../../images/pstep2.png";
import step3 from "../../images/pstep3.png";




export default class BusinessPartnerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedSlide: 0,
      phoneNumber: "",
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (prevProps.sliderRef === this.props.sliderRef) return;
    setTimeout(this.showSliderContent, 300);
    if (this.state.selectedSlide === this.props.selectedSlide) return;
    this.setState({ selectedSlide: this.props.selectedSlide });
  }

  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }
  stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Partner as business entity opens an account.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy:
        "Login and manage their respective outlets (Farms and Processing Units) and their staff.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Created staff-members login and perform the assigned jobs.",
      img: step3,
    },
  ];
  render() {
    return (
      <Container className="fullWidthContainer">
        <HeaderComponent />

       
        <FooterComponent />
      </Container>
    );
  }
}