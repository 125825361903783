import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import { Row, Col, Container } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import { NotificationManager } from "react-notifications";
import { Button } from "@material-ui/core";
import { FiThumbsUp } from "react-icons/fi";
import SearchBar from "material-ui-search-bar";
import "./InputSupplies.css";

class InputSupplies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPageprod: 3,
      offsetprod: 0,
      prodPageCount: 1,
      currentPageprod: 0,
      inputSuppliesData: [],
      filteredinputSuppliesData: [],
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      productOwnsers: [],
      suppliesidVlaue: "",
      farmgridsearched: "",
      filterIdValue: "",
      userTypes: ["FOOD-PROCESSOR", "AssetDeveloper", "Broker", "AHSP"],
      subCategoriesList: [],
    };
  }

  componentDidMount() {
    this.getProductCategory();
    this.getUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.suppliesidVlaue) return;
    if (prevState.suppliesidVlaue !== this.state.suppliesidVlaue) {
      this.getAllInputSupplies();
    }
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.getContentCreators(this.state.userTypes[i]);
    }
  }

  getContentCreators(userType) {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${userType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState((prevState) => ({
          productOwnsers: [...prevState.productOwnsers, result.users].flatMap(
            (num) => num
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getProductCategory() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const inputCatID = result.categoryList.filter((data) => {
          return data.categoryname === "INPUT-SUPPLIES";
        });
        this.setState({
          suppliesidVlaue: inputCatID[0].categoryid,
        });
        this.getCategorySubcategories(inputCatID[0].categoryid);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getAllInputSupplies() {
    this.setState({
      productOwnsers: "",
      subCategoriesList: "",
    });
    fetch(
      `${window.appConfig.getProductsBasedonCategoryId}${window.appConfig.Relam}${this.state.suppliesidVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {

        this.setState({
          inputSuppliesData: result.products,
          filteredinputSuppliesData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
        this.getUsers();
        this.getCategorySubcategories(this.state.suppliesidVlaue);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  supplierUserProducts(Val) {
    this.setState({
      subCategoriesList: "",
    });
    this.getCategorySubcategories(this.state.suppliesidVlaue);
    fetch(
      `${window.appConfig.getProductsBasedonAdminUser}${window.appConfig.Relam}${Val}/${this.state.suppliesidVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          inputSuppliesData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  /* PRODUCTS FILTER BASED ON THE Legacy Asset Type */
  requestFarmGridSearch(searchedVal) {
    const filteredRows = this.state.filteredinputSuppliesData.filter((row) => {
      return row.legacyassettype
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    this.setState({
      inputSuppliesData: filteredRows,
      prodPageCount: Math.ceil(filteredRows.length / this.state.perPageprod),
    });
  }

  cancelFarmGridSearch = () => {
    this.setState({
      farmgridsearched: "",
    });
    this.requestFarmGridSearch(
      this.state.farmgridsearched,
      this.state.filterIdValue
    );
  };
  /* EOD PRODUCTS FILTER BASED ON THE Legacy Asset Type */

  getCategorySubcategories(catID) {
    fetch(
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          subCategoriesList: result.contentsubcategories,
        });
      })
      .catch((err) => console.log("err", err));
  }

  subCategoriesOnClick(subCategory) {
    this.setState({
      productOwnsers: "",
    });
    this.getUsers();
    const filterData = this.state.filteredinputSuppliesData.filter(
      (data, index) => {
        return data.contentsubcategory
          .toLowerCase()
          .includes(subCategory.toLowerCase());
      }
    );
    this.setState({
      inputSuppliesData: filterData,
      prodPageCount: Math.ceil(filterData.length / this.state.perPageprod),
    });
  }

  likeCount(product) {
    fetch(
      `${window.appConfig.productLikeCount}${product.consumeradminid}/${product.businessid}/${product.productid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        this.getAllInputSupplies();
        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetprod = selectedPage * this.state.perPageprod;
    this.setState({
      currentPageprod: selectedPage,
      offsetprod: offsetprod,
    });
  };
  /*End of pagination */

  render() {
    const {
      inputSuppliesData,
      productOwnsers,
      farmgridsearched,
      subCategoriesList,
    } = this.state;

    const productsListOne = inputSuppliesData.slice(
      this.state.offsetprod,
      this.state.offsetprod + this.state.perPageprod
    );

    return (
      <>
        <div className="explore-product-wrapper">
          {/* <Container> */}
            <div className=" homepageSlider mb-0">
              {/* Input Supplies */}
              <div id="tendersContainer" className="container-fluid">
                <h3 id="Raw-Products" className="container-title  pt-0">
                  Input Supplies
                </h3>
                <div className="mb-4">
                  <div className="dropdown">
                    <div className="navbarRight">
                      <button
                        class="btn btn-success homepageBtn"
                        type="button"
                        onClick={() => this.getAllInputSupplies()}
                      >
                        Input Supplies
                      </button>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div className="navbarRight">
                      <Form.Select
                        onChange={(e) =>
                          this.supplierUserProducts(e.target.value)
                        }
                      >
                        <option>Brand</option>
                        {productOwnsers &&
                          productOwnsers.map((filter, i) => (
                            <option value={filter.username}>
                              {filter.username}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div className="navbarRight">
                      <Form.Select
                        onChange={(e) =>
                          this.subCategoriesOnClick(e.target.value)
                        }
                      >
                        <option>Sub Categories</option>
                        {subCategoriesList &&
                          subCategoriesList.map((filter, i) => (
                            <option value={filter.contentsubcategoryname}>
                              {filter.contentsubcategoryname}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div className="navbarRight navbarRight-mod">
                      <SearchBar
                        placeholder="Search"
                        value={farmgridsearched}
                        onChange={(searchedVal) =>
                          this.requestFarmGridSearch(searchedVal)
                        }
                        onCancelSearch={() => this.cancelFarmGridSearch()}
                      />
                    </div>
                  </div>
                </div>
                <div className="adminTendersDiv">
                  {/* View All Input Supplies */}
                  {productsListOne.length !== 0 ? (
                    <Row>
                      {productsListOne.map((product, index) => (
                        <Col
                          lg={4}
                          md={6}
                          sm={12}
                          className="d-flex mb-4 justify-content-center justify-content-lg-start"
                        >
                          <div className="explore-cards w-100">
                            <div className="explore-cards-image-container">
                              {(() => {
                                switch (product.productimageurl) {
                                  case "":
                                    break;
                                  case "null":
                                    return (
                                      <div className="image-placeholder"></div>
                                    );
                                    break;
                                  default:
                                    return (
                                      <p>
                                        <img
                                          src={product.productimageurl}
                                          className=""
                                        ></img>
                                      </p>
                                    );
                                }
                              })()}
                            </div>
                            <div className="mt-4 mb-4">
                              <div className="explore-prooduct-card-body">
                                <div className="d-flex justify-content-between align-items-center mb-2 ">
                                  <span className="product-name d-block ">
                                    {product.contentsubcategory}
                                  </span>
                                  <p className="products-like mb-0 d-flex align-items-end">
                                    <span
                                      className="d-inline-block"
                                      onClick={() => {
                                        this.likeCount(product);
                                      }}
                                    >
                                      <FiThumbsUp size={30} />
                                    </span>
                                    <small>{product.likecount}</small>
                                  </p>
                                </div>
                                {/* <span className="product-name  d-block mb-2"> {product.timestamp}</span>
                                <h2 className=" mt-0 mb-3">
                                  {product.businessid}
                                </h2> */}

                                <h2 className="mb-0">
                                  {product.productfriendlyname}
                                </h2>

                                <h5 className="mb-2 explore-card-span">
                                  Posted by <span>{product.businessid}</span>,{" "}
                                  {product.timestamp}
                                </h5>
                                <span>
                                  <p>
                                    <b>Product Id: </b>
                                    {product.productid}
                                  </p>
                                  <p>
                                    <b>Origin: </b>
                                    {product.country}
                                  </p>
                                  <p>
                                    <b>Product Cost: </b>
                                    <span className="product-original-price">
                                      {product.productcost} {product.unit}
                                    </span>
                                  </p>
                                  {(() => {
                                    switch (product.discount) {
                                      case "":
                                        break;
                                      case 0:
                                        break;
                                      default:
                                        return [
                                          <div>
                                            <p>
                                              <b>Discounted Price: </b>
                                              {product.discountedprice}{" "}
                                              {product.unit}
                                            </p>
                                          </div>,
                                        ];
                                    }
                                  })()}
                                  <p>
                                    <b>Item Count: </b>
                                    {product.itemcount}
                                  </p>
                                  {(() => {
                                    switch (product.productqrcodeurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.productqrcodeurl}
                                              className="img-qr"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.qacertificateurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.qacertificateurl}
                                              className="img-thumbnail"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.labcertificate) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.labcertificate}
                                              className="img-thumbnail"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.productvideourl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <ReactPlayer
                                              className="product-video"
                                              url={product.productvideourl}
                                              pip={true}
                                              controls={true}
                                              playing={false}
                                              muted={true}
                                            />
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.producthistoryurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <a
                                              href={product.producthistoryurl}
                                              target="_blank"
                                            >
                                              Product History
                                            </a>
                                          </p>
                                        );
                                    }
                                  })()}
                                  <p>
                                    <b>Status: </b>
                                    {product.status}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="explore-button-container p-3 px-0">
                              <button
                                type="button"
                                className="btn btn-success signbutton w-100 mx-auto d-block"
                              >
                                <a
                                  className="text-white text-decoration-none"
                                  href="/auth/3/signin"
                                >
                                  Buy
                                </a>
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                      <div className="paginationComponent">
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.prodPageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </Row>
                  ) : (
                    <div className="card">
                      <p className="card-body container-heading2">
                        <b>Input Supplies Not Found</b>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          {/* </Container> */}
        </div>
      </>
    );
  }
}

export default InputSupplies;
