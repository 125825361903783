import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Fab,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import SearchBar from '../../../../../Containers/SearchBar/SearchBar';
import MatTable from '../../../../../Containers/Table/Table';
import MainService from '../../../Common/MainService';
import AddIcon from '@material-ui/icons/Add';

const fields = [
  '',
  'Route ID',
  'Name',
  'Start Location',
  'End Location',
  'Last Modified',
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: 'white',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  color: 'black',
  p: 4,
};

const RouteManagement = ({ rouHandlers }) => {
  const [routeData, setRouteData] = useState({ routeList: [] });
  const [selectedItem, setSelectedItem] = useState('');
  const [visible, setVisible] = useState(10);
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);

  useEffect(() => {
    getRouteInfo();
  }, []);

  const findItem = () => {
    const items = routeData?.routeList;
    const filterdata = items.filter((item) => item.routeId !== selectedItem);
    setRouteData({ routeList: filterdata });
    setOpen(false);
  };

  const delRoute = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    let selecteditem = selectedItem;
    const deleteRoute = await MainService.delRoute(
      parentId,
      username,
      selecteditem
    );
    if (deleteRoute) {
      findItem();
    }
    console.log('delroute..................', deleteRoute);
  };

  const getRouteInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { routeCount, routeList } = await MainService.getRouteInfo(
      parentId,
      username
    );
    console.log(routeCount, 'routeCount........');
    console.log(routeList, 'routelist.......');
    setRouteData({ routeCount, routeList });
  };

  const deleteItems = (items) => {
    setConfirmDelete(true);
  };

  const onClose = (val) => {
    if (val) {
    } else {
      setConfirmDelete(false);
    }
  };

  const onSelect = (item) => {
    if (selectedItem === item) {
      setSelectedItem('');
    } else {
      setSelectedItem(item);
    }
  };

  const loadMoreRoute = () => {
    setVisible(visible + 10);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === 'delete') {
    }
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <SearchBar
          placeholder={'Search Location'}
          // onSubmit={this.getSearchLocation.bind(this)}
        />
        <Box>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='outlined'
            disabled={selectedItem === ''}
          >
            ACTION
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <MatTable
          fields={fields}
          rows={routeData.routeList.slice(0, visible)}
          keys={[
            'routeId',
            'routeName',
            'startLocId',
            'endLocId',
            'lastModifiedDate',
          ]}
          onSelect={onSelect}
          unique='routeId'
          selected={selectedItem}
          total={routeData.routeCount}
          change_page={getRouteInfo}
        />
      </Box>
      <Box
        sx={{
          marginTop: '1rem',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <Button
          onClick={loadMoreRoute}
          sx={{
            color: '#fff',
            background: '#1976d2',
            ':hover': {
              bgcolor: '#167add',
              color: '#fff',
            },
          }}
        >
          Load More{' '}
        </Button>
        <Fab
          color='primary'
          size={'large'}
          aria-label='add'
          cursor='pointer'
          title='Add Route'
          onClick={() => rouHandlers()}
          // className={classes.myStyle}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Modal
        open={open}
        // onClose={this.setState({ open: false })}
        onClose={closeModelHandler}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />
          <Box sx={{ marginTop: '0.5rem', display: 'flex', gap: 1 }}>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={delRoute}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RouteManagement;
