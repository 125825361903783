import React from 'react';
import { CForm, CRow, CCol, CCard, CCardHeader } from '@coreui/react';
import {
  TextField,
  makeStyles,
  Typography,
  Select,
  Snackbar,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import { Button } from '@mui/material';
import MainService from '../../Common/MainService';
import './booking.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class AddBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: { userInfo: {} },
      locationPath: {},
      vehicles: { assets: [] },
      drivers: { users: [] },
      open: false,
      message: '',
      isError: false,
      editPage: false,
    };
    this.props = { ...props };
    console.log(this.props, 'props...................... ');
  }

  componentDidMount() {
    this.getDrivers();
    this.getVehicleList();
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.setState({
      fromDate:
        firstDay.getDate() +
        '-' +
        firstDay.getMonth() +
        '-' +
        firstDay.getFullYear(),
      toDate:
        lastDay.getDate() +
        '-' +
        lastDay.getMonth() +
        '-' +
        lastDay.getFullYear(),
    });
  }

  //Fetch All Drivers............
  getDrivers = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { users } = await MainService.getDrivers(username);
    this.setState({ drivers: { users } });
  };

  //Fetch All Vehicle...........
  getVehicleList = async () => {
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const { assets } = await MainService.getVehicleList(username);
    this.setState({ vehicles: { assets } });
  };

  onSubmit(value) {}

  //***selectDriver***** */
  selectDriver(val) {
    const selectedLoc = { ...this.state.locationPath };
    const selectedDriver = val;
    if (selectedDriver) {
      selectedLoc['username'] = selectedDriver.username;
    } else {
      selectedLoc['username'] = null;
    }
    this.setState({ locationPath: selectedLoc });

    console.log(this.state.drivers, ' drivers..............');
    console.log(this.state.vehicles, 'vehicles...............');
  }

  //**selectVehicle*****
  selectVehicle(val) {
    const selectedLoc = { ...this.state.locationPath };
    // console.log( this.state.locationPath,"*******locationPath...................");

    const selectedVehicle = val;
    // console.log(selectedVehicle, "selectedVehicle................");
    if (selectedVehicle) {
      selectedLoc['assetId'] = selectedVehicle.assetId;
    } else {
      selectedLoc['assetId'] = null;
    }
    this.setState({ locationPath: selectedLoc });
    // console.log(selectedLoc, "locationPath....................");
  }

  //***setTime******************

  setTime(evt) {
    const date = new Date(evt.target.value);
    const month = date.getMonth() + 1;
    const selectedLoc = { ...this.state.locationPath };
    console.log(
      this.state.locationPath,
      'selectedLoc(locationPath)....................'
    );

    selectedLoc['startTime'] = evt.target.value;

    selectedLoc['startTimeTemp'] =
      date.getDate() +
      '-' +
      month +
      '-' +
      date.getFullYear() +
      'T' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds() +
      '.000';

    this.setState({ locationPath: selectedLoc });
  }

  setData(type, evt) {
    const formData = { ...this.state.bookingDetails };
    console.log(
      formData,
      '................formDta-(bookingDetails)..............'
    );
    if (type !== 'none') {
      formData[type][evt.target.name] = evt.target.value;
    } else {
      formData[evt.target.name] = evt.target.value;
    }
    this.setState({ bookingDetails: formData });
  }

  //....createBooking onSubmit............

  createBooking(evt) {
    evt.preventDefault();

    console.log('ffffffffffffffffffffffff');

    const timeStamp = new Date();
    console.log(timeStamp, 'timestamp.............');

    const data = {
      consignor: this.state.bookingDetails.adid,
      consignee: this.state.bookingDetails.asid,
      vendor: this.state.bookingDetails.vendor,
      bookingDate: this.state.locationPath.startTime,
      transportationMode: this.state.bookingDetails.tmode,
      billPaidBy: this.state.bookingDetails.billpaidby,
      taxPaidBy: this.state.bookingDetails.taxpaidby,
      fromLocationlat: parseFloat(this.state.bookingDetails.fromlat),
      fromLocationlng: parseFloat(this.state.bookingDetails.fromlong),
      lat: parseFloat(this.state.bookingDetails.tolat),
      lng: parseFloat(this.state.bookingDetails.tolong),
      itemName: this.state.bookingDetails.itemname,
      quantity: this.state.bookingDetails.quantity,
      actualWeight: this.state.bookingDetails.actualweight,
      grossWeight: this.state.bookingDetails.grossweight,
      weightType: this.state.bookingDetails.weighttype,
      tax: this.state.bookingDetails.tax,
      rateAsPer: this.state.bookingDetails.ratetype,
      rate: this.state.bookingDetails.rate,
      freight: this.state.bookingDetails.freight,
      cnCharge: this.state.bookingDetails.cncharge,
      pickupCharge: this.state.bookingDetails.pickupcharge,
      stCharge: this.state.bookingDetails.stcharge,
      ewayBillNumber: this.state.bookingDetails.ewaybillno,
      ewayBillDate: this.state.locationPath.ewaybilldate,
      ewayExpiryDate: this.state.locationPath.ewayexpirydate,
      assetId: this.state.locationPath.assetId,
      vehicleNumber: this.state.bookingDetails.vehiclenumber,
      tripId: this.state.bookingDetails.tripid,
      logisticsTransportUser: this.state.locationPath.username,
      deliveryType: this.state.bookingDetails.deliverytype,
      shippingCharge: this.state.bookingDetails.shippingcharge,
      paymentTerms: this.state.bookingDetails.paymentterms,
      paymentMode: this.state.bookingDetails.paymentmode,
      additionalCharges: this.state.bookingDetails.additionalcharges,
      totalBillAmount: this.state.bookingDetails.totalbillamount,
      advanceAmount: this.state.bookingDetails.advance,
      dueBalanceAmount: this.state.bookingDetails.duebalance,
      consignorAdddress: this.state.bookingDetails.consignoradddress,
      consigneeAdddress: this.state.bookingDetails.consigneeadddress,
      shippingAdddress: this.state.bookingDetails.shippingadddress,
      orderId: this.state.bookingDetails.oid,
      pickupSiteAddress: this.state.bookingDetails.pickupSiteAddress,
      deliverySiteAddress: this.state.bookingDetails.deliverySiteAddress,
      invoiceUrl: this.state.bookingDetails.invoiceUrl,
      timestamp: timeStamp,
    };

    if (!this.state.bookingDetails.adid) {
      this.setState({ open: true, message: 'Please enter consignor' });
      return false;
    }
    if (!this.state.bookingDetails.asid) {
      this.setState({ open: true, message: 'Please enter consignee' });
      return false;
    }
    if (!this.state.bookingDetails.vendor) {
      this.setState({ open: true, message: 'Please enter vendor' });
      return false;
    }

    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    MainService.createBooking(data, username);
    this.props.history.push('./bookings');
  }

  closeModal() {
    this.setState({ show: false });
  }

  render() {
    const { open, message } = this.state;
    return (
      <>
        <Snackbar open={open}>
          <Alert severity='error'>{message}</Alert>
        </Snackbar>

        <CForm
          onSubmit={this.createBooking.bind(this)}
          className='main_contaier'
        >
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 '>
                <CCol col='4'>
                  <Typography variant='body'></Typography>
                </CCol>
                <CCol col='2'></CCol>
                <CCol col='4'></CCol>
                <CCol
                  col='2'
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    style={{
                      color: 'red',
                    }}
                    onClick={() => {
                      this.props.history.push('./bookings');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' color='success' type='submit'>
                    Submit
                  </Button>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****NEW BOOKING**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 '>
                <CCol
                  col='2'
                  style={{
                    color: 'blue',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    fontFamily: 'math',
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  NEW BOOKING
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****BOOKING DETAILS**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 '>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Booking Details
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Consignor Id'
                    name='adid'
                    value={this.state.bookingDetails.adid}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Consignee Id'
                    name='asid'
                    value={this.state.bookingDetails.asid}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Vendor'
                    name='vendor'
                    value={this.state.bookingDetails.vendor}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <FormControl required fullWidth>
                    <TextField
                      id='datetime-local'
                      label='Booking Date'
                      type='datetime-local'
                      name='startTime'
                      onChange={this.setTime.bind(this)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.locationPath.startTime}
                    />
                    <FormHelperText>Required</FormHelperText>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Transport Mode'
                    name='tmode'
                    value={this.state.bookingDetails.tmode}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Paid By (Bill To)
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.billpaidby}
                      onChange={this.setData.bind(this, 'none')}
                      name='billpaidby'
                      inputProps={{
                        id: '',
                      }}
                    >
                      <option value=''>Paid By (Bill To) ----------</option>
                      <option value='Consignor'>Consignor</option>
                      <option value='Consignee'>Consignee</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Tax Paid By
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.taxpaidby}
                      onChange={this.setData.bind(this, 'none')}
                      name='taxpaidby'
                      inputProps={{
                        id: '',
                      }}
                    >
                      <option value=''>Tax Paid By ----------</option>
                      <option value='Consignor'>Consignor</option>
                      <option value='Consignee'>Consignee</option>
                      <option value='Transporter'>Transporter</option>
                      <option value='Vendor'>Vendor</option>
                    </Select>
                  </FormControl>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****FROM LOCATION**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  From Location
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Latitude'
                    name='fromlat'
                    value={this.state.bookingDetails.fromlat}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Longitude'
                    name='fromlong'
                    value={this.state.bookingDetails.fromlong}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****TO LOCATION**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 '>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  To Location
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Latitude'
                    name='tolat'
                    value={this.state.bookingDetails.tolat}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Longitude'
                    name='tolong'
                    value={this.state.bookingDetails.tolong}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****ORDER DETAILS**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Order Details
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Item Name'
                    name='itemname'
                    value={this.state.bookingDetails.itemname}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Quantity'
                    name='quantity'
                    value={this.state.bookingDetails.quantity}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Actual Weight'
                    name='actualweight'
                    value={this.state.bookingDetails.actualweight}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Gross Weight'
                    name='grossweight'
                    value={this.state.bookingDetails.grossweight}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Weight Type
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.weighttype}
                      onChange={this.setData.bind(this, 'none')}
                      name='weighttype'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Weight Type ----------</option>
                      <option value='kilograms'>Kilograms (KGS)</option>
                      <option value='quantals'>Quantals (QTS)</option>
                      <option value='litres'>Litres (LTS)</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Tax
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.tax}
                      onChange={this.setData.bind(this, 'none')}
                      name='tax'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Tax ----------</option>
                      <option value='5%'>5% GST</option>
                      <option value='12%'>12% GST</option>
                      <option value='18%'>18% GST</option>
                      <option value='tax-free'>Tax Free</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Rate as per
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.ratetype}
                      onChange={this.setData.bind(this, 'none')}
                      name='ratetype'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Rate as per ----------</option>
                      <option value='Weight'>Weight</option>
                      <option value='Size'>Volume/Size</option>
                      <option value='Quantity'>Quantity</option>
                      <option value='Distance'>Distance</option>
                      <option value='Hours'>Hours</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Rate'
                    name='rate'
                    value={this.state.bookingDetails.rate}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****ADDITIONAL CHARGES**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Additional Charges
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1 main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Freight'
                    name='freight'
                    value={this.state.bookingDetails.freight}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='C. N. Charge'
                    name='cncharge'
                    value={this.state.bookingDetails.cncharge}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='PickUp Charge'
                    name='pickupcharge'
                    value={this.state.bookingDetails.pickupcharge}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='St. Charge'
                    name='stcharge'
                    value={this.state.bookingDetails.stcharge}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/******BOOKING SUMMARY**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Booking Summary
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1  main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='E-Way Bill No.'
                    name='ewaybillno'
                    value={this.state.bookingDetails.ewaybillno}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <FormControl required fullWidth>
                    <TextField
                      id='datetime-local'
                      label='E-Way Bill Date'
                      type='datetime-local'
                      name='ewaybilldate'
                      onChange={this.setTime.bind(this)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.locationPath.ewaybilldate}
                    />
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <FormControl required fullWidth>
                    <TextField
                      id='datetime-local'
                      label='E-Way Expiry Date'
                      type='datetime-local'
                      name='ewayexpirydate'
                      onChange={this.setTime.bind(this)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.locationPath.ewayexpirydate}
                    />
                  </FormControl>
                </CCol>
                <CCol col='4'></CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****SHIPPING SUMMARY**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Shipping Summary
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1  main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Order Id'
                    name='oid'
                    value={this.state.bookingDetails.oid}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <FormControl required fullWidth>
                    <Autocomplete
                      id='combo-box-demo'
                      options={this.state.vehicles.assets}
                      getOptionLabel={(option) => option.assetId}
                      style={{ width: 150 }}
                      name='vehicleId'
                      onChange={(event, value) => this.selectVehicle(value)}
                      value={
                        this.state.vehicles.assets.find(
                          (v) => v.assetId === this.state.locationPath.assetId
                        ) || {}
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Select Asset'
                          variant='outlined'
                        />
                      )}
                    />
                    <FormHelperText>Required</FormHelperText>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Vehicle No.'
                    name='vehiclenumber'
                    value={this.state.bookingDetails.vehiclenumber}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>

                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Delivery Type
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.deliverytype}
                      onChange={this.setData.bind(this, 'none')}
                      name='deliverytype'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Delivery Type ----------</option>
                      <option value='godown-delivery'>Godown Delivery</option>
                      <option value='door-delivery'>Door Delivery</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Shipping Charge'
                    name='shippingcharge'
                    value={this.state.bookingDetails.shippingcharge}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****BILLING SUMMARY**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Billing Summary
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1  main_container-row'>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Payment Terms
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.paymentterms}
                      onChange={this.setData.bind(this, 'none')}
                      name='paymentterms'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Payment Terms ----------</option>
                      <option value='paid'>Paid</option>
                      <option value='to-pay'>To Pay</option>
                      <option value='to-be-billed'>To Be Billed</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='' shrink={true}>
                      Payment Mode
                    </InputLabel>
                    <Select
                      native
                      value={this.state.bookingDetails.paymentmode}
                      onChange={this.setData.bind(this, 'none')}
                      name='paymentmode'
                      inputProps={{
                        id: 'age-native-required',
                      }}
                    >
                      <option value=''>Payment Mode ----------</option>
                      <option value='paid'>Paid</option>
                      <option value='Cash'>Cash</option>
                      <option value='Online'>Online</option>
                    </Select>
                  </FormControl>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Additional Charges'
                    name='additionalcharges'
                    value={this.state.bookingDetails.additionalcharges}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Total Bill Amount'
                    name='totalbillamount'
                    value={this.state.bookingDetails.totalbillamount}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Advance/Received'
                    name='advance'
                    value={this.state.bookingDetails.advance}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Due Balance Amount'
                    name='duebalance'
                    value={this.state.bookingDetails.duebalance}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          {/* ****DELIVERY SUMMARY**** */}
          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1'>
                <CCol
                  col='2'
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    color: 'blue',
                    borderBottom: '1px solid grey',
                  }}
                >
                  Delivery Summary
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>

          <CCard>
            <CCardHeader>
              <CRow xl className='mb-1  main_container-row'>
                <CCol col='2'>
                  <TextField
                    label='Consignor Address'
                    name='consignoradddress'
                    value={this.state.bookingDetails.consignoradddress}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Consignee Address'
                    name='consigneeadddress'
                    value={this.state.bookingDetails.consigneeadddress}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
                <CCol col='2'>
                  <TextField
                    label='Shipping Address'
                    name='shippingadddress'
                    value={this.state.bookingDetails.shippingadddress}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>

                <CCol col='2'>
                  <TextField
                    label='Pick up SiteAddress'
                    name='pickupSiteAddress'
                    value={this.state.bookingDetails.pickupSiteAddress}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>

                <CCol col='2'>
                  <TextField
                    label='Delivery Site Address'
                    name='deliverySiteAddress'
                    value={this.state.bookingDetails.deliverySiteAddress}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>

                <CCol col='2'>
                  <TextField
                    label='Invoice Url'
                    name='invoiceUrl'
                    value={this.state.bookingDetails.invoiceUrl}
                    onChange={this.setData.bind(this, 'none')}
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.editPage}
                  />
                  <FormHelperText>Required</FormHelperText>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>
        </CForm>
      </>
    );
  }
}
export default AddBooking;
