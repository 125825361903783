import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AgriEnrepenurJourney from "../AgriEnrepenurJourney/AgriEnrepenurJourney";
import CTA from "../CTA/CTA";
import AgriEntreprenuerServices from "./AgriEntreprenuerServices";
import AgriEntreprenuerChallange from "./AgriEntreprenuerChallange";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriEntreprenurBenefits from "./AgriEntreprenurBenefits";
import AgriEntreprenurContribution from "./AgriEntreprenurContribution";
import ProductionManager from "../pricingTabs/ProductionManager";
export default function ForAgriEntreprenuer() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          "Want to become an agri-contract manufacturer   ",
          <br className="d-lg-block d-none"></br>,
          " to monetize unutilised manufacturing capacity ",
        ]}
        roleType="NULL"
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />
      <AgriEntreprenuerChallange />
      <SolutionEcosystem
        type="CM"
        bodycopy="Eco-system to monetize the unutilized manufacturing capacity to address the needs."
      />
      <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            titleClass="text-start"
            classname2="text-start"
            classname="mb-0 mt-0 "
            title="Discovering an Agri-Contract Manufacturer"
            description="Agri-contract manufacturers are companies that specialize in providing manufacturing services for agricultural products and equipment. They typically offer services such as product formulation, packaging, and labeling tailored to the needs of agricultural businesses. These companies often have expertise in producing fertilizers, pesticides, seeds, and other agricultural inputs. Agri-contract manufacturers help streamline production processes for agricultural companies, allowing them to focus on other aspects of their business such as marketing and distribution. By outsourcing manufacturing to specialized providers, agricultural businesses can benefit from cost savings, scalability, and access to advanced technologies."
          />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <Container>
      <AgriEntreprenuerServices />
      </Container>
      <div className="main-wrapper2">
        <AgriEntreprenurBenefits />
      </div>

      <div className="subSectionWrapper">
        <Container>
          <AgriEnrepenurJourney />
        </Container>
      </div>
      <div className="main-wrapper2">
        <Container>
          <AgriEntreprenurContribution />
        </Container>
      </div>
      <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <ProductionManager />
        </Container>
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading="Elevate Your Agricultural Expertise Join Us as a Agro-contract manufacturer!"
            btnText="Register Now"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
