import React from "react";
import CareService from "../../../images/cbn-network-icons/care-services.svg";
import TradeService from "../../../images/cbn-network-icons/trade-service.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function CBNMainServices() {
  const services = [

   
     
    {
      img: CareService,
      title: "Care Service",
      bodycopy:
      "CBN Network offers comprehensive care services, enabling users to procure a wide array of animal and plant healthcare products, ensuring the vitality and health of agricultural and animal environments.",
    },
    {
        img: TradeService,
        title: "Trade Service",
        bodycopy: " CBN Network provides a trade service allowing farmers to sell their produce directly, fostering efficient transactions and empowering agricultural communities to thrive in the marketplace."    },
     
        {
          img: TradeService,
          title: "Distribution Service",
          bodycopy:
            "Facilitate asset distribution services for agricultural producers, encompassing both agricultural and machinery assets."
        },
          
  ];
  return (
    <div className="main-wrapper2">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Services
      </h2>
      <Row>
        {services.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
