import React, { useEffect, useState } from 'react';
import InvoiceTable from '../components/InvoiceTable';
import {
  Alert,
  Box,
  Button,
  Divider,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import UserForm from '../components/UserForm';
import MainService from '../../../Common/MainService';
import SupplyListHeader from '../components/SupplyListHeader';
import CommanTable from '../components/CommanTable';
import dayjs from 'dayjs';
import CommanUserForm from '../components/CommanUserForm';
import { uniqueNumber } from '../utils/genrateUniqueNumber';

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempQuotationDetails = [
  { id: 'quotationID', label: 'Quotation ID' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'buyer_email', label: 'Buyer Email' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const tempItemDetailsLabel = [
  { id: 'description', label: 'Description' },
  { id: 'quantity', label: 'Quantity', type: 'number' },
  { id: 'unit', label: 'Unit' },
  { id: 'reqDeliveryDate', label: 'Delivery Date', type: 'date' },
];

const tempRawItemDetailsLabel = [
  { id: 'description', label: 'Raw Material Provide by Buyer(if any)' },
  { id: 'quantity', label: 'Quantity', type: 'number' },
  { id: 'unit', label: 'Unit' },
  { id: 'reqDeliveryDate', label: 'Delivery Date', type: 'date' },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];

const tempBuyerData = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempSupplier = {
  supplierName: '',
  supplierContact: '',
  sup_email: '',
  sup_gstin: '',
  sup_panNo: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
};
const tempquotationDetails = {
  quotationID: 'c12',
  quotationDate: formattedValue,
  customerID: '',
  validUpto: '',
};
const tempdescriptionItemDetails = [
  {
    description: '',
    quantity: '',
    unit: '',
    reqDeliveryDate: '',
  },
];

const tempTermConditionValue = [];

const BuyerQutationManagement = ({ userDetails }) => {
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [showQutationForm, setShowQutaitonForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [quotationFileList, setQuotationFileList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [formSubmitError, setFormSubmitError] = useState(false);

  const addNewQutationHandler = () => {
    setShowQutaitonForm(true);
  };

  const [quotationDetails, setQuotationDetails] =
    useState(tempquotationDetails);
  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);
  const [descriptionItemDetails, setDescriptionItemDetails] = useState(
    tempdescriptionItemDetails
  );

  const [rawMaterialItemDetails, setRawMaterialItemDetails] = useState([]);

  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  useEffect(() => {
    const quotationID = 'QT-' + uniqueNumber();
    setQuotationDetails({
      quotationID: quotationID,
      quotationDate: formattedValue,
      customerID: '',
      validUpto: '',
    });
  }, [showQutationForm]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setBuyerDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails, showQutationForm]);

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;
    console.log(name, value);

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  const cancelQutationHandler = () => {
    setShowAlert(false);
    setShowQutaitonForm(false);
    setImageFile(null);
    setTempImage(null);
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setQuotationDetails(tempquotationDetails);
    setDescriptionItemDetails(tempdescriptionItemDetails);
    setRawMaterialItemDetails(tempdescriptionItemDetails);
    setSaveButtonStatus(false);
    setFormSubmitError(false);
  };

  const saveQutationHandler = async () => {
    console.log('saving...', quotationDetails);

    // setSaveButtonStatus(true);
    // Perform validation

    setTimeout(() => {
      setFormSubmitError(false);
    }, 3000);

    // const isObjectEmpty = (obj) =>
    //   Object.values(obj).some((value) => value === '');

    const isItemEmpty = (item) => {
      const hasValidSupplierContact = (supplierContact) =>
        !(supplierContact.trim().length >= 10);
      return Object.values(item).some(
        (value) =>
          value === '' ||
          (typeof value === 'number' && isNaN(value)) ||
          (typeof value === 'string' && value === 'Invalid Date') ||
          (value === item.supplierContact &&
            hasValidSupplierContact(item.supplierContact))
      );
    };

    const isBuyerDetailsEmpty = isItemEmpty(buyerDetails);
    const isSupplierDetailsEmpty = isItemEmpty(supplierDetails);
    const isDescriptionItemDetailsEmpty =
      descriptionItemDetails.some(isItemEmpty);
    const isRawMaterialItemDetailsEmpty =
      rawMaterialItemDetails.some(isItemEmpty);
    const isQuotationDetailsEmpty = isItemEmpty(quotationDetails);

    if (
      isBuyerDetailsEmpty ||
      isSupplierDetailsEmpty ||
      isDescriptionItemDetailsEmpty ||
      isRawMaterialItemDetailsEmpty ||
      isQuotationDetailsEmpty ||
      !(buyerDetails.buyer_cell.buyer_cell1.length === 10)
    ) {
      // Handle empty fields error, display a message or perform appropriate action
      setSaveButtonStatus(true);
      setFormSubmitError(true);
      return;
    }

    const data = {
      quotationDetails,
      supplierDetails,
      buyerDetails,
      consigneeDetails: {
        consigneeContactNo: buyerDetails.buyer_cell.buyer_cell1,
        consigneeAdressline1: buyerDetails.buyerAdressline1,
        consigneeAdressline2: buyerDetails.buyerAdressline2,
        consigneeAdressline3: buyerDetails.buyerAdressline3,
      },
      items: [...descriptionItemDetails],
      rawItems: [...rawMaterialItemDetails],
      termsConditions: [...termsConditions.map((obj) => obj.term)],
    };

    const contentInfo = {
      contentname: `${quotationDetails.quotationID}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: 'contentdescription',
      expirydate: quotationDetails.validUpto,
      assetdeveloperid: 'consumer2',
    };

    const formData = new FormData();
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('data', JSON.stringify(data));
    formData.append('file', imageFile);

    const response = await MainService.createQuotationApi(
      `${parentId}/${username}`,
      orgid,
      suborgid,
      formData,
      username
    );

    const tempQutationList = {
      viewLink: response.data.LocationUrl,
      quotationID: response.data.contenId,
    };

    if (response.status === 200) {
      handleDownload(response.data.LocationUrl, response.data.contenId);
      setShowAlert(true);
      setQuotationFileList((prevList) => [...prevList, tempQutationList]);
      setTimeout(() => {
        cancelQutationHandler();
      }, 3000);
    } else {
      setFormSubmitError(true);
    }

    console.log(response);

    // Log all items in the FormData object

    // const response = await MainService.createQuotationApi(
    //   formData,
    //   setQuotationFileList,
    //   quotationData
    // );

    // if (response === 200) {
    //   // Show the alert message
    //   setShowAlert(true);
    //   // Hide the alert message after 3 seconds
    //   setTimeout(() => {
    //     cancelQutationHandler();
    //   }, 3000);
    // } else {
    //   setFormSubmitError(true);
    // }
    // console.log(response);
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  //image file change handler
  const imageFileChangeHandler = (event) => {
    setImageFile(event.target.files[0]);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const imageChangeHandler = () => {
    const fileInput = document.getElementById('upload-photo');
    fileInput.click();
  };

  //Quotation details change handler
  const quotationDetailsChangeHandler = (field, value) => {
    setQuotationDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  //Adding new item to description
  const addItemHandler = (itemDetails, setItemDetails) => {
    console.log('click');
    const data = {
      description: '',
      quantity: '',
      unit: '',
      reqDeliveryDate: '',
    };
    setItemDetails([...itemDetails, data]);
  };

  //Deleting description item handler
  const deleteItemHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  //Description change Handler
  const descriptionItemChangeHandler = (
    index,
    field,
    value,
    setDescription
  ) => {
    setDescription((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      {!showQutationForm && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Buyer Accounts Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempQuotationDetails}
            rowDataList={quotationFileList}
          />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewQutationHandler}>Add New Quotation</Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {showQutationForm && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Buyer Quotation
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
            }}
          >
            <SupplyListHeader
              image={tempImage}
              imageChangeHandler={imageChangeHandler}
              DocumentDetails={quotationDetails}
              saveButtonStatus={saveButtonStatus}
              DocumentDetailsChangeHandler={quotationDetailsChangeHandler}
              imageFileChangeHandler={imageFileChangeHandler}
            />
          </Box>
          <Box>
            <UserForm
              saveButtonStatus={saveButtonStatus}
              buyerDetails={buyerDetails}
              supplierDetails={supplierDetails}
              userInputBuyerChangehandler={(event) =>
                formUserInputChangeHandler(event, setBuyerDetails)
              }
              userInputSupplierChangehandler={(event) =>
                formUserInputChangeHandler(event, setSupplierDetails)
              }
              invoice={false}
            />

            <CommanTable
              columnsData={tempItemDetailsLabel}
              rowsData={descriptionItemDetails}
              tableType={'editable'}
              inputFieldChangeHandler={(index, field, value) =>
                descriptionItemChangeHandler(
                  index,
                  field,
                  value,
                  setDescriptionItemDetails
                )
              }
              addItemHandler={() =>
                addItemHandler(
                  descriptionItemDetails,
                  setDescriptionItemDetails
                )
              }
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  descriptionItemDetails,
                  setDescriptionItemDetails,
                  true
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
            <CommanTable
              columnsData={tempRawItemDetailsLabel}
              rowsData={rawMaterialItemDetails}
              tableType={'editable'}
              inputFieldChangeHandler={(index, field, value) =>
                descriptionItemChangeHandler(
                  index,
                  field,
                  value,
                  setRawMaterialItemDetails
                )
              }
              addItemHandler={() =>
                addItemHandler(
                  rawMaterialItemDetails,
                  setRawMaterialItemDetails
                )
              }
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  rawMaterialItemDetails,
                  setRawMaterialItemDetails,
                  false
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
          </Box>
          <Box>
            <CommanTable
              columnsData={tempTermConditionLabel}
              rowsData={termsConditions}
              tableType={'editable'}
              addItemHandler={addTermConditionHandler}
              inputFieldChangeHandler={termsConditionsChangeHandler}
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  termsConditions,
                  setTermsConditions,
                  false
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
          </Box>
          {showAlert && (
            <Alert severity='success' sx={{ mt: '0.5rem' }}>
              Quotation successfully submitted.
            </Alert>
          )}
          {formSubmitError && (
            <Alert severity='error' sx={{ mt: '0.5rem' }}>
              Please fill the required field.
            </Alert>
          )}
          <Box>
            <Stack>
              <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={cancelQutationHandler}>Cancel</Button>
                <Button onClick={saveQutationHandler}>Save</Button>
              </ListItem>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BuyerQutationManagement;
