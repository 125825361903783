import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./OperatorLegacyAssetManagementComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function OperatorLegacyAssetManagementComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [assetVerticalIdValue, setAssetVerticalIdValue] = useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [categoryTypeValue, setCategoryTypeValue] = useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelIdValue, setModelIdValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelTypeValue, setModelTypeValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [mimeTypeValue, setMimeTypeValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [imageUrlValue, setImageUrlValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");

  /*List Filter */
 const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
 };

 const cancelSearch = () => {
   setSearched("");
   requestSearch(searched);
 };

 /*Grid Filter */
 const requestFarmGridSearch = (searchedVal) => {
   const filteredRows = setFilterUsers.filter((row) => {
     return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
   });
   setAssetsList(filteredRows);
 };

 const cancelFarmGridSearch = () => {
   setFarmGridSearched("");
   requestFarmGridSearch(farmgridsearched);
 };


  useEffect(() => {
    getWhiteLabelAssets();
    getAssetVerticalsIdList();
  }, []); 

  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId,selectedCategory]); 

  useEffect(() => {
    if (!selectedAssertTypes) return;
    getModelsList();
  }, [selectedVerticalsId,selectedCategory,selectedAssertTypes]);

  useEffect(() => {    
    getAllSites();
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllSites = (Type) => {
    if(Type === "List"){
      setListView(true);
      setGridView(false);
    } else if(Type === "Grid"){
      setListView(false);
      setGridView(true);
    }
    // setIsLoading(true);
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSite(data[0].siteid);
          setSitesList(data);
        } else {
          setSelectedSite("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getAssetVerticalsIdList = () => {

    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setVerticalsIdList(data);
        } else {
          
          setSelectedVerticalsId("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),"consumer2")
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
           
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getModelsList = () => {
    
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");
           
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getWhiteLabelAssets = () => {
    setIsLoading(true);
   
      MainService.getWhiteLabelAssetsForAD(      
        username
      )
        .then((data) => {
          if (data) {
            console.log("all assets data:",data)
            setAssetsList(data);
            getFilterUsers(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    
  };
  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: siteNameValue,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: modelFriendlyNameValue,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: "AssetDeveloper",
      consumerAdminId: "consumer2",
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    getWhiteLabelAssets();
    setShowAssetPopup(!assetPopup);
  };

  const uploadSignedDocumentClick = () => {
    setShowAssetPopup(!assetPopup);
  };
  /*Create Asset */
  const closeAgreementModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleAgreement = "Create Asset";
  const agreementBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetDeveloperId">AssetDeveloper ID:</InputLabel>
        <Input
          disabled
          id="assetDeveloperId"
          name="assetDeveloperId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="5533222"
          value={assetIdValue}
          onChange={(e) => {
            setAssetIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name :
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => {
            setLegacyAssetFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsId(e.target.value);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Sector not found</h4>
          )}
        </Select>
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetVerticalFriendlyName">
          Asset Vertical Friendly Name :
        </InputLabel>
        <Input
          id="assetVerticalFriendlyName"
          name="assetVerticalFriendlyName"
          type="text"
          value={assetVerticalFriendlyNameValue}
          onChange={(e) => {
            setAssetVerticalFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl> */}
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Vertical Friendly Name :</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={assetVerticalFriendlyNameValue}
          onChange={(e) => {
            setAssetVerticalFriendlyNameValue(e.target.value);
          }}
        >
          {verticalsIdList &&
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.friendlyName}>
                {idObj.friendlyName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Domain</InputLabel>
        <Select
          id="assetDomain"
          name="assetDomain"
          type="text"
          value={assetDomainValue}
          onChange={(e) => {
            setAssetDomainValue(e.target.value);
          }}
        >
          <MenuItem selected>Select Any</MenuItem>
          <MenuItem key="Farm" value="Farm">
            Farm
          </MenuItem>
          <MenuItem key="Field" value="Field">
            Field
          </MenuItem>
          <MenuItem key="Enterprise" value="Enterprise">
            Enterprise
          </MenuItem>
          <MenuItem key="Home" value="Home">
            Home
          </MenuItem>
          <MenuItem key="Personal" value="Personal">
            Personal
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSite(e.target.value);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
        <Input
          id="zone"
          name="zone"
          type="text"
          value={zoneValue}
          onChange={(e) => {
            setZoneValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
        <Input
          id="lotName"
          name="lotName"
          type="text"
          value={lotNameValue}
          onChange={(e) => {
            setLotNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerId"> Manufacturer Id *:</InputLabel>
        <Input
          disabled
          id="manufacturerId"
          name="manufacturerId"
          type="text"
          value={manufacturerIdValue}
          onChange={(e) => {
            setManufacturerIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerUrl"> Manufacturer Url:</InputLabel>
        <Input
          id="manufacturerUrl"
          name="manufacturerUrl"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => setSelectedModel(event.target.value)}
          label="Select Model"
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelId"> Model Id*:</InputLabel>
        <Input
          id="modelId"
          name="modelId"
          type="text"
          value={modelIdValue}
          onChange={(e)=>{
            setModelIdValue(e.target.value)
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelFriendlyName">  Model Friendly Name:</InputLabel>
        <Input
          disabled
          id="modelFriendlyName"
          name="modelFriendlyName"
          type="text"
          value={modelFriendlyNameValue}
          onChange={(e)=>{
            setModelFriendlyNameValue(e.target.value)
          }}
        />
      </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
        <Input
          disabled
          id="modelType"
          name="modelType"
          type="text"
          value={selectedModel}
          /* onChange={(e) => {
            setModelTypeValue(e.target.value);
          }} */
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
        <Input
          id="modelUrl"
          name="modelUrl"
          type="text"
          value={modelUrlValue}
          onChange={(e) => {
            setModelUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
        <Input
          id="serialNo"
          name="serialNo"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn"> UDN*:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnValue}
          onChange={(e) => {
            setUdnValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="upc"> UPC:</InputLabel>
        <Input
          id="upc"
          name="upc"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
        <Input
          id="motherBreed"
          name="motherBreed"
          type="text"
          value={motherBreedValue}
          onChange={(e) => {
            setMotherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
        <Input
          id="fatherBreed"
          name="fatherBreed"
          type="text"
          value={fatherBreedValue}
          onChange={(e) => {
            setFatherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="imageUrl"> Image Url:</InputLabel>
        <Input
          id="imageUrl"
          name="imageUrl"
          type="file"
          inputProps={{ accept: 'image/jpg, image/jpeg, image/png' }}
          value={imageUrlValue}
          onChange={(e) => {
            setImageUrlValue(e.target.value);
            setMimeTypeValue(imageUrlValue.split('.').pop());
            console.log("image url:",e.target.value)
            console.log("image url:",e.target.value.split('.').pop())
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mimeType"> Mime Type:</InputLabel>
        <Input
          id="mimeType"
          name="mimeType"
          type="text"
          value={mimeTypeValue}
          onChange={(e) => {
            setMimeTypeValue(imageUrlValue.split('.').pop());
          }}
        />
      </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="width"> Width:</InputLabel>
        <Input
          id="width"
          name="width"
          type="text"
          value={widthValue}
          onChange={(e) => {
            setWidthValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="height"> Height:</InputLabel>
        <Input
          id="height"
          name="height"
          type="text"
          value={heightValue}
          onChange={(e) => {
            setHeightValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="depth"> Depth:</InputLabel>
        <Input
          id="depth"
          name="depth"
          type="text"
          value={depthValue}
          onChange={(e) => {
            setDepthValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whiteLabelCost"> WhiteLabel Cost:</InputLabel>
        <Input
          id="whiteLabelCost"
          name="whiteLabelCost"
          type="text"
          value={whiteLabelCostValue}
          onChange={(e) => {
            setWhiteLabelCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
        <Input
          id="sellingCost"
          name="sellingCost"
          type="text"
          value={sellingCostValue}
          onChange={(e) => {
            setSellingCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude">Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* Mapping Functionality */

  const mapPopUpHandler = (assetId,categoryType) =>{
    setMapPopUp(!mapPopUp);
    setMapAssetId(assetId);
    setMapCategoryType(categoryType);
  };
  const closeMapModal = (
    <span
      onClick={() => {
        setMapPopUp(!mapPopUp);
      }}
    >
      X
    </span>
  );
  const createMapping = () =>{
    
    const siteIdData = {
      siteid : mapSiteId,
    }
    const dataSiteId = JSON.stringify(siteIdData);

    MainService.createMapping(username,mapAssetId,mapCategoryType,dataSiteId)
    .then((res) => {
      setMapPopUp(!mapPopUp);
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  }

  const mapAgreement = "Map Model";
  const mapModelBody = (
    <div>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Id</InputLabel>
        <Select
          id="selectedModel"
          value={mapSiteId}
          onChange={(event) => setMapSiteId(event.target.value)}
          label="Select Model"
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
        <Input
          disabled
          id="assetId"
          name="assetId"
          type="text"
          value={mapAssetId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={mapCategoryType}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={createMapping}
        >
          Create Mapping
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setMapPopUp(!mapPopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Mapping Functionality */

  /* UnMap Asset Handler Functionality */
  const unMapAssetHandler = (assetId,categoryType,siteIds) =>{
  
    const siteId = {
      siteid : siteIds,
    }
    const siteIdData = JSON.stringify(siteId);

    MainService.createUnMapping(assetId,categoryType,siteIdData,username)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  }
  /* End UnMap Asset Handler Functionality */

  /* Update Legacy Asset */
  const closeUpdateModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );

  const updateAssetHandler = () =>{
    
    const formData = {
      status : updateAssetStatus,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(assetIdVal,statusData)
    .then((res) => {
      setLegacyUpdate(!legacyUpdate);
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  }

  const updateLegacyAssetHandler = (assetverticalId,assetType,assetId,friendlyName,modelId,categoryType,udn,whitelabelCost,sellingCost,assetStatus) =>{
    setLegacyUpdate(!legacyUpdate);
    setAssetVerticalId(assetverticalId);
    setAssetTypeVal(assetType);
    setAssetIdVal(assetId);
    setAssetFriName(friendlyName);
    setModelIdVal(modelId);
    setCatgeryVal(categoryType);
    setUdnVal(udn);
    setWhiteLabelVal(whitelabelCost);
    setSellingCostVal(sellingCost);
    setExAssetStatus(assetStatus);
  };

  const updateLegacyAsset = "Asset Information";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input
          disabled
          id="udn"
          name="udn"
          type="text"
          value={udnVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          disabled
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          disabled
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATED ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
              <MenuItem selected>Select Any</MenuItem>
              <MenuItem key="PrematureSale" value="PrematureSale">Premature Sale</MenuItem>
              <MenuItem key="Lost" value="Lost">Lost</MenuItem>
              <MenuItem key="Damaged" value="Damaged">Damaged</MenuItem>
              <MenuItem key="UnderMaintenance" value="UnderMaintenance">Under-Maintenance</MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetHandler}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset */

  /* Asset Delete Handler */

  const addedAssetDeleteHandler = (assetIds,assetTypes,modelIds,assetverticalIds,categoryTypes) => {

    if(window.confirm("Are you sure to delete?")){
      MainService.assetDelete(assetIds,assetTypes,modelIds,assetverticalIds,categoryTypes,username)
      .then((res) => {
        // return NotificationManager.success(res);
        return NotificationManager.success("successfully deleted");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
   }

  }
  /*End Asset Delete Handler */

  return (
    <>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Asset"
          className={classes.fab}
        >
          <AddIcon onClick={() => uploadSignedDocumentClick()}></AddIcon>
        </Fab>
        <div className="topHeadersWrapper">
          
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => 
                  {
                    setSelectedVerticalsId(event.target.value);
                    const filteredRows = setFilterUsers.filter((row) => {
                      return row.assetverticalId.includes(event.target.value);
                    });
                    setAssetsList(filteredRows);
                  }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>
          
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.categoryType.toLowerCase().includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.assetType.toLowerCase().includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.modelId.includes(event.target.value);
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={()=>{getAllSites("Grid")}}
          >
           
          </Button>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={()=>{getAllSites("List")}}
          >
            
          </Button>
          </div>
        </div>
        <div className="MuiGrid-root">

          {getListView === true ?
            <Box sx={{ width: "100%", marginBottom: 30 }}>
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <Box sx={{ width: "100%"}}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  {assetsList.length > 0 ? (
                        <Paper sx={{ width: "100%" }}>
                          <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                              <TableRow align="center">
                              <TableCell align="center" colSpan={21}>
                                Assets
                              </TableCell>
                              </TableRow>
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={1}>
                                    Asset Id
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Asset Status
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    AssetType
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Asset Friendly Name
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    AssetverticalId
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Blocking Status
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Category Type
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Gender
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    ModelId
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Model Name
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Model Type
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Model Url
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Selling Cost
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    SiteId
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    UDN
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Weight
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Whitelabel Cost
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Map
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    UnMap
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Update
                                  </TableCell>
                                  <TableCell align="center" colSpan={1}>
                                    Delete
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {assetsList.length > 0 ? (
                                  assetsList
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((assets, index) => {
                                      return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                          <TableCell align="center">
                                            {assets.assetId}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.assetStatus}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.assetType}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.friendlyName}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.assetverticalId}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.blockingStatus}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.categoryType}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.gender}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.modelId}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.modelName}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.modelType}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.modelUrl}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.sellingCost}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.siteId}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.udn}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.weight}
                                          </TableCell>
                                          <TableCell align="center">
                                            {assets.whitelabelCost}
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              variant="contained"
                                              // color="primary"
                                              // className="registerBtn"
                                              type="button"
                                              onClick={()=>mapPopUpHandler(assets.assetId,assets.categoryType)}
                                            >
                                              Map
                                            </Button>
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              variant="contained"
                                              // color="primary"
                                              // className="registerBtn"
                                              type="button"
                                              onClick={()=>unMapAssetHandler(assets.assetId,assets.categoryType,assets.siteId)}
                                            >
                                              UnMap
                                            </Button>
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              variant="contained"
                                              // color="primary"
                                              // className="registerBtn"
                                              type="button"
                                              onClick={()=>updateLegacyAssetHandler(
                                                        assets.assetverticalId,
                                                        assets.assetType,
                                                        assets.assetId,
                                                        assets.friendlyName,
                                                        assets.modelId,
                                                        assets.categoryType,
                                                        assets.udn,
                                                        assets.whitelabelCost,
                                                        assets.sellingCost,
                                                        assets.assetStatus,
                                              )}
                                            >
                                              Update
                                            </Button>
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              variant="contained"
                                              // color="primary"
                                              // className="registerBtn"
                                              type="button"
                                              onClick={()=>addedAssetDeleteHandler(
                                                assets.assetId,
                                                assets.assetType,
                                                assets.modelId,
                                                assets.assetverticalId,
                                                assets.categoryType
                                              )}
                                            >
                                              Delete
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                ) : (
                                  <TableRow align="center">
                                    <TableCell align="center" colSpan={12}>
                                      Assets not found.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={assetsList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                        </Paper>
                  ) : (
                    <h4>Assets not found.</h4>
                  )}
                </Box> 
              )}
            </div>
            </Box>
          : null }

          {getGridView === true ? 
          <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
            <SearchBar
              value={farmgridsearched}
              onChange={(searchVal) => requestFarmGridSearch(searchVal)}
              onCancelSearch={() => cancelFarmGridSearch()}
            />
            {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{width:"100%",textAlign:"center"}}>List Of Assets</h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            <p>
                              <b>Blocking Status:</b>
                              {assets.blockingStatus}
                            </p>
                            <p>
                              <b>Category Type:</b>
                              {assets.categoryType}
                            </p>
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>
                            <p>
                              <b>Model Name:</b>
                              {assets.modelName}
                            </p>
                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>SiteId:</b>
                              {assets.siteId}
                            </p>
                            <p>
                              <b>UDN:</b>
                              {assets.udn}
                            </p>
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <div style={{width:"100%",margin:"5px"}}>
                              <Button
                                style={{marginRight:"10px"}}
                                size="small"
                                variant="contained"
                                onClick={()=>mapPopUpHandler(assets.assetId,assets.categoryType)}
                              >
                                Map
                              </Button>
                              <Button
                                style={{marginRight:"10px"}}
                                size="small"
                                variant="contained"
                                onClick={()=>unMapAssetHandler(assets.assetId,assets.categoryType,assets.siteId)}
                              >
                                UnMap
                              </Button>
                              <Button
                                style={{marginRight:"10px"}}
                                size="small"
                                variant="contained"
                                onClick={()=>updateLegacyAssetHandler(
                                  assets.assetverticalId,
                                  assets.assetType,
                                  assets.assetId,
                                  assets.friendlyName,
                                  assets.modelId,
                                  assets.categoryType,
                                  assets.udn,
                                  assets.whitelabelCost,
                                  assets.sellingCost,
                                  assets.assetStatus,
                                )}
                              >
                                Update
                              </Button>
                              <Button
                                style={{marginRight:"10px"}}
                                size="small"
                                variant="contained"
                                onClick={()=>addedAssetDeleteHandler(
                                  assets.assetId,
                                  assets.assetType,
                                  assets.modelId,
                                  assets.assetverticalId,
                                  assets.categoryType
                                )}
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Assets Found.</h4>
                  )}
                </div>
            )}
          </Box>: null}
        </div>
      </div>
      <CustomPopup
        closeButton={closeAgreementModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={agreementBody}
        title={titleAgreement}
      />
      <CustomPopup
        closeButton={closeMapModal}
        isOpen={mapPopUp}
        closeModal={mapPopUp}
        content={mapModelBody}
        title={mapAgreement}
      />
      <CustomPopup
        closeButton={closeUpdateModel}
        isOpen={legacyUpdate}
        closeModal={legacyUpdate}
        content={updateLegacyAssetData}
        title={updateLegacyAsset}
      />
    </>
  );
}
