import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { event } from 'react-ga';

const StyledTableCell = styled(TableCell)({
  padding: '5px 10px ',
});

const tempData = [
  {
    field:
      'Is the Farmers added for this month? If yes, please provide details',
  },
  {
    field:
      'Is {produceType} extracted from {nameOfassetType2} If Yes, Provide details.',
  },
  {
    field:
      'Is {produceType1} stored in safe and secure place, if yes, please mention place where {produceType2} is stored',
  },
  {
    field:
      'Is any {nameOfassetType3} sold. If yes, please provides details about the {nameOfassetType4} in remarks section',
  },
  {
    field: 'Is organic certification done. If Yes, please mention details',
  },
  {
    field: 'Is training given to the assigned farmer?',
  },
  {
    field:
      'Any necessary tasks assigned to the farmer If yes, please mention details.',
  },
  {
    field: 'Any  tasks/action item is  open.',
  },
  {
    field: 'Any  tasks/action item is  close.  ',
  },
  {
    field:
      'Any  requests and/or concerns of farmer. If yes, please mention details',
  },
  {
    field: 'Is Health Status report is captured.If yes, please mention details',
  },
  {
    field:
      'Is Health Status report is captured. If yes, please attach all reports',
  },
  {
    field:
      'Is {nameOfassetType5} Farm compliance done If yes, please attach report.',
  },
  {
    field:
      'Is  remote monitoring of {nameOfassetType6} is in place. If yes, please share.',
  },
  {
    field: 'Any birth of new {nameOfassetType7} If yes, please mention details',
  },
  {
    field:
      'Is health status of {nameOfassetType8} is captured. If yes provide details in remarks .',
  },
  {
    field:
      'Any  {nameOfassetType9} is lost or damaged?. If yes, please mention  action taken.',
  },
  {
    field: 'Is  insurance of {nameOfassetType10} is done?',
  },
  {
    field: 'Is private insurer is identified.',
  },
  {
    field:
      'Is the  farmer adopt all practices and controls to keep any “pests” at an acceptable level.',
  },
  {
    field:
      'Any migration is done with in this month. If yes, please mention details.',
  },
  {
    field:
      'Is operator providing weekly report about {nameOfassetType11} to { serviceProviderName1} and  {sponsoredCompany2}?',
  },
  {
    field: 'Is the weekly report is maintained',
  },
  {
    field: 'Is the farmer performing well',
  },
  {
    field:
      'Is the  Guidelines for the farmer is prepared. If yes, please mention details',
  },
  {
    field:
      'Is the attendance of {nameOfassetType12} once in a week  is happening. If yes, provide the details.',
  },
  {
    field: 'Is the photos of {nameOfassetType13} is recorded for this month',
  },
  {
    field:
      'Is the farmer providing weekly report about the farm and {nameOfassetType14}',
  },
  {
    field:
      'Is the environment parameter like temperature, humidity etc. of colonies are captured once in a week. If yes, please provide details',
  },
  {
    field:
      'Any feed provided. If yes, provide  feed details about the {nameOfassetType15} with the name of feeder and date.',
  },
  {
    field: 'Provide location of {nameOfassetType16} for this month',
  },
  {
    field:
      'If  migration happens, then provide migration site address in remarks',
  },
  {
    field:
      'Is {nameOfassetType17} weight is recorded, if yes, then provide details.',
  },
  {
    field: 'Is there be any operational issue. If yes, then please mention.',
  },
  {
    field:
      'Any issue of the farm and/or {nameOfassetType18} are raised by the farmer. If yes, please mention',
  },
  {
    field: 'Is the operations managed professionally.',
  },
  {
    field:
      'Is the operator/farmer has the android phone. If yes, please mention details.',
  },
  {
    field:
      'Any milestones as per SOW is completed in this month. if yes, please mention.',
  },
  {
    field: 'Any risk identified with farmer. if yes, please mention.',
  },
  {
    field:
      'Any risk identified with {nameOfassetType19}. If yes, please mention with asset number.',
  },
];

const GenrateNewReport = () => {
  const [checkPointMenuAnchorEl, setCheckPointMenuAnchorEl] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState('');
  const [checkpointDataObj, setCheckpointDataObj] = useState([]);
  const [menuItemRemark, setMenuItemRemark] = useState('');
  const checkboxMenuOpen = Boolean(checkPointMenuAnchorEl);

  const checkboxMenuOpenHandler = (event, index) => {
    setCheckPointMenuAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  // check box menu item handler
  const checkboxMenuItemOptionHandler = (option) => {
    setCheckpointDataObj((prev) => ({
      ...prev,
      [selectedItemIndex]: {
        ...tempData[selectedItemIndex],
        option,
        remark: menuItemRemark,
      },
    }));
    checkboxMenuCloseHandler();
  };

  // menu item remark change handler
  const menuItemRemarkChangeHandler = (event, index) => {
    console.log(event.target.value);
    setSelectedItemIndex(index);
    setMenuItemRemark(event.target.value);
    setCheckpointDataObj((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...tempData[selectedItemIndex],
        remark: menuItemRemark,
      },
    }));
  };

  const checkboxMenuCloseHandler = () => {
    setCheckPointMenuAnchorEl(null);
    setSelectedItemIndex(null);
  };

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: '2px solid', mb: 2 }}>
          <Typography
            sx={{
              p: 1,
              fontSize: '1.6rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Monthly Report
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'space-between',
            '&>*': { flex: 1 },
            mb: 1,
          }}
        >
          <Box>
            <Typography>Report Prepared By</Typography>
            <TextField
              variant='standard'
              label='Please enter name'
              sx={{ display: 'flex' }}
            />
          </Box>

          <Box>
            <Typography>Report Reviewed By</Typography>
            <TextField
              variant='standard'
              label='Please enter name'
              sx={{ display: 'flex' }}
            />
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Box>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 500 }}>
              Report Duration
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Typography>Start Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker />
              </LocalizationProvider>
            </Box>
            <Box>
              <Typography>End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 500 }}>
              Checkpoint Report
            </Typography>
          </Box>

          <Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Monthly Check Points</TableCell>
                      <TableCell align='right'>Yes/No</TableCell>
                      <TableCell align='right'>
                        Remarks & details about execution
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {console.log('menu', checkpointDataObj)}
                    {tempData.map((li, index) => {
                      return (
                        <TableRow key={index}>
                          <StyledTableCell
                            sx={{
                              width: '10px',
                              textAlign: 'center',
                              fontWeight: '500',
                            }}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell sx={{ maxWidth: '260px' }}>
                            <Typography>{li.field}</Typography>
                          </StyledTableCell>
                          <StyledTableCell sx={{ textAlign: 'end' }}>
                            <Button
                              id={index}
                              onClick={(event) =>
                                checkboxMenuOpenHandler(event, index)
                              }
                            >
                              {checkpointDataObj[index]?.option
                                ? checkpointDataObj[index].option
                                : 'Select'}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <TextField
                              sx={{ width: '100%' }}
                              value={checkpointDataObj.remark}
                              onChange={(event) =>
                                menuItemRemarkChangeHandler(event, index)
                              }
                            />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <Button variant='outlined' color='error'>
                Cancel
              </Button>
              <Button variant='outlined'>Submit</Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={checkPointMenuAnchorEl}
        open={checkboxMenuOpen}
        onClose={checkboxMenuCloseHandler}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => checkboxMenuItemOptionHandler('Yes')}>
          Yes
        </MenuItem>
        <MenuItem onClick={() => checkboxMenuItemOptionHandler('No')}>
          No
        </MenuItem>
        <MenuItem onClick={() => checkboxMenuItemOptionHandler('NA')}>
          NA
        </MenuItem>
      </Menu>
    </>
  );
};

export default GenrateNewReport;
