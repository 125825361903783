import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import MainService from "../../Common/MainService";
import CustomizedSteppers from "../../Common/StepperCounter/StepperCounterComponent";
import "./DashboardePageComponentForAD.css";



const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 250,
    zIndex: 99,
    float: "right",
  },
  formControlLabel: {
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgb(43, 144, 99)",
    marginTop: "-10px",
    marginLeft: "-10px",
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));



export default function DashboardePageComponentForAD() {

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    growers: 0,
    assets: 0,
    category: 0,
    myAssets: 0,
    profit: 0,
  });
  
 
  const [solutionList, setSolutionList] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(null);
  
  useEffect(() => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
  return (
    <div>
      <h1>Dashboard</h1>
      <CustomizedSteppers></CustomizedSteppers>
      <Grid container spacing={0}>
        {/* Dashboard Cards Container */}
        <Grid item md={12} xs={12}>
          <Grid container className={classes.gridRoot} spacing={2}>
            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Total Sites
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.growers}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                    <a
                      href="/main/growers"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Total Assets
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.myAssets}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                    <a
                      href="/main/myassets"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Profit
                    {/* My White Label Assets */}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.profit}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                    <a
                      href="/main/statement"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        

      



       
      </Grid>
    </div>
  );
}
