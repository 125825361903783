import React, { useState, useEffect } from 'react';
import Map from '../Map';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Typography, TextField } from '@mui/material';

const ConsciousnessChecker = ({ location, markerPostion }) => {
  console.log(location, 'locationprops............');
  const [randomNumber, setRandomNumber] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isConscious, setIsConscious] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [getMap, setGetMap] = useState(false);
  const [startGameButton, setStartGameButton] = useState(true);
  const [same, setSame] = useState(false);

  const generateRandomNumber = () => {
    // Generate a random 10-digit number
    const random = Math.floor(Math.random() * 10000000000);
    setRandomNumber(random.toString().padStart(10, '0'));
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };
  console.log(location, 'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww');
  const handleSubmit = () => {
    // Check if the user has typed the entire number correctly
    if (userInput === randomNumber) {
      setIsConscious(true);
      setIsActive(false);
      clearTimeout(timeoutId);
      // closeModelHandler()
      // gameOf();
      setSame(true);
      setGetMap(true);
    }
  };

  const startGame = () => {
    setIsActive(true);
    setIsConscious(false);
    generateRandomNumber();
    setStartGameButton(false);
  };

  return (
    <>
      {startGameButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <Button variant='contained' color='primary' onClick={startGame}>
            Click here to Start the game
          </Button>
        </div>
      )}

      <Card style={{ textAlign: 'center' }}>
        {isActive && (
          <div style={{ padding: '2rem' }}>
            <Typography variant='body1'>
              Type the following number within 10 seconds:
            </Typography>
            <Typography variant='h4'>{randomNumber}</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <TextField
                variant='outlined'
                value={userInput}
                onChange={handleUserInput}
                style={{ marginRight: '1rem' }}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </Card>

      <div style={{ marginTop: '4rem' }}>
        {isConscious && getMap && (
          <Map location={location} MarkerMap={markerPostion} />
        )}
      </div>
    </>
  );
};

export default ConsciousnessChecker;
