import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

// import SigninPage from './Signin/SigninPageComponent.js';
// import SignupPage from './Signup/SignupPageComponent.js';
// import ForgotPassword from './ForgotPassword/ForgotPasswordComponent.js';

import FarmerSigninPage from './FarmerSignin/SigninPageComponent.js';
import FarmerSignupPage from './FarmerSignup/SignupPageComponent.js';
import FarmerForgotPassword from './FarmerForgotPassword/ForgotPasswordComponent.js';

import CBNSignin from "./CBNSignin/Signin/SigninPageComponent";
import CBNSignup from "./CBNSignup/Signup/SignupPageComponent";
import CBNForgotPassword from "./CBNForgotPassword/ForgotPassword/ForgotPasswordComponent";

export default class AuthRouteComponent extends React.Component{   
    render(){
        return(
            <div>
                <Switch>
                    {/* <Route exact path={this.props.match.url+"/:code/signin" } component={(args) => <SigninPage {...args}/>} /> */}
                    {/* <Route exact path={this.props.match.url+"/:code/signup" } component={(args) => <SignupPage {...args}/>} /> */}
                    {/* <Route exact path={this.props.match.url+"/:code/forgot" } component={(args) => <ForgotPassword {...args}/>} /> */}

                    {/* <Route exact path={this.props.match.url+"/:code/farmersignin" } component={(args) => <FarmerSigninPage {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/farmersignup" } component={(args) => <FarmerSignupPage {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/farmerforgot" } component={(args) => <FarmerForgotPassword {...args}/>} /> */}

                    <Route exact path={this.props.match.url+"/:code/signin" } component={(args) => <CBNSignin {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/signup" } component={(args) => <CBNSignup {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/forgot" } component={(args) => <CBNForgotPassword {...args}/>} />
                    <Route component={(args) => <Redirect to={this.props.match.url+"/1/signin"} />} />
                </Switch>
            </div>
        )
    }
}