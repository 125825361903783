import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HTTP_Client from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import "react-notifications/lib/notifications.css";

// import MainService from "../../Main/Common/MainService";
import MainService from "../../Main/components/Main/Common/MainService";
import "./SignupPageComponent.css";

export default class SignupPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      privecyCheck: false,
      signupObj: {},
      userType: [],
    };
  }
  componentDidMount() {
    this.getUserType();
  }
  getUserType = () => {
    HTTP_Client({
      method: "get",
      url: `${window.baseUrl_signup}user/type`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          NotificationManager.error(res.data);
          return;
        }
        this.setState({ userType: res.data });
      })
      .catch((e) => {
        console.error(e);
        //NotificationManager.error("Unable to get data from api.");
      });
  };
  doSignup = (evt) => {
    evt.preventDefault();

    if (!this.state.signupObj.username) {
      NotificationManager.warning("Please enter Username/Email");
      return;
    }
    if (!this.state.signupObj.firstname) {
      NotificationManager.warning("Please enter valid firstname");
      return;
    }
    if (!this.state.signupObj.lastname) {
      NotificationManager.warning("Please enter valid lastname");
      return;
    }
    if (!this.state.signupObj.city) {
      NotificationManager.warning("Please enter City");
      return;
    }
    if (!this.state.signupObj.city) {
      NotificationManager.warning("Please enter State");
      return;
    }
    if (!this.state.signupObj.city) {
      NotificationManager.warning("Please enter Address");
      return;
    }
    if (!this.state.signupObj.userphone) {
      NotificationManager.warning("Please enter Phone Number");
      return;
    }
    if (!this.state.privecyCheck) {
      NotificationManager.warning(
        "Please accept the Terms of Use & Privacy Policy."
      );
      return;
    }

    if (this.props.match.params.code ===10 ) {
      NotificationManager.error("Invalid access type.");
      //invalid type
      console.log("Invalid type");
      return;
    }

    const {
      username,
      firstname,
      lastname,
      city,
      state,
      address,
      userphone,
    } = this.state.signupObj;
    //const encPwd = sha256(password);
    //const encPwd = password;
    let _data = {
      email: username,
      username,
      firstname,
      lastname,
      phone: userphone,
      usertype: "Farmer",
      city: city,
      state: state,
      address: address,
    };

    this.setState({ isLoading: true });

    MainService.doFarmerSignup(_data)
      .then((signupRes) => {
        console.log(signupRes);
        MainService.doLogin(username, "")
          .then(() => {
            this.setState({ isLoading: false });
            const loginData = MainService.getLocalData();
            loginData.user = { type: 3, ...signupRes };
            MainService.setLocalData();
            NotificationManager.success("Signup successfully.");
            setTimeout(() => {
              this.goToHome();
            }, 1000);
          })
          .catch((e) => {
            this.setState({ isLoading: false });
            NotificationManager.success(
              "Signup successfully, please login to continue."
            );
            setTimeout(() => {
              this.goToSignin();
            }, 1000);
          });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
        NotificationManager.error(e);
      });
  };
  goToForgotPwd = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmerforgot"
    );
  };
  goToSignin = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmersignin"
    );
  };
  goToHome = (evt) => {
    this.props.history.push("/");
  };
  onFieldChange = (evt) => {
    let signupObj = this.state.signupObj;
    signupObj[evt.target.name] = evt.target.value;
    this.setState({ signupObj });
  };
  onPrivecyChange = (evt) => {
    this.setState({ privecyCheck: !this.state.privecyCheck });
  };
  render() {
    const _code = parseInt(this.props.match.params.code);
    const _class =
      _code === 10
        ? "farmer"
        : "default";

    return (
      <Container maxWidth={false} className={"signinPageContainer " + _class}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={11}
            sm={5}
            md={4}
            lg={3}
            className="signupOuterContainer"
          >
            <div className="loginContainer">
              <form onSubmit={this.doSignup}>
                <h2>Grow Big & Reap</h2>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username">
                    Username/Email Address *
                  </InputLabel>
                  <Input
                    id="username"
                    name="username"
                    type="email"
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="firstname">First Name *</InputLabel>
                  <Input
                    id="firstname"
                    name="firstname"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="firstname">Last Name *</InputLabel>
                  <Input
                    id="lastname"
                    name="lastname"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                          <AssignmentIndIcon />
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                {}

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userphone">Phone Number *</InputLabel>
                  <Input
                    id="userphone"
                    name="userphone"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons">phone</i>
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                {}

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="city">City *</InputLabel>
                  <Input
                    id="city"
                    name="city"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons">face</i>
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="state">State *</InputLabel>
                  <Input
                    id="state"
                    name="state"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons">face</i>
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="address">Address *</InputLabel>
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons">face</i>
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControlLabel
                  className="rememberMe"
                  control={
                    <Checkbox
                     
                      color="primary"
                      value={this.state.privecyCheck}
                      onChange={this.onPrivecyChange}
                    />
                  }
                  label="I accept the Terms of Use & Privacy Policy."
                />

                <FormControl fullWidth={true}>
                  {this.state.isLoading === false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="submit"
                      disabled={
                        (this.state.username !== "") &
                        (this.state.phone !== "")
                          ? false
                          : true
                      }
                    >
                      Fill Application
                    </Button>
                  )}
                  {this.state.isLoading === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className="loginLoader" />
                    </Button>
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToSignin}
                >
                  Already have an account? SignIn here
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToHome}
                  style={{ marginBottom: "15px" }}
                >
                  Back Home?
                </FormControl>
              </form>
            </div>
          </Grid>
        </Grid>

        <NotificationContainer />
      </Container>
    );
  }
}

