import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./ViewClinicAdminsComponent.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ViewClinicAdminsComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState("");
  const [clinicAdminsList, setClinicAdminsList] = useState([]);
  const [clinicAdminUserId, setClinicAdminUserId] = useState("");
  const [clinicAdminUserName, setClinicAdminUserName] = useState("");
  const [clinicAdminAddress, setClinicAdminAddress] = useState("");
  const [clinicAdminCity, setClinicAdminCity] = useState("");
  const [clinicAdminFirstName, setClinicAdminFirstName] = useState("");
  const [clinicAdminLastName, setClinicAdminLastName] = useState("");
  const [clinicAdminPhone, setClinicAdminPhone] = useState("");
  const [clinicAdminEnterpriceId, setClinicAdminEnterpriceId] = useState("");
  const [clinicAdminUserType, setClinicAdminUserType] = useState("");
  const [clinicAdminEmail, setClinicAdminEmail] = useState("");
  const [clinicAdminDomain, setClinicAdminDomain] = useState("");
  const [clinicAdminState, setClinicAdminState] = useState("");
  const [clinicAdminNominee, setClinicAdminNominee] = useState("");
  const [clinicAdminPancard, setClinicAdminPancard] = useState("");
  const [clinicAdminLongitude, setClinicAdminLongitude] = useState("");
  const [clinicAdminLatitude, setClinicAdminLatitude] = useState("");
  const [clinicAdminMessage, setClinicAdminMessage] = useState("");
  const [clinicAdminStatus, setClinicAdminStatus] = useState("");
  const { user } = MainService.getLocalData();
  const { username } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailValue, setEmailValue] = React.useState("");
  const [messageValue, setMessageValue] = React.useState("");
  const [clinicAdminEmailDialog, setClinicAdminEmailDialog] =
    React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [clinicAdminSMSDialog, setClinicAdminSMSDialog] = React.useState(false);
  const [phoneNumerValue, setPhoneNumerValue] = React.useState("");
  const [selectedClinicAdminUsername, setSelectedClinicAdminUserName] =
    useState("");
  const [filteredClinicAdminsList, setFilteredClinicAdminsList] = useState([]);
  const [clinicAdminInfoDialog, setClinicAdminInfoDialog] = useState(false);
  const [listView, setListView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [viewAllClick, setViewAllClick] = useState(true);
  const [viewAssociatedClick, setViewAssociatedClick] = useState(false);

  useEffect(() => {
    getAllClinicAdmins();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Clinic Admins */
  const getAllClinicAdmins = () => {
    MainService.getClinicAdmins()
      .then((res) => {
        setClinicAdminsList(res);
        setFilteredClinicAdminsList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All Clinic Admins */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* Logistic Provider info Dialog */
  const clinicAdminViewInfoDialogClick = (clinicAdmin) => {
    MainService.getLogisticProviderInfo(clinicAdmin)
      .then((res) => {
        setClinicAdminUserId(res[0].userid);
        setClinicAdminUserName(res[0].username);
        setClinicAdminFirstName(res[0].firstname);
        setClinicAdminLastName(res[0].lastname);
        setClinicAdminEnterpriceId(res[0].enterpriseid);
        setClinicAdminUserType(res[0].usertype);
        setClinicAdminEmail(res[0].email);
        setClinicAdminDomain(res[0].domain);
        setClinicAdminPhone(res[0].phone);
        setClinicAdminAddress(res[0].address);
        setClinicAdminCity(res[0].city);
        setClinicAdminState(res[0].state);
        setClinicAdminNominee(res[0].nominee);
        setClinicAdminPancard(res[0].pancard);
        setClinicAdminLongitude(res[0].longitude);
        setClinicAdminLatitude(res[0].latitude);
        setClinicAdminMessage(res[0].message);
        setClinicAdminStatus(res[0].status);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setClinicAdminInfoDialog(!clinicAdminInfoDialog);
  };

  const clinicAdminSendEmailClick = (selectedUser) => {
    setSelectedClinicAdminUserName(selectedUser);
    setClinicAdminEmailDialog(!clinicAdminEmailDialog);
  };
  const sendEmailClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("email Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setClinicAdminEmailDialog(!clinicAdminEmailDialog);
  };

  const sendSMSClick = () => {
    let smsData = {
      sms: messageValue,
    };
    const smsDataString = JSON.stringify(smsData);
    MainService.sendSMS(phoneNumerValue, smsDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setClinicAdminSMSDialog(!clinicAdminSMSDialog);
  };

  const clinicAdminSendMessageClick = (selectedUser) => {
    setSelectedClinicAdminUserName(selectedUser);
    setClinicAdminSMSDialog(!clinicAdminSMSDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredClinicAdminsList.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setClinicAdminsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const listViewClick = () => {
    setListView(true);
    setGridView(false);
    setMapView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
    setMapView(false);
  };
  const mapViewClick = () => {
    setListView(false);
    setGridView(false);
    setMapView(true);
  };

  const AssociatedClinicAdminsClick = () => {
    setViewAllClick(false);
    setViewAssociatedClick(true);
  };
  const AllClinicAdminsClick = () => {
    setViewAllClick(true);
    setViewAssociatedClick(false);
  };

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          <Button
            startIcon={<MapIcon />}
            size="large"
            onClick={mapViewClick}
          ></Button>
          &nbsp;
          {viewAllClick && (
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={AssociatedClinicAdminsClick}
            >
              View Associated Clinic Admins
            </Button>
          )}
          {viewAssociatedClick && (
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={AllClinicAdminsClick}
            >
              View All Clinic Admins
            </Button>
          )}
        </div>
        {viewAllClick && (
          <div>
            <h2>View Clinic Admins(Nutrition Providers)</h2>
            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of Associated Clinic Admins
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          USer Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          View
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clinicAdminsList.length > 0 ? (
                        clinicAdminsList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((clinicAdmins, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {clinicAdmins.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {clinicAdmins.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminViewInfoDialogClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center"></TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminSendEmailClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    Send Email
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminSendMessageClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    Send Message
                                  </Button>
                                </TableCell>
                                
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Feedback
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            Clinic Admins Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={clinicAdminsList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {clinicAdminsList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {clinicAdminsList.map((clinicAdmins, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>User Id:</b>
                                {clinicAdmins.userid}
                              </p>
                            </span>
                            <p>
                              <b>User Name:</b>
                              {clinicAdmins.username}
                            </p>
                            <h4 className="cardactiontitle">View</h4>
                            <div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminViewInfoDialogClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                View Info
                              </Button>
                            </div>
                            <div>
                              <h4 className="cardactiontitle">Operate</h4>
                            </div>
                            <div>
                              <h4 className="cardactiontitle">Actions</h4>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminSendEmailClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                Send Email
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminSendMessageClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                Send Msssage
                              </Button>
                            </div>
                            &nbsp;&nbsp;
                            <div>
                              
                              <Button type="button" variant="contained">
                                Feedback
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>Clinic Admins not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            {mapView && (
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={4}
                    center={defaultCenter}
                  >
                    <MarkerClusterer options={options} averageCenter={true}>
                      {(clusterer) =>
                        clinicAdminsList.map((markerObj, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(markerObj.latitude),
                                lng: parseFloat(markerObj.longitude),
                              }}
                              clusterer={clusterer}
                              onClick={() => onSelect(markerObj)}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>

                    {selected.latitude && selected.longitude && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <CardContent>
                          <Typography variant="body2" color="textSecondary">
                            <b>ID:</b> {selected.siteid}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <b> Name:</b>Site{selected.sitename}
                          </Typography>
                        </CardContent>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            )}
          </div>
        )}
        {viewAssociatedClick && (
          <div>
            <h2>View Associated Clinic Admins(Nutrition Providers)</h2>
            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of Clinic Admins
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          USer Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          View
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clinicAdminsList.length > 0 ? (
                        clinicAdminsList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((clinicAdmins, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {clinicAdmins.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {clinicAdmins.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminViewInfoDialogClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center"></TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminSendEmailClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    Send Email
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      clinicAdminSendMessageClick(
                                        clinicAdmins.username
                                      );
                                    }}
                                  >
                                    Send Message
                                  </Button>
                                </TableCell>
                                
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Feedback
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            Associated Clinic Admins Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={clinicAdminsList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {clinicAdminsList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {clinicAdminsList.map((clinicAdmins, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>User Id:</b>
                                {clinicAdmins.userid}
                              </p>
                            </span>
                            <p>
                              <b>User Name:</b>
                              {clinicAdmins.username}
                            </p>
                            <h4 className="cardactiontitle">View</h4>
                            <div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminViewInfoDialogClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                View Info
                              </Button>
                            </div>
                            <div>
                              <h4 className="cardactiontitle">Operate</h4>
                            </div>
                            <div>
                              <h4 className="cardactiontitle">Actions</h4>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminSendEmailClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                Send Email
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  clinicAdminSendMessageClick(
                                    clinicAdmins.username
                                  );
                                }}
                              >
                                Send Msssage
                              </Button>
                            </div>
                            &nbsp;&nbsp;
                            <div>
                              
                              <Button type="button" variant="contained">
                                Feedback
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>Associated Clinic Admins not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            {mapView && (
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={4}
                    center={defaultCenter}
                  >
                    <MarkerClusterer options={options} averageCenter={true}>
                      {(clusterer) =>
                        clinicAdminsList.map((markerObj, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(markerObj.latitude),
                                lng: parseFloat(markerObj.longitude),
                              }}
                              clusterer={clusterer}
                              onClick={() => onSelect(markerObj)}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>

                    {selected.latitude && selected.longitude && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <CardContent>
                          <Typography variant="body2" color="textSecondary">
                            <b>ID:</b> {selected.siteid}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <b> Name:</b>Site{selected.sitename}
                          </Typography>
                        </CardContent>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            )}
          </div>
        )}

        {/* Send SMS */}
        <Dialog
          fullScreen={fullScreen}
          open={clinicAdminSMSDialog}
          onClose={handleClose}
          aria-labelledby="sendSMS"
        >
          <DialogTitle id="sendSMS">Send SMS</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="clinicAdminUsername">User Name:</InputLabel>
              <Input
                disabled
                id="clinicAdminUsername"
                name="clinicAdminUsername"
                type="text"
                value={selectedClinicAdminUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Mobile Number:</InputLabel>
              <Input
                id="phone"
                name="phone"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendSMSClick();
              }}
            >
              Send
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setClinicAdminSMSDialog(!clinicAdminSMSDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Email */}
        <Dialog
          fullScreen={fullScreen}
          open={clinicAdminEmailDialog}
          onClose={handleClose}
          aria-labelledby="sendEmail"
        >
          <DialogTitle id="sendEmail">{"Send Email"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="clinicAdminUsername">User Name:</InputLabel>
              <Input
                disabled
                id="clinicAdminUsername"
                name="clinicAdminUsername"
                type="text"
                value={selectedClinicAdminUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subject">Subject:</InputLabel>
              <Input
                id="subject"
                name="subject"
                type="text"
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendEmailClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setClinicAdminEmailDialog(!clinicAdminEmailDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View Info */}
        <Dialog
          fullScreen={fullScreen}
          open={clinicAdminInfoDialog}
          onClose={handleClose}
          aria-labelledby="viewInfoDialog"
        >
          <DialogTitle id="viewInfoDialog">
            {"View Clinic Admin Info"}
          </DialogTitle>
          <DialogContent>
            <p>
              <b>User Id:</b>
              {clinicAdminUserId}
            </p>
            <p>
              <b>User Name:</b>
              {clinicAdminUserName}
            </p>
            <p>
              <b>First Name:</b>
              {clinicAdminFirstName}
            </p>
            <p>
              <b>Last Name:</b>
              {clinicAdminLastName}
            </p>
            <p>
              <b>Enterprise Id:</b>
              {clinicAdminEnterpriceId}
            </p>
            <p>
              <b>UserType:</b>
              {clinicAdminUserType}
            </p>
            <p>
              <b>Email:</b>
              {clinicAdminEmail}
            </p>
            <p>
              <b>Domain:</b>
              {clinicAdminDomain}
            </p>
            <p>
              <b>Phone:</b>
              {clinicAdminPhone}
            </p>
            <p>
              <b>Address:</b>
              {clinicAdminAddress}
            </p>
            <p>
              <b>City:</b>
              {clinicAdminCity}
            </p>
            <p>
              <b>State:</b>
              {clinicAdminState}
            </p>
            <p>
              <b>Nominee:</b>
              {clinicAdminNominee}
            </p>
            <p>
              <b>Pancard:</b>
              {clinicAdminPancard}
            </p>
            <p>
              <b>Longitude:</b>
              {clinicAdminLongitude}
            </p>
            <p>
              <b>Latitude:</b>
              {clinicAdminLatitude}
            </p>
            <p>
              <b>Message:</b>
              {clinicAdminMessage}
            </p>
            <p>
              <b>Status:</b>
              {clinicAdminStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setClinicAdminInfoDialog(!clinicAdminInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
