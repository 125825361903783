
import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import AnimalCare from "../../images/agrocaremarket/plant-care.jpg"
import PlantCare from "../../images/agrocaremarket/animal-care.jpg"

export default function CollectivesPartnerServices() {
  const services = [
   
   
    {
      title: "Local Training",
      image: AnimalCare,
      href: "/auth/3/signin",
    },
    {
      title: "Local Distribution",
      image: PlantCare,
      href: "/auth/3/signin",
    },



  ];

  return (
    <Container className="container for-farmer-section2 px-0  subSectionWrapper">
 
 <h2 className="text-center container-title mb-4"> Collective Services for Agri-Producer</h2>


    

      <Row className="justify-content-center ">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            
            <Col md={4} className="mb-4">
                <NavLink to ={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-decoration-none  container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>

                <p className="mb-0 container-para"> {e.data} </p>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

