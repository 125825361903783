import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./AgreementComponent.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function AgreementComponent(props) {
  const [unSignedAgreementsList, setUnSignedAgreementsList] = useState([]);
  const [signedAgreementsList, setSignedAgreementsList] = useState([]);
  const [filteredUnSignedAgreementsList, setFilteredUnSignedAgreementsList] =
    useState([]);
  const [filteredSignedAgreementsList, setFilteredSignedAgreementsList] =
    useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [AgreementsViewDialog, setAgreementsViewDialog] = React.useState(false);
  const [uploadAgreementsDialog, setUploadAgreementsDialog] =
    React.useState(false);
  const [AgreementsDocumentLink, setAgreementsDocumentLink] =
    React.useState(null);
  const [parentId, setParentId] = React.useState("");
  const [agreementType, setAgreementType] = useState("");
  const [agreementFile, setAgreementsFile] = useState(null);
  const [viewSignedAgreements, setViewSignedAgreements] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    getAllUnsignedAgreements();
    getAllSignedAgreements();
    getParentId();
  }, [username]); //eslint-disable-line react-hooks/exhaustive-deps

  const getParentId = () => {
    MainService.getParentId(username)
      .then((data) => {
        setParentId(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /* View All AgreementsDocs */
  const getAllUnsignedAgreements = () => {
    MainService.getAllUnsignedAgreementsForAD()
      .then((data) => {
        setUnSignedAgreementsList(data);
        setFilteredUnSignedAgreementsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getAllSignedAgreements = () => {
    MainService.getAllSignedAgreementsForAD(username)
      .then((data) => {
        console.log("signed agreements data:", data);
        setSignedAgreementsList(data);
        setFilteredSignedAgreementsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /* End All AgreementsDocs */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  const AgreementsViewDialogClick = (link) => {
    setAgreementsDocumentLink(link);
    setAgreementsViewDialog(!AgreementsViewDialog);
  };
  const AgreementsSubmit = () => {
    if(agreementFile !== null){
      let agreementData = new FormData();
      agreementData.append("file", agreementFile);
      agreementData.append("mimetype", agreementFile.type);
  
      MainService.uploadAgreementForAD(agreementData, username)
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
        setUploadAgreementsDialog(!uploadAgreementsDialog);
    }else{
      return NotificationManager.error("please choose file")
    }
    
    
  };

  const uploadAgreementsClick = () => {
    setUploadAgreementsDialog(!uploadAgreementsDialog);
  };
  const viewSignedAgreementsClick = () => {
    setViewSignedAgreements(!viewSignedAgreements);
  };
  const viewUnSignedAgreementsClick = () => {
    setViewSignedAgreements(!viewSignedAgreements);
  };

  const requestSearchSigned = (searchedVal) => {
    const filteredRows = filteredSignedAgreementsList.filter((row) => {
      return row.documenttype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSignedAgreementsList(filteredRows);
  };
  const cancelSearchSigned = () => {
    setSearched("");
    requestSearchSigned(searched);
  };

  const requestSearchUnsigned = (searchedVal) => {
    const filteredRows = filteredUnSignedAgreementsList.filter((row) => {
      return row.documenttype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUnSignedAgreementsList(filteredRows);
  };
  const cancelSearchUnsigned = () => {
    setSearched("");
    requestSearchUnsigned(searched);
  };
  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                uploadAgreementsClick();
              }}
            >
              Upload Agreement
            </Button>
          </div>
        </div>
        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          <h2>Agreements</h2>
          {listView && (
            <div>
              {viewSignedAgreements ? (
                <div>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    onClick={() => {
                      viewSignedAgreementsClick();
                    }}
                  >
                    View UnSigned Agreements
                  </Button>
                  <br />
                  <br />
                  <Paper sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearchSigned(searchVal)}
                      onCancelSearch={() => cancelSearchSigned()}
                    />
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow align="center">
                            <TableCell align="center" colSpan={12}>
                              List of Signed Agreements
                            </TableCell>
                          </TableRow>
                          <TableRow align="center">
                            <TableCell align="center" colSpan={1}>
                              Document Type
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              Document Status
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              Option
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {signedAgreementsList.length > 0 ? (
                            signedAgreementsList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((AgreementsDocs, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {AgreementsDocs.documenttype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {AgreementsDocs.documentstatus}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          AgreementsViewDialogClick(
                                            AgreementsDocs.documentlink
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow align="center">
                              <TableCell align="center" colSpan={12}>
                                Signed Agreements Not Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={signedAgreementsList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </div>
              ) : (
                <div>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    onClick={() => {
                      viewUnSignedAgreementsClick();
                    }}
                  >
                    View Signed Agreements
                  </Button>
                  <br />
                  <br />
                  <Paper sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearchUnsigned(searchVal)}
                      onCancelSearch={() => cancelSearchUnsigned()}
                    />
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow align="center">
                            <TableCell align="center" colSpan={12}>
                              List of Unsigned Agreements
                            </TableCell>
                          </TableRow>
                          <TableRow align="center">
                            <TableCell align="center" colSpan={1}>
                              Document Type
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              Document Status
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              Option
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {unSignedAgreementsList.length > 0 ? (
                            unSignedAgreementsList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((AgreementsDocs, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {AgreementsDocs.documenttype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {AgreementsDocs.documentstatus}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          AgreementsViewDialogClick(
                                            AgreementsDocs.documentlink
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow align="center">
                              <TableCell align="center" colSpan={12}>
                                UnSigned Agreements Not Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={unSignedAgreementsList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </div>
              )}
            </div>
          )}
          {gridView && (
            <div>
              {viewSignedAgreements ? (
                <div>
                  <h3>List of Signed Agreements</h3>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    onClick={() => {
                      viewSignedAgreementsClick();
                    }}
                  >
                    View UnSigned Agreements
                  </Button>
                  <br />
                  <br />
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearchSigned(searchVal)}
                    onCancelSearch={() => cancelSearchSigned()}
                  />
                  <div className="cardStyleForDashboardItems">
                    {isLoading ? (
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="cardStyleForDashboardItems">
                        {signedAgreementsList.length > 0 ? (
                          <div className="cardStyleForDashboardItems">
                            {signedAgreementsList.map(
                              (agreementsdocs, index) => (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <p>
                                    <b>Document Type:</b>
                                    {agreementsdocs.documenttype}
                                  </p>
                                  <p>
                                    <b>Document Status:</b>
                                    {agreementsdocs.documentstatus}
                                  </p>

                                  <div>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        AgreementsViewDialogClick(
                                          agreementsdocs.documentlink
                                        );
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <h4>Signed Agreements not found.</h4>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <h3>List of Unsigned Agreements</h3>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    onClick={() => {
                      viewSignedAgreementsClick();
                    }}
                  >
                    View Signed Agreements
                  </Button>
                  <br />
                  <br />
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearchUnsigned(searchVal)}
                    onCancelSearch={() => cancelSearchUnsigned()}
                  />
                  <div className="cardStyleForDashboardItems">
                    {isLoading ? (
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="cardStyleForDashboardItems">
                        {unSignedAgreementsList.length > 0 ? (
                          <div className="cardStyleForDashboardItems">
                            {unSignedAgreementsList.map(
                              (agreementsdocs, index) => (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <p>
                                    <b>Document Type:</b>
                                    {agreementsdocs.documenttype}
                                  </p>
                                  <p>
                                    <b>Document Status:</b>
                                    {agreementsdocs.documentstatus}
                                  </p>

                                  <div>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        AgreementsViewDialogClick(
                                          agreementsdocs.documentlink
                                        );
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <h4>Unsigned Agreements not found.</h4>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={AgreementsViewDialog}
          onClose={handleClose}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Agreements Document"}
          </DialogTitle>
          <DialogContent>
            <embed
              accept="image/*,.pdf"
              className="viewWidthHeight"
              src={AgreementsDocumentLink}
            ></embed>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAgreementsViewDialog(!AgreementsViewDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={uploadAgreementsDialog}
          onClose={handleClose}
          aria-labelledby="uploadAgreement"
        >
          <DialogTitle id="uploadAgreement">{"Upload Agreement"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="parentId">Parent Id :</InputLabel>
              <Input
                disabled
                id="parentId"
                name="parentId"
                type="text"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="parentId">User Name :</InputLabel>
              <Input
                disabled
                id="parentId"
                name="parentId"
                type="text"
                value={username}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="doc">file :</InputLabel>
              <Input
                id="doc"
                name="doc"
                type="file"
                onChange={(e) => {
                  setAgreementsFile(e.target.files[0]);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                AgreementsSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUploadAgreementsDialog(!uploadAgreementsDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
