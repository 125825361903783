import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "12px"
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const [searchVal, setSearchVal] = React.useState('');

  React.useEffect(() => {
    if (props.val) {
      setSearchVal(props.val);
    }
   
  });

  return (
    <Paper component="form" className={classes.root}>

      <InputBase
        className={classes.input}
        placeholder={props.placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        value={searchVal}
        onChange={(evt) => setSearchVal(evt.target.value)}
      />

      <IconButton
        className={classes.iconButton} aria-label="search"
        onClick={(typeof props.onSubmit == 'function') ? props.onSubmit.bind(this, searchVal) : ''}>
        <SearchIcon />

      </IconButton>
    </Paper>
  );
}