import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import "./CustomerCareComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import { Dialog } from "@mui/material";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function CustomerCareComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading,setIsLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [customerSupportList, setCustomerSupportList] = useState([]);
  const [filteredCustomerSupportList, setFilteredCustomerSupportList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [searched, setSearched] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createCustomerCareDialog, setCreateCustomerCareDialog] =
    useState(false);
    const [updateCustomerCareDialog, setUpdateCustomerCareDialog] =
    useState(false);
    const [deleteCustomerCareDialog, setDeleteCustomerCareDialog] =
    useState(false);
  const [setAboutUsLink, getAboutUsLink] = useState("");
  const [setCustomerCareNumber, getCustomerCareNumber] = useState("");
  const [setCustomerCareEmail, getCustomerCareEmail] = useState("");
  const [setCustomerCareFax, getCustomerCareFax] = useState("");
  const [setAddress, getAddress] = useState("");
  const [selectedCustomerCareAboutUsLink,setSelectedCustomerCareAboutUsLink] = useState("");
  const [selectedCustomerCareAddress,setSelectedCustomerCareAddress] = useState("");
  const [selectedCustomerCareEmail,setSelectedCustomerCareEmail] = useState("");
  const [selectedCustomerCareFax,setSelectedCustomerCareFax] = useState("");
  const [selectedCustomerCareNumber,setSelectedCustomerCareNumber] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);


  useEffect(() => {
    getCustomerSupportList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Infos */
  const getCustomerSupportList = () => {
    MainService.customerSupport(parentId)
      .then((res) => {
        setCustomerSupportList(res);
        setFilteredCustomerSupportList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All Infos */
  const handleClose = () => {};
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  

  
const listViewClick = () => {
  setListView(true);
  setGridView(false);
  
};
const gridViewClick = () => {
  setListView(false);
  setGridView(true);
  
};

  return (
    <>
      <div className="container" id="mainContentList">
        
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          
        </div>
        <h2>Customer Care Info</h2>
        {listView &&
        <Paper sx={{ width: "100%" }}>
        
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow align="center">
                <TableCell align="center" colSpan={1}>
                  About Us Link
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Number
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Email
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Fax
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Address
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {customerSupportList.length > 0 ? (
                customerSupportList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customercare, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell align="center">
                          {customercare.aboutusLink}
                        </TableCell>

                        <TableCell align="center">
                          {customercare.CustomerCare_number}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.CustomerCare_email}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.CustomerCare_fax}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.Address}
                        </TableCell>
                       
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow align="center">
                  <TableCell align="center" colSpan={12}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={customerSupportList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
}
        {gridView &&
        <div className="cardStyleForDashboardItems">
        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <div>
          
          <div className="cardStyleForDashboardItems">
            {customerSupportList.length > 0 ? (
              <div className="cardStyleForDashboardItems">
                {customerSupportList.map((customersupport, index) => (
                  <div
                    key={index}
                    className="cardStyleForDashboardItemsBody"
                  >
                    <span>
                      <p>
                        <b>About Us Link:</b>
                        {customersupport.aboutusLink}
                      </p>
                      <p>
                        <b>Customer Care Number:</b>
                        {customersupport.CustomerCare_number}
                      </p>
                    </span>
                    <p>
                      <b>Customer Care Email:</b>
                      {customersupport.CustomerCare_email}
                    </p>
                    <p>
                      <b> Customer Care Fax:</b>
                      {customersupport.CustomerCare_fax}
                    </p>
                    
                    <p>
                      <b>Address:</b>
                      {customersupport.Address}
                    </p>                   
                    
                  </div>
                ))}
              </div>
            ) : (
              <h4>Info not found.</h4>
            )}
          </div>
          </div>
        )}
      </div>
        }     

        
      </div>
    </>
  );
}
