import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import "./OperatorSiteManagementComponent.css";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
}));

export default function OperatorSiteManagementComponent() {
   
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [viewAllSites, setViewallSites] = useState(false);
  const [viewAllGridSites, setViewallGridSites] = useState(false);
  const [viewAllMapSites, setViewallMapSites] = useState(true);
  const [showAssetList, hideAssetList] = useState(false);
  const [showAssetGrid, hideAssetGrid] = useState(false);
  const [showAssetMap, hideAssetMap] = useState(false);
  const [showDistricts, setShowDistricts] = useState(false);
  const [mappedAssetShow, setMappedAssetShow] = useState(false);
  const [mapWithSite,setMapWithSites] = useState(false);
  const [showSiteRating, setShowSiteRating] = useState(false);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [getAllSites, setAllSites] = useState([]);
  const getDistrict = 'District';
  const [getMappedAssets, setMappedAssets] = useState([]);
  const [getMapwithSite, setMapwithSite] = useState([]);
  const [getSiteId, setSiteId] = useState("");
  const [redirectSiteId, setRedirectSiteId] = useState("");
  const [getSiteRatings, setSiteRatings] = useState([]);
  const [getSiteRateSiteId, setSiteRateSiteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* Create Form Site */
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [getSiteName, setSiteName] = useState("");
  const [getMailId, setMailId] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getSiteType, setSiteType] = useState("");
  const [getSiteData, setSiteData] = useState([]);
  const assetVerticalIdValue =  '6a112364-7adc-47c7-8436-47479d11fcf3';
  const assetVerticalFriendlyNameValue =  'Animal';
  /* End Create Form Site */
  /* Site Rating */
  const [getSiteRatePopup, setSiteRatePopUp] = useState(false);
  const [defaultSiteId, setDefaultSiteId] = useState("");
  const [setupdateSiteRating, getUpDateSiteRating] = useState("");
  /* End Of Site Rating */
  /* Site Info */
  const [setSiteInfoPopup, getSiteInfoPopUp] = useState(false);
  const [setInfoSiteId, getInfoSiteId] = useState("");
  const [setSiteInfoName, getSiteInfoName] = useState("");
  const [setPhoneNumber, getPhoneNumber] = useState("");
  const [setEmail, getEmail] = useState("");
  const [setSiteInfoExistingSiteType, getSiteInfoExistingSiteType] = useState("");
  const [setUpdateSiteType, getUpdateSiteType] = useState("");
  const [setWebsiteUrl, getWebsiteUrl] = useState("");
  const [setLocAddress, getLocAddress] = useState("");
  const [setSiteInfoAssetVerticalId, getSiteInfoAssetVerticalId] = useState("");
  const [setIndustryType, getIndustryType] = useState("");
  const [setStreetName, getStreetName] = useState("");
  const [setStreetNumber, getStreetNumber] = useState("");
  const [setLocality, getLocality] = useState("");
  const [setArea, getArea] = useState("");
  const [setRegion, getRegion] = useState("");
  const [setCountry, getCountry] = useState("");
  const [setPostalCode, getPostalCode] = useState("");
  const [setLatitude, getLatitude] = useState("");
  const [setLongitude, getLongitude] = useState("");
  const [setAltitude, getAltitude] = useState("");
  const [setTimeZone, getTimeZone] = useState("");
  const [setCityCode, getCityCode] = useState("");
  const [setCityName, getCityName] = useState("");
  const [setCountryCode, getCountryCode] = useState("");
  const [setStdCode, getStdCode] = useState("");
  /* End Of Site Info */
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [setmarker_position, getmarker_position] = useState({ lat: 13.093426,lng: 77.655773});  
  /********** Asset Onboarding Info ****************/
  const [showOnboarding, hideOnboarding] = useState(false);
  const [getViewAssetOnboarding, setViewAssetOnboarding] = useState([]);

  const [showViewAssetOnboarding, hideViewAssetOnboarding] = useState(false);

  const [getUpdateAssetOnboardingInfoPopup, 
    setUpdateAssetOnboardingInfoPopup] = useState(false);
  const [getUpdateAssetId, setUpdateAssetId] = useState("");
  const [getWhiteLabelCost, setWhiteLabelCost] = useState("");
  const [getExistingAssetStatus, setExistingAssetStatus] = useState("");
  const [getUpdatedAssetStatus, setUpdatedAssetStatus] = useState("");
  /********** End Of Asset Onboarding Info ****************/
  /********** Birth ****************/
  const [showBirth, hideBirth] = useState(false);
  const [showNewBirthInfoPopup, hideNewBirthInfoPopup] = useState(false);
  const [getBirthInfos, setBirthInfos] = useState([]);

  const [getNewBirthAssetid, setNewBirthAssetid] = useState("");
  const [getNewBirthAssetFriendlyName, setNewBirthAssetFriendlyName] = useState("");
  const [getNewBirthUserid, setNewBirthUserid] = useState(username);
  const [getNewBirthUserName, setNewBirthUserName] = useState("");
  const [getNewBirthSiteid, setNewBirthSiteid] = useState("");
  const [getNewBirthSiteName, setNewBirthSiteName] = useState("");
  const [getNewBirthLotid, setNewBirthLotid] = useState("");
  const [getNewBirthLotName, setNewBirthLotName] = useState("");
  const [getNewBirthLegacyAssetType, setNewBirthLegacyAssetType] = useState("");
  const [getNewBirthModelid, setNewBirthModelid] = useState("");
  const [getNewBirthModelFriendlyName, setNewBirthModelFriendlyName] = useState("");
  const [getNewBirthModelType, setNewBirthModelType] = useState("");
  const [getNewBirthModelURL, setNewBirthModelURL] = useState("");
  const [getNewBirthDOB, setNewBirthDOB] = useState("");
  const [getNewBirthGender, setNewBirthGender] = useState("");
  const [getNewBirthMaleParent, setNewBirthMaleParent] = useState("");
  const [getNewBirthFemaleParent, setNewBirthFemaleParent] = useState("");
  const [getNewBirthParentModelid, setNewBirthParentModelid] = useState("");
  const [getNewBirthLatitude, setNewBirthLatitude] = useState("");
  const [getNewBirthLongitude, setNewBirthLongitude] = useState("");
  const [getNewBirthAltitude, setNewBirthAltitude] = useState("");
  const [getNewBirthDescription, setNewBirthDescription] = useState("");

  const [getDeleteBirthInfoPopup, setDeleteBirthInfoPopup] = useState(false);
  const [getDeleteBirthInfo, setDeleteBirthInfo] = useState("");

  const [showUpdateBirthInfoPopup, hideUpdateBirthInfoPopup] = useState(false);
  const [getUpdateNewBirthAssetid, setUpdateNewBirthAssetid] = useState("");
  const [getUpdateNewBirthAssetFriendlyName, setUpdateNewBirthAssetFriendlyName] = useState("");
  const [getUpdateNewBirthUserid, setUpdateUpdateNewBirthUserid] = useState("");
  const [getUpdateNewBirthUserName, setUpdateNewBirthUserName] = useState("");
  const [getUpdateNewBirthSiteid, setUpdateNewBirthSiteid] = useState("");
  const [getUpdateNewBirthSiteName, setUpdateNewBirthSiteName] = useState("");
  const [getUpdateNewBirthLotid, setUpdateNewBirthLotid] = useState("");
  const [getUpdateNewBirthLotName, setUpdateNewBirthLotName] = useState("");
  const [getUpdateNewBirthLegacyAssetType, setUpdateNewBirthLegacyAssetType] = useState("");
  const [getUpdateNewBirthModelid, setUpdateNewBirthModelid] = useState("");
  const [getUpdateNewBirthModelFriendlyName, setUpdateNewBirthModelFriendlyName] = useState("");
  const [getUpdateNewBirthModelType, setUpdateNewBirthModelType] = useState("");
  const [getUpdateNewBirthModelURL, setUpdateNewBirthModelURL] = useState("");
  const [getUpdateNewBirthDOB, setUpdateNewBirthDOB] = useState("");
  const [getUpdateNewBirthGender, setUpdateNewBirthGender] = useState("");
  const [getUpdateNewBirthMaleParent, setUpdateNewBirthMaleParent] = useState("");
  const [getUpdateNewBirthFemaleParent, setUpdateNewBirthFemaleParent] = useState("");
  const [getUpdateNewBirthParentModelid, setUpdateNewBirthParentModelid] = useState("");
  const [getUpdateNewBirthLatitude, setUpdateNewBirthLatitude] = useState("");
  const [getUpdateNewBirthLongitude, setUpdateNewBirthLongitude] = useState("");
  const [getUpdateNewBirthAltitude, setUpdateNewBirthAltitude] = useState("");
  const [getUpdateNewBirthDescription, setUpdateNewBirthDescription] = useState("");
  /********** End Of Birth ****************/
  /********** Back Button ****************/
  const [getBackType, setBackType] = useState("");
  /********** End Of Back Button ****************/
   /********** Attendance Location ****************/
   const [showAttendanceLocation, hideAttendanceLocation] = useState(false);
   const [getAttendanceLocationHistory, setAttendanceLocationHistory] = useState([]);

   const [showNewAttendanceLocationPopup, hideNewAttendanceLocationPopup] = useState(false);
   const [getNewAttendanceLocationAssetid, setNewAttendanceLocationAssetid] = useState("");
   const [getNewAttendanceLocationAssetfriendlyName, setNewAttendanceLocationAssetfriendlyName] = useState("");
   const [getNewAttendanceLocationUserid, setNewAttendanceLocationUserid] = useState(username);
   const [getNewAttendanceLocationLatitude, setNewAttendanceLocationLatitude] = useState("");
   const [getNewAttendanceLocationLongitude, setNewAttendanceLocationLongitude] = useState("");
   const [getNewAttendanceLocationAltitude, setNewAttendanceLocationAltitude] = useState("");
   const [getNewAttendanceLocationDescription, setNewAttendanceLocationDescription] = useState("");

   const [getDeletedAttendanceAssetid, setDeletedAttendanceAssetid] = useState("");
   const [getDeletedAttendancePopup, setDeletedAttendancePopup] = useState(false);
   
   const [getUpdateAttendanceLocationInfoAssetid, setUpdateAttendanceLocationInfoAssetid] = useState("");
   const [getUpdateAttendanceLocationInfoAssetfriendlyName, 
          setUpdateAttendanceLocationInfoAssetfriendlyName] = useState("");
   const [getUpdateAttendanceLocationInfoUserid, setUpdateAttendanceLocationInfoUserid] = useState("");
   const [getUpdateAttendanceLocationInfoLatitude, setUpdateAttendanceLocationInfoLatitude] = useState("");
   const [getUpdateAttendanceLocationInfoLongitude, setUpdateAttendanceLocationInfoLongitude] = useState("");
   const [getUpdateAttendanceLocationInfoAltitude, setUpdateAttendanceLocationInfoAltitude] = useState("");
   const [getUpdateAttendanceLocationInfoDescription, setUpdateAttendanceLocationInfoDescription] = useState("");
   const [showUpdateAttendaneLocationPopup, hideUpdateAttendaneLocationPopup] = useState(false);
   /********** End Of Attendance Location ****************/
   /********** Health Record ****************/
   const [showHealthRecords, hideHealthRecords] = useState(false);
   const [showNewHealthRecordPopup, hideNewHealthRecordPopup] = useState(false);
   const [getHealthRecords, setHealthRecords] = useState([]);

   const [getNewHealthAssetid, setNewHealthAssetid] = useState("");
   const [getNewHealthAssetFriendlyName, setNewHealthAssetFriendlyName] = useState("");
   const [getNewHealthUserid, setNewHealthUserid] = useState(username);
   const [getNewHealthMedicineDate, setNewHealthMedicineDate] = useState("");
   const [getNewHealthVaccinationDate, setNewHealthVaccinationDate] = useState("");
   const [getNewHealthExamine, setNewHealthExamine] = useState("");
   const [getNewHealthSurgicalProcedure, setNewHealthSurgicalProcedure] = useState("");
   const [getNewHealthVaccinationType, setNewHealthVaccinationType] = useState("");
   const [getNewHealthMedicineType, setNewHealthMedicineType] = useState("");
   const [getNewHealthDiseaseType, setNewHealthDiseaseType] = useState("");
   const [getNewHealthTreatmentDate, setNewHealthTreatmentDate] = useState("");
   const [getNewHealthLatitude, setNewHealthLatitude] = useState("");
   const [getNewHealthLongitude, setNewHealthLongitude] = useState("");
   const [getNewHealthAltitude, setNewHealthAltitude] = useState("");
   const [getNewHealthDescription, setNewHealthDescription] = useState("");

   const [getDeleteHealthAssetid, setDeleteHealthAssetid] = useState("");
   const [showDeleteHealthAssetPopup, hideDeleteHealthAssetPopup] = useState(false);

   const [showUpdateNewHealthRecordPopup, hideUpdateNewHealthRecordPopup] = useState(false);
   const [getUpdateNewHealthAssetid, setUpdateNewHealthAssetid] = useState("");
   const [getUpdateNewHealthAssetFriendlyName, setUpdateNewHealthAssetFriendlyName] = useState("");
   const [getUpdateNewHealthUserid, setUpdateNewHealthUserid] = useState("");
   const [getUpdateNewHealthMedicineDate, setUpdateNewHealthMedicineDate] = useState("");
   const [getUpdateNewHealthVaccinationDate, setUpdateNewHealthVaccinationDate] = useState("");
   const [getUpdateNewHealthExamine, setUpdateNewHealthExamine] = useState("");
   const [getUpdateNewHealthSurgicalProcedure, setUpdateNewHealthSurgicalProcedure] = useState("");
   const [getUpdateNewHealthVaccinationType, setUpdateNewHealthVaccinationType] = useState("");
   const [getUpdateNewHealthMedicineType, setUpdateNewHealthMedicineType] = useState("");
   const [getUpdateNewHealthDiseaseType, setUpdateNewHealthDiseaseType] = useState("");
   const [getUpdateNewHealthTreatmentDate, setUpdateNewHealthTreatmentDate] = useState("");
   const [getUpdateNewHealthLatitude, setUpdateNewHealthLatitude] = useState("");
   const [getUpdateNewHealthLongitude, setUpdateNewHealthLongitude] = useState("");
   const [getUpdateNewHealthAltitude, setUpdateNewHealthAltitude] = useState("");
   const [getUpdateNewHealthDescription, setUpdateNewHealthDescription] = useState("");
   /********** End Of Health Record ****************/
   /********** Nutrition ****************/
   const [showNutrition, hideNutrition] = useState(false);
   const [getNutritions, setNutritions] = useState([]);

   const [showAddNutritionPopup, hideAddNutritionPopup] = useState(false);
   const [getAddNutritionAssetid, setAddNutritionAssetid] = useState("");
   const [getAddNutritionAssetFriendlyName, setAddNutritionAssetFriendlyName] = useState("");
   const [getAddNutritionUserid, setAddNutritionUserid] = useState(username);
   const [getAddNutritionFeedType, setAddNutritionFeedType] = useState("");
   const [getAddNutritionSchedule, setAddNutritionSchedule] = useState("");
   const [getAddNutritionLatitude, setAddNutritionLatitude] = useState("");
   const [getAddNutritionLongitude, setAddNutritionLongitude] = useState("");
   const [getAddNutritionAltitude, setAddNutritionAltitude] = useState("");
   const [getAddNutritionDescription, setAddNutritionDescription] = useState("");

   const [showDeleteNutrition, hideDeleteNutrition] = useState(false);
   const [getDeleteNutritionAssetid, setDeleteNutritionAssetid] = useState("");

   const [showUpdateAddNutritionPopup, hideUpdateAddNutritionPopup] = useState(false);
   const [getUpdateAddNutritionAssetid, setUpdateAddNutritionAssetid] = useState("");
   const [getUpdateAddNutritionAssetFriendlyName, setUpdateAddNutritionAssetFriendlyName] = useState("");
   const [getUpdateAddNutritionUserid, setUpdateAddNutritionUserid] = useState("");
   const [getUpdateAddNutritionFeedType, setUpdateAddNutritionFeedType] = useState("");
   const [getUpdateAddNutritionSchedule, setUpdateAddNutritionSchedule] = useState("");
   const [getUpdateAddNutritionLatitude, setUpdateAddNutritionLatitude] = useState("");
   const [getUpdateAddNutritionLongitude, setUpdateAddNutritionLongitude] = useState("");
   const [getUpdateAddNutritionAltitude, setUpdateAddNutritionAltitude] = useState("");
   const [getUpdateAddNutritionDescription, setUpdateAddNutritionDescription] = useState("");
   const [getUpdateAddNutritionExistingFeedType, setUpdateAddNutritionExistingFeedType] = useState("");
   /********** End Of Nutrition ****************/
   /********** Weighing ****************/
   const [showWeighing, hideWeighing] = useState(false);
   const [getWeighingDetail, setWeighingDetail] = useState([]);

   const [showNewWeighingPopup, hideNewWeighingPopup] = useState(false);
   const [getNewWeighingAssetid, setNewWeighingAssetid] = useState("");
   const [getNewWeighingAssetFriendlyName, setNewWeighingAssetFriendlyName] = useState("");
   const [getNewWeighingUserid, setNewWeighingUserid] = useState(username);
   const [getNewWeighingWeightAtBirth, setNewWeighingWeightAtBirth] = useState("");
   const [getNewWeighingWeaningAge, setNewWeighingWeaningAge] = useState("");
   const [getNewWeighingWeaningWeight, setNewWeighingWeaningWeight] = useState("");
   const [getNewWeighingAgeAtSlaughter, setNewWeighingAgeAtSlaughter] = useState("");
   const [getNewWeighingCarcassWeight, setNewWeighingCarcassWeight] = useState("");
   const [getNewWeighingGender, setNewWeighingGender] = useState("");
   const [getNewWeighingLatitude, setNewWeighingLatitude] = useState("");
   const [getNewWeighingLongitude, setNewWeighingLongitude] = useState("");
   const [getNewWeighingAltitude, setNewWeighingAltitude] = useState("");
   const [getNewWeighingDescription, setNewWeighingDescription] = useState("");
   const [getNewWeighingMimetype, setNewWeighingMimetype] = useState("");
   const [getNewWeighingWidth, setNewWeighingWidth] = useState("");
   const [getNewWeighingHeight, setNewWeighingHeight] = useState("");
   const [getNewWeighingDepth, setNewWeighingDepth] = useState("");
   const [getNewWeighingImage, setNewWeighingImage] = useState("");

   const [showWeighingDeletePopup, hideWeighingDeletePopup] = useState(false);
   const [getWeighingDeleteAssetid, setWeighingDeleteAssetid] = useState("");

   const [showUpdateNewWeighingPopup, hideUpdateNewWeighingPopup] = useState(false);
   const [getUpdateNewWeighingAssetid, setUpdateNewWeighingAssetid] = useState("");
   const [getUpdateNewWeighingAssetFriendlyName, setUpdateNewWeighingAssetFriendlyName] = useState("");
   const [getUpdateNewWeighingUserid, setUpdateNewWeighingUserid] = useState("");
   const [getUpdateNewWeighingWeightAtBirth, setUpdateNewWeighingWeightAtBirth] = useState("");
   const [getUpdateNewWeighingWeaningAge, setUpdateNewWeighingWeaningAge] = useState("");
   const [getUpdateNewWeighingWeaningWeight, setUpdateNewWeighingWeaningWeight] = useState("");
   const [getUpdateNewWeighingAgeAtSlaughter, setUpdateNewWeighingAgeAtSlaughter] = useState("");
   const [getUpdateNewWeighingCarcassWeight, setUpdateNewWeighingCarcassWeight] = useState("");
   const [getUpdateNewWeighingGender, setUpdateNewWeighingGender] = useState("");
   const [getUpdateNewWeighingLatitude, setUpdateNewWeighingLatitude] = useState("");
   const [getUpdateNewWeighingLongitude, setUpdateNewWeighingLongitude] = useState("");
   const [getUpdateNewWeighingAltitude, setUpdateNewWeighingAltitude] = useState("");
   const [getUpdateNewWeighingDescription, setUpdateNewWeighingDescription] = useState("");
   const [getUpdateNewWeighingMimetype, setUpdateNewWeighingMimetype] = useState("");
   const [getUpdateNewWeighingWidth, setUpdateNewWeighingWidth] = useState("");
   const [getUpdateNewWeighingHeight, setUpdateNewWeighingHeight] = useState("");
   const [getUpdateNewWeighingDepth, setUpdateNewWeighingDepth] = useState("");
   const [getUpdateNewWeighingImage, setUpdateNewWeighingImage] = useState("");
   /********** End Of Weighing ****************/
   /********** Parturition ****************/
   const [showParturition, hideParturition] = useState(false);
   const [getParturitionDetail, setParturitionDetail] = useState([]);

   const [showNewParturitionPopup, hideNewParturitionPopup] = useState(false);
   const [getNewParturitionAssetid, setNewParturitionAssetid] = useState("");
   const [getNewParturitionAssetfriendlyName, setNewParturitionAssetfriendlyName] = useState("");
   const [getNewParturitionUserid, setNewParturitionUserid] = useState(username);
   const [getNewParturitionWeightAtFirstMating, setNewParturitionWeightAtFirstMating] = useState("");
   const [getNewParturitionAgeAtFirstParturition, setNewParturitionAgeAtFirstParturition] = useState("");
   const [getNewParturitionBodyConditionsIndex, setNewParturitionBodyConditionsIndex] = useState("");
   const [getNewParturitionNumberOfMatings, setNewParturitionNumberOfMatings] = useState("");
   const [getNewParturitionSupermogram, setNewParturitionSupermogram] = useState("");
   const [getNewParturitionBreed, setNewParturitionBreed] = useState("");
   const [getNewParturitionBreedPercentage, setNewParturitionBreedPercentage] = useState("");
   const [getNewParturitionBreederId, setNewParturitionBreederId] = useState("");
   const [getNewParturitionBreederAge, setNewParturitionBreederAge] = useState("");
   const [getNewParturitionBreedingDate, setNewParturitionBreedingDate] = useState("");
   const [getNewParturitionNextBreeding, setNewParturitionNextBreeding] = useState("");
   const [getNewParturitionExpectedKiddingDate, setNewParturitionExpectedKiddingDate] = useState("");
   const [getNewParturitionDeliveredDate, setNewParturitionDeliveredDate] = useState("");
   const [getNewParturitionTotalKidsDelivered, setNewParturitionTotalKidsDelivered] = useState("");
   const [getNewParturitionDisposal, setNewParturitionDisposal] = useState("");
   const [getNewParturitionLatitude, setNewParturitionLatitude] = useState("");
   const [getNewParturitionLongitude, setNewParturitionLongitude] = useState("");
   const [getNewParturitionAltitude, setNewParturitionAltitude] = useState("");
   const [getNewParturitionDescription, setNewParturitionDescription] = useState("");

   const [showParturitionDeletePopup, hideParturitionDeletePopup] = useState(false);
   const [getParturitionDeleteAssetid, setParturitionDeleteAssetid] = useState("");

   const [showUpdateNewParturitionPopup, hideUpdateNewParturitionPopup] = useState(false);
   const [getUpdateNewParturitionAssetid, setUpdateNewParturitionAssetid] = useState("");
   const [getUpdateNewParturitionAssetfriendlyName, setUpdateNewParturitionAssetfriendlyName] = useState("");
   const [getUpdateNewParturitionUserid, setUpdateNewParturitionUserid] = useState("");
   const [getUpdateNewParturitionWeightAtFirstMating, setUpdateNewParturitionWeightAtFirstMating] = useState("");
   const [getUpdateNewParturitionAgeAtFirstParturition, setUpdateNewParturitionAgeAtFirstParturition] = useState("");
   const [getUpdateNewParturitionBodyConditionsIndex, setUpdateNewParturitionBodyConditionsIndex] = useState("");
   const [getUpdateNewParturitionNumberOfMatings, setUpdateNewParturitionNumberOfMatings] = useState("");
   const [getUpdateNewParturitionSupermogram, setUpdateNewParturitionSupermogram] = useState("");
   const [getUpdateNewParturitionBreed, setUpdateNewParturitionBreed] = useState("");
   const [getUpdateNewParturitionBreedPercentage, setUpdateNewParturitionBreedPercentage] = useState("");
   const [getUpdateNewParturitionBreederId, setUpdateNewParturitionBreederId] = useState("");
   const [getUpdateNewParturitionBreederAge, setUpdateNewParturitionBreederAge] = useState("");
   const [getUpdateNewParturitionBreedingDate, setUpdateNewParturitionBreedingDate] = useState("");
   const [getUpdateNewParturitionNextBreeding, setUpdateNewParturitionNextBreeding] = useState("");
   const [getUpdateNewParturitionExpectedKiddingDate, setUpdateNewParturitionExpectedKiddingDate] = useState("");
   const [getUpdateNewParturitionDeliveredDate, setUpdateNewParturitionDeliveredDate] = useState("");
   const [getUpdateNewParturitionTotalKidsDelivered, setUpdateNewParturitionTotalKidsDelivered] = useState("");
   const [getUpdateNewParturitionDisposal, setUpdateNewParturitionDisposal] = useState("");
   const [getUpdateNewParturitionLatitude, setUpdateNewParturitionLatitude] = useState("");
   const [getUpdateNewParturitionLongitude, setUpdateNewParturitionLongitude] = useState("");
   const [getUpdateNewParturitionAltitude, setUpdateNewParturitionAltitude] = useState("");
   const [getUpdateNewParturitionDescription, setUpdateNewParturitionDescription] = useState("");
   /********** End Of Parturition ****************/
  /********** Mortality ****************/
  const [showMortality, hideMortality] = useState(false);
  const [getMortalityDetails, setMortalityDetails] = useState([]);
  
  const [showNewMortalityPopuop, hideNewMortalityPopuop] = useState(false);
  const [getNewMortalityAssetid, setNewMortalityAssetid] = useState("");
  const [getNewMortalityAssetFriendlyName, setNewMortalityAssetFriendlyName] = useState("");
  const [getNewMortalityUserid, setNewMortalityUserid] = useState(username);
  const [getNewMortalityModelid, setNewMortalityModelid] = useState("");
  const [getNewMortalityBreedPercentage, setNewMortalityBreedPercentage] = useState("");
  const [getNewMortalityGender, setNewMortalityGender] = useState("");
  const [getNewMortalityBirthDate, setNewMortalityBirthDate] = useState("");
  const [getNewMortalityExpiredDate, setNewMortalityExpiredDate] = useState("");
  const [getNewMortalityTotalAge, setNewMortalityTotalAge] = useState("");
  const [getNewMortalityCurrentValue, setNewMortalityCurrentValue] = useState("");
  const [getNewMortalityCauseOfDeath, setNewMortalityCauseOfDeath] = useState("");
  const [getNewMortalityLatitude, setNewMortalityLatitude] = useState("");
  const [getNewMortalityLongitude, setNewMortalityLongitude] = useState("");
  const [getNewMortalityAltitude, setNewMortalityAltitude] = useState("");
  const [getNewMortalityDescription, setNewMortalityDescription] = useState("");

  const [showMortalityDeletePopup, hideMortalityDeletePopup] = useState(false);
  const [getMortalityDeleteAssetid, setMortalityDeleteAssetid] = useState("");

  const [showUpdateNewMortalityPopuop, hideUpdateNewMortalityPopuop] = useState(false);
  const [getUpdateNewMortalityAssetid, setUpdateNewMortalityAssetid] = useState("");
  const [getUpdateNewMortalityAssetFriendlyName, setUpdateNewMortalityAssetFriendlyName] = useState("");
  const [getUpdateNewMortalityUserid, setUpdateNewMortalityUserid] = useState(username);
  const [getUpdateNewMortalityModelid, setUpdateNewMortalityModelid] = useState("");
  const [getUpdateNewMortalityBreedPercentage, setUpdateNewMortalityBreedPercentage] = useState("");
  const [getUpdateNewMortalityGender, setUpdateNewMortalityGender] = useState("");
  const [getUpdateNewMortalityBirthDate, setUpdateNewMortalityBirthDate] = useState("");
  const [getUpdateNewMortalityExpiredDate, setUpdateNewMortalityExpiredDate] = useState("");
  const [getUpdateNewMortalityTotalAge, setUpdateNewMortalityTotalAge] = useState("");
  const [getUpdateNewMortalityCurrentValue, setUpdateNewMortalityCurrentValue] = useState("");
  const [getUpdateNewMortalityCauseOfDeath, setUpdateNewMortalityCauseOfDeath] = useState("");
  const [getUpdateNewMortalityLatitude, setUpdateNewMortalityLatitude] = useState("");
  const [getUpdateNewMortalityLongitude, setUpdateNewMortalityLongitude] = useState("");
  const [getUpdateNewMortalityAltitude, setUpdateNewMortalityAltitude] = useState("");
  const [getUpdateNewMortalityDescription, setUpdateNewMortalityDescription] = useState("");
  /********** End Of Mortality ****************/
  /********** Asset Operational Status ****************/
  const [showOperationalStatus, hideOperationalStatus] = useState(false);
  const [getOperationalStatusDetails, setOperationalStatusDetails] = useState([]);

  const [showNewAssetOperationalPopup, hideNewAssetOperationalPopup] = useState(false);
  const [getNewAssetOperationalAssetid, setNewAssetOperationalAssetid] = useState("");
  const [getNewAssetOperationalAssetFriendlyName, setNewAssetOperationalAssetFriendlyName] = useState("");
  const [getNewAssetOperationalUserid, setNewAssetOperationalUserid] = useState(username);
  const [getNewAssetOperationalStatus, setNewAssetOperationalStatus] = useState("");
  const [getNewAssetOperationalLatitude, setNewAssetOperationalLatitude] = useState("");
  const [getNewAssetOperationalLongitude, setNewAssetOperationalLongitude] = useState("");
  const [getNewAssetOperationalAltitude, setNewAssetOperationalAltitude] = useState("");
  const [getNewAssetOperationalDescription, setNewAssetOperationalDescription] = useState("");

  const [showAssetOperationalDeletePopup, hideAssetOperationalDeletePopup] = useState(false);
  const [getAssetOperationalDeleteAssetid, setAssetOperationalDeleteAssetid] = useState("");

  const [showUpdateNewAssetOperationalPopup, hideUpdateNewAssetOperationalPopup] = useState(false);
  const [getUpdateNewAssetOperationalAssetid, setUpdateNewAssetOperationalAssetid] = useState("");
  const [getUpdateNewAssetOperationalAssetFriendlyName, setUpdateNewAssetOperationalAssetFriendlyName] = useState("");
  const [getUpdateNewAssetOperationalUserid, setUpdateNewAssetOperationalUserid] = useState("");
  const [getUpdateNewAssetOperationalStatus, setUpdateNewAssetOperationalStatus] = useState("");
  const [getUpdateExistingNewAssetOperationalStatus, setUpdateExistingNewAssetOperationalStatus] = useState("");
  const [getUpdateNewAssetOperationalLatitude, setUpdateNewAssetOperationalLatitude] = useState("");
  const [getUpdateNewAssetOperationalLongitude, setUpdateNewAssetOperationalLongitude] = useState("");
  const [getUpdateNewAssetOperationalAltitude, setUpdateNewAssetOperationalAltitude] = useState("");
  const [getUpdateNewAssetOperationalDescription, setUpdateNewAssetOperationalDescription] = useState("");
  /********** End Of Asset Operational Status ****************/
  /**********  Asset Map UnMap Delete Update CrateAsset ****************/
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");

  const [getShowDeletePopup, setShowDeletePopup] = useState(false);
  const [getMappedAssetDeleteAssetid, setMappedAssetDeleteAssetid] = useState("");
  const [getMappedAssetDeleteAssetType, setMappedAssetDeleteAssetType] = useState("");
  const [getMappedAssetDeleteAssetModelid, setMappedAssetDeleteAssetModelid] = useState("");
  const [getMappedAssetDeleteAssetVerticalid, setMappedAssetDeleteAssetVerticalid] = useState("");
  const [getMappedAssetDeleteAssetCategort, setMappedAssetDeleteAssetCategory] = useState("");

  const [getlegacyUpdatePopup, setLegacyUpdatePopup] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  /********** End Of Asset Map UnMap Delete Update CrateAsset ****************/
   /**************************** Create Asset ********************************/
   const [verticalsIdList, setVerticalsIdList] = useState([]);
   const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
   const [categoryTypesList, setCategoryTypesList] = useState([]);
   const [selectedCategory, setSelectedCategory] = useState("");
   const [assertTypesList, setAssertTypesList] = useState([]);
   const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
   const [modelList, setModelsList] = useState([]);
   const [selectedModel, setSelectedModel] = useState("");
   const [selectedSite, setSelectedSite] = useState("");
   const [assetIdValue, setAssetIdValue] = useState("");
   const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
     useState("");
   const [assetVerticalFriendlyNameValues, setAssetVerticalFriendlyNameValue] =
     useState("");
   const [assetDomainValue, setAssetDomainValue] = useState("");
   const [siteNameValue, setSiteNameValue] = useState("");
   const [zoneValue, setZoneValue] = useState("");
   const [lotNameValue, setLotNameValue] = useState("");
   const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
   const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
   const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
   const [modelUrlValue, setModelUrlValue] = useState("");
   const [serialNoValue, setSerialNoValue] = useState("");
   const [udnValue, setUdnValue] = useState("");
   const [upcValue, setUpcValue] = useState("");
   const [motherBreedValue, setMotherBreedValue] = useState("");
   const [fatherBreedValue, setFatherBreedValue] = useState("");
   const [descriptionValue, setDescriptionValue] = useState("");
   const [widthValue, setWidthValue] = useState("");
   const [heightValue, setHeightValue] = useState("");
   const [depthValue, setDepthValue] = useState("");
   const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
   const [sellingCostValue, setSellingCostValue] = useState("");
   const [acquireCostValue, setAcquireCostValue] = useState("");
   const [latitudeValue, setLatitudeValue] = useState("");
   const [longitudeValue, setLongitudeValue] = useState("");
   const [altitudeValue, setAltitudeValue] = useState("");
   const [getCreateAssetPopup, setCreateAssetPopup] = useState(false);
   /********************************** End Of Create Asset***********************/
   /********************************** Selling Cost *************************/
   const [showSelling, setShowSelling] = useState(false);
   const [getSellingDetails, setSellingDetails] = useState([]);

   const [getAddSellingCostPopup, setAddSellingCostPopup] = useState(false);
   const [getNewSellingCostUserid, setNewSellingCostUserid] = useState(username);
   const [getNewSellingCostAssetid, setNewSellingCostAssetid] = useState("");
   const [getNewSellingCostAssetFriendlyName, setNewSellingCostAssetFriendlyName] = useState("");
   const [getNewSellingCostSellingCost, setNewSellingCostSellingCost] = useState("");
   /********************************** End Of Selling ***********************/
   /********** Patrolling ****************/
   const [showPatrolling, setShowPatrolling] = useState(false);
   const [getViewSitePatrolling, setViewSitePatrolling] = useState([]);

   const [showSitePatrolingPopup, getShowSitePatrolingPopup] = useState(false);
   const [getNewSitePatrolingSiteid, setNewSitePatrolingSiteid] = useState("");
   const [getNewSitePatrolingFriendlyName, setNewSitePatrolingFriendlyName] = useState("");
   const [getNewSitePatrolingUserid, setNewSitePatrolingUserid] = useState(username);
   const [getNewSitePatrolingMobileNumber, setNewSitePatrolingMobileNumber] = useState("");
   const [getNewSitePatrolingLatitude, setNewSitePatrolingLatitude] = useState("");
   const [getNewSitePatrolingLongitude, setNewSitePatrolingLongitude] = useState("");
   const [getNewSitePatrolingAltitude, setNewSitePatrolingAltitude] = useState("");
   const [getNewSitePatrolingDescription, setNewSitePatrolingDescription] = useState("");

   const [showUpdateSitePatrolingPopup, getUpdateShowSitePatrolingPopup] = useState(false);
   const [getUpdateNewSitePatrolingSiteid, setUpdateNewSitePatrolingSiteid] = useState("");
   const [getUpdateNewSitePatrolingFriendlyName, setUpdateNewSitePatrolingFriendlyName] = useState("");
   const [getUpdateNewSitePatrolingUserid, setUpdateNewSitePatrolingUserid] = useState(username);
   const [getUpdateNewSitePatrolingMobileNumber, setUpdateNewSitePatrolingMobileNumber] = useState("");
   const [getUpdateNewSitePatrolingLatitude, setUpdateNewSitePatrolingLatitude] = useState("");
   const [getUpdateNewSitePatrolingLongitude, setUpdateNewSitePatrolingLongitude] = useState("");
   const [getUpdateNewSitePatrolingAltitude, setUpdateNewSitePatrolingAltitude] = useState("");
   const [getUpdateNewSitePatrolingDescription, setUpdateNewSitePatrolingDescription] = useState("");

   const [showSitePatrolingDeletePopup, getShowSitePatrolingDeletePopup] = useState(false); 
   const [getDeleteSiteid, setDeleteSiteid] = useState("");
   
   const [showAssetPatrolling, setShowAssetPatrolling] = useState(false);
   const [getViewAssetPatrolling, setViewAssetSitePatrolling] = useState([]);
   const [getAssetBackButtton, setAssetBackButtton] = useState("");

   const [getShowNewAssetPatrollingPopup, setShowNewAssetPatrollingPopup ] = useState(false);
   const [getNewAssetPatrollingAssetid, setNewAssetPatrollingAssetid] = useState("");
   const [getNewAssetPatrollingAssetFriendlyName, setNewAssetPatrollingAssetFriendlyName] = useState("");
   const [getNewAssetPatrollingUserid, setNewAssetPatrollingUserid] = useState(username);
   const [getNewAssetPatrollingMobileNumber, setNewAssetPatrollingMobileNumber] = useState("");
   const [getNewAssetPatrollingLatitude, setNewAssetPatrollingLatitude] = useState("");
   const [getNewAssetPatrollingLongitude, setNewAssetPatrollingLongitude] = useState("");
   const [getNewAssetPatrollingAltitude, setNewAssetPatrollingAltitude] = useState("");
   const [getNewAssetPatrollingDescription, setNewAssetPatrollingDescription] = useState("");

   const [showAssetPatrolingDeletePopup, getShowAssetPatrolingDeletePopup] = useState(false); 
   const [getDeleteAssetid, setDeleteAssetid] = useState("");

   const [getUpdateShowNewAssetPatrollingPopup, setUpdateShowNewAssetPatrollingPopup ] = useState(false);
   const [getUpdateNewAssetPatrollingAssetid, setUpdateNewAssetPatrollingAssetid] = useState("");
   const [getUpdateNewAssetPatrollingAssetFriendlyName, setUpdateNewAssetPatrollingAssetFriendlyName] = useState("");
   const [getUpdateNewAssetPatrollingUserid, setUpdateNewAssetPatrollingUserid] = useState(username);
   const [getUpdateNewAssetPatrollingMobileNumber, setUpdateNewAssetPatrollingMobileNumber] = useState("");
   const [getUpdateNewAssetPatrollingLatitude, setUpdateNewAssetPatrollingLatitude] = useState("");
   const [getUpdateNewAssetPatrollingLongitude, setUpdateNewAssetPatrollingLongitude] = useState("");
   const [getUpdateNewAssetPatrollingAltitude, setUpdateNewAssetPatrollingAltitude] = useState("");
   const [getUpdateNewAssetPatrollingDescription, setUpdateNewAssetPatrollingDescription] = useState("");
   /********** End Of Patrolling ****************/
   /********** Site Feedback ****************/
   const [showSiteFeedbackModel, setShowSiteFeedbackModel] = useState(false);
   const [getSiteFeedbackSiteid, setSiteFeedbackSiteid] = useState("");

   const [getSiteCustomerExperience, setSiteCustomerExperience] = useState(0);
   const [getSiteCustomerExperienceHover, setSiteCustomerExperienceHover] = useState(-1);
   const [getSiteFeedbackCustomerExperience, setSiteFeedbackCustomerExperience] = useState("");

   const [getSiteProduct, setSiteProduct] = useState(0);
   const [getSiteProductHover, setSiteProductHover] = useState(-1);
   const [getSiteFeedbackProduct, setSiteFeedbackProduct] = useState("");

   const [getSiteCustomerSupport, setSiteCustomerSupport] = useState(0);
   const [getSiteCustomerSupportHover, setSiteCustomerSupportHover] = useState(-1);
   const [getSiteFeedbackCustomerSupport, setSiteFeedbackCustomerSupport] = useState("");

   const [getSiteDelivery, setSiteDelivery] = useState(0);
   const [getSiteDeliveryHover, setSiteDeliveryHover] = useState(-1);
   const [getSiteFeedbackDelivery, setSiteFeedbackDelivery] = useState("");

   const [getSiteServiceOthers, setSiteServiceOthers] = useState(0);
   const [getSiteServiceOthersHover, setSiteServiceOthersHover] = useState(-1);
   const [getSiteFeedbackServiceOthers, setSiteFeedbackServiceOthers] = useState("");

   const [getSiteFeedbackRemark, setSiteFeedbackRemark] = useState("");
   /********** End Of Site Feedback ****************/
   /********** Asset Feedback ****************/
   const [showAssetFeedbackModel, setShowAssetFeedbackModel] = useState(false);
   const [getAssetFeedbackAssetDeveloperid, setAssetFeedbackAssetDeveloperid] = useState(username);
   const [getAssetFeedbackAssetid, setAssetFeedbackAssetid] = useState("");

   const [getAssetCustomerExperience, setAssetCustomerExperience] = useState(0);
   const [getAssetCustomerExperienceHover, setAssetCustomerExperienceHover] = useState(-1);
   const [getAssetFeedbackCustomerExperience, setAssetFeedbackCustomerExperience] = useState("");

   const [getAssetProduct, setAssetProduct] = useState(0);
   const [getAssetProductHover, setAssetProductHover] = useState(-1);
   const [getAssetFeedbackProduct, setAssetFeedbackProduct] = useState("");

   const [getAssetCustomerSupport, setAssetCustomerSupport] = useState(0);
   const [getAssetCustomerSupportHover, setAssetCustomerSupportHover] = useState(-1);
   const [getAssetFeedbackCustomerSupport, setAssetFeedbackCustomerSupport] = useState("");

   const [getAssetDelivery, setAssetDelivery] = useState(0);
   const [getAssetDeliveryHover, setAssetDeliveryHover] = useState(-1);
   const [getAssetFeedbackDelivery, setAssetFeedbackDelivery] = useState("");

   const [getAssetServiceOthers, setAssetServiceOthers] = useState(0);
   const [getAssetServiceOthersHover, setAssetServiceOthersHover] = useState(-1);
   const [getAssetFeedbackServiceOthers, setAssetFeedbackServiceOthers] = useState("");

   const [getAssetFeedbackRemark, setAssetFeedbackRemark] = useState("");
   /********** End Of Asset Feedback ****************/

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(()=>{
    getAllSitesData();
  },[setAllSites]);

  useEffect(()=>{
    getAllSitesList();
  },[setSiteData]);

  useEffect(() => {
    getAssetVerticalsIdList();
  }, [setVerticalsIdList]);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  /* Onboarding Api's */

  const onBoardingButtonClickHandler = (siteid, backType) =>{
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    viewAssetOnboardingHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(true);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const viewAssetOnboardingHandler = () => {
    setIsLoading(true);
    MainService.ViewAssetOnboardings(username)
    .then(res=>{
      setIsLoading(false);
      setViewAssetOnboarding(res.readyForOnboardAssets);
    })
    .catch(err=>{
      return NotificationManager.err(err);
    })
  };

  const updateAssetOnboardingInfoPopup = (assets) =>{
    setUpdateAssetId(assets.assetid);
    setWhiteLabelCost(assets.whitelabelcost);
    setExistingAssetStatus(assets.assetstatus);
    setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
  };

  const updateReadyForOnboardAssetStatusHandler = () => {
    const updateAsset = {
      assetid: getUpdateAssetId,
	    whitelabelcost: getWhiteLabelCost,
	    assetstatus: getUpdatedAssetStatus,
    };
    const updateData = JSON.stringify(updateAsset);

    MainService.updateReadyForOnboardAssetStatus(updateData)
    .then(res=>{
      setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
      NotificationManager.success("asset status updated");
      viewAssetOnboardingHandler();
    })
    .catch(err=>{
      return NotificationManager.console.error("Something Went to Wrong");
    });
  };

  /* End Of Onboarding Api's */

  /* Birth Api's*/

  const viewMappedAssetsDropdown = (siteid) =>{
    
    MainService.mappedLegacyAssets(siteid,username)
    .then(res=>{
      setMappedAssets(res.assets);
      setNewBirthLegacyAssetType(res.assets[0].assetType);
      setNewBirthLatitude(res.assets[0].latitude);
      setNewBirthLongitude(res.assets[0].longitude);
      setNewBirthModelid(res.assets[0].modelId);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const birthInfoButtonClickHandler = (assetid, assetType) => {
    setNewBirthAssetid(assetid);
    setAssetBackButtton(assetType);
    getBirthDetailsHandler();
    hideBirth(true);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const getBirthDetailsHandler = () => {
    setIsLoading(true);
    MainService.getBirthDetails(username)
    .then(res=>{
      setIsLoading(false);
      setBirthInfos(res.birthInfos);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const setNewBirthSiteidHandler = (e) =>{
    setUpdateNewBirthSiteid(e.target.value);
    const siteidfilter = getAllSites.filter(sites=>{
      return sites.siteid === e.target.value;
    });
    setUpdateNewBirthSiteName(siteidfilter[0].sitename);
  };

  const createNewBirthInfo = () => {
    const birth = {
      assetid: getNewBirthAssetid,
      assetfriendlyname: getNewBirthAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewBirthUserid,
      username: getNewBirthUserName,
      siteid: getNewBirthSiteid,
      sitename: getNewBirthSiteName,
      lotid: getNewBirthLotid,
      lotname: getNewBirthLotName,
      legacyassettype: getNewBirthLegacyAssetType,
      modelid: getNewBirthModelid,
      modelfriendlyname: getNewBirthModelFriendlyName,
      modeltype: getNewBirthModelType,
      modelurl: getNewBirthModelURL,
      dob: getNewBirthDOB,
      gender: getNewBirthGender,
      maleparent: getNewBirthMaleParent,
      femaleparent: getNewBirthFemaleParent,
      parentmodelid: getNewBirthParentModelid,
      latitude: getNewBirthLatitude,
      longitude: getNewBirthLongitude,
      altitude: getNewBirthAltitude,
      description: getNewBirthDescription
    };
    const birthinfo = JSON.stringify(birth);

    MainService.addBirthInfo(birthinfo)
    .then(res=>{
      hideNewBirthInfoPopup(false);
      getBirthDetailsHandler();
      return NotificationManager.success("birth info added");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };

  const deleteNewBirthInfoAssetId = (assetid) => {
    setDeleteBirthInfo(assetid);
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const deleteBirthInfoAsset = () => {
    MainService.deleteBirthInfo(getDeleteBirthInfo)
    .then(res=>{
      setDeleteBirthInfoPopup(false);
      getBirthDetailsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateBirthInfoData = (assetinfo) => {
    setUpdateNewBirthAssetid(assetinfo.assetid);
    setUpdateNewBirthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateUpdateNewBirthUserid(assetinfo.userid);
    setUpdateNewBirthUserName(assetinfo.username);
    setUpdateNewBirthSiteid(assetinfo.siteid);
    setUpdateNewBirthSiteName(assetinfo.sitename);
    setUpdateNewBirthLotid(assetinfo.lotid);
    setUpdateNewBirthLotName(assetinfo.lotname);
    setUpdateNewBirthLegacyAssetType(assetinfo.legacyassettype);
    setUpdateNewBirthModelid(assetinfo.modelid);
    setUpdateNewBirthModelFriendlyName(assetinfo.modelfriendlyname);
    setUpdateNewBirthModelType(assetinfo.modeltype);
    setUpdateNewBirthModelURL(assetinfo.modelurl);
    setUpdateNewBirthDOB(assetinfo.dob);
    setUpdateNewBirthGender(assetinfo.gender);
    setUpdateNewBirthMaleParent(assetinfo.maleparent);
    setUpdateNewBirthFemaleParent(assetinfo.femaleparent);
    setUpdateNewBirthParentModelid(assetinfo.parentmodelid);
    setUpdateNewBirthLatitude(assetinfo.latitude);
    setUpdateNewBirthLongitude(assetinfo.longitude);
    setUpdateNewBirthAltitude(assetinfo.altitude);
    setUpdateNewBirthDescription(assetinfo.description);

    hideUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
  };

  const updateNewBirthInfoHandler = () =>{
    const updatebirth = {
      assetid:  getUpdateNewBirthAssetid,
      assetfriendlyname: getUpdateNewBirthAssetFriendlyName,
      siteid: getUpdateNewBirthSiteid,
      sitename: getUpdateNewBirthSiteName,
      lotid: getUpdateNewBirthLotid,
      lotname: getUpdateNewBirthLotName,
      legacyassettype: getUpdateNewBirthLegacyAssetType,
      modelid: getUpdateNewBirthModelid,
      modelfriendlyname: getUpdateNewBirthModelFriendlyName,
      modeltype: getUpdateNewBirthModelType,
      modelurl: getUpdateNewBirthModelURL,
      dob: getUpdateNewBirthDOB,
      gender: getUpdateNewBirthGender,
      maleparent: getUpdateNewBirthMaleParent,
      femaleparent: getUpdateNewBirthFemaleParent,
      parentmodelid: getUpdateNewBirthParentModelid,
      latitude: getUpdateNewBirthLatitude,
      longitude: getUpdateNewBirthLongitude,
      altitude: getUpdateNewBirthAltitude,
      description: getUpdateNewBirthDescription,
    };
    const updatebirthinfo = JSON.stringify(updatebirth);
    MainService.updateBirthInfo(updatebirthinfo)
    .then(res=>{
      hideUpdateBirthInfoPopup(false);
      getBirthDetailsHandler();
      return NotificationManager.success("birth info updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };
  /* End Of Birth Api's*/

  /* Attendance Location Api's*/

  const attendanceLocationButtonClickHandler = (assetid, assetType) =>{
    setNewAttendanceLocationAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(true);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };
 
  const attendanceLocationHistoryHandler = () => {
    setIsLoading(true);
    MainService.AttendanceLocationHistory(username)
    .then(res=>{
      setIsLoading(false);
      setAttendanceLocationHistory(res.attendanceLocations);
    })
    .catch(err=>{
      NotificationManager.error(err);
    })
  };

  const addAttendanceLocationHandler = () => {
    const attendanceLocation = {
      assetid:getNewAttendanceLocationAssetid,
	    assetfriendlyname:getNewAttendanceLocationAssetfriendlyName,
	    parentid:"consumer2",
	    userid:getNewAttendanceLocationUserid,
	    latitude:getNewAttendanceLocationLatitude,
	    longitude:getNewAttendanceLocationLongitude,
	    altitude:getNewAttendanceLocationAltitude,
	    description:getNewAttendanceLocationDescription
    };
    const newattendanceLocation = JSON.stringify(attendanceLocation);
    console.log("const newattendanceLocation = JSON.stringify(attendanceLocation)",newattendanceLocation);

    MainService.NewAttendanceLocation(newattendanceLocation)
    .then(res=>{
      hideNewAttendanceLocationPopup(false);
      attendanceLocationHistoryHandler();
      NotificationManager.success("attendance location added")
    })
    .catch(err=>{
      NotificationManager.error(err);
    })
  };

  const deleteAttendanceLocationsAssetId = (assetid) => {
    setDeletedAttendanceAssetid(assetid);
    setDeletedAttendancePopup(!getDeletedAttendancePopup);
  };

  const deleteAttendanceAsset = () => {
    MainService.DeleteNewAttendanceLocation(getDeletedAttendanceAssetid)
    .then(res=>{
      setDeletedAttendancePopup(false);
      attendanceLocationHistoryHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateAttendanceLocationsAssetInfo = (assetinfo) => {
    setUpdateAttendanceLocationInfoAssetid(assetinfo.assetid);
    setUpdateAttendanceLocationInfoAssetfriendlyName(assetinfo.assetfriendlyname);
    setUpdateAttendanceLocationInfoUserid(assetinfo.userid);
    setUpdateAttendanceLocationInfoLatitude(assetinfo.latitude);
    setUpdateAttendanceLocationInfoLongitude(assetinfo.longitude);
    setUpdateAttendanceLocationInfoAltitude(assetinfo.altitude);
    setUpdateAttendanceLocationInfoDescription(assetinfo.description);
    hideUpdateAttendaneLocationPopup(!showUpdateAttendaneLocationPopup)
  };

  const updateAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getUpdateAttendanceLocationInfoAssetid,
	    assetfriendlyname: getUpdateAttendanceLocationInfoAssetfriendlyName,
	    latitude: getUpdateAttendanceLocationInfoLatitude,
	    longitude: getUpdateAttendanceLocationInfoLongitude,
	    altitude: getUpdateAttendanceLocationInfoAltitude,
	    description: getUpdateAttendanceLocationInfoDescription
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.UpdateAttendanceLocation(updateAttendance)
    .then(res=>{
      hideUpdateAttendaneLocationPopup(false);
      attendanceLocationHistoryHandler();
      return NotificationManager.success("attendance location updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };
  /* End Of Attendance Location Api's*/

  /* Health Records Api's */

  const healthRecordButtonClickHandler = (assetid, assetType) => {
    healthRecordsHandler();
    setNewHealthAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(true);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const healthRecordsHandler = () => {
    setIsLoading(true);
    MainService.getHealthRecords(username)
    .then(res=>{
      setIsLoading(false);
      setHealthRecords(res.healthRecord);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addHealthRecordHandler = () =>{
    const addHealthRecord = {
      assetid: getNewHealthAssetid,
      assetfriendlyname: getNewHealthAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewHealthUserid,
      medicinedate:getNewHealthMedicineDate,
      vaccinationdate: getNewHealthVaccinationDate,
      examine:getNewHealthExamine,
      surgicalprocedure: getNewHealthSurgicalProcedure,
      vaccinationtype: getNewHealthVaccinationType,
      medicinetype: getNewHealthMedicineType,
      diseasetype: getNewHealthDiseaseType,
      treatmentdate: getNewHealthTreatmentDate,
      latitude: getNewHealthLatitude,
      longitude: getNewHealthLongitude,
      altitude: getNewHealthAltitude,
      description: getNewHealthDescription,
    };
    const healthRecord = JSON.stringify(addHealthRecord);
  
    MainService.addHealthRecords(healthRecord)
    .then(res=>{
      hideNewHealthRecordPopup(false);
      healthRecordsHandler();
      return NotificationManager.success("health record added");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };

  const deleteHealthRecordAssetId = (assetid) => {
    setDeleteHealthAssetid(assetid);
    hideDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
  };

  const deleteHealthRecordAsset = () => {
    MainService.deleteHealthRecord(getDeleteHealthAssetid)
    .then(res=>{
      hideDeleteHealthAssetPopup(false);
      healthRecordsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateHealthRecordInfo = (assetinfo) => {
    setUpdateNewHealthAssetid(assetinfo.assetid);
    setUpdateNewHealthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewHealthUserid(assetinfo.userid);
    setUpdateNewHealthMedicineDate(assetinfo.medicinedate);
    setUpdateNewHealthVaccinationDate(assetinfo.vaccinationdate);
    setUpdateNewHealthExamine(assetinfo.examine);
    setUpdateNewHealthSurgicalProcedure(assetinfo.surgicalprocedure);
    setUpdateNewHealthVaccinationType(assetinfo.vaccinationtype);
    setUpdateNewHealthMedicineType(assetinfo.medicinetype);
    setUpdateNewHealthDiseaseType(assetinfo.diseasetype);
    setUpdateNewHealthTreatmentDate(assetinfo.treatmentdate);
    setUpdateNewHealthLatitude(assetinfo.latitude);
    setUpdateNewHealthLongitude(assetinfo.longitude);
    setUpdateNewHealthAltitude(assetinfo.altitude);
    setUpdateNewHealthDescription(assetinfo.description);

    hideUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup);
  };

  const updateHealthRecordHandler = () => {
    const updateHealthRecord = {
      assetid: getUpdateNewHealthAssetid,
      assetfriendlyname: getUpdateNewHealthAssetFriendlyName,
      medicinedate:getUpdateNewHealthMedicineDate,
      vaccinationdate: getUpdateNewHealthVaccinationDate,
      examine: getUpdateNewHealthExamine,
      surgicalprocedure: getUpdateNewHealthSurgicalProcedure,
      vaccinationtype: getUpdateNewHealthVaccinationType,
      medicinetype: getUpdateNewHealthMedicineType,
      diseasetype: getUpdateNewHealthDiseaseType,
      treatmentdate: getUpdateNewHealthTreatmentDate,
      latitude: getUpdateNewHealthLatitude,
      longitude: getUpdateNewHealthLongitude,
      altitude: getUpdateNewHealthAltitude,
      description: getUpdateNewHealthDescription,
    };
    const healthrecord = JSON.stringify(updateHealthRecord);
    
    MainService.updateHealthRecord(healthrecord)
    .then(res=>{
      hideUpdateNewHealthRecordPopup(false);
      healthRecordsHandler();
      return NotificationManager.success("health record updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Worng");
    })
  }
  /* End Of Health Records Api's */

  /* Nutrition Api's */

  const nutritionButtonClickHandler = (assetid, assetType) =>{
    getNutritionHandler();
    setAddNutritionAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(true);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const getNutritionHandler = () => {
    setIsLoading(true);
    MainService.getNutrition(username)
    .then(res=>{
      setIsLoading(false);
      setNutritions(res.nutritions);
    })
    .catch(err=>{
      return NotificationManager.err(err);
    })
  };

  const addNutritionHandler = () => {
    const addnutrition = {
      assetid: getAddNutritionAssetid,
      assetfriendlyname: getAddNutritionAssetFriendlyName,
      parentid:"consumer2",
      userid: getAddNutritionUserid,
      feedtype: getAddNutritionFeedType,
      schedule: getAddNutritionSchedule,
      latitude: getAddNutritionLatitude,
      longitude: getAddNutritionLongitude,
      altitude: getAddNutritionAltitude,
      description: getAddNutritionDescription,
    };
    const addnutritionstr = JSON.stringify(addnutrition);

    MainService.addNutrition(addnutritionstr)
    .then(res=>{
      hideAddNutritionPopup(false);
      getNutritionHandler();
      return NotificationManager.success("nutrition added");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };

  const deleteNutririonAssetid = (assetid) => {
    setDeleteNutritionAssetid(assetid);
    hideDeleteNutrition(true);
  };

  const deleteNutritionHandler = () => {
    MainService.deleteNutrition(getDeleteNutritionAssetid)
    .then(res=>{
      hideDeleteNutrition(false);
      getNutritionHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateNutririonInfo = (assetinfo) => {
    setUpdateAddNutritionAssetid(assetinfo.assetid);
    setUpdateAddNutritionAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateAddNutritionUserid(assetinfo.userid);
    setUpdateAddNutritionFeedType(assetinfo.feedtype);
    setUpdateAddNutritionSchedule(assetinfo.schedule);
    setUpdateAddNutritionLatitude(assetinfo.latitude);
    setUpdateAddNutritionLongitude(assetinfo.longitude);
    setUpdateAddNutritionAltitude(assetinfo.altitude);
    setUpdateAddNutritionDescription(assetinfo.description);
    setUpdateAddNutritionExistingFeedType(assetinfo.feedtype);
  
    hideUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
  };

  const updateNutritionHandler = () => {
    const updateNutrition = {
      assetid: getUpdateAddNutritionAssetid,
      assetfriendlyname: getUpdateAddNutritionAssetFriendlyName,
      feedtype: getUpdateAddNutritionFeedType,
      schedule: getUpdateAddNutritionSchedule,
      latitude: getUpdateAddNutritionLatitude,
      longitude: getUpdateAddNutritionLongitude,
      altitude: getUpdateAddNutritionAltitude,
      description: getUpdateAddNutritionDescription,
    };
    const updateNutritionStr = JSON.stringify(updateNutrition);

    MainService.updateNutrition(updateNutritionStr)
    .then(res=>{
      hideUpdateAddNutritionPopup(false);
      getNutritionHandler();
      return NotificationManager.success("nutrition info updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };
  /* End Of Nutrition Api's */

  /* Weighing Api's */

  const weighingButtonClickHandler = (siteid, backType) =>{
    weighingDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(true);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const weighingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getWeighingDetails(username)
    .then(res=>{
      setIsLoading(false);
      setWeighingDetail(res.weighingDetails);
    })
    .catch(err=>{
      return NotificationManager.eror(err);
    })
  };

  const addWeighingHandler = () => {
    const addweighing = {
      assetid: getNewWeighingAssetid,
      assetfriendlyname: getNewWeighingAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewWeighingUserid,
      weightatbirth: getNewWeighingWeightAtBirth,
      weaningage: getNewWeighingWeaningAge,
      weaningweight: getNewWeighingWeaningWeight,
      ageatslaughter: getNewWeighingAgeAtSlaughter,
      carcassweight: getNewWeighingCarcassWeight,
      gender: getNewWeighingGender,
      latitude: getNewWeighingLatitude,
      longitude: getNewWeighingLongitude,
      altitude: getNewWeighingAltitude,
      description: getNewWeighingDescription,
      mimetype: getNewWeighingMimetype,
      width: getNewWeighingWidth,
      height: getNewWeighingHeight,
      depth: getNewWeighingDepth,
      imagedata: getNewWeighingImage,
    };
    const weighingStr = JSON.stringify(addweighing);
    console.log("const weighingStr = JSON.stringify(addweighing)",addweighing);
    MainService.addWeighingDetails(weighingStr)
    .then(res=>{
      hideNewWeighingPopup(false);
      weighingDetailsHandler();
      return NotificationManager.success("weighing details added");
    })
    .catch(err=>{
      return NotificationManager.error("Something went Worng");
    })
  };

  const deleteWeighingAssetid = (assetid) => {
    setWeighingDeleteAssetid(assetid);
    hideWeighingDeletePopup(true);
  };

  const deleteWeighingHandler = () => {
    MainService.deleteWeighingDetails(getWeighingDeleteAssetid)
    .then(res=>{
      hideWeighingDeletePopup(false);
      weighingDetailsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateWeighingInfo = (assetinfo) => {
    setUpdateNewWeighingAssetid(assetinfo.assetid);
    setUpdateNewWeighingAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewWeighingUserid(assetinfo.userid);
    setUpdateNewWeighingWeightAtBirth(assetinfo.weightatbirth);
    setUpdateNewWeighingWeaningAge(assetinfo.weaningage);
    setUpdateNewWeighingWeaningWeight(assetinfo.weaningweight);
    setUpdateNewWeighingAgeAtSlaughter(assetinfo.ageatslaughter);
    setUpdateNewWeighingCarcassWeight(assetinfo.carcassweight);
    setUpdateNewWeighingGender(assetinfo.gender);
    setUpdateNewWeighingLatitude(assetinfo.latitude);
    setUpdateNewWeighingLongitude(assetinfo.longitude);
    setUpdateNewWeighingAltitude(assetinfo.altitude);
    setUpdateNewWeighingDescription(assetinfo.description);

    hideUpdateNewWeighingPopup(true);
  };

  const addUpdateWeighingHandler = () =>{
    const updateweighing = {
      assetid: getUpdateNewWeighingAssetid,
      assetfriendlyname: getUpdateNewWeighingAssetFriendlyName,
      weightatbirth: getUpdateNewWeighingWeightAtBirth,
      weaningage: getUpdateNewWeighingWeaningAge,
      weaningweight: getUpdateNewWeighingWeaningWeight,
      ageatslaughter: getUpdateNewWeighingAgeAtSlaughter,
      carcassweight: getUpdateNewWeighingCarcassWeight,
      gender: getUpdateNewWeighingGender,
      latitude: getUpdateNewWeighingLatitude,
      longitude: getUpdateNewWeighingLongitude,
      altitude: getUpdateNewWeighingAltitude,
      description: getUpdateNewWeighingDescription,
      imagedata: getUpdateNewWeighingImage,
    };
    const weighingStr = JSON.stringify(updateweighing);

    MainService.updateWeighingDetails(weighingStr)
    .then(res=>{
      hideUpdateNewWeighingPopup(false);
      weighingDetailsHandler();
      return NotificationManager.success("weighing details updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };
  /* End Of Weighing Api's */

  /* Parturition Api's */

  const parturitionButtonClickHandler = (assetid, assetType) =>{
    getParturitionDetailsHandler();
    setNewParturitionAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(true);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const getParturitionDetailsHandler = () => {
    setIsLoading(true);
    MainService.getParturitionDetails(username)
    .then(res=>{
      setIsLoading(false);
      setParturitionDetail(res.parturitions);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addParturitionHandler = () =>{
    const newparturition = {
      assetid: getNewParturitionAssetid,
      assetfriendlyname: getNewParturitionAssetfriendlyName,
      parentid:"consumer2",
      userid: getNewParturitionUserid,
      weightatfirstmating: getNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getNewParturitionBodyConditionsIndex,
      numberofmatings: getNewParturitionNumberOfMatings,
      supermogram: getNewParturitionSupermogram,
      breed: getNewParturitionBreed,
      breedpercentage: getNewParturitionBreedPercentage,
      breederid: getNewParturitionBreederId,
      breederage: getNewParturitionBreederAge,
      breedingdate: getNewParturitionBreedingDate,
      nextbreeding: getNewParturitionNextBreeding,
      expectedkiddingdate: getNewParturitionExpectedKiddingDate,
      delivereddate: getNewParturitionDeliveredDate,
      totalkidsdelivered: getNewParturitionTotalKidsDelivered,
      disposal: getNewParturitionDisposal,
      latitude: getNewParturitionLatitude,
      longitude: getNewParturitionLongitude,
      altitude: getNewParturitionAltitude,
      description: getNewParturitionDescription,
    };
    const newparturitionStr = JSON.stringify(newparturition);

    MainService.addParturitionDetails(newparturitionStr)
    .then(res=>{
      hideNewParturitionPopup(false);
      getParturitionDetailsHandler();
      return NotificationManager.success("parturition details added");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };

  const deleteParturitionAssetid = (assetid) => {
    setParturitionDeleteAssetid(assetid);
    hideParturitionDeletePopup(true);
  };

  const deleteParturitionHandler = () => {
    MainService.deleteParturitionDetails(getParturitionDeleteAssetid)
    .then(res=>{
      hideParturitionDeletePopup(false);
      getParturitionDetailsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateParturitionInfo = (ParturitionInfo) => {

    setUpdateNewParturitionAssetid(ParturitionInfo.assetid);
    setUpdateNewParturitionAssetfriendlyName(ParturitionInfo.assetfriendlyname);
    setUpdateNewParturitionUserid(ParturitionInfo.userid);
    setUpdateNewParturitionWeightAtFirstMating(ParturitionInfo.weightatfirstmating);
    setUpdateNewParturitionAgeAtFirstParturition(ParturitionInfo.ageatfirstparturition);
    setUpdateNewParturitionBodyConditionsIndex(ParturitionInfo.bodyconditionsindex);
    setUpdateNewParturitionNumberOfMatings(ParturitionInfo.numberofmatings);
    setUpdateNewParturitionSupermogram(ParturitionInfo.supermogram);
    setUpdateNewParturitionBreed(ParturitionInfo.breed);
    setUpdateNewParturitionBreedPercentage(ParturitionInfo.breedpercentage);
    setUpdateNewParturitionBreederId(ParturitionInfo.breederid);
    setUpdateNewParturitionBreederAge(ParturitionInfo.breederage);
    setUpdateNewParturitionBreedingDate(ParturitionInfo.breedingdate);
    setUpdateNewParturitionNextBreeding(ParturitionInfo.nextbreeding);
    setUpdateNewParturitionExpectedKiddingDate(ParturitionInfo.expectedkiddingdate);
    setUpdateNewParturitionDeliveredDate(ParturitionInfo.delivereddate);
    setUpdateNewParturitionTotalKidsDelivered(ParturitionInfo.totalkidsdelivered);
    setUpdateNewParturitionDisposal(ParturitionInfo.disposal);
    setUpdateNewParturitionLatitude(ParturitionInfo.latitude);
    setUpdateNewParturitionLongitude(ParturitionInfo.longitude);
    setUpdateNewParturitionAltitude(ParturitionInfo.altitude);
    setUpdateNewParturitionDescription(ParturitionInfo.description);

    hideUpdateNewParturitionPopup(true);
  };

  const updateParturitionHandler = () => {
    const updateparturition = {
      assetid: getUpdateNewParturitionAssetid,
      assetfriendlyname: getUpdateNewParturitionAssetfriendlyName,
      weightatfirstmating: getUpdateNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getUpdateNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getUpdateNewParturitionBodyConditionsIndex,
      numberofmatings: getUpdateNewParturitionNumberOfMatings,
      supermogram: getUpdateNewParturitionSupermogram,
      breed: getUpdateNewParturitionBreed,
      breedpercentage: getUpdateNewParturitionBreedPercentage,
      breederid: getUpdateNewParturitionBreederId,
      breederage: getUpdateNewParturitionBreederAge,
      breedingdate: getUpdateNewParturitionBreedingDate,
      nextbreeding: getUpdateNewParturitionNextBreeding,
      expectedkiddingdate: getUpdateNewParturitionExpectedKiddingDate,
      delivereddate: getUpdateNewParturitionDeliveredDate,
      totalkidsdelivered: getUpdateNewParturitionTotalKidsDelivered,
      disposal: getUpdateNewParturitionDisposal,
      latitude: getUpdateNewParturitionLatitude,
      longitude: getUpdateNewParturitionLongitude,
      altitude: getUpdateNewParturitionAltitude,
      description: getUpdateNewParturitionDescription,
    };
    const updateparturitionstr = JSON.stringify(updateparturition);
  
    MainService.updateParturitionDetails(updateparturitionstr)
    .then(res=>{
      hideUpdateNewParturitionPopup(false);
      getParturitionDetailsHandler();
      return NotificationManager.success("parturition info updated");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };
  /* End Of Parturition Api's */

  /*  Mortality Api's */

  const mortalityButtonClickHandler = (assetid, assetType) => {
    getMortalityDetailsHandler();
    setNewMortalityAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(true);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);

    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const getMortalityDetailsHandler = () =>{
    setIsLoading(true);
    MainService.getMortalityDetails(username)
    .then(res=>{
      setIsLoading(false);
      setMortalityDetails(res.mortality);
    })
    .catch(err=>{
      NotificationManager.error(err);
    })
  };

  const addMortalityHandler = () => {
    const addmortality = {
      assetid: getNewMortalityAssetid,
      assetfriendlyname: getNewMortalityAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewMortalityUserid,
      modelid: getNewMortalityModelid,
      breedpercentage: getNewMortalityBreedPercentage,
      gender: getNewMortalityGender,
      birthdate: getNewMortalityBirthDate,
      expireddate: getNewMortalityExpiredDate,
      totalage: getNewMortalityTotalAge,
      currentvalue: getNewMortalityCurrentValue,
      causeofdeath: getNewMortalityCauseOfDeath,
      latitude: getNewMortalityLatitude,
      longitude: getNewMortalityLongitude,
      altitude: getNewMortalityAltitude,
      description: getNewMortalityDescription,
    };
    const addmortalitystr = JSON.stringify(addmortality);
    
    MainService.addMortalityDetails(addmortalitystr)
    .then(res=>{
      hideNewMortalityPopuop(false);
      getMortalityDetailsHandler();
      return NotificationManager.success("mortality details added");
    })
    .catch(err=>{
      return NotificationManager.error("Something Went Wrong");
    })
  };

  const deleteMortalityAssetid = (assetid) => {
    setMortalityDeleteAssetid(assetid);
    hideMortalityDeletePopup(true);
  };

  const deleteMortalityHandler = () => {
    MainService.deleteMortalityDetails(getMortalityDeleteAssetid)
    .then(res=>{
      hideMortalityDeletePopup(false);
      getMortalityDetailsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateMortalityInfo = (mortalityinfo) => {
    setUpdateNewMortalityAssetid(mortalityinfo.assetid);
    setUpdateNewMortalityAssetFriendlyName(mortalityinfo.assetfriendlyname);
    setUpdateNewMortalityUserid(mortalityinfo.userid);
    setUpdateNewMortalityModelid(mortalityinfo.modelid);
    setUpdateNewMortalityBreedPercentage(mortalityinfo.breedpercentage);
    setUpdateNewMortalityGender(mortalityinfo.gender);
    setUpdateNewMortalityBirthDate(mortalityinfo.birthdate);
    setUpdateNewMortalityExpiredDate(mortalityinfo.expireddate);
    setUpdateNewMortalityTotalAge(mortalityinfo.totalage);
    setUpdateNewMortalityCurrentValue(mortalityinfo.currentvalue);
    setUpdateNewMortalityCauseOfDeath(mortalityinfo.causeofdeath);
    setUpdateNewMortalityLatitude(mortalityinfo.latitude);
    setUpdateNewMortalityLongitude(mortalityinfo.longitude);
    setUpdateNewMortalityAltitude(mortalityinfo.altitude);
    setUpdateNewMortalityDescription(mortalityinfo.description);

    hideUpdateNewMortalityPopuop(true);
  };

  const updateMortalityHandler = () => {
    const updatemortality = {
      assetid: getUpdateNewMortalityAssetid,
      assetfriendlyname: getUpdateNewMortalityAssetFriendlyName,
      modelid: getUpdateNewMortalityModelid,
      breedpercentage: getUpdateNewMortalityBreedPercentage,
      gender: getUpdateNewMortalityGender,
      birthdate: getUpdateNewMortalityBirthDate,
      expireddate: getUpdateNewMortalityExpiredDate,
      totalage: getUpdateNewMortalityTotalAge,
      currentvalue: getUpdateNewMortalityCurrentValue,
      causeofdeath: getUpdateNewMortalityCauseOfDeath,
      latitude: getUpdateNewMortalityLatitude,
      longitude: getUpdateNewMortalityLongitude,
      altitude: getUpdateNewMortalityAltitude,
      description: getUpdateNewMortalityDescription,
    };
    const updatemortalitystr = JSON.stringify(updatemortality);
    MainService.updateMortalityDetails(updatemortalitystr)
    .then(res=>{
      hideUpdateNewMortalityPopuop(false);
      getMortalityDetailsHandler();
      return NotificationManager.success("mortality info updated");
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };
  /* End Of Mortality Api's */

  /*  Asset Operational Status  Api's */

  const assetOperationalStatusButtonClickHandler = (siteid, backType) =>{
  getAssetOptionalStatusDetailsHandler();
  viewMappedAssetsDropdown(siteid);
  setBackType(backType);
  attendanceLocationHistoryHandler();
  hideBirth(false);
  hideAttendanceLocation(false);
  hideHealthRecords(false);
  hideNutrition(false);
  hideWeighing(false);
  hideParturition(false);
  hideMortality(false);
  hideOperationalStatus(true);
  hideOnboarding(false);
  setShowSelling(false);
  setShowPatrolling(false);
  setShowAssetPatrolling(false);

  setViewallSites(false);
  setViewallGridSites(false);
  setViewallMapSites(false);
  setShowDistricts(false);
  setMappedAssetShow(false);
  setMapWithSites(false);
  setShowSiteRating(false);
  hideAssetList(false);
  hideAssetGrid(false);
  hideAssetMap(false);
  };

  const getAssetOptionalStatusDetailsHandler = () => {
    setIsLoading(true);
    MainService.getAssetOptionalStatusDetails(username)
    .then(res=>{
      setIsLoading(false);
      setOperationalStatusDetails(res.assetOperationalStatus);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addAssetOperationalHandler = () => {
    const assetoperational = {
      assetid: getNewAssetOperationalAssetid,
      assetfriendlyname: getNewAssetOperationalAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewAssetOperationalUserid,
      status: getNewAssetOperationalStatus,
      latitude: getNewAssetOperationalLatitude,
      longitude: getNewAssetOperationalLongitude,
      altitude: getNewAssetOperationalAltitude,
      description: getNewAssetOperationalDescription,
    };
    const assetoperationalstr = JSON.stringify(assetoperational);

    MainService.addAssetOparationalStatusDetails(assetoperationalstr)
    .then(res=>{
      hideNewAssetOperationalPopup(false);
      getAssetOptionalStatusDetailsHandler();
      return NotificationManager.success("asset operational status added");
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const deleteAssetOperationalStatusAssetid = (assetid) => {
    setAssetOperationalDeleteAssetid(assetid);
    hideAssetOperationalDeletePopup(true);
  };

  const deleteAssetOperationalHandler = () => {
    MainService.deleteAssetOperationalStatusDetails(getAssetOperationalDeleteAssetid)
    .then(res=>{
      hideAssetOperationalDeletePopup(false);
      getAssetOptionalStatusDetailsHandler();
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateAssetOperationalStatusInfo = (assetinfo) => {
    setUpdateNewAssetOperationalAssetid(assetinfo.assetid);
    setUpdateNewAssetOperationalAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewAssetOperationalUserid(assetinfo.userid);
    setUpdateNewAssetOperationalStatus(assetinfo.status);
    setUpdateExistingNewAssetOperationalStatus(assetinfo.status);
    setUpdateNewAssetOperationalLatitude(assetinfo.latitude);
    setUpdateNewAssetOperationalLongitude(assetinfo.longitude);
    setUpdateNewAssetOperationalAltitude(assetinfo.altitude);
    setUpdateNewAssetOperationalDescription(assetinfo.description);

    hideUpdateNewAssetOperationalPopup(true);
  };

  const updateAssetOperationalHandler = () => {
    const updateasset = {
      assetid: getUpdateNewAssetOperationalAssetid,
      assetfriendlyname: getUpdateNewAssetOperationalAssetFriendlyName,
      status: getUpdateNewAssetOperationalStatus,
      latitude: getUpdateNewAssetOperationalLatitude,
      longitude: getUpdateNewAssetOperationalLongitude,
      altitude: getUpdateNewAssetOperationalAltitude,
      description: getUpdateNewAssetOperationalDescription,
    };
    const updateassetstr = JSON.stringify(updateasset);
    console.log("const updateassetstr = JSON.stringify(updateasset);",updateasset);
    MainService.updateAssetOperationalStatusDetails(updateassetstr)
    .then(res=>{
      hideUpdateNewAssetOperationalPopup(false);
      getAssetOptionalStatusDetailsHandler();
      return NotificationManager.success("asset operational status info updated");
    })
    .catch(err=>{
      return NotificationManager.eror("Something Went Wrong");
    })
  };
  /* End Of Asset Operational Status  Api's */
  /* Selling Cost  Api's */
  const sellingCostButtonClickHandler = (siteid, backType) => {
    sellingCostDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(true);
    setShowPatrolling(false);
    setShowAssetPatrolling(false);
  
    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const sellingCostDetailsHandler = () => {
    setIsLoading(true);
    MainService.getSellingCostDetails(username)
    .then(res=>{
      setIsLoading(false);
      setSellingDetails(res.sellingCost);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addSellingCostHandler = () =>{
    const sellingDetails = {
      assetid: getNewSellingCostAssetid,
      assetfriendlyname: getNewSellingCostAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewSellingCostUserid,
      sellingcost: getNewSellingCostSellingCost,
    };
    const data = JSON.stringify(sellingDetails);
    MainService.addSellingCostDetails(data)
    .then(res=>{
      sellingCostDetailsHandler();
      setAddSellingCostPopup(!getAddSellingCostPopup);
      return NotificationManager.success("selling cost added");
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };
  /* End Of Selling  Api's */

  /* Patrolling Api's */
  const sitePatrollingButtonClickHandler = (siteid, backType) => {
    sitePatrollingDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setNewSitePatrolingSiteid(siteid);
    setBackType(backType);
    attendanceLocationHistoryHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(true);
    setShowAssetPatrolling(false);
  
    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const sitePatrollingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getSitePatrollingDetails(username)
    .then(res=>{
      setIsLoading(false);
      setViewSitePatrolling(res.patrollings);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addSitePatrolingHandler = () => {
    const sitepatrolingdetails = {
      siteid: getNewSitePatrolingSiteid,
      friendlyname: getNewSitePatrolingFriendlyName,
      parentid:"consumer2",
      userid: getNewSitePatrolingUserid,
      mobilenumber: getNewSitePatrolingMobileNumber,
      latitude: getNewSitePatrolingLatitude,
      longitude: getNewSitePatrolingLongitude,
      altitude: getNewSitePatrolingAltitude,
      description: getNewSitePatrolingDescription,
    };
    const data = JSON.stringify(sitepatrolingdetails);

    MainService.addSitePatrolingDetails(data)
    .then(res=>{
      getShowSitePatrolingPopup(!showSitePatrolingPopup);
      sitePatrollingDetailsHandler();
      return NotificationManager.success("patrolling details added");
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };

  const deleteSitePatrolingInfo = (siteid) => {
    setDeleteSiteid(siteid);
    getShowSitePatrolingDeletePopup(!showSitePatrolingDeletePopup);
  };

  const deleteSitePatrolingHandler = () => {
    MainService.deleteSitePatrolingDetails(getDeleteSiteid)
    .then(res=>{
      sitePatrollingDetailsHandler();
      getShowSitePatrolingDeletePopup(!showSitePatrolingDeletePopup);
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateSitePatrolingInfo = (sitepatrolinginfo) => {
    setUpdateNewSitePatrolingSiteid(sitepatrolinginfo.siteid);
    setUpdateNewSitePatrolingFriendlyName(sitepatrolinginfo.friendlyname);
    setUpdateNewSitePatrolingMobileNumber(sitepatrolinginfo.mobilenumber);
    setUpdateNewSitePatrolingLatitude(sitepatrolinginfo.latitude);
    setUpdateNewSitePatrolingLongitude(sitepatrolinginfo.longitude);
    setUpdateNewSitePatrolingAltitude(sitepatrolinginfo.altitude);
    setUpdateNewSitePatrolingDescription(sitepatrolinginfo.description);
    getUpdateShowSitePatrolingPopup(!showUpdateSitePatrolingPopup);
  };

  const updateSitePatrolingHandler = () =>{
    const updatepatrolinginfo = {
      siteid: getUpdateNewSitePatrolingSiteid,
      friendlyname: getUpdateNewSitePatrolingFriendlyName,
      mobilenumber: getUpdateNewSitePatrolingMobileNumber,
      latitude: getUpdateNewSitePatrolingLatitude,
      longitude: getUpdateNewSitePatrolingLongitude,
      altitude: getUpdateNewSitePatrolingAltitude,
      description: getUpdateNewSitePatrolingDescription,
    };
    const data = JSON.stringify(updatepatrolinginfo);
    MainService.updateSitePatrolingDetails(data)
    .then(res=>{
      sitePatrollingDetailsHandler();
      getUpdateShowSitePatrolingPopup(!showUpdateSitePatrolingPopup);
      return NotificationManager.success("patrolling info updated");
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };

  const AssetPatrollingButtonClickHandler = (assetid, assetType) => {
    setNewAssetPatrollingAssetid(assetid);
    setAssetBackButtton(assetType);
    getAssetPatrollingDetailsHandler();
    hideBirth(false);
    hideAttendanceLocation(false);
    hideHealthRecords(false);
    hideNutrition(false);
    hideWeighing(false);
    hideParturition(false);
    hideMortality(false);
    hideOperationalStatus(false);
    hideOnboarding(false);
    setShowSelling(false);
    setShowPatrolling(false);
    setShowAssetPatrolling(true);
  
    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setMapWithSites(false);
    setShowSiteRating(false);
    hideAssetList(false);
    hideAssetGrid(false);
    hideAssetMap(false);
  };

  const getAssetPatrollingDetailsHandler = () =>{
    setIsLoading(true);
    MainService.getAssetPatrollingDetails(username)
    .then(res=>{
      setIsLoading(false);
      setViewAssetSitePatrolling(res.patrollings);
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };

  const addAssetPatrolingHandler = () => {
    const assetpatrolling = {
      assetid: getNewAssetPatrollingAssetid,
      assetfriendlyname: getNewAssetPatrollingAssetFriendlyName,
      parentid:"consumer2",
      userid: getNewAssetPatrollingUserid,
      mobilenumber: getNewAssetPatrollingMobileNumber,
      latitude: getNewAssetPatrollingLatitude,
      longitude: getNewAssetPatrollingLongitude,
      altitude: getNewAssetPatrollingAltitude,
      description: getNewAssetPatrollingDescription,
    };
    const data = JSON.stringify(assetpatrolling);

    MainService.addAssetPatrolingDetails(data)
    .then(res=>{
      getAssetPatrollingDetailsHandler();
      setShowNewAssetPatrollingPopup(!getShowNewAssetPatrollingPopup);
      return NotificationManager.success("patrolling details added");
    })
    .catch(err=>{
      return NotificationManager.error("error");
    })
  };

  const deleteAssetPatrolingInfo = (assetid) => {
    setDeleteAssetid(assetid);
    getShowAssetPatrolingDeletePopup(!showAssetPatrolingDeletePopup);
  };

  const deleteAssetPatrolingHandler = () => {
    MainService.deleteAssetPatrolingDetails(getDeleteAssetid)
    .then(res=>{
      getAssetPatrollingDetailsHandler();
      getShowAssetPatrolingDeletePopup(!showAssetPatrolingDeletePopup);
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const updateAssetPatrolingInfo = (assetinfo) => {
    setUpdateNewAssetPatrollingAssetid(assetinfo.assetid);
    setUpdateNewAssetPatrollingAssetFriendlyName(assetinfo.friendlyname);
    setUpdateNewAssetPatrollingMobileNumber(assetinfo.mobilenumber);
    setUpdateNewAssetPatrollingLatitude(assetinfo.latitude);
    setUpdateNewAssetPatrollingLongitude(assetinfo.longitude);
    setUpdateNewAssetPatrollingAltitude(assetinfo.altitude);
    setUpdateNewAssetPatrollingDescription(assetinfo.description);
    setUpdateShowNewAssetPatrollingPopup(!getUpdateShowNewAssetPatrollingPopup);
  };

  const addUpdateAssetPatrolingHandler = () => {
    const updateasset = {
      assetid: getUpdateNewAssetPatrollingAssetid,
      assetfriendlyname: getUpdateNewAssetPatrollingAssetFriendlyName,
      mobilenumber: getUpdateNewAssetPatrollingMobileNumber,
      latitude: getUpdateNewAssetPatrollingLatitude,
      longitude: getUpdateNewAssetPatrollingLongitude,
      altitude: getUpdateNewAssetPatrollingAltitude,
      description: getUpdateNewAssetPatrollingDescription,
    };
    const data = JSON.stringify(updateasset);
    MainService.updateAssetPatrolingDetails(data)
    .then(res=>{
      getAssetPatrollingDetailsHandler();
      setUpdateShowNewAssetPatrollingPopup(!getUpdateShowNewAssetPatrollingPopup);
      return NotificationManager.success("patrolling info updated");
    })
    .catch(err=>{
      return NotificationManager.error("error")
    })
  };
  /* End Of Patrolling Api's */

  /* View All Sites */

  const getAllSitesData = (Type) =>{
    setPage(0);
    if(Type === "List"){
      setViewallSites(true);
      setViewallGridSites(false);
      setViewallMapSites(false);

      setShowDistricts(false);
      setShowSiteRating(false);
      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(false);
      setMapWithSites(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    } else if(Type === "Grid"){
      setViewallSites(false);
      setViewallGridSites(true);
      setViewallMapSites(false);

      setShowDistricts(false);
      setShowSiteRating(false);
      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(false);
      setMapWithSites(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    } else if(Type === "Map"){
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(true);

      setShowDistricts(false);
      setShowSiteRating(false);
      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(false);
      setMapWithSites(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    }
    
    MainService.viewAllSites(username,getDistrict)
      .then(res=>{
        setAllSites(res.sites);
        getFilterUsers(res.sites);
      })
      .catch(err=>{
        return NotificationManager.error(err);
      })
  };

  /* End View All Sites */

  const showAssetMappingHandler = () =>{
      setViewallSites(false);
      setShowDistricts(true);
      setMappedAssetShow(false);
      setMapWithSites(false);
      setShowSiteRating(false);
      setViewallGridSites(false);
      setViewallMapSites(false);

      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(false);
  };

  const viewMappedAssets = (siteid,Type,sitename) =>{
    setPage(0);
    setRedirectSiteId(siteid);
    setNewBirthSiteName(sitename);
    setNewBirthSiteid(siteid);
    if(Type === "List"){
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setShowDistricts(false);
      setMappedAssetShow(false);
      setMapWithSites(false);
      setShowSiteRating(false);

      hideAssetList(true);
      hideAssetGrid(false);
      hideAssetMap(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    } else if(Type === "Grid"){
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setShowDistricts(false);
      setMappedAssetShow(false);
      setMapWithSites(false);
      setShowSiteRating(false);

      hideAssetList(false);
      hideAssetGrid(true);
      hideAssetMap(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    } else if(Type === "Map"){
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setShowDistricts(false);
      setMappedAssetShow(false);
      setMapWithSites(false);
      setShowSiteRating(false);

      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(true);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    } else {
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setShowDistricts(false);
      setMappedAssetShow(true);
      setMapWithSites(false);
      setShowSiteRating(false);

      hideAssetList(false);
      hideAssetGrid(false);
      hideAssetMap(false);

      hideBirth(false);
      hideAttendanceLocation(false);
      hideHealthRecords(false);
      hideNutrition(false);
      hideWeighing(false);
      hideParturition(false);
      hideMortality(false);
      hideOperationalStatus(false);
      hideOnboarding(false);
      setShowSelling(false);
      setShowPatrolling(false);
      setShowAssetPatrolling(false);
    }

    MainService.mappedLegacyAssets(siteid,username)
    .then(res=>{
      setMappedAssets(res.assets);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addUnmappedToMap = (siteID) =>{
    setPage(0);
    setSiteId(siteID);
    setMapWithSites(true);
    setViewallSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setShowSiteRating(false);

    MainService.getAllAssetsData(username)
    .then(res=>{
      setMapwithSite(res.assets);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const mappedAssetValues = (assId,Cat) =>{

    const formData = {
      siteid : getSiteId,
    }

    const dataSiteId = JSON.stringify(formData);

    MainService.assetMapingtoSite(username,assId,Cat,dataSiteId)
    .then(res=>{
      setMapWithSites(false);
      setViewallSites(false);
      setShowDistricts(false);
      setMappedAssetShow(true);
      setShowSiteRating(false);

      viewMappedAssets(redirectSiteId);

      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  }
  /* Site Delete Functionality */

  const deleteSiteHandler = (siteid,sitename) => {

    if(window.confirm("Are you sure ? ")){
      MainService.siteDeleteHandler(siteid,sitename,username)
      .then(res=>{
        return NotificationManager.success("Successfully Deleted");
      })
      .catch(err=>{
        return NotificationManager.error(err);
      })
    }
    getAllSitesData();
  };

  /* End Of Site Delete Functionality */

  /*Site Ratings */

  const siteRatingsHandler = (siteId) =>{

    setMapWithSites(false);
    setViewallSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowDistricts(false);
    setMappedAssetShow(false);
    setShowSiteRating(true);
    setSiteRateSiteId(siteId);

    MainService.siteRating(siteId)
    .then(res=>{
      setSiteRatings(res.ratings);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const addedSiteRatingHandler = (siteratesiteid) =>{
    setDefaultSiteId(siteratesiteid);
    setSiteRatePopUp(!getSiteRatePopup);
  };

  const addorupdateSiteRating = () => {
    const siteRateData = {
      siteid : defaultSiteId,
      ratings : setupdateSiteRating,
    };
  const formData = JSON.stringify(siteRateData);
    MainService.addSiteRating(formData)
    .then(res=>{
      setSiteRatePopUp(!getSiteRatePopup);
      siteRatingsHandler(defaultSiteId);
      return NotificationManager.success('ratings added');
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  }

  const closeSiteRatingModal = (
    <span
      onClick={() => {
        setSiteRatePopUp(!getSiteRatePopup);
      }}
    >
      X
    </span>
  );

  const siteRateAgreement = "RATINGS";
  const siteRateModel = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteId"> Id:</InputLabel>
        <Input
          disabled
          id="siteId"
          name="siteId"
          type="text"
          value={defaultSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siterating"> Ratings:</InputLabel>
        <Input
          id="siterating"
          name="siterating"
          type="text"
          value={setupdateSiteRating}
          onChange = {(e)=>getUpDateSiteRating(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={addorupdateSiteRating}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteRatePopUp(!getSiteRatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

   /*End Of Site Ratings */

  /* Create Site */

  const getAllSitesList = () =>{

    MainService.getAllSiteTypes("consumer2")
    .then(res=>{
      setSiteData(res.sitetypes);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    })
  };

  const siteCreateHandler = () => {
    
    const createSiteFormData = new FormData();
    createSiteFormData.append("parentid", username);
    createSiteFormData.append("sitename", getSiteName);
    createSiteFormData.append("mailid", getMailId);
    createSiteFormData.append("phone", getPhone);
    createSiteFormData.append("sitetype", getSiteType);
    createSiteFormData.append("assetverticalid", assetVerticalIdValue);
    createSiteFormData.append("assetverticalfriendlyname", assetVerticalFriendlyNameValue);

    MainService.createSite(createSiteFormData)
    .then(res=>{
      setMapWithSites(false);
      setViewallSites(true);
      setShowDistricts(false);
      setMappedAssetShow(false);
      setShowSiteRating(false);
      setSiteCreatePopUp(!getSitePopUp);
      return NotificationManager.success("Site Created");
    })
    .catch(err=>{
      return NotificationManager.error(err);
    });

    getAllSitesData();
  }

  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
  };

  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );

  const siteAgreement = "Create A New District";
  const siteModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange = {(e)=>setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          id="mailid"
          name="mailid"
          type="text"
          value={getMailId}
          onChange = {(e)=>setMailId(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={getPhone}
          onChange = {(e)=>setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getSiteType}
          onChange={(event) => setSiteType(event.target.value)}
          label="Select Model"
        >
          {getSiteData &&
            getSiteData.map((idObj, index) => (
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Of Create Site */

  /* Site Info */

  const siteInfoPopUpHandler = (infositeid) => {

    MainService.siteInfo(infositeid)
    .then(res=>{
      console.log("SiteInfo Data For Site Update",res)
      getSiteInfoName(res.siteProfile.sitename);
      getSiteInfoExistingSiteType(res.siteProfile.subdivision);
      getWebsiteUrl(res.siteProfile.designation);
      getLocAddress(res.siteProfile.contactaddr);
      getSiteInfoAssetVerticalId(res.siteProfile.assetverticalid);
      getIndustryType(res.siteProfile.industrytype);
      getStreetName(res.siteProfile.streetname);
      getStreetNumber(res.siteProfile.streetnumber);
      getLocality(res.siteProfile.locality);
      getArea(res.siteProfile.area);
      getRegion(res.siteProfile.region);
      getCountry(res.siteProfile.country);
      getPostalCode(res.siteProfile.postalcode);
      getLatitude(res.siteProfile.latitude);
      getLongitude(res.siteProfile.longitude);
      getAltitude(res.siteProfile.altitude);
      getTimeZone(res.siteProfile.timezone);
      getCityCode(res.siteProfile.citycode);
      getCityName(res.siteProfile.cityname);
      getCountryCode(res.siteProfile.countrycode);

    })
    .catch(err=>{
      return NotificationManager.error(err);
    });

    getInfoSiteId(infositeid);
    getSiteInfoPopUp(!setSiteInfoPopup);
  };

  const updateSiteInfoHandler = () =>{
    
    const siteUpdateInfo = {
      phone : setPhoneNumber,
      email : setEmail,
      sitetype : setUpdateSiteType,
      websiteurl : setWebsiteUrl,
      locationaddress : setLocAddress,
      streetname : setStreetName,
      streetnumber : setStreetNumber,
      locality : setLocality,
      area : setArea,
      region : setRegion,
      country : setCountry,
      postalcode : setPostalCode,
      latitude : setLatitude,
      longitude : setLongitude,
      altitude : setAltitude,
      timezone : setTimeZone,
      citycode : setCityCode,
      cityname : setCityName,
      countrycode : setCountryCode,
      stdcode: setStdCode
    };

    const formData = JSON.stringify(siteUpdateInfo);

    MainService.updateSiteInfo(setInfoSiteId,formData)
    .then(res=>{
      getSiteInfoPopUp(!setSiteInfoPopup);
      return NotificationManager.success(res);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    });
    getAllSitesData();
  };

  const closeSiteInfoModal = (
    <span
      onClick={() => {
        getSiteInfoPopUp(!setSiteInfoPopup);
      }}
    >
      X
    </span>
  );

  const siteInfoHeader = "SITE DETAILS";
  const SiteInfoBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteid"> Site Id:</InputLabel>
        <Input
          disabled
          id="siteid"
          name="siteid"
          type="text"
          value={setInfoSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          disabled
          id="sitename"
          name="sitename"
          type="text"
          value={setSiteInfoName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone"> Phone Number:</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="number"
          value={setPhoneNumber}
          onChange = {(e)=>getPhoneNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="email"> Email:</InputLabel>
        <Input
          id="email"
          name="email"
          type="text"
          value={setEmail}
          onChange = {(e)=>getEmail(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitetype"> Existing Site Type:</InputLabel>
        <Input
          disabled
          id="sitetype"
          name="sitetype"
          type="text"
          value={setSiteInfoExistingSiteType}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Update Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={setUpdateSiteType}
          onChange={(e) => getUpdateSiteType(e.target.value)}
          label="Select Model"
        >
              <MenuItem value="" disabled selected><em>Select Any Site Type</em></MenuItem>
              <MenuItem key="Farm" value="Farm">Farm</MenuItem>
              <MenuItem key="OpenFarm" value="OpenFarm">OpenFarm</MenuItem>
              <MenuItem key="PhysicalMarket" value="PhysicalMarket">Physical-Market</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="weburl"> Website URL:</InputLabel>
        <Input
          id="weburl"
          name="weburl"
          type="text"
          value={setWebsiteUrl}
          onChange = {(e)=>getWebsiteUrl(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locaddress"> Location Address:</InputLabel>
        <Input
          id="locaddress"
          name="locaddress"
          type="text"
          value={setLocAddress}
          onChange = {(e)=>getLocAddress(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetvi"> Asset Vertical Id:</InputLabel>
        <Input
          disabled
          id="assetvi"
          name="assetvi"
          type="text"
          value={setSiteInfoAssetVerticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="industrytype"> Industry Type (Asset Vertical Friendly Name):</InputLabel>
        <Input
          id="industrytype"
          name="industrytype"
          type="text"
          value={setIndustryType}
          onChange = {(e)=>getIndustryType(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetname"> Street Name:</InputLabel>
        <Input
          id="streetname"
          name="streetname"
          type="text"
          value={setStreetName}
          onChange = {(e)=>getStreetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetnumber"> Street Number:</InputLabel>
        <Input
          id="streetnumber"
          name="streetnumber"
          type="text"
          value={setStreetNumber}
          onChange = {(e)=>getStreetNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locality"> Locality:</InputLabel>
        <Input
          id="locality"
          name="locality"
          type="text"
          value={setLocality}
          onChange = {(e)=>getLocality(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="area"> Area (State):</InputLabel>
        <Input
          id="area"
          name="area"
          type="text"
          value={setArea}
          onChange = {(e)=>getArea(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="region"> Region:</InputLabel>
        <Input
          id="region"
          name="region"
          type="text"
          value={setRegion}
          onChange = {(e)=>getRegion(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="country"> Country:</InputLabel>
        <Input
          id="country"
          name="country"
          type="text"
          value={setCountry}
          onChange = {(e)=>getCountry(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="pstcode"> Postal Code(Zip):</InputLabel>
        <Input
          id="pstcode"
          name="pstcode"
          type="text"
          value={setPostalCode}
          onChange = {(e)=>getPostalCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={setLatitude}
          onChange = {(e)=>getLatitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude"> Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={setLongitude}
          onChange = {(e)=>getLongitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={setAltitude}
          onChange = {(e)=>getAltitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="timezone"> Time Zone:</InputLabel>
        <Input
          id="timezone"
          name="timezone"
          type="text"
          value={setTimeZone}
          onChange = {(e)=>getTimeZone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="citycode"> City Code:</InputLabel>
        <Input
          id="citycode"
          name="citycode"
          type="text"
          value={setCityCode}
          onChange = {(e)=>getCityCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="cityname"> City Name:</InputLabel>
        <Input
          id="cityname"
          name="cityname"
          type="text"
          value={setCityName}
          onChange = {(e)=>getCityName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="countrycode"> Country Code:</InputLabel>
        <Input
          id="countrycode"
          name="countrycode"
          type="text"
          value={setCountryCode}
          onChange = {(e)=>getCountryCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="stdcode"> Std Code:</InputLabel>
        <Input
          id="stdcode"
          name="stdcode"
          type="text"
          value={setStdCode}
          onChange = {(e)=>getStdCode(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateSiteInfoHandler}
        >
          Update
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          getSiteInfoPopUp(!setSiteInfoPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Of Site Info */
  /*************** Site Management Map *************/
    const SiteManagementGoogleMap = withGoogleMap(() => {
      return (
        <GoogleMap
            key={getAllSites.index}
            defaultZoom = {4}
            defaultCenter={setmarker_position}
            center={setmarker_position}
        >
            {getAllSites.map((value, index) => {
            return (
            (value) ? <Marker 
                        key ={index} 
                        position={{lat: parseFloat(value.latitude), lng: parseFloat(value.longitude)}}
                        >
                          <InfoWindow 
                          options={{disableAutoPan: true}} 
                          >
                            <button type="button" onClick={()=>{viewMappedAssets(value.siteid,"Map",value.sitename)}}>
                              <div>{value.sitename}</div>
                            </button>
                          </InfoWindow>
                      </Marker> : ''
            )
            })}
        </GoogleMap>
      );
    });

    const AssetsGoogleMap = withGoogleMap(() => {
      return (
        <GoogleMap
            key={getMappedAssets.index}
            defaultZoom = {4}
            defaultCenter={setmarker_position}
            center={setmarker_position}
        >
            {getMappedAssets.map((value, index) => {
            return (
            (value) ? <Marker 
                        key ={index} 
                        position={{lat: parseFloat(value.latitude), lng: parseFloat(value.longitude)}}
                        >
                          <InfoWindow 
                          options={{disableAutoPan: true}} 
                          >
                            <div>{value.assetType}</div>
                          </InfoWindow>
                      </Marker> : ''
            )
            })}
        </GoogleMap>
      );
    }); 
  /*************** End Of Site Management Map *************/
  
  /****************** Map, UnMap, Update, Delete Asset Api's **********************/

  const mapPopUpHandler = (assetInfo) =>{
    setMapAssetId(assetInfo.assetId);
    setMapCategoryType(assetInfo.category);
    setMapPopUp(!mapPopUp);
  };

  const addAssetToSiteMappingHandler = () =>{
    const siteIdData = {
      siteid : mapSiteId,
    };
    const siteid = JSON.stringify(siteIdData);
    MainService.createMapping(username, mapAssetId, mapCategoryType, siteid)
    .then((res) => {
      setMapPopUp(!mapPopUp);
      return NotificationManager.success(res);
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  };

  const unMapAssetHandler = (assetInfo) => {
    const siteId = assetInfo.siteId;
    const siteIdData = {
      siteid : siteId,
    };
    const siteidstr = JSON.stringify(siteIdData);
    MainService.createUnMapping(assetInfo.assetId,assetInfo.category,siteidstr,username)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  };

  const addedAssetDeleteHandler = (assetid) =>{
    MainService.getLegacyAssetInfo(username, assetid)
    .then(res=>{
      setMappedAssetDeleteAssetid(res.assetId);
      setMappedAssetDeleteAssetType(res.assetType);
      setMappedAssetDeleteAssetModelid(res.modelId);
      setMappedAssetDeleteAssetVerticalid(res.assetverticalId);
      setMappedAssetDeleteAssetCategory(res.category);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    });
    setShowDeletePopup(!getShowDeletePopup);
  };

  const assetDeleteHandler = () => {
    MainService.assetDelete(
      getMappedAssetDeleteAssetid,
      getMappedAssetDeleteAssetType,
      getMappedAssetDeleteAssetModelid,
      getMappedAssetDeleteAssetVerticalid,
      getMappedAssetDeleteAssetCategort,
      username
      )
    .then((res) => {
      setShowDeletePopup(false);
      return NotificationManager.success("successfully deleted");
    })
    .catch((err) => {
      return NotificationManager.error("error");
    });
  };

  const updateLegacyAssetHandler = (assetid) => {
    MainService.getLegacyAssetInfo(username, assetid)
    .then(res=>{
      setAssetVerticalId(res.assetverticalId);
      setAssetTypeVal(res.assetType);
      setAssetIdVal(res.assetId);
      setAssetFriName(res.friendlyName);
      setModelIdVal(res.modelId);
      setCatgeryVal(res.category);
      setUdnVal(res.udn);
      setWhiteLabelVal(res.whitelabelcost);
      setSellingCostVal(res.sellingcost);
      setExAssetStatus(res.assetstatus);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    });
    setLegacyUpdatePopup(!getlegacyUpdatePopup);
  };

  const updateAssetHandler = () => {
    const formData = {
      status : updateAssetStatus,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(assetIdVal,statusData)
    .then((res) => {
      setLegacyUpdatePopup(!getlegacyUpdatePopup);
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  };

  /****************** End Of Map, UnMap, Update, Delete Asset Api's **********************/

    /*Create Asset */
    const cretaeAssetModel = () => {
      setCreateAssetPopup(!getCreateAssetPopup);
    };
  
    const getAssetVerticalsIdList = () => {
      MainService.getAssetVerticalsIdList("consumer2")
        .then((data) => {
          setVerticalsIdList(data);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    };
  
    const setSelectedVerticalsIdHandler = (e) => {
      getCategoryTypesList(e.target.value);
      setSelectedVerticalsId(e.target.value);
    };
  
    const getCategoryTypesList = (assetVerticalId) => {
      MainService.getCategoryTypesList(assetVerticalId,"consumer2")
        .then((data) => {
          setCategoryTypesList(data);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    };
  
    const setSelectedCategoryHandler = (e) => {
      getAssetTypesList(selectedVerticalsId, e.target.value);
      setSelectedCategory(e.target.value);
    };
  
    const getAssetTypesList = (VerticalsId, Category) => {
      MainService.getAssetTypesList(VerticalsId, Category,"consumer2")
        .then((data) => {
          setAssertTypesList(data);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    };
  
    const setSelectedAssertTypesHandler = (e) => {
      getModelsList(selectedVerticalsId, selectedCategory, e.target.value);
      setSelectedAssertTypes(e.target.value);
    };
  
    const getModelsList = (VerticalsId, Category, AssertTypes) => {
      MainService.getModelsList(VerticalsId, Category, AssertTypes,"consumer2")
        .then((data) => {
          setModelsList(data);
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    };
  
    const createAssetClick = () => {
      let createAssetData = {
        assetFriendlyName: legacyAssetFriendlyNameValue,
        assetVerticalId: selectedVerticalsId,
        categoryType: selectedCategory,
        legacyAssetType: selectedAssertTypes,
        assetDomain: assetDomainValue,
        siteId: selectedSite,
        siteName: siteNameValue,
        lotId: lotNameValue,
        lotName: lotNameValue,
        manufacturerId: manufacturerIdValue,
        manufacturUrl: manufacturerUrlValue,
        modelId: selectedModel,
        modelName: modelFriendlyNameValue,
        modelType: selectedModel,
        modelUrl: modelUrlValue,
        serialNo: serialNoValue,
        udn: udnValue,
        upc: upcValue,
        motherBreed: motherBreedValue,
        fatherBreed: fatherBreedValue,
        description: descriptionValue,
        whitelableCost: whiteLabelCostValue,
        sellingcost: sellingCostValue,
        acquireCost: acquireCostValue,
        latitude: latitudeValue,
        longitude: longitudeValue,
        altitude: altitudeValue,
        actorType: "AssetDeveloper",
        consumerAdminId: "consumer2",
      };
      const legacyAssetData = JSON.stringify(createAssetData);
      MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
      setCreateAssetPopup(!getCreateAssetPopup);
    };
    /*End Of Create Asset */
    
    /* Site Feedback */
    const labels = {
      1: 'Very Poor',
      2: 'Poor',
      3: 'Fair',
      4: 'Good',
      5: 'Very Good',
    };

    const getLabelCustomerExperience = (getSiteCustomerExperience) => {
      return `${getSiteCustomerExperience} Star${getSiteCustomerExperience !== 1 ? 's' : ''}, 
      ${labels[getSiteCustomerExperience]}`;
    };

    const getLabelProduct = (getSiteProduct) => {
      return `${getSiteProduct} Star${getSiteProduct !== 1 ? 's' : ''}, 
      ${labels[getSiteProduct]}`;
    };

    const getLabelCustomerSupport = (getSiteCustomerSupport) => {
      return `${getSiteCustomerSupport} Star${getSiteCustomerSupport !== 1 ? 's' : ''}, 
      ${labels[getSiteCustomerSupport]}`;
    };

    const getLabelCustomerDelivery = (getSiteDelivery) => {
      return `${getSiteDelivery} Star${getSiteDelivery !== 1 ? 's' : ''}, 
      ${labels[getSiteDelivery]}`;
    };

    const getLabelServiceOthers  = (getSiteServiceOthers) => {
      return `${getSiteServiceOthers} Star${getSiteServiceOthers !== 1 ? 's' : ''}, 
      ${labels[getSiteServiceOthers]}`;
    };

    const siteFeedback = (siteid) => {
      setSiteFeedbackSiteid(siteid);
      setShowSiteFeedbackModel(!showSiteFeedbackModel);
    };

    const siteFeedbackSubmit = () => {
      const sitefeedback = {
        "siteid" : getSiteFeedbackSiteid,
        "how_would_you_rate_your_overall_customer_experience?" : getSiteFeedbackCustomerExperience,
        "how_satisfied_were_you_with_the_product?" : getSiteFeedbackProduct,
        "how_satisfied_were_you_with_the_customer_support?" : getSiteFeedbackCustomerSupport,
        "how_satisfied_were_you_with_the_timeliness_of_delivery?": getSiteFeedbackDelivery,
        "would_you_recommend_our_product_or_service_to_others?": getSiteFeedbackServiceOthers,
        "remark": getSiteFeedbackRemark,
        };
        const feeback = JSON.stringify(sitefeedback);
        MainService.siteFeedbackDetails(username,feeback)
        .then(res=>{
          setShowSiteFeedbackModel(!showSiteFeedbackModel);
          return NotificationManager.success(res);
        })
        .catch(err=>{
          return NotificationManager.error(err);
        })
    };
    /* End Of Site Feedback */

    /* Site Feedback */
    const labelsAsset = {
      1: 'Very Poor',
      2: 'Poor',
      3: 'Fair',
      4: 'Good',
      5: 'Very Good',
    };

    const getLabelAssetCustomerExperience = (getAssetCustomerExperience) => {
      return `${getAssetCustomerExperience} Star${getAssetCustomerExperience !== 1 ? 's' : ''}, 
      ${labels[getAssetCustomerExperience]}`;
    };

    const getLabelAssetProduct = (getAssetProduct) => {
      return `${getAssetProduct} Star${getAssetProduct !== 1 ? 's' : ''}, 
      ${labels[getAssetProduct]}`;
    };

    const getLabelAssetCustomerSupport = (getAssetCustomerSupport) => {
      return `${getAssetCustomerSupport} Star${getAssetCustomerSupport !== 1 ? 's' : ''}, 
      ${labels[getAssetCustomerSupport]}`;
    };

    const getLabelAssetCustomerDelivery = (getAssetDelivery) => {
      return `${getAssetDelivery} Star${getAssetDelivery !== 1 ? 's' : ''}, 
      ${labels[getAssetDelivery]}`;
    };

    const getLabelAssetServiceOthers  = (getAssetServiceOthers) => {
      return `${getAssetServiceOthers} Star${getAssetServiceOthers !== 1 ? 's' : ''}, 
      ${labels[getAssetServiceOthers]}`;
    };

    const assetFeedback = (assetid) => {
      setAssetFeedbackAssetid(assetid);
      setShowAssetFeedbackModel(!showAssetFeedbackModel);
    };

    const assetFeedbackSubmit = () => {
      const assetfeedback = {
        "assetdeveloperid" : getAssetFeedbackAssetDeveloperid,
        "assetid" : getAssetFeedbackAssetid,
        "how_would_you_rate_your_overall_customer_experience?" : getAssetFeedbackCustomerExperience,
        "how_satisfied_were_you_with_the_product?" : getAssetFeedbackProduct,
        "how_satisfied_were_you_with_the_customer_support?" : getAssetFeedbackCustomerSupport,
        "how_satisfied_were_you_with_the_timeliness_of_delivery?": getAssetFeedbackDelivery,
        "would_you_recommend_our_product_or_service_to_others?": getAssetFeedbackServiceOthers,
        "remark": getSiteFeedbackRemark,
        };
        const data = JSON.stringify(assetfeedback);
      MainService.assetFeedbackDetails(username, data)
      .then(res=>{
        setShowAssetFeedbackModel(!showAssetFeedbackModel);
        return NotificationManager.success(res);
      })
      .catch(err=>{
        return NotificationManager.error(err);
      })
    };
    /* End Of Site Feedback */

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          <div className="topHeaders">
                <Button 
                  startIcon={<MapIcon />}
                  size="large"
                  onClick={()=>{getAllSitesData("Map")}}
                >
                 
                </Button>
                <Button 
                 startIcon={<GridIcon />}
                 size="large"
                  onClick={()=>{getAllSitesData("Grid")}}
                >
                
                </Button>
                <Button 
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={()=>{getAllSitesData("List")}}
                >
                
                </Button>
                <Button 
                  variant="contained" 
                  style={{width:"200px",margin:"5px"}} 
                  color="primary"
                  className="registerBtn"
                  onClick={showAssetMappingHandler}
                >
                  Site Asset Mapping
                </Button>
                <Button 
                  variant="contained" 
                  style={{width:"200px",margin:"5px"}}
                  color="primary"
                  className="registerBtn"
                >
                  Diagnostics
                </Button>
          </div>
        </div>
        <div className="MuiGrid-root">
          { viewAllSites === true ?
          <Box sx={{ width: "100%", marginTop: "10px" }}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Add Site"
              className={classes.fab}
            >
              <AddIcon onClick={() => createSiteHandler()}></AddIcon>
            </Fab>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {getAllSites.length > 0 ? 
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 430 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={22}>
                        VIEW SITE LIST
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Site ID
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Site Name
                      </TableCell>
                      <TableCell align="center" colSpan={1} 
                        className={classes.tableRightBorder}>
                        Site Type
                      </TableCell>
                      <TableCell align="center" colSpan={9} 
                        className={classes.tableRightBorder}>
                        View
                      </TableCell>
                      <TableCell align="center" colSpan={6} 
                        className={classes.tableRightBorder}>
                        Operate
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getAllSites
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((site, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {site.siteid}
                              </TableCell>
                              <TableCell align="center">
                                {site.sitename}
                              </TableCell>
                              <TableCell align="center">
                                {site.sitetype}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=>viewMappedAssets(site.siteid,"Map",site.sitename)}
                                >
                                  <MapIcon
                                    titleAccess="Assets Map View"
                                  />
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=>viewMappedAssets(site.siteid,"Grid",site.sitename)}
                                >
                                   <GridIcon
                                    titleAccess="Assets Grid View"
                                  />
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=>viewMappedAssets(site.siteid,"List",site.sitename)}
                                >
                                   <ListIcon
                                    titleAccess="Assets List View"
                                  />
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=>siteInfoPopUpHandler(site.siteid)}
                                >
                                  Site Info
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Monitoring
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => siteRatingsHandler(site.siteid)}
                                >
                                  Site Rating
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Mortality
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Health Status
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=>deleteSiteHandler(site.siteid, site.sitename)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  style={{marginRight:"10px"}}
                                  size="small"
                                  variant="contained"
                                  onClick={()=>{siteFeedback(site.siteid)}}
                                >
                                  Site Feedback
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Issue
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Daily Report
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Alarms
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Group Nutrition
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Site Attendance
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Grow Request
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Sell Request
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  Loan Request
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                >
                                  VET Visit Request
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={getAllSites.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper> : <h4>No View Sites List</h4>
            }
          </Box>: null }

          {viewAllGridSites === true ? 
          <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Add Site"
              className={classes.fab}
            >
              <AddIcon onClick={() => createSiteHandler()}></AddIcon>
            </Fab>
            <SearchBar
              value={farmgridsearched}
              onChange={(searchVal) => requestFarmGridSearch(searchVal)}
              onCancelSearch={() => cancelFarmGridSearch()}
            />
            <div className="cardStyleForDashboardItems">
            <h4 style={{width:"100%",textAlign:"center"}}>View Site List</h4>
              {getAllSites.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {getAllSites.map((sites, index) => (
                    <div
                      key={index}
                      className="cardStyleForDashboardItemsBody"
                    >
                      <span>
                        <p>
                          <b>Site ID:</b>
                          {sites.siteid}
                        </p>
                        <p>
                          <b>Site Name:</b>
                          {sites.sitename}
                        </p>
                        <p>
                          <b>Site Type:</b>
                          {sites.sitetype}
                        </p>
                        <h3 className="cardactiontitle">
                          View
                        </h3>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>viewMappedAssets(sites.siteid,"Map", sites.sitename)}
                          >
                            <MapIcon
                              titleAccess="Assets Map View"
                            />
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>viewMappedAssets(sites.siteid, "Grid", sites.sitename)}
                          >
                            <GridIcon 
                              titleAccess="Assets Grid View"
                            />
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={() => viewMappedAssets(sites.siteid, "List", sites.sitename)}
                          >
                            <ListIcon 
                              titleAccess="Assets List View"
                            />
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>siteInfoPopUpHandler(sites.siteid)}
                          >
                            Site Info
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Monitoring
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                            onClick={() => siteRatingsHandler(sites.siteid)}
                          >
                            Site Rating
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Mortality
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Health Status
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>deleteSiteHandler(sites.siteid, sites.sitename)}
                          >
                            Delete
                          </Button>
                        </div>
                        <h3 className="cardactiontitle">
                          Operate
                        </h3>
                        <div style={{width:"100%",margin:"5px"}}>
                          
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          {/* <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>onBoardingButtonClickHandler(sites.siteid, "Grid")}
                          >
                            Onboarding
                          </Button> */}
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          {/* <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>weighingButtonClickHandler(sites.siteid, "Grid")}
                          >
                            Weighing
                          </Button> */}
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          {/* <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>assetOperationalStatusButtonClickHandler(sites.siteid, "Grid")}
                          >
                            Asset Operational Status
                          </Button> */}
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          {/* <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>sitePatrollingButtonClickHandler(sites.siteid, "Grid")}
                          >
                            Site Patrolling
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>sellingCostButtonClickHandler(sites.siteid, "Grid")}
                          >
                            Selling Cost
                          </Button> */}
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>{siteFeedback(sites.siteid)}}
                          >
                            Site Feedback
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Issue
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Daily Report
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Alarms
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Group Nutrition
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Site Attendance
                          </Button>
                        </div>
                        <h3 className="cardactiontitle">
                          Actions
                        </h3>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Grow Requests
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Sell Requests
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            Loan Requests
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"45%"}}
                            size="small"
                            variant="contained"
                          >
                            VET Visit Requests
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No View Sites List.</h4>
              )}
            </div>
          </Box>: null}

          {viewAllMapSites === true ?
          <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Add Site"
              className={classes.fab}
            >
              <AddIcon onClick={() => createSiteHandler()}></AddIcon>
            </Fab>
            {getAllSites.length > 0 ?
            <SiteManagementGoogleMap
              containerElement={ <div style={{ height: `500px`, width: '100%'}} /> }
              mapElement={ <div style={{ height: `100%` }} /> }
            /> : <h4>Sites List Not Found</h4>}
          </Box> : null }

          { showAssetList === true ?
          <Box sx={{ width: "100%", marginBottom: 30,marginTop: 30 }}>
            {getMappedAssets.length > 0 ? 
            <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 450 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={21}>
                      MAPPED LEGACY ASSET DEVICES
                    </TableCell>
                  </TableRow>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={1}>
                      Solution ID
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Site ID
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Asset ID
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Asset Type
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Category
                    </TableCell>
                    <TableCell align="center" colSpan={1} 
                      className={classes.tableRightBorder}>
                      Asset Status
                    </TableCell>
                    <TableCell align="center" colSpan={6} 
                      className={classes.tableRightBorder}>
                      View
                    </TableCell>
                    <TableCell align="center" colSpan={9} 
                      className={classes.tableRightBorder}>
                      Operate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getMappedAssets
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((assets, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="center">
                              {assets.solutionId}
                            </TableCell>
                            <TableCell align="center">
                              {assets.siteId}
                            </TableCell>
                            <TableCell align="center">
                              {assets.assetId}
                            </TableCell>
                            <TableCell align="center">
                              {assets.assetType}
                            </TableCell>
                            <TableCell align="center">
                              {assets.category}
                            </TableCell>
                            <TableCell align="center">
                              {assets.assetStatus}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={()=>mapPopUpHandler(assets)}
                              >
                                Map
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={()=>unMapAssetHandler(assets)}
                              >
                                UnMap
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={()=>updateLegacyAssetHandler(assets.assetId)}
                              >
                                Update
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={()=>addedAssetDeleteHandler(assets.assetId)}
                              >
                                Delete
                              </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                            >
                              Asset Score
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                            >
                              Asset Monitoring
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>attendanceLocationButtonClickHandler(assets.assetId, "List")}
                            >
                              Asset Attendance 
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>birthInfoButtonClickHandler(assets.assetId, "List")}
                            >
                              Birth
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>nutritionButtonClickHandler(assets.assetId, "List")}
                            >
                              Nutrition
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>mortalityButtonClickHandler(assets.assetId, "List")}
                            >
                              Mortality
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>parturitionButtonClickHandler(assets.assetId, "List")}
                            >
                              Parturition
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>healthRecordButtonClickHandler(assets.assetId, "List")}
                            >
                              Health Record
                            </Button>
                          </TableCell>
                          <TableCell align="center"> 
                            <Button
                              size="small"
                              variant="contained"
                              onClick={()=>assetFeedback(assets.assetId)}
                            >
                              Asset Feedback
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                            >
                              Photo Upload
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                            >
                              Asset Issue
                            </Button>
                          </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={getMappedAssets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper> : <h4>No Mapped Legacy Asset Devices</h4> 
            }
          </Box>: null }

          {showAssetGrid === true ? 
          <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Asset"
            className={classes.fab}
          >
            <AddIcon onClick={() => cretaeAssetModel()}></AddIcon>
          </Fab>
          <div className="cardStyleForDashboardItems">
          <h4 style={{width:"100%",textAlign:"center"}}>Mapped Legacy Asset Devices</h4>
            {getMappedAssets.length > 0 ? (
              <div className="cardStyleForDashboardItems">
                {getMappedAssets.map((assets, index) => (
                  <div
                    key={index}
                    className="cardStyleForDashboardItemsBody"
                  >
                    <span>
                      <p>
                        <b>Solution ID:</b>
                        {assets.solutionId}
                      </p>
                      <p>
                        <b>Site ID:</b>
                        {assets.siteId}
                      </p>
                      <p>
                        <b>Asset ID:</b>
                        {assets.assetId}
                      </p>
                      <p>
                        <b>Asset Type:</b>
                        {assets.assetType}
                      </p>
                      <p>
                        <b>Model ID:</b>
                        {assets.modelId}
                      </p>
                      <p>
                        <b>Category:</b>
                        {assets.category}
                      </p>
                      <p>
                        <b>Asset Status:</b>
                        {assets.assetStatus}
                      </p>
                      <h3 className="cardactiontitle">
                        View
                      </h3>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>mapPopUpHandler(assets)}
                          >
                            Map
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>unMapAssetHandler(assets)}
                          >
                            UnMap
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>updateLegacyAssetHandler(assets.assetId)}
                          >
                            Update
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>addedAssetDeleteHandler(assets.assetId)}
                          >
                            Delete
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                          >
                            Asset Score
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"40%"}}
                            size="small"
                            variant="contained"
                          >
                            Asset Monitoring
                          </Button>
                        </div>
                        <h3 className="cardactiontitle">
                          Operate
                        </h3>
                        <div style={{width:"100%",margin:"5px"}}>
                          {/* <Button
                            style={{marginRight:"10px", width:"60%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>AssetPatrollingButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Asset Patrolling
                          </Button> */}
                          <Button
                            style={{marginRight:"10px", width:"60%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>attendanceLocationButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Asset Attendance 
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>birthInfoButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Birth
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>nutritionButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Nutrition
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>mortalityButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Mortality
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>parturitionButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Parturition
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>healthRecordButtonClickHandler(assets.assetId, "Grid")}
                          >
                            Health Record
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                            onClick={()=>assetFeedback(assets.assetId)}
                          >
                            Asset Feedback
                          </Button>
                        </div>
                        <div style={{width:"100%",margin:"5px"}}>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                          >
                            Photo Upload
                          </Button>
                          <Button
                            style={{marginRight:"10px", width:"30%"}}
                            size="small"
                            variant="contained"
                          >
                            Asset Issue
                          </Button>
                        </div>
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <h4>No Mapped Legacy Asset Devices.</h4>
            )}
          </div>
          </Box>: null}

          {showAssetMap === true ?
          <Box sx={{ width: "100%", marginBottom: 30, marginTop:30}}>
            {getMappedAssets.length > 0 ?
            <AssetsGoogleMap
              containerElement={ <div style={{ height: `500px`, width: '100%'}} /> }
              mapElement={ <div style={{ height: `100%` }} /> }
            /> : <h4>No Mapped Legacy Asset Devices.</h4>}
          </Box> : null }

          { showDistricts === true ?
            <Box sx={{ width: "100%", marginBottom: 30,marginTop: 30 }}>
              {getAllSites.length > 0 ? 
                <TableContainer component={Paper}>
                <h2>LIST OF SITES</h2>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Site ID</StyledTableCell>
                      <StyledTableCell align="center">Site Name</StyledTableCell>
                      <StyledTableCell align="center">View Legacy Assets</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getAllSites
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.siteid}>
                        <StyledTableCell component="th" scope="row" align="center">{row.siteid}</StyledTableCell>
                        <StyledTableCell align="center">{row.sitename}</StyledTableCell>
                        <StyledTableCell align="center">
                          <Stack spacing={2} style={{margin:"2px",width:"70%"}} >
                            <Button variant="contained" onClick={() => viewMappedAssets(row.siteid)}>View</Button>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={getAllSites.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
              : <h4>No LIST OF SITES</h4> }
            </Box> 
          : null}

          { mappedAssetShow === true ?
            <Box sx={{ width: "100%", marginBottom: 30,marginTop: 30 }}>
              {getMappedAssets.length > 0 ? 
              <TableContainer component={Paper}>
              <h2>Mapped Legacy Asset Devices</h2>
              <p>
                  <Button variant="contained" onClick={()=>addUnmappedToMap(redirectSiteId)}>Add</Button>
              </p>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Asset Id</StyledTableCell>
                      <StyledTableCell align="center">Category</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getMappedAssets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.assetId}>
                        <StyledTableCell component="th" scope="row" align="center">{row.assetId}</StyledTableCell>
                        <StyledTableCell align="center">{row.category}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={getMappedAssets.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
              </TableContainer>
              : 
              <div>
                <p>
                  <Button variant="contained" onClick={()=>addUnmappedToMap(redirectSiteId)}>Add</Button>
                </p>
                <h4>No MAPPED LEGACY ASSETS FOUND</h4>
              </div>}
            </Box> 
          : null}

          { mapWithSite === true ?
            <Box sx={{ width: "100%", marginBottom: 30,marginTop: 30 }}>
              {getMapwithSite.length > 0 ? 
              <TableContainer component={Paper}>
              <h2>Select Legacy Asset To Map With Site</h2>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Asset Id</StyledTableCell>
                      <StyledTableCell align="center">Category</StyledTableCell>
                      <StyledTableCell align="center">Option</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getMapwithSite
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.assetId}>
                        <StyledTableCell component="th" scope="row" align="center">{row.assetId}</StyledTableCell>
                        <StyledTableCell align="center">{row.category}</StyledTableCell>
                        <StyledTableCell align="center">
                        <Button 
                          variant="contained" 
                          onClick={()=>mappedAssetValues(row.assetId,row.category)}
                        >
                          MAP
                        </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={getMappedAssets.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
              </TableContainer>
              : <h4>No UNMAPPED LEGACY ASSETS FOUND</h4>}
            </Box> 
          : null}

          { showSiteRating === true ?
            <Box sx={{ width: "100%", marginBottom: 30,marginTop: 30 }}>
              <p>
              <Button 
                variant="contained" 
                onClick={()=>addedSiteRatingHandler(getSiteRateSiteId)}
              >
                ADD
              </Button>
              </p>
              {getSiteRatings.length > 0 ? 
              <TableContainer component={Paper}>
              <h2>RATINGS</h2>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Id</StyledTableCell>
                      <StyledTableCell align="center">Rating</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSiteRatings
                    .map((row) => (
                      <StyledTableRow key={row.siteid}>
                        <StyledTableCell component="th" scope="row" align="center">{row.siteid}</StyledTableCell>
                        <StyledTableCell align="center">{row.ratings}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              : <h4>Ratings Not Found</h4>}
            </Box> 
          : null}

          {/************************ Onboarding ****************************/}
            {showOnboarding === true ? 
            <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={()=>{getAllSitesData(getBackType)}}
                  >
                  Back
                  </Button>
                </div>
              </div>
              {/* <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    // onClick={()=>{backToIcons()}}
                  >
                  Back
                  </Button>
                </div>
                <div className="topHeaders">
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={()=>{viewAssetOnboardingHandler()}}
                  >
                  View Asset Onboarding
                  </Button>
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    // onClick={()=>{getAllSites("List")}}
                  >
                    Add Site Onboarding
                  </Button>
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    // onClick={()=>{getAllSites("List")}}
                  >
                    View Site Onboarding
                  </Button>
                </div>
              </div> */}
              <div className="MuiGrid-root">
                {/* {showViewAssetOnboarding === true ?  */}
                <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                    {isLoading ? (
                        <div className="cardStyleForDashboardItems">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="cardStyleForDashboardItems">
                          <h4 style={{width:"100%",textAlign:"center"}}>VIEW ASSET ONBOARDINGS</h4>
                          {getViewAssetOnboarding.length > 0 ? (
                            <div className="cardStyleForDashboardItems">
                              {getViewAssetOnboarding.map((assets, index) => (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <span>
                                    <p>
                                      <b>Asset Id:</b>
                                      {assets.assetid}
                                    </p>
                                    <p>
                                      <b>White label Cost:</b>
                                      {assets.whitelabelcost}
                                    </p>
                                    <p>
                                      <b>Asset Status:</b>
                                      {assets.assetstatus}
                                    </p>
                                    <div style={{width:"100%",margin:"5px"}}>
                                      <Button
                                        style={{marginRight:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={()=>updateAssetOnboardingInfoPopup(assets)}
                                      >
                                        Update
                                      </Button>
                                    </div>
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <h4>VIEW ASSET ONBOARDINGS NOT FOUND.</h4>
                          )}
                        </div>
                    )}
                </Box>
                  {/* : null} */}
              </div>
            </Box> : null}
          {/************************ End Of Onboarding ****************************/}

          {/************************ Birth ****************************/}
            {showBirth === true ? 
            <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Consumer User"
                className={classes.fab}
                onClick={() => {
                  hideNewBirthInfoPopup(!showNewBirthInfoPopup)
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                  >
                  Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                    {isLoading ? (
                        <div className="cardStyleForDashboardItems">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="cardStyleForDashboardItems">
                          <h4 style={{width:"100%",textAlign:"center"}}>VIEW BIRTH DETAILS</h4>
                          {getBirthInfos.length > 0 ? (
                            <div className="cardStyleForDashboardItems">
                              {getBirthInfos.map((assets, index) => (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <span>
                                    <p>
                                      <b>Asset Id:</b>
                                      {assets.assetid}
                                    </p>
                                    <p>
                                      <b>Male Parent:</b>
                                      {assets.maleparent}
                                    </p>
                                    <p>
                                      <b>Female Parent:</b>
                                      {assets.femaleparent	}
                                    </p>
                                    <p>
                                      <b>D.O.B:</b>
                                      {assets.dob	}
                                    </p>
                                    <p>
                                      <b>Timestamp:</b>
                                      {assets.timestamp	}
                                    </p>
                                    <div style={{width:"100%",margin:"5px"}}>
                                      <Button
                                        style={{marginRight:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={()=>updateBirthInfoData(assets)}
                                      >
                                        Update
                                      </Button>
                                      <Button
                                        style={{marginRight:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={()=>deleteNewBirthInfoAssetId(assets.assetid)}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <h4>VIEW BIRTH DETAILS NOT FOUND.</h4>
                          )}
                        </div>
                    )}
                </Box>
              </div>
            </Box> : null}
          {/************************ End Of Birth ****************************/}

          {/************************  Attendance Location ****************************/}
            {showAttendanceLocation === true ? 
            <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Consumer User"
                className={classes.fab}
                onClick={() => {
                  hideNewAttendanceLocationPopup(!showNewAttendanceLocationPopup)
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{marginRight:"10px"}}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                  >
                  Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                    {isLoading ? (
                        <div className="cardStyleForDashboardItems">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="cardStyleForDashboardItems">
                          <h4 style={{width:"100%",textAlign:"center"}}>VIEW ATTENDANCE LOCATIONS</h4>
                          {getAttendanceLocationHistory.length > 0 ? (
                            <div className="cardStyleForDashboardItems">
                              {getAttendanceLocationHistory.map((assets, index) => (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <span>
                                    <p>
                                      <b>Asset Id:</b>
                                      {assets.assetid}
                                    </p>
                                    <p>
                                      <b>Latitude:</b>
                                      {assets.latitude}
                                    </p>
                                    <p>
                                      <b>Longitude:</b>
                                      {assets.longitude	}
                                    </p>
                                    <p>
                                      <b>Altitude:</b>
                                      {assets.altitude	}
                                    </p>
                                    <p>
                                      <b>Timestamp:</b>
                                      {assets.timestamp	}
                                    </p>
                                    <div style={{width:"100%",margin:"5px"}}>
                                      <Button
                                        style={{marginRight:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={()=>updateAttendanceLocationsAssetInfo(assets)}
                                      >
                                        Update
                                      </Button>
                                      <Button
                                        style={{marginRight:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={()=>deleteAttendanceLocationsAssetId(assets.assetid)}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <h4>VIEW ATTENDANCE LOCATIONS NOT FOUND.</h4>
                          )}
                        </div>
                    )}
                </Box>
              </div>
            </Box> : null}
          {/************************ End Of Attendance Location ****************************/}

           {/************************ Health Records ****************************/}
              {showHealthRecords === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideNewHealthRecordPopup(!showNewHealthRecordPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW HEALTH RECORDS</h4>
                            {getHealthRecords.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getHealthRecords.map((assets, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {assets.assetid}
                                      </p>
                                      <p>
                                        <b>Medicine Type:</b>
                                        {assets.medicinetype}
                                      </p>
                                      <p>
                                        <b>Disease Type:</b>
                                        {assets.diseasetype}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {assets.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateHealthRecordInfo(assets)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteHealthRecordAssetId(assets.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW HEALTH RECORDS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Health Records ****************************/}

           {/************************ Nutrition ****************************/}
              {showNutrition === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideAddNutritionPopup(!showAddNutritionPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW NUTRITION DETAILS</h4>
                            {getNutritions.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getNutritions.map((nutrition, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {nutrition.assetid}
                                      </p>
                                      <p>
                                        <b>Feed Type:</b>
                                        {nutrition.feedtype}
                                      </p>
                                      <p>
                                        <b>Schedule:</b>
                                        {nutrition.schedule}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {nutrition.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateNutririonInfo(nutrition)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteNutririonAssetid(nutrition.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW NUTRITION DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Nutrition ****************************/}

           {/************************ Weighing ****************************/}
              {showWeighing === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideNewWeighingPopup(!showNewWeighingPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{getAllSitesData(getBackType)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW WEIGHING DETAILS</h4>
                            {getWeighingDetail.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getWeighingDetail.map((weighing, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {weighing.assetid}
                                      </p>
                                      <p>
                                        <b>Weaning Age:</b>
                                        {weighing.weaningage}
                                      </p>
                                      <p>
                                        <b>Weaning Weight:</b>
                                        {weighing.weaningweight}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {weighing.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateWeighingInfo(weighing)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteWeighingAssetid(weighing.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW WEIGHING DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Weighing ****************************/}

           {/************************ Parturition ****************************/}
              {showParturition === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideNewParturitionPopup(!showNewParturitionPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW PARTURITION DETAILS</h4>
                            {getParturitionDetail.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getParturitionDetail.map((parturitions, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {parturitions.assetid}
                                      </p>
                                      <p>
                                        <b>Breed:</b>
                                        {parturitions.breed}
                                      </p>
                                      <p>
                                        <b>Breeder Id:</b>
                                        {parturitions.breederid}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {parturitions.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateParturitionInfo(parturitions)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteParturitionAssetid(parturitions.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW PARTURITION DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Parturition ****************************/}

           {/************************ Mortality ****************************/}
              {showMortality === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideNewMortalityPopuop(!showNewMortalityPopuop)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton, getNewBirthSiteName)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW MORTALITY DETAILS</h4>
                            {getMortalityDetails.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getMortalityDetails.map((mortality, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {mortality.assetid}
                                      </p>
                                      <p>
                                        <b>Cause Of Death:</b>
                                        {mortality.causeofdeath}
                                      </p>
                                      <p>
                                        <b>Gender:</b>
                                        {mortality.gender}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {mortality.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateMortalityInfo(mortality)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteMortalityAssetid(mortality.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW MORTALITY DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Mortality ****************************/}

           {/************************ Asset Operational Status ****************************/}
              {showOperationalStatus === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    hideNewAssetOperationalPopup(!showNewAssetOperationalPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{getAllSitesData(getBackType)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW ASSET OPERATIONAL STATUS DETAILS</h4>
                            {getOperationalStatusDetails.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getOperationalStatusDetails.map((operational, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {operational.assetid}
                                      </p>
                                      <p>
                                        <b>Asset Friendly Name :</b>
                                        {operational.assetfriendlyname}
                                      </p>
                                      <p>
                                        <b>Status:</b>
                                        {operational.status}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {operational.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateAssetOperationalStatusInfo(operational)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteAssetOperationalStatusAssetid(operational.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW ASSET OPERATIONAL STATUS DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Asset Operational Status ****************************/}

           {/************************ Selling Cost ****************************/}
              {showSelling === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    setAddSellingCostPopup(!getAddSellingCostPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{getAllSitesData(getBackType)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW SELLING COST DETAILS</h4>
                            {getSellingDetails.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getSellingDetails.map((selling, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {selling.assetid}
                                      </p>
                                      <p>
                                        <b>Asset Friendly Name :</b>
                                        {selling.friendlyname}
                                      </p>
                                      <p>
                                        <b>Selling Cost:</b>
                                        {selling.sellingcost}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {selling.timestamp}
                                      </p>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW SELLING COST DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Selling Cost ****************************/}

           {/************************ Site Patroling ****************************/}
              {showPatrolling === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    getShowSitePatrolingPopup(!showSitePatrolingPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{getAllSitesData(getBackType)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW SITE PATROLLING DETAILS</h4>
                            {getViewSitePatrolling.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getViewSitePatrolling.map((sitepatroling, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Site Id:</b>
                                        {sitepatroling.siteid}
                                      </p>
                                      <p>
                                        <b>Site Friendly Name:</b>
                                        {sitepatroling.friendlyname}
                                      </p>
                                      <p>
                                        <b>Mobile Number:</b>
                                        {sitepatroling.mobilenumber}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {sitepatroling.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateSitePatrolingInfo(sitepatroling)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteSitePatrolingInfo(sitepatroling.siteid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW SITE PATROLLING DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Site Patroling ****************************/}

            {/************************ Asset Patroling ****************************/}
              {showAssetPatrolling === true ? 
              <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                <Fab
                  color="primary"
                  size={"large"}
                  aria-label="add"
                  title="Add Consumer User"
                  className={classes.fab}
                  onClick={() => {
                    setShowNewAssetPatrollingPopup(!getShowNewAssetPatrollingPopup)
                  }}
                >
                  <AddIcon></AddIcon>
                </Fab>
                <div className="topHeadersWrapper">
                  <div className="topHeaders">
                    <Button
                      style={{marginRight:"10px"}}
                      color="primary"
                      className="registerBtn"
                      variant="contained"
                      onClick={()=>{viewMappedAssets(redirectSiteId, getAssetBackButtton)}}
                    >
                    Back
                    </Button>
                  </div>
                </div>
                <div className="MuiGrid-root">
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop:30 }}>
                      {isLoading ? (
                          <div className="cardStyleForDashboardItems">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="cardStyleForDashboardItems">
                            <h4 style={{width:"100%",textAlign:"center"}}>VIEW ASSET PATROLLING DETAILS</h4>
                            {getViewAssetPatrolling.length > 0 ? (
                              <div className="cardStyleForDashboardItems">
                                {getViewAssetPatrolling.map((assetpatroling, index) => (
                                  <div
                                    key={index}
                                    className="cardStyleForDashboardItemsBody"
                                  >
                                    <span>
                                      <p>
                                        <b>Asset Id:</b>
                                        {assetpatroling.assetid}
                                      </p>
                                      <p>
                                        <b>Asset Friendly Name:</b>
                                        {assetpatroling.friendlyname}
                                      </p>
                                      <p>
                                        <b>Mobile Number:</b>
                                        {assetpatroling.mobilenumber}
                                      </p>
                                      <p>
                                        <b>Timestamp:</b>
                                        {assetpatroling.timestamp}
                                      </p>
                                      <div style={{width:"100%",margin:"5px"}}>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>updateAssetPatrolingInfo(assetpatroling)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          style={{marginRight:"10px"}}
                                          size="small"
                                          variant="contained"
                                          onClick={()=>deleteAssetPatrolingInfo(assetpatroling.assetid)}
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4>VIEW ASSET PATROLLING DETAILS NOT FOUND.</h4>
                            )}
                          </div>
                      )}
                  </Box>
                </div>
              </Box> : null}
           {/************************ End Of Asset Patroling ****************************/}

          {/* Update Asset Onboarding Info */}
            <Dialog
              fullScreen={fullScreen}
              open={getUpdateAssetOnboardingInfoPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Asset Onboarding Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      disabled
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateAssetId}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="WhiteLabelCost">White Label Cost:</InputLabel>
                    <Input
                      disabled
                      id="WhiteLabelCost"
                      name="WhiteLabelCost"
                      type="text"
                      value={getWhiteLabelCost}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">Existing Asset Status:</InputLabel>
                    <Input
                      disabled
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getExistingAssetStatus}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Updated Asset Status:</InputLabel>
                    <Select
                      id="updatestatus"
                      name="updatestatus"
                      type="text"
                      value={getUpdatedAssetStatus}
                      onChange={(e) => {
                        setUpdatedAssetStatus(e.target.value);
                      }}
                    >
                      <MenuItem key="ReadyForWhiteLabel" value="Ready For White Label">
                        Ready For White Label
                      </MenuItem>
                      <MenuItem key="Deboard" value="Deboard">
                        Deboard
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateReadyForOnboardAssetStatusHandler();
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setUpdateAssetOnboardingInfoPopup(
                      !getUpdateAssetOnboardingInfoPopup
                    )
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Asset Onboarding Info */}

          {/* New Birth Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewBirthInfoPopup}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"New Birth Info"}
              </DialogTitle>
              <DialogContent>
                {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewBirthAssetid}
                    onChange={(e) => setNewBirthAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getNewBirthAssetid}
                    // onChange={(e) => {
                    //   setNewBirthAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset Friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getNewBirthAssetFriendlyName}
                    onChange={(e) => {
                      setNewBirthAssetFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User ID :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getNewBirthUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Latitude">User Name :</InputLabel>
                  <Input
                    id="Latitude"
                    name="Latitude"
                    type="text"
                    value={getNewBirthUserName}
                    onChange={(e) => {
                      setNewBirthUserName(e.target.value)
                    }}
                  />
                </FormControl>
                {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site ID</InputLabel>
                  <Select
                    id="sitename"
                    value={getNewBirthSiteid}
                    onChange={(e) => setNewBirthSiteidHandler(e)}
                    label="sitename"
                  >
                    {getAllSites &&
                      getAllSites.map((sites, index) => (
                        <MenuItem key={`${index}`} value={sites.siteid}>
                          {sites.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getNewBirthSiteid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="sitename">Site Name :</InputLabel>
                  <Input
                    id="sitename"
                    name="sitename"
                    type="text"
                    value={getNewBirthSiteName}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
                  <Input
                    id="lotid"
                    name="lotid"
                    type="text"
                    value={getNewBirthLotid}
                    onChange={(e) => {
                      setNewBirthLotid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
                  <Input
                    id="lotname"
                    name="lotname"
                    type="text"
                    value={getNewBirthLotName}
                    onChange={(e) => {
                      setNewBirthLotName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
                  <Input
                    id="assetType"
                    name="assetType"
                    type="text"
                    value={getNewBirthLegacyAssetType}
                    onChange={(e) => {
                      setNewBirthLegacyAssetType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelid">Model ID :</InputLabel>
                  <Input
                    id="modelid"
                    name="modelid"
                    type="text"
                    value={getNewBirthModelid}
                    onChange={(e) => {
                      setNewBirthModelid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelname">Model Friendly Name :</InputLabel>
                  <Input
                    id="modelname"
                    name="modelname"
                    type="text"
                    value={getNewBirthModelFriendlyName}
                    onChange={(e) => {
                      setNewBirthModelFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
                  <Input
                    id="modeltype"
                    name="modeltype"
                    type="text"
                    value={getNewBirthModelType}
                    onChange={(e) => {
                      setNewBirthModelType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
                  <Input
                    id="modelurl"
                    name="modelurl"
                    type="text"
                    value={getNewBirthModelURL}
                    onChange={(e) => {
                      setNewBirthModelURL(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  {/* <InputLabel htmlFor="dob">DOB :</InputLabel> */}
                  <Input
                    id="dob"
                    name="dob"
                    type="date"
                    value={getNewBirthDOB}
                    onChange={(e) => {
                      setNewBirthDOB(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="gender">Gender :</InputLabel>
                  <Input
                    id="gender"
                    name="gender"
                    type="text"
                    value={getNewBirthGender}
                    onChange={(e) => {
                      setNewBirthGender(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
                  <Input
                    id="maleparent"
                    name="maleparent"
                    type="text"
                    value={getNewBirthMaleParent}
                    onChange={(e) => {
                      setNewBirthMaleParent(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
                  <Input
                    id="femaleparent"
                    name="femaleparent"
                    type="text"
                    value={getNewBirthFemaleParent}
                    onChange={(e) => {
                      setNewBirthFemaleParent(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="parentmodelid">Parent Model Id :</InputLabel>
                  <Input
                    id="parentmodelid"
                    name="parentmodelid"
                    type="text"
                    value={getNewBirthParentModelid}
                    onChange={(e) => {
                      setNewBirthParentModelid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewBirthLatitude}
                    onChange={(e) => {
                      setNewBirthLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getNewBirthLongitude}
                    onChange={(e) => {
                      setNewBirthLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getNewBirthAltitude}
                    onChange={(e) => {
                      setNewBirthAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getNewBirthDescription}
                      onChange={(e) => {
                        setNewBirthDescription(e.target.value)
                      }}
                    />
                  </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    createNewBirthInfo()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideNewBirthInfoPopup(!showNewBirthInfoPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Birth Info */}

          {/* Delete Birth Info */}
            <Dialog
              fullScreen={fullScreen}
              open={getDeleteBirthInfoPopup}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"Delete Birth Details"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteBirthInfoAsset()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Birth Info */}

          {/* Update Birth Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateBirthInfoPopup}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"Update Birth Info"}
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getUpdateNewBirthAssetid}
                    // onChange={(e) => {
                    //   setUpdateNewBirthAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset Friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getUpdateNewBirthAssetFriendlyName}
                    onChange={(e) => {
                      setUpdateNewBirthAssetFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User ID :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getUpdateNewBirthUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Latitude">User Name :</InputLabel>
                  <Input
                    id="Latitude"
                    name="Latitude"
                    type="text"
                    value={getUpdateNewBirthUserName}
                    // onChange={(e) => {
                    //   setNewBirthUserName(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site ID</InputLabel>
                  <Select
                    id="sitename"
                    value={getUpdateNewBirthSiteid}
                    onChange={(e) => setNewBirthSiteidHandler(e)}
                    label="sitename"
                  >
                    {getAllSites &&
                      getAllSites.map((sites, index) => (
                        <MenuItem key={`${index}`} value={sites.siteid}>
                          {sites.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">Site Name :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getUpdateNewBirthSiteName}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
                  <Input
                    id="lotid"
                    name="lotid"
                    type="text"
                    value={getUpdateNewBirthLotid}
                    onChange={(e) => {
                      setUpdateNewBirthLotid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
                  <Input
                    id="lotname"
                    name="lotname"
                    type="text"
                    value={getUpdateNewBirthLotName}
                    onChange={(e) => {
                      setUpdateNewBirthLotName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
                  <Input
                    id="assetType"
                    name="assetType"
                    type="text"
                    value={getUpdateNewBirthLegacyAssetType}
                    onChange={(e) => {
                      setUpdateNewBirthLegacyAssetType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelid">Model ID :</InputLabel>
                  <Input
                    id="modelid"
                    name="modelid"
                    type="text"
                    value={getUpdateNewBirthModelid}
                    onChange={(e) => {
                      setUpdateNewBirthModelid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelname">Model Friendly Name :</InputLabel>
                  <Input
                    id="modelname"
                    name="modelname"
                    type="text"
                    value={getUpdateNewBirthModelFriendlyName}
                    onChange={(e) => {
                      setUpdateNewBirthModelFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
                  <Input
                    id="modeltype"
                    name="modeltype"
                    type="text"
                    value={getUpdateNewBirthModelType}
                    onChange={(e) => {
                      setUpdateNewBirthModelType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
                  <Input
                    id="modelurl"
                    name="modelurl"
                    type="text"
                    value={getUpdateNewBirthModelURL}
                    onChange={(e) => {
                      setUpdateNewBirthModelURL(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="dob">DOB :</InputLabel>
                  <Input
                    id="dob"
                    name="dob"
                    type="date"
                    // value={getUpdateNewBirthDOB}
                    defaultValue={getUpdateNewBirthDOB}
                    onChange={(e) => {
                      setUpdateNewBirthDOB(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="gender">Gender :</InputLabel>
                  <Input
                    id="gender"
                    name="gender"
                    type="text"
                    value={getUpdateNewBirthGender}
                    onChange={(e) => {
                      setUpdateNewBirthGender(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
                  <Input
                    id="maleparent"
                    name="maleparent"
                    type="text"
                    value={getUpdateNewBirthMaleParent}
                    onChange={(e) => {
                      setUpdateNewBirthMaleParent(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
                  <Input
                    id="femaleparent"
                    name="femaleparent"
                    type="text"
                    value={getUpdateNewBirthFemaleParent}
                    onChange={(e) => {
                      setUpdateNewBirthFemaleParent(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="parentmodelid">Parent Model Id :</InputLabel>
                  <Input
                    id="parentmodelid"
                    name="parentmodelid"
                    type="text"
                    value={getUpdateNewBirthParentModelid}
                    onChange={(e) => {
                      setUpdateNewBirthParentModelid(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewBirthLatitude}
                    onChange={(e) => {
                      setUpdateNewBirthLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateNewBirthLongitude}
                    onChange={(e) => {
                      setUpdateNewBirthLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateNewBirthAltitude}
                    onChange={(e) => {
                      setUpdateNewBirthAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getUpdateNewBirthDescription}
                      onChange={(e) => {
                        setUpdateNewBirthDescription(e.target.value)
                      }}
                    />
                  </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateNewBirthInfoHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideUpdateBirthInfoPopup(!showUpdateBirthInfoPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Birth Info */}

          {/* New Attendance Location */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewAttendanceLocationPopup}
              // onClose={handleClose}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"New Attendance Location"}
              </DialogTitle>
              <DialogContent>
                {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewAttendanceLocationAssetid}
                    onChange={(e) => setNewAttendanceLocationAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getNewAttendanceLocationAssetid}
                    // onChange={(e) => {
                    //   setNewAttendanceLocationAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getNewAttendanceLocationAssetfriendlyName}
                    onChange={(e) => {
                      setNewAttendanceLocationAssetfriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User id :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getNewAttendanceLocationUserid}
                    // onChange={(e) => {
                    //   setNewAttendanceLocationUserid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                  <Input
                    id="Latitude"
                    name="Latitude"
                    type="text"
                    value={getNewAttendanceLocationLatitude}
                    onChange={(e) => {
                      setNewAttendanceLocationLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                  <Input
                    id="Longitude"
                    name="Longitude"
                    type="text"
                    value={getNewAttendanceLocationLongitude}
                    onChange={(e) => {
                      setNewAttendanceLocationLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                  <Input
                    id="Altitude"
                    name="Altitude"
                    type="text"
                    value={getNewAttendanceLocationAltitude}
                    onChange={(e) => {
                      setNewAttendanceLocationAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getNewAttendanceLocationDescription}
                      onChange={(e) => {
                        setNewAttendanceLocationDescription(e.target.value)
                      }}
                    />
                  </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addAttendanceLocationHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideNewAttendanceLocationPopup(!showNewAttendanceLocationPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Attendance Location */}

          {/* Delete Attendance Location */}
            <Dialog
              fullScreen={fullScreen}
              open={getDeletedAttendancePopup}
              // onClose={handleClose}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"Delete Attendance Location"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteAttendanceAsset()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setDeletedAttendancePopup(!getDeletedAttendancePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Attendance Location */}

          {/* Update Attendance Location Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateAttendaneLocationPopup}
              // onClose={handleClose}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"Update Attendance Location Info"}
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                  <Input
                    disabled
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getUpdateAttendanceLocationInfoAssetid}
                    // onChange={(e) => {
                    //   setUpdateAttendanceLocationInfoAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getUpdateAttendanceLocationInfoAssetfriendlyName}
                    onChange={(e) => {
                      setUpdateAttendanceLocationInfoAssetfriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User id :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getUpdateAttendanceLocationInfoUserid}
                    // onChange={(e) => {
                    //   setNewAttendanceLocationUserid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                  <Input
                    id="Latitude"
                    name="Latitude"
                    type="text"
                    value={getUpdateAttendanceLocationInfoLatitude}
                    onChange={(e) => {
                      setUpdateAttendanceLocationInfoLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                  <Input
                    id="Longitude"
                    name="Longitude"
                    type="text"
                    value={getUpdateAttendanceLocationInfoLongitude}
                    onChange={(e) => {
                      setUpdateAttendanceLocationInfoLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                  <Input
                    id="Altitude"
                    name="Altitude"
                    type="text"
                    value={getUpdateAttendanceLocationInfoAltitude}
                    onChange={(e) => {
                      setUpdateAttendanceLocationInfoAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getUpdateAttendanceLocationInfoDescription}
                      onChange={(e) => {
                        setUpdateAttendanceLocationInfoDescription(e.target.value)
                      }}
                    />
                  </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateAttendanceLocationsHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideUpdateAttendaneLocationPopup(!showUpdateAttendaneLocationPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Attendance Location Info */}

          {/* New Health Record */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewHealthRecordPopup}
              aria-labelledby="newhealthrecord"
            >
              <DialogTitle id="newhealthrecord">
                {"New Health Record"}
              </DialogTitle>
              <DialogContent>
                {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewHealthAssetid}
                    onChange={(e) => setNewHealthAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getNewHealthAssetid}
                    // onChange={(e) => {
                    //   setNewHealthAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset Friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getNewHealthAssetFriendlyName}
                    onChange={(e) => {
                      setNewHealthAssetFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User ID :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getNewHealthUserid}
                    // onChange ={(e)=>{setNewHealthUserid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
                  <Input
                    id="Medicine"
                    name="Medicine"
                    type="date"
                    value={getNewHealthMedicineDate}
                    onChange={(e) => {
                      setNewHealthMedicineDate(e.target.value)
                    }}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Vaccination">Vaccination Date :</InputLabel>
                  <Input
                    id="Vaccination"
                    name="Vaccination"
                    type="date"
                    value={getNewHealthVaccinationDate}
                    onChange={(e) => {
                      setNewHealthVaccinationDate(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Examine">Examine :</InputLabel>
                  <Input
                    id="Examine"
                    name="Examine"
                    type="text"
                    value={getNewHealthExamine}
                    onChange={(e)=>{setNewHealthExamine(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
                  <Input
                    id="Surgical"
                    name="Surgical"
                    type="text"
                    value={getNewHealthSurgicalProcedure}
                    onChange={(e) => {
                      setNewHealthSurgicalProcedure(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Vaccination">Vaccination Type :</InputLabel>
                  <Input
                    id="Vaccination"
                    name="Vaccination"
                    type="text"
                    value={getNewHealthVaccinationType}
                    onChange={(e) => {
                      setNewHealthVaccinationType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
                  <Input
                    id="MedicineType"
                    name="MedicineType"
                    type="text"
                    value={getNewHealthMedicineType}
                    onChange={(e) => {
                      setNewHealthMedicineType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
                  <Input
                    id="Disease"
                    name="Disease"
                    type="text"
                    value={getNewHealthDiseaseType}
                    onChange={(e) => {
                      setNewHealthDiseaseType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
                  <Input
                    id="Treatment"
                    name="Treatment"
                    type="date"
                    value={getNewHealthTreatmentDate}
                    onChange={(e) => {
                      setNewHealthTreatmentDate(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewHealthLatitude}
                    onChange={(e) => {
                      setNewHealthLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getNewHealthLongitude}
                    onChange={(e) => {
                      setNewHealthLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getNewHealthAltitude}
                    onChange={(e) => {
                      setNewHealthAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getNewHealthDescription}
                      onChange={(e) => {
                        setNewHealthDescription(e.target.value)
                      }}
                    />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addHealthRecordHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideNewHealthRecordPopup(!showNewHealthRecordPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Health Record */}

          {/* Delete HEALTH RECORD */}
            <Dialog
              fullScreen={fullScreen}
              open={showDeleteHealthAssetPopup}
              aria-labelledby="craete Consumer User"
            >
              <DialogTitle id="createConsumerUser">
                {"Delete Health Record"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteHealthRecordAsset()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideDeleteHealthAssetPopup(!showDeleteHealthAssetPopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete HEALTH RECORD*/}

          {/* Update Health Record */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateNewHealthRecordPopup}
              aria-labelledby="newhealthrecord"
            >
              <DialogTitle id="newhealthrecord">
                {"Update Health Record"}
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getUpdateNewHealthAssetid}
                    // onChange={(e) => {
                    //   setUpdateNewHealthAssetid(e.target.value)
                    // }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendlyName">Asset Friendly Name :</InputLabel>
                  <Input
                    id="friendlyName"
                    name="friendlyName"
                    type="text"
                    value={getUpdateNewHealthAssetFriendlyName}
                    onChange={(e) => {
                      setUpdateNewHealthAssetFriendlyName(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="userid">User ID :</InputLabel>
                  <Input
                    disabled
                    id="userid"
                    name="userid"
                    type="text"
                    value={getUpdateNewHealthUserid}
                    // onChange ={(e)=>{setUpdateNewHealthUserid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
                  <Input
                    id="Medicine"
                    name="Medicine"
                    type="date"
                    // value={getUpdateNewHealthMedicineDate}
                    defaultValue={getUpdateNewHealthMedicineDate}
                    onChange={(e) => {
                      setUpdateNewHealthMedicineDate(e.target.value)
                    }}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Vaccination">Vaccination Date :</InputLabel>
                  <Input
                    id="Vaccination"
                    name="Vaccination"
                    type="date"
                    // value={getUpdateNewHealthVaccinationDate}
                    defaultValue={getUpdateNewHealthVaccinationDate}
                    onChange={(e) => {
                      setUpdateNewHealthVaccinationDate(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Examine">Examine :</InputLabel>
                  <Input
                    id="Examine"
                    name="Examine"
                    type="text"
                    value={getUpdateNewHealthExamine}
                    onChange={(e)=>{setUpdateNewHealthExamine(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
                  <Input
                    id="Surgical"
                    name="Surgical"
                    type="text"
                    value={getUpdateNewHealthSurgicalProcedure}
                    onChange={(e) => {
                      setUpdateNewHealthSurgicalProcedure(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Vaccination">Vaccination Type :</InputLabel>
                  <Input
                    id="Vaccination"
                    name="Vaccination"
                    type="text"
                    value={getUpdateNewHealthVaccinationType}
                    onChange={(e) => {
                      setUpdateNewHealthVaccinationType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
                  <Input
                    id="MedicineType"
                    name="MedicineType"
                    type="text"
                    value={getUpdateNewHealthMedicineType}
                    onChange={(e) => {
                      setUpdateNewHealthMedicineType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
                  <Input
                    id="Disease"
                    name="Disease"
                    type="text"
                    value={getUpdateNewHealthDiseaseType}
                    onChange={(e) => {
                      setUpdateNewHealthDiseaseType(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
                  <Input
                    id="Treatment"
                    name="Treatment"
                    type="date"
                    // value={getUpdateNewHealthTreatmentDate}
                    defaultValue={getUpdateNewHealthTreatmentDate}
                    onChange={(e) => {
                      setUpdateNewHealthTreatmentDate(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewHealthLatitude}
                    onChange={(e) => {
                      setUpdateNewHealthLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateNewHealthLongitude}
                    onChange={(e) => {
                      setUpdateNewHealthLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateNewHealthAltitude}
                    onChange={(e) => {
                      setUpdateNewHealthAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getUpdateNewHealthDescription}
                      onChange={(e) => {
                        setUpdateNewHealthDescription(e.target.value)
                      }}
                    />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateHealthRecordHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup)
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Health Record */}

          {/* New Nutrition details */}
            <Dialog
              fullScreen={fullScreen}
              open={showAddNutritionPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                New Nutrition details
              </DialogTitle>
              <DialogContent>
                <div>
                  {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getAddNutritionAssetid}
                      onChange={(e) => setAddNutritionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getAddNutritionAssetid}
                      // onChange={(e)=>{setAddNutritionAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getAddNutritionAssetFriendlyName}
                      onChange={(e)=>{setAddNutritionAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getAddNutritionUserid}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Select Feed Type:</InputLabel>
                    <Select
                      id="feedtype"
                      name="feedtype"
                      type="text"
                      value={getAddNutritionFeedType}
                      onChange={(e) => {
                        setAddNutritionFeedType(e.target.value);
                      }}
                    >
                      <MenuItem key="Milk" value="Milk">Milk</MenuItem>
                      <MenuItem key="Colstrum" value="Colstrum">Colstrum</MenuItem>
                      <MenuItem key="Rough-Age" value="Rough-Age">Rough-Age</MenuItem>
                      <MenuItem key="Concentrate" value="Concentrate">Concentrate</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Select Schedule:</InputLabel>
                    <Select
                      id="Schedule"
                      name="Schedule"
                      type="text"
                      value={getAddNutritionSchedule}
                      onChange={(e) => {
                        setAddNutritionSchedule(e.target.value);
                      }}
                    >
                      <MenuItem key="Morning" value="Morning">Morning</MenuItem>
                      <MenuItem key="Afternoon" value="Afternoon">Afternoon</MenuItem>
                      <MenuItem key="Evening" value="Evening">Evening</MenuItem>
                      <MenuItem key="Night" value="Night">Night</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getAddNutritionLatitude}
                    onChange={(e) => {
                      setAddNutritionLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getAddNutritionLongitude}
                    onChange={(e) => {
                      setAddNutritionLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getAddNutritionAltitude}
                    onChange={(e) => {
                      setAddNutritionAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getAddNutritionDescription}
                      onChange={(e) => {
                        setAddNutritionDescription(e.target.value)
                      }}
                    />
                </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addNutritionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideAddNutritionPopup(!showAddNutritionPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* New Nutrition details */}

          {/* Delete Nutrition */}
            <Dialog
              fullScreen={fullScreen}
              open={showDeleteNutrition}
              aria-labelledby="Delete Nutrition"
            >
              <DialogTitle id="Delete Nutrition">
                {"Delete Nutrition"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteNutritionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideDeleteNutrition(!showDeleteNutrition)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Nutrition*/}

          {/* Update Nutrition Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateAddNutritionPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Nutrition Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateAddNutritionAssetid}
                      // onChange={(e)=>{setUpdateAddNutritionAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateAddNutritionAssetFriendlyName}
                      onChange={(e)=>{setUpdateAddNutritionAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getUpdateAddNutritionUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingFeedtype">Existing Feed type:</InputLabel>
                    <Input
                      id="ExistingFeedtype"
                      name="ExistingFeedtype"
                      type="text"
                      value={getUpdateAddNutritionExistingFeedType}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Select Feed Type:</InputLabel>
                    <Select
                      id="feedtype"
                      name="feedtype"
                      type="text"
                      value={getUpdateAddNutritionFeedType}
                      onChange={(e) => {
                        setUpdateAddNutritionFeedType(e.target.value);
                      }}
                    >
                      <MenuItem key="Milk" value="Milk">Milk</MenuItem>
                      <MenuItem key="Colstrum" value="Colstrum">Colstrum</MenuItem>
                      <MenuItem key="Rough-Age" value="Rough-Age">Rough-Age</MenuItem>
                      <MenuItem key="Concentrate" value="Concentrate">Concentrate</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Select Schedule:</InputLabel>
                    <Select
                      id="Schedule"
                      name="Schedule"
                      type="text"
                      value={getUpdateAddNutritionSchedule}
                      onChange={(e) => {
                        setUpdateAddNutritionSchedule(e.target.value);
                      }}
                    >
                      <MenuItem key="Morning" value="Morning">Morning</MenuItem>
                      <MenuItem key="Afternoon" value="Afternoon">Afternoon</MenuItem>
                      <MenuItem key="Evening" value="Evening">Evening</MenuItem>
                      <MenuItem key="Night" value="Night">Night</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateAddNutritionLatitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLatitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateAddNutritionLongitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLongitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateAddNutritionAltitude}
                    onChange={(e) => {
                      setUpdateAddNutritionAltitude(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getUpdateAddNutritionDescription}
                      onChange={(e) => {
                        setUpdateAddNutritionDescription(e.target.value)
                      }}
                    />
                </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateNutritionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideUpdateAddNutritionPopup(!showUpdateAddNutritionPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Nutrition Info */}

          {/* New Weighing Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewWeighingPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                New Weighing Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewWeighingAssetid}
                      onChange={(e) => setNewWeighingAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getNewWeighingAssetid}
                      onChange={(e)=>{setNewWeighingAssetid(e.target.value)}}
                    />
                  </FormControl> */}
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewWeighingAssetFriendlyName}
                      onChange={(e)=>{setNewWeighingAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getNewWeighingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weight">Weight At Birth:</InputLabel>
                    <Input
                      id="Weight"
                      name="Weight"
                      type="text"
                      value={getNewWeighingWeightAtBirth}
                      onChange={(e)=>{setNewWeighingWeightAtBirth(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weaning">Weaning Age:</InputLabel>
                    <Input
                      id="Weaning"
                      name="Weaning"
                      type="text"
                      value={getNewWeighingWeaningAge}
                      onChange={(e)=>{setNewWeighingWeaningAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="WeaningWeight">Weaning Weight:</InputLabel>
                    <Input
                      id="WeaningWeight"
                      name="WeaningWeight"
                      type="text"
                      value={getNewWeighingWeaningWeight}
                      onChange={(e)=>{setNewWeighingWeaningWeight(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Slaughter">Age At Slaughter:</InputLabel>
                    <Input
                      id="Slaughter"
                      name="Slaughter"
                      type="text"
                      value={getNewWeighingAgeAtSlaughter}
                      onChange={(e)=>{setNewWeighingAgeAtSlaughter(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Carcass">Carcass Weight:</InputLabel>
                    <Input
                      id="Carcass"
                      name="Carcass"
                      type="text"
                      value={getNewWeighingCarcassWeight}
                      onChange={(e)=>{setNewWeighingCarcassWeight(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Gender:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewWeighingGender}
                      onChange={(e)=>{setNewWeighingGender(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewWeighingLatitude}
                    onChange={(e) => {
                      setNewWeighingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewWeighingLongitude}
                      onChange={(e) => {
                        setNewWeighingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewWeighingAltitude}
                      onChange={(e) => {
                        setNewWeighingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewWeighingDescription}
                        onChange={(e) => {
                          setNewWeighingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Mimetype">Mimetype :</InputLabel>
                    <Input
                      id="Mimetype"
                      name="Mimetype"
                      type="text"
                      value={getNewWeighingMimetype}
                      onChange={(e) => {
                        setNewWeighingMimetype(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Width">Width :</InputLabel>
                    <Input
                      id="Width"
                      name="Width"
                      type="text"
                      value={getNewWeighingWidth}
                      onChange={(e) => {
                        setNewWeighingWidth(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Height">Height :</InputLabel>
                    <Input
                      id="Height"
                      name="Height"
                      type="text"
                      value={getNewWeighingHeight}
                      onChange={(e) => {
                        setNewWeighingHeight(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Depth">Depth :</InputLabel>
                    <Input
                      id="Depth"
                      name="Depth"
                      type="text"
                      value={getNewWeighingDepth}
                      onChange={(e) => {
                        setNewWeighingDepth(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Image">Image :</InputLabel>
                    <Input
                      id="Image"
                      name="Image"
                      accept="image/*"
                      type="file"
                      value={getNewWeighingImage}
                      onChange={(e) => {
                        setNewWeighingImage(e.target.value)
                      }}
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addWeighingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideNewWeighingPopup(!showNewWeighingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Weighing Details */}

          {/* Delete Weighing */}
            <Dialog
              fullScreen={fullScreen}
              open={showWeighingDeletePopup}
              aria-labelledby="Delete Weighing"
            >
              <DialogTitle id="Delete Weighing">
                {"Delete Weighing"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteWeighingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideWeighingDeletePopup(!showWeighingDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Weighing*/}

          {/* Update Weighing Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateNewWeighingPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Weighing Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateNewWeighingAssetid}
                      // onChange={(e)=>{setUpdateNewWeighingAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewWeighingAssetFriendlyName}
                      onChange={(e)=>{setUpdateNewWeighingAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getUpdateNewWeighingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weight">Weight At Birth:</InputLabel>
                    <Input
                      id="Weight"
                      name="Weight"
                      type="text"
                      value={getUpdateNewWeighingWeightAtBirth}
                      onChange={(e)=>{setUpdateNewWeighingWeightAtBirth(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weaning">Weaning Age:</InputLabel>
                    <Input
                      id="Weaning"
                      name="Weaning"
                      type="text"
                      value={getUpdateNewWeighingWeaningAge}
                      onChange={(e)=>{setUpdateNewWeighingWeaningAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="WeaningWeight">Weaning Weight:</InputLabel>
                    <Input
                      id="WeaningWeight"
                      name="WeaningWeight"
                      type="text"
                      value={getUpdateNewWeighingWeaningWeight}
                      onChange={(e)=>{setUpdateNewWeighingWeaningWeight(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Slaughter">Age At Slaughter:</InputLabel>
                    <Input
                      id="Slaughter"
                      name="Slaughter"
                      type="text"
                      value={getUpdateNewWeighingAgeAtSlaughter}
                      onChange={(e)=>{setUpdateNewWeighingAgeAtSlaughter(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Carcass">Carcass Weight:</InputLabel>
                    <Input
                      id="Carcass"
                      name="Carcass"
                      type="text"
                      value={getUpdateNewWeighingCarcassWeight}
                      onChange={(e)=>{setUpdateNewWeighingCarcassWeight(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Gender:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getUpdateNewWeighingGender}
                      onChange={(e)=>{setUpdateNewWeighingGender(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewWeighingLatitude}
                    onChange={(e) => {
                      setUpdateNewWeighingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewWeighingLongitude}
                      onChange={(e) => {
                        setUpdateNewWeighingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewWeighingAltitude}
                      onChange={(e) => {
                        setUpdateNewWeighingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getUpdateNewWeighingDescription}
                        onChange={(e) => {
                          setUpdateNewWeighingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Image">Image :</InputLabel>
                    <Input
                      id="Image"
                      name="Image"
                      accept="image/*"
                      type="file"
                      value={getUpdateNewWeighingImage}
                      onChange={(e) => {
                        setUpdateNewWeighingImage(e.target.value)
                      }}
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addUpdateWeighingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideUpdateNewWeighingPopup(!showUpdateNewWeighingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Weighing Info */}

          {/* New Parturition Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewParturitionPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                New Parturition Details
              </DialogTitle>
              <DialogContent>
                <div>
                  {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewParturitionAssetid}
                      onChange={(e) => setNewParturitionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getNewParturitionAssetid}
                      // onChange={(e)=>{setNewParturitionAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewParturitionAssetfriendlyName}
                      onChange={(e)=>{setNewParturitionAssetfriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getNewParturitionUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weight">Weight At First Mating:</InputLabel>
                    <Input
                      id="Weight"
                      name="Weight"
                      type="text"
                      value={getNewParturitionWeightAtFirstMating}
                      onChange={(e)=>{setNewParturitionWeightAtFirstMating(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weaning">Age At First Parturition:</InputLabel>
                    <Input
                      id="Weaning"
                      name="Weaning"
                      type="text"
                      value={getNewParturitionAgeAtFirstParturition}
                      onChange={(e)=>{setNewParturitionAgeAtFirstParturition(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="WeaningWeight">Body Conditions Index:</InputLabel>
                    <Input
                      id="WeaningWeight"
                      name="WeaningWeight"
                      type="text"
                      value={getNewParturitionBodyConditionsIndex}
                      onChange={(e)=>{setNewParturitionBodyConditionsIndex(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Slaughter">Number Of Matings:</InputLabel>
                    <Input
                      id="Slaughter"
                      name="Slaughter"
                      type="text"
                      value={getNewParturitionNumberOfMatings}
                      onChange={(e)=>{setNewParturitionNumberOfMatings(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Carcass">Supermogram:</InputLabel>
                    <Input
                      id="Carcass"
                      name="Carcass"
                      type="text"
                      value={getNewParturitionSupermogram}
                      onChange={(e)=>{setNewParturitionSupermogram(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Breed:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionBreed}
                      onChange={(e)=>{setNewParturitionBreed(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Breed Percentage:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionBreedPercentage}
                      onChange={(e)=>{setNewParturitionBreedPercentage(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Breeder Id:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionBreederId}
                      onChange={(e)=>{setNewParturitionBreederId(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Breeder Age:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionBreederAge}
                      onChange={(e)=>{setNewParturitionBreederAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Breeding Date:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="date"
                      value={getNewParturitionBreedingDate}
                      onChange={(e)=>{setNewParturitionBreedingDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Next Breeding:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="date"
                      value={getNewParturitionNextBreeding}
                      onChange={(e)=>{setNewParturitionNextBreeding(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Expected Kidding Date:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="date"
                      value={getNewParturitionExpectedKiddingDate}
                      onChange={(e)=>{setNewParturitionExpectedKiddingDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Delivered Date:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="date"
                      value={getNewParturitionDeliveredDate}
                      onChange={(e)=>{setNewParturitionDeliveredDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Total Kids Delivered:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionTotalKidsDelivered}
                      onChange={(e)=>{setNewParturitionTotalKidsDelivered(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Disposal:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewParturitionDisposal}
                      onChange={(e)=>{setNewParturitionDisposal(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewParturitionLatitude}
                    onChange={(e) => {
                      setNewParturitionLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewParturitionLongitude}
                      onChange={(e) => {
                        setNewParturitionLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewParturitionAltitude}
                      onChange={(e) => {
                        setNewParturitionAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewParturitionDescription}
                        onChange={(e) => {
                          setNewParturitionDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addParturitionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideNewParturitionPopup(!showNewParturitionPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Parturition Details */}

          {/* Delete Parturition */}
            <Dialog
              fullScreen={fullScreen}
              open={showParturitionDeletePopup}
              aria-labelledby="Delete Parturition"
            >
              <DialogTitle id="Delete Parturition">
                {"Delete Parturition"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteParturitionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideParturitionDeletePopup(!showParturitionDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Parturition*/}

          {/* Update Parturition Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateNewParturitionPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Parturition Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateNewParturitionAssetid}
                      // onChange={(e)=>{setUpdateNewParturitionAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewParturitionAssetfriendlyName}
                      onChange={(e)=>{setUpdateNewParturitionAssetfriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getUpdateNewParturitionUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Weight">Weight At First Mating:</InputLabel>
                    <Input
                      id="Weight"
                      name="Weight"
                      type="text"
                      value={getUpdateNewParturitionWeightAtFirstMating}
                      onChange={(e)=>{setUpdateNewParturitionWeightAtFirstMating(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Parturition">Age At First Parturition:</InputLabel>
                    <Input
                      id="Parturition"
                      name="Parturition"
                      type="text"
                      value={getUpdateNewParturitionAgeAtFirstParturition}
                      onChange={(e)=>{setUpdateNewParturitionAgeAtFirstParturition(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Index">Body Conditions Index:</InputLabel>
                    <Input
                      id="Index"
                      name="Index"
                      type="text"
                      value={getUpdateNewParturitionBodyConditionsIndex}
                      onChange={(e)=>{setUpdateNewParturitionBodyConditionsIndex(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="NumberOfMatings">Number Of Matings:</InputLabel>
                    <Input
                      id="NumberOfMatings"
                      name="NumberOfMatings"
                      type="text"
                      value={getUpdateNewParturitionNumberOfMatings}
                      onChange={(e)=>{setUpdateNewParturitionNumberOfMatings(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Supermogram">Supermogram:</InputLabel>
                    <Input
                      id="Supermogram"
                      name="Supermogram"
                      type="text"
                      value={getUpdateNewParturitionSupermogram}
                      onChange={(e)=>{setUpdateNewParturitionSupermogram(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Breed">Breed:</InputLabel>
                    <Input
                      id="Breed"
                      name="Breed"
                      type="text"
                      value={getUpdateNewParturitionBreed}
                      onChange={(e)=>{setUpdateNewParturitionBreed(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Percentage">Breed Percentage:</InputLabel>
                    <Input
                      id="Percentage"
                      name="Percentage"
                      type="text"
                      value={getUpdateNewParturitionBreedPercentage}
                      onChange={(e)=>{setUpdateNewParturitionBreedPercentage(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BreederId">Breeder Id:</InputLabel>
                    <Input
                      id="BreederId"
                      name="BreederId"
                      type="text"
                      value={getUpdateNewParturitionBreederId}
                      onChange={(e)=>{setUpdateNewParturitionBreederId(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BreederAge">Breeder Age:</InputLabel>
                    <Input
                      id="BreederAge"
                      name="BreederAge"
                      type="text"
                      value={getUpdateNewParturitionBreederAge}
                      onChange={(e)=>{setUpdateNewParturitionBreederAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BreedingDate">Breeding Date:</InputLabel>
                    <Input
                      id="BreedingDate"
                      name="BreedingDate"
                      type="date"
                      defaultValue={getUpdateNewParturitionBreedingDate}
                      onChange={(e)=>{setUpdateNewParturitionBreedingDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="NextBreeding">Next Breeding:</InputLabel>
                    <Input
                      id="NextBreeding"
                      name="NextBreeding"
                      type="date"
                      defaultValue={getUpdateNewParturitionNextBreeding}
                      onChange={(e)=>{setUpdateNewParturitionNextBreeding(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Expected">Expected Kidding Date:</InputLabel>
                    <Input
                      id="Expected"
                      name="Expected"
                      type="date"
                      defaultValue={getUpdateNewParturitionExpectedKiddingDate}
                      onChange={(e)=>{setUpdateNewParturitionExpectedKiddingDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="DeliveredDate">Delivered Date:</InputLabel>
                    <Input
                      id="DeliveredDate"
                      name="DeliveredDate"
                      type="date"
                      defaultValue={getUpdateNewParturitionDeliveredDate}
                      onChange={(e)=>{setUpdateNewParturitionDeliveredDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Kids">Total Kids Delivered:</InputLabel>
                    <Input
                      id="Kids"
                      name="Kids"
                      type="text"
                      value={getUpdateNewParturitionTotalKidsDelivered}
                      onChange={(e)=>{setUpdateNewParturitionTotalKidsDelivered(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Disposal">Disposal:</InputLabel>
                    <Input
                      id="Disposal"
                      name="Disposal"
                      type="text"
                      value={getUpdateNewParturitionDisposal}
                      onChange={(e)=>{setUpdateNewParturitionDisposal(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewParturitionLatitude}
                    onChange={(e) => {
                      setUpdateNewParturitionLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewParturitionLongitude}
                      onChange={(e) => {
                        setUpdateNewParturitionLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewParturitionAltitude}
                      onChange={(e) => {
                        setUpdateNewParturitionAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Comments/Description:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={getUpdateNewParturitionDescription}
                      onChange={(e) => {
                        setUpdateNewParturitionDescription(e.target.value)
                      }}
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateParturitionHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideUpdateNewParturitionPopup(!showUpdateNewParturitionPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Parturition Info */}

          {/* New Mortality Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewMortalityPopuop}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                New Mortality Details
              </DialogTitle>
              <DialogContent>
                <div>
                  {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewMortalityAssetid}
                      onChange={(e) => setNewMortalityAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getNewMortalityAssetid}
                      // onChange={(e)=>{setNewMortalityAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewMortalityAssetFriendlyName}
                      onChange={(e)=>{setNewMortalityAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getNewMortalityUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="modelid">Model id:</InputLabel>
                    <Input
                      id="modelid"
                      name="modelid"
                      type="text"
                      value={getNewMortalityModelid}
                      onChange={(e)=>{setNewMortalityModelid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BreedPercentage">Breed Percentage:</InputLabel>
                    <Input
                      id="BreedPercentage"
                      name="BreedPercentage"
                      type="text"
                      value={getNewMortalityBreedPercentage}
                      onChange={(e)=>{setNewMortalityBreedPercentage(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Gender:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getNewMortalityGender}
                      onChange={(e)=>{setNewMortalityGender(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BirthDate">Birth Date:</InputLabel>
                    <Input
                      id="BirthDate"
                      name="BirthDate"
                      type="date"
                      value={getNewMortalityBirthDate}
                      onChange={(e)=>{setNewMortalityBirthDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExpiredDate">Expired Date:</InputLabel>
                    <Input
                      id="ExpiredDate"
                      name="ExpiredDate"
                      type="date"
                      value={getNewMortalityExpiredDate}
                      onChange={(e)=>{setNewMortalityExpiredDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="TotalAge">Total Age:</InputLabel>
                    <Input
                      id="TotalAge"
                      name="TotalAge"
                      type="text"
                      value={getNewMortalityTotalAge}
                      onChange={(e)=>{setNewMortalityTotalAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="CurrentValue">Current Value:</InputLabel>
                    <Input
                      id="CurrentValue"
                      name="CurrentValue"
                      type="text"
                      value={getNewMortalityCurrentValue}
                      onChange={(e)=>{setNewMortalityCurrentValue(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="CauseOfDeath">Cause Of Death:</InputLabel>
                    <Input
                      id="CauseOfDeath"
                      name="CauseOfDeath"
                      type="text"
                      value={getNewMortalityCauseOfDeath}
                      onChange={(e)=>{setNewMortalityCauseOfDeath(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewMortalityLatitude}
                    onChange={(e) => {
                      setNewMortalityLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewMortalityLongitude}
                      onChange={(e) => {
                        setNewMortalityLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewMortalityAltitude}
                      onChange={(e) => {
                        setNewMortalityAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewMortalityDescription}
                        onChange={(e) => {
                          setNewMortalityDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addMortalityHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideNewMortalityPopuop(!showNewMortalityPopuop)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Mortality Details */}

          {/* Delete Mortality */}
            <Dialog
              fullScreen={fullScreen}
              open={showMortalityDeletePopup}
              aria-labelledby="Delete Mortality"
            >
              <DialogTitle id="Delete Mortality">
                {"Delete Mortality"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteMortalityHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideMortalityDeletePopup(!showMortalityDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Mortality*/}

          {/* Update Mortality Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateNewMortalityPopuop}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Mortality Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateNewMortalityAssetid}
                      // onChange={(e)=>{setUpdateNewMortalityAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewMortalityAssetFriendlyName}
                      onChange={(e)=>{setUpdateNewMortalityAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getUpdateNewMortalityUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="modelid">Model id:</InputLabel>
                    <Input
                      id="modelid"
                      name="modelid"
                      type="text"
                      value={getUpdateNewMortalityModelid}
                      onChange={(e)=>{setUpdateNewMortalityModelid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BreedPercentage">Breed Percentage:</InputLabel>
                    <Input
                      id="BreedPercentage"
                      name="BreedPercentage"
                      type="text"
                      value={getUpdateNewMortalityBreedPercentage}
                      onChange={(e)=>{setUpdateNewMortalityBreedPercentage(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Gender">Gender:</InputLabel>
                    <Input
                      id="Gender"
                      name="Gender"
                      type="text"
                      value={getUpdateNewMortalityGender}
                      onChange={(e)=>{setUpdateNewMortalityGender(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="BirthDate">Birth Date:</InputLabel>
                    <Input
                      id="BirthDate"
                      name="BirthDate"
                      type="date"
                      defaultValue={getUpdateNewMortalityBirthDate}
                      onChange={(e)=>{setUpdateNewMortalityBirthDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExpiredDate">Expired Date:</InputLabel>
                    <Input
                      id="ExpiredDate"
                      name="ExpiredDate"
                      type="date"
                      defaultValue={getUpdateNewMortalityExpiredDate}
                      onChange={(e)=>{setUpdateNewMortalityExpiredDate(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="TotalAge">Total Age:</InputLabel>
                    <Input
                      id="TotalAge"
                      name="TotalAge"
                      type="text"
                      value={getUpdateNewMortalityTotalAge}
                      onChange={(e)=>{setUpdateNewMortalityTotalAge(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="CurrentValue">Current Value:</InputLabel>
                    <Input
                      id="CurrentValue"
                      name="CurrentValue"
                      type="text"
                      value={getUpdateNewMortalityCurrentValue}
                      onChange={(e)=>{setUpdateNewMortalityCurrentValue(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="CauseOfDeath">Cause Of Death:</InputLabel>
                    <Input
                      id="CauseOfDeath"
                      name="CauseOfDeath"
                      type="text"
                      value={getUpdateNewMortalityCauseOfDeath}
                      onChange={(e)=>{setUpdateNewMortalityCauseOfDeath(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewMortalityLatitude}
                    onChange={(e) => {
                      setUpdateNewMortalityLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewMortalityLongitude}
                      onChange={(e) => {
                        setUpdateNewMortalityLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewMortalityAltitude}
                      onChange={(e) => {
                        setUpdateNewMortalityAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getUpdateNewMortalityDescription}
                        onChange={(e) => {
                          setUpdateNewMortalityDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateMortalityHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideUpdateNewMortalityPopuop(!showUpdateNewMortalityPopuop)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Mortality Details */}

          {/* New Asset Operational Status Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showNewAssetOperationalPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                New Asset Operational Status Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewAssetOperationalAssetid}
                      onChange={(e) => setNewAssetOperationalAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getNewAssetOperationalAssetid}
                      onChange={(e)=>{setNewAssetOperationalAssetid(e.target.value)}}
                    />
                  </FormControl> */}
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewAssetOperationalAssetFriendlyName}
                      onChange={(e)=>{setNewAssetOperationalAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getNewAssetOperationalUserid}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="status"
                    value={getNewAssetOperationalStatus}
                    onChange={(e) => setNewAssetOperationalStatus(e.target.value)}
                    label="status"
                  >
                    <MenuItem key="Ready For Onboard" value="readyforonboard">Ready For Onboard</MenuItem>
                    <MenuItem key="Deboard" value="deboard">Deboard</MenuItem>
                    <MenuItem key="Ready For Whitelabel" value="ready for whitelabel">Ready For Whitelabel</MenuItem>
                    <MenuItem key="Whitelabelled" value="whitelabelled">Whitelabelled</MenuItem>
                    <MenuItem key="In-qa" value="In-qa">In-qa</MenuItem>
                    <MenuItem key="Sold" value="sold">Sold</MenuItem>
                    <MenuItem key="Leased" value="leased">Leased</MenuItem>
                    <MenuItem key="Damaged" value="damaged">Damaged</MenuItem>
                    <MenuItem key="Lost" value="lost">Lost</MenuItem>
                    <MenuItem key="Under-Maintenance" value="under-maintenance">Under-Maintenance</MenuItem>
                  </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewAssetOperationalLatitude}
                    onChange={(e) => {
                      setNewAssetOperationalLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewAssetOperationalLongitude}
                      onChange={(e) => {
                        setNewAssetOperationalLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewAssetOperationalAltitude}
                      onChange={(e) => {
                        setNewAssetOperationalAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewAssetOperationalDescription}
                        onChange={(e) => {
                          setNewAssetOperationalDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addAssetOperationalHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideNewAssetOperationalPopup(!showNewAssetOperationalPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Asset Operational Status Details */}

          {/* Delete Asset Operational Status */}
            <Dialog
              fullScreen={fullScreen}
              open={showAssetOperationalDeletePopup}
              aria-labelledby="Delete Asset Operational Status"
            >
              <DialogTitle id="Delete Asset Operational Status">
                {"Delete Asset Operational Status"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteAssetOperationalHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    hideAssetOperationalDeletePopup(!showAssetOperationalDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Asset Operational Status*/}

          {/* Update Asset Operational Status Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateNewAssetOperationalPopup}
              aria-labelledby="updateasset"
            >
              <DialogTitle id="updateasset">
                Update Asset Operational Status Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      value={getUpdateNewAssetOperationalAssetid}
                      // onChange={(e)=>{setUpdateNewAssetOperationalAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewAssetOperationalAssetFriendlyName}
                      onChange={(e)=>{setUpdateNewAssetOperationalAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="Userid">User id:</InputLabel>
                    <Input
                      id="Userid"
                      name="Userid"
                      type="text"
                      value={getUpdateNewAssetOperationalUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingStatus">Existing Status:</InputLabel>
                    <Input
                      id="ExistingStatus"
                      name="ExistingStatus"
                      type="text"
                      value={getUpdateExistingNewAssetOperationalStatus}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="status"
                    value={getUpdateNewAssetOperationalStatus}
                    onChange={(e) => setUpdateNewAssetOperationalStatus(e.target.value)}
                    label="status"
                  >
                    <MenuItem key="Ready For Onboard" value="readyforonboard">Ready For Onboard</MenuItem>
                    <MenuItem key="Deboard" value="deboard">Deboard</MenuItem>
                    <MenuItem key="Ready For Whitelabel" value="ready for whitelabel">Ready For Whitelabel</MenuItem>
                    <MenuItem key="Whitelabelled" value="whitelabelled">Whitelabelled</MenuItem>
                    <MenuItem key="In-qa" value="In-qa">In-qa</MenuItem>
                    <MenuItem key="Sold" value="sold">Sold</MenuItem>
                    <MenuItem key="Leased" value="leased">Leased</MenuItem>
                    <MenuItem key="Damaged" value="damaged">Damaged</MenuItem>
                    <MenuItem key="Lost" value="lost">Lost</MenuItem>
                    <MenuItem key="Under-Maintenance" value="under-maintenance">Under-Maintenance</MenuItem>
                  </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewAssetOperationalLatitude}
                    onChange={(e) => {
                      setUpdateNewAssetOperationalLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewAssetOperationalLongitude}
                      onChange={(e) => {
                        setUpdateNewAssetOperationalLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewAssetOperationalAltitude}
                      onChange={(e) => {
                        setUpdateNewAssetOperationalAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getUpdateNewAssetOperationalDescription}
                        onChange={(e) => {
                          setUpdateNewAssetOperationalDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateAssetOperationalHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {hideUpdateNewAssetOperationalPopup(!showUpdateNewAssetOperationalPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Asset Operational Status Info */}

          {/* Mapped Asset Model */}
            <Dialog
              fullScreen={fullScreen}
              open={mapPopUp}
              aria-labelledby="mapasset"
            >
              <DialogTitle id="mapasset">
                MAP MODEL
              </DialogTitle>
              <DialogContent>
              <div>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site Id</InputLabel>
                  <Select
                    id="selectedModel"
                    value={mapSiteId}
                    onChange={(event) => setMapSiteId(event.target.value)}
                    label="Select Model"
                  >
                    {getAllSites &&
                      getAllSites.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.siteid}>
                          {idObj.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
                  <Input
                    disabled
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={mapAssetId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
                  <Input
                    disabled
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={mapCategoryType}
                  />
                </FormControl>
              </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addAssetToSiteMappingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {setMapPopUp(!mapPopUp)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Mapped Asset Model */}

          {/* Delete Asset */}
            <Dialog
              fullScreen={fullScreen}
              open={getShowDeletePopup}
              aria-labelledby="DeleteAsset"
            >
              <DialogTitle id="DeleteAsset">
                {"DeleteAsset"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    assetDeleteHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowDeletePopup(!getShowDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Asset */}

          {/* ASSET INFORMATION UPDATE */}
            <Dialog
              fullScreen={fullScreen}
              open={getlegacyUpdatePopup}
              aria-labelledby="assetupdate"
            >
              <DialogTitle id="assetupdate">
                ASSET INFORMATION
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
                    <Input
                      disabled
                      id="solutionid"
                      name="solutionid"
                      type="text"
                      value={username}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
                    <Input
                      disabled
                      id="assetverticalid"
                      name="assetverticalid"
                      type="text"
                      value={assetVeriticalId}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
                    <Input
                      disabled
                      id="assettype"
                      name="assettype"
                      type="text"
                      value={assetTypeVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
                    <Input
                      disabled
                      id="assetid"
                      name="assetid"
                      type="text"
                      value={assetIdVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
                    <Input
                      disabled
                      id="assetfname"
                      name="assetfname"
                      type="text"
                      value={assetFriName}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
                    <Input
                      disabled
                      id="modelid"
                      name="modelid"
                      type="text"
                      value={modelIdVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="category">CATEGORY:</InputLabel>
                    <Input
                      disabled
                      id="category"
                      name="category"
                      type="text"
                      value={categoryVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
                    <Input
                      disabled
                      id="manufacturerid"
                      name="manufacturerid"
                      type="text"
                      value={username}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="udn">UDN:</InputLabel>
                    <Input
                      disabled
                      id="udn"
                      name="udn"
                      type="text"
                      value={udnVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
                    <Input
                      disabled
                      id="whitecost"
                      name="whitecost"
                      type="text"
                      value={whiteLabelVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
                    <Input
                      disabled
                      id="sellingcost"
                      name="sellingcost"
                      type="text"
                      value={sellingCostVal}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
                    <Input
                      disabled
                      id="assetstatus"
                      name="assetstatus"
                      type="text"
                      value={exAssetStatus}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>UPDATED ASSET STATUS</InputLabel>
                    <Select
                      id="selectedModel"
                      value={updateAssetStatus}
                      onChange={(event) => setUpdateAssetStatus(event.target.value)}
                      label="Select Model"
                    >
                          <MenuItem selected>Select Any</MenuItem>
                          <MenuItem key="PrematureSale" value="PrematureSale">Premature Sale</MenuItem>
                          <MenuItem key="Lost" value="Lost">Lost</MenuItem>
                          <MenuItem key="Damaged" value="Damaged">Damaged</MenuItem>
                          <MenuItem key="UnderMaintenance" value="UnderMaintenance">Under-Maintenance</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateAssetHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {setLegacyUpdatePopup(!getlegacyUpdatePopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of ASSET INFORMATION UPDATE */}

          {/* Create Asset */}
            <Dialog
              fullScreen={fullScreen}
              open={getCreateAssetPopup}
              aria-labelledby="createasset"
            >
              <DialogTitle id="createasset">Create Asset</DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetDeveloperId">
                      AssetDeveloper ID:
                    </InputLabel>
                    <Input
                      disabled
                      id="assetDeveloperId"
                      name="assetDeveloperId"
                      type="text"
                      value={username}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
                    <Input
                      id="assetId"
                      name="assetId"
                      type="text"
                      placeholder="5533222"
                      value={assetIdValue}
                      onChange={(e) => {
                        setAssetIdValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="legacyAssetFriendlyName">
                      Legacy Asset Friendly Name :
                    </InputLabel>
                    <Input
                      id="legacyAssetFriendlyName"
                      name="legacyAssetFriendlyName"
                      placeholder="Betal Sheep"
                      type="text"
                      value={legacyAssetFriendlyNameValue}
                      onChange={(e) => {
                        setLegacyAssetFriendlyNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>AssetVerticalId:</InputLabel>
                    <Select
                      id="assetVerticalID"
                      name="assetVerticalID"
                      type="text"
                      value={selectedVerticalsId}
                      onChange={(e) => {
                        setSelectedVerticalsIdHandler(e);
                      }}
                    >
                      {verticalsIdList.length > 0 ? (
                        verticalsIdList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                            {idObj.friendlyName}
                          </MenuItem>
                        ))
                      ) : (
                        <h4>Sector not found</h4>
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                    <Select
                      id="categoryType"
                      name="categoryType"
                      type="text"
                      value={assetVerticalFriendlyNameValues}
                      onChange={(e) => {
                        setAssetVerticalFriendlyNameValue(e.target.value);
                      }}
                    >
                      {verticalsIdList &&
                        verticalsIdList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.friendlyName}>
                            {idObj.friendlyName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Category Type*</InputLabel>
                    <Select
                      id="categoryType"
                      name="categoryType"
                      type="text"
                      value={selectedCategory}
                      onChange={(e) => {
                        setSelectedCategoryHandler(e);
                      }}
                    >
                      {categoryTypesList &&
                        categoryTypesList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.categoryType}>
                            {idObj.categoryType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset Domain</InputLabel>
                    <Select
                      id="assetDomain"
                      name="assetDomain"
                      type="text"
                      value={assetDomainValue}
                      onChange={(e) => {
                        setAssetDomainValue(e.target.value);
                      }}
                    >
                      <MenuItem selected>Select</MenuItem>
                      <MenuItem key="Farm" value="Farm">
                        Farm
                      </MenuItem>
                      <MenuItem key="Field" value="Field">
                        Field
                      </MenuItem>
                      <MenuItem key="Enterprise" value="Enterprise">
                        Enterprise
                      </MenuItem>
                      <MenuItem key="Home" value="Home">
                        Home
                      </MenuItem>
                      <MenuItem key="Personal" value="Personal">
                        Personal
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Site Name*</InputLabel>
                    <Select
                      id="categoryType"
                      name="categoryType"
                      type="text"
                      value={selectedSite}
                      onChange={(e) => {
                        setSelectedSite(e.target.value);
                      }}
                    >
                      {getAllSites &&
                        getAllSites.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.siteid}>
                            {idObj.sitename}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
                    <Input
                      id="zone"
                      name="zone"
                      type="text"
                      value={zoneValue}
                      onChange={(e) => {
                        setZoneValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
                    <Input
                      id="lotName"
                      name="lotName"
                      type="text"
                      value={lotNameValue}
                      onChange={(e) => {
                        setLotNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="manufacturerId">
                      {" "}
                      Manufacturer Id *:
                    </InputLabel>
                    <Input
                      disabled
                      id="manufacturerId"
                      name="manufacturerId"
                      type="text"
                      value={manufacturerIdValue}
                      onChange={(e) => {
                        setManufacturerIdValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="manufacturerUrl">
                      {" "}
                      Manufacturer Url:
                    </InputLabel>
                    <Input
                      id="manufacturerUrl"
                      name="manufacturerUrl"
                      type="text"
                      value={manufacturerUrlValue}
                      onChange={(e) => {
                        setManufacturerUrlValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset Type</InputLabel>
                    <Select
                      id="selectedAssertTypes"
                      value={selectedAssertTypes}
                      onChange={(e) => setSelectedAssertTypesHandler(e)}
                      label="Select Type"
                    >
                      {assertTypesList &&
                        assertTypesList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.assetType}>
                            {idObj.assetType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Model</InputLabel>
                    <Select
                      id="selectedModel"
                      value={selectedModel}
                      onChange={(event) => setSelectedModel(event.target.value)}
                      label="Select Model"
                    >
                      {modelList &&
                        modelList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.modelid}>
                            {idObj.modelfriendlyname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
                    <Input
                      disabled
                      id="modelType"
                      name="modelType"
                      type="text"
                      value={selectedModel}
                      /* onChange={(e) => {
                        setModelTypeValue(e.target.value);
                      }} */
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
                    <Input
                      id="modelUrl"
                      name="modelUrl"
                      type="text"
                      value={modelUrlValue}
                      onChange={(e) => {
                        setModelUrlValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
                    <Input
                      id="serialNo"
                      name="serialNo"
                      type="text"
                      value={serialNoValue}
                      onChange={(e) => {
                        setSerialNoValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="udn"> UDN*:</InputLabel>
                    <Input
                      id="udn"
                      name="udn"
                      type="text"
                      value={udnValue}
                      onChange={(e) => {
                        setUdnValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="upc"> UPC:</InputLabel>
                    <Input
                      id="upc"
                      name="upc"
                      type="text"
                      value={upcValue}
                      onChange={(e) => {
                        setUpcValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
                    <Input
                      id="motherBreed"
                      name="motherBreed"
                      type="text"
                      value={motherBreedValue}
                      onChange={(e) => {
                        setMotherBreedValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
                    <Input
                      id="fatherBreed"
                      name="fatherBreed"
                      type="text"
                      value={fatherBreedValue}
                      onChange={(e) => {
                        setFatherBreedValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="description"> Description:</InputLabel>
                    <Input
                      id="description"
                      name="description"
                      type="text"
                      value={descriptionValue}
                      onChange={(e) => {
                        setDescriptionValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="width"> Width:</InputLabel>
                    <Input
                      id="width"
                      name="width"
                      type="text"
                      value={widthValue}
                      onChange={(e) => {
                        setWidthValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="height"> Height:</InputLabel>
                    <Input
                      id="height"
                      name="height"
                      type="text"
                      value={heightValue}
                      onChange={(e) => {
                        setHeightValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="depth"> Depth:</InputLabel>
                    <Input
                      id="depth"
                      name="depth"
                      type="text"
                      value={depthValue}
                      onChange={(e) => {
                        setDepthValue(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="whiteLabelCost">
                      {" "}
                      WhiteLabel Cost:
                    </InputLabel>
                    <Input
                      id="whiteLabelCost"
                      name="whiteLabelCost"
                      type="text"
                      value={whiteLabelCostValue}
                      onChange={(e) => {
                        setWhiteLabelCostValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
                    <Input
                      id="sellingCost"
                      name="sellingCost"
                      type="text"
                      value={sellingCostValue}
                      onChange={(e) => {
                        setSellingCostValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
                    <Input
                      id="acquireCost"
                      name="acquireCost"
                      type="text"
                      value={acquireCostValue}
                      onChange={(e) => {
                        setAcquireCostValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
                    <Input
                      id="latitude"
                      name="latitude"
                      type="text"
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude:</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={altitudeValue}
                      onChange={(e) => {
                        setAltitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    createAssetClick();
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setCreateAssetPopup(!getCreateAssetPopup);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Create Asset */}

          {/* New Selling Cost Details */}
            <Dialog
              fullScreen={fullScreen}
              open={getAddSellingCostPopup}
              aria-labelledby="sellingcost"
            >
              <DialogTitle id="sellingcost">
                New Selling Cost Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewSellingCostAssetid}
                      onChange={(e) => setNewSellingCostAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewSellingCostAssetFriendlyName}
                      onChange={(e)=>{setNewSellingCostAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
                    <Input
                      id="ExistingAssetStatus"
                      name="ExistingAssetStatus"
                      type="text"
                      value={getNewSellingCostUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="sellingcost">Selling Cost:</InputLabel>
                    <Input
                      id="sellingcost"
                      name="sellingcost"
                      type="text"
                      value={getNewSellingCostSellingCost}
                      onChange ={(e)=>{setNewSellingCostSellingCost(e.target.value)}}
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addSellingCostHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {setAddSellingCostPopup(!getAddSellingCostPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Selling Cost Details */}

          {/* New Site Patroling Details */}
            <Dialog
              fullScreen={fullScreen}
              open={showSitePatrolingPopup}
              aria-labelledby="sitepatrolling"
            >
              <DialogTitle id="sitepatrolling">
                New Site Patroling Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="siteid">Site id:</InputLabel>
                    <Input
                      id="siteid"
                      name="siteid"
                      type="text"
                      value={getNewSitePatrolingSiteid}
                      // onChange={(e)=>{setNewSitePatrolingSiteid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Site Friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewSitePatrolingFriendlyName}
                      onChange={(e)=>{setNewSitePatrolingFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="userid">User id:</InputLabel>
                    <Input
                      id="userid"
                      name="userid"
                      type="text"
                      value={getNewSitePatrolingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobilenumber">Mobile Number:</InputLabel>
                    <Input
                      id="mobilenumber"
                      name="mobilenumber"
                      type="text"
                      value={getNewSitePatrolingMobileNumber}
                      onChange={(e)=>{setNewSitePatrolingMobileNumber(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewSitePatrolingLatitude}
                    onChange={(e) => {
                      setNewSitePatrolingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewSitePatrolingLongitude}
                      onChange={(e) => {
                        setNewSitePatrolingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewSitePatrolingAltitude}
                      onChange={(e) => {
                        setNewSitePatrolingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewSitePatrolingDescription}
                        onChange={(e) => {
                          setNewSitePatrolingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addSitePatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {getShowSitePatrolingPopup(!showSitePatrolingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Site Patroling Details */}

          {/* Delete Site Patroling */}
            <Dialog
              fullScreen={fullScreen}
              open={showSitePatrolingDeletePopup}
              aria-labelledby="DeleteSitePatroling"
            >
              <DialogTitle id="DeleteSitePatroling">
                {"Delete Site Patroling"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteSitePatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    getShowSitePatrolingDeletePopup(!showSitePatrolingDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Site Patroling */}

          {/* Update Site Patrolling Info */}
            <Dialog
              fullScreen={fullScreen}
              open={showUpdateSitePatrolingPopup}
              aria-labelledby="updatesitepatrolling"
            >
              <DialogTitle id="updatesitepatrolling">
                Update Site Patrolling Info
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="siteid">Site id:</InputLabel>
                    <Input
                      id="siteid"
                      name="siteid"
                      type="text"
                      value={getUpdateNewSitePatrolingSiteid}
                      // onChange={(e)=>{setNewSitePatrolingSiteid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Site Friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewSitePatrolingFriendlyName}
                      onChange={(e)=>{setUpdateNewSitePatrolingFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="userid">User id:</InputLabel>
                    <Input
                      id="userid"
                      name="userid"
                      type="text"
                      value={getUpdateNewSitePatrolingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobilenumber">Mobile Number:</InputLabel>
                    <Input
                      id="mobilenumber"
                      name="mobilenumber"
                      type="text"
                      value={getUpdateNewSitePatrolingMobileNumber}
                      onChange={(e)=>{setUpdateNewSitePatrolingMobileNumber(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewSitePatrolingLatitude}
                    onChange={(e) => {
                      setUpdateNewSitePatrolingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewSitePatrolingLongitude}
                      onChange={(e) => {
                        setUpdateNewSitePatrolingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewSitePatrolingAltitude}
                      onChange={(e) => {
                        setUpdateNewSitePatrolingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getUpdateNewSitePatrolingDescription}
                        onChange={(e) => {
                          setUpdateNewSitePatrolingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateSitePatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {getUpdateShowSitePatrolingPopup(!showUpdateSitePatrolingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update Site Patrolling Info */}

          {/* New Asset Patroling Details */}
            <Dialog
              fullScreen={fullScreen}
              open={getShowNewAssetPatrollingPopup}
              aria-labelledby="assetpatrolling"
            >
              <DialogTitle id="assetpatrolling">
                New Asset Patroling Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="siteid">Asset id:</InputLabel>
                    <Input
                      id="siteid"
                      name="siteid"
                      type="text"
                      value={getNewAssetPatrollingAssetid}
                      // onChange={(e)=>{setNewAssetPatrollingAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset Friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getNewAssetPatrollingAssetFriendlyName}
                      onChange={(e)=>{setNewAssetPatrollingAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="userid">User id:</InputLabel>
                    <Input
                      id="userid"
                      name="userid"
                      type="text"
                      value={getNewAssetPatrollingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobilenumber">Mobile Number:</InputLabel>
                    <Input
                      id="mobilenumber"
                      name="mobilenumber"
                      type="text"
                      value={getNewAssetPatrollingMobileNumber}
                      onChange={(e)=>{setNewAssetPatrollingMobileNumber(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewAssetPatrollingLatitude}
                    onChange={(e) => {
                      setNewAssetPatrollingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getNewAssetPatrollingLongitude}
                      onChange={(e) => {
                        setNewAssetPatrollingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getNewAssetPatrollingAltitude}
                      onChange={(e) => {
                        setNewAssetPatrollingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getNewAssetPatrollingDescription}
                        onChange={(e) => {
                          setNewAssetPatrollingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addAssetPatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {setShowNewAssetPatrollingPopup(!getShowNewAssetPatrollingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of New Asset Patroling Details */}

          {/* Delete Asset Patroling */}
            <Dialog
              fullScreen={fullScreen}
              open={showAssetPatrolingDeletePopup}
              aria-labelledby="DeleteAssetPatroling"
            >
              <DialogTitle id="DeleteAssetPatroling">
                {"Delete Asset Patroling"}
              </DialogTitle>
              <DialogContent>
                Are You Sure ? 
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteAssetPatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    getShowAssetPatrolingDeletePopup(!showAssetPatrolingDeletePopup)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Delete Asset Patroling */}

          {/* Update New Asset Patroling Details */}
            <Dialog
              fullScreen={fullScreen}
              open={getUpdateShowNewAssetPatrollingPopup}
              aria-labelledby="assetpatrolling"
            >
              <DialogTitle id="assetpatrolling">
                Update Asset Patroling Details
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="siteid">Asset id:</InputLabel>
                    <Input
                      id="siteid"
                      name="siteid"
                      type="text"
                      value={getUpdateNewAssetPatrollingAssetid}
                      // onChange={(e)=>{setUpdateNewAssetPatrollingAssetid(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="friendly">Asset Friendly Name:</InputLabel>
                    <Input
                      id="friendly"
                      name="friendly"
                      type="text"
                      value={getUpdateNewAssetPatrollingAssetFriendlyName}
                      onChange={(e)=>{setUpdateNewAssetPatrollingAssetFriendlyName(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="userid">User id:</InputLabel>
                    <Input
                      id="userid"
                      name="userid"
                      type="text"
                      value={getUpdateNewAssetPatrollingUserid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobilenumber">Mobile Number:</InputLabel>
                    <Input
                      id="mobilenumber"
                      name="mobilenumber"
                      type="text"
                      value={getUpdateNewAssetPatrollingMobileNumber}
                      onChange={(e)=>{setUpdateNewAssetPatrollingMobileNumber(e.target.value)}}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewAssetPatrollingLatitude}
                    onChange={(e) => {
                      setUpdateNewAssetPatrollingLatitude(e.target.value)
                    }}
                  />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={getUpdateNewAssetPatrollingLongitude}
                      onChange={(e) => {
                        setUpdateNewAssetPatrollingLongitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                    <Input
                      id="altitude"
                      name="altitude"
                      type="text"
                      value={getUpdateNewAssetPatrollingAltitude}
                      onChange={(e) => {
                        setUpdateNewAssetPatrollingAltitude(e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="address">Comments/Description:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getUpdateNewAssetPatrollingDescription}
                        onChange={(e) => {
                          setUpdateNewAssetPatrollingDescription(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addUpdateAssetPatrolingHandler()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {setUpdateShowNewAssetPatrollingPopup(!getUpdateShowNewAssetPatrollingPopup)}}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Update New Asset Patroling Details */}

          {/* Site Feedback */}
            <Dialog
              fullScreen={fullScreen}
              open={showSiteFeedbackModel}
              aria-labelledby="sitefeedback"
            >
              <DialogTitle id="sitefeedback">
                {"Record Site Feedback"}
              </DialogTitle>
              <DialogContent>
                <div>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="siteid">Site id:</InputLabel>
                    <Input
                      id="siteid"
                      name="siteid"
                      type="text"
                      value={getSiteFeedbackSiteid}
                    />
                  </FormControl>
                  <Typography>
                  <h4 style={{width : "100%"}}>How would you rate your overall customer experience?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="customer-experience"
                      value={getSiteCustomerExperience}
                      precision={1}
                      getLabelText={getLabelCustomerExperience}
                      onChange={(event, value) => {
                        setSiteCustomerExperience(value);
                        setSiteFeedbackCustomerExperience(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerExperienceHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getSiteCustomerExperience !== null && (
                      <Box sx={{ ml: 2 }}>{labels[getSiteCustomerExperienceHover !== -1 ? getSiteCustomerExperienceHover : getSiteCustomerExperience]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_product?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="product"
                      value={getSiteProduct}
                      precision={1}
                      getLabelText={getLabelProduct}
                      onChange={(event, value) => {
                        setSiteProduct(value);
                        setSiteFeedbackProduct(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteProductHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getSiteProduct !== null && (
                      <Box sx={{ ml: 2 }}>{labels[getSiteProductHover !== -1 ? getSiteProductHover : getSiteProduct]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_customer_support?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="customer_support"
                      value={getSiteCustomerSupport}
                      precision={1}
                      getLabelText={getLabelCustomerSupport}
                      onChange={(event, value) => {
                        setSiteCustomerSupport(value);
                        setSiteFeedbackCustomerSupport(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerSupportHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getSiteCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>{labels[getSiteCustomerSupportHover !== -1 ? getSiteCustomerSupportHover : getSiteCustomerSupport]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_timeliness_of_delivery?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getSiteDelivery}
                      precision={1}
                      getLabelText={getLabelCustomerDelivery}
                      onChange={(event, value) => {
                        setSiteDelivery(value);
                        setSiteFeedbackDelivery(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteDeliveryHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getSiteDelivery !== null && (
                      <Box sx={{ ml: 2 }}>{labels[getSiteDeliveryHover !== -1 ? getSiteDeliveryHover : getSiteDelivery]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>would_you_recommend_our_product_or_service_to_others?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="serviceothers"
                      value={getSiteServiceOthers}
                      precision={1}
                      getLabelText={getLabelServiceOthers}
                      onChange={(event, value) => {
                        setSiteServiceOthers(value);
                        setSiteFeedbackServiceOthers(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteServiceOthersHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getSiteServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>{labels[getSiteServiceOthersHover !== -1 ? getSiteServiceOthersHover : getSiteServiceOthers]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="remark">Remark:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getSiteFeedbackRemark}
                        onChange={(e) => {
                          setSiteFeedbackRemark(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    siteFeedbackSubmit()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowSiteFeedbackModel(!showSiteFeedbackModel)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Site Feedback */}

          {/* Asset Feedback */}
            <Dialog
              fullScreen={fullScreen}
              open={showAssetFeedbackModel}
              aria-labelledby="assetfeedback"
            >
              <DialogTitle id="assetfeedback">
                {"Record Asset Feedback"}
              </DialogTitle>
              <DialogContent>
                <div>
                <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetdevid">Asset id:</InputLabel>
                    <Input
                      id="assetdevid"
                      name="assetdevid"
                      type="text"
                      value={getAssetFeedbackAssetDeveloperid}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="assetid">Asset id:</InputLabel>
                    <Input
                      id="assetid"
                      name="assetid"
                      type="text"
                      value={getAssetFeedbackAssetid}
                    />
                  </FormControl>
                  <Typography>
                  <h4 style={{width : "100%"}}>How would you rate your overall customer experience?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="customerexperience"
                      value={getAssetCustomerExperience}
                      precision={1}
                      getLabelText={getLabelAssetCustomerExperience}
                      onChange={(event, value) => {
                        setAssetCustomerExperience(value);
                        setAssetFeedbackCustomerExperience(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerExperienceHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getAssetCustomerExperience !== null && (
                      <Box 
                       sx={{ ml: 2 }}>{labelsAsset[getAssetCustomerExperienceHover !== -1 ? getAssetCustomerExperienceHover : getAssetCustomerExperience]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_product?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="product"
                      value={getAssetProduct}
                      precision={1}
                      getLabelText={getLabelAssetProduct}
                      onChange={(event, value) => {
                        setAssetProduct(value);
                        setAssetFeedbackProduct(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetProductHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getAssetProduct !== null && (
                      <Box sx={{ ml: 2 }}>{labelsAsset[getAssetProductHover !== -1 ? getAssetProductHover : getAssetProduct]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_customer_support?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="customersupport"
                      value={getAssetCustomerSupport}
                      precision={1}
                      getLabelText={getLabelAssetCustomerSupport}
                      onChange={(event, value) => {
                        setAssetCustomerSupport(value);
                        setAssetFeedbackCustomerSupport(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerSupportHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getAssetCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>{labelsAsset[getAssetCustomerSupportHover !== -1 ? getAssetCustomerSupportHover : getAssetCustomerSupport]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>how_satisfied_were_you_with_the_timeliness_of_delivery?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getAssetDelivery}
                      precision={1}
                      getLabelText={getLabelAssetCustomerDelivery}
                      onChange={(event, value) => {
                        setAssetDelivery(value);
                        setAssetFeedbackDelivery(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetDeliveryHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getAssetDelivery !== null && (
                      <Box sx={{ ml: 2 }}>{labelsAsset[getAssetDeliveryHover !== -1 ? getAssetDeliveryHover : getAssetDelivery]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <Typography>
                  <h4 style={{width : "100%"}}>would_you_recommend_our_product_or_service_to_others?</h4>
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name="service-others"
                      value={getAssetServiceOthers}
                      precision={1}
                      getLabelText={getLabelAssetServiceOthers}
                      onChange={(event, value) => {
                        setAssetServiceOthers(value);
                        setAssetFeedbackServiceOthers(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetServiceOthersHover(hover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {getAssetServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>{labelsAsset[getAssetServiceOthersHover !== -1 ? getAssetServiceOthersHover : getAssetServiceOthers]}</Box>
                    )}
                  </Box>
                  </Typography>
                  <FormControl fullWidth={true} className="loginFields">
                      <p htmlFor="remark">Remark:</p>
                      <TextareaAutosize
                        minRows={5}
                        style={{ minwidth: 200 }}
                        value={getAssetFeedbackRemark}
                        onChange={(e) => {
                          setAssetFeedbackRemark(e.target.value)
                        }}
                      />
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    assetFeedbackSubmit()
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowAssetFeedbackModel(!showAssetFeedbackModel)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          {/* End Of Asset Feedback */}
        </div> 
      </div>
      <CustomPopup
        closeButton={closeSiteModal}
        isOpen={getSitePopUp}
        closeModal={getSitePopUp}
        content={siteModelBody}
        title={siteAgreement}
      />
      <CustomPopup
        closeButton={closeSiteRatingModal}
        isOpen={getSiteRatePopup}
        closeModal={getSiteRatePopup}
        content={siteRateModel}
        title={siteRateAgreement}
      />
      <CustomPopup
        closeButton={closeSiteInfoModal}
        isOpen={setSiteInfoPopup}
        closeModal={setSiteInfoPopup}
        content={SiteInfoBody}
        title={siteInfoHeader}
      />
    </React.Fragment>
  );
};

