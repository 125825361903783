import React, { useCallback, useEffect, useRef, useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api';
import { Button } from '@mui/material';

const containerStyle = {
  width: '80vw',
  height: '70vh',
};

const center = {
  lat: 12.9716,
  lng: 77.5946,
};

const Map = ({ location, MarkerMap, handleMapBack }) => {
  console.log(MarkerMap, 'markupppp......bbbb');

  const mapRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const [position, setPosition] = useState({ lat: '', lng: '' });
  const [locationData, setLocationData] = useState([]);
  const [dposition, setdPosition] = useState({ lat: '', lng: '' });
  const [liveLocation, setLiveLocation] = useState();
  const [delMap, setDelMap] = useState(false);
  const [backButton, setbackButton] = useState(false);
  const onLoad = useCallback(
    (ref) => {
      setSearchBox(ref);
    },

    [locationData]
  );

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPlacesChanged = useCallback(() => {
    console.log('hello search');

    setPosition({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),

      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });
  }, [searchBox]);

  const handleBackMapDel = () => {
    setDelMap(true);
    setbackButton(true);
    handleMapBack();
  };

  return (
    <>
      {/* {!backButton && (
        <Button  onClick={handleBackMapDel}>
          <ArrowBackOutlinedIcon/>
        </Button>
      )} */}
      {console.log('adfjsfkjsdfjsdfj', MarkerMap)}
      {!delMap && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={MarkerMap ? MarkerMap[0] : center}
          center={
            MarkerMap
              ? {
                  lat: parseFloat(MarkerMap[0]?.lat),
                  lng: parseFloat(MarkerMap[0]?.lng),
                }
              : { lat: 28.679079, lng: 77.06971 }
          }
          zoom={6}
          onLoad={mapLoad}
        >
          <Polyline
            path={location}
            geodesic={true}
            options={{
              strokeColor: '#187bcd',
              strokeOpacity: 5.75,
              strokeWeight: 7,
            }}
          />

          {MarkerMap.map((item, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(item?.lat),
                lng: parseFloat(item?.lng),
                
              }}
            />
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default React.memo(Map);
