import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../../WebSite/HomeSubSection/HomeSubSection";
import "./AssetMarketService.css";

export default function AssetMarketService(props) {

  const customPropValue = props.location?.state?.customProp ;
  return (
    <>
 <HeaderComponent />
      <UpdatesBanner bannerText="Asset Market" className="asset-market-bg" />

      <div className="main-wrapper2">
        <div className="subSectionWrapper">
        { customPropValue !== "SHG"  && 
        <>
          <HomeSubSection
            className3="buy-sell-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="
        Buy and Sell Request"
            description="GBR Care offers a convenient buy and sell request service, enabling farmers to efficiently acquire and sell agricultural assets, as well as essential animal and plant health care products. Through this platform, farmers can access a wide range of quality agricultural inputs, including seeds, fertilizers, equipment, and veterinary supplies, tailored to their specific needs. By facilitating these transactions, GBR Care enhances the accessibility of crucial resources for farmers, promoting productivity and sustainability in the agricultural sector. Whether purchasing or selling, farmers can leverage GBR's platform to streamline their operations and optimize their resource management.
        "
          />

          <HomeSubSection
            className3="store-service"
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards"
            descriptionTitle="
       Store Request"
            description="GBR Care's Store Request service offers a practical solution for farmers seeking storage facilities for their agricultural produce. Farmers can conveniently request storage space through the platform or offer their own storage facilities for rent. This service streamlines the process of finding suitable storage options, ensuring the safe and efficient preservation of agricultural goods. By connecting producers with available storage resources, GBR Care contributes to minimizing post-harvest losses and optimizing supply chain management in the agricultural sector.
        "
          />
          </>}
          {customPropValue === "SHG" &&   <HomeSubSection
            className3="sell-service"
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards"
            descriptionTitle="
       Sell Request"
            description="GBR Care's Sell Request service offers farmers a convenient platform to sell their agricultural produce. Through this service, farmers can easily list their products for sale, reaching a wider market audience. GBR Care facilitates the transaction process, ensuring a smooth and efficient sale experience for both sellers and buyers. By providing a centralized marketplace for agricultural products, GBR Care contributes to improving market access and increasing profitability for farmers.
        "
          />}

          <HomeSubSection
            className3="price-discovery-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="
        Price Discovery"
            description="GBR Care's price discovery service empowers farmers by providing valuable information on the best market prices for their agricultural products. Through this service, farmers gain insights into current market trends and prices, enabling them to make informed decisions about when and where to sell their produce for optimal returns. By facilitating access to accurate pricing information, GBR Care supports farmers in maximizing their profitability and navigating the complexities of agricultural markets effectively.
        "
          />
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
