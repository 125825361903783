import React, { Fragment, memo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";

function CreateContentSubCategory({
  reLoad,
  userName,
  categoryId,
  categoryName,
  parentID,
  onOpen,
  onClose,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getSubCategoryName, setSubCategoryName] = useState("");

  const submitSubCategoryClick = () => {
    if (!getSubCategoryName) {
      NotificationManager.warning("Please enter Sub-Category Name");
      return;
    }
    let subCategoryData = {
      categoryid: categoryId,
      categoryname: categoryName,
      contentsubcategoryname: getSubCategoryName,
      parentid: parentID,
      contentsubcategorycreator: userName,
    };

    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        reLoad();
        setSubCategoryName("");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={onOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Content Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} size="medium" margin="dense">
            <InputLabel htmlFor="assetdeveloperid" variant="standard">
              Assetdeveloper Id:
            </InputLabel>
            <Input
              disabled
              variant="standard"
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={userName}
            />
          </FormControl>
          <FormControl fullWidth={true} size="medium" margin="dense">
            <InputLabel htmlFor="category" variant="standard">
              Category
            </InputLabel>
            <Input
              disabled
              variant="standard"
              id="category"
              name="category"
              type="text"
              value={categoryId}
            ></Input>
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            size="medium"
            margin="dense"
          >
            <InputLabel id="subcat">Enter Sub_Category</InputLabel>
            <Input
              variant="standard"
              id="category"
              name="category"
              type="text"
              value={getSubCategoryName}
              onChange={(e) => {
                setSubCategoryName(e.target.value);
              }}
            ></Input>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={(e) => {
              submitSubCategoryClick();
            }}
          >
            Create
          </Button>
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(CreateContentSubCategory);
