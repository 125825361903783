import { useState, useEffect } from 'react';
import MainService from '../../../../Common/MainService';
import Map from '../Map';
// import { ArrowBack } from "@material-ui/icons";
import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const BackButton = styled('button')`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #555555;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #333333;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: box-shadow 0.5s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Title = styled(Typography)`
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: aliceblue;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const DetailsButton = styled('button')`
  color: #ffffff;
  background: #4caf50;
  width: 90px;
  height: 36px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const ContentText = styled(Typography)`
  margin-bottom: 8px;
  /* border-bottom: 1px solid gray; */
`;

const Booking = ({ handleBack }) => {
  const [bookings, setBookings] = useState([]);
  const { user } = MainService.getLocalData();

  const { username, parentId } = user;
  const [getMap, setGetMap] = useState(false);
  const [bookClose, setBookClose] = useState(false);
  const [back, setBack] = useState(false);
  const [apicall, setApicall] = useState({});
  const [MarkerMap, setMarkerMap] = useState([]);
  const [center, setCenter] = useState({});

  const handleMap = (props) => {
    setGetMap(true);
    setBookClose(true);
    setBack(true);

    const testMapData = [
      { lat: props.fromLocationlat, lng: props.fromLocationlng },
      { lat: props.toLocationlat, lng: props.toLocationlng },
    ];

    setMarkerMap(testMapData);
    console.log(MarkerMap, 'markermapp.................');
  };
  const handlePrevious = () => {
    handleBack();
    setBookClose(true);
    setBack(true);
  };

  const getMyBooking = async () => {
    const data = await MainService.getMyBooking(username, parentId);
    console.log(data, 'dataaa......................');
    setBookings(data.bookings);
  };

  const handleMapBack = () => {
    setBookClose(false);
    setBack(true);
    setGetMap(!getMap);
  };

  useEffect(() => {
    getMyBooking();
  }, []);

  return (
    <>
      <div style={{ padding: '10px' }}>
        <BackButton onClick={handlePrevious}>
          <ArrowBackOutlinedIcon />
        </BackButton>

        {!bookClose && (
          <Grid container spacing={2}>
            {bookings.map((booking, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <StyledCard>
                  <CardContent>
                    <Title color='textSecondary' variant='h6'>
                      BOOKING NO. {index + 1}
                    </Title>
                    <ContentText variant='body1'>
                      <strong>Booking ID:</strong> {booking.bookingid}
                    </ContentText>
                    <ContentText variant='body1'>
                      <strong>Order ID:</strong> {booking.orderid}
                    </ContentText>
                    <ContentText variant='body1'>
                      <strong>From Location:</strong> {booking.fromLocationlat}
                      ,&nbsp;{booking.fromLocationlng}
                    </ContentText>

                    <ContentText variant='body1'>
                      <strong>To Location:</strong> {booking.toLocationlat}
                      ,&nbsp;{booking.toLocationlng}
                    </ContentText>

                    <ContentText variant='body1'>
                      <strong>Timestamp:</strong> {booking.timestamp}
                    </ContentText>
                  </CardContent>

                  <ButtonContainer>
                    <DetailsButton onClick={() => handleMap(booking)}>
                      DETAILS
                    </DetailsButton>
                  </ButtonContainer>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}

        <div>
          {getMap && (
            <Map
              location={[]}
              MarkerMap={MarkerMap}
              handleMapBack={handleMapBack}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Booking;
