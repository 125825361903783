import React, { useState, useEffect, memo, Fragment } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function ContentSubCategoryCreate({
  props,
  modelShow,
  closeModel,
  reloadComponent,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [getSubcategoryName, setSubcategoryName] = useState("");

  /* Create Sub-Category */
  const createSubCategoryHandler = () => {
    if (!getSubcategoryName) {
      NotificationManager.warning("Please enter Sub-Category Name");
      return;
    }
    let subCategoryData = {
      categoryid: props.categoryid,
      categoryname: props.categoryname,
      contentsubcategoryname: getSubcategoryName,
      parentid: props.assetdeveloperid,
      contentsubcategorycreator: username,
    };
    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        closeModel();
        reloadComponent();
        return NotificationManager.success("Created Sub-Category");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  /* EOD Create Sub-Category */

  return (
    <Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Category">Category:</InputLabel>
            <Input
              id="Category"
              name="Category"
              type="text"
              value={props.categoryname}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Creator">Category Creator:</InputLabel>
            <Input id="Creator" name="Creator" type="text" value={username} />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="subcatname">Sub-Category Name:</InputLabel>
            <Input
              id="subcatname"
              name="subcatname"
              type="text"
              value={getSubcategoryName}
              onChange={(e) => {
                setSubcategoryName(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              createSubCategoryHandler();
            }}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={closeModel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(ContentSubCategoryCreate);
