import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const SupplyListHeader = ({
  image,
  imageChangeHandler,
  DocumentDetails,
  saveButtonStatus,
  DocumentDetailsChangeHandler,
  imageFileChangeHandler,
}) => {
  const { quotationID, customerID, validUpto } = DocumentDetails;
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Logo</TableCell>
            <TableCell align='center'>Qutation ID</TableCell>
            <TableCell align='center'>Customer ID</TableCell>
            <TableCell
              align='center'
              sx={{ width: { xs: 'auto', md: '15rem' } }}
            >
              Valid Until
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell align='center'>
              <Box
                sx={{
                  height: '5rem',
                  width: '5rem',
                  border: '1px solid',
                  display: 'flex',
                  mx: 'auto',
                  color: saveButtonStatus && !image ? '#e35a5a' : '',
                  borderRadius: '5px',
                  overflow: 'hidden',
                }}
              >
                {image && (
                  <img
                    src={image}
                    style={{ width: '100%', height: '100%' }}
                    onClick={imageChangeHandler}
                  />
                )}
                {!image && (
                  <label
                    style={{
                      display: 'flex',
                      padding: '1px',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    htmlFor='upload-photo'
                  >
                    Please choose logo
                  </label>
                )}
                <input
                  id='upload-photo'
                  type='file'
                  style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    display: 'none',
                  }}
                  onChange={imageFileChangeHandler}
                />
              </Box>
            </TableCell>

            <TableCell align='center'>{quotationID}</TableCell>
            <TableCell align='center'>
              <TextField
                error={!customerID && saveButtonStatus}
                id='customerid'
                label='Please enter customer Id'
                placeholder='Please enter customer id'
                // helperText='Incorrect entry.'
                variant='standard'
                value={customerID}
                name='buyerName'
                onChange={(event) =>
                  DocumentDetailsChangeHandler('customerID', event.target.value)
                }
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                }}
              />
            </TableCell>
            <TableCell
              align='center'
              sx={{ width: { xs: 'auto', md: '15rem' } }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name='delivery_dateOfRawMaterial'
                  value={dayjs(validUpto)}
                  onChange={(newValue, event) => {
                    const formattedValue = dayjs(newValue).format('YYYY-MM-DD');

                    DocumentDetailsChangeHandler('validUpto', formattedValue);
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor:
                          saveButtonStatus && !validUpto ? '#e35a5a' : '#000',
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SupplyListHeader;
