import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import OurServices from "../../OurServices/OurServices";

export default class GBRCareLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedSlide: 0,
      phoneNumber: "",
    };
  }
  componentDidMount = () => {
    const id = localStorage.getItem("Log");
  };
  sendAppLink() {
    Toast.loading("Loading", () => {
      this.handleClose.call(this, -1);
      Toast.success("App link send to " + this.state.phoneNumber, 2000);
    });
    setTimeout(Toast.hide, 2000);
  }
  componentDidMount() {}

  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    const selectedSlide = this.state.selectedSlide;
    return (
      <>
        <HeaderComponent />
        <UpdatesBanner
          DlBtn="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.cbnapp"
          DownloadText="Download App"
          className="farmUpdateBanner"
          bannerTitle="Grow Big & Reap from your Farms"
          bannerbodycopy1={["Join GBR Care to access loan to buy inputs"]}
          // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
          href="/auth/3/signup"
          buttonText="Explore"
          clicked={()=>{
            localStorage.setItem("userType", "Farmer")
          }}
        />
        <div className="main-wrapper2">
          <ForFarmerChallenges />
        </div>

        <SolutionEcosystem
          type="AB"
          bodycopy="System and Process where farmers can have easy access for quality inputs and to make profitable sales."
        />

        {/* <div className="main-wrapper2">
          <ForFarmerBenefits />
        </div> */}
        <Container>
          
          {/* <AgriEntrepenurBenefits /> */}
          <div className="subSectionWrapper">
            <CTA
              // secondBtn= "true"
              // href3="/agri-business"
              // btnText2= "Explore Agri Business"
              // data="present"
              onClick={()=>{
                localStorage.setItem("userType", "Farmer")
              }}
              href="/auth/3/signup"
              heading="Unlock hassle-free local trading opportunities by joining community clubs tailored for farmers"
              btnText="Sign Up"
            />
          </div>
        </Container>

        <FooterComponent />
      </>
    );
  }
}
