import React, { Fragment, memo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from "@mui/material";

function DeleteContentSubCategory({
  reLoad,
  userName,
  categoryId,
  subCategoryList,
  onOpen,
  onClose,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");

  const submitSubCategoryDeleteClick = () => {
    MainService.deleteContentSubCategory(
      userName,
      subCategoryNameValue.replace(" ", "")
    )
      .then((res) => {
        reLoad();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={onOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Content Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} size="medium" margin="dense">
            <InputLabel htmlFor="assetdeveloperid" variant="standard">
              Assetdeveloper Id:
            </InputLabel>
            <Input
              disabled
              variant="standard"
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={userName}
            />
          </FormControl>
          <FormControl fullWidth={true} size="medium" margin="dense">
            <InputLabel htmlFor="category" variant="standard">
              Category
            </InputLabel>
            <Input
              disabled
              variant="standard"
              id="category"
              name="category"
              type="text"
              value={categoryId}
            ></Input>
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            size="medium"
            margin="dense"
          >
            <InputLabel id="subcat">Choose Sub_Category</InputLabel>
            <Select
              labelId="subcat"
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
              }}
              label="Select Sub-Category"
            >
              {subCategoryList &&
                subCategoryList.map((idObj, index) => (
                  <MenuItem
                    selected={true}
                    key={`${index} `}
                    value={idObj.contentsubcategoryid}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={(e) => {
              submitSubCategoryDeleteClick();
            }}
          >
            Delete
          </Button>
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(DeleteContentSubCategory);
