import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import "./App.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./App.css";
import ChallengesSection from "./ChallengesSection/ChallengesSection";
import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";
import IAATab from "./IAATab/IAATab";
import AssetInvestment from "../Investments/InvestmentSection2/AssetInvestment";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import InvestmentSection4 from "../Investments/InvestmentSection4/InvestmentSection4";
import CTA from "../CTA/CTA";
import CBNNetwork from "./CBNNetork/CBNNetwork";
import CBN from "../../images/gbrHome/CBN.gif";
import HomeSubSection from "./HomeSubSection/HomeSubSection";
import CBNBenefits from "./Benefits/CBNBenefits";
import InvestmentROIComponent from "./InvestmentROI/InvestmentROIComponent";
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";
import ForFarmerSection3 from "../ForFarmerSection3/ForFarmerSection3";
import FieldSupportServiceSection1 from "../FieldSupportService/FieldSupportServiceSection1";
import TitleDescription from "../TitleDescription/TitleDescription";
import AgriEntrepenurBenefits from "../AgriEntrepenurBenefits/AgriEntrepenurBenefits";
import FarmerFranchiseJourney from "../ForFarmerFranchise/FarmerFranchiseJourney";
import MoneyMakingAssets from "../ForFarmers/MoneyMakingAssets";
import GrowerPricingData from "../pricingTabs/GrowerPricingData";
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedSlide: 0,
      phoneNumber: "",
      customPropValue : props.customPropValue
    };
  }
 
  sendAppLink() {
    Toast.loading("Loading", () => {
      this.handleClose.call(this, -1);
      Toast.success("App link send to " + this.state.phoneNumber, 2000);
    });
    setTimeout(Toast.hide, 2000);
  }
  componentDidMount() {}

  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    return (
      <>
        <HeaderComponent />
        <UpdatesBanner
          data="storage"
          DlBtn="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
          DownloadText="Download App"
          className="agri-storage-entreprenur"
          bannerText={[
            "We transform Agri-MSME founders into digital entrepreneurs, enabling them ",
            <br className="d-lg-block d-none"></br>,
            "to monetize unutilized capacities and maximize profits" ,
            <br className="d-lg-block d-none"></br>,
            "",

          ]}
          bannerbodycopy="Increased Income | Job Creations "
          href="/profit-generating-services"
          buttonText="Explore Profit-generating Services"
        />
        <div className="main-wrapper2">
          <ChallengesSection />
        </div>

        <SolutionEcosystem type = "ASE" bodycopy=" System and Process to convert agri-storage MSMEs into Agri-storage entrepreneur to
          address their needs."/>
        <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
          titleClass ="text-start"
          classname2 = "text-start"
            classname="mb-0 mt-0 "
            title="Who are an Agri-Storage Entrepreneur"
            description={[
              'An Agri-storage entrepreneur can be a micro-small organization that can get into different levels of entrepreneurships in the agri-storage sector by leveraging  digital  Transformation in agri-storage industry.',
              // <br className="d-block pb-4"></br>,

              // "GBR Farming will provide digital technology and tools   for Agri-storage entrepreneurs to manage and operate their agri-entrepreneur and their assets.",
            ]}
          />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <Container>
        <MoneyMakingAssets customPropValue={this.state.customPropValue} />
      </Container>
        <div className="main-wrapper2">
          <ForFarmerSection3 />
        </div>
        <div className="subSectionWrapper">
        <Container>
          <FarmerFranchiseJourney />
          </Container>
        </div>
        <div className="main-wrapper2">
        <Container>
          <AgriEntrepenurBenefits />
       </Container>
          </div>
          <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <GrowerPricingData />
        </Container>
        <Container>
          {" "}
  
          <div className="subSectionWrapper">
            <CTA
              onClick={() => {
                localStorage.setItem("userType", "FF");
              }}
              href="/auth/3/signup"
              heading="Turn your small storage business into Agri-storage Entrepreneurship "
              btnText="Register Now"
            />
          </div>
        </Container>

        <FooterComponent />
      </>
    );
  }
}
