import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { NotificationManager } from 'react-notifications';
import MainService from '../../Common/MainService';
import './CBNConsumerIAMComponent.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchBar from 'material-ui-search-bar';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOnOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@mui/material';
import sha256 from 'sha256';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: '#fff',
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function CBNConsumerIAMComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consumerUsersList, setConsumerUsersList] = useState([]);
  const [consumerUserUserId, setConsumerUserUserId] = useState('');
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState('');
  const [consumerUserUserType, setConsumerUserUserType] = useState('');
  const [consumerUserUserName, setConsumerUserUserName] = useState('');
  const [consumerUserAddress, setConsumerUserAddress] = useState('');
  const [consumerUserCity, setConsumerUserCity] = useState('');
  const [consumerUserFirstName, setConsumerUserFirstName] = useState('');
  const [consumerUserLastName, setConsumerUserLastName] = useState('');
  const [consumerUserPhone, setConsumerUserPhone] = useState('');
  const [consumerUserEmail, setConsumerUserEmail] = useState('');
  const [consumerUserState, setConsumerUserState] = useState('');
  const [consumerUserNominee, setConsumerUserNominee] = useState('');
  const [consumerUserPancard, setConsumerUserPancard] = useState('');
  const [consumerUserLongitude, setConsumerUserLongitude] = useState('');
  const [consumerUserLatitude, setConsumerUserLatitude] = useState('');
  const [consumerUserMessage, setConsumerUserMessage] = useState('');
  const [allRolesList, setAllRolesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const [messageValue, setMessageValue] = React.useState('');
  const [consumerUserResetPasswordDialog, setconsumerUserResetPasswordDialog] =
    React.useState(false);
  const [usernameValue, setUsernameValue] = React.useState('');
  const [firstNameValue, setFirstNameValue] = React.useState('');
  const [lastNameValue, setLastNameValue] = React.useState('');
  const [userTypeValue, setUserTypeValue] = React.useState('');
  const [phoneNumerValue, setPhoneNumerValue] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [consumerUserDialog, setConsumerUserDialog] = React.useState(false);
  const [domainNameValue, setDomainNameValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [cityNameValue, setCityNameValue] = useState('');
  const [stateNameValue, setStateNameValue] = useState('');
  const [nomineeValue, setNomineeValue] = useState('');
  const [pancardValue, setPancardValue] = useState('');
  const [longitudeValue, setLongitudeValue] = useState('');
  const [latitudeValue, setLatitudeValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [oldPasswordValue, setOldPasswordValue] = useState('');
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState('');
  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState('');
  const [selectedConsumerEmail, setSelectedConsumerEmail] = useState('');
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState('');
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [
    consumerUserUpdateNodeStatusDialog,
    setConsumerUserUpdateNodeStatusDialog,
  ] = useState(false);
  const [consumerUserRoleDialog, setConsumerUserRoleDialog] = useState(false);
  const [nodeUserName, setNodeUserName] = useState('');
  const [nodeUserNodeId, setNodeUserNodeId] = useState('');
  const [nodeUserAccountType, setNodeUserAccountType] = useState('');
  const [nodeUserUserType, setNodeUserUserType] = useState('');
  const [nodeUserAssetType, setNodeUserAssetType] = useState('');
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState('');
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState('');
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState('');
  const [nodeUserFeedType, setNodeUserFeedType] = useState('');
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState('');
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState('');
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState('');
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState('');
  const [nodeUserBreed, setNodeUserBreed] = useState('');
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState('');
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState('');
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState('');
  const [nodeUserNodeType, setNodeUserNodeType] = useState('');
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState('');
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState('');
  const [nodeUserFirstName, setNodeUserFirstName] = useState('');
  const [nodeUserLastName, setNodeUserLastName] = useState('');
  const [nodeUserEmail, setNodeUserEmail] = useState('');
  const [nodeUserPhone, setNodeUserPhone] = useState('');
  const [nodeUserAddress, setNodeUserAddress] = useState('');
  const [nodeUserCity, setNodeUserCity] = useState('');
  const [nodeUserState, setNodeUserState] = useState('');
  const [nodeUserDistrict, setNodeUserDistrict] = useState('');
  const [nodeUserPincode, setNodeUserPincode] = useState('');
  const [nodeUserLatitude, setNodeUserLatitude] = useState('');
  const [nodeUserLongitude, setNodeUserLongitude] = useState('');
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState('');
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState('');
  const [nodeStatusValue, setNodeStatusValue] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [roleNameValue, setRoleNameValue] = useState('');
  const [roleMapDialog, setRoleMapDialog] = useState(false);
  const [consumerUserNameValue, setConsumerUserNameValue] = useState('');
  const [initialUserType, setInitialUserType] = useState('');
  const [filteredRolesList, setFilteredRolesList] = useState([]);

  const [filteredConsumerUsersList, setFilteredConsumerUsersList] = useState(
    []
  );
  const [searched, setSearched] = useState('');
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    getUserType();
    getAllConsumerUsers();
  }, [initialUserType]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log('setInitialUserType:', data);
        setInitialUserType(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useEffect(() => {
    getAllRolesList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All consumerUsers */
  const getAllConsumerUsers = () => {
    setIsLoading(true);
    MainService.getAllConsumerUsers(parentId, username)
      .then((res) => {
        console.log('initialUserType', initialUserType);
        if (initialUserType !== undefined) {
          if (initialUserType === 'AssetDeveloper') {
            if (res.length > 0) {
              const filteredUserTypes = res.filter((row) => {
                return (
                  row.usertype === 'Farmer' ||
                  row.usertype === 'Agent' ||
                  row.usertype === 'SHG' ||
                  row.usertype === 'FPO' ||
                  row.usertype === 'CS' ||
                  row.usertype === 'NGO' ||
                  row.usertype === 'Trader' ||
                  row.usertype === 'FF' ||
                  row.usertype === 'SiteOperator' ||
                  row.usertype === 'GBRStore' ||
                  row.usertype === 'Trainer' ||
                  row.usertype === 'SupplyShop' ||
                  row.usertype === 'InputRetailer'
                );
              });
              setConsumerUsersList(filteredUserTypes);
              setFilteredConsumerUsersList(filteredUserTypes);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          } else if (initialUserType === 'FOOD-PROCESSOR') {
            if (res.length > 0) {
              const filteredUserTypes = res.filter((row) => {
                return (
                  row.usertype === 'Farmer' ||
                  row.usertype === 'SHG' ||
                  row.usertype === 'FPO' ||
                  row.usertype === 'CS' ||
                  row.usertype === 'NGO' ||
                  row.usertype === 'Trader' ||
                  row.usertype === 'FF'
                );
              });
              setConsumerUsersList(filteredUserTypes);
              setFilteredConsumerUsersList(filteredUserTypes);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          } else if (initialUserType === 'BROKER')
            if (res.length > 0) {
              const filteredUserTypes = res.filter((row) => {
                return (
                  row.usertype === 'Farmer' ||
                  row.usertype === 'Trader' ||
                  row.usertype === 'SiteOperator' ||
                  row.usertype === 'Logistics-Executive' ||
                  row.usertype === 'Logistics-Manager' ||
                  row.usertype === 'Logistics-TransportUser'
                );
              });
              console.log('filteredUserTypes', filteredUserTypes);
              setConsumerUsersList(filteredUserTypes);
              setFilteredConsumerUsersList(filteredUserTypes);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All consumerUsers */
  /* view all roles  */
  const getAllRolesList = () => {
    MainService.getAllRoles(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          console.log('roless res:', res);
          setAllRolesList(res);
          setFilteredRolesList(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* end of view all roles requests */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* consumer IAM */

  const createconsumerUsersubmit = () => {
    let consumerUserData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      domain: username,
      usertype: userTypeValue,
      phone: phoneNumerValue,
      address: addressValue,
      city: cityNameValue,
      state: stateNameValue,
      nominee: nomineeValue,
      pancard: pancardValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      message: messageValue,
      email: email,
    };
    const consumerUserDataString = JSON.stringify(consumerUserData);

    if (
      usernameValue === email &&
      firstNameValue &&
      lastNameValue &&
      userTypeValue &&
      phoneNumerValue
    ) {
      MainService.createConsumerUser(
        consumerUserDataString,
        username,
        usernameValue,
        email
      )
        .then((res) => {
          getAllConsumerUsers();
          return NotificationManager.success('user created Successfully');
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error('Username and Email should be same');
    }

    setConsumerUserDialog(!consumerUserDialog);
  };

  const updateNodeStatusSubmit = () => {
    if (nodeStatusValue === '') {
      return NotificationManager.error('please select status');
    }

    MainService.updateNodeStatus(selectedNodeNameValue, nodeStatusValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
  };
  const consumerUserUpdateDialogClick = (consumerUserName) => {
    MainService.getConsumerUserInfoForAD(username, consumerUserName)
      .then((consumerUser) => {
        console.log('consumer user info:', consumerUser[0]);
        setConsumerUserUserId(consumerUser[0].userid);
        setConsumerUserUserName(consumerUser[0].username);
        setConsumerUserFirstName(consumerUser[0].firstname);
        setConsumerUserLastName(consumerUser[0].lastname);
        setConsumerUserEmail(consumerUser[0].email);
        setConsumerUserPhone(consumerUser[0].phone);
        setConsumerUserAddress(consumerUser[0].address);
        setConsumerUserCity(consumerUser[0].city);
        setConsumerUserState(consumerUser[0].state);
        setConsumerUserNominee(consumerUser[0].nominee);
        setConsumerUserPancard(consumerUser[0].pancard);
        setConsumerUserLongitude(consumerUser[0].longitude);
        setConsumerUserLatitude(consumerUser[0].latitude);
        setConsumerUserMessage(consumerUser[0].message);
        setConsumerUserUserType(consumerUser[0].usertype);
        setConsumerUserUserDomainName(consumerUser[0].domain);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getAllConsumerUsers();
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };
  const consumerUserUpdateSubmit = () => {
    if (
      !consumerUserFirstName ||
      !consumerUserLastName ||
      !consumerUserPhone ||
      !consumerUserAddress ||
      !consumerUserCity ||
      !consumerUserState ||
      !consumerUserState ||
      !consumerUserNominee ||
      !consumerUserPancard ||
      !consumerUserLongitude ||
      !consumerUserLatitude ||
      !consumerUserMessage ||
      !consumerUserEmail
    ) {
      return NotificationManager.error('Please fill all fields');
    }

    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress,
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage,
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfo(
      consumerUserUpdateDataString,
      username,
      consumerUserUserId,
      consumerUserUserName
    )
      .then((res) => {
        return NotificationManager.success('User info updated successfully');
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    console.log('consumer data for update :', consumerUserUpdateDataString);
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };
  const deleteDialogClick = (consumerUserName, consumerUserId) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedConsumerUserId(consumerUserId);

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };
  const deleteSubmitClick = () => {
    MainService.deleteConsumerUser(
      username,
      selectedConsumerUserName,
      selectedConsumerUserId
    )
      .then((res) => {
        getAllConsumerUsers();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const updateNodeStatusClick = (consumerUserName) => {
    setSelectedNodeNameValue(consumerUserName);
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
  };
  const viewUserRoleClick = (consumerUserName) => {
    setSelectedConsumerUserName(consumerUserName);
    MainService.getRole(username, consumerUserName)
      .then((res) => {
        console.log('role res:', res);
        if (res.role) {
          setRoleNameValue(res.role);
        } else {
          setRoleNameValue('');
        }
      })
      .catch((err) => {
        setRoleNameValue('');
        return NotificationManager.error(err);
      });
    setConsumerUserRoleDialog(!consumerUserRoleDialog);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const mapRoleClickForConcumerManagement = (consumerUSerName) => {
    setConsumerUserNameValue(consumerUSerName);
    setRoleMapDialog(!roleMapDialog);
  };
  const roleMapSubmitClick = () => {
    let mapRoleData = {
      parentid: username,
      username: consumerUserNameValue,
      role: roleNameValue,
    };

    MainService.mapRole(mapRoleData, username)
      .then((res) => {
        console.log('map role dta:');
        getAllRolesList();
        setRoleNameValue('');
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setRoleMapDialog(!roleMapDialog);
    setConsumerUserRoleDialog(!consumerUserRoleDialog);
  };
  const unmapRoleClickForConcumerManagement = (consumerUserName) => {
    MainService.unmapUserRole(username, consumerUserName)
      .then((res) => {
        setRoleNameValue('');
        getAllRolesList();
        return NotificationManager.success('Role Unmapped Successfully');
      })
      .catch((err) => {
        return NotificationManager.error('Role Not Unmapped');
      });

    setConsumerUserRoleDialog(!consumerUserRoleDialog);
  };

  const requestSearchForConsumerUSers = (searchedVal) => {
    const filteredRows = filteredConsumerUsersList.filter((row) => {
      return row.usertype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setConsumerUsersList(filteredRows);
  };
  const cancelSearchForConsumerUsers = () => {
    setSearched('');
    requestSearchForConsumerUSers(searched);
  };

  return (
    <>
      <div className='container' id='mainContentList'>
        <Box sx={{ width: '100%', marginBottom: 30 }}>
          <Fab
            color='primary'
            size={'large'}
            aria-label='add'
            title='Add Consumer User'
            className={classes.fab}
            onClick={() => {
              setConsumerUserDialog(!consumerUserDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <Grid>
            <div style={{ float: 'right' }}>
              <Button
                startIcon={<ListIcon />}
                size='large'
                onClick={listViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size='large'
                onClick={gridViewClick}
              ></Button>
            </div>
          </Grid>
          <br />
          <Grid container spacing={1}>
            {listView && (
              <Paper sx={{ width: '100%' }}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) =>
                    requestSearchForConsumerUSers(searchVal)
                  }
                  onCancelSearch={() => cancelSearchForConsumerUsers()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow align='center'>
                        <TableCell
                          className='headerFontSize'
                          align='center'
                          colSpan={12}
                        >
                          List of Consumer Users
                        </TableCell>
                      </TableRow>
                      <TableRow align='center'>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          User Id
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          User Name
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          User Type
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          Domain Name
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={1}
                        >
                          View
                        </TableCell>
                        <TableCell
                          className='subHeaderFontSize'
                          align='center'
                          colSpan={4}
                        >
                          Operate
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {consumerUsersList.length > 0 ? (
                        consumerUsersList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((consumerUsers, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align='center'>
                                  {consumerUsers.userid}
                                </TableCell>
                                <TableCell align='center'>
                                  {consumerUsers.username}
                                </TableCell>
                                <TableCell align='center'>
                                  {consumerUsers.usertype}
                                </TableCell>
                                <TableCell align='center'>
                                  {consumerUsers.domain}
                                </TableCell>
                                <TableCell align='center'>
                                  {consumerUsers.email}
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      viewUserRoleClick(consumerUsers.username);
                                    }}
                                  >
                                    View Role
                                  </Button>
                                </TableCell>

                                <TableCell align='center'>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      consumerUserUpdateDialogClick(
                                        consumerUsers.username
                                      );
                                    }}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      deleteDialogClick(
                                        consumerUsers.username,
                                        consumerUsers.userid
                                      );
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                                <TableCell align='center'>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                      updateNodeStatusClick(
                                        consumerUsers.username
                                      );
                                    }}
                                  >
                                    Update Node Status
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align='center'>
                          <TableCell align='center' colSpan={12}>
                            Consumer Users Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component='div'
                  count={consumerUsersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <div>
                      <SearchBar
                        value={searched}
                        onChange={(searchVal) =>
                          requestSearchForConsumerUSers(searchVal)
                        }
                        onCancelSearch={() => cancelSearchForConsumerUsers()}
                      />
                      <h4 style={{ textAlign: 'center' }}>
                        List of Consumer Users
                      </h4>
                    </div>
                    <br />
                    <div>
                      {consumerUsersList.length > 0 ? (
                        <div className='cardStyleForDashboardItems'>
                          {consumerUsersList.map((consumerUsers, index) => (
                            <div
                              key={index}
                              className='cardStyleForDashboardItemsBody'
                            >
                              <span>
                                <p>
                                  <b>User Id:</b>
                                  {consumerUsers.userid}
                                </p>
                              </span>
                              <p>
                                <b>User Name:</b>
                                {consumerUsers.username}
                              </p>
                              <p>
                                <b>User Type:</b>
                                {consumerUsers.usertype}
                              </p>
                              <p>
                                <b>Domain Name:</b>
                                {consumerUsers.domain}
                              </p>
                              <p>
                                <b>Email:</b>
                                {consumerUsers.email}
                              </p>
                              <div>
                                <h4 className='cardactiontitle'>View</h4>
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    viewUserRoleClick(consumerUsers.username);
                                  }}
                                >
                                  View Role
                                </Button>
                              </div>
                              <div>
                                <h4 className='cardactiontitle'>Operate</h4>
                                &nbsp;&nbsp;
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    consumerUserUpdateDialogClick(
                                      consumerUsers.username
                                    );
                                  }}
                                >
                                  Update
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    deleteDialogClick(
                                      consumerUsers.username,
                                      consumerUsers.userid
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                    updateNodeStatusClick(
                                      consumerUsers.username
                                    );
                                  }}
                                >
                                  Update Node Status
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4 style={{ textAlign: 'center' }}>
                          ConsumerIAM not found.
                        </h4>
                      )}
                    </div>
                  </>
                )}
              </Box>
            )}
          </Grid>
        </Box>

        {/* Node info */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserRoleDialog}
          onClose={handleClose}
          aria-labelledby='consumerUserNodeInfo'
        >
          <DialogTitle id='consumerUserNodeInfo'>View Mapped Role</DialogTitle>
          <DialogContent>
            <span>
              <p>
                <b>Role Name : </b>
                {roleNameValue}
              </p>
              {/* {roleNameValue !== "" ?( */}
              <Button
                size='small'
                variant='contained'
                onClick={() => {
                  unmapRoleClickForConcumerManagement(selectedConsumerUserName);
                }}
              >
                Unmap Role
              </Button>
              &nbsp;
              {/* ) : ( */}
              <Button
                size='small'
                variant='contained'
                onClick={() => {
                  mapRoleClickForConcumerManagement(selectedConsumerUserName);
                }}
              >
                Map Role
              </Button>
              {/* )} */}
            </span>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setConsumerUserRoleDialog(!consumerUserRoleDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Node Status */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateNodeStatusDialog}
          onClose={handleClose}
          aria-labelledby='consumerUserNodeUpdate'
        >
          <DialogTitle id='consumerUserNodeUpdate'>
            Update Node Status
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='nodeName'>Node Name :</InputLabel>
              <Input
                disabled
                id='nodeName'
                name='nodeName'
                type='text'
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='nodeName'>Existing Node Status :</InputLabel>
              <Input
                disabled
                id='nodeName'
                name='nodeName'
                type='text'
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='nodeStatus'>Update Node Status</InputLabel>
              <Select
                id='nodeStatus'
                name='nodeStatus'
                value={nodeStatusValue}
                onChange={(e) => {
                  setNodeStatusValue(e.target.value);
                }}
              >
                <MenuItem value='AssetOEM'>Authentication Pending</MenuItem>
                <MenuItem value='AssetSeller'>Authenticated</MenuItem>
                <MenuItem value='AssetDeveloper'>Approved</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                updateNodeStatusSubmit();
              }}
            >
              Update
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setConsumerUserUpdateNodeStatusDialog(
                  !consumerUserUpdateNodeStatusDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* create Consumer User */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDialog}
          onClose={handleClose}
          aria-labelledby='craete Consumer User'
        >
          <DialogTitle id='createConsumerUser'>
            {'Create Consumer User'}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='username'>Consumer User Name * :</InputLabel>
              <Input
                id='username'
                name='username'
                type='email'
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='firstName'>First Name *:</InputLabel>
              <Input
                id='firstName'
                name='firstName'
                type='text'
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='lastName'>Last Name *:</InputLabel>
              <Input
                id='lastName'
                name='lastName'
                type='text'
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='domainName'>Domain Name * :</InputLabel>
              <Input
                id='domainName'
                name='domainName'
                type='text'
                value={username}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='userType'>User Type *</InputLabel>
              <Select
                id='userType'
                name='userType'
                value={userTypeValue}
                onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }}
              >
                <MenuItem value='Farmer'>Farmer</MenuItem>
                <MenuItem value='Agent'>Agent</MenuItem>
                <MenuItem value='SHG'>SHG</MenuItem>
                <MenuItem value='FPO'>FPO</MenuItem>
                <MenuItem value='CS'>CS</MenuItem>
                <MenuItem value='NGO'>NGO</MenuItem>
                <MenuItem value='Trader'>Trader</MenuItem>
                <MenuItem value='FF'>FF</MenuItem>
                <MenuItem value='SiteOperator'>Site-Operator</MenuItem>
                <MenuItem value='GBRStore'>GBR-Store</MenuItem>
                <MenuItem value='Trainer'>Trainer</MenuItem>
                <MenuItem value='SupplyShop'>SupplyShop</MenuItem>
                <MenuItem value='InputRetailer'>InputRetailer</MenuItem>
                <MenuItem value='Logistics-TransportUser'>
                  Logistics-TransportUser
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='email'>E-Mail Id:*</InputLabel>
              <Input
                id='email'
                name='email'
                type='email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='mobileNumber'>Mobile Number *:</InputLabel>
              <Input
                id='mobileNumber'
                name='mobileNumber'
                type='text'
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <p htmlFor='address'>Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='city'>City :</InputLabel>
              <Input
                id='city'
                name='city'
                type='text'
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='state'>State :</InputLabel>
              <Input
                id='state'
                name='state'
                type='text'
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='nominee'>Nominee :</InputLabel>
              <Input
                id='nominee'
                name='nominee'
                type='text'
                value={nomineeValue}
                onChange={(e) => {
                  setNomineeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='pancard'>Pancard Number :</InputLabel>
              <Input
                id='pancard'
                name='pancard'
                type='text'
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
              <Input
                id='longitude'
                name='longitude'
                type='text'
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
              <Input
                id='latitude'
                name='latitude'
                type='text'
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <p htmlFor='messageDescription'>Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                createconsumerUsersubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* update consumer user */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateDialog}
          onClose={handleClose}
          aria-labelledby='UpdateConsumerUser'
        >
          <DialogTitle id='UpdateConsumerUser'>
            {'Update Consumer USer'}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='username'>Consumer User Name :</InputLabel>
              <Input
                disabled
                id='username'
                name='username'
                type='text'
                value={consumerUserUserName}
              />
            </FormControl>

            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='firstName'>First Name :</InputLabel>
              <Input
                id='firstName'
                name='firstName'
                type='text'
                value={consumerUserFirstName}
                onChange={(e) => {
                  setConsumerUserFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='lastName'>Last Name :</InputLabel>
              <Input
                id='lastName'
                name='lastName'
                type='text'
                value={consumerUserLastName}
                onChange={(e) => {
                  setConsumerUserLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='domainName'>Domain Name :</InputLabel>
              <Input
                disabled
                id='domainName'
                name='domainName'
                type='text'
                value={consumerUserUserDomainName}
                onChange={(e) => {
                  setConsumerUserUserDomainName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='userType'>User Type</InputLabel>
              <Select
                disabled
                id='userType'
                name='userType'
                value={consumerUserUserType}
              >
                <MenuItem value={consumerUserUserType}>
                  {consumerUserUserType}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='email'>E-Mail Id:</InputLabel>
              <Input
                id='email'
                name='email'
                type='email'
                value={consumerUserEmail}
                onChange={(e) => {
                  setConsumerUserEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='mobileNumber'>Mobile Number :</InputLabel>
              <Input
                id='mobileNumber'
                name='mobileNumber'
                type='text'
                value={consumerUserPhone}
                onChange={(e) => {
                  setConsumerUserPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <p htmlFor='address'>Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserAddress}
                onChange={(e) => {
                  setConsumerUserAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='city'>City :</InputLabel>
              <Input
                id='city'
                name='city'
                type='text'
                value={consumerUserCity}
                onChange={(e) => {
                  setConsumerUserCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='state'>State :</InputLabel>
              <Input
                id='state'
                name='state'
                type='text'
                value={consumerUserState}
                onChange={(e) => {
                  setConsumerUserState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='nominee'>Nominee :</InputLabel>
              <Input
                id='nominee'
                name='nominee'
                type='text'
                value={consumerUserNominee}
                onChange={(e) => {
                  setConsumerUserNominee(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='pancard'>Pancard Number :</InputLabel>
              <Input
                id='pancard'
                name='pancard'
                type='text'
                value={consumerUserPancard}
                onChange={(e) => {
                  setConsumerUserPancard(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='longitude'>Longitude :</InputLabel>
              <Input
                id='longitude'
                name='longitude'
                type='text'
                value={consumerUserLongitude}
                onChange={(e) => {
                  setConsumerUserLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='latitude'>Latitude :</InputLabel>
              <Input
                id='latitude'
                name='latitude'
                type='text'
                value={consumerUserLatitude}
                onChange={(e) => {
                  setConsumerUserLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <p htmlFor='messageDescription'>Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserMessage}
                onChange={(e) => {
                  setConsumerUserMessage(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                consumerUserUpdateSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDeleteDialog}
          onClose={handleClose}
          aria-labelledby='deleteConsumerUser'
        >
          <DialogTitle id='deleteConsumerUser'>
            {'Delete Consumer User'}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Map Role */}
        <Dialog
          fullScreen={fullScreen}
          open={roleMapDialog}
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby='mapRole'
        >
          <DialogTitle id='mapRole'>{'Map Role'}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='consumerUserName'>
                Consumer User Name :
              </InputLabel>

              <Input
                id='consumerUserName'
                name='consumerUserName'
                type='text'
                value={consumerUserNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className='loginFields'>
              <InputLabel htmlFor='roleName'>Role Name :</InputLabel>
              <Select
                id='roleName'
                name='roleName'
                type='text'
                value={roleNameValue}
                onChange={(e) => {
                  setRoleNameValue(e.target.value);
                }}
              >
                {allRolesList.length > 0 &&
                  allRolesList.map((roles, index) => (
                    <MenuItem key={`${index} `} value={roles.rolename}>
                      {roles.rolename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                roleMapSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setRoleMapDialog(!roleMapDialog);
                setRoleNameValue('');
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
