import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import MainPage from './MainPage/MainPageComponent';
import MainService from './Common/MainService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
/* AssetDeveloper Role */

import TrackTheOrderComponent from './AssetDeveloperRole/TrackTheOrder/TrackTheOrderComponent';
import AdvisoryComponent from './AssetDeveloperRole/Advisory/AdvisoryComponent';
import AgreementComponent from './AssetDeveloperRole/Agreement/AgreementComponent';

import BuyInputComponent from './AssetDeveloperRole/BuyInput/BuyInputComponent';
import CustomerCareComponent from './AssetDeveloperRole/CustomerCare/CustomerCareComponent';
import EventsComponent from './AssetDeveloperRole/Events/EventsComponent';
import LegacyAssetManagementComponent from './AssetDeveloperRole/LegacyAssetManagement/LegacyAssetManagementComponent';
import OperatorLegacyAssetManagementComponent from './AssetDeveloperRole/OperatorLegacyAssetManagement/OperatorLegacyAssetManagementComponent';
import PriceDiscoveryComponent from './AssetDeveloperRole/PriceDiscovery/PriceDiscoveryComponent';
import SettingsInfoComponent from './AssetDeveloperRole/SettingsInfo/SettingsInfoComponent';
import SiteManagementComponent from './AssetDeveloperRole/SiteManagement/SiteManagementComponent';
import OperatorSiteManagementComponent from './AssetDeveloperRole/OperatorSiteManagement/OperatorSiteManagementComponent';
import UploadKYCDocumentsComponent from './AssetDeveloperRole/UploadKYCDocuments/UploadKYCDocumentsComponent';
import ViewAssetDistributersComponent from './AssetDeveloperRole/ViewAssetDistributers/ViewAssetDistributersComponent';
import ViewAssetOEMComponent from './AssetDeveloperRole/ViewAssetOEM/ViewAssetOEMComponent';
import ViewBuyersComponent from './AssetDeveloperRole/ViewBuyers/ViewBuyersComponent';
import ViewCarriersComponent from './AssetDeveloperRole/ViewCarriers/ViewCarriersComponent';
import ViewClinicAdminsComponent from './AssetDeveloperRole/ViewClinicAdmins/ViewClinicAdminsComponent';
import ViewDeveloperQAsComponent from './AssetDeveloperRole/ViewDeveloperQAs/ViewDeveloperQAsComponent';
import ViewExecutivesComponent from './AssetDeveloperRole/ViewExecutives/ViewExecutivesComponent';
import ViewFarmersComponent from './AssetDeveloperRole/ViewFarmers/ViewFarmersComponent';
import GrowerFarmerComponent from './AssetDeveloperRole/GrowerFarmer/GrowerFarmerComponent';
import ViewFarmerNodesComponent from './AssetDeveloperRole/FarmerNodes/ViewFarmerNodesComponent';
import ViewFarmerSitesComponent from './AssetDeveloperRole/FarmerSites/ViewFarmerSitesComponent';
import OperatorFarmersComponent from './AssetDeveloperRole/OperatorFarmers/OperatorFarmersComponent';
import ViewOperatorsComponent from './AssetDeveloperRole/ViewOperators/ViewOperatorsComponent';
import ViewProductionManagersComponent from './AssetDeveloperRole/ViewProductionManagers/ViewProductionManagersComponent';
import ViewProductionTeamsComponent from './AssetDeveloperRole/ViewProductionTeams/ViewProductionTeamsComponent';
import SiteUpdatesComponent from './AssetDeveloperRole/SiteUpdates/SiteUpdatesComponent';
import AgentComponent from './AssetDeveloperRole/ViewAgents/ViewAgentsComponent';
import PropTypes from 'prop-types';
import SerializationComponent from './AssetDeveloperRole/Serialization/SerializationComponent';
import SerializationWithIdComponent from './AssetDeveloperRole/SerializationWithId/SerializationWithIdComponent';
import DashboardForPMComponent from './ProductionManagerRole/DashboardForProductionManager/DashboardForPMComponent';
import CompactSiteManagement from './AssetDeveloperRole/CompactSiteManagement/CompactSiteManagement';
import Report from './features/Report';

/* Asset OEM Role */

import StoreManagerDashboard from './Common/Layout/StoreManagerDashboard';

import FeedbackComponent from './AssetOEMRole/Feedback/FeedbackPageComponent';

import KYCComponent from './AssetOEMRole/KYC/KYC';

import ProfileComponent from './AssetOEMRole/Profile/ProfilePageComponent';

import UploadAgreementComponet from './AssetOEMRole/UploadAgreement/UploadAgreementPageComponent';
import WalletComponent from './AssetOEMRole/Wallet/WalletPageComponent';
import DashboardComponentForAD from './AssetDeveloperRole/Dashboard/DashboardePageComponentForAD';

/* GovtDept Role */
import ComplaintsComponent from './GovtDeptRole/Complaints/ComplaintsComponent';

/* Operator Role */
import DashboardePageComponentForOperator from './OperatorRole/Dashboard/DashboardePageComponentForOperator';
import AttendanceComponent from './OperatorRole/Attendance/AttendanceComponent';
import AboutUsComponent from './AssetOEMRole/AboutUs/AboutUsComponent';

/* Executive Role */
import AssetHealthStatusComponent from './ExecutiveRole/AssetHealthStatus/AssetHealthStatusComponent';
import DashboardComponentForExecutive from './ExecutiveRole/DashboardForExecutive/DashboardComponentForExecutive';
import AssetStatisticsComponent from './ExecutiveRole/AssetStatistics/AssetStatisticsComponent';
import ProcessHealthStatusComponent from './ExecutiveRole/ProcessHealthStatus/ProcessHealthStatusComponent';
import SettingsComponent from './ExecutiveRole/Settings/SettingsComponent';

/* PM role */
import ConsumerIAMComponent from './AssetDeveloperRole/ConsumerIAM/ConsumerIAMComponent';
import RoleManagementComponent from './AssetDeveloperRole/RoleManagement/RoleManagement';
import ResourceManagementComponent from './AssetDeveloperRole/ResourceManagement/ResourceManagementComponent';
import Market from './AssetDeveloperRole/Market/Market';
/* QA Role */
import DashboardForQAComponent from './DeveloperQARole/DashboardForQA/DashboardForQAComponent';

/* LOGISTIC MANAGER  */
import ManagerDashboard from './LogisticsManager/ManagerDashboard/ManagerDashboard';
import VehicleManagement from './LogisticsManager/Geofence/VehicleManagement/VehicleManagement';
import TripPlannerComponent from './LogisticsManager/TripPlanner/TripPlannerComponent';
import LocationManagement from './LogisticsManager/TripPlanner/Location/LocationManagement';
import AddLocation from './LogisticsManager/TripPlanner/Location/AddLocation';
import RouteManagement from './LogisticsManager/TripPlanner/Route/RouteManagement';
import AddRoute from './LogisticsManager/TripPlanner/Route/AddRoute';
import TripManagement from './LogisticsManager/TripPlanner/Trip/TripManagement';
import AddTrip from './LogisticsManager/TripPlanner/Trip/AddTrip';
import GeofenceDetails from './LogisticsManager/Geofence/geofencedetails/GeofenceDetails';
import GetBookingReq from './LogisticsManager/OrderManagement/BookingRequest/GetBookingReq';
import ViewOrders from './LogisticsManager/OrderManagement/ViewOrders';
import AddBooking from './LogisticsManager/OrderManagement/AddBooking';
import VehicleStatus from './LogisticsManager/LiveMonitoring/VehicleStatus/VehicleStatus';
import CommunityComponent from './AssetDeveloperRole/Community/CommunityComponent';
import TransportManagementComponent from './AssetDeveloperRole/TransportManagement/TransportManagementComponent';

/*TRANSPORT USER ROLE*/

import DashbordForDriverComponent from './LogisticUser/DashboardForDriver/DashbordForDriverComponent';
import OrderDetails from './LogisticUser/TransportUser/components/TripOrders/TripOrders';

/*TRANSPORT USER ROLE*/
/*Start Invoice Components */
import BuyerAccounts from './Common/Invoicecomponents/buyeraccountmanagement/BuyerAccounts';
import SellerAccounts from './Common/Invoicecomponents/selleraccountmanagement/SellerAccountManagement';
import SellerInvoiceManagement from './Common/Invoicecomponents/selleraccountmanagement/SellerInvoiceManagement';
import ViewBuyerRequest from './AssetDeveloperRole/viewBuyerRequest/ViewBuyerRequest';
import { Dashboard } from '@material-ui/icons';
import PlantManagementDashboard from './Common/Layout/PlantManagementDashboard';
import StoreOperator from './Common/Layout/StoreOperator';
import PlantOperatorDashboard from './Common/Layout/PlantOperatorDashboard';
import EHSOperatorDashboard from './Common/Layout/EHSOperatorDashboard';
import TechnicianDashboard from './Common/Layout/TechnicianDashboard';
import PlantQAQCDashboard from './Common/Layout/PlantQAQCDashboard';
import DSNExecutiveDashboard from './Common/Layout/DSNExecutiveDashboard';
/* End Invoice components */

//  VCN COMPONENTS
import VCNTraderDashboard from '../../VCN/Trader/VCNTraderDashboard';
import VCNFarmerDashboard from '../../VCN/Farmer/VCNFarmerDashboard';
import VCNStorageOperatorDash from '../../VCN/StorageOperator/VCNStorageOperatorDash';
// ACN COMPONENTS
import ACNExporterDash from '../../ACN/ACNExporter/ACNExporterDash';

export default class MainRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      _isMounted: true,
      userTypeValue: '',
    };
    this.userType = this.userType.bind(this);
  }
  componentWillUnmount() {
    this.setState({
      _isMounted: false,
    });
  }
  componentDidMount() {
    this.userType();

    if (this._isMounted === true) {
      const timer = setTimeout(() => {
        if (_.isEqual(this.state.location, this.props.location)) return;
        this.setState({ location: this.props.location });
        this.setState({
          _isMounted: false,
        });
      });
      return () => clearTimeout(timer);
    }
  }

  userType() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        this.setState({
          userTypeValue: data,
        });
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  }

  render() {
    return (
      <MainPage location={this.props.location}>
        <Switch>
          <Route
            exact
            path={this.props.match.url + '/transportManagement'}
            component={(args) => <TransportManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/community'}
            component={(args) => <CommunityComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/buyerPurchaseOrder'}
            component={(args) => <BuyerAccounts {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/sellerInvoice'}
            component={(args) => <SellerAccounts {...args} />}
          />
          {/* Farmer role */}
          <Route
            exact
            path={this.props.match.url + '/viewFarmerNodes'}
            component={(args) => <ViewFarmerNodesComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewFarmerSites'}
            component={(args) => <ViewFarmerSitesComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/profile'}
            component={(args) => <ProfileComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/feedback'}
            component={(args) => <FeedbackComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/complaints'}
            component={(args) => <ComplaintsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/growerFarmers'}
            component={(args) => <GrowerFarmerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/uploaddocuments'}
            component={(args) => <UploadAgreementComponet {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/agreement'}
            component={(args) => <AgreementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/KYC'}
            component={(args) => <KYCComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/wallet'}
            component={(args) => <WalletComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/customercare'}
            component={(args) => <CustomerCareComponent {...args} />}
          />
          {this.state.userTypeValue === 'Farmer' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/growerFarmers'} />
              )}
            />
          )}
          {/* Operator Role */}
          <Route
            exact
            path={this.props.match.url + '/settings'}
            component={(args) => <SettingsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/attendance'}
            component={(args) => <AttendanceComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/dashboardForOperator'}
            component={(args) => (
              <DashboardePageComponentForOperator {...args} />
            )}
          />
          <Route
            exact
            path={this.props.match.url + '/aboutUs'}
            component={(args) => <AboutUsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorSiteManagement'}
            component={(args) => <OperatorSiteManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorFarmers'}
            component={(args) => <OperatorFarmersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorLegacyAssetManagement'}
            component={(args) => (
              <OperatorLegacyAssetManagementComponent {...args} />
            )}
          />
          {this.state.userTypeValue === 'Operator' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForOperator'} />
              )}
            />
          )}

          {/* DeveloperQA Role */}
          <Route
            exact
            path={this.props.match.url + '/dashboardForQA'}
            component={(args) => <DashboardForQAComponent {...args} />}
          />
          {this.state.userTypeValue === 'developerQA' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForQA'} />
              )}
            />
          )}

          {/* Production-Manager Role */}
          <Route
            exact
            path={this.props.match.url + '/markets'}
            component={(args) => <Market {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/compactSiteManagement'}
            component={(args) => <CompactSiteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/resourceManagement'}
            component={(args) => <ResourceManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/serialization'}
            component={(args) => <SerializationComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/serialization'}
            component={(args) => <SerializationComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/serializationWithId'}
            component={(args) => <SerializationWithIdComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/trackTheOrder'}
            component={(args) => <TrackTheOrderComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/advisory'}
            component={(args) => <AdvisoryComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/agreement'}
            component={(args) => <AgreementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/assetStatistics'}
            component={(args) => <AssetStatisticsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/assetHealthStatus'}
            component={(args) => <AssetHealthStatusComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/processHealthStatus'}
            component={(args) => <ProcessHealthStatusComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/buyInput'}
            component={(args) => <BuyInputComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/consumerIAM'}
            component={(args) => <ConsumerIAMComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/roleProfileManagement'}
            component={(args) => <RoleManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/customercare'}
            component={(args) => <CustomerCareComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/events'}
            component={(args) => <EventsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/legacyAssetManagement'}
            component={(args) => <LegacyAssetManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorLegacyAssetManagement'}
            component={(args) => (
              <OperatorLegacyAssetManagementComponent {...args} />
            )}
          />
          <Route
            exact
            path={this.props.match.url + '/priceDiscovery'}
            component={(args) => <PriceDiscoveryComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/siteUpdates'}
            component={(args) => <SiteUpdatesComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewAgents'}
            component={(args) => <AgentComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/settingsInfo'}
            component={(args) => <SettingsInfoComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/siteManagement'}
            component={(args) => <SiteManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorSiteManagement'}
            component={(args) => <OperatorSiteManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/uploadKYCdocuments'}
            component={(args) => <UploadKYCDocumentsComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/viewAssetDistributors'}
            component={(args) => <ViewAssetDistributersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewAssetOEM'}
            component={(args) => <ViewAssetOEMComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewBuyers'}
            component={(args) => <ViewBuyersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewCarriers'}
            component={(args) => <ViewCarriersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewClinicAdmins'}
            component={(args) => <ViewClinicAdminsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewDeveloperQAs'}
            component={(args) => <ViewDeveloperQAsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewExecutives'}
            component={(args) => <ViewExecutivesComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewFarmers'}
            component={(args) => <ViewFarmersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/operatorFarmers'}
            component={(args) => <OperatorFarmersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/growerFarmers'}
            component={(args) => <GrowerFarmerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewOperators'}
            component={(args) => <ViewOperatorsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewProductionManagers'}
            component={(args) => <ViewProductionManagersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/viewProductionTeams'}
            component={(args) => <ViewProductionTeamsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/dashboardForPM'}
            component={(args) => <DashboardForPMComponent {...args} />}
          />

          {this.state.userTypeValue === 'Production-Manager' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForPM'} />
              )}
            />
          )}

          {/* FF Role */}

          {this.state.userTypeValue === 'FF' && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + '/compactSiteManagement'}
                />
              )}
            />
          )}

          {/* Executive Role */}

          <Route
            exact
            path={this.props.match.url + '/dashboardForExecutive'}
            component={(args) => <DashboardComponentForExecutive {...args} />}
          />
          {this.state.userTypeValue === 'Executive' && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + '/dashboardForExecutive'}
                />
              )}
            />
          )}

          {/* dashboard: */}
          <Route
            exact
            path={this.props.match.url + '/dashboardManager'}
            component={(args) => <ManagerDashboard {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/brokerTripPlanner'}
            component={(args) => <TripPlannerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/getLocation'}
            component={(args) => <LocationManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/addLocation'}
            component={(args) => <AddLocation {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/getRoutes'}
            component={(args) => <RouteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/addRoutes'}
            component={(args) => <AddRoute {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/getTrips'}
            component={(args) => <TripManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/addTrips'}
            component={(args) => <AddTrip {...args} />}
          />

          {/* BROKER GEOFENCING ROUTES********** */}

          <Route
            exact
            path={this.props.match.url + '/main/vehicle'}
            component={(args) => <VehicleManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/geofence/:id'}
            component={(args) => <GeofenceDetails {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/brokerLiveMonitoring'}
            component={(args) => <VehicleStatus {...args} />}
          />

          {/* BROKER OrderManagement ROUTES************** */}
          <Route
            exact
            path={this.props.match.url + '/bookings'}
            component={(args) => <ViewOrders {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/addBooking'}
            component={(args) => <AddBooking {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/getBookingReq'}
            component={(args) => <GetBookingReq {...args} />}
          />
          {this.state.userTypeValue === 'Logistics-Manager' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardManager'} />
              )}
            />
          )}

          {/* LOGISTIC-MANAGER */}

          {/* dashboard: */}
          <Route
            exact
            path={this.props.match.url + '/dashboardManager'}
            component={(args) => <ManagerDashboard {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/brokerTripPlanner'}
            component={(args) => <TripPlannerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/getLocation'}
            component={(args) => <LocationManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/addLocation'}
            component={(args) => <AddLocation {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/getRoutes'}
            component={(args) => <RouteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/addRoutes'}
            component={(args) => <AddRoute {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/getTrips'}
            component={(args) => <TripManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/addTrips'}
            component={(args) => <AddTrip {...args} />}
          />

          {/* BROKER GEOFENCING ROUTES********** */}

          <Route
            exact
            path={this.props.match.url + '/main/vehicle'}
            component={(args) => <VehicleManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/geofence/:id'}
            component={(args) => <GeofenceDetails {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/brokerLiveMonitoring'}
            component={(args) => <VehicleStatus {...args} />}
          />

          {/* BROKER OrderManagement ROUTES************** */}
          <Route
            exact
            path={this.props.match.url + '/bookingsmanagement'}
            component={(args) => <ViewOrders {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/addBooking'}
            component={(args) => <AddBooking {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + '/getBookingReq'}
            component={(args) => <GetBookingReq {...args} />}
          />
          {this.state.userTypeValue === 'ACNManager' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardManager'} />
              )}
            />
          )}

          {/* ******************* TRANSPORT USER ROLE ************* */}

          {/* <Route
            exact
            path={this.props.match.url + '/orderDetails'}
            component={(args) => <OrderDetails {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/dashboardForDriver'}
            component={(args) => <DashbordForDriverComponent {...args} />}
          />

          {this.state.userTypeValue === 'Logistics-TransportUser' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForDriver'} />
              )}
            />
          )} */}
          {/* ******************* TRANSPORT USER ROLE ************* */}
          {/* VCN */}

          <Route
            exact
            path={this.props.match.url + '/orderDetails'}
            component={(args) => <OrderDetails {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + '/dashboardForDriver'}
            component={(args) => <DashbordForDriverComponent {...args} />}
          />

          {this.state.userTypeValue === 'VCNDriver' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForDriver'} />
              )}
            />
          )}

          {this.state.userTypeValue === 'ACNDriver' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/dashboardForDriver'} />
              )}
            />
          )}
          {/*ACN Executive Role */}

          <Route
            exact
            path={this.props.match.url + '/dashboardForExecutive'}
            component={(args) => <DashboardComponentForExecutive {...args} />}
          />
          {this.state.userTypeValue === 'ACNExecutive' && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + '/dashboardForExecutive'}
                />
              )}
            />
          )}

          {this.state.userTypeValue === 'VCNExecutive' && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + '/dashboardForExecutive'}
                />
              )}
            />
          )}

          {/* START OF VCN COMPONENTS */}
          {/* TRADER */}
          <Route
            exact
            path={this.props.match.url + '/traderDashboard'}
            component={(args) => <VCNTraderDashboard />}
          />

          {this.state.userTypeValue === 'VCNTrader' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/traderDashboard'} />
              )}
            />
          )}
          {/* FARMER */}

          <Route
            exact
            path={this.props.match.url + '/farmerDashboard'}
            component={(args) => <GrowerFarmerComponent />}
          />

          {this.state.userTypeValue === 'VCNFarmer' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/farmerDashboard'} />
              )}
            />
          )}

          {/* STORAGE OPERATOR */}
          <Route
            exact
            path={this.props.match.url + '/VCNStorageOperatorDashboard'}
            component={(args) => <DashboardePageComponentForOperator />}
          />

          {this.state.userTypeValue === 'VCNStorageOperator' && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + '/VCNStorageOperatorDashboard'}
                />
              )}
            />
          )}

          {/* END OF VCN COMPONENTS */}

          {/* START OF ACN COMPONENTS */}
          <Route
            exact
            path={this.props.match.url + '/ACNExporterdashboard'}
            component={(args) => <ManagerDashboard />}
          />

          {this.state.userTypeValue === 'ACNExporter' && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + '/ACNExporterdashboard'} />
              )}
            />
          )}

          {/* DSN Executive Role */}
          <Route
            exact
            path={this.props.match.url + '/dsndashboard'}
            component={(args) => <DashboardComponentForExecutive />}
            // component={(args) => <EHSOperatorDashboard />}
          />
          <Route
            exact
            path={this.props.match.url + '/invoice'}
            component={(args) => <SellerAccounts />}
          />
          <Route
            exact
            path={this.props.match.url + '/report'}
            component={(args) => <Report />}
          />
          {this.state.userTypeValue === 'DSNExecutive' && (
            <>
              <Route
                component={(args) => (
                  <Redirect to={this.props.match.url + '/dsndashboard'} />
                )}
              />
            </>
          )}

          {/* Store manager Role */}
          <Route
            exact
            path={this.props.match.url + '/storemanagerdashboard'}
            component={(args) => <DashboardForPMComponent />}
          />
          {this.state.userTypeValue === 'StoreManager' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/storemanagerdashboard'}
                  />
                )}
              />
              <Route
                exact
                path={this.props.match.url + '/resourcemanagement'}
                component={(args) => <ResourceManagementComponent />}
              />
              <Route
                exact
                path={this.props.match.url + '/buyerrequest'}
                component={(args) => <ViewBuyerRequest />}
              />
              <Route
                exact
                path={this.props.match.url + '/invoice'}
                component={(args) => <SellerAccounts />}
              />
              <Route
                exact
                path={this.props.match.url + '/report'}
                component={(args) => <Report />}
              />
            </>
          )}

          {/* Store operator */}
          <Route
            exact
            path={this.props.match.url + '/storeoperatordashboard'}
            component={(args) => <DashboardePageComponentForOperator />}
          />
          {this.state.userTypeValue === 'StoreOperator' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/storeoperatordashboard'}
                  />
                )}
              />
            </>
          )}

          {/* Plant Executive role */}
          <Route
            exact
            path={this.props.match.url + '/plantexecutivedashboard'}
            component={(args) => <DashboardForPMComponent />}
          />

          {this.state.userTypeValue === 'PlantExecutive' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/plantexecutivedashboard'}
                  />
                )}
              />
            </>
          )}

          {/* Plant Manager Role */}
          <Route
            exact
            path={this.props.match.url + '/plantmanagerdashboard'}
            component={(args) => <DashboardForPMComponent />}
          />

          {this.state.userTypeValue === 'PlantManager' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/plantmanagerdashboard'}
                  />
                )}
              />
            </>
          )}
          {/* Plant Operator Role */}
          <Route
            exact
            path={this.props.match.url + '/dashboardplantoperator'}
            component={(args) => <DashboardePageComponentForOperator />}
          />
          {this.state.userTypeValue === 'PlantOperator' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/dashboardplantoperator'}
                  />
                )}
              />
            </>
          )}
          {/* Plant EHS operator Role */}
          <Route
            exact
            path={this.props.match.url + '/eshdashboard'}
            component={(args) => <DashboardePageComponentForOperator />}
          />
          {this.state.userTypeValue === 'EHSOperator' && (
            <>
              <Route
                component={(args) => (
                  <Redirect to={this.props.match.url + '/eshdashboard'} />
                )}
              />
            </>
          )}
          {/* Plant technician Role */}
          <Route
            exact
            path={this.props.match.url + '/techniciandashboard'}
            component={(args) => <DashboardePageComponentForOperator />}
          />
          {this.state.userTypeValue === 'Technician' && (
            <>
              <Route
                component={(args) => (
                  <Redirect
                    to={this.props.match.url + '/techniciandashboard'}
                  />
                )}
              />
            </>
          )}
          {/* Plant qaqc Role */}
          <Route
            exact
            path={this.props.match.url + '/dashboardqa'}
            component={(args) => <DashboardForQAComponent />}
          />

          {this.state.userTypeValue === 'PlantQA-QC' && (
            <>
              <Route
                component={(args) => (
                  <Redirect to={this.props.match.url + '/dashboardqa'} />
                )}
              />
            </>
          )}
        </Switch>
      </MainPage>
    );
  }
}
MainRouteComponent.propTypes = {
  function: PropTypes.func,
};
