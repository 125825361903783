import React, { useState } from 'react';
import Map from '../../googlemap/Map';
import './location.css';

import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';

const getAppConfig = () => window.AppConfigData;

const AddLocation = ({ locationHandler }) => {
  const [location, setLocation] = useState([]);
  const [street, setStreet] = useState([]);
  const [locationTag, setLocationTag] = useState('');
  const [error, setError] = useState('');

  const getToken = () => {
    try {
      let _enc = localStorage.getItem('CBN');
      let loginInfoString = atob(_enc);
      let loginInfo = JSON.parse(loginInfoString);
      return loginInfo.token;
    } catch (e) {
      return '';
    }
  };

  const addLocation = async (props) => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const datafile = {
      tagName: locationTag,
      geoLocation: {
        lat: location[0].geoLocation.lat,
        lng: location[0].geoLocation.lng,
      },
      address: {
        city: street,
      },
    };

    if (street === '') {
      alert('Please enter the location');
    } else {
      const tempData = await axios.post(
        `${getAppConfig().ApiUrlMapp.addLocationData}${
          getAppConfig().Realm
        }/${username}/location/add`,
        datafile,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            parent: parentId,
          },
        }
      );
      if (tempData.status === 200) {
        locationHandler();
      }
    }
  };

  const getLocationHandler = (props) => {
    console.log('props55555555', props[0]);
    const geo = {
      lat: props[0]?.geometry?.location?.lat(),
      lng: props[0]?.geometry?.location?.lng(),
    };
    setLocation([...location, { geoLocation: geo }]);
    setStreet(...street, props[0].formatted_address);
  };

  const locationTagChangeHandler = (e) => {
    setLocationTag(e.target.value);
  };

  const addLocationHandler = () => {
    // Validate the location tag before submitting
    if (locationTag.trim() === '') {
      setError('Please enter a location tag.');
      return;
    }
    if (typeof street !== 'string' || street.trim() === '') {
      setError('Please enter a location.');
      return;
    }
    addLocation();
  };

  return (
    <>
      {/* sx={{ height: '82vh', overflow: 'hidden' }} */}
      <Box>
        {error && (
          <span className='error' sx={{ marginTop: '0.5rem' }}>
            {error}
          </span>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            mt: 2,
            '&>*': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box sx={{ gap: '1rem' }}>
            <Typography>Location Tag</Typography>
            <TextField
              placeholder='enter a location tag'
              onChange={locationTagChangeHandler}
            />
          </Box>
          <Box>
            <Button variant='text' onClick={locationHandler}>
              Cancel
            </Button>
            <Button variant='outlined' onClick={() => addLocationHandler()}>
              Submit
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 2, display: 'flex' }}>
          <Map
            style={{ background: 'red' }}
            getLocationHandler={getLocationHandler}
            routeLocationData={location}
          />
        </Box>
      </Box>
    </>
  );
};
export default AddLocation;
