import { Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DashboardApp from "../TransportUser/components/UpcomingTrips/UpcomingTrips";
import Booking from "../TransportUser/components/AllBookings/Booking";
import React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
import MainService from "../../Common/MainService";
import { Book, Dashboard as DashboardIcon } from "@material-ui/icons";



import {
  AppCurrentVisits,
  AppWidgetSummary,
} from "../../../sections/@dashboard/app";



// const formatDate = (date) => {
//   const day = date.getDate();
//   const month = date.getMonth() + 1;
//   const year = date.getFullYear();
//   return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
// };

const useStyles = makeStyles({
  paper: {
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  clickablePaper: {
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    height: "100px",
    background: "white",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:'aliceblue',
      color: "black",
      transition: "all 0.3s ease-out",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    "& svg": {
      marginRight: "0.5rem",
    },
  },
  value: {
    fontWeight: "bold",
  },
  // new styles
  dashboardTitle: {
    marginBottom: "2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  sectionTitle: {
    marginBottom: "1rem",
    fontWeight: "bold",
  },
  // media queries
  "@media (max-width: 599px)": {
    sectionTitle: {
      fontSize: "1rem",
    },
  },
});

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export default function DashbordForDriverComponent() {
  const theme = useTheme();

  const [bookingCard, setBookingCard] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [store, setStore] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [getBooking, setGetBooking] = useState(false);
  const [addDesign, setAddDesign] = useState(false);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const classes = useStyles();

  useEffect(() => {

    
    const currentDate = new Date();
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(currentDate.getDate() + 7);

    const formattedStartDate = formatDate(currentDate);
    const formattedEndDate = formatDate(sevenDaysLater);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);


  const handleBooking = () => {
    setBookingCard(!bookingCard);
    setGetBooking(!getBooking);
  };

  const handleBack = () => {
    setBookingCard(!bookingCard);
    setGetBooking(false);

    setAddDesign(false);
  };

  const getWeeklyDriverSchedule = async () => {
    const urls = `fromDate=${startDate}T00%3A00%3A00z&toDate=${endDate}T23%3A59%3A59z&start=0&limit=100`;
    const {
      user: { username },
    } = JSON.parse(atob(localStorage.getItem("CBN")));
    const data = await MainService.getDriverSchedule(username, urls);
    console.log(data, "dataaa......................");

    setStore(data);
  };

  const getAllOrderCount = async () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const data = await MainService.getAllOrderCount(username, parentId);
    console.log(data, "dataaa......................");
    setHomeData(data.orderCount[0]);
  };

  useEffect(() => {
    getAllOrderCount();
  }, []);



  const handleDesign = () => {
    getWeeklyDriverSchedule();
    setBookingCard(true);
    setAddDesign(true);
  };
  const originalDate = startDate.split("-").reverse().join("-");
  const originalDate1 = endDate.split("-").reverse().join("-");

  return (
    <>
      <Container maxWidth="xl">
        <Grid className="main-wrapper-container" container spacing={3}>
          {!bookingCard && (
            <>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid
                    display="flex"
                    justifyContent="center"
                  
                  >
                    <Grid item>
                      <DashboardIcon fontSize="large" />
                    </Grid>
                    <Grid>
                      <Typography variant="h4">
                        Welcome to Driver Dashboard
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* APP WIDGET SUMMARY */}
              <Grid item xs={12} sm={4} >
                <AppWidgetSummary
                  title="Total Trips"
                  total={homeData.trip}
                  icon={"ic:baseline-drive-eta"}
                />
              </Grid>

              <Grid item xs={12} sm={4} >
                <AppWidgetSummary
                  title="Total Orders"
                  total={homeData.order}
                  color="info"
                  icon={"material-symbols:order-approve-outline"}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <AppWidgetSummary
                  title="Total Earning"
                  total={homeData.totalamountcollected}
                  // total="0"
                  color="warning"
                  icon={"nimbus:money"}
                />
              </Grid>

            

              <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.clickablePaper}
                  onClick={handleDesign}
                >
                  <Typography
                    variant="h6"
                    className={classes.title}
                    gutterBottom
                  >
                    UPCOMING TRIPS FOR NEXT WEEK
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.clickablePaper}
                  onClick={() => handleBooking()}
                >
                  <Typography
                    variant="h6"
                    className={classes.title}
                    gutterBottom
                  >
                    ALL BOOKINGS
                  </Typography>
                </Paper>
              </Grid>
            </>
          )}
          {bookingCard && addDesign && (
            <DashboardApp
              startDate={startDate}
              endDate={endDate}
              store={store}
              handleBack={handleBack}
            />
          )}
          {getBooking && <Booking handleBack={handleBack} />}
        </Grid>
      </Container>
    </>
  );
}
