import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import MaterialTable from "material-table";
import "./TrackTheOrderComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function TrackTheOrderComponent(props) {
  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [searched, setSearched] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredOrderList, setFilterdOrderList] = useState([]);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  //const [rows, setRows] = useState(ordersList);

  useEffect(() => {
    getAllOrdersList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Orders */
  const getAllOrdersList = () => {
    MainService.trackTheOrder(username)
      .then((res) => {
        setOrdersList(res);
        setFilterdOrderList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All Orders */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredOrderList.filter((row) => {
      return row.status.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setOrdersList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
        </div>
        <h2>Track The Order</h2>
        {listView && (
          <Paper sx={{ width: "100%" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={1}>
                      Order Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Order Type
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Solution Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Legacy Asset Type
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Model Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Asset Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Service Package
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Order Status
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      View
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersList.length > 0 ? (
                    ordersList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((orders, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="center">
                              {orders.orderid}
                            </TableCell>
                            <TableCell align="center">
                              {orders.ordertype}
                            </TableCell>
                            <TableCell align="center">{orders.id}</TableCell>
                            <TableCell align="center">
                              {orders.solutionid}
                            </TableCell>
                            <TableCell align="center">
                              {orders.legacyassettype}
                            </TableCell>
                            <TableCell align="center">
                              {orders.modelid}
                            </TableCell>
                            <TableCell align="center">
                              {orders.assetid}
                            </TableCell>

                            <TableCell align="center">
                              {orders.servicepackage}
                            </TableCell>
                            <TableCell align="center">
                              {orders.status}
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <Button size="small" variant="contained">
                                View Location
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        Orders Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={ordersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        {gridView && (
          <div className="cardStyleForDashboardItems">
            {isLoading ? (
              <div className="cardStyleForDashboardItems">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <div className="cardStyleForDashboardItems">
                  {ordersList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {ordersList.map((orders, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Order Id:</b>
                              {orders.orderid}
                            </p>
                            <p>
                              <b>Order Type:</b>
                              {orders.ordertype}
                            </p>
                          </span>
                          <p>
                            <b>Id:</b>
                            {orders.id}
                          </p>
                          <p>
                            <b>Solution Id:</b>
                            {orders.solutionid}
                          </p>
                          <p>
                            <b>Legacy Asset Type:</b>
                            {orders.legacyassettype}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {orders.modelid}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {orders.assetid}
                          </p>

                          <p>
                            <b>Service Package:</b>
                            {orders.servicepackage}
                          </p>
                          <p>
                            <b>Order Status:</b>
                            {orders.status}
                          </p>
                          <div>
                            <h4 className="cardactiontitle">View</h4>
                            <Button size="small" variant="contained">
                              View Location
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Orders not found.</h4>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
