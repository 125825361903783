import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {
    NotificationManager,
  } from "react-notifications";

// import MainService from "../../Main/Common/MainService";
import MainService from "../../Main/components/Main/Common/MainService";

import "./ForgotPasswordComponent.css"; 

export default class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }
  doLogin = (evt) => {
    evt.preventDefault();
    console.log("forgotPwd");
    const username = document.getElementById("username").value;
    console.log(username);

    this.setState({
      isLoading: true,
    });
    MainService.doResetPwd(username)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        // window.open(res.reset_password)
        return NotificationManager.success(res);
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        return NotificationManager.error(e);
      });
  };
  goToSignin = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmersignin"
    );
  };
  goToSignup = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/farmersignup"
    );
  };
  goToHome = (evt) => {
    this.props.history.push("/");
  };
  render() {
    const _code = parseInt(this.props.match.params.code);
    const _class =
      _code === 10
        ? "farmer"
        : "default";

    return (
      <Container maxWidth={false} className={"signinPageContainer " + _class}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={11}
            sm={5}
            md={4}
            lg={3}
            className="loginOuterContainer"
          >
            <div className="forgotPwdContainer">
              <form onSubmit={this.doLogin}>
                <h2>Grow Big & Reap</h2>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username">Username *</InputLabel>
                  <Input
                    id="username"
                    type="email"
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons">face</i>
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  {this.state.isLoading === false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="submit"
                      disabled={
                        (this.state.username !== "") &
                        (this.state.password !== "")
                          ? false
                          : true
                      }
                    >
                      Reset Password
                    </Button>
                  )}
                  {this.state.isLoading === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className="loginLoader" />
                    </Button>
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToSignin}
                >
                  Already have an account? Signin here
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToSignup}
                >
                  Not registered? Click to Signup
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToHome}
                  style={{ marginBottom: "15px" }}
                >
                  Back Home?
                </FormControl>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
