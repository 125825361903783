import React, { useEffect, useState } from "react";
import axios from "axios";
import Test from "../OrderStatus";
import MainService from "../../../../Common/MainService";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Paper } from "@material-ui/core";
import { Box, Modal } from "@mui/material";
import ConsciousnessChecker from "../ConsiousnessChecker/ConsciousnessChecker";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "90vh",
  },
  backButton: {
    backgroundColor: "#3ECF8E",
    color: "#FFFFFF",
    marginRight: "10px",
  },

  orderContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
  },
  orderDetails: {
    marginLeft: theme.spacing(2),
  },
  status: {
    fontWeight: "bold",
    color: "#ff5722",
    marginBottom: theme.spacing(1),
  },
  detailsButton: {
    marginLeft: "auto",
    backgroundColor: "#3ECF8E",
    color: "#ffffff",
    border: "none",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
}));

const TripOrders = ({ cardData, backHandler, routeData }) => {
  console.log(routeData.routeId, "shivam singh altiux");
  const classes = useStyles();

  const [order, setOrder] = useState([]);
  const [detail, setDetail] = useState("");
  const [pageSecond, setPageSecond] = useState(false);
  const [showNot, setShowNot] = useState(false);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState({});
  const [orderStatusArray, setOrderStatusArray] = useState([]);
  const [location, setLocation] = useState();
  const [markerPostion, setMarkerPosition] = useState([]);

  const [rute, setRute] = useState(true);

  const getDriverOrder = async (cardData) => {
    const { user } = MainService.getLocalData();

    const { username, parentId } = user;
    const data = await MainService.getDriverOrder(username, cardData);

    setOrder(data.orders);
  };

  const getMyRoute = async () => {
    const data = await MainService.getMyRoute(routeData);
    console.log("trirrrrrrrrrr", data);
    setLocation(data.geoPath);
    const markData = [data.geoPath[0], data.geoPath[data.geoPath.length - 1]];
    setMarkerPosition(...markerPostion, markData);
  };

  useEffect(() => {
    getMyRoute();
  }, [routeData]);

  const backPrevious = () => {
    setPageSecond(true);
    setShowNot(false);
  };
  const handleStand = (prop) => {
    setDetail(prop);
    // setPageSecond(true);
    setShowNot(false);
    setOpen(true);
  };

  const handleBack = () => {
    setPageSecond(false);
  };

  const orderStatusStyle = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-25%, -40%)",
    overflow: "auto",
    // height: "600px",
    borderRadius: "0.25rem",
    width: "unset",
    bgcolor: "background.paper",

    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    // p: 4,
  };

  const closeModelHandle = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDriverOrder(cardData);
  }, []);

  const orderStatusHandler = (status, orderid) => {
    console.log(status, orderid);
    const filterData = order.filter((li) => li.orderid === orderid);

    filterData[0].status = status;
  };

  const handleChange = (event) => {
    console.log(event.currentTarget.name);

    const filterData = order.filter(
      (item) => item.orderid === event.currentTarget.value
    );
    filterData[0].isChecked = !filterData[0].isChecked;
    setOrder([...order]);

    if (filterData[0].isChecked) {
      setOrderStatusArray([...orderStatusArray, event.currentTarget.name]);
    } else {
      const index = orderStatusArray.findIndex(
        (item) => item === event.currentTarget.name
      );
      if (index !== -1) {
        orderStatusArray.splice(index, 1);
        return orderStatusArray;
      }
      setOrderStatusArray(filterData);
    }
  };

  const handleRoute = () => {
    const isStatusLoaded = order.every(
      (ele) => ele.isChecked && ele.status === "loaded"
    );
    if (isStatusLoaded && order.length > 0) {
      setCheck(true);
      backPrevious();
      setRute(false);
    } else {
      console.log(
        "Not all checkboxes are checked or not all statuses are loaded."
      );
    }
  };

  return (
    <>
      {!showNot && (
        <div>
          <Button onClick={backHandler}>
            <ArrowBackOutlinedIcon />
          </Button>
        </div>
      )}

      <div style={{ marginTop: "3rem" }}>
        <h1
          style={{
            fontSize: "2rem",
            borderBottom: "1px solid gray",
            color: "#333",
            textTransform: "uppercase",
            fontWeight: "bold",
            padding: "0.5rem",
            margin: "0",
            fontFamily: "Arial, sans-serif",
          }}
        >
          {cardData}
        </h1>
      </div>
      {console.log(
        "orderorderorderorderorderorderorderorderorderorderorderorderorderorder",
        order
      )}
      {order.length === 0 && <Container maxWidth="sm" className={classes.container}>No data found.</Container>}
      <Container maxWidth="sm" className={classes.container}>
        {!pageSecond &&
          order.length > 0 &&
          order.map((ele, i) => {
            const isStatusLoaded = ele.isChecked && ele.status === "loaded";
            return (
              <>
                <Container className={classes.container}>
                  <div key={i}>
                    <Paper className={classes.orderContainer}>
                      <input
                        type="checkbox"
                        name={ele.status}
                        value={ele.orderid}
                        defaultChecked={ele.isChecked}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <div className={classes.orderDetails}>
                        <Typography>
                          <strong>Order Id:</strong> {ele.orderid}
                        </Typography>
                        <Typography className={classes.status}>
                          {" "}
                          <strong>Status:</strong>
                          {ele.status}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <strong>Timestamp:</strong>
                          {ele.timestamp}
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => handleStand(ele)}
                      >
                        Update
                      </Button>
                    </Paper>
                    {rute && (
                      <Button
                        variant="outlined"
                        onClick={handleRoute}
                        disabled={!isStatusLoaded}
                      >
                        Get Routes
                      </Button>
                    )}
                  </div>
                </Container>
              </>
            );
          })}

        <Modal
          open={open}
          onClose={() => closeModelHandle()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={orderStatusStyle}>
            <div className={classes.closeBtn}>
              <Button
                // style={{ color: "#1976d2" }}
                onClick={() => closeModelHandle()}
              >
                <CloseOutlinedIcon />
              </Button>
            </div>

            {
              <Test
                detail={detail}
                backPrevious={backPrevious}
                orderStatusHandler={orderStatusHandler}
                closeModelHandle={closeModelHandle}
              />
            }
          </Box>
        </Modal>
        {check && orderStatusArray.length > 0 && (
          <ConsciousnessChecker
            location={location}
            markerPostion={markerPostion}
          />
        )}
      </Container>
    </>
  );
};

export default TripOrders;
