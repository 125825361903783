import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Agri-Transporter_Needs_29-12-2023-removebg-preview.png";

import Monetize from "../../images/Techonological adoption.svg";
import Order from "../../images/Transport demand.svg";

const ForAgriTransporterChallenges = () => {
    const challanges =[{
        title:"Technology Adoption",
        description:"The cost of adopting and integrating modern technologies, such as GPS tracking, logistics software, and fleet management systems, may be prohibitive for small companies. This can impact their ability to optimize routes, improve efficiency, and provide real-time visibility into operations.  ",
        image:Monetize
      },
    
 
      {
        title:"Demand",
        description:"Agricultural transport demand is often seasonal, aligning with planting and harvesting cycles. Small companies may face the challenge of managing cash flow and maintaining profitability during periods of lower demand.",
        image:Order
      },
    //   {
    //     title:"Market Access",
    //     description:"Farmers face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
    //     image:Ecosystem
    //   },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers farmers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by farmers.",
    //     image:Technology
    //   }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Agri-Transporter Needs
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default ForAgriTransporterChallenges
