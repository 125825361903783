import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import "./ViewFarmerSitesComponent.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@mui/material/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ViewFarmerSitesComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [setListOpe, getListOpe] = useState(false);
  const [setGridOpe, getGridOpe] = useState(true);
  const [viewNode, setNode] = useState(false);
  const [viewGridNode, setGridNode] = useState(false);
  const [viewMarketPrice, setViewMarketPrice] = useState(false);
  const [viewGridMarketPrice, setViewGridMarketPrice] = useState(false);
  const [viewGridMarketOwners, setViewGridMarketOwners] = useState(false);
  const [viewListMarketOwners, setViewListMarketOwners] = useState(false);
  const [viewGrow, setGrow] = useState(false);
  const [viewGridGrow, setGridGrow] = useState(false);
  const [viewSell, setSell] = useState(false);
  const [viewGridSell, setGridSell] = useState(false);
  const [viewLoan, setLoan] = useState(false);
  const [viewGridLoan, setGridLoan] = useState(false);
  const [viewVetVisit, setVetVisit] = useState(false);
  const [viewGidVetVisit, setGridVetVisit] = useState(false);
  const [viewBuyInput, setBuyInput] = useState(false);
  const [viewGridBuyInput, setGridBuyInput] = useState(false);
  const [viewStore, setStore] = useState(false);
  const [viewGridStore, setGridStore] = useState(false);
  const [viewAdvisory, setAdvisory] = useState(false);
  const [viewGridAdvisory, setGridAdvisory] = useState(false);
  const [viewGridAdvisoryInfo, setViewGridAdvisoryInfo] = useState(false);
  const [viewListAdvisoryInfo, setViewListAdvisoryInfo] = useState(false);
  const [farmerSitesList , setFarmerSitesList] = useState([]);
  const [setViewMarketPriceReq, getViewMarketPrice] = useState([]);
  const [marketOwnersList, setMarketOwnersList] = useState([]);
  const [parentIdOfPM, setParentIdOfPM] = useState("");
  const [setGrowReq, getGrowReq] = useState([]);
  const [setSellReq, getSellReq] = useState([]);
  const [setLoanReq, getLoanReq] = useState([]);
  const [setVetVisitReq, getVetVisitReq] = useState([]);
  const [setBuyInputReq, getBuyInputReq] = useState([]);
  const [setStoreReq, getStoreReq] = useState([]);
  const [setAdvisoryReq, getAdvisoryReq] = useState([]);
  const [setNodeReq, getNodeReq] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [viewNodeUpdate, setNodeUpdate] = useState(false);
  const [viewNodeInfo, setNodeInfo] = useState(false);
  const [setNodeName, getNodeName] = useState("");
  const [setNodeStatus, getNodeStatus] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [setDefaultNodeName, getDefaultNodeName] = useState("");
  /* Site Info */
  const _ = require("lodash");
  const [siteInfoObj, setSiteInfoObj] = useState({});
  const [nodeInfoObj, setNodeInfoObj] = useState({});
  const [isObjectEmpty, setIsObjectEmpty] = useState(false);
  const [infoSiteId, getInfoSiteId] = useState("");
  const [setSiteInfoPopup, getSiteInfoPopUp] = useState(false);
  const [getNodeInfoPopup, setNodeInfoPopUp] = useState(false);
  /* Site update */
  const [roleName, setRoleName] = useState("");
  const [getSiteInfoName, setSiteInfoName] = useState("");
  const [getPhoneNumber, setPhoneNumber] = useState("");
  const [getSiteInfoExistingSiteType, setSiteInfoExistingSiteType] =
    useState("");
  const [getUpdateSiteType, setUpdateSiteType] = useState("");
  const [getWebsiteUrl, setWebsiteUrl] = useState("");
  const [getLocAddress, setLocAddress] = useState("");
  const [getSiteInfoAssetVerticalId, setSiteInfoAssetVerticalId] = useState("");
  const [getIndustryType, setIndustryType] = useState("");
  const [getStreetName, setStreetName] = useState("");
  const [getStreetNumber, setStreetNumber] = useState("");
  const [getLocality, setLocality] = useState("");
  const [getArea, setArea] = useState("");
  const [getRegion, setRegion] = useState("");
  const [getCountry, setCountry] = useState("");
  const [getPostalCode, setPostalCode] = useState("");
  const [getLatitude, setLatitude] = useState("");
  const [getLongitude, setLongitude] = useState("");
  const [getAltitude, setAltitude] = useState("");
  const [getTimeZone, setTimeZone] = useState("");
  const [getCityCode, setCityCode] = useState("");
  const [getCityName, setCityName] = useState("");
  const [getCountryCode, setCountryCode] = useState("");
  const [getStdCode, setStdCode] = useState("");
  const [getSiteUpdatePopup, setSiteUpdatePopup] = useState(false);

  /* End Of Site Update */
  /* Node Info Data */
  const [setInfoEnterpriceId, getInfoEnterpriceId] = useState("");
  const [setInfoUserName, getInfoUserName] = useState("");
  const [setInfoFname, getInfoFname] = useState("");
  const [setInfoLname, getInfoLname] = useState("");
  const [setInfoUserType, getInfoUserType] = useState("");
  const [setInfoEmail, getInfoEmail] = useState("");
  const [setInfoPhone, getInfoPhone] = useState("");
  const [setInfoAddress, getInfoAddress] = useState("");
  const [setInfoCity, getInfoCity] = useState("");
  const [setInfoState, getInfoState] = useState("");
  const [setInfoPan, getInfoPan] = useState("");
  const [setInfoDomain, getInfoDomain] = useState("");
  const [setInfoMessage, getInfoMessage] = useState("");
  const [setInfoNominee, getInfoNominee] = useState("");
  const [setInfoLatitude, getInfoLatitude] = useState("");
  const [setInfoLongitude, getInfoLongitude] = useState("");
  const [setInfoStatus, getInfoStatus] = useState("");
  /* End Of Node Info Data */
  /* Update Approval Status */
  const [setUpdateRequest, getUpdateRequest] = useState(false);
  const [setViewMarketPriceId, getApprovalId] = useState("");
  const [setViewMarketPricePreStatus, getApprovalPreStatus] = useState("");
  const [setViewMarketPricePostStatus, getApprovalPostStatus] = useState("");
  /* Update Grow Status */
  const [setGrowUpdateRequest, getGrowUpdateRequest] = useState(false);
  const [setGrowId, getGrowId] = useState("");
  const [setGrowPreStatus, getGrowPreStatus] = useState("");
  const [setGrowPostStatus, getGrowPostStatus] = useState("");
  /* Update Sell Status */
  const [setSellUpdateRequest, getSellUpdateRequest] = useState(false);
  const [setSellId, getSellId] = useState("");
  const [setSellPreStatus, getSellPreStatus] = useState("");
  const [setSellPostStatus, getSellPostStatus] = useState("");
  /* Update Loan Status */
  const [setLoanUpdateRequest, getLoanUpdateRequest] = useState(false);
  const [setLoanId, getLoanId] = useState("");
  const [setLoanPreStatus, getLoanPreStatus] = useState("");
  const [setLoanPostStatus, getLoanPostStatus] = useState("");
  /* Update Vet Visit Status */
  const [setVetUpdateRequest, getVetUpdateRequest] = useState(false);
  const [setVetId, getVetId] = useState("");
  const [setVetPreStatus, getVetPreStatus] = useState("");
  const [setVetPostStatus, getVetPostStatus] = useState("");
  /* Update Buy Input Status */
  const [setBuyUpdateRequest, getBuyUpdateRequest] = useState(false);
  const [setBuyId, getBuyId] = useState("");
  const [setBuyPreStatus, getBuyPreStatus] = useState("");
  const [setBuyPostStatus, getBuyPostStatus] = useState("");
  /* Update Store Status */
  const [setStoreUpdateRequest, getStoreUpdateRequest] = useState(false);
  const [setStoreId, getStoreId] = useState("");
  const [setStorePreStatus, getStorePreStatus] = useState("");
  const [setStorePostStatus, getStorePostStatus] = useState("");
  /* End Of Update Store Status */
  /*Creator Farmers */
  const [addDialog, setAddDialog] = useState(false);
  const [usernameValue, setUsernameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [domainNameValue, setDomainNameValue] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("farmer");
  const [email, setEmail] = useState("");
  const [phoneNumerValue, setPhoneNumerValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  /*End Creator Farmers */
  /*Delete Farmers */
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  /*End Of Delete Farmers */
  /*Farmers Password Reset*/
  const [consumerUserResetPasswordDialog, setconsumerUserResetPasswordDialog] =
    useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  /*End Of Farmers Password Reset*/
  /*Farmers Update*/
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [consumerUserUserId, setConsumerUserUserId] = useState("");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  /*End Of Farmers Update*/
  /*Update Node Status Of Consumer Farmers*/
  const [viewNodeStatusDialog, setViewNodeStatusDialog] = useState(false);
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState("");
  const [nodeStatusValue, setNodeStatusValue] = useState("");
  /*End Of Update Node Status Of Consumer Farmers*/
  /*Node Info Of Consumer Farmers*/
  const [consumerUserViewNodeInfoDialog, setConsumerUserViewNodeInfoDialog] =
    useState(false);
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeUserNodeId, setNodeUserNodeId] = useState("");
  const [nodeUserAccountType, setNodeUserAccountType] = useState("");
  const [nodeUserUserType, setNodeUserUserType] = useState("");
  const [nodeUserAssetType, setNodeUserAssetType] = useState("");
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState("");
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState("");
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState("");
  const [nodeUserFeedType, setNodeUserFeedType] = useState("");
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState("");
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState("");
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState("");
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState("");
  const [nodeUserBreed, setNodeUserBreed] = useState("");
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState("");
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState("");
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState("");
  const [nodeUserNodeType, setNodeUserNodeType] = useState("");
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState("");
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState("");
  const [nodeUserFirstName, setNodeUserFirstName] = useState("");
  const [nodeUserLastName, setNodeUserLastName] = useState("");
  const [nodeUserEmail, setNodeUserEmail] = useState("");
  const [nodeUserPhone, setNodeUserPhone] = useState("");
  const [nodeUserAddress, setNodeUserAddress] = useState("");
  const [nodeUserCity, setNodeUserCity] = useState("");
  const [nodeUserState, setNodeUserState] = useState("");
  const [nodeUserDistrict, setNodeUserDistrict] = useState("");
  const [nodeUserPincode, setNodeUserPincode] = useState("");
  const [nodeUserLatitude, setNodeUserLatitude] = useState("");
  const [nodeUserLongitude, setNodeUserLongitude] = useState("");
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState("");
  /*End Of Node Info Of Consumer Farmers*/
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [getFilterSites, setFilterSites] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getType, setType] = useState("");
  const [nodeId, setNodeId] = useState("");
  /* Add node */
  const [pincodeValue, setPincodeValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [breedValue, setBreedValue] = useState("");
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [selectedVerticalsName, setSelectedVerticalsName] = useState("");
  const [accountTypeValue, setAccountTypeValue] = useState("");
  const [grazingTypeValue, setGrazingTypeValue] = useState("");
  const [assetTypeValue, setAssetTypeValue] = useState("");
  const [feedTypeValue, setFeedTypeValue] = useState("");
  const [farmingExperienceValue, setFarmingExperienceValue] = useState("");
  const [overallAgeValue, setOverallAgeValue] = useState("");
  const [maleGenderNumberValue, setMaleGenderNumberValue] = useState("");
  const [femaleGenderNumberValue, setFemaleGenderNumberValue] = useState("");
  const [noOfKidsValue, setNoOfKidsValue] = useState("");
  const [areaSizeValue, setAreaSizeValue] = useState("");
  const [numOfAssetsValue, setNumOfAssetsValue] = useState("");
  const [nodeTypeValue, setNodeTypeValue] = useState("farm");
  /* Grow Request */
  const [addGrowRequestDialog, setAddGrowRequestDialog] = useState(false);
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [advisoryInputValue, setAdvisoryInputValue] = useState("");
  const [selectedGrowerDestination, setSelectedGrowerDestination] =
    useState("");
  const [selectedGrowerSource, setSelectedGrowerSource] = useState("");
  const [selectedGrowerReqId, setSelectedGrowerReqId] = useState("");
  const [selectedGrowerStatus, setSelectedGrowerStatus] = useState("");
  const [cancelGrowRequestDialog, setCancelGrowRequestDialog] = useState(false);
  const [addSellRequestDialog, setAddSellRequestDialog] = useState(false);
  const [cancelSellRequestDialog, setCancelSellRequestDialog] = useState(false);
  const [selectedSellSource, setSelectedSellSource] = useState("");
  const [selectedSellDestination, setSelectedSellDestination] = useState("");
  const [selectedSellStatus, setSelectedSellStatus] = useState("");
  const [selectedSellReqId, setSelectedSellReqId] = useState("");
  const [selectedLoanSource, setSelectedLoanSource] = useState("");
  const [selectedLoanDestination, setSelectedLoanDestination] = useState("");
  const [selectedLoanStatus, setSelectedLoanStatus] = useState("");
  const [selectedLoanReqId, setSelectedLoanReqId] = useState("");
  const [addLoanRequestDialog, setAddLoanRequestDialog] = useState(false);
  const [cancelLoanRequestDialog, setCancelLoanRequestDialog] = useState(false);

  const [selectedVetSource, setSelectedVetSource] = useState("");
  const [selectedVetDestination, setSelectedVetDestination] = useState("");
  const [selectedVetStatus, setSelectedVetStatus] = useState("");
  const [selectedVetReqId, setSelectedVetReqId] = useState("");
  const [addVetRequestDialog, setAddVetRequestDialog] = useState(false);
  const [cancelVetRequestDialog, setCancelVetRequestDialog] = useState(false);

  const [selectedBuyInputSource, setSelectedBuyInputSource] = useState("");
  const [selectedBuyInputDestination, setSelectedBuyInputDestination] =
    useState("");
  const [selectedBuyInputStatus, setSelectedBuyInputStatus] = useState("");
  const [selectedBuyInputReqId, setSelectedBuyInputReqId] = useState("");
  const [addBuyInputDialog, setAddBuyInputDialog] = useState(false);
  const [cancelBuyInputDialog, setCancelBuyInputDialog] = useState(false);
  const [addStoreDialog, setAddStoreDialog] = useState(false);
  const [addAdvisoryDialog, setAddAdvisoryDialog] = useState(false);
  const [cancelStoreDialog, setCancelStoreDialog] = useState(false);
  const [cancelAdvisoryDialog, setCancelAdvisoryDialog] = useState(false);
  const [selectedStoreSource, setSelectedStoreSource] = useState("");
  const [selectedStoreDestination, setSelectedStoreDestination] = useState("");
  const [selectedStoreStatus, setSelectedStoreStatus] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");

  const [selectedAdvisorySource, setSelectedAdvisorySource] = useState("");
  const [selectedAdvisoryDestination, setSelectedAdvisoryDestination] =
    useState("");
  const [selectedAdvisoryStatus, setSelectedAdvisoryStatus] = useState("");
  const [selectedAdvisoryId, setSelectedAdvisoryId] = useState("");
  const [getSiteData, setSiteData] = useState([]);
  /* Update Node */
  const [updateNodeDialog, setUpdateNodeDialog] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const [selectedSiteName, setSelectedSiteName] = useState("");
  /* End of update Node */
  /* Delete Node */
  const [farmerNodeDeleteDialog, setFarmerNodeDeleteDialog] = useState(false);
  /* End of Delete Node */
  /* Advisory Request */
  const [sheepList, setSheepList] = useState([
    "Care",
    "Fodder",
    "Breed",
    "Disease-Diagnosis",
    "Facility-Structure",
  ]);
  const [beeList, setBeeList] = useState(["Care", "Feed", "Disease"]);
  const [cowList, setCowList] = useState(["Care", "Fodder", "Disease"]);
  const [advisoryList, setAdvisoryList] = useState([]);
  const [certifiedConsultantList, setCertifiedConsultantList] = useState([]);
  const [certifiedConsultants, setCertifiedConsultants] = useState(false);
  /* Send SMS and Email */
  const [emailValue, setEmailValue] = React.useState("");

  const [logisticProviderEmailDialog, setLogisticProviderEmailDialog] =
    React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [logisticProviderSMSDialog, setLogisticProviderSMSDialog] =
    React.useState(false);

  const [
    selectedLogisticProviderUsername,
    setSelectedLogisticProviderUserName,
  ] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFarmerSitesList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFarmerSitesList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

 
  useEffect(() => {
    listOfFarmerSites();
  }, [setFarmerSitesList]);
  useEffect(() => {
    getAllSitesList();
  }, [setSiteData]);

  useEffect(() => {
    getAssetVerticalsIdList();
  }, []);
  useEffect(() => {
    // if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    // if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    // if (!selectedAssertTypes) return;
    getModelList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */
  /* API Section */
  const getAllSitesList = () => {
    MainService.getAllSiteTypes("consumer2")
      .then((res) => {
        setSiteData(res.sitetypes);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  

  const listOfFarmerSites = (Type) => {
    setType(Type);
    setIsLoading(true);
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    MainService.getSitesBasedOnEmail(parentId, username)
      .then((res) => {
        console.log("farmer sites:", res);
        setFarmerSitesList(res);
        setFilterSites(res);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const nodeHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(true);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(true);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }

    MainService.getNodeInfo(farmername)
      .then((res) => {
        getNodeReq(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const marketOwnersRequestHandler = (Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(true);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(true);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(true);
      setViewListMarketOwners(false);
    }
    /* MainService.getParentId(parentId)
    .then((data) => {
      if (data.length > 0) {
        console.log("parent id for govtDept:",data)
        setParentIdOfPM(data);
      }
    })
    .catch((e) => {
      console.error(e);
      return NotificationManager.error(e);
    }); */
   /*  if(parentIdOfPM)
    { */
      MainService.marketOwners(parentId)
      .then((res) => {
        console.log("market ownwers res:", res);
        setMarketOwnersList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    /* } */
    
  };
  const marketPriceRequestHandler = (contentOwner, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(true);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(true);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }

    MainService.marketPriceRequests(parentId, contentOwner)
      .then((res) => {
        console.log("market price res:", res);
        getViewMarketPrice(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const growRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(true);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(true);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allGrowRequests(farmername)
      .then((res) => {
        getGrowReq(res.growrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const sellRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(true);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(true);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allSellRequests(farmername)
      .then((res) => {
        getSellReq(res.sellrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const LoanRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(true);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(true);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allLoanRequests(farmername)
      .then((res) => {
        getLoanReq(res.loanrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const vetVisitRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(true);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(true);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allVetVisitRequests(farmername)
      .then((res) => {
        getVetVisitReq(res.vetvisitrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const buyInputRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(true);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(true);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allBuyInputRequests(farmername)
      .then((res) => {
        getBuyInputReq(res.buyinputrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const storeRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(true);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(true);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allStoreRequests(farmername)
      .then((res) => {
        getStoreReq(res.storerequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const advisoryRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(true);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(true);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allAdvisoryRequests(farmername)
      .then((res) => {
        console.log("advisory requests:", res);
        getAdvisoryReq(res.advisoryrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const advisoryInfoHandler = (requestId, Type) => {
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setViewListAdvisoryInfo(true);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(true);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    advisoryRequestInfo(requestId);
  };
  const certifiedConsultantHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(true);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setViewMarketPrice(false);
      setViewGridMarketPrice(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
      setCertifiedConsultants(true);
      setViewGridAdvisoryInfo(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
    setSelectedNodeNameValue(farmername);
  };
  const nodeStatusHandler = (nodename) => {
    getNodeName(nodename);
    // setNode(false);
    setNodeUpdate(true);
  };

  const handleUpdateNodeClose = () => {
    // setNode(true);
    setNodeUpdate(false);
  };

  const updateNodeStatusHandler = () => {
    MainService.setNodeStatus(setNodeName, setNodeStatus)
      .then((res) => {
        setNodeUpdate(false);
        nodeHandler(setDefaultNodeName);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const nodeInfoHandler = (nodeid) => {
    if (viewNodeInfo === false) {
      setNodeInfo(true);
    }

    MainService.getNodeInfoBasedOnNodeId(nodeid)
      .then((res) => {
        setNodeUserName(res.sitename);
        setNodeUserNodeId(res.siteid);
        setNodeUserAccountType(res.accounttype);
        setNodeUserUserType(res.usertype);
        setNodeUserAssetType(res.assettype);
        setNodeUserNumberOfAssets(res.numberofassets);
        setNodeUserAreaSize(res.areasize);
        setNodeUserGrazingType(res.grazingtype);
        setNodeUserFeedType(res.feedtype);
        setNodeUserNumberOfKids(res.numberofkids);
        setNodeUserMaleGenderNumber(res.malegendernumber);
        setNodeUserFemaleGenderNumber(res.femalegendernumber);
        setNodeUserOverallAge(res.overallage);
        setNodeUserBreed(res.breed);
        setNodeUserFarmingExperience(res.farmingexperience);
        setNodeUserNodeStatus(res.nodestatus);
        setNodeUserContentUrl(res.contenturl);
        setNodeUserNodeType(res.nodetype);
        setNodeUserAssetverticalId(res.assetverticalid);
        setNodeUserAssetverticalFriendlyName(res.assetverticalfriendlyname);
        setNodeUserFirstName(res.firstname);
        setNodeUserLastName(res.lastname);
        setNodeUserEmail(res.email);
        setNodeUserPhone(res.phone);
        setNodeUserAddress(res.address);
        setNodeUserCity(res.city);
        setNodeUserState(res.state);
        setNodeUserDistrict(res.district);
        setNodeUserPincode(res.pincode);
        setNodeUserLatitude(res.latitude);
        setNodeUserLongitude(res.longitude);
        setNodeUserTimestamp(res.timestamp);
      })
      .catch((err) => {
        return NotificationManager.error("err");
      });
  };

  const nodeInfoCloseHandler = () => {
    setNodeInfo(false);
  };

  const updateRequestHandler = () => {
    getUpdateRequest(false);
  };

  const updateApprovalRequest = (requestid, status) => {
    getApprovalId(requestid);
    getApprovalPreStatus(status);
    getUpdateRequest(true);
  };

  const approvalReqStatusHandler = () => {
    const approvalBody = {
      requestid: setViewMarketPriceId,
      existingApprovalRequestStatus: setViewMarketPricePreStatus,
      updatedApprovalRequestStatus: setViewMarketPricePostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.approvalReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateGrowRequest = (requestid, status) => {
    getGrowId(requestid);
    getGrowPreStatus(status);
    getGrowUpdateRequest(true);
  };

  const updateGrowRequestHandler = () => {
    getGrowUpdateRequest(false);
  };

  const growReqStatusHandler = () => {
    const approvalBody = {
      requestid: setGrowId,
      existingGrowRequestStatus: setGrowPreStatus,
      updatedGrowRequestStatus: setGrowPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.growReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateSellRequest = (requestid, status) => {
    getSellId(requestid);
    getSellPreStatus(status);
    getSellUpdateRequest(true);
  };

  const updateSellRequestHandler = () => {
    getSellUpdateRequest(false);
  };

  const sellReqStatusHandler = () => {
    const approvalBody = {
      requestid: setSellId,
      existingSellRequestStatus: setSellPreStatus,
      updatedSellRequestStatus: setSellPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.sellReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateLoanRequest = (requestid, status) => {
    getLoanId(requestid);
    getLoanPreStatus(status);
    getLoanUpdateRequest(true);
  };

  const updateLoanRequestHandler = () => {
    getLoanUpdateRequest(false);
  };

  const loanReqStatusHandler = () => {
    const approvalBody = {
      requestid: setLoanId,
      existingLoanRequestStatus: setLoanPreStatus,
      updatedLoanRequestStatus: setLoanPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.LoanReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateVetRequest = (requestid, status) => {
    getVetId(requestid);
    getVetPreStatus(status);
    getVetUpdateRequest(true);
  };

  const updateVetRequestHandler = () => {
    getVetUpdateRequest(false);
  };

  const vetReqStatusHandler = () => {
    const approvalBody = {
      requestid: setVetId,
      existingLoanRequestStatus: setVetPreStatus,
      updatedLoanRequestStatus: setVetPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.VetReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateBuyRequest = (requestid, status) => {
    getBuyId(requestid);
    getBuyPreStatus(status);
    getBuyUpdateRequest(true);
  };

  const updateBuyRequestHandler = () => {
    getBuyUpdateRequest(false);
  };

  const buyReqStatusHandler = () => {
    const approvalBody = {
      requestid: setBuyId,
      existingBuyInputRequestStatus: setBuyPreStatus,
      updatedBuyInputRequestStatus: setBuyPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.BuyReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateStoreRequest = (requestid, status) => {
    getStoreId(requestid);
    getStorePreStatus(status);
    getStoreUpdateRequest(true);
  };

  const updateStoreRequestHandler = () => {
    getStoreUpdateRequest(false);
  };

  const storeReqStatusHandler = () => {
    const approvalBody = {
      requestid: setStoreId,
      existingStoreRequestStatus: setStorePreStatus,
      updatedStoreRequestStatus: setStorePostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.StoreReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          console.log("category data:", data);
          if (data.length > 0) {
            setCategoryTypesList(data);
            setSelectedCategory(data[0].categoryType);
            
          } else {
            
            setCategoryTypesList([]);
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          console.log("asset type data:", data);
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            
            setCategoryTypesList([]);
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getModelList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            console.log("model data:", data);
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            
            setModelsList([]);
            setSelectedModel("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  /* Send SMS and Email */
  const sendEmailClickDialog = (username, email) => {
    setUsernameValue(username);
    setEmailValue(email);
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };
  const sendEmailClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("email Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };

  const sendSMSClick = () => {
    let smsData = {
      sms: messageValue,
    };
    const smsDataString = JSON.stringify(smsData);
    MainService.sendSMS(phoneNumerValue, smsDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  const sendMessageClickDialog = (username, phonenumber) => {
    setUsernameValue(username);
    setPhoneNumerValue(phonenumber);
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  /*************** Create, Update, Delete, NodeStatus, Node Info Farmers ***************/

/* Site Info */


const siteInfoPopUpHandler = (infositeid) => {
  MainService.siteInfo(infositeid)
    .then((res) => {
      const isObjectEmpty = (res) => {
        return _.isEmpty(res);
      };
      setIsObjectEmpty(isObjectEmpty(res));
      if (isObjectEmpty(res) === false) {
        setIsLoading(false);
        console.log("site info:", res);
        setSiteInfoObj(res);
      }
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });

    getSiteInfoPopUp(!setSiteInfoPopup);
};

const closeSiteInfoModal = (
  <span
    onClick={() => {
      getSiteInfoPopUp(!setSiteInfoPopup);
    }}
  >
    X
  </span>
);

const siteInfoHeader = "SITE DETAILS";
const SiteInfoBody = isObjectEmpty ? (
  isLoading ? (
    <CircularProgress />
  ) : (
    <h4>Data Not Available</h4>
  )
) : isLoading ? (
  <CircularProgress />
) : (
  <div>
    <p>
      <b>Site Name:</b>
      {siteInfoObj.sitename}
    </p>
    <p>
      <b>AssetverticalId:</b>
      {siteInfoObj.assetverticalid}
    </p>
    <p>
      <b>Displayname:</b>
      {siteInfoObj.displayname}
    </p>
    <p>
      <b>Designation:</b>
      {siteInfoObj.designation}
    </p>
    <p>
      <b>Industry Type:</b>
      {siteInfoObj.industrytype}
    </p>
    <p>
      <b>Contact Addr:</b>
      {siteInfoObj.contactaddr}
    </p>
    <p>
      <b>City Name:</b>
      {siteInfoObj.cityname}
    </p>
    <p>
      <b>City Code:</b>
      {siteInfoObj.citycode}
    </p>
    <p>
      <b>Country:</b>
      {siteInfoObj.country}
    </p>
    <p>
      <b>Countrycode:</b>
      {siteInfoObj.countrycode}
    </p>
    <p>
      <b>Street Name:</b>
      {siteInfoObj.streetname}
    </p>
    <p>
      <b>Street Number:</b>
      {siteInfoObj.streetnumber}
    </p>
    <p>
      <b>Area:</b>
      {siteInfoObj.area}
    </p>
    <p>
      <b>Region:</b>
      {siteInfoObj.region}
    </p>
    <p>
      <b>Postal Code:</b>
      {siteInfoObj.postalcode}
    </p>
    <p>
      <b>Subdivision:</b>
      {siteInfoObj.subdivision}
    </p>
    <p>
      <b>Locality:</b>
      {siteInfoObj.locality}
    </p>
    <p>
      <b>Latitude:</b>
      {siteInfoObj.latitude}
    </p>
    <p>
      <b>Longitude:</b>
      {siteInfoObj.longitude}
    </p>
    <p>
      <b>Altitude:</b>
      {siteInfoObj.altitude}
    </p>
    <p>
      <b>Timezone:</b>
      {siteInfoObj.timezone}
    </p>
    <br />
    <Button
      variant="outlined"
      style={{ left: "45%" }}
      onClick={() => {
        getSiteInfoPopUp(!setSiteInfoPopup);
      }}
    >
      Cancel
    </Button>
  </div>
);
/* End of Site Info */

  /* Start of Site Update */
  const updateSiteUpdateClick = (infositeid) => {
    getInfoSiteId(infositeid);
    MainService.siteInfo(infositeid)
      .then((res) => {
        setSiteInfoName(res.sitename);
        setSiteInfoExistingSiteType(res.subdivision);
        setWebsiteUrl(res.designation);
        setLocAddress(res.contactaddr);
        setSiteInfoAssetVerticalId(res.assetverticalid);
        setIndustryType(res.industrytype);
        setStreetName(res.streetname);
        setStreetNumber(res.streetnumber);
        setLocality(res.locality);
        setArea(res.area);
        setRegion(res.region);
        setCountry(res.country);
        setPostalCode(res.postalcode);
        setLatitude(res.latitude);
        setLongitude(res.longitude);
        setAltitude(res.altitude);
        setTimeZone(res.timezone);
        setCityCode(res.citycode);
        setCityName(res.cityname);
        setCountryCode(res.countrycode);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const updateSiteUpdateHandler = () => {
    const siteUpdateInfo = {
      phone: getPhoneNumber,
      email: email,
      sitetype: getUpdateSiteType,
      websiteurl: getWebsiteUrl,
      locationaddress: getLocAddress,
      streetname: getStreetName,
      streetnumber: getStreetNumber,
      locality: getLocality,
      area: getArea,
      region: getRegion,
      country: getCountry,
      postalcode: getPostalCode,
      latitude: getLatitude,
      longitude: getLongitude,
      altitude: getAltitude,
      timezone: getTimeZone,
      citycode: getCityCode,
      cityname: getCityName,
      countrycode: getCountryCode,
      stdcode: getStdCode,
    };

    const formData = JSON.stringify(siteUpdateInfo);

    MainService.updateSiteInfo(infoSiteId, formData)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
      listOfFarmerSites();
    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const closeSiteUpdateModal = (
    <span
      onClick={() => {
        setSiteUpdatePopup(!getSiteUpdatePopup);
      }}
    >
      X
    </span>
  );

  const siteUpdateHeader = "SITE UPDATE DETAILS";
  const SiteUpdateBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteid"> Site Id:</InputLabel>
        <Input
          disabled
          id="siteid"
          name="siteid"
          type="text"
          value={infoSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          disabled
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteInfoName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone"> Phone Number:</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="number"
          value={getPhoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="email"> Email:</InputLabel>
        <Input
          id="email"
          name="email"
          type="text"
          value={setEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitetype"> Existing Site Type:</InputLabel>
        <Input
          disabled
          id="sitetype"
          name="sitetype"
          type="text"
          value={getSiteInfoExistingSiteType}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Update Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getUpdateSiteType}
          onChange={(e) => {
            setUpdateSiteType(e.target.value);
          }}
          label="Select Model"
        >
          <MenuItem value="" disabled selected>
            <em>Select Any Site Type</em>
          </MenuItem>
          <MenuItem key="Farm" value="Farm">
            Farm
          </MenuItem>
          <MenuItem key="OpenFarm" value="OpenFarm">
            OpenFarm
          </MenuItem>
          <MenuItem key="PhysicalMarket" value="PhysicalMarket">
            Physical-Market
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="weburl"> Website URL:</InputLabel>
        <Input
          id="weburl"
          name="weburl"
          type="text"
          value={getWebsiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locaddress"> Location Address:</InputLabel>
        <Input
          id="locaddress"
          name="locaddress"
          type="text"
          value={getLocAddress}
          onChange={(e) => setLocAddress(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetvi"> Asset Vertical Id:</InputLabel>
        <Input
          disabled
          id="assetvi"
          name="assetvi"
          type="text"
          value={getSiteInfoAssetVerticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="industrytype">
          {" "}
          Industry Type (Asset Vertical Friendly Name):
        </InputLabel>
        <Input
          id="industrytype"
          name="industrytype"
          type="text"
          value={getIndustryType}
          onChange={(e) => setIndustryType(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetname"> Street Name:</InputLabel>
        <Input
          id="streetname"
          name="streetname"
          type="text"
          value={getStreetName}
          onChange={(e) => setStreetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetnumber"> Street Number:</InputLabel>
        <Input
          id="streetnumber"
          name="streetnumber"
          type="text"
          value={getStreetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locality"> Locality:</InputLabel>
        <Input
          id="locality"
          name="locality"
          type="text"
          value={getLocality}
          onChange={(e) => setLocality(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="area"> Area (State):</InputLabel>
        <Input
          id="area"
          name="area"
          type="text"
          value={getArea}
          onChange={(e) => setArea(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="region"> Region:</InputLabel>
        <Input
          id="region"
          name="region"
          type="text"
          value={getRegion}
          onChange={(e) => setRegion(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="country"> Country:</InputLabel>
        <Input
          id="country"
          name="country"
          type="text"
          value={getCountry}
          onChange={(e) => setCountry(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="pstcode"> Postal Code(Zip):</InputLabel>
        <Input
          id="pstcode"
          name="pstcode"
          type="text"
          value={getPostalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={getLatitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude"> Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={getLongitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={getAltitude}
          onChange={(e) => setAltitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="timezone"> Time Zone:</InputLabel>
        <Input
          id="timezone"
          name="timezone"
          type="text"
          value={getTimeZone}
          onChange={(e) => setTimeZone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="citycode"> City Code:</InputLabel>
        <Input
          id="citycode"
          name="citycode"
          type="text"
          value={getCityCode}
          onChange={(e) => setCityCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="cityname"> City Name:</InputLabel>
        <Input
          id="cityname"
          name="cityname"
          type="text"
          value={getCityName}
          onChange={(e) => setCityName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="countrycode"> Country Code:</InputLabel>
        <Input
          id="countrycode"
          name="countrycode"
          type="text"
          value={getCountryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="stdcode"> Std Code:</InputLabel>
        <Input
          id="stdcode"
          name="stdcode"
          type="text"
          value={getStdCode}
          onChange={(e) => setStdCode(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateSiteUpdateHandler}
        >
          Update
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteUpdatePopup(!getSiteUpdatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Of Site update Info */




  const updateNodeDialogClick = (nodeId) => {
    setSelectedNodeId(nodeId);
    nodeInfoHandler(nodeId);
    setNodeInfo(false);
    setUpdateNodeDialog(!updateNodeDialog);
  };
  const updateNodeSubmit = () => {
    let updateNodeData = {
      id: nodeUserNodeId,
      username: nodeUserName,
      firstname: nodeUserFirstName,
      lastname: nodeUserLastName,
      phone: nodeUserPhone,
      district: nodeUserDistrict,
      pincode: nodeUserPincode,
      state: nodeUserState,
      city: nodeUserCity,
      address: nodeUserAddress,
      longitude: nodeUserLongitude,
      latitude: nodeUserLatitude,
      accounttype: nodeUserAccountType,
      usertype: nodeUserUserType,
      assettype: nodeUserAssetType,
      numberofassets: nodeUserNumberOfAssets,
      areasize: nodeUserAreaSize,
      grazingtype: nodeUserGrazingType,
      feedtype: nodeUserFeedType,
      numberofkids: nodeUserNumberOfKids,
      malegendernumber: nodeUserMaleGenderNumber,
      femalegendernumber: nodeUserFemaleGenderNumber,
      overallage: nodeUserOverallAge,
      farmingexperience: nodeUserFarmingExperience,
      breed: nodeUserBreed,
    };
    MainService.updateNode(updateNodeData, selectedNodeId)
      .then((res) => {
        listOfFarmerSites("Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateNodeDialog(!updateNodeDialog);
  };

  const deleteNodeDialogClick = (nodeId, sitename) => {
    setSelectedNodeId(nodeId);
    setSelectedSiteName(sitename);
    setFarmerNodeDeleteDialog(!farmerNodeDeleteDialog);
  };
  const deleteNodeSubmitClick = () => {
    MainService.siteDeleteHandler(selectedNodeId,selectedSiteName,username,)
      .then((res) => {
        listOfFarmerSites("Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setFarmerNodeDeleteDialog(!farmerNodeDeleteDialog);
  };

  const resetPasswordDialogClick = (consumerUserId) => {
    setSelectedConsumerUserId(consumerUserId);
    setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
  };

  const resetPasswordSubmit = () => {
    if (newPasswordValue === "") {
      return NotificationManager.error("please provide password");
    }
    if (newPasswordValue === passwordConfirmationValue) {
      let resetPwdData = {
        type: "password",
        value: newPasswordValue,
        temporary: false,
      };
      MainService.resetConsumerUserPasswordIndividual(
        resetPwdData,
        username,
        selectedConsumerUserId
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
    } else {
      return NotificationManager.error(
        " Confirmation Password is not match with New password"
      );
    }
  };

  const consumerUserUpdateDialogClick = (consumerUser) => {
    setSelectedConsumerUserName(consumerUser.sitename);
    setSelectedConsumerUserId(consumerUser.userid);

    setConsumerUserUserId(consumerUser.userid);
    setConsumerUserUserName(consumerUser.username);
    setConsumerUserFirstName(consumerUser.firstname);
    setConsumerUserLastName(consumerUser.lastname);
    setConsumerUserEmail(consumerUser.email);
    setConsumerUserPhone(consumerUser.phone);
    setConsumerUserAddress(consumerUser.address);
    setConsumerUserCity(consumerUser.city);
    setConsumerUserState(consumerUser.state);
    setConsumerUserNominee(consumerUser.nominee);
    setConsumerUserPancard(consumerUser.pancard);
    setConsumerUserLongitude(consumerUser.longitude);
    setConsumerUserLatitude(consumerUser.latitude);
    setConsumerUserMessage(consumerUser.message);
    setConsumerUserUserType(consumerUser.usertype);
    setConsumerUserUserDomainName(consumerUser.domain);

    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const consumerUserUpdateSubmit = () => {
    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress,
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage,
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      consumerUserUpdateDataString,
      selectedConsumerUserId,
      selectedConsumerUserName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const viewNodeStatusClick = (selectedNodeId) => {
    MainService.viewNodeStatus(selectedNodeId)
      .then((res) => {
        getNodeName(res.nodename);
        setNodeId(res.siteid);
        getNodeStatus(res.nodestatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setViewNodeStatusDialog(!viewNodeStatusDialog);
  };

  const viewNodeInfoClick = (nodeid) => {
    MainService.getNodeInfoBasedOnNodeId(nodeid)
      .then((res) => {
        setNodeUserName(res.sitename);
        setNodeUserNodeId(res.siteid);
        setNodeUserAccountType(res.accounttype);
        setNodeUserUserType(res.usertype);
        setNodeUserAssetType(res.assettype);
        setNodeUserNumberOfAssets(res.numberofassets);
        setNodeUserAreaSize(res.areasize);
        setNodeUserGrazingType(res.grazingtype);
        setNodeUserFeedType(res.feedtype);
        setNodeUserNumberOfKids(res.numberofkids);
        setNodeUserMaleGenderNumber(res.malegendernumber);
        setNodeUserFemaleGenderNumber(res.femalegendernumber);
        setNodeUserOverallAge(res.overallage);
        setNodeUserBreed(res.breed);
        setNodeUserFarmingExperience(res.farmingexperience);
        setNodeUserNodeStatus(res.nodestatus);
        setNodeUserContentUrl(res.contenturl);
        setNodeUserNodeType(res.nodetype);
        setNodeUserAssetverticalId(res.assetverticalid);
        setNodeUserAssetverticalFriendlyName(res.assetverticalfriendlyname);
        setNodeUserFirstName(res.firstname);
        setNodeUserLastName(res.lastname);
        setNodeUserEmail(res.email);
        setNodeUserPhone(res.phone);
        setNodeUserAddress(res.address);
        setNodeUserCity(res.city);
        setNodeUserState(res.state);
        setNodeUserDistrict(res.district);
        setNodeUserPincode(res.pincode);
        setNodeUserLatitude(res.latitude);
        setNodeUserLongitude(res.longitude);
        setNodeUserTimestamp(res.timestamp);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserViewNodeInfoDialog(!consumerUserViewNodeInfoDialog);
  };
  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfo Farmers ***************/
  /* End Of API Section */

  const gotoFarmerNodesClick = (viewType) => {
    if (viewType === "Grid") {
      setGridNode(false);
      setViewGridMarketPrice(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
      getGridOpe(true);
    } else if (viewType === "List") {
      setNode(false);
      setViewMarketPrice(false);
      setAdvisory(false);
      setBuyInput(false);
      setGrow(false);
      setLoan(false);
      setSell(false);
      setStore(false);
      setVetVisit(false);
      setGridAdvisory(false);
      setGridVetVisit(false);
      setCertifiedConsultants(false);
      setViewGridAdvisoryInfo(false);
      setViewListAdvisoryInfo(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
      getListOpe(true);
    }
  };

  const backToMarketOwners = (viewType)=>{
    if (viewType === "List") {
      setGridNode(false);
      setViewGridMarketPrice(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setAdvisory(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(true);
    } else {
      setGridNode(false);
      setViewGridMarketPrice(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setAdvisory(false);
      setViewGridMarketOwners(true);
      setViewListMarketOwners(false);
    }
  }
  const backToAdvisoryRequests = (viewType) => {
    if (viewType === "Grid") {
      setGridNode(false);
      setViewGridMarketPrice(false);
      setGridAdvisory(true);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setAdvisory(false);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    } else {
      setGridNode(false);
      setViewGridMarketPrice(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      setViewGridAdvisoryInfo(false);
      setCertifiedConsultants(false);
      setViewListAdvisoryInfo(false);
      setAdvisory(true);
      setViewGridMarketOwners(false);
      setViewListMarketOwners(false);
    }
  };
  /* Add Node */
  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        console.log("verticals data:", data);
        if (data.length > 0) {
          setVerticalsIdList(data);
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalsName(data[0].friendlyName);
        } else {
          setIsLoading(false);
          setSelectedVerticalsId("");
          setSelectedVerticalsName("");
          setVerticalsIdList([]);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const selectedVeticalIdHandler = (event) => {
    const filteredVerticalName = verticalsIdList.filter(function (item) {
      return item.assetVerticalId === event.target.value;
    });
    setSelectedVerticalsName(filteredVerticalName[0].friendlyName);
    setSelectedVerticalsId(filteredVerticalName[0].assetVerticalId);
  };
  const addGrowRequestSubmit = () => {
    let addGrowRequestData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addGrowRequestDataString = JSON.stringify(addGrowRequestData);
    MainService.addGrowerRequest(addGrowRequestDataString)
      .then((res) => {
        growRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddGrowRequestDialog(!addGrowRequestDialog);
  };
  const addSellRequestSubmit = () => {
    let addSellRequestData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addSellRequestDataString = JSON.stringify(addSellRequestData);
    MainService.addSellRequest(addSellRequestDataString)
      .then((res) => {
        sellRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddSellRequestDialog(!addSellRequestDialog);
  };
  const addLoanRequestSubmit = () => {
    let addLoanRequestData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addLoanRequestDataString = JSON.stringify(addLoanRequestData);
    MainService.addLoanRequest(addLoanRequestDataString)
      .then((res) => {
        LoanRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddLoanRequestDialog(!addLoanRequestDialog);
  };
  const addVetRequestSubmit = () => {
    let addVetRequestData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addVetRequestDataString = JSON.stringify(addVetRequestData);
    MainService.addVetRequest(addVetRequestDataString)
      .then((res) => {
        vetVisitRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddVetRequestDialog(!addVetRequestDialog);
  };

  const addBuyInputSubmit = () => {
    let addBuyInputData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addBuyInputDataString = JSON.stringify(addBuyInputData);
    MainService.addBuyInputRequest(addBuyInputDataString)
      .then((res) => {
        buyInputRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddBuyInputDialog(!addBuyInputDialog);
  };

  const cancelBuyInputDialogClick = (sell) => {
    setSelectedBuyInputSource(sell.source);
    setSelectedBuyInputDestination(sell.destination);
    setSelectedBuyInputStatus(sell.status);
    setSelectedBuyInputReqId(sell.requestid);

    setCancelBuyInputDialog(!cancelBuyInputDialog);
  };
  const cancelBuyInputSubmit = () => {
    let cancelRequestData = {
      requestid: selectedBuyInputReqId,
      existingRequestStatus: selectedBuyInputStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelLoanRequest(
      selectedBuyInputDestination,
      selectedBuyInputSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelGrowRequestDialogClick = (grower) => {
    setSelectedGrowerSource(grower.source);
    setSelectedGrowerDestination(grower.destination);
    setSelectedGrowerStatus(grower.status);
    setSelectedGrowerReqId(grower.requestid);

    setCancelGrowRequestDialog(!cancelGrowRequestDialog);
  };
  const cancelGrowRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedGrowerReqId,
      existingRequestStatus: selectedGrowerStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelGrowRequest(
      selectedGrowerDestination,
      selectedGrowerSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCancelGrowRequestDialog(!cancelGrowRequestDialog);
  };
  const cancelSellRequestDialogClick = (sell) => {
    setSelectedSellSource(sell.source);
    setSelectedSellDestination(sell.destination);
    setSelectedSellStatus(sell.status);
    setSelectedSellReqId(sell.requestid);

    setCancelSellRequestDialog(!cancelSellRequestDialog);
  };
  const cancelSellRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedSellReqId,
      existingRequestStatus: selectedSellStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    if (
      selectedSellDestination !== "" &&
      selectedSellSource !== "" &&
      cancelRequestDataString !== ""
    ) {
      MainService.cancelSellRequest(
        selectedSellDestination,
        selectedSellSource,
        cancelRequestDataString
      )
        .then((res) => {
          listOfFarmerSites(getType);
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    }

    setCancelSellRequestDialog(!cancelSellRequestDialog);
  };

  const cancelLoanRequestDialogClick = (sell) => {
    setSelectedLoanSource(sell.source);
    setSelectedLoanDestination(sell.destination);
    setSelectedLoanStatus(sell.status);
    setSelectedLoanReqId(sell.requestid);

    setCancelLoanRequestDialog(!cancelLoanRequestDialog);
  };
  const cancelLoanRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedLoanReqId,
      existingRequestStatus: selectedLoanStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelLoanRequest(
      selectedLoanDestination,
      selectedLoanSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCancelLoanRequestDialog(!cancelLoanRequestDialog);
  };

  const cancelVetRequestDialogClick = (sell) => {
    setSelectedVetSource(sell.source);
    setSelectedVetDestination(sell.destination);
    setSelectedVetStatus(sell.status);
    setSelectedVetReqId(sell.requestid);

    setCancelVetRequestDialog(!cancelVetRequestDialog);
  };
  const cancelVetRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedVetReqId,
      existingRequestStatus: selectedVetStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelVetRequest(
      selectedVetDestination,
      selectedVetSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCancelVetRequestDialog(!cancelVetRequestDialog);
  };

  const addStoreSubmit = () => {
    let addStoreData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addStoreDataString = JSON.stringify(addStoreData);
    MainService.addStoreRequest(addStoreDataString)
      .then((res) => {
        storeRequestHandler(selectedNodeNameValue, "Grid");
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddStoreDialog(!addStoreDialog);
  };
  const cancelStoreSubmit = () => {
    let cancelRequestData = {
      requestid: selectedStoreId,
      existingRequestStatus: selectedStoreStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelStoreRequest(
      selectedStoreDestination,
      selectedStoreSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCancelStoreDialog(!cancelStoreDialog);
  };
  const cancelStoreDialogClick = (sell) => {
    setSelectedStoreSource(sell.source);
    setSelectedStoreDestination(sell.destination);
    setSelectedStoreStatus(sell.status);
    setSelectedStoreId(sell.requestid);

    setCancelStoreDialog(!cancelStoreDialog);
  };
  const addNodeSubmit = () => {
    let addNodeData = {
      id: username,
      username: username,
      email: username,
      firstname: firstNameValue,
      lastname: lastNameValue,
      phone: phoneNumerValue,
      district: districtValue,
      pincode: pincodeValue,
      state: stateNameValue,
      city: cityNameValue,
      address: addressValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      accounttype: accountTypeValue,
      usertype: userTypeValue,
      assettype: assetTypeValue,
      numberofassets: numOfAssetsValue,
      areasize: areaSizeValue,
      grazingtype: grazingTypeValue,
      feedtype: feedTypeValue,
      numberofkids: noOfKidsValue,
      malegendernumber: maleGenderNumberValue,
      femalegendernumber: femaleGenderNumberValue,
      overallage: overallAgeValue,
      farmingexperience: farmingExperienceValue,
      nodetype: nodeTypeValue,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: selectedVerticalsName,
      breed: breedValue,
    };
    const addNodeDataString = JSON.stringify(addNodeData);
    MainService.addNodeToFarmer(addNodeDataString)
      .then((res) => {
        listOfFarmerSites("Grid");
        return NotificationManager.success(res.siteid, res.nodename);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddDialog(!addDialog);
  };

  const addAdvisorySubmit = () => {
    let addAdvisoryData = {
      source: selectedNodeNameValue,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: "ADVISORY",
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue,
      lat: latitudeValue,
      longi: longitudeValue,
      advisoryinput: advisoryInputValue,
    };
    const addAdvisoryDataString = JSON.stringify(addAdvisoryData);
    MainService.addAdvisoryRequest(addAdvisoryDataString)
      .then((res) => {
        console.log("res.requestid", res);

        advisoryInfoHandler(res, "Grid");
        //advisoryRequestHandler(selectedNodeNameValue, "Grid");

        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddAdvisoryDialog(!addAdvisoryDialog);
  };

  const advisoryRequestInfo = (requestId) => {
    if (requestId !== undefined) {
      MainService.contentInfoBasedOnRequestId(requestId, parentId)
        .then((res) => {
          console.log("advisoryRequestInfo", res);
          setAdvisoryList(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    }
  };
  const contactExpertClick = () => {
    MainService.AssetCertifiedConsultant(parentId)
      .then((res) => {
        console.log("AssetCertifiedConsultant", res);
        setCertifiedConsultantList(res);
        certifiedConsultantHandler(selectedNodeNameValue, "Grid");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCertifiedConsultants(!certifiedConsultants);
  };
  const cancelAdvisorySubmit = () => {
    let cancelRequestData = {
      requestid: selectedAdvisoryId,
      existingRequestStatus: selectedAdvisoryStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelAdvisoryRequest(
      selectedAdvisoryDestination,
      selectedAdvisorySource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerSites(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelAdvisoryDialogClick = (sell) => {
    setSelectedAdvisorySource(sell.source);
    setSelectedAdvisoryDestination(sell.destination);
    setSelectedAdvisoryStatus(sell.status);
    setSelectedAdvisoryId(sell.requestid);

    setCancelStoreDialog(!cancelStoreDialog);
  };
  /* End of add node */
  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        {(viewAdvisory === true || viewGridAdvisory === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Advisory Request"
            className={classes.fab}
            onClick={() => {
              setAddAdvisoryDialog(!addAdvisoryDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewStore === true || viewGridStore === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Store Request"
            className={classes.fab}
            onClick={() => {
              setAddStoreDialog(!addStoreDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewBuyInput === true || viewGridBuyInput === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Buy-Input Request"
            className={classes.fab}
            onClick={() => {
              setAddBuyInputDialog(!addBuyInputDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewVetVisit === true || viewGidVetVisit === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Vet Visit Request"
            className={classes.fab}
            onClick={() => {
              setAddVetRequestDialog(!addVetRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGridSell === true || viewSell === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Sell Request"
            className={classes.fab}
            onClick={() => {
              setAddSellRequestDialog(!addSellRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGridLoan === true || viewLoan === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Loan Request"
            className={classes.fab}
            onClick={() => {
              setAddLoanRequestDialog(!addLoanRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGrow === true || viewGridGrow === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Grow Request"
            className={classes.fab}
            onClick={() => {
              setAddGrowRequestDialog(!addGrowRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {/* {(setListOpe === true || setGridOpe === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Node"
            className={classes.fab}
            onClick={() => {
              getAssetVerticalsIdList();
              getCategoryTypesList();
              getAssetTypesList();
              setAddDialog(!addDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )} */}

        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                listOfFarmerSites("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                listOfFarmerSites("List");
              }}
            ></Button>
          </div>
        </div>
        {/* LIST OF Nodes */}
        {setListOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Box>
              <h4>Total No of Sites :{farmerSitesList.length} </h4>
            </Box>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {farmerSitesList.length > 0 ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={17} >
                          LIST OF FARMER SITES
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center"colSpan={1}>
                          Site Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Site Name
                        </TableCell>
                        
                        <TableCell align="center" colSpan={3}>
                          Farm Details
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Farmer Operations
                        </TableCell>
                        <TableCell align="center" colSpan={9}>
                          Farmer Requests
                        </TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {farmerSitesList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {user.siteid}
                              </TableCell>
                              <TableCell align="center">
                                {user.sitename}
                              </TableCell>
                              
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteInfoPopUpHandler(user.siteid)
                                  }
                                >
                                  Site Info
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewNodeStatusClick(user.siteid);
                                  }}
                                >
                                  Node Status
                                </Button>
                              </TableCell> */}

                              <TableCell />
                              {user.nodestatus !== "authentication-pending" ? (
                                <>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        updateSiteUpdateClick(user.siteid)
                                      }
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        deleteNodeDialogClick(user.siteid, user.sitename);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                  <TableCell />

                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        marketOwnersRequestHandler("List");
                                      }}
                                    >
                                      Market Price
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        growRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Grow Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Sell Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        LoanRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Loan Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        vetVisitRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      VET Visit Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        buyInputRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Buy Input Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        storeRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Store Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        advisoryRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Advisory Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        advisoryRequestHandler(
                                          user.sitename,
                                          "List"
                                        );
                                      }}
                                    >
                                      Buy Assets Requests
                                    </Button>
                                  </TableCell>
                                  <TableCell />
                                </>
                              ) : (
                                <h5 style={{ color: "#3ECF8E" }}>
                                  Wait for 12hrs. Your farm is under processing.
                                  <br />
                                  After Processing you can start using standard
                                  services
                                </h5>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={farmerSitesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No Sites Found</h4>
            )}
          </Box>
        ) : null}

        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            {setGridOpe === true ? (
              <Box sx={{ width: "100%", marginBottom: 30 }}>
                <Box>
              <h4>Total No of Sites :{farmerSitesList.length} </h4>
            </Box>
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    LIST OF FARMER SITES
                  </h4>
                  {farmerSitesList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {farmerSitesList.map((ope, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Site Id:</b>
                              {ope.siteid}
                            </p>
                            <p>
                              <b>Site Name:</b>
                              {ope.sitename}
                            </p>
                            
                            <h4 className="cardactiontitle">Farm Details</h4>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  siteInfoPopUpHandler(ope.siteid)
                                }
                              >
                                Site Info
                              </Button>
                              {/*  &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  viewNodeStatusClick(ope.siteid);
                                }}
                              >
                                Node Status
                              </Button> */}
                            </div>
                            {ope.nodestatus !== "authentication-pending" ? (
                              <div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <h4 className="cardactiontitle">
                                    Farmer Operations
                                  </h4>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateSiteUpdateClick(ope.siteid)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      deleteNodeDialogClick(ope.siteid, ope.sitename);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <h4 className="cardactiontitle">
                                    Farmer Requests
                                  </h4>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      growRequestHandler(ope.sitename, "Grid");
                                    }}
                                  >
                                    Grow Requests
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      sellRequestHandler(ope.sitename, "Grid");
                                    }}
                                  >
                                    Sell Requests
                                  </Button>
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      LoanRequestHandler(ope.sitename, "Grid");
                                    }}
                                  >
                                    Loan Requests
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      vetVisitRequestHandler(
                                        ope.sitename,
                                        "Grid"
                                      );
                                    }}
                                  >
                                    VET Visit Requests
                                  </Button>
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      buyInputRequestHandler(
                                        ope.sitename,
                                        "Grid"
                                      );
                                    }}
                                  >
                                    Buy Input Requests
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      storeRequestHandler(ope.sitename, "Grid");
                                    }}
                                  >
                                    Store Requests
                                  </Button>
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      advisoryRequestHandler(
                                        ope.sitename,
                                        "Grid"
                                      );
                                    }}
                                  >
                                    Advisory Requests
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      marketOwnersRequestHandler("Grid");
                                    }}
                                  >
                                    Market Price
                                  </Button>
                                </div>
                                &nbsp;&nbsp;
                                <div style={{ width: "100%", margin: "5px" }}>                                  
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      marketOwnersRequestHandler("Grid");
                                    }}
                                  >
                                    Buy Assets Requests
                                  </Button>
                                </div>
                                <div></div>
                              </div>
                            ) : (
                              <h5 style={{ color: "#3ECF8E" }}>
                                Wait for 12hrs. Your farm is under processing.
                                <br />
                                After Processing you can start using standard
                                services
                              </h5>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Sites Found.</h4>
                  )}
                </div>
              </Box>
            ) : null}
          </Box>
        )}
        {/* VIEW NODE LIST */}
        {viewNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Sites
            </Button>
            <div className="MuiGrid-root">
              <h2>VIEW NODE LIST</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setNodeReq ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                USER NAME
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                TYPE OF ASSET
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                NUMBER OF ASSETS
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                AREA SIZE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                GRAZING TYPE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FEED TYPE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                NUMBER OF KIDS
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                MALE GENDER NUMBER
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FEMALE GENDER NUMBER
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                OVERALL AGE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                BREED
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FARMING EXPERIENCE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                LATITUDE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                LONGITUDE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                STATUS
                              </TableCell>
                              <TableCell align="center" colSpan={3}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setNodeReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((node, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {node.sitename}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.assettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.numberofassets}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.areasize}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.grazingtype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.feedtype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.numberofkids}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.malegendernumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.femalegendernumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.overallage}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.breed}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.farmingexperience}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.latitude}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.longitude}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.nodestatus}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          nodeStatusHandler(node.sitename);
                                        }}
                                      >
                                        Site Status
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          nodeInfoHandler(node.siteid);
                                        }}
                                      >
                                        Node Info
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        // onClick={() => {
                                        //   roleNameOfOperator(user.username)
                                        // }}
                                      >
                                        View Operators
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setNodeReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Sites Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>VIEW NODE LIST</h4>
              </span>
              {setNodeReq ? (
                <div className="cardStyleForDashboardItems">
                  {setNodeReq.map((nodelist, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>USER NAME:</b>
                          {nodelist.sitename}
                        </p>
                        <p>
                          <b>TYPE OF ASSET:</b>
                          {nodelist.assettype}
                        </p>
                        <p>
                          <b>NUMBER OF ASSETS:</b>
                          {nodelist.numberofassets}
                        </p>
                        <p>
                          <b>AREA SIZE:</b>
                          {nodelist.areasize}
                        </p>
                        <p>
                          <b>GRAZING TYPE:</b>
                          {nodelist.grazingtype}
                        </p>
                        <p>
                          <b>FEED TYPE:</b>
                          {nodelist.feedtype}
                        </p>
                        <p>
                          <b>NUMBER OF KIDS:</b>
                          {nodelist.numberofkids}
                        </p>
                        <p>
                          <b>MALE GENDER NUMBER:</b>
                          {nodelist.malegendernumber}
                        </p>
                        <p>
                          <b>FEMALE GENDER NUMBER:</b>
                          {nodelist.femalegendernumber}
                        </p>
                        <p>
                          <b>OVERALL AGE:</b>
                          {nodelist.overallage}
                        </p>
                        <p>
                          <b>BREED:</b>
                          {nodelist.breed}
                        </p>
                        <p>
                          <b>FARMING EXPERIENCE:</b>
                          {nodelist.farmingexperience}
                        </p>
                        <p>
                          <b>LATITUDE:</b>
                          {nodelist.latitude}
                        </p>
                        <p>
                          <b>LONGITUDE:</b>
                          {nodelist.longitude}
                        </p>
                        <p>
                          <b>STATUS:</b>
                          {nodelist.nodestatus}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              nodeStatusHandler(nodelist.sitename);
                            }}
                          >
                            Site Status
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              nodeInfoHandler(nodelist.siteid);
                            }}
                          >
                            Node Info
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              // storeRequestHandler(nodelist.username);
                            }}
                          >
                            View Operators
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Node List Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL MARKET PRICES */}
        {viewMarketPrice === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => backToMarketOwners("List")}
            >
              Back to Market Owners
            </Button>
            <div className="MuiGrid-root">
              <h4>ALL MARKET PRICE LIST</h4>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setViewMarketPriceReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={14}>
                                ALL MARKET PRICE LIST
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Content Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Owner
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Creator
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Description
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Expirydate
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Advisory Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Discounted Price
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Image
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setViewMarketPriceReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.contentid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentname}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentowner}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.categoryid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.categoryname}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentcreatorid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentdescription}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentexpirydate}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentsubcategory}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.discountedprice}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.siteid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.sitename}
                                    </TableCell>
                                    <TableCell align="center">
                                      <img src={advisory.locationurl} />
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.timestamp}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setViewMarketPriceReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No MARKET PRICES Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridMarketPrice === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => backToMarketOwners("Grid")}
            >
              Back to Market Owners
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL MARKET PRICES LIST</h4>
              </p>
              {setViewMarketPriceReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setViewMarketPriceReq.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Category Name:</b>
                          {advisory.categoryname}
                        </p>
                        <p>
                          <b>Category Id:</b>
                          {advisory.categoryid}
                        </p>
                        <p>
                          <b>Contentcreator:</b>
                          {advisory.contentcreatorid}
                        </p>
                        <p
                          style={{
                            padding: 10,
                            width: 300,
                          }}
                        >
                          <b>Content Description:</b>
                          {advisory.contentdescription}
                        </p>
                        <p>
                          <b>Content Expirydate:</b>
                          {advisory.contentexpirydate}
                        </p>
                        <p>
                          <b>Content Format:</b>
                          {advisory.contentformat}
                        </p>

                        <p>
                          <b>Content Id:</b>
                          {advisory.contentid}
                        </p>
                        <p>
                          <b>Content Name:</b>
                          {advisory.contentname}
                        </p>
                        <p>
                          <b>Content Owner:</b>
                          {advisory.contentowner}
                        </p>
                        <p>
                          <b>Content-Subcategory:</b>
                          {advisory.contentsubcategory}
                        </p>
                        <p>
                          <b>Discounted Price:</b>
                          {advisory.discountedprice}
                        </p>
                        <p>
                          <b>Likecount:</b>
                          {advisory.likecount}
                        </p>

                        <p>
                          <b>Mimetype:</b>
                          {advisory.mimetype}
                        </p>
                        <p>
                          <b>Parentid:</b>
                          {advisory.parentid}
                        </p>
                        <p>
                          <b>Price:</b>
                          {advisory.price}
                        </p>
                        <p>
                          <b>Unit:</b>
                          {advisory.unit}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {advisory.timestamp}
                        </p>
                      </span>
                      <img src={advisory.locationurl} /> <br />
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No MARKET PRICES Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL MARKET PRICES */}
        {viewListMarketOwners === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Sites
            </Button>
            <div className="MuiGrid-root">
              <h4>ALL MARKET OWNERS LIST</h4>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {marketOwnersList.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={3}>
                                ALL MARKET OWNERS LIST
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Type
                              </TableCell>

                              
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {marketOwnersList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.sitename}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.sitetype}
                                    </TableCell>

                                    
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          marketPriceRequestHandler(
                                            advisory.sitename,"List"
                                          );
                                        }}
                                      >
                                        View Price
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={marketOwnersList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No MARKET PRICES Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridMarketOwners === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL MARKET OWNERS LIST</h4>
              </p>
              {marketOwnersList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {marketOwnersList.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Site Name:</b>
                          {advisory.sitename}
                        </p>
                        <p>
                          <b>Site Type:</b>
                          {advisory.sitetype}
                        </p>
                        
                      </span>
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            marketPriceRequestHandler(advisory.sitename,"Grid");
                          }}
                        >
                          View Price
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Mandis Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL GROW REQUESTS */}
        {viewGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL GROW REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={13}>
                                ALL GROW REQUESTS
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setGrowReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((grow, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {grow.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.timestamp}
                                    </TableCell>
                                    {grow.status === "new-grow-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelGrowRequestDialogClick(grow);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setGrowReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No GROW REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL GROW REQUESTS</h4>
              </p>
              {setGrowReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.map((grow, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {grow.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {grow.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {grow.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {grow.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {grow.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {grow.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {grow.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {grow.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {grow.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {grow.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {grow.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {grow.timestamp}
                        </p>
                        {grow.status === "new-grow-request" && (
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ margin: "5px", width: "50%" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelGrowRequestDialogClick(grow);
                              }}
                            >
                              cancel
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Grow Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL SELL REQUESTS */}
        {viewSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL SELL REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setSellReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Total Selling Amount
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setSellReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((sell, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {sell.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.totalsellingamount}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.timestamp}
                                    </TableCell>
                                    {sell.status === "new-sell-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelSellRequestDialogClick(sell);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setSellReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No SELL REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL SELL REQUESTS</h4>
              </p>
              {setSellReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setSellReq.map((sell, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {sell.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {sell.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {sell.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {sell.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {sell.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {sell.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {sell.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {sell.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {sell.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {sell.quantity}
                        </p>
                        <p>
                          <b>Total Selling Amount:</b>
                          {sell.totalsellingamount}
                        </p>
                        <p>
                          <b>Status:</b>
                          {sell.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {sell.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {sell.status === "new-sell-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelSellRequestDialogClick(sell);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Sell Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL LOAN REQUESTS */}
        {viewLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL LOAN REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setLoanReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((loan, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {loan.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.timestamp}
                                    </TableCell>
                                    {loan.status === "new-loan-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelLoanRequestDialogClick(loan);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setLoanReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No LOAN REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL LOAN REQUESTS</h4>
              </p>
              {setLoanReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.map((loan, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {loan.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {loan.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {loan.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {loan.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {loan.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {loan.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {loan.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {loan.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {loan.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {loan.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {loan.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {loan.status === "new-loan-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelLoanRequestDialogClick(loan);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Loan Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL VET VISIT REQUESTS */}
        {viewVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL VET VISIT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setVetVisitReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((vet, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {vet.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.timestamp}
                                    </TableCell>
                                    {vet.status === "new-vetvisit-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelVetRequestDialogClick(vet);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setVetVisitReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No VET VISIT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGidVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL VET VISIT REQUESTS</h4>
              </p>
              {setVetVisitReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.map((vetvisit, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {vetvisit.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {vetvisit.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {vetvisit.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {vetvisit.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {vetvisit.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {vetvisit.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {vetvisit.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {vetvisit.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {vetvisit.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {vetvisit.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {vetvisit.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {vetvisit.status === "new-vetvisit-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelVetRequestDialogClick(vetvisit);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Vet Visit Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL BUY INPUT REQUESTS */}
        {viewBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL BUY INPUT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Growering Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setBuyInputReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((buyinput, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {buyinput.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.groweringinput}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.timestamp}
                                    </TableCell>
                                    {buyinput.status ===
                                      "new-buyinput-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelBuyInputDialogClick(buyinput);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setBuyInputReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No BUY INPUT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL BUY INPUT REQUESTS</h4>
              </span>
              {setBuyInputReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.map((buyinput, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {buyinput.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {buyinput.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {buyinput.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {buyinput.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {buyinput.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {buyinput.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {buyinput.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {buyinput.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {buyinput.email}
                        </p>
                        <p>
                          <b>Growering Input:</b>
                          {buyinput.groweringinput}
                        </p>
                        <p>
                          <b>Status:</b>
                          {buyinput.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {buyinput.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {buyinput.status === "new-buyinput-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelBuyInputDialogClick(buyinput);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Buy Input Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL STORE REQUESTS */}
        {viewStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL STORE REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setStoreReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((store, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {store.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.timestamp}
                                    </TableCell>
                                    {store.status === "new-store-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelStoreDialogClick(store);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setStoreReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Store Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL STORE REQUESTS</h4>
              </span>
              {setStoreReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.map((store, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {store.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {store.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {store.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {store.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {store.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {store.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {store.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {store.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {store.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {store.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {store.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {store.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {store.status === "new-store-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelStoreDialogClick(store);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Store Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL ADVISORY REQUESTS */}
        {viewAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => backToAdvisoryRequests("List")}
            >
              Back to Advisory
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL ADVISORY REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Assetvertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy AssetType
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Mobile Number
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>

                              <TableCell align="center" colSpan={1}>
                                Advisory Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setAdvisoryReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.advisoryinputs}
                                    </TableCell>

                                    <TableCell align="center">
                                      {advisory.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          advisoryInfoHandler(
                                            advisory.requestid,
                                            "List"
                                          );
                                        }}
                                      >
                                        Advisory Info
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setAdvisoryReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No ADVISORY REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}
        {viewListAdvisoryInfo === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Sites
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL ADVISORIES</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {advisoryList.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Content Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category Owner
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Creator
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Description
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Content Expirydate
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Advisory Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Discounted Price
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Image
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {advisoryList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.contentid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentname}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentowner}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.categoryid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.categoryname}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentcreator}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentdescription}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentexpirydate}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.contentsubcategory}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.discountedprice}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.siteid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.sitename}
                                    </TableCell>
                                    <TableCell align="center">
                                      <img src={advisory.locationurl} />
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.timestamp}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={advisoryList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No ADVISORY Info Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL ADVISORY REQUESTS</h4>
              </span>
              {setAdvisoryReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {advisory.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {advisory.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {advisory.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {advisory.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {advisory.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {advisory.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {advisory.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {advisory.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {advisory.email}
                        </p>
                        <p>
                          <b>Advisory Input:</b>
                          {advisory.advisoryinputs}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {advisory.timestamp}
                        </p>
                      </span>

                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          advisoryInfoHandler(advisory.requestid, "Grid");
                        }}
                      >
                        Advisory Info
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Advisory Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {viewGridAdvisoryInfo === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => backToAdvisoryRequests("Grid")}
            >
              Back to Advisory
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL ADVISORIES</h4>
              </span>
              {advisoryList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {advisoryList.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Category Name:</b>
                          {advisory.categoryname}
                        </p>
                        <p>
                          <b>Category Id:</b>
                          {advisory.categoryid}
                        </p>
                        <p>
                          <b>Contentcreator:</b>
                          {advisory.contentcreator}
                        </p>
                        <p>
                          <b>Content Description:</b>
                          {advisory.contentdescription}
                        </p>
                        <p>
                          <b>Content Expirydate:</b>
                          {advisory.contentexpirydate}
                        </p>
                        <p>
                          <b>Content Format:</b>
                          {advisory.contentformat}
                        </p>

                        <p>
                          <b>Content Id:</b>
                          {advisory.contentid}
                        </p>
                        <p>
                          <b>Content Name:</b>
                          {advisory.contentname}
                        </p>
                        <p>
                          <b>Content Owner:</b>
                          {advisory.contentowner}
                        </p>
                        <p>
                          <b>Content-Subcategory:</b>
                          {advisory.contentsubcategory}
                        </p>
                        <p>
                          <b>Discounted Price:</b>
                          {advisory.discountedprice}
                        </p>
                        <p>
                          <b>Likecount:</b>
                          {advisory.likecount}
                        </p>

                        <p>
                          <b>Mimetype:</b>
                          {advisory.mimetype}
                        </p>
                        <p>
                          <b>Parentid:</b>
                          {advisory.parentid}
                        </p>
                        <p>
                          <b>Price:</b>
                          {advisory.price}
                        </p>
                        <p>
                          <b>Unit:</b>
                          {advisory.unit}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {advisory.timestamp}
                        </p>
                      </span>
                      <img src={advisory.locationurl} /> <br />
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          contactExpertClick();
                        }}
                      >
                        Contact Expert
                      </Button>
                      {/* <div style={{ width: "100%", margin: "5px" }}>
                        {advisory.status === "new-advisory-request" && (
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              cancelAdvisoryDialogClick(advisory);
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </div> */}
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Advisory Requests Info Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {certifiedConsultants === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => backToAdvisoryRequests("Grid")}
            >
              Back to Advisory
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL Certified Consultants</h4>
              </span>
              {certifiedConsultantList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {certifiedConsultantList.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>User Name:</b>
                          {advisory.username}
                        </p>
                        <p>
                          <b>User Id:</b>
                          {advisory.userid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {advisory.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {advisory.email}
                        </p>
                        <p>
                          <b>Domain:</b>
                          {advisory.domain}
                        </p>
                      </span>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          sendEmailClickDialog(
                            advisory.sitename,
                            advisory.email
                          );
                        }}
                      >
                        Send Email
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          sendMessageClickDialog(
                            advisory.sitename,
                            advisory.phone
                          );
                        }}
                      >
                        Send SMs
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Advisory Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}

        {/* NODE STATUS */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeUpdate}
          onClose={handleUpdateNodeClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Node Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Node Name:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={setNodeName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Node Status</InputLabel>
              <Select
                id="status"
                value={setNodeStatus}
                onChange={(e) => {
                  getNodeStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem
                  key="authenticationpending"
                  value="authentication-pending"
                >
                  {" "}
                  authentication-pending{" "}
                </MenuItem>
                <MenuItem key="authenticated" value="authenticated">
                  {" "}
                  authenticated{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={updateNodeStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleUpdateNodeClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* NODE INFO */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeInfo}
          onClose={nodeInfoCloseHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Node Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pid">Enterprice Id:</InputLabel>
              <Input
                id="pid"
                name="pid"
                placeholder="Enterprice Id"
                type="text"
                value={setInfoEnterpriceId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="uname">User Name:</InputLabel>
              <Input
                id="uname"
                name="uname"
                placeholder="User Name"
                type="text"
                value={setInfoUserName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="fname">First Name:</InputLabel>
              <Input
                id="fname"
                name="fname"
                placeholder="First Name"
                type="text"
                value={setInfoFname}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lname">Last Name:</InputLabel>
              <Input
                id="lname"
                name="lname"
                placeholder="Last Name"
                type="text"
                value={setInfoLname}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="usertype">User Type:</InputLabel>
              <Input
                id="usertype"
                name="usertype"
                placeholder="User Type"
                type="text"
                value={setInfoUserType}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="eid">Email Id:</InputLabel>
              <Input
                id="eid"
                name="eid"
                placeholder="Email Id"
                type="text"
                value={setInfoEmail}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Phone:</InputLabel>
              <Input
                id="phone"
                name="phone"
                placeholder="Phone"
                type="text"
                value={setInfoPhone}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="address">Address:</InputLabel>
              <Input
                id="address"
                name="address"
                placeholder="Address"
                type="text"
                value={setInfoAddress}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City:</InputLabel>
              <Input
                id="city"
                name="city"
                placeholder="City"
                type="text"
                value={setInfoCity}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State:</InputLabel>
              <Input
                id="state"
                name="state"
                placeholder="State"
                type="text"
                value={setInfoState}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pan">Pan Card Number:</InputLabel>
              <Input
                id="pan"
                name="pan"
                placeholder="Pan Card Number"
                type="text"
                value={setInfoPan}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domain">Domain:</InputLabel>
              <Input
                id="domain"
                name="domain"
                placeholder="Domain"
                type="text"
                value={setInfoDomain}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee:</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                placeholder="Nominee"
                type="text"
                value={setInfoNominee}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="message">Message:</InputLabel>
              <Input
                id="message"
                name="message"
                placeholder="Message"
                type="text"
                value={setInfoMessage}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude:</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                placeholder="Longitude"
                type="text"
                value={setInfoLongitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude:</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                placeholder="Latitude"
                type="text"
                value={setInfoLatitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Node Status:</InputLabel>
              <Input
                id="status"
                name="status"
                placeholder="Node Status"
                type="text"
                value={setInfoStatus}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={nodeInfoCloseHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* MARKET PRICE Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setUpdateRequest}
          onClose={updateRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update MARKET PRICE Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setViewMarketPriceId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setViewMarketPricePostStatus}
                onChange={(e) => {
                  getApprovalPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem>
                  <MenuItem key="in-progress" value="in-progress"> in-progress </MenuItem> */}
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                {/* <MenuItem key="accepted" value="accepted"> accepted </MenuItem>
                  <MenuItem key="rejected" value="rejected"> rejected </MenuItem>
                  <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={approvalReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Grow Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setGrowUpdateRequest}
          onClose={updateGrowRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Grow Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setGrowId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setGrowPostStatus}
                onChange={(e) => {
                  getGrowPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={growReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateGrowRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Sell Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setSellUpdateRequest}
          onClose={updateSellRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Sell Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setSellId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setSellPostStatus}
                onChange={(e) => {
                  getSellPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={sellReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateSellRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Loan Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setLoanUpdateRequest}
          onClose={updateLoanRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Loan Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setLoanId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setLoanPostStatus}
                onChange={(e) => {
                  getLoanPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={loanReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateLoanRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Vet Visit Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setVetUpdateRequest}
          onClose={updateVetRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Vet Visit Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setVetId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setVetPostStatus}
                onChange={(e) => {
                  getVetPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={vetReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateVetRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Buy Input Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setBuyUpdateRequest}
          onClose={updateBuyRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Buy Input Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setBuyId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setBuyPostStatus}
                onChange={(e) => {
                  getBuyPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={buyReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateBuyRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Store Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setStoreUpdateRequest}
          onClose={updateStoreRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Store Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setStoreId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setStorePostStatus}
                onChange={(e) => {
                  getStorePostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={storeReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateStoreRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Node */}
        <Dialog
          fullScreen={fullScreen}
          open={addDialog}
          // onClose={handleClose}
          aria-labelledby="addNode"
        >
          <DialogTitle id="addNode">{"Create Node"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Id :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
                /* onChange={(e) => {
                  setUsernameValue(e.target.value);
                }} */
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={username}
                /* onChange={(e) => {
                  setEmail(e.target.value);
                }} */
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select id="userType" name="userType" value={userTypeValue}>
                <MenuItem value={userTypeValue}>Farmer</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  selectedVeticalIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="assetType">Asset Type</InputLabel>
              <Select
                id="assetType"
                name="assetType"
                value={selectedAssertTypes}
                onChange={(e) => {
                  setSelectedAssertTypes(e.target.value);
                }}
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="numOfAssets">Number of Assets:</InputLabel>
              <Input
                id="numOfAssets"
                name="numOfAssets"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="areaSize">Area Size(in sqmt):</InputLabel>
              <Input
                id="areaSize"
                name="areaSize"
                type="text"
                value={areaSizeValue}
                onChange={(e) => {
                  setAreaSizeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="noOfKids">Number of Kids:</InputLabel>
              <Input
                id="noOfKids"
                name="noOfKids"
                type="text"
                value={noOfKidsValue}
                onChange={(e) => {
                  setNoOfKidsValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="maleNumber">Male Gender Number:</InputLabel>
              <Input
                id="maleNumber"
                name="maleNumber"
                type="text"
                value={maleGenderNumberValue}
                onChange={(e) => {
                  setMaleGenderNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="femaleNumber">
                Female Gender Number:
              </InputLabel>
              <Input
                id="femaleNumber"
                name="femaleNumber"
                type="text"
                value={femaleGenderNumberValue}
                onChange={(e) => {
                  setFemaleGenderNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="overallAge">
                Overall Age(in months):
              </InputLabel>
              <Input
                id="overallAge"
                name="overallAge"
                type="text"
                value={overallAgeValue}
                onChange={(e) => {
                  setOverallAgeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="farmingexperience">
                Farming Experience:
              </InputLabel>
              <Input
                id="farmingexperience"
                name="farmingexperience"
                type="text"
                value={farmingExperienceValue}
                onChange={(e) => {
                  setFarmingExperienceValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="nodeType">Node Type</InputLabel>
              <Select
                id="nodeType"
                name="nodeType"
                value={nodeTypeValue}
                onChange={(e) => {
                  setNodeTypeValue(e.target.value);
                }}
              >
                {/* <MenuItem value={nodeTypeValue}>Farm</MenuItem> */}
                {getSiteData &&
                  getSiteData.map((idObj, index) => (
                    <MenuItem key={`${index}`} value={idObj.sitetype}>
                      {idObj.sitetype}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="feedType">Feed Type</InputLabel>
              <Select
                id="feedType"
                name="feedType"
                value={feedTypeValue}
                onChange={(e) => {
                  setFeedTypeValue(e.target.value);
                }}
              >
                <MenuItem value={feedTypeValue}>{feedTypeValue}</MenuItem>
                <MenuItem value="Pasture">Pasture</MenuItem>
                <MenuItem value="Hay">Hay</MenuItem>
                <MenuItem value="Silage">Silage</MenuItem>
                <MenuItem value="RootCrops">RootCrops</MenuItem>
              </Select>
            </FormControl>

            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="grazingType">Grazing Type</InputLabel>
              <Select
                id="grazingType"
                name="grazingType"
                value={grazingTypeValue}
                onChange={(e) => {
                  setGrazingTypeValue(e.target.value);
                }}
              >
                <MenuItem value={grazingTypeValue}>{grazingTypeValue}</MenuItem>
                <MenuItem value="opengrazing">Open-Grazing</MenuItem>
                <MenuItem value="closedgrazing">Closed-Grazing</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userType">Account Type</InputLabel>
              <Select
                id="accountType"
                name="accountType"
                value={accountTypeValue}
                onChange={(e) => {
                  setAccountTypeValue(e.target.value);
                }}
              >
                <MenuItem value={accountTypeValue}>{accountTypeValue}</MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Family">Family</MenuItem>
              </Select>
            </FormControl>

            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="breed">Breed :</InputLabel>
              <Input
                id="breed"
                name="breed"
                type="text"
                value={breedValue}
                onChange={(e) => {
                  setBreedValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">District :</InputLabel>
              <Input
                id="district"
                name="district"
                type="text"
                value={districtValue}
                onChange={(e) => {
                  setDistrictValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Pincode :</InputLabel>
              <Input
                id="pincode"
                name="pincode"
                type="text"
                value={pincodeValue}
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addNodeSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddDialog(!addDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Grow Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addGrowRequestDialog}
          aria-labelledby="addGrowRequst"
        >
          <DialogTitle id="addGrowRequest">{"Add Grow Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addGrowRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddGrowRequestDialog(!addGrowRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Grower Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelGrowRequestDialog}
          aria-labelledby="cancelGrowerRequest"
        >
          <DialogTitle id="cancelGrowerRequest">
            {"Cancel Grower Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelGrowRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelGrowRequestDialog(!cancelGrowRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Sell Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addSellRequestDialog}
          aria-labelledby="addSellRequst"
        >
          <DialogTitle id="addSellRequst">{"Add Sell Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addSellRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddSellRequestDialog(!addSellRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Sell Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelSellRequestDialog}
          aria-labelledby="cancelSellRequest"
        >
          <DialogTitle id="cancelSellRequest">
            {"Cancel Sell Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelSellRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelSellRequestDialog(!cancelSellRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Loan Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addLoanRequestDialog}
          aria-labelledby="addLoanRequst"
        >
          <DialogTitle id="addLoanRequest">{"Add Loan Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addLoanRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddLoanRequestDialog(!addLoanRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Loan Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelLoanRequestDialog}
          aria-labelledby="cancelLoanRequest"
        >
          <DialogTitle id="cancelLoanRequest">
            {"Cancel Loan Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelLoanRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelLoanRequestDialog(!cancelLoanRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Advisory */}
        <Dialog
          fullScreen={fullScreen}
          open={addAdvisoryDialog}
          aria-labelledby="addAdvisory"
        >
          <DialogTitle id="addAdvisory">{"Add Advisory"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Advisory Input</InputLabel>
              <Select
                id="selectedAdvisory"
                value={advisoryInputValue}
                onChange={(e) => {
                  setAdvisoryInputValue(e.target.value);
                }}
                label="Select Advisory"
              >
                {(selectedAssertTypes === "sheep" ||
                  selectedAssertTypes === "Sheep" ||
                  selectedAssertTypes === "SHEEP") &&
                  (selectedModel === "Nali" ||
                    selectedModel === "Lohi" ||
                    selectedModel === "LOHI" ||
                    selectedModel === "Nati" ||
                    selectedModel === "Gaadi") &&
                  sheepList.map((sheep, index) => (
                    <MenuItem key={`${index} `} value={sheep}>
                      {sheep}
                    </MenuItem>
                  ))}

                {(selectedAssertTypes === "goat" ||
                  selectedAssertTypes === "Goat" ||
                  selectedAssertTypes === "GOAT") &&
                  (selectedModel === "Jamunapari" ||
                    selectedModel === "Sorjat" ||
                    selectedModel === "Beetal" ||
                    selectedModel === "Barbari" ||
                    selectedModel === "Black-Bengal") &&
                  sheepList.map((goat, index) => (
                    <MenuItem key={`${index} `} value={goat}>
                      {goat}
                    </MenuItem>
                  ))}
                {(selectedAssertTypes === "bee" ||
                  selectedAssertTypes === "Bee" ||
                  selectedAssertTypes === "BEE") &&
                  (selectedModel === "Indian-Bee" ||
                    selectedModel === "European-Bee" ||
                    selectedModel === "EUROPEAN-BEE" ||
                    selectedModel === "Rock-Bee") &&
                  beeList.map((bee, index) => (
                    <MenuItem key={`${index} `} value={bee}>
                      {bee}
                    </MenuItem>
                  ))}
                {selectedAssertTypes === "MilkingCow" &&
                  (selectedModel === "Jersey" ||
                    selectedModel === "Sahiwal" ||
                    selectedModel === "Gir" ||
                    selectedModel === "Rathi" ||
                    selectedModel === "Kankrej" ||
                    selectedModel === "Tarparkarj" ||
                    selectedModel === "MotoSin") &&
                  cowList.map((cow, index) => (
                    <MenuItem key={`${index} `} value={cow}>
                      {cow}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addAdvisorySubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddAdvisoryDialog(!addAdvisoryDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Advisory */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelAdvisoryDialog}
          aria-labelledby="cancelAdvisory"
        >
          <DialogTitle id="cancelAdvisory">
            {"Cancel Advisory Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelAdvisorySubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelAdvisoryDialog(!cancelAdvisoryDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add BuyInput */}
        <Dialog
          fullScreen={fullScreen}
          open={addBuyInputDialog}
          aria-labelledby="addBuyInput"
        >
          <DialogTitle id="addBuyInput">{"Add BuyInput"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth>
              <InputLabel>Buy Input Type</InputLabel>
              <Select
                id="selectedAdvisory"
                value={advisoryInputValue}
                onChange={(e) => {
                  setAdvisoryInputValue(e.target.value);
                }}
                label="Select Advisory"
              >
                {(selectedAssertTypes === "sheep" ||
                  selectedAssertTypes === "Sheep" ||
                  selectedAssertTypes === "SHEEP") &&
                  (selectedModel === "Nali" ||
                    selectedModel === "Lohi" ||
                    selectedModel === "LOHI" ||
                    selectedModel === "Nati" ||
                    selectedModel === "Gaadi") &&
                  sheepList.map((sheep, index) => (
                    <MenuItem key={`${index} `} value={sheep}>
                      {sheep}
                    </MenuItem>
                  ))}

                {(selectedAssertTypes === "goat" ||
                  selectedAssertTypes === "Goat" ||
                  selectedAssertTypes === "GOAT") &&
                  (selectedModel === "Jamunapari" ||
                    selectedModel === "Sorjat" ||
                    selectedModel === "Beetal" ||
                    selectedModel === "Barbari" ||
                    selectedModel === "Black-Bengal") &&
                  sheepList.map((goat, index) => (
                    <MenuItem key={`${index} `} value={goat}>
                      {goat}
                    </MenuItem>
                  ))}
                {(selectedAssertTypes === "bee" ||
                  selectedAssertTypes === "Bee" ||
                  selectedAssertTypes === "BEE") &&
                  (selectedModel === "Indian-Bee" ||
                    selectedModel === "European-Bee" ||
                    selectedModel === "EUROPEAN-BEE" ||
                    selectedModel === "Rock-Bee") &&
                  beeList.map((bee, index) => (
                    <MenuItem key={`${index} `} value={bee}>
                      {bee}
                    </MenuItem>
                  ))}
                {selectedAssertTypes === "MilkingCow" &&
                  (selectedModel === "Jersey" ||
                    selectedModel === "Sahiwal" ||
                    selectedModel === "Gir" ||
                    selectedModel === "Rathi" ||
                    selectedModel === "Kankrej" ||
                    selectedModel === "Tarparkarj" ||
                    selectedModel === "MotoSin") &&
                  cowList.map((cow, index) => (
                    <MenuItem key={`${index} `} value={cow}>
                      {cow}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addBuyInputSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddBuyInputDialog(!addBuyInputDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel BuyInput */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelBuyInputDialog}
          aria-labelledby="cancelBuyInput"
        >
          <DialogTitle id="cancelBuyInput">
            {"Cancel BuyInput Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelBuyInputSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelBuyInputDialog(!cancelBuyInputDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Store */}
        <Dialog
          fullScreen={fullScreen}
          open={addStoreDialog}
          aria-labelledby="addStore"
        >
          <DialogTitle id="addStore">{"Add Store"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addStoreSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddStoreDialog(!addStoreDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Store */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelStoreDialog}
          aria-labelledby="cancelStore"
        >
          <DialogTitle id="cancelStore">{"Cancel Store Request"}</DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelStoreSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelStoreDialog(!cancelStoreDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add VET Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addVetRequestDialog}
          aria-labelledby="addVetRequst"
        >
          <DialogTitle id="addVetRequest">
            {"Add VET Visit Request"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (Parent) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelid}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addVetRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddVetRequestDialog(!addVetRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel VET Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelVetRequestDialog}
          aria-labelledby="cancelVetRequest"
        >
          <DialogTitle id="cancelVetRequest">
            {"Cancel VET Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelVetRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelVetRequestDialog(!cancelVetRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Farmer */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDeleteDialog}
          // onClose={handleClose}
          aria-labelledby="deleteConsumerUser"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Farmer User"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteNodeSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Farmer */}
        {/* Reset Password Of Farmer */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserResetPasswordDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserResetPassword"
        >
          <DialogTitle id="consumerUserResetPassword">
            Farmer Password Reset
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="newPassword">New Password:</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPasswordValue}
                onChange={(e) => {
                  setNewPasswordValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="passwordConfirmation">
                Password Confirmation:
              </InputLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                value={passwordConfirmationValue}
                onChange={(e) => {
                  setPasswordConfirmationValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetPasswordSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setconsumerUserResetPasswordDialog(
                  !consumerUserResetPasswordDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Reset Password Farmer */}
        {/* Delete Farmer Node */}
        <Dialog
          fullScreen={fullScreen}
          open={farmerNodeDeleteDialog}
          aria-labelledby="deleteConsumerUser"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Farmer Node"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteNodeSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setFarmerNodeDeleteDialog(!farmerNodeDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Farmer Node */}
        {/* Update Node */}
        <Dialog
          fullScreen={fullScreen}
          open={updateNodeDialog}
          // onClose={handleClose}
          aria-labelledby="addNode"
        >
          <DialogTitle id="addNode">{"Update Node"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Node Id :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={nodeUserNodeId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={nodeUserName}
                /* onChange={(e) => {
                  setUsernameValue(e.target.value);
                }} */
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={nodeUserEmail}
                onChange={(e) => {
                  setNodeUserEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={nodeUserFirstName}
                onChange={(e) => {
                  setNodeUserFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={nodeUserLastName}
                onChange={(e) => {
                  setNodeUserLastName(e.target.value);
                }}
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Input
                id="userType"
                name="userType"
                value={nodeUserUserType}
              ></Input>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Input value={nodeUserAssetverticalFriendlyName}></Input>
              {/* <Select
                id="selectedVerticalsId"
                value={nodeUserAssetverticalFriendlyName}
                onChange={(event) => {
                  setNodeUserAssetverticalFriendlyName(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>

            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="assetType">Asset Type</InputLabel>
              <Input value={nodeUserAssetType}></Input>
              {/* <Select
                id="assetType"
                name="assetType"
                value={nodeUserAssetType}
                onChange={(e) => {
                  setNodeUserAssetType(e.target.value);
                }}
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="numOfAssets">Number of Assets:</InputLabel>
              <Input
                id="numOfAssets"
                name="numOfAssets"
                type="text"
                value={nodeUserNumberOfAssets}
                onChange={(e) => {
                  setNodeUserNumberOfAssets(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="areaSize">Area Size(in sqmt):</InputLabel>
              <Input
                id="areaSize"
                name="areaSize"
                type="text"
                value={nodeUserAreaSize}
                onChange={(e) => {
                  setNodeUserAreaSize(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="noOfKids">Number of Kids:</InputLabel>
              <Input
                id="noOfKids"
                name="noOfKids"
                type="text"
                value={nodeUserNumberOfKids}
                onChange={(e) => {
                  setNodeUserNumberOfKids(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="maleNumber">Male Gender Number:</InputLabel>
              <Input
                id="maleNumber"
                name="maleNumber"
                type="text"
                value={nodeUserMaleGenderNumber}
                onChange={(e) => {
                  setNodeUserMaleGenderNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="femaleNumber">
                Female Gender Number:
              </InputLabel>
              <Input
                id="femaleNumber"
                name="femaleNumber"
                type="text"
                value={nodeUserFemaleGenderNumber}
                onChange={(e) => {
                  setNodeUserFemaleGenderNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="overallAge">
                Overall Age(in months):
              </InputLabel>
              <Input
                id="overallAge"
                name="overallAge"
                type="text"
                value={nodeUserOverallAge}
                onChange={(e) => {
                  setNodeUserOverallAge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="farmingexperience">
                Farming Experience:
              </InputLabel>
              <Input
                id="farmingexperience"
                name="farmingexperience"
                type="text"
                value={nodeUserFarmingExperience}
                onChange={(e) => {
                  setNodeUserFarmingExperience(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="nodeType">Node Type</InputLabel>
              <Select
                id="nodeType"
                name="nodeType"
                value={nodeUserNodeType}
                onChange={(e) => {
                  setNodeUserNodeType(e.target.value);
                }}
              >
                {getSiteData &&
                  getSiteData.map((idObj, index) => (
                    <MenuItem key={`${index}`} value={idObj.sitetype}>
                      {idObj.sitetype}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="feedType">Feed Type</InputLabel>

              <Select
                id="feedType"
                name="feedType"
                value={nodeUserFeedType}
                onChange={(e) => {
                  setNodeUserFeedType(e.target.value);
                }}
              >
                <MenuItem>{nodeUserFeedType}</MenuItem>
                <MenuItem value="Pasture">Pasture</MenuItem>
                <MenuItem value="Hay">Hay</MenuItem>
                <MenuItem value="Silage">Silage</MenuItem>
                <MenuItem value="RootCrops">RootCrops</MenuItem>
              </Select>
            </FormControl>

            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="grazingType">Grazing Type</InputLabel>
              <Select
                id="grazingType"
                name="grazingType"
                value={nodeUserGrazingType}
                onChange={(e) => {
                  setNodeUserGrazingType(e.target.value);
                }}
              >
                <MenuItem value="opengrazing">Open-Grazing</MenuItem>
                <MenuItem value="closedgrazing">Closed-Grazing</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userType">Account Type</InputLabel>
              <Select
                id="accountType"
                name="accountType"
                value={nodeUserAccountType}
                onChange={(e) => {
                  setNodeUserAccountType(e.target.value);
                }}
              >
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Family">Family</MenuItem>
              </Select>
            </FormControl>

            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="breed">Breed :</InputLabel>
              <Input
                id="breed"
                name="breed"
                type="text"
                value={nodeUserBreed}
                onChange={(e) => {
                  setNodeUserBreed(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={nodeUserPhone}
                onChange={(e) => {
                  setNodeUserPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={nodeUserAddress}
                onChange={(e) => {
                  setNodeUserAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={nodeUserCity}
                onChange={(e) => {
                  setNodeUserCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={nodeUserState}
                onChange={(e) => {
                  setNodeUserState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">District :</InputLabel>
              <Input
                id="district"
                name="district"
                type="text"
                value={nodeUserDistrict}
                onChange={(e) => {
                  setNodeUserDistrict(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Pincode :</InputLabel>
              <Input
                id="pincode"
                name="pincode"
                type="text"
                value={nodeUserPincode}
                onChange={(e) => {
                  setNodeUserPincode(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={nodeUserLongitude}
                onChange={(e) => {
                  setNodeUserLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={nodeUserLatitude}
                onChange={(e) => {
                  setNodeUserLatitude(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateNodeSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateNodeDialog(!updateNodeDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Node Status */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeStatusDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeUpdate"
        >
          <DialogTitle id="consumerUserNodeUpdate">
            View Node Status
          </DialogTitle>
          <DialogContent>
            <p>
              <b>Node Name:</b>
              {setNodeName}
            </p>
            <p>
              <b>Node ID:</b>
              {nodeId}
            </p>
            <p>
              <b>Node Status:</b>
              {setNodeStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewNodeStatusDialog(!viewNodeStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Node Status */}
        {/*Node Info */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserViewNodeInfoDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeInfo"
        >
          <DialogTitle id="consumerUserNodeInfo">View Node Info</DialogTitle>
          <DialogContent>
            <p>
              <b>User Name : </b>
              {nodeUserName}
            </p>
            <p>
              <b>Node ID : </b>
              {nodeUserNodeId}
            </p>
            <p>
              <b>Account Type : </b>
              {nodeUserAccountType}
            </p>
            <p>
              <b>User Type : </b>
              {nodeUserUserType}
            </p>
            <p>
              <b>Asset Type : </b>
              {nodeUserAssetType}
            </p>
            <p>
              <b>Number Of Assets : </b>
              {nodeUserNumberOfAssets}
            </p>
            <p>
              <b>Area Size : </b>
              {nodeUserAreaSize}
            </p>
            <p>
              <b>Grazing Type : </b>
              {nodeUserGrazingType}
            </p>
            <p>
              <b>Feed Type : </b>
              {nodeUserFeedType}
            </p>
            <p>
              <b>Number Of Kids : </b>
              {nodeUserNumberOfKids}
            </p>
            <p>
              <b>Male Gender Number : </b>
              {nodeUserMaleGenderNumber}
            </p>
            <p>
              <b>Female Gender Number : </b>
              {nodeUserFemaleGenderNumber}
            </p>
            <p>
              <b>Over All Age : </b>
              {nodeUserOverallAge}
            </p>
            <p>
              <b>Breed : </b>
              {nodeUserBreed}
            </p>
            <p>
              <b>Farming Experience : </b>
              {nodeUserFarmingExperience}
            </p>
            <p>
              <b>Node Status : </b>
              {nodeUserNodeStatus}
            </p>
            <p>
              <b>Content Url : </b>
              {nodeUserContentUrl}
            </p>
            <p>
              <b>Node Type : </b>
              {nodeUserNodeType}
            </p>
            <p>
              <b>Assetvertical Id : </b>
              {nodeUserAssetverticalId}
            </p>
            <p>
              <b>Assetvertical Friendly Name: </b>
              {nodeUserAssetverticalFriendlyName}
            </p>
            <p>
              <b>Firstname : </b>
              {nodeUserFirstName}
            </p>
            <p>
              <b>Last Name : </b>
              {nodeUserLastName}
            </p>
            <p>
              <b>Email : </b>
              {nodeUserEmail}
            </p>
            <p>
              <b>Phone : </b>
              {nodeUserPhone}
            </p>
            <p>
              <b>State : </b>
              {nodeUserState}
            </p>
            <p>
              <b>City : </b>
              {nodeUserCity}
            </p>
            <p>
              <b>District : </b>
              {nodeUserDistrict}
            </p>
            <p>
              <b>Pincode : </b>
              {nodeUserPincode}
            </p>
            <p>
              <b>Latitude : </b>
              {nodeUserLatitude}
            </p>
            <p>
              <b>Longitude : </b>
              {nodeUserLongitude}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserViewNodeInfoDialog(
                  !consumerUserViewNodeInfoDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Node Info */}
        {/* Send SMS */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderSMSDialog}
          //onClose={handleClose}
          aria-labelledby="sendSMS"
        >
          <DialogTitle id="sendSMS">Send SMS</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={usernameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Mobile Number:</InputLabel>
              <Input
                id="phone"
                name="phone"
                type="text"
                value={phoneNumerValue}
                /* onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }} */
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendSMSClick();
              }}
            >
              Send
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Email */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderEmailDialog}
          //onClose={handleClose}
          aria-labelledby="sendEmail"
        >
          <DialogTitle id="sendEmail">{"Send Email"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={usernameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={emailValue}
                /* onChange={(e) => {
                  setEmailValue(e.target.value);
                }} */
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subject">Subject:</InputLabel>
              <Input
                id="subject"
                name="subject"
                type="text"
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendEmailClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <CustomPopup
        closeButton={closeSiteInfoModal}
        isOpen={setSiteInfoPopup}
        closeModal={setSiteInfoPopup}
        content={SiteInfoBody}
        title={siteInfoHeader}
      />
      <CustomPopup
        closeButton={closeSiteUpdateModal}
        isOpen={getSiteUpdatePopup}
        closeModal={getSiteUpdatePopup}
        content={SiteUpdateBody}
        title={siteUpdateHeader}
      />
      </div>
    </React.Fragment>
  );
}
