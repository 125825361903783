import React from "react";
import AccessToCare from "../../../images/cbn-network-icons/access-to-care.svg";
import SellingDoorstep from "../../../images/cbn-network-icons/doorstep-selling.svg";
import Sales from "../../../images/cbn-network-icons/easy-sales.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";
import Icon4 from "../../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../../images/gbrFarmerBenefits/save-money.svg";
import Icon6 from "../../../images/gbrFarmerBenefits/breakdown.svg";
import Icon7 from "../../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../../images/gbrFarmerBenefits/smart-farm.svg";

export default function CBNNetworkBenefit() {
  const services = [
    {
      img: AccessToCare,
      title: "Easy Access to Care Services",
      bodycopy:
        "CBN Network offers Easy Access to Care Services, ensuring enhanced produce quality through the utilization of specialized care products, facilitating increased yields and optimal crop health.",
    },
 

    {
      img: Sales,
      title: "Easy Agriproduce Sales",
      bodycopy:
        " CBN Network provides a trade service allowing farmers to sell their produce directly, fostering efficient transactions and empowering agricultural communities to thrive in the marketplace.",
    },
    {
      img: Icon5,
      title: "Access to Finance ",
      bodycopy:
        "Farmers gain access to finance through our support, facilitating investments in their operations and nurturing sustainable growth.",
    },
    
    // {
    //   image: Icon7,
    //   title: "Monetize un-utilized Capacity ",
    //   bodycopy:
    //     "Farmers can effectively monetize their previously unutilized capacity, turning resources into valuable income streams."
    // },
    {
      img: Icon8,
      title: "Access to Agri-producer facilitation services ",
      bodycopy:
        "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    },
  
   
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    {
      img: Icon7,
      title: "Easy Access ",
      bodycopy:
        "Easy access to traceable and authentic animal healthcare products, plant healthcare products, and farming equipment benefits agri-retailers by ensuring product reliability and customer trust. This accessibility helps retailers meet customer needs efficiently and build a strong reputation."
    },
   
 
  
  ];
  return (
    <div className="main-wrapper2">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Benefits</h2>
        <Row>
          {services.map((e) => {
            return (
              <Col md={4} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
