import React, { useState } from 'react';
import { Button, styled } from '@mui/material';
import LocationManagement from './Location/LocationManagement';
import RouteManagement from './Route/RouteManagement';
import TripManagement from './Trip/TripManagement';
import AddLocation from './Location/AddLocation';
import AddRoute from './Route/AddRoute';
import AddTrip from './Trip/AddTrip';

export default function TripPlannerComponent() {
  const [viewLocation, setViewLocation] = useState(true);
  const [viewRoute, setViewRoute] = useState(false);
  const [viewTrips, setViewTrips] = useState(false);

  const [viewAddLocation, setViewAddLocation] = useState(false);
  const [viewAddRoute, setViewAddRoute] = useState(false);
  const [viewAddTrip, setViewAddTrip] = useState(false);

  const locationHandler = () => {
    setViewLocation(true);
    setViewRoute(false);
    setViewTrips(false);
    setViewAddLocation(false);
    setViewAddTrip(false);
    setViewAddRoute(false);
  };

  const routeHandler = () => {
    setViewRoute(true);
    setViewLocation(false);
    setViewTrips(false);
    setViewAddRoute(false);
    setViewAddLocation(false);
    setViewAddTrip(false);
  };

  const tripHandler = () => {
    setViewTrips(true);
    setViewLocation(false);
    setViewRoute(false);
    setViewAddTrip(false);
    setViewAddRoute(false);
    setViewAddLocation(false);
  };

  const locHandler = () => {
    // console.log('click form tripplanner')
    setViewRoute(false);
    setViewLocation(false);
    setViewTrips(false);
    setViewAddLocation(true);
  };

  const rouHandler = () => {
    console.log('routehandler.......');
    setViewRoute(false);
    setViewLocation(false);
    setViewTrips(false);
    setViewAddRoute(true);
  };

  const tripHandler1 = () => {
    // console.log("trip handler.......");
    setViewAddTrip(!viewAddTrip);
    setViewRoute(false);
    setViewLocation(false);
    setViewTrips(false);
  };

  return (
    <>
      <div className='topHeadersWrapper'>
        <div className='topHeaders'>
          <Button
            color='success'
            size='large'
            variant='contained'
            onClick={() => {
              locationHandler();
            }}
          >
            Location
          </Button>
        </div>
        &nbsp;
        <div className='topHeaders'>
          <Button
            color='success'
            size='large'
            variant='contained'
            onClick={() => {
              routeHandler();
            }}
          >
            Route
          </Button>
        </div>
        &nbsp;
        <div className='topHeaders'>
          <Button
            color='success'
            size='large'
            variant='contained'
            onClick={() => {
              tripHandler();
            }}
          >
            Trip
          </Button>
        </div>
        &nbsp;
      </div>

      {viewLocation && <LocationManagement locHandler={locHandler} />}
      {viewRoute && <RouteManagement rouHandlers={rouHandler} />}
      {viewTrips && <TripManagement tripHandler1={tripHandler1} />}
      {viewAddLocation && <AddLocation locationHandler={locationHandler} />}
      {viewAddRoute && <AddRoute routeHandler={routeHandler} />}
      {viewAddTrip && <AddTrip tripHandler={tripHandler} />}
    </>
  );
}
