import React from "react";
import { Container } from "@material-ui/core";
import ABNNET from "../../../images/Agri-Entrepreneur Infographic Desktop 19-12-2023  1.gif";
import ABNNET1 from "../../../images/Agri-Entrepreneur Infographic Mobile 20-12-2023 .gif";
import AB from "../../../images/GBR Care Infographic - Desktop - 12-06-2024 C.gif";
import AB1 from "../../../images/GBR Care Infographic - Desktop - 12-06-2024 C.gif";

import ASE from "../../../images/Agri-Storage Entrepreneur Infographic Desktop 07-02-2023 C.gif";
import ASE1 from "../../../images/Agri-Storage Entrepreneur Infographic mobile 07-02-2023 C.gif";
import CM from "../../../images/Contract Manufacturers Infographic desktop 07-02-2024 C.gif";
import CM1 from "../../../images/Contract Manufacturers Infographic mobile 07-02-2024 C.gif";
import MSME from "../../../images/MSME Infographic desktop 22-20-2024 C.gif";
import MSME1 from "../../../images/MSME Infographic mobile 22-02-2024 C.gif";
import AR from "../../../images/Agri-Retailers Infographic Desktop - GBR care -  16-05-2024 N.gif";
import AR1 from "../../../images/Agri-Retailers Infographic Desktop - GBR care -  16-05-2024 N.gif";

import AI from "../../../images/Agri-Investors - GBR Care Infographic Desktop 16-05-2024  C.gif";
import AI1 from "../../../images/Agri-Investors - GBR Care Infographic Desktop 16-05-2024  C.gif";

import AT from "../../../images/Agri-Transporter Infographic Desktop 22-02-2024 C.gif";
import AT1 from "../../../images/Agri-Transporter Infographic mobile 22-02-2024 C.gif";

import Brands from "../../../images/Brands Infographic Desktop  29-02-2024.gif";
import Brands1 from "../../../images/Brand Infographic Mobile 29-02-2024  .gif";
import "./SolutionEcosystem.css";
import FarmersServices from "../../AgriProducers/Farmer/FarmersServices";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
import IP from "../../../images/Input Manufacturer - GBR care Infographic Desktop 05-03-2024 .gif";
import IP1 from "../../../images/Input Manufacturer - GBR care Infographic 04-03-2024 1 1.gif";
import LTN from "../../../images/LTN GBR Care Infographic Desktop - GBR care -  24-05-2024 C.gif";
import LTN1 from "../../../images/LTN GBR Care Infographic Desktop - GBR care -  24-05-2024 C.gif";
import OurServices from "../../OurServices/OurServices";
export default function SolutionEcosystem({ type, bodycopy }) {
  return (
    <>
      <Container
        className="solutionEcosystem-container  subSectionWrapper "
        id="ExploreABN"
      >
        <h2 className="container-title text-center ">Our Solution</h2>
        <p className="container-para text-center mx-0 mx-md-4">{bodycopy}</p>
        {type === "AB" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AB}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AB1}
              alt="Infographics"
            />
            {/* <div className=" subSectionWrapper mb-0 ">
              <TitleDescription
                titleClass="text-start"
                classname2="text-start"
                classname="mb-0 mt-0"
                title="Who are an Agri-Producers"
                description={[
                  "Farmers, also known as agricultural producers or farmers, are individuals or entities engaged in the cultivation and production of crops, livestock, and other agricultural products. These individuals or organizations play a crucial role in sustaining food systems, providing essential commodities for human consumption, and contributing to the economy. Farmers employ various techniques and technologies to maximize yields while often striving to minimize environmental impact. They face numerous challenges such as fluctuating market prices, weather uncertainties, and evolving regulatory landscapes. Despite these challenges, farmers are essential contributors to global food security and rural livelihoods, with their efforts shaping the agricultural landscape and influencing broader socio-economic dynamics."
                  // 'An agri-entrepreneur can be an aspiring agri-producer  or an organization that can get into different levels of entrepreneurships in the agri-food sector by leveraging  digital  Transformation in agri-food industry. As an Agri-Entrepreneur partner of GBR farming, the aspiring agri-producer or  organizations can truly become a "Farm-to-Factory digital enterprise"',
                  // <br className="d-block pb-4"></br>,

                  // "GBR will provide digital technology and tools for Agri-Entrepreneurs to manage and operate their farms or / and other farmers’ farms. GBR will also facilitate the Agri entrepreneurs with financial assistance for installing micro-processing units to process the farm outputs and training to ensure the effective usage of the processing units.",
                ]}
              />

              <AgriBusinessCard />
            </div> */}
            {/* <Container> */}
            {/* <GBRFarmerService/> */}
            {/* <OurServices/> */}
            <FarmersServices/>
              {/* <MoneyMakingAssets /> */}
            {/* </Container> */}
          </>
        )}

{
          (type === "IP"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={IP}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={IP1}
                alt="Infographics"
              />
            </>
          ))
        }

        {
          (type === "ASE"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={ASE}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={ASE1}
                alt="Infographics"
              />
            </>
          ))
        }
         {
          (type === "LTN"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={LTN}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={LTN1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "CM" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={CM}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={CM1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "AE" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={ABNNET}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={ABNNET1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "MSME" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={MSME}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={MSME1}
                alt="Infographics"
              />
            </>
          ))
        }


{
          (type === "AR" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AR}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AR1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "AI" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AI}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AI1}
                alt="Infographics"
              />
            </>
          ))
        }


{
          (type === "AT" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AT}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AT1}
                alt="Infographics"
              />
            </>
          ))
        }


{
          (type === "Brands" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={Brands}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={Brands1}
                alt="Infographics"
              />
            </>
          ))
        }
      </Container>
    </>
  );
}
