import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./ViewBuyersComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ViewAssetDistributorsComponent from "../ViewAssetDistributers/ViewAssetDistributersComponent";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CardContent from "@material-ui/core/CardContent";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import TrackTheOrderComponent from "../TrackTheOrder/TrackTheOrderComponent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function ViewBuyersComponent(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const orientation = isSmallScreen ? 'vertical' : 'horizontal';
  const centered = !isLargeScreen;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [sellersList, setSellersList] = useState([]);
  const [sellerUserId, setSellerUserId] = useState("");
  const [sellerUserName, setSellerUserName] = useState("");
  const [sellerFirstName, setSellerFirstName] = useState("");
  const [sellerLastName, setSellerLastName] = useState("");
  const [sellerPhone, setSellerPhone] = useState("");
  const [sellerEnterpriceId, setSellerEnterpriceId] = useState("");
  const [sellerUserType, setSellerUserType] = useState("");
  const [sellerDomain, setSellerDomain] = useState("");
  const [sellerState, setSellerState] = useState("");
  const [sellerNominee, setSellerNominee] = useState("");
  const [sellerPancard, setSellerPancard] = useState("");
  const [sellerLongitude, setSellerLongitude] = useState("");
  const [sellerLatitude, setSellerLatitude] = useState("");
  const [sellerMessage, setSellerMessage] = useState("");
  const [sellerStatus, setSellerStatus] = useState("");
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [sellerUpdteDialog, setSellerUpdteDialog] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailValue, setEmailValue] = React.useState("");
  const [messageValue, setMessageValue] = React.useState("");
  const [sellerEmailDialog, setSellerEmailDialog] = React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [sellerSMSDialog, setSellerSMSDialog] = React.useState(false);
  const [usernameValue, setUsernameValue] = React.useState("");
  const [firstNameValue, setFirstNameValue] = React.useState("");
  const [lastNameValue, setLastNameValue] = React.useState("");
  const [userTypeValue, setUserTypeValue] = React.useState("");
  const [phoneNumerValue, setPhoneNumerValue] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [searched, setSearched] = useState("");
  const [sellerViewInfoDialog, setSellerViewInfoDialog] = React.useState(false);
  const [sellerCreateDialog, setSellerCreateDialog] = useState(false);
  const [domainNameValue, setDomainNameValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [sellerDeleteDialog, setSellerDeleteDialog] = useState(false);
  const [selectedSellerUserId, setSelectedSellerUserId] = useState("");
  const [selectedSellerUserName, setSelectedSellerUserName] = useState("");
  const [buyersList, setBuyersList] = useState([]);
  const [filteredBuyers, setFilteredBuyers] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [UpdateRequestDialog, setUpdateRequestDialog] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState("");
  const [existingStatus, setSelectedExistingStatus] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [sellerMobileNumber, setSellerMobileNumber] = useState("");
  const [sellerAddress, setSellerAddress] = useState("");
  const [sellerCity, setSellerCity] = useState("");
  /* Seller Update */
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [consumerUserUserId, setConsumerUserUserId] = useState("");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [mapView, setMapView] = useState(false);

  useEffect(() => {
    getAllAssetSellers();
    getAllAssetRequests();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getAllAssetSellers = () => {
    MainService.getAssetSellers()
      .then((res) => {
        setSellersList(res);
        setFilteredSellers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getAllAssetRequests = () => {
    MainService.getAllAssetRequests(username)
      .then((res) => {
        setBuyersList(res);
        setFilteredBuyers(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const sellerViewInfoDialogClick = (seller) => {
    MainService.getSellerInfo(parentId,seller)
      .then((res) => {
        setSellerUserId(res[0].userid);
        setSellerUserName(res[0].username);
        setSellerFirstName(res[0].firstname);
        setSellerLastName(res[0].lastname);
        setSellerEnterpriceId(res[0].enterpriseid);
        setSellerUserType(res[0].usertype);
        setSellerEmail(res[0].email);
        setSellerDomain(res[0].domain);
        setSellerPhone(res[0].phone);
        setSellerAddress(res[0].address);
        setSellerCity(res[0].city);
        setSellerState(res[0].state);
        setSellerNominee(res[0].nominee);
        setSellerPancard(res[0].pancard);
        setSellerLongitude(res[0].longitude);
        setSellerLatitude(res[0].latitude);
        setSellerMessage(res[0].message);
        setSellerStatus(res[0].status);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSellerViewInfoDialog(!sellerViewInfoDialog);
  };
  const sellerUpdateDialogClick = (consumerUser) => {
    setSelectedConsumerUserName(consumerUser.username);
    setSelectedConsumerUserId(consumerUser.userid);

    setConsumerUserUserId(consumerUser.userid);
    setConsumerUserUserName(consumerUser.username);
    setConsumerUserFirstName(consumerUser.firstname);
    setConsumerUserLastName(consumerUser.lastname);
    setConsumerUserEmail(consumerUser.email);
    setConsumerUserPhone(consumerUser.phone);
    setConsumerUserAddress(consumerUser.address);
    setConsumerUserCity(consumerUser.city);
    setConsumerUserState(consumerUser.state);
    setConsumerUserNominee(consumerUser.nominee);
    setConsumerUserPancard(consumerUser.pancard);
    setConsumerUserLongitude(consumerUser.longitude);
    setConsumerUserLatitude(consumerUser.latitude);
    setConsumerUserMessage(consumerUser.message);
    setConsumerUserUserType(consumerUser.usertype);
    setConsumerUserUserDomainName(consumerUser.domain);
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const consumerUserUpdateSubmit = () => {
    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress,
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage,
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      consumerUserUpdateDataString,
      selectedConsumerUserId,
      selectedConsumerUserName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };
  const sellerDeleteClick = (selectedUser) => {
    setSelectedSellerUserName(selectedUser.username);
    setSelectedSellerUserId(selectedUser.userid);
    setSellerDeleteDialog(!sellerDeleteDialog);
  };
  const sellerDeleteSubmitClick = () => {
    MainService.deleteConsumerUser(
      username,
      selectedSellerUserName,
      selectedSellerUserId
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSellerDeleteDialog(!sellerDeleteDialog);
  };
  const sellerEmailClick = (selectedUser) => {
    setSelectedSellerUserName(selectedUser);
    setSellerEmailDialog(!sellerEmailDialog);
  };
  const sendEmailClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("email Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setSellerEmailDialog(!sellerEmailDialog);
  };

  const sendSMSClick = () => {
    let smsData = {
      sms: messageValue,
    };
    const smsDataString = JSON.stringify(smsData);
    MainService.sendSMS(phoneNumerValue, smsDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setSellerSMSDialog(!sellerSMSDialog);
  };

  const sellerSendMessageClick = (selectedUser) => {
    setSelectedSellerUserName(selectedUser);
    setSellerSMSDialog(!sellerSMSDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredBuyers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSellersList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const createsellersubmit = () => {
    let sellerData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      domain: domainNameValue,
      usertype: userTypeValue,
      phone: phoneNumerValue,
      address: addressValue,
      city: cityNameValue,
      state: stateNameValue,
      nominee: nomineeValue,
      pancard: pancardValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      message: messageValue,
      email: email,
    };
    const sellerDataString = JSON.stringify(sellerData);
    console.log("sellerData:", sellerData);
    MainService.createConsumerUser(
      sellerDataString,
      username,
      usernameValue,
      email
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSellerCreateDialog(!sellerCreateDialog);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  
  const UpdateRequestDialogClick = (id, assetId, existingStatus) => {
    setSelectedId(id);
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(existingStatus);
    setUpdateRequestDialog(!UpdateRequestDialog);
  };
  const updateRequestStatusSubmit = () => {
    let updateStatusData = {
      status: newStatusValue,
    };
    const updateStatusDataString = JSON.stringify(updateStatusData);
    MainService.updateAssetRequestStatusForAd(
      updateStatusDataString,
      selectedId,
      selectedAssetId
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateRequestDialog(!UpdateRequestDialog);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
    setMapView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
    setMapView(false);
  };
  const mapViewClick = () => {
    setListView(false);
    setGridView(false);
    setMapView(true);
  };

   /* new map1 */
   const [selected, setSelected] = useState({});
   const options = {
     imagePath:
       "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
   };
   const onSelect = (item) => {
     setSelected(item);
   };
   const mapStyles = {
     height: "100vh",
     width: "100%",
   };
 
   const defaultCenter = {
     lat: 13.093426,
     lng: 77.655773,
   };

  return (
    <>
      <div className="container" id="mainContentList">
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
            orientation={orientation}
              value={value}
              onChange={handleChange}
              aria-label="AssetOEM Options"
            >
              <Tab sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }} label="View Distributors " {...a11yProps(0)} />

              <Tab sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }} label="Sellers/Buyers" {...a11yProps(1)} />
              <Tab sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }} label="Buyers Request" {...a11yProps(2)} />
              <Tab sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }} label="Track The Order" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ViewAssetDistributorsComponent />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className="container" id="mainContentList">
              <div style={{ float: "right" }}>
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={listViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={gridViewClick}
                ></Button>
                <Button
                  startIcon={<MapIcon />}
                  size="large"
                  onClick={mapViewClick}
                ></Button>
              </div>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Seller"
                className={classes.fab}
                onClick={() => {
                  setSellerCreateDialog(!sellerCreateDialog);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <h2>Asset Sellers/Buyers</h2>
              {listView && (
                <Paper sx={{ width: "100%" }}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            List of Asset Sellers
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            User Id
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            User Name
                          </TableCell>
                          <TableCell align="center" colSpan={5}>
                            Option
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sellersList.length > 0 ? (
                          sellersList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((sellers, index) => {
                              return (
                                <TableRow hover tabIndex={-1} key={index}>
                                  <TableCell align="center">
                                    {sellers.userid}
                                  </TableCell>
                                  <TableCell align="center">
                                    {sellers.username}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellerViewInfoDialogClick(
                                          sellers.username
                                        );
                                      }}
                                    >
                                      View Info
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellerUpdateDialogClick(
                                          sellers.username
                                        );
                                      }}
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellerDeleteClick(sellers);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellerSendMessageClick(
                                          sellers.username
                                        );
                                      }}
                                    >
                                      Send Message
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        sellerEmailClick(sellers.username);
                                      }}
                                    >
                                      Send Email
                                    </Button>
                                  </TableCell>
                                  
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow align="center">
                            <TableCell align="center" colSpan={12}>
                              Asset Sellers Not Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={sellersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}

               {gridView && (
                <div className="cardStyleForDashboardItems">
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      {sellersList.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {sellersList.map(
                            (sellers, index) => (
                              <div
                                key={index}
                                className="cardStyleForDashboardItemsBody"
                              >
                                <span>
                                  <p>
                                    <b>User Id:</b>
                                    {sellers.userid}
                                  </p>
                                </span>
                                <p>
                                  <b>User Name:</b>
                                  {sellers.username}
                                </p>
                                <h4 className="cardactiontitle">View</h4>
                                <div>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      sellerViewInfoDialogClick(
                                        sellers.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                  &nbsp;&nbsp;
                                </div>
                                <div>
                                  <h4 className="cardactiontitle">Operate</h4>
                                </div>
                                <div>
                                <h4 className="cardactiontitle">Operate</h4>
                                <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  sellerUpdateDialogClick(
                                    sellers.username
                                  );
                                }}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  sellerDeleteClick(sellers);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                            </div>
                                <div>
                                  <h4 className="cardactiontitle">Actions</h4>
                                  <div>
                                    <Button
                                      type="button"
                                      variant="contained"
                                      onClick={() => {
                                        sellerEmailClick(sellers.username);
                                      }}
                                    >
                                      Send Email
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                      type="button"
                                      variant="contained"
                                      onClick={() => {
                                        sellerSendMessageClick(
                                          sellers.username
                                        );
                                      }}
                                    >
                                      Send Msssage
                                    </Button>
                                  </div>
                                  &nbsp;&nbsp;
                                  
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <h4>Asset Distributors not found.</h4>
                      )}
                    </div>
                  )}
                </div>
              )}
              {mapView && (
                <div>
                  <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={4}
                      center={defaultCenter}
                    >
                      <MarkerClusterer options={options} averageCenter={true}>
                        {(clusterer) =>
                          sellersList.map((markerObj, index) => {
                            return (
                              <Marker
                                key={index}
                                position={{
                                  lat: parseFloat(markerObj.latitude),
                                  lng: parseFloat(markerObj.longitude),
                                }}
                                clusterer={clusterer}
                                onClick={() => onSelect(markerObj)}
                              />
                            );
                          })
                        }
                      </MarkerClusterer>

                      {selected.latitude && selected.longitude && (
                        <InfoWindow
                          position={{
                            lat: parseFloat(selected.latitude),
                            lng: parseFloat(selected.longitude),
                          }}
                          clickable={true}
                          onCloseClick={() => setSelected({})}
                        >
                          <CardContent>
                            <Typography variant="body2" color="textSecondary">
                              <b>ID:</b> {selected.siteid}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <b> Name:</b>Site{selected.sitename}
                            </Typography>
                          </CardContent>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>
              )}
              {/* view info */}
              <Dialog
                fullScreen={fullScreen}
                open={sellerViewInfoDialog}
                onClose={handleClose}
                aria-labelledby="viewInfoDialog"
              >
                <DialogTitle id="viewInfoDialog">
                  {"View Seller Info"}
                </DialogTitle>
                <DialogContent>
                  <p>
                    <b>User Id:</b>
                    {sellerUserId}
                  </p>
                  <p>
                    <b>User Name:</b>
                    {sellerUserName}
                  </p>
                  <p>
                    <b>First Name:</b>
                    {sellerFirstName}
                  </p>
                  <p>
                    <b>Last Name:</b>
                    {sellerLastName}
                  </p>
                  <p>
                    <b>Enterprise Id:</b>
                    {sellerEnterpriceId}
                  </p>
                  <p>
                    <b>UserType:</b>
                    {sellerUserType}
                  </p>
                  <p>
                    <b>Email:</b>
                    {sellerEmail}
                  </p>
                  <p>
                    <b>Domain:</b>
                    {sellerDomain}
                  </p>
                  <p>
                    <b>Phone:</b>
                    {sellerPhone}
                  </p>
                  <p>
                    <b>Address:</b>
                    {sellerAddress}
                  </p>
                  <p>
                    <b>City:</b>
                    {sellerCity}
                  </p>
                  <p>
                    <b>State:</b>
                    {sellerState}
                  </p>
                  <p>
                    <b>Nominee:</b>
                    {sellerNominee}
                  </p>
                  <p>
                    <b>Pancard:</b>
                    {sellerPancard}
                  </p>
                  <p>
                    <b>Longitude:</b>
                    {sellerLongitude}
                  </p>
                  <p>
                    <b>Latitude:</b>
                    {sellerLatitude}
                  </p>
                  <p>
                    <b>Message:</b>
                    {sellerMessage}
                  </p>
                  <p>
                    <b>Status:</b>
                    {sellerStatus}
                  </p>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSellerViewInfoDialog(!sellerViewInfoDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {/* update Seller */}
              <Dialog
                fullScreen={fullScreen}
                open={consumerUserUpdateDialog}
                aria-labelledby="UpdateConsumerUser"
              >
                <DialogTitle id="UpdateConsumerUser">
                  {"Update Seller"}
                </DialogTitle>
                <DialogContent>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="username">
                      Consumer User Name :
                    </InputLabel>
                    <Input
                      disabled
                      id="username"
                      name="username"
                      type="email"
                      value={consumerUserUserName}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="firstName">First Name :</InputLabel>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={consumerUserFirstName}
                      onChange={(e) => {
                        setConsumerUserFirstName(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="lastName">Last Name :</InputLabel>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={consumerUserLastName}
                      onChange={(e) => {
                        setConsumerUserLastName(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
                    <Input
                      disabled
                      id="domainName"
                      name="domainName"
                      type="text"
                      value={consumerUserUserDomainName}
                      onChange={(e) => {
                        setConsumerUserUserDomainName(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="userType">User Type</InputLabel>
                    <Select
                      disabled
                      id="userType"
                      name="userType"
                      value={consumerUserUserType}
                    ></Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={consumerUserEmail}
                      onChange={(e) => {
                        setConsumerUserEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobileNumber">
                      Mobile Number :
                    </InputLabel>
                    <Input
                      id="mobileNumber"
                      name="mobileNumber"
                      type="text"
                      value={consumerUserPhone}
                      onChange={(e) => {
                        setConsumerUserPhone(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Address:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={consumerUserAddress}
                      onChange={(e) => {
                        setConsumerUserAddress(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="city">City :</InputLabel>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      value={consumerUserCity}
                      onChange={(e) => {
                        setConsumerUserCity(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="state">State :</InputLabel>
                    <Input
                      id="state"
                      name="state"
                      type="text"
                      value={consumerUserState}
                      onChange={(e) => {
                        setConsumerUserState(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="nominee">Nominee :</InputLabel>
                    <Input
                      id="nominee"
                      name="nominee"
                      type="text"
                      value={consumerUserNominee}
                      onChange={(e) => {
                        setConsumerUserNominee(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
                    <Input
                      id="pancard"
                      name="pancard"
                      type="text"
                      value={consumerUserPancard}
                      onChange={(e) => {
                        setConsumerUserPancard(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={consumerUserLongitude}
                      onChange={(e) => {
                        setConsumerUserLongitude(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                    <Input
                      id="latitude"
                      name="latitude"
                      type="text"
                      value={consumerUserLatitude}
                      onChange={(e) => {
                        setConsumerUserLatitude(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="messageDescription">Message:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={consumerUserMessage}
                      onChange={(e) => {
                        setConsumerUserMessage(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      consumerUserUpdateSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Delete Seller */}
              <Dialog
                fullScreen={fullScreen}
                open={sellerDeleteDialog}
                onClose={handleClose}
                aria-labelledby="deleteSeller"
              >
                <DialogTitle id="deleteSeller">Delete Seller</DialogTitle>
                <DialogContent>Are you sure want to delete?</DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      sellerDeleteSubmitClick();
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSellerDeleteDialog(!sellerDeleteDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                fullScreen={fullScreen}
                open={sellerSMSDialog}
                onClose={handleClose}
                aria-labelledby="sendSMS"
              >
                <DialogTitle id="sendSMS">Send SMS</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="sellerUsername">User Name:</InputLabel>
                    <Input
                      disabled
                      id="sellerUsername"
                      name="sellerUsername"
                      type="text"
                      value={selectedSellerUserName}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="phone">Mobile Number:</InputLabel>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      value={phoneNumerValue}
                      onChange={(e) => {
                        setPhoneNumerValue(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="messageDescription">Message:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={messageValue}
                      onChange={(e) => {
                        setMessageValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      sendSMSClick();
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSellerSMSDialog(!sellerSMSDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                fullScreen={fullScreen}
                open={sellerEmailDialog}
                onClose={handleClose}
                aria-labelledby="mailSeller"
              >
                <DialogTitle id="mailSeller">{"Send Email"}</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="sellerUsername">User Name:</InputLabel>
                    <Input
                      disabled
                      id="sellerUsername"
                      name="sellerUsername"
                      type="text"
                      value={selectedSellerUserName}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={emailValue}
                      onChange={(e) => {
                        setEmailValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="subject">Subject:</InputLabel>
                    <Input
                      id="subject"
                      name="subject"
                      type="text"
                      value={subjectValue}
                      onChange={(e) => {
                        setSubjectValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="messageDescription">Message:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={messageValue}
                      onChange={(e) => {
                        setMessageValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      sendEmailClick();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSellerEmailDialog(!sellerEmailDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                fullScreen={fullScreen}
                open={sellerCreateDialog}
                onClose={handleClose}
                aria-labelledby="craete Consumer User"
              >
                <DialogTitle id="createConsumerUser">
                  {"Create Consumer USer"}
                </DialogTitle>
                <DialogContent>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="username">
                      Consumer User Name :
                    </InputLabel>
                    <Input
                      id="username"
                      name="username"
                      type="email"
                      value={usernameValue}
                      onChange={(e) => {
                        setUsernameValue(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="firstName">First Name :</InputLabel>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={firstNameValue}
                      onChange={(e) => {
                        setFirstNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="lastName">Last Name :</InputLabel>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={lastNameValue}
                      onChange={(e) => {
                        setLastNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
                    <Input
                      id="domainName"
                      name="domainName"
                      type="text"
                      value={domainNameValue}
                      onChange={(e) => {
                        setDomainNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="userType">User Type</InputLabel>
                    <Select
                      id="userType"
                      name="userType"
                      value={userTypeValue}
                      onChange={(e) => {
                        setUserTypeValue(e.target.value);
                      }}
                    >
                      <MenuItem selected value="Executive">
                        Executive
                      </MenuItem>
                      <MenuItem value="ProductionManager">
                        Production-Manager
                      </MenuItem>
                      <MenuItem value="ProductionTeam">
                        Production-Team
                      </MenuItem>
                      <MenuItem value="Operator">Operator</MenuItem>
                      <MenuItem value="DeveloperQA">DeveloperQA</MenuItem>
                      <MenuItem value="Agent">Agent</MenuItem>
                      <MenuItem value="Farmer">Farmer</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="mobileNumber">
                      Mobile Number :
                    </InputLabel>
                    <Input
                      id="mobileNumber"
                      name="mobileNumber"
                      type="text"
                      value={phoneNumerValue}
                      onChange={(e) => {
                        setPhoneNumerValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="address">Address:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={addressValue}
                      onChange={(e) => {
                        setAddressValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="city">City :</InputLabel>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      value={cityNameValue}
                      onChange={(e) => {
                        setCityNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="state">State :</InputLabel>
                    <Input
                      id="state"
                      name="state"
                      type="text"
                      value={stateNameValue}
                      onChange={(e) => {
                        setStateNameValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="nominee">Nominee :</InputLabel>
                    <Input
                      id="nominee"
                      name="nominee"
                      type="text"
                      value={nomineeValue}
                      onChange={(e) => {
                        setNomineeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
                    <Input
                      id="pancard"
                      name="pancard"
                      type="text"
                      value={pancardValue}
                      onChange={(e) => {
                        setPancardValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                    <Input
                      id="longitude"
                      name="longitude"
                      type="text"
                      value={longitudeValue}
                      onChange={(e) => {
                        setLongitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                    <Input
                      id="latitude"
                      name="latitude"
                      type="text"
                      value={latitudeValue}
                      onChange={(e) => {
                        setLatitudeValue(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth={true} className="loginFields">
                    <p htmlFor="messageDescription">Message:</p>
                    <TextareaAutosize
                      minRows={5}
                      style={{ minwidth: 200 }}
                      value={messageValue}
                      onChange={(e) => {
                        setMessageValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      createsellersubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSellerCreateDialog(!sellerCreateDialog);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={12}
                      >
                        All Asset Requests
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Solution Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Legacy Asset Type
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Model Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Asset Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Service Package
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Asset Request Status
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={2}
                      >
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buyersList.length > 0 ? (
                      buyersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((requests, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {requests.id}
                              </TableCell>
                              <TableCell align="center">
                                {requests.solutionid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.legacyassettype}
                              </TableCell>
                              <TableCell align="center">
                                {requests.modelid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.assetid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.servicepackage}
                              </TableCell>
                              <TableCell align="center">
                                {requests.status}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    UpdateRequestDialogClick(
                                      requests.id,
                                      requests.assetid,
                                      requests.status
                                    );
                                  }}
                                >
                                  Update Request
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Asset Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={buyersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <Dialog
              fullScreen={fullScreen}
              open={UpdateRequestDialog}
              onClose={handleClose}
              aria-labelledby="updateRequest"
            >
              <DialogTitle id="updateRequest">
                Update Asset Request Status
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="id">ID :</InputLabel>
                  <Input
                    disabled
                    id="id"
                    name="id"
                    type="text"
                    value={selectedId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset ID :</InputLabel>
                  <Input
                    disabled
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={selectedId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="existingStatus">
                    Existing Asset Request Status :
                  </InputLabel>
                  <Input
                    disabled
                    id="existingStatus"
                    name="existingStatus"
                    type="text"
                    value={existingStatus}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="assetId">
                    Update Asset Request Status
                  </InputLabel>
                  <Select
                    id="assetId"
                    name="assetId"
                    value={newStatusValue}
                    onChange={(e) => {
                      setNewStatusValue(e.target.value);
                    }}
                  >
                    <MenuItem value="in-progress">In-Progress</MenuItem>
                    <MenuItem value="in-shipment">In-Shipment</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateRequestStatusSubmit();
                  }}
                >
                  Update
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setUpdateRequestDialog(!UpdateRequestDialog);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </TabPanel> 
          <TabPanel value={value} index={3}>
            <TrackTheOrderComponent />
          </TabPanel>
        </Box>
      </div>
    </>
  );
}
