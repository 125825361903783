import React from 'react';
import Icon1 from "../../images/ServiceIcons/PP.svg";
import Icon2 from "../../images/ServiceIcons/FarmerFranchise.svg";
import Icon3 from "../../images/ServiceIcons/farming.svg";
import Icon4 from "../../images/ServiceIcons/AAMC.svg";
import OurServiceCardContainer from './OurServiceCardContainer';




export default function PNServices({title, services}) {
    const ABNServices = [
   
        {
          image: Icon1,
          title: " PN-PPN ",
          bodycopy:
            "Manage factory & distributed plant production through farmer franchise network & Own QA .",
        },
       


        {
          image: Icon2,
          title: " PN-FPN ",
          bodycopy:
            "Manage Organic and Normal field production in own farms through own operators and own QA.",
        },


        {
          image: Icon3,
          title: "PN-CF ",
          bodycopy:
            " Manage Organic and Normal Agri-Assets production through contract farming. "
        },
     


        {
            image: Icon4,
            title: "  PN-AAMC",
            bodycopy:
              "Manage Agri-Assets through Agri-entrepreneurs and their operators to manage the approved farms."
          },
          
      ];
  return (
    <OurServiceCardContainer services={ABNServices} title={title}/>
  )
}
