import React from "react";
import HoneyAsset from "../../images/installation-bee-hives-new-location.jpg";
import ProcessingSupport from "../../images/processingService/banner.jpg"
import { NavLink } from "react-router-dom";
import FinancialSupport from "../../images/coin-wooden-table.jpg";

import { Row, Col } from "react-bootstrap";

export default function CollectiveServices() {
  const services = [
   

  
    {
      title: "Business Growth Service",
      image: ProcessingSupport,
      href: "/GrowthService",
      customPropValue : "Collectives"
    },
    {
      title: "Financial Service",
      image: FinancialSupport,
      href: "/financial-services",
      customPropValue: "collectives",

    },
    {
      title: "Billing Service",
      image: HoneyAsset,
      href: "/billing-service",
    },
   
  ];

  return (
<div className="main-wrapper2">
    <div className="container  for-farmer-section2 subSectionWrapper px-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        Our Services for Collectives
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          // Add customPropValue to NavLink props if it exists
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            <Col md={4} sm={6}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
    </div>
  );
}

