import React, { Component } from "react";
import "./GbrFormsComponent.css";
import ReactPaginate from "react-paginate";
import $, { event } from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import { Col, Row } from "react-bootstrap";
import { Container } from "@material-ui/core";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import Advertisement from "../gbrEngage/Advertisement/Advertisement";
import AdvisaryComponent from "../gbrEngage/Advisary/Advisary";
import BuyInput from "../gbrEngage/BuyInput/BuyInput";
import GovtSchemesComponent from "../gbrEngage/GovtSchemes/GovtSchemes";
import PriceDiscovery from "../gbrEngage/PriceDiscovery/PriceDiscovery";
import SiteUpdates from "../gbrEngage/SiteUpdates/SiteUpdates";
import InputSupplies from "../gbrEngage/InputSupplies/InputSupplies";
import Club from "../Club/Club";
import ClubUpdates from "../gbrEngage/ClubUpdates/ClubUpdates";
import ClubMainPage from "../Club/ClubMainPage";
import AdvisaryCBNComponent from "../gbrEngage/Advisary/AdvisaryCBN";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import JobsComponent from "../gbrEngage/Jobs/Jobs";
import AgriAssets from "../ExploreProducts/AgriAssets";
import ProducersBenefits from "../WebSite/ProducersBenefits/ProducersBenefits";
import ProducersJourney from "../WebSite/ProducersJourney/ProducersJourney";
import SiteWeatherManagement from "../SiteWeatherManagement/SiteWeatherManagement";
import IAAProducers from "../WebSite/IAATab/IAAProducers";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import TransporterImg from "../../images/gbrCBN/CBNImg7.webp";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import StorageProvider from "../gbrEngage/StorageProvider/StorageProvider"
class GbrFormsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumeruserparent: "consumer2",
      tenders: [],
      tendersFilter: [],
      offset: 0,
      tensdersPageCount: 1,
      perPage: 6,
      currentPage: 0,
      checked: false,
      postCategoryIdVlaue: "",
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      foodProcessor: ["FOOD-PROCESSOR"],
      Machinery: ["PMC", "FMC"],
      ahp:["AHSP"]
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.postCategoryIdValue();
    this.adminsDropdownClick();
  }

  postCategoryIdValue() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "firstttttttttt");
        const postid = result.categoryList.filter((data) => {
          return data.categoryname === "SITE-UPDATES";
        });
        this.viewAllSiteUpdates(postid[0].categoryid);
        this.setState({
          postCategoryIdVlaue: postid[0].categoryid,
        });
      })
      .catch((err) => console.log("err", err));
  }

  viewAllSiteUpdates(postid) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}category/${postid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      });
  }

  adminsDropdownClick() {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}FOOD-PROCESSOR`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tendersFilter: result.users,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  organizationsDropdownClick() {
    $("#dropdownOrganizations").toggle();
  }

  organizationsOnClick(org) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${org}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          tenders: result.contentList,
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownOrganizations").hide();
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => { }
    );
  };
  /*End of pagination */

  render() {
    const { tenders } = this.state;
    const siteUpdatesList = tenders.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
<div className = "subSectionWrapper ">
        <div className=" update-container-wrapper">
          {/* <h1 className="text-center container-title">
              Farmers Engagement Services
            </h1> */}
          <h3 class="container-title text-center">Advertisements</h3>


          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image19"
            descriptionTitle="
            Machinery Providers"
            description="
            GBR Care collaborates with leading machinery providers to support farmers by showcasing their advertisements. Through this partnership, GBR Care ensures that farmers are aware of the latest agricultural machinery and technology, helping them make informed decisions to enhance their productivity and efficiency. By facilitating this connection, GBR Care plays a crucial role in bridging the gap between innovative machinery providers and the farming community, fostering growth and development within the agricultural sector.
            "
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"

        //     buttoncont7='Sign up'

        // onClick={() => {
        //   localStorage.setItem("userType", "FMC");
        // }}              href7="/auth/3/signup"
        //     href4="http://abn.gbrfarming.com/"
        //     buttoncont1="Sign In"
        //     userType="Farmers-Machinery-Provider"
          />
          <AdvertisementCBN />

          <h3 class="container-title text-center">Advisory</h3>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image16"
            description="Advisors are welcome to join GBR Care and share their expertise on a range of topics, including animal husbandry, plant cultivation, and agricultural machinery. By contributing valuable advice and insights, advisors help enrich the knowledge base available to farmers, aiding them in improving their practices and achieving better outcomes. This collaborative effort ensures that the farming community benefits from diverse, expert perspectives, fostering a more informed and productive agricultural environment.
            "
            descriptionTitle="
            Farmers' Advisors"
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"

            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Trainer"
          />
          <AdvisaryCBNComponent />

          <h3 class="container-title text-center">Buy Inputs</h3>

          <HomeSubSection
                      className="rowReverseAbout--mod borderedCards borderedCards--mod"

            className3="image20"
            description="Input manufacturers are essential partners of GBR Care. GBR Care supports these manufacturers by purchasing their products, including animal and plant healthcare items, and distributing them to farmers. This partnership ensures that farmers have access to high-quality inputs necessary for maintaining the health and productivity of their livestock and crops, ultimately enhancing agricultural outcomes and sustainability."
            descriptionTitle="
            Input Manufacturers"
            description1= "Nutrition Companies | Agri Input Companies"
            
        //     buttoncont7='Sign up'

        // onClick={() => {
        //   localStorage.setItem("userType", "AHSP");
        // }}              href7="/auth/3/signup"
        //     // href5="http://abn.gbrfarming.com/auth/undefined/signup"
        //     // buttoncont2="Register"
        //     href4="http://abn.gbrfarming.com/"
        //     buttoncont1="Sign In"
          />

          {/* <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image24"
            description="At GBR Farming, we've built a supportive network where renowned agri-input companies come together to provide essential solutions for farming animals and fields. This collaborative effort ensures Agri-Entrepreneur or Agri-Retailer receive expert guidance and high-quality agri-input options, boosting the health, productivity, and sustainability of their farms.
            "
            descriptionTitle="
            Agri Input Companies"
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"
            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "AIC");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Agri Input Companies"
          /> */}

          <BuyInput />

          {/* <h3 class="container-title text-center">Machinery</h3>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image28"
            description="Within the vibrant GBR Farming community, we join forces with renowned agri machinery providers to deliver essential solutions tailored for farms. This cooperative effort ensures farmers benefit from expert guidance and superior agri machinery options, ultimately enhancing the efficiency, productivity, and sustainability of their farming operations."
            descriptionTitle="
  Agri Machinery"
            href5="http://abn.gbrfarming.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
          />

          <HomeSubSection
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image29"
            description="Within the dynamic GBR Farming community, we partner with leading processing machinery providers to offer essential solutions customized for farms. This collaborative effort ensures farmers gain access to expert guidance and high-quality processing machinery options, resulting in improved efficiency, productivity, and sustainability for their farming operations."
            descriptionTitle="
  Processing Machinery"
            href5="http://abn.gbrfarming.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Agri Input Companies"
          />

          <Container className="mt-4">
            <ProcessedProducts
              type="MACHINERY"
              userType={this.state.Machinery}
              cbn="true"

            />
          </Container> */}
          <h3 class="container-title text-center">Govt Schemes</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image6"
            descriptionTitle="
            Government Organizations"
            description="Government organizations are key partners of GBR Care. GBR Care assists these organizations by publishing and promoting their schemes and programs to farmers. This facilitation ensures that farmers are well-informed about government initiatives designed to support and enhance agricultural practices, thereby enabling them to take full advantage of available resources and opportunities for development and growth."
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"
            // buttoncont7='Sign up'

            // onClick={() => {
            //   localStorage.setItem("userType", "GovtDept");
            // }}              href7="/auth/3/signup"
            // href4="http://abn.gbrfarming.com/"
            // buttoncont1="Sign In"
            // userType="Farmers-Machinery-Provider"
          />
          <GovtSchemesComponent />
          <div className="mb-lg-5 mb-4">
            <JobsComponent />
          </div>
          <div className="mb-lg-5 mb-4">
            <PriceDiscovery />
          </div>
          <div className="mb-lg-5 mb-4">
            <ClubUpdates />
          </div>



          {/* <div id="tendersContainer" className="container-fluid">
            <h3 id="AgriAssets" className="container-title">
              {" "}
              Agri Assets
            </h3>
            <Container>
              <ProcessedProducts
                type="AGRI-ASSETS"
                userType={this.state.foodProcessor}
                cbn="true"
              />



            </Container>
          </div> */}


          {/* <h3 class="container-title text-center">Storage</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image23"
            descriptionTitle="
            Storage Providers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures farmers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"
            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "StorageCompany");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Storage Providers"
          />
          <StorageProvider /> */}
          {/* <h3 class="container-title text-center">Transporters</h3>

          <HomeSubSection
            className1="aboutBgGrey mb-3"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            description="Teaming up with proficient transporters, we facilitate the seamless movement of agricultural produce. This partnership ensures farmers have access to efficient transportation solutions, enabling the smooth and timely delivery of their goods to the intended destinations."
            descriptionTitle="
            Transporters"
            src={TransporterImg}
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"
            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "Driver");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="LogisticProvider"
          />

          <h3 class="container-title text-center">Contaract Maufacturers</h3>

          <HomeSubSection
            className1="aboutBgGrey mb-3"
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image33"
            descriptionTitle="Food Processor"
            description="GBR Farming has forged a strategic partnership with a food processor to facilitate contract manufacturing. This collaboration aims to leverage the strengths of both entities, with GBR Farming contributing its agricultural expertise and the food processor bringing manufacturing capabilities. Together, they seek to enhance the efficiency and quality of food production, contributing to a more robust and streamlined agricultural value chain.
            "
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"
            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Food-Processor"
          /> */}

<div className="mb-lg-5 mb-4">
<h3 class="container-title text-center">Weather Updates</h3>

<HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image16"
            description="Farmers can join GBR Care and provide their site details to access tailored weather information for their specific locations. This feature helps farmers stay informed about local weather conditions, enabling them to make timely and informed decisions regarding their farming activities, ultimately improving their productivity and resilience to weather-related challenges.
            "
            descriptionTitle="
            Farmers"
            // href5="http://abn.gbrfarming.com/auth/undefined/signup"
            // buttoncont2="Register"

            buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR");
        }}              href7="/auth/3/signup"
            href4="http://abn.gbrfarming.com/"
            buttoncont1="Sign In"
            userType="Trainer"
          />
            <SiteWeatherManagement />
          </div>
        </div>
        </div>

        {/* 
        <div className="MSMEBenfitJourney">
          <ProducersBenefits />
          <ProducersJourney />
        </div> */}
        {/* <FooterComponent /> */}
      </>
    );
  }
}

export default GbrFormsComponent;
