import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExploreTechnologyBanner from "./ExploreTechnologyBanner";
import ExploreTechnologySection2 from "./ExploreTechnologySection2";
import "./ExploreTechnology.css";

export default function ExploreTechnology() {
  return (
    <>
      <HeaderComponent />
      <ExploreTechnologyBanner />
      <div className="subSectionWrapper">
        {/* <ExploreTechnologySection1 /> */}
        <ExploreTechnologySection2 />
      </div>
      <FooterComponent />
    </>
  );
}
