import axios from 'axios';
import { saveAs } from 'file-saver';
const BASE_URL = 'http://localhost:8000';

const generateBoundary = () => {
  return '--------------------------' + Math.random().toString(16).substring(2);
};

const quotationPostRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,
  },
});

export const generateQuotation = async (
  url,
  formData,
  setQuotationFileList,
  quotationData
) => {
  console.log('skfjksdf', quotationData);
  try {
    const response = await quotationPostRequest.post(url, formData, {
      responseType: 'blob',
    });

    const contentDispositionHeader = response.headers['content-disposition'];
    const filename = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1]
      : 'document.pdf';

    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, quotationData.quotationId);

    const tempQuotationData = {
      ...quotationData,
      viewLink: blob,
    };

    // Update the state with the previous quotationFileList
    setQuotationFileList((prevList) => [...prevList, tempQuotationData]);

    // return data;
    return response.status;
  } catch (error) {
    console.log(error);
  }
};

export const placeOrderRequestApi = async (
  url,
  formData,
  purchaseOrderDetils,
  buyerDetails,
  setPurchaseOrderList
) => {
  try {
    const response = await quotationPostRequest.post(url, formData, {
      responseType: 'blob',
    });

    const contentDispositionHeader = response.headers['content-disposition'];
    const filename = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1]
      : 'document.pdf';

    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, purchaseOrderDetils.purchaseOrderID);

    const tempPurchaseOrderList = {
      buyerName: buyerDetails.buyerName,
      buyer_email: buyerDetails.buyer_email,
      quotationId: purchaseOrderDetils[0].purchaseOrderID,
      viewLink: blob,
    };

    // Update the state with the previous quotationFileList
    setPurchaseOrderList((prevList) => [...prevList, tempPurchaseOrderList]);

    // return data;
    return response.status;
  } catch (error) {
    console.log(error);
  }
};

//post proforma invoice to server

export const placeProformaInvoiceApi = async (
  url,
  formData,
  proformaInvoiceDetails,
  buyerDetails,
  setProformaInvoiceList
) => {
  try {
    const response = await quotationPostRequest.post(url, formData, {
      responseType: 'blob',
    });

    const contentDispositionHeader = response.headers['content-disposition'];
    const filename = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1]
      : 'document.pdf';

    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, proformaInvoiceDetails.invNo);

    const tempProformaInvoiceList = {
      buyerName: buyerDetails.buyerName,
      buyer_email: buyerDetails.buyer_email,
      invNo: proformaInvoiceDetails.invNo,
      viewLink: blob,
    };

    // Update the state with the previous quotationFileList
    setProformaInvoiceList((prevList) => [
      ...prevList,
      tempProformaInvoiceList,
    ]);

    // return data;
    return response.status;
  } catch (error) {
    console.log(error);
  }
};

//genrate invoice from api
export const genrateInvoiceApi = async (
  url,
  formData,
  proformaInvoiceDetails,
  buyerDetails,
  setInvoiceList
) => {
  try {
    const response = await quotationPostRequest.post(url, formData, {
      responseType: 'blob',
    });

    const contentDispositionHeader = response.headers['content-disposition'];
    const filename = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1]
      : 'document.pdf';

    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, proformaInvoiceDetails.invNo);

    const tempInvoiceList = {
      buyerName: buyerDetails.buyerName,
      buyer_email: buyerDetails.buyer_email,
      invNo: proformaInvoiceDetails.invNo,
      viewLink: blob,
    };

    // Update the state with the previous quotationFileList
    setInvoiceList((prevList) => [...prevList, tempInvoiceList]);

    // return data;
    return response.status;
  } catch (error) {
    console.log(error);
  }
};
