import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function DashboardForQAComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleResourcesList, setRoleResourcesList] = useState([]);

  const [roleResourceAssetsList, setRoleResourceAssetsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");

  /*End CreatorDeveloperQA*/
  /*DeleteDeveloperQA*/

  /* View */
  const [listViewAssets, setListViewAssets] = useState(false);
  const [listViewSites, setListViewSites] = useState(false);
  const [gridViewAssets, setGridViewAssets] = useState(false);
  const [gridViewSites, setGridViewSites] = useState(true);

  const [resourceId, setResourceId] = useState("");
  const [resourceName, setResourceName] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");
  const [selectedExistingStatus, setSelectedExistingStatus] = useState("");

  /* Feedback end */
  /* Compliance Site and Asset*/
  const [siteCompliancePopup, setSiteCompliancePopup] = useState(false);
  const [assetCompliancePopup, setAssetCompliancePopup] = useState(false);
  const [siteReportPopup, setSiteReportPopup] = useState(false);
  const [assetReportPopup, setAssetReportPopup] = useState(false);
  const [assetUpdateStatusPopup, setAssetUpdateStatusPopup] = useState(false);
  const [updateAssetStatusValue, setUpdateAssetStatusValue] = useState("");
  const [operationalStatusValue, setOperationalStatusValue] = useState("");
  const [reportDescriptionValue, setReportDescriptionValue] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [procesHealthStatusValue, setProcesHealthStatusValue] = useState("");
  const [assetHealthStatusValue, setAssetHealthStatusValue] = useState("");
  const [weightValue, setWeightValue] = useState(false);
  const [temperatureValue, setTemperatureValue] = useState(false);
  const [humidityValue, setHumidityValue] = useState(false);
  const [buzzingValue, setBuzzingValue] = useState(false);
  const [flightValue, setFlightValue] = useState(false);
  const [watchmenAvailabilityValue, setWatchmenAvailabilityValue] =
    useState(false);
  const [firstAidAvailabilityValue, setFirstAidAvailabilityValue] =
    useState(false);
  const [sitePPMValue, setSitePPMValue] = useState(false);
  const [cleaningAvailabilityValue, setCleaningAvailabilityValue] =
    useState(false);
  const [feedingAvailabilityValue, setFeedingAvailabilityValue] =
    useState(false);
  const [grazingValue, setGrazingValue] = useState(false);
  const [waterAvailabilityValue, setWaterAvailabilityValue] = useState(false);
  //const [assetComplianceData, setAssetComplianceData] = useState(JSON.stringify({}));
  /* Site Patrol */
  const [sitePatrolPopup, setSitePatrolPopup] = useState("");
  const [mobileNumberValue, setMobileNumberValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");

  /* Asset Patrol */
  const [assetPatrolPopup, setAssetPatrolPopup] = useState("");
  const [selectedAssetFriendlyName, setSelectedAssetFriendlyName] =
    useState("");

  //const [timeStamp, setTimeStamp] = useState(Math.floor(Date.now() /1000));
  const [timeStamp, setTimeStamp] = useState("");

  const getTimeStamp = () => {
    var date = new Date();
    var current_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    var date_time = current_date + " " + current_time;
    setTimeStamp(date_time);
    return date_time;
  };

  useEffect(() => {
    getRoleNameofOperator();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoleNameofOperator = () => {
    MainService.getRoleNameofOperator(parentId, username)
      .then((res) => {
        console.log("role resp:", res);

        getRoleResources(res, "Grid");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getRoleResources = (roleName, Type) => {
    if (Type === "List") {
      setGridViewSites(false);
      setListViewSites(true);

      setGridViewAssets(false);
      setListViewAssets(false);
    } else if (Type === "Grid") {
      setGridViewSites(true);
      setListViewSites(false);

      setGridViewAssets(false);
      setListViewAssets(false);
    }
    MainService.getRoleResources(parentId, roleName)
      .then((res) => {
        if (res.role.resources.length > 0) {
          console.log("role resources:", res);
          setRoleResourcesList(res.role.resources);
        } else {
          setRoleResourcesList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getRoleResourceAssets = (resourceId) => {
    MainService.getRoleResourceAssets(parentId, resourceId)
      .then((res) => {
        console.log("getRoleResourceAssets", res);
        setRoleResourceAssetsList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  const sitesListViewClick = () => {
    setListViewSites(true);
    setGridViewSites(false);
    setListViewAssets(false);
    setGridViewAssets(false);
  };
  const sitesGridViewClick = () => {
    setGridViewSites(true);
    setListViewSites(false);

    setGridViewAssets(false);
    setListViewAssets(false);
  };

  const assetsListViewClick = (resourceId) => {
    setResourceId(resourceId);
    getRoleResourceAssets(resourceId);

    setGridViewSites(false);
    setListViewSites(false);

    setGridViewAssets(false);
    setListViewAssets(true);
  };
  const assetsGridViewClick = (resourceId) => {
    setResourceId(resourceId);
    getRoleResourceAssets(resourceId);

    setGridViewSites(false);
    setListViewSites(false);

    setGridViewAssets(true);
    setListViewAssets(false);
  };

  const backToHome = () => {
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
  };
  const backToSites = () => {
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
  };

  const siteComplianceClick = (siteId) => {
    setResourceId(siteId);

    setSiteCompliancePopup(!siteCompliancePopup);
  };
  const siteComplianceSubmitClick = () => {
    let siteComplianceData = {
      developerqa: username,
      siteid: resourceId,
      "Does site have Grazing?": grazingValue.toString(),
      "Does Water availability of the site adequate?":
        waterAvailabilityValue.toString(),
      "Does Temparature of the site adequate?":
        watchmenAvailabilityValue.toString(),
      "Does Humidity of the site adequate?":
        firstAidAvailabilityValue.toString(),
      "Does PPM of the site adequate?": sitePPMValue.toString(),
      "Process health status": procesHealthStatusValue,
      timestamp: getTimeStamp(),
    };
    const stringifyData = JSON.stringify(siteComplianceData);
    MainService.recordCompliance(stringifyData, username, "Site")
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteCompliancePopup(!siteCompliancePopup);
  };
  const siteReportsClick = (siteId) => {
    setResourceId(siteId);
    setSiteReportPopup(!siteReportPopup);
  };
  const siteReportSubmitClick = () => {
    let reportData = {
      solutionid: parentId,
      siteid: resourceId,
      operationalstatus: operationalStatusValue,
      reportdescription: reportDescriptionValue,
      timestamp:getTimeStamp()
    };
    MainService.recordReport(reportData, username, "Site")
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteReportPopup(!siteReportPopup);
  };
  const sitePatrolClick = (siteId, siteName) => {
    setResourceId(siteId);
    setResourceName(siteName);
    setSitePatrolPopup(!sitePatrolPopup);
  };
  const sitePatrolSubmitClick = () => {
    let sitePatrolData = {
      siteid: resourceId,
      friendlyname: resourceName,
      parentid: parentId,
      userid: username,
      mobilenumber: mobileNumberValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: reportDescriptionValue,
    };
    MainService.addSitePatrolingDetails(sitePatrolData)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSitePatrolPopup(!sitePatrolPopup);
  };
  const assetPatrolClick = (asset) => {
    setSelectedAssetId(asset.assetId);
    setSelectedAssetFriendlyName(asset.friendlyname);
    setAssetPatrolPopup(!assetPatrolPopup);
  };
  const assetPatrolSubmitClick = () => {
    let assetPatrolData = {
      assetid: selectedAssetId,
      //assetfriendlyname: selectedAssetFriendlyName,
      parentid: parentId,
      userid: username,
      mobilenumber: mobileNumberValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      description: reportDescriptionValue,
    };
    MainService.addAssetPatrolingDetails(assetPatrolData)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetPatrolPopup(!assetPatrolPopup);
  };
  const assetComplianceClick = (asset) => {
    setSelectedAssetType(asset.assetType);
    setSelectedAssetId(asset.assetId);
    console.log("selectedAssetType1", selectedAssetType);
    setAssetCompliancePopup(!assetCompliancePopup);
  };

  const assetComplianceSubmitClick = () => {
    console.log("selectedAssetType2", selectedAssetType);
    if (selectedAssetType === "BEE") {
      let assetComplianceDataForBee = {
        developerqa: username,
        siteid: resourceId,
        assetid: selectedAssetId,
        "Does Weight of hive adequate?": weightValue.toString(),
        "Does Temperature inside and outside of hive adequate?":
          temperatureValue.toString(),
        "Does Humidity inside and outsite of hive adequate?":
          humidityValue.toString(),
        "Does Buzzing inside and outside of hive adequate?":
          buzzingValue.toString(),
        "Does Flight activity around the hive?": flightValue.toString(),
        "Asset health status": assetHealthStatusValue,
        timestamp: getTimeStamp(),
      };
      MainService.recordCompliance(
        JSON.stringify(assetComplianceDataForBee),
        username,
        "Asset"
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      //setAssetComplianceData(JSON.stringify(assetComplianceDataForBee));
    } else if (
      selectedAssetType === "Sheep" ||
      selectedAssetType === "Goat" ||
      selectedAssetType === "Milking-Cow"
    ) {
      let assetComplianceDataForSheep = {
        developerqa: username,
        siteid: resourceId,
        assetid: selectedAssetId,
        "Does Weight of the Animal is adequate?": weightValue.toString(),
        "Does Grazing of shed is adequate?": grazingValue.toString(),
        "Does Water availability of the shed is adequate?":
          waterAvailabilityValue.toString(),
        "Does Watchmen  availability for the shed?":
          watchmenAvailabilityValue.toString(),
        "Does First-Aid Kit avilability in shed?":
          firstAidAvailabilityValue.toString(),
        "Does Cleaning avilability in shed?":
          cleaningAvailabilityValue.toString(),
        "Does Feeding avilability in shed?":
          feedingAvailabilityValue.toString(),
        "Asset health status": assetHealthStatusValue,
        timestamp: getTimeStamp(),
      };
      console.log("assetComplianceDataForSheep", assetComplianceDataForSheep);
      MainService.recordCompliance(
        JSON.stringify(assetComplianceDataForSheep),
        username,
        "Asset"
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });

      //setAssetComplianceData(JSON.stringify(assetComplianceDataForSheep));
    }
    // console.log("compliance data1:",assetComplianceData);

    setAssetCompliancePopup(!assetCompliancePopup);
  };
  const assetReportClick = (asset) => {
    setSelectedAssetId(asset.assetId);
    setSelectedModelId(asset.modelId);
    setAssetReportPopup(!assetReportPopup);
  };
  const assetReportSubmitClick = () => {
    let reportData = {
      solutionid: parentId,
      siteid: resourceId,
      modelid: selectedModelId,
      assetid: selectedAssetId,
      operationalstatus: operationalStatusValue,
      reportdescription: reportDescriptionValue,
    };
    MainService.recordReport(reportData, username, "Asset")
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetReportPopup(!assetReportPopup);
  };

  const assetUpdateStatusClick = (assetId, status) => {
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(status);
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const assetUpdateStatusSubmitClick = () => {
    const assetStatusData = {
      status: updateAssetStatusValue,
    };
    const assetStatusDataStringify = JSON.stringify(assetStatusData);

    MainService.assetStatusUpdate(selectedAssetId, assetStatusDataStringify)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const handleClose = () => {};

  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfoDeveloperQA***************/

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          {(listViewSites || gridViewSites) && (
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={sitesListViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={sitesGridViewClick}
                ></Button>
                {/* <Button
                  startIcon={<MapIcon />}
                  size="large"
                  onClick={sitesMapViewClick}
                ></Button> */}
              </div>
            </div>
          )}
        </div>

        {/* Sites */}
        {listViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              onClick={() => {
                backToHome();
              }}
            >
              Back
            </Button>
            <div className="MuiGrid-root">
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {roleResourcesList ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 350 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={11} align="center">
                                List Of Sites
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>

                              <TableCell align="center" colSpan={5}>
                                View Assets
                              </TableCell>
                              <TableCell align="center" colSpan={5}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {roleResourcesList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((site, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {site.resourceid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {site.resourcename}
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      <Button
                                        startIcon={<ListIcon />}
                                        size="large"
                                        onClick={() => {
                                          assetsListViewClick(site.resourceid);
                                        }}
                                      ></Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        startIcon={<GridIcon />}
                                        size="large"
                                        onClick={() => {
                                          assetsGridViewClick(site.resourceid);
                                        }}
                                      ></Button>
                                    </TableCell>

                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteComplianceClick(site.resourceid);
                                        }}
                                      >
                                        Site Compliance
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteReportsClick(site.resourceid);
                                        }}
                                      >
                                        Site Report
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          sitePatrolClick(
                                            site.resourceid,
                                            site.resourcename
                                          );
                                        }}
                                      >
                                        Site Patrolling
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={roleResourcesList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Site List Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {gridViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>List Of Sites</h3>

            <div className="cardStyleForDashboardItems">
              {roleResourcesList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {roleResourcesList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Site Id:</b>
                          {site.resourceid}
                        </p>
                        <p>
                          <b>Site Name:</b>
                          {site.resourcename}
                        </p>
                        <h4 className="cardactiontitle">View Assets</h4>
                        <div>
                          <Button
                            title="Assets List View"
                            startIcon={<ListIcon />}
                            size="large"
                            onClick={() => {
                              assetsListViewClick(site.resourceid);
                            }}
                          ></Button>

                          <Button
                            title="Assets Grid View"
                            startIcon={<GridIcon />}
                            size="large"
                            onClick={() => {
                              assetsGridViewClick(site.resourceid);
                            }}
                          ></Button>
                        </div>
                        <h4 className="cardactiontitle">Options</h4>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              siteComplianceClick(site.resourceid);
                            }}
                          >
                            Site Compliance
                          </Button>{" "}
                          &nbsp;
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              siteReportsClick(site.resourceid);
                            }}
                          >
                            Site Report
                          </Button>
                        </div>
                        &nbsp;
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              sitePatrolClick(
                                site.resourceid,
                                site.resourcename
                              );
                            }}
                          >
                            Site Patrolling
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Site Found.</h4>
              )}
            </div>
          </Box>
        ) : null}

        {/* {mapViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <Button
              variant="contained"
              onClick={() => {
                backToHome();
              }}
            >
              Back
            </Button>
            {roleResourcesList.length > 0 ? (
              <OperatorsGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <h4>No sites Found</h4>
            )}
          </Box>
        ) : null} */}
        {/* End of Sites */}

        {/* Assets */}
        {listViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <br />
            <div className="MuiGrid-root">
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {roleResourceAssetsList.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 350 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={18} align="center">
                                List Of "{resourceId}" Assets
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Id
                              </TableCell>
                              <TableCell align="center" colSpan={6}>
                                View
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {roleResourceAssetsList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((asset, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {asset.siteId}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.assetType}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.modelId}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.assetId}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetComplianceClick(asset);
                                        }}
                                      >
                                        Compliance
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetReportClick(asset);
                                        }}
                                      >
                                        Report
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetPatrolClick(asset);
                                        }}
                                      >
                                        Patrolling
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetUpdateStatusClick(
                                            asset.assetId,
                                            asset.assetStatus
                                          );
                                        }}
                                      >
                                        Update Status
                                      </Button>
                                    </TableCell>
                                    <TableCell />
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={roleResourceAssetsList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Asset List Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {gridViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3 style={{ textAlign: "center" }}>
              List Of "{resourceId}" Assets
            </h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              {roleResourceAssetsList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {roleResourceAssetsList.map((asset, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <p>
                        <b>Site Id:</b>
                        {asset.siteId}
                      </p>
                      <p>
                        <b>Asset Type:</b>
                        {asset.assetType}
                      </p>
                      <p>
                        <b>Model Id:</b>
                        {asset.modelId}
                      </p>
                      <p>
                        <b>Asset Id:</b>
                        {asset.assetId}
                      </p>
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetComplianceClick(asset);
                          }}
                        >
                          Compliance
                        </Button>{" "}
                        &nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetReportClick(asset);
                          }}
                        >
                          Report
                        </Button>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetPatrolClick(asset);
                          }}
                        >
                          Patrolling
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetUpdateStatusClick(
                              asset.assetId,
                              asset.assetStatus
                            );
                          }}
                        >
                          Update Status
                        </Button>{" "}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h4>No Assets Found.</h4>
                </div>
              )}
            </div>
          </Box>
        ) : null}

        {/* {mapViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            {roleResourceAssetsList.length > 0 ? (
              <AssetsGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <div>
                <h4>No Assets Found</h4>
              </div>
            )}
          </Box>
        ) : null} */}

        {/* End of Assets */}

        {/* Site Compliance */}
        <Dialog
          fullScreen={fullScreen}
          open={siteCompliancePopup}
          onClose={handleClose}
          aria-labelledby="siteCompliance"
        >
          <DialogTitle id="siteCompliance">{"Add Site Compliance"}</DialogTitle>
          <DialogContent>
            <h4>Site Compliance</h4>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceId}
              />
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={grazingValue}
                  onChange={(e) => {
                    setGrazingValue(e.target.checked);
                  }}
                />
              }
              label="Does site have Grazing?"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={waterAvailabilityValue}
                  onChange={(e) => {
                    setWaterAvailabilityValue(e.target.checked);
                  }}
                />
              }
              label="Does Water availability of the site adequate??"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={temperatureValue}
                  onChange={(e) => {
                    setTemperatureValue(e.target.checked);
                  }}
                />
              }
              label="Does Temparature of the site adequate?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={humidityValue}
                  onChange={(e) => {
                    setHumidityValue(e.target.checked);
                  }}
                />
              }
              label="Does Humidity of the site adequate?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sitePPMValue}
                  onChange={(e) => {
                    setSitePPMValue(e.target.checked);
                  }}
                />
              }
              label="Does PPM of the site adequate?"
            />

            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Process Health Status :</InputLabel>
              <Select
                id="selectedModel"
                value={procesHealthStatusValue}
                onChange={(e) => {
                  setProcesHealthStatusValue(e.target.value);
                }}
                label="Process Health Status"
              >
                <MenuItem key="Severe" value="Severe">
                Severe
                </MenuItem>
                <MenuItem key="Healthy" value="Healthy">
                Healthy
                </MenuItem>
                <MenuItem key="Moderate" value="Moderate">
                Moderate
                </MenuItem>
                <MenuItem key="Unhealthy" value="Unhealthy">
                Unhealthy
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                siteComplianceSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setSiteCompliancePopup(!siteCompliancePopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Compliance */}
        <Dialog
          fullScreen={fullScreen}
          open={assetCompliancePopup}
          onClose={handleClose}
          aria-labelledby="assetCompliance"
        >
          <DialogTitle id="assetCompliance">
            {"Add Asset Compliance"}
          </DialogTitle>
          <DialogContent>
            <h4>Asset Compliance</h4>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Asset Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={selectedAssetId}
              />
            </FormControl>
            {selectedAssetType && selectedAssetType === "BEE" ? (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weightValue}
                      onChange={(e) => {
                        setWeightValue(e.target.name);
                      }}
                      name="Does Weight of hive adequate?"
                    />
                  }
                  label="Does Weight of hive adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={temperatureValue}
                      onChange={(e) => {
                        setTemperatureValue(e.target.name);
                      }}
                      name="Does Temperature inside and outside of hive adequate?"
                    />
                  }
                  label="Does Temperature inside and outside of hive adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={humidityValue}
                      onChange={(e) => {
                        setHumidityValue(e.target.name);
                      }}
                      name="Does Humidity inside and outsite of hive adequate?"
                    />
                  }
                  label="Does Humidity inside and outsite of hive adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={buzzingValue}
                      onChange={(e) => {
                        setBuzzingValue(e.target.name);
                      }}
                      name="Does Buzzing inside and outside of hive adequate?"
                    />
                  }
                  label="Does Buzzing inside and outside of hive adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flightValue}
                      onChange={(e) => {
                        setFlightValue(e.target.name);
                      }}
                      name="Does Flight activity around the hive?"
                    />
                  }
                  label="Does Flight activity around the hive?"
                />
              </div>
            ) : null}
            {selectedAssetType &&
            (selectedAssetType === "Sheep" ||
              selectedAssetType === "Goat" ||
              selectedAssetType === "Milking-Cow") ? (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weightValue}
                      onChange={(e) => {
                        setWeightValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Weight of the Animal is adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={grazingValue}
                      onChange={(e) => {
                        setGrazingValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Grazing of shed is adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={waterAvailabilityValue}
                      onChange={(e) => {
                        setWaterAvailabilityValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Water availability of the shed is adequate?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watchmenAvailabilityValue}
                      onChange={(e) => {
                        setWatchmenAvailabilityValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Watchmen  availability for the shed?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={firstAidAvailabilityValue}
                      onChange={(e) => {
                        setFirstAidAvailabilityValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does First-Aid Kit avilability in shed?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cleaningAvailabilityValue}
                      onChange={(e) => {
                        setCleaningAvailabilityValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Cleaning avilability in shed?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={feedingAvailabilityValue}
                      onChange={(e) => {
                        setFeedingAvailabilityValue(e.target.checked);
                      }}
                    />
                  }
                  label="Does Feeding avilability in shed?"
                />
              </div>
            ) : null}

            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Asset Health Status :</InputLabel>
              <Select
                id="selectedModel"
                value={assetHealthStatusValue}
                onChange={(e) => {
                  setAssetHealthStatusValue(e.target.value);
                }}
                label="Asset Health Status"
              >
                <MenuItem key="Good" value="Good">
                Good
                </MenuItem>
                <MenuItem key="Average" value="Average">
                Average
                </MenuItem>
                <MenuItem key="Bad" value="Bad">
                Bad
                </MenuItem>
                <MenuItem key="Critical" value="Critical">
                Critical
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                assetComplianceSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetCompliancePopup(!assetCompliancePopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Site Report */}
        <Dialog
          fullScreen={fullScreen}
          open={siteReportPopup}
          onClose={handleClose}
          aria-labelledby="siteReport"
        >
          <DialogTitle id="siteReport">{"Add Site Report"}</DialogTitle>
          <DialogContent>
            <h4>Create Site Report</h4>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceId}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Operational Status:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={operationalStatusValue}
                onChange={(e) => {
                  setOperationalStatusValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Report Description:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={reportDescriptionValue}
                onChange={(e) => {
                  setReportDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                siteReportSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setSiteReportPopup(!siteReportPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Report */}
        <Dialog
          fullScreen={fullScreen}
          open={assetReportPopup}
          onClose={handleClose}
          aria-labelledby="assetReport"
        >
          <DialogTitle id="assetReport">{"Add Asset Report"}</DialogTitle>
          <DialogContent>
            <h4>Asset Report</h4>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Model Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={selectedModelId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Asset Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={selectedAssetId}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Operational Status:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={operationalStatusValue}
                onChange={(e) => {
                  setOperationalStatusValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Report Description:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={reportDescriptionValue}
                onChange={(e) => {
                  setReportDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                assetReportSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetReportPopup(!assetReportPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Update Asset Status */}
        <Dialog
          fullScreen={fullScreen}
          open={assetUpdateStatusPopup}
          onClose={handleClose}
          aria-labelledby="assetReport"
        >
          <DialogTitle id="assetReport">{"Update Asset Status"}</DialogTitle>
          <DialogContent>
            <h4>Update Asset Status </h4>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="status">Existing Status:</p>
              <Input
                type="text"
                value={selectedExistingStatus}
                /* onChange={(e) => {
                  setSelectedExistingStatus(e.target.value);
                }} */
              />
            </FormControl>

            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Update Status</InputLabel>
              <Select
                id="selectedModel"
                value={updateAssetStatusValue}
                onChange={(event) =>
                  setUpdateAssetStatusValue(event.target.value)
                }
                label="Select Model"
              >
                <MenuItem key="in-qa-progress" value="in-qa-progress">
                  in-qa-progress
                </MenuItem>
                <MenuItem key="in-qa-success" value="in-qa-success">
                  in-qa-success
                </MenuItem>
                <MenuItem key="in-qa-fail" value="in-qa-fail">
                  in-qa-fail
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                assetUpdateStatusSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Site Patrol */}
        <Dialog
          fullScreen={fullScreen}
          open={sitePatrolPopup}
          onClose={handleClose}
          aria-labelledby="siteReport"
        >
          <DialogTitle id="siteReport">{"Add Site Patrolling"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceId}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Site Name:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={resourceName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Parent Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">User Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={username}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Mobile Number:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={mobileNumberValue}
                onChange={(e) => {
                  setMobileNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Latitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Longitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Altitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={altitudeValue}
                onChange={(e) => {
                  setAltitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Description:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={reportDescriptionValue}
                onChange={(e) => {
                  setReportDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                sitePatrolSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setSitePatrolPopup(!sitePatrolPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Patrol */}
        <Dialog
          fullScreen={fullScreen}
          open={assetPatrolPopup}
          onClose={handleClose}
          aria-labelledby="siteReport"
        >
          <DialogTitle id="siteReport">{"Add Asset Patrolling"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Asset Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={selectedAssetId}
              />
            </FormControl>

            {/* <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Asset Name:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={selectedAssetFriendlyName}
              />
            </FormControl> */}
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Parent Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">User Id:</InputLabel>
              <Input
                disabled
                id="siteId"
                name="siteId"
                type="text"
                value={username}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Mobile Number:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={mobileNumberValue}
                onChange={(e) => {
                  setMobileNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Latitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Longitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Altitude:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={altitudeValue}
                onChange={(e) => {
                  setAltitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="siteId">Description:</InputLabel>
              <Input
                id="siteId"
                name="siteId"
                type="text"
                value={reportDescriptionValue}
                onChange={(e) => {
                  setReportDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                assetPatrolSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetPatrolPopup(!assetPatrolPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
