import React, { useState } from "react";
import MainService from "../../../../Common/MainService";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",

    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  backButton: {
    marginBottom: "20px",
    margin: "auto",
  },
  
});

const TripDetail = ({ handlerAgain, data }) => {
  console.log(data, "ssssdrewty5");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const [rise, setRise] = useState(false);
  // const [data, setData] = useState([]);
  const [rout, setRout] = useState(false);
  const [routeid, setRouteid] = useState("");

  const handleLeft = () => {
    setRise(true);
    handlerAgain();
  };

  const classes = useStyles();

  return (
    <div className={`testtable  ${classes.root}`}>
      {!rise && !rout && (
        <TableContainer>
          <div style={{display:'flex',justifyContent:'center', borderBottom:'1px solid'}}>
            <strong>
              <h3>TRIP DETAILS</h3>
            </strong>
          </div>
          <Table className={classes.table}>
        
            <TableHead>
              <TableRow>
                <TableCell> <strong>KEY</strong></TableCell>
                <TableCell><strong>Value</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell><strong>DRIVER ID</strong></TableCell>
                <TableCell>{data.driverId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>ROUTE ID</strong></TableCell>
                <TableCell>{data.routeId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>STATUS</strong> </TableCell>
                <TableCell>{data.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>TRIP ID</strong></TableCell>
                <TableCell>{data.tripId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>VEHICLE ID</strong></TableCell>
                <TableCell>{data.vehicleId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell> <strong>CREATE DATE</strong></TableCell>
                <TableCell>{data.createdDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>START TIME</strong></TableCell>
                <TableCell>{data.startTime}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default TripDetail;
