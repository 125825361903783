import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import StarIcon from "@material-ui/icons/Star";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function FeedbackPageComponent() {
  const classes = useStyles();
  const [, setSiteList] = useState([]);
  const [siteModelsList, setModelsList] = useState([]);
  const [assetsData, setAssetsData] = useState([]);
  const [growerIdList, setGrowerIdList] = useState([]);
  const [selectedGrowserId, setSelectedGrowserId] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedAssetSiteId, setSelectedAssetSiteId] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedModelSiteId, setSelectedModelSiteId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedSchemeModelId, setSelectedSchemeModelId] = useState("");
  const [, setSelectedSchemeAssetdeveloperId] = useState("");
  const [isSelectedGrowserId, setIsSelectedGrowserId] = useState(true);
  const [isSelectedSiteName, setIsSelectedSiteName] = useState(false);
  const [isSelectedSAssetId, setIsSelectedAssetId] = useState(false);
  const [isSelectedSModelId, setIsSelectedModelId] = useState(false);
  const [isSelectedSchemeModelId, setIsSelectedSchemeModelId] = useState(false);
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState("");
  const [deliveryExp, setDeliveryExp] = useState("");
  const [productExp, setProductExp] = useState("");
  const [supportExp, setSupportExp] = useState("");
  const [othersExp, setOthersExp] = useState("");
  const [duplicateSiteList, setDuplicateSiteList] = useState([]);
  const [duplicateModelList, setDuplicateModelList] = useState([]);
  const [noDataFound, setNoDataFound] = useState("");

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? "s" : ""}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? "s" : ""}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? "s" : ""}, ${
      labels[othersRate]
    }`;
  }

  useEffect(() => {
    getGrowerIdList();
    myAssetsList();
    getInvestedModels();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedGrowserId) return;
    getAllSolutionsSite();
  }, [selectedGrowserId]);// eslint-disable-line react-hooks/exhaustive-deps

  const getAllSolutionsSite = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    // MainService.getAllSolutionsSite(selectedGrowserId) //if feedback required to be given for all solutions
    MainService.getAllAssociatedSolutionSites(username)
      .then((data) => {
        if (data.length > 0) {
          removeDuplicateSites(data);          
          setSelectedSiteId(data[0].siteid);          
        }
        else{
          setNoDataFound("No Data Found");
        }
        setSiteList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  
  const getInvestedModels = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.getInvestedModels(username)
      .then((data) => {
        if (data.length > 0) {
          // removeDuplicateModels(data);          
          setSelectedSchemeAssetdeveloperId(data[0].assetdeveloperid);
          setSelectedSchemeModelId(data[0].modelid);
          
        }
        else{
          setNoDataFound("No Data Found");
        }
        setModelsList(data);
        
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const myAssetsList = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.myAssetsList(username)
      .then((data) => {
        if (data.length > 0) {
          removeDuplicateModels(data);          
          setSelectedAssetId(data[0].assetId);
          setSelectedAssetSiteId(data[0].siteId);
          setSelectedModelId(data[0].modelId);
          setSelectedModelSiteId(data[0].siteId);
        } else{
          setNoDataFound("No Data Found");
        }

        setAssetsData(data);
        
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  function removeDuplicateSites(arr) {
    const result = arr.filter(
      (key, index, self) =>
        index === self.findIndex((t) => t.sitename === key.sitename)
    );
   
    setDuplicateSiteList(result);
  }
  function removeDuplicateModels(arr) {
    const result = arr.filter(
      (key, index, self) =>
        index === self.findIndex((t) => t.modelName === key.modelName)
    );
    
    setDuplicateModelList(result);
  }
  const getGrowerIdList = () => {
    // MainService.getAllSolutions()  //if feedback required to be given for all solutions

    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.getAllAssociatedSolutions(username)
      .then((data) => {
        if (data.length > 0) {
          // setSelectedGrowserId(data[0].solutionid);  //if feedback required to be given for all solutions
          setSelectedGrowserId(data[0].assetdeveloperid);
        } else{
          setNoDataFound("No Data Found");
        }
        setGrowerIdList(data);
        
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const assetDeveloperFeedbackClick = () => {
    
    const { user } = MainService.getLocalData();
    const { username } = user;

    let addFeedback = {
      assetdeveloperid: selectedGrowserId,
      recorder: username,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const addFeedbackData = JSON.stringify(addFeedback);
    MainService.assetDeveloperFeedback(addFeedbackData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const siteFeedbackClick = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    let siteFeedback = {
      assetdeveloperid: selectedGrowserId,
      recorder: username,
      siteid: selectedSiteId,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const siteFeedbackData = JSON.stringify(siteFeedback);
    MainService.siteFeedback(siteFeedbackData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const assetFeedbackClick = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    let assetFeedback = {
      assetdeveloperid: selectedGrowserId,
      siteid: selectedAssetSiteId,
      recorder: username,
      assetid: selectedAssetId,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const assetFeedbackData = JSON.stringify(assetFeedback);
    MainService.assetFeedback(assetFeedbackData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const modelFeedbackClick = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    let modelFeedback = {
      assetdeveloperid: selectedGrowserId,
      siteid: selectedModelSiteId,
      recorder: username,
      modelid: selectedModelId,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const modelFeedbackData = JSON.stringify(modelFeedback);
    MainService.modelFeedback(modelFeedbackData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const schemeFeedbackClick = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    let schemeFeedback = {
      recorder: username,
      modelid: selectedModelId,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const schemeFeedbackData = JSON.stringify(schemeFeedback);
    MainService.modelFeedback(schemeFeedbackData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  return (
    <>
      <Grid container spacing={1}>
      
        <Grid item md={12} sm={12} xs={12} >  
        <span >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select AAMC
              </InputLabel>
              <Select
                id="selectedGrowserId"
                value={selectedGrowserId}
                onChange={(event) => {
                  setSelectedGrowserId(event.target.value);
                  
                }}
                onClick={(e)=>{
                  setIsSelectedAssetId(false);
                  setIsSelectedGrowserId(true);
                  setIsSelectedSiteName(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select Grower"
              >
                {growerIdList.map((idObj,index) => (                  
                  <MenuItem key={index}
                  value={idObj.assetdeveloperid}>
                  {idObj.assetdeveloperid}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>        
          <span >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Farm
              </InputLabel>
              <Select
                id="selectedSiteName"
                value={selectedSiteId}
                onChange={(event) => {
                  setSelectedSiteId(event.target.value);
    
                }}
                onClick={(event) => {
                  
                  setIsSelectedSiteName(true);
                  setIsSelectedAssetId(false);
                  setIsSelectedGrowserId(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select site"
              >
                {duplicateSiteList.map((idObj,index) => (                  
                  <MenuItem key={index} value={idObj.siteid}>
                    {idObj.siteid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          
          <span >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Asset
              </InputLabel>
              <Select
                id="selectedAssetId"
                value={selectedAssetId}
                name={selectedAssetSiteId}
                onChange={(event) => {
                  setSelectedAssetId(event.target.value);
                  setSelectedAssetSiteId(event.target.name);
                  
                }}
                onClick={(event) => {
                 
                 
                  setIsSelectedAssetId(true);
                  setIsSelectedSiteName(false);
                  setIsSelectedGrowserId(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select Asset"
              >
                {assetsData.map((idObj,index) => (
                  <MenuItem key={index} value={idObj.assetId}>
                    {idObj.friendlyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          
          <span >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Scheme
              </InputLabel>
              <Select
                id="selectedSchemeModelId"
                value={selectedSchemeModelId}
                
                onChange={(event) => {
                  setSelectedSchemeModelId(event.target.value);
                  
                }}
                onClick={(event) => {                 
                  
                  setIsSelectedSchemeModelId(true);
                  setIsSelectedModelId(false);
                  setIsSelectedAssetId(false);
                  setIsSelectedSiteName(false);
                  setIsSelectedGrowserId(false);
                }}
                label="Select scheme"
              >
                {siteModelsList.map((idObj,index) => (
                  <MenuItem key={index} value={idObj.modelid}>
                    {idObj.modelid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          <span >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Asset Model
              </InputLabel>
              <Select
                id="selectedModelId"
                value={selectedModelId}
                name={selectedModelSiteId}
                onChange={(event) => {
                  setSelectedModelId(event.target.value);
                  setSelectedModelSiteId(event.target.name);
                  
                }}
                onClick={(event) => {
                 
                  
                  setIsSelectedModelId(true);
                  setIsSelectedAssetId(false);
                  setIsSelectedSiteName(false);
                  setIsSelectedGrowserId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select asset model"
              >
                {duplicateModelList.map((idObj,index) => (
                  <MenuItem key={index} value={idObj.modelId}>
                    {idObj.modelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </Grid>
        <Grid item md={12} sm={12} xs={12} >
        <h3>Feedback</h3><br/>
        </Grid>
        {(isSelectedGrowserId && selectedGrowserId)?
        (
          <Grid item md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Asset Developer Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-feedback"
                      value={customerRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                       
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth: "260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br/>
                <Button
                  style={{ marginRight: "10px" }}
                  variant="contained"
                  color="primary"
                  className="registerBtn"
                  type="button"
                  onClick={() => assetDeveloperFeedbackClick()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ):(
          <h4>{noDataFound}<br/></h4>
        )}
        {(isSelectedSiteName && selectedSiteId)?
        (
          <Grid item md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Site Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={customerRate}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                       
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth:"260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br/>
                <Button
                  style={{ marginTop: "20px" }}
                  variant="contained"
                  color="primary"
                  className="registerBtn"
                  type="button"
                  onClick={() => siteFeedbackClick()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ):(
          <h4>{noDataFound}<br/></h4>
        )}
        {(isSelectedSAssetId && selectedAssetId)? 
        (
          <Grid item md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Asset Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={customerRate}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                        
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth:"260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br/>
                <Button
                  style={{ marginRight: "10px" }}
                  variant="contained"
                  color="primary"
                  className="registerBtn"
                  type="button"
                  onClick={() => assetFeedbackClick()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ):(
          <h4>{noDataFound}<br/></h4>
        )}
        {( isSelectedSModelId && selectedModelId)?  
        (
          <Grid item md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Model Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={customerRate}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                       
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth:"260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br/>
                <Button
                  style={{ marginRight: "10px" }}
                  variant="contained"
                  color="primary"
                  className="registerBtn"
                  type="button"
                  onClick={() => modelFeedbackClick()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ):(
          <h4>{noDataFound}<br/></h4>
        )}
         {( isSelectedSchemeModelId && selectedSchemeModelId)?  
         (
          <Grid item md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Scheme Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={customerRate}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                        
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth:"260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br/>
                <Button
                  style={{ marginRight: "10px" }}
                  variant="contained"
                  color="primary"
                  className="registerBtn"
                  type="button"
                  onClick={() => schemeFeedbackClick()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ):(
          <h4>{noDataFound}<br/></h4>
        )}
      </Grid>

      {/* <MaterialTable
      title="Feedback"
      columns={state.columns}
      data={state.data}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                setState((prevState) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    /> */}
    </>
  );
}
