import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./FarmSiteManagementComponent.css";
import SitesMap from "./SitesMap";
import Input from "@material-ui/core/Input";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function FarmSiteManagementComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [setFarmData, getFarmData] = useState([]);
  const [showFarm, hideFarm] = useState(false);
  const [setFarmDropDwon, getFarmDropDown] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [setmarker_position, getmarker_position] = useState({
    lat: 12.936350000000001,
    lng: 77.69044000000001,
  });
  const [showMap, hideMap] = useState(false);
  const [setSiteValue, getSiteValue] = useState("");
  const [setAssets, getAssets] = useState([]);
  /* Create Form Site */
  const [getListOfCategories, setListOfCategories] = useState([]);
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [getSiteName, setSiteName] = useState("");
  const [getMailId, setMailId] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getSiteType, setSiteType] = useState("");
  const [getSiteData, setSiteData] = useState([]);
  const [getFarmerUserName,setFarmerUserName] = useState("");
  const [showCreateSitePopup,setShowCreateSitePopup] = useState(false);
  const [showCreateSiteDialog,setShowCreateSiteDialog] = useState(false);
  //const assetVerticalIdValue = "6a112364-7adc-47c7-8436-47479d11fcf3";
  //const assetVerticalFriendlyNameValue = "Animal";
  
  const [nodeEmail,setNodeEmail] = useState("");
  const [nodeMobileNumber, setNodePhoneNumber]= useState("");
  const [nodeUserName,setNodeUserName] = useState("");
  const [nodeType, setNodeType] = useState("");
  const [getNodeId,setNodeId] = useState("");
  /* End Create Form Site */
  const [showGrid, hideGrid] = useState(true);
  const [showAssetGrid, hideAssetGrid] = useState(false);
  const [showAssetLis, hideAssetList] = useState(false);
  const [showAssetMap, hideAssetMap] = useState(false);
  const [setGridAssets, getGridAssets] = useState([]);
  const [setFarmMapAssets, getFarmMapAssets] = useState([]);
  const [farmlistsearched, setFarmListSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  /**************************** Create Asset *********************************** */
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [getCreateAssetPopup, setCreateAssetPopup] = useState(false);
  const [getType, setType] = useState("");
  /********************************** End Of Create Asset***************************** */

  /*List Filter */
  const requestFarmListSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getFarmData(filteredRows);
  };

  const cancelFarmListSearch = () => {
    setFarmListSearched("");
    requestFarmListSearch(farmlistsearched);
  };
  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getFarmData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    getAllFarmsHandler();
  }, [getFarmData, setSiteValue]);

  useEffect(() => {
    farmsDropDwonHandler();
  }, [getFarmDropDown]);

  useEffect(() => {
    getAllSitesList();
  }, [setSiteData]);

  useEffect(() => {
    getAssetVerticalsIdList();
  }, [setVerticalsIdList]);

  /* Pagination */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /* End Pagination */

  const getAllFarmsHandler = (Map) => {
    setPage(0);
    setType(Map);
    if (Map === "Map") {
      hideFarm(false);
      hideMap(true);
      hideGrid(false);

      hideAssetGrid(false);
      hideAssetList(false);
      hideAssetMap(false);
    } else if (Map === "List") {
      hideFarm(true);
      hideMap(false);
      hideGrid(false);

      hideAssetGrid(false);
      hideAssetList(false);
      hideAssetMap(false);
    } else if (Map === "Grid") {
      hideFarm(false);
      hideMap(false);
      hideGrid(true);

      hideAssetGrid(false);
      hideAssetList(false);
      hideAssetMap(false);
    }

    MainService.viewAllFarms(username)
      .then((res) => {
        if (setSiteValue !== "") {
          const data = res.sites.filter((val) => {
            return val.siteid === setSiteValue;
          });
          getFarmData(data);
          if (Map === "Clear") {
            getSiteValue("");
          }
        } else {
          getFarmData(res.sites);
          getFilterUsers(res.sites);
        }
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const farmRelatedAssetsHandler = (farmid, Map) => {
    if (Map === "Grid") {
      hideAssetGrid(true);
      hideAssetList(false);
      hideAssetMap(false);

      hideFarm(false);
      hideMap(false);
      hideGrid(false);
    } else if (Map === "List") {
      hideAssetGrid(false);
      hideAssetList(true);
      hideAssetMap(false);

      hideFarm(false);
      hideMap(false);
      hideGrid(false);
    }

    MainService.viewAllFarmRelatedAssets(username, farmid)
      .then((res) => {
        getGridAssets(res.assets);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const viewFarmMapRelatedAssetMap = (siteid) => {
    hideAssetGrid(false);
    hideAssetList(false);
    hideAssetMap(true);

    hideFarm(false);
    hideMap(false);
    hideGrid(false);

    MainService.viewFarmMapRelatedAssets(username, siteid)
      .then((res) => {
        getFarmMapAssets(res.assets);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const farmsDropDwonHandler = () => {
    MainService.farmsDropDownList(username)
      .then((res) => {
        getFarmDropDown(res.sites);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  /* Create Site */
  /* const getAllSitesList = () => {
    MainService.getAllTenderSiteTypes()
      .then((res) => {
        const Farmdata = res.sitetypes.filter((val) => {
          return val.sitetype === "Farm";
        });
        setSiteData(Farmdata);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteCreateHandler = () => {
    const createSiteFormData = new FormData();
    createSiteFormData.append("parentid", username);
    createSiteFormData.append("sitename", getSiteName);
    createSiteFormData.append("mailid", getMailId);
    createSiteFormData.append("phone", getPhone);
    createSiteFormData.append("sitetype", getSiteType);
    createSiteFormData.append("assetverticalid", selectedVerticalsId);
    createSiteFormData.append(
      "assetverticalfriendlyname",
      assetVerticalFriendlyNameValue
    );

    MainService.createTenderSite(createSiteFormData)
      .then((res) => {
        setSiteCreatePopUp(!getSitePopUp);
        getAllFarmsHandler();
        return NotificationManager.success("Site Created");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
  };

  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );

  const siteAgreement = "Create A New Site";
  const siteModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          id="mailid"
          name="mailid"
          type="text"
          value={getMailId}
          onChange={(e) => setMailId(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={getPhone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getSiteType}
          onChange={(event) => setSiteType(event.target.value)}
          label="Select Model"
        >
          {getSiteData &&
            getSiteData.map((idObj, index) => (
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  ); */





/* Create Node & Site */

const getAllSitesList = () => {
  MainService.getAllSiteTypes("consumer2")
    .then((res) => {
      setSiteData(res.sitetypes);
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
};

const siteCreateHandler = () => {
  /* const createSiteFormData = new FormData();
  createSiteFormData.append("farmerusername", getFarmerUserName);
  createSiteFormData.append("parentid", username);
  createSiteFormData.append("sitename", getSiteName);
  createSiteFormData.append("mailid", getFarmerUserName);
  createSiteFormData.append("phone", getPhone);
  createSiteFormData.append("sitetype", getSiteType);
  createSiteFormData.append("assetverticalid", selectedVerticalsId);
  createSiteFormData.append(
    "assetverticalfriendlyname",
    assetVerticalFriendlyNameValue
  );
 */
  var siteDetails = {
    farmerusername: getFarmerUserName,
    parentid: username,
    sitename: getSiteName,
    mailid: getFarmerUserName,
    phone: getPhone,
    sitetype: getSiteType,
    assetverticalid: selectedVerticalsId,
    assetverticalfriendlyname: assetVerticalFriendlyNameValue,
  };

  var siteBody = [];
  for (var property in siteDetails) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(siteDetails[property]);
    siteBody.push(encodedKey + "=" + encodedValue);
  }
  siteBody = siteBody.join("&");
  MainService.createSite(siteBody)
    .then((res) => {
      return NotificationManager.success("Site Created");
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
    setSiteCreatePopUp(!getSitePopUp);
    getAllFarmsHandler("grid");
};
const closeSiteModal = (
  <span
    onClick={() => {
      setSiteCreatePopUp(!getSitePopUp);
    }}
  >
    X
  </span>
);

const siteCreationTitle = "Create New Site";
const siteModelBody = (
  <div>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="farmerName"> Farmer User Name:</InputLabel>
      <Input
        id="farmerName"
        name="farmerName"
        type="text"
        value={getFarmerUserName}
        onChange={(e) => {
          setFarmerUserName(e.target.value);
        }}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
      <Input
      disabled
        id="mailid"
        name="mailid"
        type="text"
        value={getFarmerUserName}
        
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="phone">Phone Number</InputLabel>
      <Input
        id="phone"
        name="phone"
        type="text"
        value={getPhone}
        onChange={(e) =>{setPhone(e.target.value)} }
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
      <Input
        id="sitename"
        name="sitename"
        type="text"
        value={getSiteName}
        onChange={(e) => {setSiteName(e.target.value)}}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="parentid"> Parent Id:</InputLabel>
      <Input
      disabled
        id="parentid"
        name="parentid"
        type="text"
        value={username}
        
      />
    </FormControl>
    <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVerticalId:</InputLabel>
                <Select
                  id="assetVerticalID"
                  name="assetVerticalID"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList.length > 0 ? (
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem
                        key={`${index} `}
                        value={idObj.assetVerticalId}
                      >
                        {idObj.friendlyName}
                      </MenuItem>
                    ))
                  ) : (
                    <h4>Asset Vertical not found</h4>
                  )}
                </Select>
              </FormControl>
              {/* <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={assetVerticalFriendlyNameValue}
                  onChange={(e) => {
                    setAssetVerticalFriendlyNameValue(e.target.value);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.friendlyName}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
    
    <FormControl
      variant="standard"
      fullWidth={true}
      className={classes.formControl}
    >
      <InputLabel>Site Type</InputLabel>
      <Select
        id="selectedModel"
        value={getSiteType}
        onChange={(event) => setSiteType(event.target.value)}
        label="Select Model"
      >
        {getSiteData &&
          getSiteData.map((idObj, index) => (
            <MenuItem key={`${index}`} value={idObj.sitetype}>
              {idObj.sitetype}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    <br />
    <FormControl>
      <Button
        variant="contained"
        color="primary"
        className="registerBtn"
        type="submit"
        onClick={siteCreateHandler}
      >
        Create
      </Button>
    </FormControl>
    <FormControl
      fullWidth={true}
      className="forgotPwdLink"
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
      style={{
        marginBottom: "20px",
        textAlign: "center",
        boxOrient: "0",
        display: "block",
        fontSize: "20",
      }}
    >
      Cancel
    </FormControl>
  </div>
);

/* End Of Create Node & Site */

/* Start of Site Creation with Node Id */

const createSitWithNodeId = () => {
setShowCreateSitePopup(!showCreateSitePopup);
};
const siteAndNodeCreateHandler = () =>{
/* const createSiteAndNodeFormData = new FormData();

createSiteAndNodeFormData.append("nodeid", getNodeId);
createSiteAndNodeFormData.append("parentid", username);
createSiteAndNodeFormData.append("sitename", getSiteName);
createSiteAndNodeFormData.append("mailid",nodeEmail );
createSiteAndNodeFormData.append("phone", nodeMobileNumber);
createSiteAndNodeFormData.append("sitetype", nodeType);
createSiteAndNodeFormData.append("assetverticalid", selectedVerticalsId);
createSiteAndNodeFormData.append(
  "assetverticalfriendlyname",
  assetVerticalFriendlyNameValue
); */

var details = {
  nodeid: getNodeId,
  parentid: username,
  sitename: getSiteName,
  mailid: nodeEmail,
  phone: nodeMobileNumber,
  sitetype: nodeType,
  assetverticalid: selectedVerticalsId,
  assetverticalfriendlyname: assetVerticalFriendlyNameValue,
};

var formBody = [];
for (var property in details) {
  var encodedKey = encodeURIComponent(property);
  var encodedValue = encodeURIComponent(details[property]);
  formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&");

MainService.createSiteWithNodeId(formBody)
  .then((res) => {
    
    setShowCreateSiteDialog(!showCreateSiteDialog);
    return NotificationManager.success("Site and Node Created");
  })
  .catch((err) => {
    return NotificationManager.error(err);
  });
  getAllFarmsHandler("grid");
}
const createSiteHandler = () => {
setSiteCreatePopUp(!getSitePopUp);
};

const submitForNode = ()=>{
MainService.getNodeInfoBasedOnNodeId(getNodeId)
.then((data) => {
  if (data) {
    setNodeUserName(data.username);
    setNodePhoneNumber(data.phone);
    setNodeEmail(data.email);
    setNodeType(data.nodetype);
    
  } else {
    return NotificationManager.error("invalid node id");
  }
})
.catch((e) => {
  console.error(e);
  return NotificationManager.error(e);
});

setShowCreateSitePopup(!showCreateSitePopup);
setShowCreateSiteDialog(!showCreateSiteDialog);
}
const closeSiteWithNodeModal = (
<span
  onClick={() => {
    setShowCreateSitePopup(!showCreateSitePopup);
  }}
>
  X
</span>
);

const closeSiteCreationWithNodeModal = (
<span
  onClick={() => {
    setShowCreateSiteDialog(!showCreateSiteDialog);
  }}
>
  X
</span>
);
const siteAndNodeCreationTitle = "Create New Node & Site";

const siteCreationWithNodeModelBody =(
<div>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
    <Input
      id="nodeid"
      name="nodeid"
      type="text"
      value={getNodeId}
      onChange={(e) => setNodeId(e.target.value)}
    />
  </FormControl>
  <br />
  <FormControl>
    <Button
      variant="contained"
      color="primary"
      className="registerBtn"
      type="submit"
      onClick={submitForNode}
    >
      Submit
    </Button>
  </FormControl>
  <FormControl
    fullWidth={true}
    className="forgotPwdLink"
    onClick={() => {
      setShowCreateSitePopup(!showCreateSitePopup);
    }}
    style={{
      marginBottom: "20px",
      textAlign: "center",
      boxOrient: "0",
      display: "block",
      fontSize: "20",
    }}
  >
    Cancel
  </FormControl>
</div>
)
const siteCreationModelBody = (
<div>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
    <Input
      disabled
      id="parentId"
      name="parentId"
      type="text"
      value={getNodeId}
    />
  </FormControl>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="farmerName"> Farmer User Name:</InputLabel>
    <Input
      id="farmerName"
      name="farmerName"
      type="text"
      value={nodeUserName}
      //onChange={(e) => setSiteName(e.target.value)}
    />
  </FormControl>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
    <Input
      id="mailid"
      name="mailid"
      type="text"
      value={nodeEmail}
      //onChange={(e) => setMailId(e.target.value)}
    />
  </FormControl>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="phone">Phone Number</InputLabel>
    <Input
      id="phone"
      name="phone"
      type="text"
      value={nodeMobileNumber}
      //onChange={(e) => setPhone(e.target.value)}
    />
  </FormControl>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
    <Input
      id="sitename"
      name="sitename"
      type="text"
      value={getSiteName}
      onChange={(e) => setSiteName(e.target.value)}
    />
  </FormControl>
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="sitename"> Parent Id:</InputLabel>
    <Input
      id="sitename"
      name="sitename"
      type="text"
      value={username}
      onChange={(e) => setSiteName(e.target.value)}
    />
  </FormControl>
  <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVerticalId:</InputLabel>
                <Select
                  id="assetVerticalID"
                  name="assetVerticalID"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList.length > 0 ? (
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem
                        key={`${index} `}
                        value={idObj.assetVerticalId}
                      >
                        {idObj.friendlyName}
                      </MenuItem>
                    ))
                  ) : (
                    <h4>Asset Vertical not found</h4>
                  )}
                </Select>
              </FormControl>
              {/* <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={assetVerticalFriendlyNameValue}
                  onChange={(e) => {
                    setAssetVerticalFriendlyNameValue(e.target.value);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.friendlyName}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
  
  <FormControl fullWidth={true} className="loginFields">
    <InputLabel htmlFor="siteType">Site Type</InputLabel>
    <Input
      id="siteType"
      name="siteType"
      type="text"
      value={nodeType}
      //onChange={(e) => setPhone(e.target.value)}
    />
  </FormControl>
  
  {/* <FormControl
    variant="standard"
    fullWidth={true}
    className={classes.formControl}
  >
    <InputLabel>Site Type</InputLabel>
    <Select
      id="selectedModel"
      value={nodeType}
      onChange={(event) => setSiteType(event.target.value)}
      label="Select Model"
    >
      {getSiteData &&
        getSiteData.map((idObj, index) => (
          <MenuItem key={`${index}`} value={idObj.sitetype}>
            {idObj.sitetype}
          </MenuItem>
        ))}
    </Select>
  </FormControl> */}
  <br />
  <FormControl>
    <Button
      variant="contained"
      color="primary"
      className="registerBtn"
      type="submit"
      onClick={siteAndNodeCreateHandler}
    >
      Create
    </Button>
  </FormControl>
  <FormControl
    fullWidth={true}
    className="forgotPwdLink"
    onClick={() => {
      setShowCreateSiteDialog(!showCreateSiteDialog);
    }}
    style={{
      marginBottom: "20px",
      textAlign: "center",
      boxOrient: "0",
      display: "block",
      fontSize: "20",
    }}
  >
    Cancel
  </FormControl>
</div>
);

/* End of Site Creation with Node Id */








  /* End Of Create Site */

  /*Create Asset */
  const cretaeAssetModel = () => {
    setCreateAssetPopup(!getCreateAssetPopup);
  };

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        setVerticalsIdList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedVerticalsIdHandler = (e) => {
    console.log("verticalsIdList:",verticalsIdList)
    let catname = verticalsIdList.filter(data=>{
      return data.assetVerticalId === e.target.value;
  })
  console.log("catname catname",catname);
  setAssetVerticalFriendlyNameValue(catname[0].friendlyName);
    getCategoryTypesList(e.target.value);
    setSelectedVerticalsId(e.target.value);
  };

  const getCategoryTypesList = (assetVerticalId) => {
    MainService.getCategoryTypesList(assetVerticalId,"consumer2")
      .then((data) => {
        setCategoryTypesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedCategoryHandler = (e) => {
    getAssetTypesList(selectedVerticalsId, e.target.value);
    setSelectedCategory(e.target.value);
  };

  const getAssetTypesList = (VerticalsId, Category) => {
    MainService.getAssetTypesList(VerticalsId, Category,parentId)
      .then((data) => {
        setAssertTypesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedAssertTypesHandler = (e) => {
    getModelsList(selectedVerticalsId, selectedCategory, e.target.value);
    setSelectedAssertTypes(e.target.value);
  };

  const getModelsList = (VerticalsId, Category, AssertTypes) => {
    MainService.getModelsList(VerticalsId, Category, AssertTypes,parentId)
      .then((data) => {
        setModelsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: siteNameValue,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: modelFriendlyNameValue,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: "AssetDeveloper",
      consumerAdminId: "consumer2",
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setCreateAssetPopup(!getCreateAssetPopup);
    getAllFarmsHandler(getType);
  };
  /*End Of Create Asset */

  /*Fram Site Assets Map */

  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={setFarmMapAssets.index}
        defaultZoom={13}
        defaultCenter={setmarker_position}
        center={setmarker_position}
      >
        {setFarmMapAssets.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetType}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });

  /*End Of Fram Site Assets Map */

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            {setSiteValue.length > 0 ? (
              <Button
                style={{ marginRight: "200px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  getAllFarmsHandler("Clear");
                }}
              >
                Clear Filter
              </Button>
            ) : null}

            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                getAllFarmsHandler("List");
              }}
            ></Button>

            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                getAllFarmsHandler("Grid");
              }}
            ></Button>

            <Button
              startIcon={<MapIcon />}
              size="large"
              onClick={() => {
                getAllFarmsHandler("Map");
              }}
            ></Button>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Filter by Site
              </InputLabel>
              <Select
                id="selectedSubCategory"
                value={setSiteValue}
                onChange={(e) => {
                  getSiteValue(e.target.value);
                }}
                label="Select Sub-Category"
              >
                {setFarmDropDwon &&
                  setFarmDropDwon.map((res, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={res.siteid}
                      // onClick ={()=>{farmsDropDownValueHandler(res.siteid)}}
                    >
                      {res.sitename}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Assets
              </InputLabel>
              <Select id="selectedSubCategory" label="Select Sub-Category">
                {setAssets &&
                  setAssets.map((res, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={res.assetId}
                      // onClick ={()=>{getAssetLocationHandler(res.assetId)}}
                    >
                      {res.assetId}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="MuiGrid-root">
          {showFarm === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <SearchBar
                value={farmlistsearched}
                onChange={(searchVal) => requestFarmListSearch(searchVal)}
                onCancelSearch={() => cancelFarmListSearch()}
              />
              {/* <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => createSiteHandler()}></AddIcon>
              </Fab> */}
              {setFarmData.length > 0 ? (
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            <h2>Farm Site Details:</h2>
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            Site Id
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Name
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Type
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            Farm Site Assets
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {setFarmData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((user, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {user.siteid}
                                </TableCell>
                                <TableCell align="center">
                                  {user.sitename}
                                </TableCell>
                                <TableCell align="center">
                                  {user.sitetype}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    // color="primary"
                                    // className="registerBtn"
                                    variant="contained"
                                    onClick={() => {
                                      viewFarmMapRelatedAssetMap(user.siteid);
                                    }}
                                  >
                                    Map
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    // color="primary"
                                    // className="registerBtn"
                                    variant="contained"
                                    onClick={() => {
                                      farmRelatedAssetsHandler(
                                        user.siteid,
                                        "Grid"
                                      );
                                    }}
                                  >
                                    Grid
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    // color="primary"
                                    // className="registerBtn"
                                    variant="contained"
                                    onClick={() => {
                                      farmRelatedAssetsHandler(
                                        user.siteid,
                                        "List"
                                      );
                                    }}
                                  >
                                    List
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={setFarmData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No Farms Found</h4>
              )}
            </Box>
          ) : null}

          {showGrid === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              <Fab
                    color="primary"
                    size={"large"}
                    aria-label="add"
                    title="Node&Site Creation"
                    className={classes.fab}
                  >
                    <AddIcon onClick={() => createSiteHandler()}></AddIcon>
                  </Fab>
                  <Fab
                    style={{ marginBottom: "60px" }}
                    color="primary"
                    size={"large"}
                    aria-label="add"
                    title="Site Creation With Node"
                    className={classes.fab}
                  >
                    <AddIcon onClick={() => createSitWithNodeId()}></AddIcon>
                  </Fab>
              {/* <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => createSiteHandler()}></AddIcon>
              </Fab> */}
              <div className="cardStyleForDashboardItems">
                <h2 style={{ width: "100%", textAlign: "center" }}>
                  Farm Site Details
                </h2>
                {setFarmData.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {setFarmData.map((farm, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {farm.siteid}
                          </p>
                          <p>
                            <b>Site Name:</b>
                            {farm.sitename}
                          </p>
                        </span>
                        <p>
                          <b>Site Type:</b>
                          {farm.sitetype}
                        </p>
                        <Button
                          style={{ marginRight: "10px" }}
                          // color="primary"
                          // className="registerBtn"
                          variant="contained"
                          onClick={() => {
                            viewFarmMapRelatedAssetMap(farm.siteid);
                          }}
                        >
                          Map
                        </Button>
                        <Button
                          style={{ marginRight: "10px" }}
                          // color="primary"
                          // className="registerBtn"
                          variant="contained"
                          onClick={() => {
                            farmRelatedAssetsHandler(farm.siteid, "Grid");
                          }}
                        >
                          Grid
                        </Button>
                        <Button
                          style={{ marginRight: "10px" }}
                          // color="primary"
                          // className="registerBtn"
                          variant="contained"
                          onClick={() => {
                            farmRelatedAssetsHandler(farm.siteid, "List");
                          }}
                        >
                          List
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Farms Found.</h4>
                )}
              </div>
            </Box>
          ) : null}

          {showMap === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              {/* <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => createSiteHandler()}></AddIcon>
              </Fab> */}
              <div
                style={{
                  marginTop: "2px",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <SitesMap
                  containerElement={
                    <div style={{ height: "800px", width: "80%" }} />
                  }
                  display_sidebar={true}
                  marker_position={setmarker_position}
                  mapElement={<div style={{ height: "100%" }} />}
                  isMarkerShown
                  site_map={setFarmData}
                  clicked={viewFarmMapRelatedAssetMap}
                />
              </div>
            </Box>
          ) : null}

          {showAssetGrid === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => cretaeAssetModel()}></AddIcon>
              </Fab>
              <div className="cardStyleForDashboardItems">
                <h4 style={{ width: "100%", textAlign: "center" }}>Assets</h4>
                {setGridAssets.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {setGridAssets.map((asset, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Site Id:</b>
                            {asset.siteId}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {asset.assetId}
                          </p>
                        </span>
                        <p>
                          <b>Category:</b>
                          {asset.category}
                        </p>
                        <p>
                          <b>Asset Type:</b>
                          {asset.assetType}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {asset.modelId}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Assets Found.</h4>
                )}
              </div>
            </Box>
          ) : null}

          {showAssetLis === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => cretaeAssetModel()}></AddIcon>
              </Fab>
              {/* <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            /> */}
              {setGridAssets.length > 0 ? (
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            <h2>Farm Asset Details:</h2>
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            Site Id
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Id
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Category
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Type
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Model Id
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {setGridAssets
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((asset, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {asset.siteId}
                                </TableCell>
                                <TableCell align="center">
                                  {asset.assetId}
                                </TableCell>
                                <TableCell align="center">
                                  {asset.category}
                                </TableCell>
                                <TableCell align="center">
                                  {asset.assetType}
                                </TableCell>
                                <TableCell align="center">
                                  {asset.modelId}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={setGridAssets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No Assets Found</h4>
              )}
            </Box>
          ) : null}

          {showAssetMap === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => cretaeAssetModel()}></AddIcon>
              </Fab>
              {setFarmMapAssets.length > 0 ? (
                <AssetsGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>No Assets Found</h4>
              )}
            </Box>
          ) : null}
        </div>

        {/* Create Asset */}
        <Dialog
          fullScreen={fullScreen}
          open={getCreateAssetPopup}
          aria-labelledby="createasset"
        >
          <DialogTitle id="createasset">Create Asset</DialogTitle>
          <DialogContent>
            <div>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetDeveloperId">
                  AssetDeveloper ID:
                </InputLabel>
                <Input
                  disabled
                  id="assetDeveloperId"
                  name="assetDeveloperId"
                  type="text"
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
                <Input
                  id="assetId"
                  name="assetId"
                  type="text"
                  placeholder="5533222"
                  value={assetIdValue}
                  onChange={(e) => {
                    setAssetIdValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="legacyAssetFriendlyName">
                  Legacy Asset Friendly Name :
                </InputLabel>
                <Input
                  id="legacyAssetFriendlyName"
                  name="legacyAssetFriendlyName"
                  placeholder="Betal Sheep"
                  type="text"
                  value={legacyAssetFriendlyNameValue}
                  onChange={(e) => {
                    setLegacyAssetFriendlyNameValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVerticalId:</InputLabel>
                <Select
                  id="assetVerticalID"
                  name="assetVerticalID"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList.length > 0 ? (
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))
                  ) : (
                    <h4>Sector not found</h4>
                  )}
                </Select>
              </FormControl>
              {/* <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={assetVerticalFriendlyNameValue}
                  onChange={(e) => {
                    setAssetVerticalFriendlyNameValue(e.target.value);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.friendlyName}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type*</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Domain</InputLabel>
                <Select
                  id="assetDomain"
                  name="assetDomain"
                  type="text"
                  value={assetDomainValue}
                  onChange={(e) => {
                    setAssetDomainValue(e.target.value);
                  }}
                >
                  <MenuItem selected>Select</MenuItem>
                  <MenuItem key="Farm" value="Farm">
                    Farm
                  </MenuItem>
                  {/* <MenuItem key="Field" value="Field">
                    Field
                  </MenuItem>
                  <MenuItem key="Enterprise" value="Enterprise">
                    Enterprise
                  </MenuItem>
                  <MenuItem key="Home" value="Home">
                    Home
                  </MenuItem>
                  <MenuItem key="Personal" value="Personal">
                    Personal
                  </MenuItem> */}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Site Name*</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={selectedSite}
                  onChange={(e) => {
                    setSelectedSite(e.target.value);
                  }}
                >
                  {setFarmData &&
                    setFarmData.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.siteid}>
                        {idObj.sitename}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
                <Input
                  id="zone"
                  name="zone"
                  type="text"
                  value={zoneValue}
                  onChange={(e) => {
                    setZoneValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
                <Input
                  id="lotName"
                  name="lotName"
                  type="text"
                  value={lotNameValue}
                  onChange={(e) => {
                    setLotNameValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="manufacturerId">
                  {" "}
                  Manufacturer Id *:
                </InputLabel>
                <Input
                  disabled
                  id="manufacturerId"
                  name="manufacturerId"
                  type="text"
                  value={manufacturerIdValue}
                  onChange={(e) => {
                    setManufacturerIdValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="manufacturerUrl">
                  {" "}
                  Manufacturer Url:
                </InputLabel>
                <Input
                  id="manufacturerUrl"
                  name="manufacturerUrl"
                  type="text"
                  value={manufacturerUrlValue}
                  onChange={(e) => {
                    setManufacturerUrlValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={selectedModel}
                  onChange={(event) => setSelectedModel(event.target.value)}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
                <Input
                  disabled
                  id="modelType"
                  name="modelType"
                  type="text"
                  value={selectedModel}
                  /* onChange={(e) => {
                    setModelTypeValue(e.target.value);
                  }} */
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
                <Input
                  id="modelUrl"
                  name="modelUrl"
                  type="text"
                  value={modelUrlValue}
                  onChange={(e) => {
                    setModelUrlValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
                <Input
                  id="serialNo"
                  name="serialNo"
                  type="text"
                  value={serialNoValue}
                  onChange={(e) => {
                    setSerialNoValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="udn"> UDN*:</InputLabel>
                <Input
                  id="udn"
                  name="udn"
                  type="text"
                  value={udnValue}
                  onChange={(e) => {
                    setUdnValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="upc"> UPC:</InputLabel>
                <Input
                  id="upc"
                  name="upc"
                  type="text"
                  value={upcValue}
                  onChange={(e) => {
                    setUpcValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
                <Input
                  id="motherBreed"
                  name="motherBreed"
                  type="text"
                  value={motherBreedValue}
                  onChange={(e) => {
                    setMotherBreedValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
                <Input
                  id="fatherBreed"
                  name="fatherBreed"
                  type="text"
                  value={fatherBreedValue}
                  onChange={(e) => {
                    setFatherBreedValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="description"> Description:</InputLabel>
                <Input
                  id="description"
                  name="description"
                  type="text"
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="width"> Width:</InputLabel>
                <Input
                  id="width"
                  name="width"
                  type="text"
                  value={widthValue}
                  onChange={(e) => {
                    setWidthValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="height"> Height:</InputLabel>
                <Input
                  id="height"
                  name="height"
                  type="text"
                  value={heightValue}
                  onChange={(e) => {
                    setHeightValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="depth"> Depth:</InputLabel>
                <Input
                  id="depth"
                  name="depth"
                  type="text"
                  value={depthValue}
                  onChange={(e) => {
                    setDepthValue(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="whiteLabelCost">
                  {" "}
                  WhiteLabel Cost:
                </InputLabel>
                <Input
                  id="whiteLabelCost"
                  name="whiteLabelCost"
                  type="text"
                  value={whiteLabelCostValue}
                  onChange={(e) => {
                    setWhiteLabelCostValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
                <Input
                  id="sellingCost"
                  name="sellingCost"
                  type="text"
                  value={sellingCostValue}
                  onChange={(e) => {
                    setSellingCostValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
                <Input
                  id="acquireCost"
                  name="acquireCost"
                  type="text"
                  value={acquireCostValue}
                  onChange={(e) => {
                    setAcquireCostValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude:</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={altitudeValue}
                  onChange={(e) => {
                    setAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createAssetClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCreateAssetPopup(!getCreateAssetPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Create Asset */}
      </div>
      {/* <CustomPopup
        closeButton={closeSiteModal}
        isOpen={getSitePopUp}
        closeModal={getSitePopUp}
        content={siteModelBody}
        title={siteAgreement}
      /> */}
      <CustomPopup
        closeButton={closeSiteWithNodeModal}
        isOpen={showCreateSitePopup}
        closeModal={showCreateSitePopup}
        content={siteCreationWithNodeModelBody}
        title={siteAndNodeCreationTitle}
      />
      <CustomPopup
        closeButton={closeSiteCreationWithNodeModal}
        isOpen={showCreateSiteDialog}
        closeModal={showCreateSiteDialog}
        content={siteCreationModelBody}
        title={siteAndNodeCreationTitle}
      />
      <CustomPopup
        closeButton={closeSiteModal}
        isOpen={getSitePopUp}
        closeModal={getSitePopUp}
        content={siteModelBody}
        title={siteCreationTitle}
      />
    </React.Fragment>
  );
}
