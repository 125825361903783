import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import "./HomeBanner.css";
import { NavLink } from "react-router-dom";
export default function HomeBanner() {
  return (
    <div className="container-banner">
      {/* <div className="overlay__bg"></div> */}
      <Container>
        <Grid container spacing={2}>
          <Grid item md={9} sm={12} xs={12} className="p-0">
            <div className="abn-content">
              <h1>
                {/* Creating Digital Trust among online Agrifood MSME community
                members to transact securely */}
                {/* We help Agri MSME to transform Digitally by providing Ecosystem and ABN */}
                We transform Agri-MSMEs founders to digital entrepreneurs so that they can monetize unutilized capacities to maximize the profit              </h1>
              <p>
                {/* Export Globally | Transform Agri-assets Production | Raise
                Investments for Investable Asset | Browse Schemes and Certifications | Access to
                Machinery | Build Own Community to Source & Local Trade |
                Transform Distributed Processing at Farmgate | Transform Supply chain into Value Chain */}

                Monetize unutilized capacity | Improve Top line | Access to Investable Assets 
                
              </p>
              <Button variant="contained" color="primary">
                <NavLink     to={{
                  pathname: "auth/3/signup",
                  state: { customData: "present" },
                }}>Become a Digital Entrepreneur </NavLink>
              </Button>
              {/* <br className="d-md-none d-block"></br>
              <Button
                variant="contained"
                color="primary"
                className="ms-md-3 ms-0 mt-md-0 mt-3"
              >
                <NavLink to="/ForSMEs">Explore Trusted Community </NavLink>
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
