import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainService from "../../Common/MainService";
import "./ProfilePageComponent.css";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  media: {
    width: 250,
    height: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
}));

export default function ProfilePageComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consumerUserid, setConsumerUserId] = useState("");
  const [consumerFirstName, setConsumerFirstName] = useState("");
  const [consumerLastName, setConsumerLastName] = useState("");
  const [consumerEmail, setConsumerEmail] = useState("");
  const [consumerPhone, setConsumerPhone] = useState("");
  const [consumerAddress, setConsumerAddress] = useState("");
  const [consumerState, setConsumerState] = useState("");
  const [consumerCity, setConsumerCity] = useState("");
  const [consumerNominee, setConsumerNominee] = useState("");
  const [consumerPancard, setConsumerPancard] = useState("");
  const [profilePhotoPopup, setShowProfilePhotoPopup] = useState(false);
  const [showManageProfileDetails, setshowManageProfileDetails] =
    useState(false);
  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
  const [, setConsumerMessage] = useState("");
  const [, setConsumerLatitude] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  //const [, setConsumerLongitude] = useState("");
  const [state, setState] = useState({
    data: [],
    imgData: [],
  });
  const [getImageLink, setgetImageLink] = useState("");

  useEffect(() => {
    getProfile();
    getUserImage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProfile = () => {
    
    MainService.getConsumerUserInfo(parentId,username)
      .then((data) => {
       
          setIsLoading(false);
          setState({ ...state, data: data });

       
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error("No profile data found");
      });
  };
  const getUserImage = () => {
    
    MainService.getUserImag(parentId,username)
      .then((data) => {
        let imagedata = data[0].imagedata;
        let base64Img = "data:image/png;base64,"+imagedata;
        console.log("profile dataa:",data)
        var size = Object.keys(data[0]).length;
        if (data[0].imagedata === "null" || size === 0) {
          setgetImageLink("https://www.jquery-az.com/html/images/banana.jpg");
        } else setgetImageLink(base64Img);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const toggleManageProfileDetails = () => {
    setshowManageProfileDetails(!showManageProfileDetails);
  };

  const toggleUpdateProfilePhoto = () => {
    setShowProfilePhotoPopup(!profilePhotoPopup);
  };
  const doManageProfileDetails = (evt) => {
    evt.preventDefault();
    const firstname = document.getElementById("firstname").value;
    const lastname = document.getElementById("lastname").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const address = document.getElementById("address").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("state").value;
    const nominee = document.getElementById("nominee").value;
    const pancard = document.getElementById("pancard").value;
    //const longitude = document.getElementById("longitude").value;
    //const latitude = document.getElementById("latitude").value;
    //const message = document.getElementById("message").value;
    if (!email || email === "") {
      return NotificationManager.warning("Enter valid Email.");
    }
    if (!firstname || firstname === "") {
      return NotificationManager.warning("Enter valid First Name.");
    }
    if (!lastname || lastname === "") {
      return NotificationManager.warning("Enter valid Last Name.");
    }
    if (!phone || phone === "") {
      return NotificationManager.warning("Enter valid Phone Number.");
    }
    if (!address || address === "") {
      return NotificationManager.warning("Enter valid Address.");
    }
    if (!city || city === "") {
      return NotificationManager.warning("Enter valid City.");
    }
    if (!state || state === "") {
      return NotificationManager.warning("Enter valid State.");
    }
    if (!nominee || nominee === "") {
      return NotificationManager.warning("Enter valid Nominee.");
    }
    if (!pancard || pancard === "") {
      return NotificationManager.warning("Enter valid Pancard.");
    }
    /* if (!longitude || longitude === "") {
      return NotificationManager.warning("Enter valid Longitude.");
    }
    if (!latitude || latitude === "") {
      return NotificationManager.warning("Enter valid Latitude.");
    } */

    setIsLoading(true);

   

    const payload = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      address: address,
      city: city,
      state: state,
      nominee: nominee,
      pancard: pancard,
      /* longitude: longitude,
      latitude: latitude, 
      message: message, */
    };
    const payloadData = JSON.stringify(payload);
    console.log("user info payload:", payload);
    MainService.updateUserInfo(username, consumerUserid, payloadData)
      .then((data) => {
        console.log("res use info data:", data);
        getProfile();
        setIsLoading(false);
        toggleManageProfileDetails();
        return NotificationManager.success("profile updated.");
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        toggleManageProfileDetails();
        return NotificationManager.error("profile not updated.");
      });
  };

  const uploadProfilePhotoClick = (evt) => {
    evt.preventDefault();

    if(profilePhotoFile)
    {
      setIsLoading(true);
      var profilePhotoBody = new FormData(); // Means body data for multipart use case

      profilePhotoBody.append("file", profilePhotoFile);
      
  
      MainService.uploadUSerImage(username,profilePhotoFile)
        .then((data) => {
          getProfile();
          setIsLoading(false);
          toggleUpdateProfilePhoto();
          return NotificationManager.success("profile photo updated.");
        })
        .catch((e) => {
          setIsLoading(false);
          toggleUpdateProfilePhoto();
          return NotificationManager.error("profile not updated");
        });
    }else{
      return NotificationManager.error("please choose file")
    }
  };

  const updateProfileDetailsClick = (consumers) => {
    setConsumerUserId(consumers.userid);
    setConsumerAddress(consumers.address);
    setConsumerCity(consumers.city);
    setConsumerEmail(consumers.email);
    setConsumerFirstName(consumers.firstname);
    setConsumerLastName(consumers.lastname);
    setConsumerLatitude(consumers.lastname);
    //setConsumerLongitude(consumers.longitude);
    setConsumerMessage(consumers.message);
    setConsumerNominee(consumers.nominee);
    setConsumerPancard(consumers.pancard);
    setConsumerPhone(consumers.phone);
    setConsumerState(consumers.state);
    toggleManageProfileDetails();
    
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
    
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
    
  };
  
  const updateProfilePhotoClick = () => {
    toggleUpdateProfilePhoto();
  };

  /* Update Profile Details */
  const manageProfileDetails = (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={5} md={4} lg={3} className="modalGridBody">
        <div className="updateProfileContainer">
          <span
            className="closeButton"
            style={{
              width: "100%",
              textAlign: "right",
              color: "red",
              fontSize: "23px",
              float: "right",
              padding: "0px 15px 0px 0px",
            }}
            onClick={toggleManageProfileDetails}
          >
            X
          </span>
          <form id="updateProfileForm" onSubmit={doManageProfileDetails}>
            <h2>Update Profile Details</h2>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">Email/Username *</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="mail"
                value={consumerEmail}
                onChange={(e) => setConsumerEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> alternate_email </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstname">First Name *</InputLabel>
              <Input
                id="firstname"
                name="firstname"
                type="text"
                value={consumerFirstName}
                onChange={(e) => setConsumerFirstName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastname">Last Name *</InputLabel>
              <Input
                id="lastname"
                name="lastname"
                type="text"
                value={consumerLastName}
                onChange={(e) => setConsumerLastName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Phone *</InputLabel>
              <Input
                id="phone"
                name="phone"
                type="tel"
                value={consumerPhone}
                onChange={(e) => setConsumerPhone(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> call </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="address">Address *</InputLabel>
              <Input
                id="address"
                name="address"
                type="text"
                value={consumerAddress}
                onChange={(e) => setConsumerAddress(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City *</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={consumerCity}
                onChange={(e) => setConsumerCity(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State *</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={consumerState}
                onChange={(e) => setConsumerState(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee *</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={consumerNominee}
                onChange={(e) => setConsumerNominee(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard *</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={consumerPancard}
                onChange={(e) => setConsumerPancard(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>

            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Save
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleManageProfileDetails}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  /*  Add Profile Photo */
  const profilePhotoBody = (
    <Grid container spacing={0}>
      <Grid
        item
        xs={11}
        sm={5}
        md={4}
        lg={3}
        className="modalGridBody"
        style={{ margin: "75px auto 0 !important" }}
      >
        <span
          className="closeButton"
          style={{
            width: "100%",
            textAlign: "right",
            color: "red",
            fontSize: "23px",
            float: "right",
            padding: "5px 15px 0px 0px",
          }}
          onClick={toggleUpdateProfilePhoto}
        >
          X
        </span>
        <div className="forgotPwdContainer">
          <form id="profilePhotoForm" onSubmit={uploadProfilePhotoClick}>
            <h2>Upload Profile Photo</h2>
            <FormControl fullWidth={true} className="loginFields">
              <Input
                name="profilePhoto"
                type="file"                
                accept="image/gif, image/jpeg, image/png"
                onChange={(e) => {
                  setProfilePhotoFile(
                    e.target.files[0],
                    URL.createObjectURL(e.target.files[0])
                  );

                  console.log(
                    "selected file before uploading",
                    e.target.files[0]
                  );
                }}
                hidden={true}
              />
            </FormControl>
            <br />
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Upload
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleUpdateProfilePhoto}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Grid>
          {/* <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          
        </div> */}
            <h2>Profile Details</h2>
          
            {isLoading ? (
              <div className="cardStyleForDashboardItems"><CircularProgress /></div>
            ) : (
              <div className="cardStyleForDashboardItems">
                {state.data ? (
                  state.data.map((consumers, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <img
                        alt=""
                        src={getImageLink}
                        width="100"
                        height="100"
                      ></img>
                      <p>
                        <b>username:</b>
                        {consumers.username}
                      </p>
                      <p>
                        <b>firstname:</b>
                        {consumers.firstname}
                      </p>
                      <p>
                        <b>lastname:</b>
                        {consumers.lastname}
                      </p>
                      <p>
                        <b>email:</b>
                        {consumers.email}
                      </p>
                      <p>
                        <b>phone:</b>
                        {consumers.phone}
                      </p>
                      <p>
                        <b>address:</b>
                        {consumers.address}
                      </p>
                      <p>
                        <b>state:</b>
                        {consumers.state}
                      </p>
                      <p>
                        <b>City:</b>
                        {consumers.city}
                      </p>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.uploadButton}
                        onClick={() => updateProfileDetailsClick(consumers)}
                      >
                        Update
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.uploadButton}
                        onClick={() => updateProfilePhotoClick()}
                      >
                        Add Profile Photo
                      </Button>
                    </div>
                  ))
                ) : (
                  <div>
                    <h4
                      style={{
                        float: "left",
                        marginTop: "7px",
                        marginBottom: "10px",
                      }}
                    >
                      Profile details not found.
                    </h4>
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={profilePhotoPopup}
        onClose={toggleUpdateProfilePhoto}
        aria-labelledby="profilephoto"
        aria-describedby="profilephoto-description"
      >
        {profilePhotoBody}
      </Modal>
      <Modal
        open={showManageProfileDetails}
        onClose={toggleManageProfileDetails}
        aria-labelledby="updateProfile"
        aria-describedby="updateProfile-description"
      >
        {manageProfileDetails}
      </Modal>
    </>
  );
}
