import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./PricingBanner.css"

export default function () {
  return (
    <div className="pricing-banner d-flex align-items-center">
      <Container>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12} className="p-0">
            <h2>
               Pricing
            </h2>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
