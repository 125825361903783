import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Input_Manufacturer_Needs_-_GBR_care_04-03-2024-removebg-preview.png";
import Profit from "../../../images/awareness.svg";
import Quality from "../../../images/sales.svg";


// import "./ChallangesSection.css";

export default function ForInputProviderChallenges() {
  const challanges =[
    {
      title:"Sales",
      description:"Sales are essential for input manufacturers as they generate revenue vital for research, development, and innovation, allowing them to improve product quality, meet evolving market demands, and remain competitive in the industry..",
      image:Quality
    },
    {
      title:"Branding",
      description:"Branding is crucial for input manufacturers to differentiate their products in a competitive market, build trust among consumers, convey quality and reliability, and establish long-term relationships, ultimately enhancing customer loyalty and driving sales growth.",
      image:Profit
    },

    
  //   {
  //   title:"Extra Source of Income",
  //   description:"An additional stream of earnings is sought after by farmers to bolster financial stability and tackle income uncertainties.",
  //   image:Finance
  // },

  // {
  //   title:"Marketing ",
  //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
  //   image:Marketing
  // },
  // {
  //   title:"Loan",
  //   description:"Securing loans is a vital necessity for farmers, to address their financial needs and to enable them to invest in and improve their agricultural practices for sustained productivity.",
  //   image:loan
  // },
  // {
  //   title:"Ecosystem",
  //   description:"The absence of a supportive community in agriculture hampers knowledge sharing. Combined with market access and certification issues, farmers face uncertainties, limiting profitability.",
  //   image:Ecosystem
  // },

  // {
  //   title:"Brand ",
  //   description:"Branding empowers farmers to establish unique identities for their products, enabling differentiation and market recognition. ",
  //   image:Brand
  // }
//  {
//     title:"Easy Technology adoption ",
//     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by farmers.",
//     image:Technology
//   }
]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        Input Manufacturers Needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 ">
                <img src={e.image} alt="Challange Icon" className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p className="mb-0">
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
