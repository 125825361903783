import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

const UserForm = ({
  saveButtonStatus,
  buyerDetails,
  supplierDetails,
  userInputBuyerChangehandler,
  userInputSupplierChangehandler,
  actualInvoice,
  invoice,
}) => {
  const [formValidationError, setFormValidationError] = useState('');

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const {
    buyerName = '',
    buyer_email = '',
    buyer_website = '',
    buyer_gstin = '',
    buyer_panNo = '',
    buyerAdressline1 = '',
    buyerAdressline2 = '',
    buyerAdressline3 = '',
    consigneeState = '',
    buyer_cell: { buyer_cell1 = '', buyer_cell2 = '' } = {},
  } = buyerDetails || {};

  const {
    supplierName = '',
    supplierContact = '',
    sup_email = '',
    sup_gstin = '',
    sup_panNo = '',
    supplierAdressline1 = '',
    supplierAdressline2 = '',
    supplierAdressline3 = '',
    supplierState = '',
    supplierStateCode = '',
  } = supplierDetails || {};

  //Buyer validation
  const isBuyerNameEmpty = !buyerName.trim();
  const isBuyer_cell1 = !buyer_cell1.trim();
  const isBuyerEmailEmpty = !buyer_email.trim();
  const isBuyerGstinEmpty = !buyer_gstin.trim();
  const isBuyerPanNumberEmpty = !buyer_panNo?.trim();
  const isBuyerAddressLine1Empty = !buyerAdressline1.trim();
  const isBuyerAddressLine2Empty = !buyerAdressline2.trim();
  const isBuyerAddressLine3Empty = !buyerAdressline3.trim();
  const isConsigneeStateEmpty = !consigneeState?.trim();

  //Supplier validataion
  const isSupplierNameEmpty = !supplierName.trim();
  const isSupplierContactEmpty = !(supplierContact.trim().length === 10);
  const isSupplierEmailEmpty = !sup_email.trim();
  const isSupplierGstinEmpty = !sup_gstin.trim();
  const isSupplierPanNumberEmpty = !sup_panNo?.trim();
  const isSupplierAddressLine1Empty = !supplierAdressline1.trim();
  const isSupplierAddressLine2Empty = !supplierAdressline2.trim();
  const isSupplierAddressLine3Empty = !supplierAdressline3.trim();
  const isSupplierStateEmpty = !supplierState.trim();
  const isSupplierStateCodeEmpty = !supplierStateCode.trim();

  // Set first empty field error for buyer form
  useEffect(() => {
    let timer;
    const validationForm = () => {
      if (saveButtonStatus) {
        if (isBuyerNameEmpty) {
          setFormValidationError('buyerName');
        } else if (isBuyer_cell1) {
          setFormValidationError('buyer_cell1');
        } else if (isBuyerEmailEmpty || !isValidEmail(buyer_email)) {
          setFormValidationError('buyer_email');
        } else if (isBuyerPanNumberEmpty) {
          setFormValidationError('buyer_panNo');
        } else if (isBuyerGstinEmpty) {
          setFormValidationError('buyer_gstin');
        } else if (isConsigneeStateEmpty && buyerDetails.consigneeState) {
          setFormValidationError('consigneeState');
        } else if (isBuyerAddressLine1Empty) {
          setFormValidationError('buyerAdressline1');
        } else if (isBuyerAddressLine2Empty) {
          setFormValidationError('buyerAdressline2');
        } else if (isBuyerAddressLine3Empty) {
          setFormValidationError('buyerAdressline3');
        } else if (isBuyerAddressLine3Empty) {
          setFormValidationError('buyerAdressline3');
        } else if (isSupplierNameEmpty) {
          setFormValidationError('supplierName');
        } else if (isSupplierContactEmpty) {
          setFormValidationError('supplierContact');
        } else if (isSupplierEmailEmpty || !isValidEmail(sup_email)) {
          setFormValidationError('sup_email');
        } else if (isSupplierPanNumberEmpty) {
          setFormValidationError('sup_panNo');
        } else if (isSupplierGstinEmpty) {
          setFormValidationError('sup_gstin');
        } else if (isSupplierAddressLine1Empty) {
          setFormValidationError('supplierAdressline1');
        } else if (isSupplierAddressLine2Empty) {
          setFormValidationError('supplierAdressline2');
        } else if (isSupplierAddressLine3Empty) {
          setFormValidationError('supplierAdressline3');
        } else if (isSupplierStateEmpty) {
          setFormValidationError('supplierState');
        } else if (isSupplierStateCodeEmpty) {
          setFormValidationError('supplierStateCode');
        } else {
          setFormValidationError('');
        }
      }
    };
    // Set the timer to delay the validation consigneeState
    timer = setTimeout(validationForm, 500);
    // Clear the timer if the component unmounts or any dependency changes
    return () => {
      clearTimeout(timer);
    };
  }, [
    buyer_email,
    sup_email,
    isBuyerNameEmpty,
    isBuyer_cell1,
    isBuyerEmailEmpty,
    isBuyerPanNumberEmpty,
    isBuyerGstinEmpty,
    isBuyerAddressLine1Empty,
    isBuyerAddressLine2Empty,
    isBuyerAddressLine3Empty,
    isSupplierNameEmpty,
    isConsigneeStateEmpty,
    isSupplierContactEmpty,
    isSupplierEmailEmpty,
    isSupplierGstinEmpty,
    isSupplierPanNumberEmpty,
    isSupplierAddressLine1Empty,
    isSupplierAddressLine2Empty,
    isSupplierAddressLine3Empty,
    isSupplierStateEmpty,
    isSupplierStateCodeEmpty,
    saveButtonStatus,
  ]);

  return (
    <>
      <Box
        component='form'
        sx={{
          // background: 'lightblue',
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          p: '1rem',
          // width: { xs: '100%', md: '70%', lg: '50%' },
          mx: 'auto',
          mb: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            '& > * ': { background: '#fff', flex: 1 },
            flexDirection: { xs: 'column', md: 'row' },
            gap: '1.5rem',
          }}
        >
          {/* Buyer form */}
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontWeight: '500',
                  color: '#1976d2',
                  fontSize: '1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  mb: '0.5rem',
                }}
              >
                {invoice ? 'Supplier Information' : 'Buyer Information'}
              </Typography>
            </Box>

            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  error={formValidationError === 'buyerName'}
                  id='buyerName'
                  label='Please enter your name'
                  placeholder='Please enter your name'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyerName}
                  name='buyerName'
                  onChange={userInputBuyerChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />
                <TextField
                  error={formValidationError === 'buyer_cell1'}
                  id='buyer_cell1'
                  label='Please enter your primary number'
                  placeholder='Please enter your primary number'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyer_cell1}
                  name='buyer_cell1'
                  onChange={userInputBuyerChangehandler}
                  type='number'
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                  }}
                />
                {!actualInvoice && (
                  <TextField
                    id='buyer_cell2'
                    label='Please enter your alternate number'
                    placeholder='Please enter your alternate number'
                    // helperText='Incorrect entry.'
                    variant='standard'
                    value={buyer_cell2}
                    name='buyer_cell2'
                    onChange={userInputBuyerChangehandler}
                    type='number'
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#008000',
                      },
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                    }}
                  />
                )}

                <TextField
                  error={formValidationError === 'buyer_email'}
                  id='buyer_email'
                  label='Please enter your email address'
                  placeholder='Please enter your email address'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyer_email}
                  name='buyer_email'
                  onChange={userInputBuyerChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />

                <TextField
                  error={formValidationError === 'buyer_website'}
                  id='buyer_website'
                  label='Please enter your website'
                  placeholder='Please enter your website'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyer_website}
                  name='buyer_website'
                  onChange={userInputBuyerChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />

                <TextField
                  error={formValidationError === 'buyer_panNo'}
                  id='buyer_panNo'
                  label='Please enter your PAN number'
                  placeholder='Please enter your PAN number'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyer_panNo}
                  name='buyer_panNo'
                  onChange={userInputBuyerChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />

                <TextField
                  error={formValidationError === 'buyer_gstin'}
                  id='buyer_gstin'
                  label='Please enter your GSTIN number'
                  placeholder='Please enter your GSTIN number'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={buyer_gstin}
                  name='buyer_gstin'
                  onChange={userInputBuyerChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />

                {actualInvoice && (
                  <TextField
                    error={formValidationError === 'consigneeState'}
                    id='consigneeState'
                    label='Please enter your State Name'
                    placeholder='Please enter your state name'
                    // helperText='Incorrect entry.'
                    variant='standard'
                    value={consigneeState}
                    name='consigneeState'
                    onChange={userInputBuyerChangehandler}
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#008000',
                      },
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontWeight: '1.2rem',
                    display: 'inline-block',
                  }}
                >
                  Address
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    error={formValidationError === 'buyerAdressline1'}
                    id='buyerAdressline1'
                    label='Address line 1'
                    placeholder='Please enter your address line 1'
                    // helperText='Incorrect entry.'
                    variant='standard'
                    value={buyerAdressline1}
                    name='buyerAdressline1'
                    onChange={userInputBuyerChangehandler}
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#008000',
                      },
                    }}
                  />
                  <TextField
                    error={formValidationError === 'buyerAdressline2'}
                    id='buyerAdressline2'
                    label='Address line 2'
                    placeholder='Please enter your address line 2'
                    // helperText='Incorrect entry.'
                    variant='standard'
                    value={buyerAdressline2}
                    name='buyerAdressline2'
                    onChange={userInputBuyerChangehandler}
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#008000',
                      },
                    }}
                  />
                  <TextField
                    error={formValidationError === 'buyerAdressline3'}
                    id='buyerAdressline3'
                    label='Address line 3'
                    placeholder='Please enter your address line 3'
                    // helperText={isBuyerAddressLine3Empty ? 'Please enter' : ''}
                    variant='standard'
                    value={buyerAdressline3}
                    name='buyerAdressline3'
                    onChange={userInputBuyerChangehandler}
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#008000',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Suplier form */}
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontWeight: '500',
                  color: '#1976d2',
                  fontSize: '1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  mb: '0.5rem',
                }}
              >
                {invoice ? 'Buyer Information' : 'Supplier Information'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <TextField
                error={formValidationError === 'supplierName'}
                id='supplierName'
                label='Please enter your name'
                placeholder='Please enter your name'
                // helperText='Incorrect entry.'
                variant='standard'
                value={supplierName}
                name='supplierName'
                onChange={userInputSupplierChangehandler}
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                }}
              />
              <TextField
                error={formValidationError === 'supplierContact'}
                id='supplierContact'
                label='Please enter your phone number'
                placeholder='Please enter your phone number'
                // helperText='Incorrect entry.'
                variant='standard'
                value={supplierContact}
                name='supplierContact'
                onChange={userInputSupplierChangehandler}
                type='number'
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                }}
              />
              <TextField
                error={formValidationError === 'sup_email'}
                id='sup_email'
                label='Please enter your email address'
                placeholder='Please enter your email address'
                // helperText='Incorrect entry.'
                variant='standard'
                value={sup_email}
                name='sup_email'
                onChange={userInputSupplierChangehandler}
                type='email'
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                }}
              />
              <TextField
                error={formValidationError === 'sup_panNo'}
                id='sup_panNo'
                label='Please enter your PAN number'
                placeholder='Please enter your PAN number'
                // helperText='Incorrect entry.'
                variant='standard'
                value={sup_panNo}
                name='sup_panNo'
                onChange={userInputSupplierChangehandler}
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                }}
              />

              <TextField
                error={formValidationError === 'sup_gstin'}
                id='sup_gstin'
                label='Please enter your GSTIN number'
                placeholder='Please enter your GSTIN number'
                // helperText='Incorrect entry.'
                variant='standard'
                value={sup_gstin}
                name='sup_gstin'
                onChange={userInputSupplierChangehandler}
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                }}
              />
              <Typography
                sx={{
                  fontWeight: '500',
                  fontWeight: '1.2rem',
                  display: 'inline-block',
                }}
              >
                Address
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.5rem',
                  flexDirection: 'column',
                }}
              >
                <TextField
                  error={formValidationError === 'supplierAdressline1'}
                  id='supplierAdressline1'
                  label='Address line 1'
                  placeholder='Please enter your address line 1'
                  // helperText='Incorrect entry.'
                  variant='standard'
                  value={supplierAdressline1}
                  name='supplierAdressline1'
                  onChange={userInputSupplierChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />
                <TextField
                  error={formValidationError === 'supplierAdressline2'}
                  id='supplierAdressline2'
                  label='Address line 2'
                  placeholder='Please enter your address line 2'
                  variant='standard'
                  value={supplierAdressline2}
                  name='supplierAdressline2'
                  onChange={userInputSupplierChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />
                <TextField
                  error={formValidationError === 'supplierAdressline3'}
                  id='supplierAdressline3'
                  label='Address line 3'
                  placeholder='Please enter your address line 3'
                  variant='standard'
                  value={supplierAdressline3}
                  name='supplierAdressline3'
                  onChange={userInputSupplierChangehandler}
                  sx={{
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                  }}
                />
                {invoice && (
                  <>
                    <TextField
                      error={formValidationError === 'supplierState'}
                      id='supplierState'
                      label='State'
                      placeholder='Please enter your State'
                      variant='standard'
                      value={supplierState}
                      name='supplierState'
                      onChange={userInputSupplierChangehandler}
                      sx={{
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#008000',
                        },
                      }}
                    />
                    <TextField
                      error={formValidationError === 'supplierStateCode'}
                      id='supplierStateCode'
                      label='State Code'
                      placeholder='Please enter your State Code'
                      variant='standard'
                      value={supplierStateCode}
                      name='supplierStateCode'
                      onChange={userInputSupplierChangehandler}
                      sx={{
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#008000',
                        },
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserForm;
