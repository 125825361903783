import React from "react";
import Icon4 from "../../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../../images/gbrFarmerBenefits/save-money.svg";
import Icon1 from "../../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon2 from "../../../images/gbrFarmerBenefits/economic-growth.svg";

import Icon7 from "../../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon6 from "../../../images/gbrFarmerBenefits/breakdown.svg";
import Icon10 from "../../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";

export default function MSMEBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Grow agri-assets & Earn more",
      bodycopy:
        "Cultivate and manage agricultural assets on behalf of a third party, leveraging expertise to optimize growth and enhance returns.",
    },
    {
      image: Icon10,
      title: "Access to Market ",
      bodycopy:
      "Access to local and global markets empowers MSMEs with expanded opportunities for sales and economic growth."
    },
    {
      image: Icon6,
      title: "Access to Machinery & agri-assets",
      bodycopy:
        "MSMEs benefit from facilitated access to machinery & agri-assets, enhancing operational efficiency and productivity."
    },

    {
        image: Icon5,
        title: "Access to Finance ",
        bodycopy:
          "MSMEs gain access to finance through our support, facilitating investments in their operations and nurturing sustainable growth.",
      },
    {
      image: Icon7,
      title: "Access to Technology ",
      bodycopy:
        "GBR Farming provides MSMEs with enhanced access to technology, fostering efficiency and innovation in agricultural practices. "
    },
    {
      image: Icon1,
      title: "Monetize unutilized capacity ",
      bodycopy:
        "Monetizing unutilized capacity allows businesses to boost earnings by efficiently using their operational capabilities, unlocking additional revenue streams and improving overall profitability. "
    },
    {
      image: Icon2,
      title: "Improve Top line",
      bodycopy:
        "Experience exponential growth as sales revenue soars to new heights, unlocking unprecedented success for your business. "
    },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Agri-MSMEs Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
