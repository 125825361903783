import React from 'react';
import { CForm, CRow, CCol, CCard, CCardHeader } from '@coreui/react';
// import {
//   TextField,
//   Button,
//   makeStyles,
//   Typography,
//   Select,
//   Snackbar,
// } from "@material-ui/core";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from "@material-ui/core/FormControl";
import Geocode from 'react-geocode';
import RouteMap from '../../RouteMap/RouteMap';
import MainService from '../../../Common/MainService';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  styled,
} from '@mui/material';

const google = window.google;

const useStyles = styled((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class AddRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locArray: [],
      routeDetails: {},
      places: [],
      loc: {},
      steps: [],
      open: false,
      message: '',
      tenantId: localStorage.getItem('tenantId'),
    };
    this.props = { ...props };
  }

  componentDidMount() {
    this.getLocationInfo();
  }

  getLocationInfo = async () => {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const data = await MainService.getLocationInfo(parentId, username);
    // console.log(data, "get sdata..................")
    this.setState({ locArray: data.locList });
    console.log(this.state.locArray, 'locarray.............');
  };

  onSubmit(value) {
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ marker_position: { lat, lng }, geo: { lat, lng } });
      },
      (error) => {
        console.error(error);
      }
    );
    this.setState({ searchValue: value });
  }

  getAddress(value) {
    this.setState({ address: value });
  }

  setGeo(val) {
    this.setState({ geo: val });
  }

  setMapType = (evt) => {
    this.setState({ map_type: evt.target.value });
  };

  setPath(lat, lng) {
    let currentPath = { lat, lng };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      this.setState({ address: results[0].formatted_address });
    });
    this.setState({ marker_position: currentPath });
  }

  clearDrawing() {
    this.setState({ polyPath: [], defaultRadius: 0 });
  }

  undoDrawing() {
    let polyPath = [...this.state.polyPath];
    polyPath.pop();
    this.setState({ polyPath: polyPath });
  }

  submitForm() {
    const {
      user: { parentId, username },
    } = JSON.parse(atob(localStorage.getItem('CBN')));
    const routeData = { ...this.state.routeDetails };
    routeData['geoPath'] = this.state.steps;

    if (!routeData.routeName) {
      this.setState({
        open: true,
        message: 'Please provide a suitable route tag',
      });
      return false;
    }

    if (!routeData.startLocId) {
      this.setState({ open: true, message: 'Please select start location' });
      return false;
    }

    if (!routeData.endLocId) {
      this.setState({ open: true, message: 'Please select end location' });
      return false;
    }

    const data = MainService.addRouteApi(parentId, username, routeData);

    this.props.routeHandler();
    console.log(data, 'add routes..........');
  }

  setDrawingType(type) {
    this.setState({ drawing_type: type });
  }

  changeData(evt) {
    const route = { ...this.state.routeDetails };
    const locArray = this.state.locArray;
    const place = [...this.state.places];
    const lst = locArray.filter((loc) => loc.locId == evt.target.value);
    const loc = { ...this.state.loc };
    if (lst.length > 0) {
      if (evt.target.name === 'startLocId') {
        place[0] = {
          latitude: lst[0].geoLocation.lat,
          longitude: lst[0].geoLocation.lng,
        };
        loc['start'] = lst[0].address.street;
      } else if (evt.target.name === 'endLocId' && place.length > 0) {
        place[1] = {
          latitude: lst[0].geoLocation.lat,
          longitude: lst[0].geoLocation.lng,
        };
        loc['end'] = lst[0].address.street;
      }
    }

    route[evt.target.name] = evt.target.value;
    this.setState({ routeDetails: route, places: place, loc }, () =>
      console.log('routeDetails........', this.state.routeDetails)
    );
  }

  getSteps(steps) {
    this.setState({ steps });
  }

  render() {
    const { open, message } = this.state;
    return (
      <>
        <Snackbar open={open}>
          <Alert severity='error'>{message}</Alert>
        </Snackbar>

        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
              mt: 2,
              '&>*': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <Box sx={{ gap: '1rem' }}>
              <Typography>Route Tag</Typography>
              <TextField
                name='routeName'
                value={this.state.routeDetails.tagName}
                onChange={this.changeData.bind(this)}
                placeholder='enter a route tag'
              />
            </Box>
            <Box>
              <Button variant='text' onClick={() => this.props.routeHandler()}>
                Cancel
              </Button>
              <Button variant='outlined' onClick={this.submitForm.bind(this)}>
                Submit
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, gap: 2, '&>*': { flex: '1' } }}>
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  value={this.state.routeDetails.startLocId}
                  onChange={this.changeData.bind(this)}
                  name='startLocId'
                  inputProps={{
                    id: 'age-native-required',
                  }}
                >
                  <option value=''>Start Location</option>
                  {this.state.locArray.map((loc) => {
                    return <option value={loc.locId}>{loc.locId}</option>;
                  })}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  value={this.state.routeDetails.endLocId}
                  onChange={this.changeData.bind(this)}
                  name='endLocId'
                  inputProps={{
                    id: 'age-native-required',
                  }}
                >
                  <option value=''>End Location</option>
                  {this.state.locArray.map((loc) => {
                    return <option value={loc.locId}>{loc.locId}</option>;
                  })}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, gap: 2, '&>*': { flex: '1' } }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography
                sx={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                Start Location :
              </Typography>
              <Typography>{this.state.loc.start}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography
                sx={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                End Location :
              </Typography>
              <Typography>{this.state.loc.end}</Typography>
            </Box>
          </Box>
          <Box style={{ marginTop: '2px', border: '1px solid' }}>
            <RouteMap
              googleMapURL={
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyCn9hH1MTZGtPKGtb33k7_L3Huh4B6ZkKw&libraries=geometry,drawing,places'
              }
              markers={this.state.places}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: '60vh' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              getSteps={this.getSteps.bind(this)}
              selectedPlace={this.setPath.bind(this)}
            />
          </Box>
        </Box>
      </>
    );
  }
}

export default AddRoute;
