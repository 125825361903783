import React, { useEffect, useState } from 'react';
import MainService from '../../../Common/MainService';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import BuyerQutationManagement from './BuyerQutationManagement';
import PoManagement from './PoManagement';

const BuyerAccounts = () => {
  const [isQuotationManagement, setIsQuotationManagement] = useState(true);
  const [isPOManagement, setIsPOManagement] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  //Quotation Management state handler
  const quotationManagementStatusHandler = () => {
    setIsQuotationManagement(true);
    setIsPOManagement(false);
  };

  //PO Managementstate handler
  const poManagementStateHandler = () => {
    setIsQuotationManagement(false);
    setIsPOManagement(true);
  };

  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.7rem',
          mb: '1rem',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <Button
          variant={isQuotationManagement ? 'contained' : 'outlined'}
          onClick={quotationManagementStatusHandler}
        >
          RFQ Management
        </Button>
        <Button
          variant={isPOManagement ? 'contained' : 'outlined'}
          onClick={poManagementStateHandler}
        >
          PO Management
        </Button>
      </Box>

      {isQuotationManagement && (
        <BuyerQutationManagement userDetails={userDetails} />
      )}
      {isPOManagement && <PoManagement userDetails={userDetails} />}
    </Box>
  );
};

export default BuyerAccounts;
