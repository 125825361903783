import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./BuyInputComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import DeleteContentSubCategory from "../../Common/CommonContent/DeleteContentSubCategory";
import CreateContentSubCategory from "../../Common/CommonContent/CreateContentSubCategory";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function BuyInputComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [buyinputPopup, setShowBuyInputPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("buy-input");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [buyInputsList, setBuyInputsList] = useState([]);
  const [filteredBuyInputsList, setFilteredBuyInputsList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buyinputNameValue, setBuyInputNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [buyinputFile, setBuyInputFile] = useState(null);
  const [buyinputFileDescription, setBuyInputFileDescription] = useState(null);
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  /* create price discovery */
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [selectedVerticalsName, setSelectedVerticalsName] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [getAssetModel, setAssetModel] = useState([]);

  /* delete content */
  const [selectedContentId, setSelectedContentId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        if (data.length > 0) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSubCategory(data[0].contentsubcategoryname);
          setsubCategoriesList(data);
          setIsLoading(false);
        } else {
          setsubCategoriesList(data);
          setSelectedSubCategory("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getCategoryTender(parentId, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setIsLoading(false);
          setBuyInputsList(data);
          setFilteredBuyInputsList(data);
        } else {
          setBuyInputsList(data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!parentId) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };

  /*Delete Sub-Category */
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  /* EOD Delete Sub-Category */

  const submitSubCategoryClick = (evt) => {
    if (!subCategoryNameValue) {
      NotificationManager.warning("Please enter Sub-Category Name");
      return;
    }
    let subCategoryData = {
      categoryid: selectedCategoryId,
      categoryname: selectedCategoryName,
      contentsubcategoryname: subCategoryNameValue,
      parentid: parentId,
      contentsubcategorycreator: username,
    };
    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        getSubCategories();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setSubCategoryDialog(!subcategorydialog);
  };

  const createBuyInputClick = () => {
    setShowBuyInputPopup(!buyinputPopup);
  };
  const submitBuyInputClick = () => {
    const buyinputData = new FormData();
    buyinputData.append("file", buyinputFile);
    buyinputData.append("mimetype", buyinputFile.type);
    buyinputData.append("categoryid", selectedCategoryId);
    buyinputData.append("categoryname", selectedCategoryName);
    buyinputData.append("contentsubcategory", subCategoryNameValue);
    buyinputData.append("contentname", buyinputNameValue);
    buyinputData.append("assetdeveloperid", parentId);
    buyinputData.append("contentdescription", descriptionValue);
    buyinputData.append("contentcreator", username);
    buyinputData.append("contentformat", "DOC");
    buyinputData.append("price", priceValue);
    buyinputData.append("filedescription", buyinputFileDescription);
    buyinputData.append("contentownerid", selectedModel);
    buyinputData.append("unit", "INR");
    buyinputData.append("discountedprice", discountValue);
    buyinputData.append("expirydate", expiryDateValue);
    buyinputData.append("event", "none");

    MainService.createContent(buyinputData, parentId)
      .then((res) => {
        getAllContentsList();
        return NotificationManager.success("BuyInput uploaded");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setShowBuyInputPopup(!buyinputPopup);
  };
  /* Delete Content */
  const deleteContentDialogClick = (buyinput) => {
    if (buyinput.contentcreatorid === username) {
      setSelectedContentId(buyinput.contentid);
      setDeleteContentCreator(buyinput.contentcreatorid);
      setDeleteContentCategory(buyinput.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other creator content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */

  const viewAllBuyInputsClick = () => {
    getAllContentsList();
    setSubCategoryNameValue("");
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* Asset Verticals */

  useEffect(() => {
    getAssetVerticalsIdList();
  }, []);
  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    if (!selectedAssertTypes) return;
    getModelList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          setVerticalsIdList(data);
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalsName(data[0].assetVerticalFriendlyName);
        } else {
          setIsLoading(false);
          setSelectedVerticalsId("");
          setSelectedVerticalsName("");
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
            const newKeyValues = data.map((keyChnage) => ({
              assetmodel: keyChnage.assetType,
            }));
            setAssetModel(newKeyValues);
          } else {
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };
  const getModelList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
            const newKeyValues = data.map((keyChnage) => ({
              assetmodel: keyChnage.modelid,
            }));
            setAssetModel((prevState) =>
              [...prevState, newKeyValues].flatMap((val) => val)
            );
          } else {
            setSelectedModel("");
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  /*END Of Asset Verticals */

  /*Create BuyInput */
  const closeBuyInputModal = (
    <span
      onClick={() => {
        setShowBuyInputPopup(!buyinputPopup);
      }}
    >
      X
    </span>
  );
  const titleBuyInput = "Create BuyInput";
  const buyinputBody = (
    <div style={{ margin: "10px" }}>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="subCategoryName">Sub-Category Name :</InputLabel>
        <Select
          id="subCategoryName"
          name="subCategoryName"
          type="text"
          value={subCategoryNameValue}
          onChange={(e) => {
            setSubCategoryNameValue(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {subCategoriesList &&
            subCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="buyinputName">BuyInput Name :</InputLabel>
        <Input
          id="buyinputName"
          name="buyinputName"
          type="text"
          value={buyinputNameValue}
          onChange={(e) => {
            setBuyInputNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel>Asset Vertical</InputLabel>
        <Select
          id="selectedVerticalsId"
          value={selectedVerticalsId}
          onChange={(event) => {
            setSelectedVerticalsId(event.target.value);
          }}
          label="Select Verticals"
        >
          {verticalsIdList &&
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel>Category</InputLabel>
        <Select
          id="selectedCategory"
          value={selectedCategory}
          onChange={(event) => {
            setSelectedCategory(event.target.value);
          }}
          label="Select Category"
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel>Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => {
            setSelectedAssertTypes(event.target.value);
          }}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel>Content Owner/Model</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => {
            setSelectedModel(event.target.value);
          }}
          label="Select Model"
        >
          {getAssetModel &&
            getAssetModel.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetmodel}>
                {idObj.assetmodel}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentCreator"> Content Creator:</InputLabel>
        <Input
          disabled
          id="contentCreator"
          name="contentCreator"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="price"> Price:</InputLabel>
        <Input
          id="price"
          name="price"
          type="text"
          value={priceValue}
          onChange={(e) => {
            setPriceValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="expiryDate"> Expiry Date:</InputLabel>
        <Input
          id="expiryDate"
          name="expiryDate"
          type="date"
          value={expiryDateValue}
          onChange={(e) => {
            setExpiryDateValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="discount"> Discount:</InputLabel>
        <Input
          id="discount"
          name="discount"
          type="text"
          value={discountValue}
          onChange={(e) => {
            setDiscountValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setBuyInputFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <Box>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={() => {
            submitBuyInputClick();
          }}
        >
          Submit
        </Button>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="error"
          onClick={() => {
            setShowBuyInputPopup(!buyinputPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </div>
  );

  return (
    <>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Upload BuyInput"
          className={classes.fab}
          onClick={() => {
            createBuyInputClick();
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              viewAllBuyInputsClick();
            }}
          >
            View All Buy-Inputs
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              createSubCategoryClick();
            }}
          >
            Create Sub-Category
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryClick();
            }}
          >
            Delete Sub-Category
          </Button>
          <FormControl variant="outlined" size="small" className="postdropdown">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Filter by Sub-Category
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
                const filteredRows = filteredBuyInputsList.filter((row) => {
                  return row.contentsubcategory
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                });
                setBuyInputsList(filteredRows);
              }}
              label="Select Sub-Category"
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          <h2>Buy-Input Details</h2>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Assetdeveloperid
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Uploader
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Organization
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Sub Category Name
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Description
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Price
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        expiry Date
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Download Document
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buyInputsList.length > 0 ? (
                      buyInputsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((buyinput, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {buyinput.parentid}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentid}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.categoryname}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.categoryid}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentcreatorid}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentowner}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentsubcategory}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentdescription}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.price}
                              </TableCell>
                              <TableCell align="center">
                                {buyinput.contentexpirydate}
                              </TableCell>
                              <TableCell align="center">
                                <a
                                  className="downloadDocument"
                                  href={buyinput.locationurl}
                                  download
                                >
                                  Download Document
                                </a>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    deleteContentDialogClick(buyinput);
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          BuyInput Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={buyInputsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  {buyInputsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {buyInputsList.map((buyinput, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Assetdeveloperid:</b>
                              {buyinput.parentid}
                            </p>
                            <p>
                              <b>Content Id:</b>
                              {buyinput.contentid}
                            </p>
                          </span>
                          <p>
                            <b>Category Id:</b>
                            {buyinput.categoryid}
                          </p>
                          <p>
                            <b>Content Creator:</b>
                            {buyinput.contentcreatorid}
                          </p>
                          <p>
                            <b>Category:</b>
                            {buyinput.categoryname}
                          </p>
                          <p>
                            <b>Content Owner:</b>
                            {buyinput.contentowner}
                          </p>
                          <p>
                            <b>Sub Categoryname:</b>
                            {buyinput.contentsubcategory}
                          </p>
                          <p>
                            <b>Description:</b>
                            {buyinput.contentdescription}
                          </p>
                          <p>
                            <b>Price:</b>
                            {buyinput.price}
                          </p>
                          <p>
                            <b>Expiry Date:</b>
                            {buyinput.contentexpirydate}
                          </p>
                          <a
                            className="downloadDocument"
                            href={buyinput.locationurl}
                            download
                          >
                            Download Document
                          </a>
                          <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                deleteContentDialogClick(buyinput);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Buy-Input not found.</h4>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <CreateContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          onOpen={subcategorydialog}
          onClose={createSubCategoryClick}
        />

        <DeleteContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          subCategoryList={subCategoriesList}
          onOpen={deleteSubcategoryDialog}
          onClose={deleteSubCategoryClick}
        />

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedContentId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <CustomPopup
          closeButton={closeBuyInputModal}
          isOpen={buyinputPopup}
          closeModal={buyinputPopup}
          content={buyinputBody}
          title={titleBuyInput}
        />
      </div>
    </>
  );
}
