import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function IaaMarketNavbar() {
    const navData = [
        { to: "/iaaMarketplace", link: " For Agri-MSMEs" },
        { to: "/AgriInvestors", link: " For Agri-Investors " },
      ];
  return (
    <>      <SecondaryNavbar props={navData} />
    </>
  )
}
