import React, { useEffect, useState } from 'react';
import CommanTable from '../components/CommanTable';
import {
  Alert,
  Box,
  Button,
  Divider,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UserForm from '../components/UserForm';
import InvoiceTable from '../components/InvoiceTable';
import dayjs from 'dayjs';
import InvoiceItemForm from '../components/InvoiceItemForm';
import ItemDataTable from '../components/ItemDataTable';
import MainService from '../../MainService';
import { uniqueNumber } from '../utils/genrateUniqueNumber';
import { saveAs } from 'file-saver';

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempProformaInvoiceListDetails = [
  { id: 'invNo', label: 'Invoice Number' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'buyer_email', label: 'Buyer Email' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const tempBuyerData = {
  supplierName: '',
  sup_gstin: '',
  sup_panNo: '',
  sup_email: '',
  supplierContact: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
  supplierState: '',
  supplierStateCode: '',
};

const tempSupplier = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempProformaInvoiceDetailsColumn = [
  { id: 'logo', label: 'Logo', type: 'logo' },
  { id: 'invNo', label: 'Invoice Number' },
  { id: 'purchaseOrderID', label: 'Purchase Order ID' },
  { id: 'invState', label: 'State Name' },
  { id: 'invStateCode', label: 'State Code' },
  { id: 'invDueDate', label: 'Valid Date', type: 'date' },
];
const tempProformaInvoiceDetailsRowData = [
  {
    invNo: 'INV-' + uniqueNumber(),
    purchaseOrderID: '',
    invIssueDate: formattedValue,
    invDueDate: '',
    invState: '',
    invStateCode: '',
  },
];

const tempTranportDetailsLabel = [
  { id: 'transporter', label: 'Transporter' },
  { id: 'vehicleType', label: 'Vehicle Type' },
  { id: 'vehicleNo', label: 'Vehicle No' },
];

const tempTranportDetails = [
  {
    transporter: '',
    vehicleType: '',
    vehicleNo: '',
  },
];

const tempBankdetailsLabel = [
  { id: 'bankAccNo', label: 'Account No.', type: 'number' },
  { id: 'bankName', label: 'Bank Name' },
  { id: 'branchName', label: 'Branch Name' },
  { id: 'bank_IFSC_code', label: 'IFSC Code' },
  { id: 'bank_swift_code', label: 'SWIFT Code' },
];

const tempBankDetailsData = [
  {
    bankName: '',
    branchName: '',
    bankAccNo: '',
    bank_IFSC_code: '',
    bank_swift_code: '',
  },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];
const tempTermConditionValue = [];

const SellerInvoiceManagement = ({ userDetails }) => {
  const [addNewProformaInvoice, setAddNewProformaInvoice] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [proformaInvoiceList, setProformaInvoiceList] = useState([]);
  const [imageFile, setImageFile] = useState({});
  const [tempImage, setTempImage] = useState({});
  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);

  const [proformaInvoiceDetails, setProformaInvoiceDetails] = useState([
    {
      invNo: 'INV-' + uniqueNumber(),
      purchaseOrderID: '',
      invIssueDate: formattedValue,
      invDueDate: '',
      invState: '',
      invStateCode: '',
    },
  ]);
  const [transportDetails, setTransportDetails] = useState(tempTranportDetails);
  const [bankDetails, setBankDetails] = useState(tempBankDetailsData);
  const [invoiceItemDataList, setiInvoiceItemDataList] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [invoiceItem, setInvoiceItem] = useState({
    description: '',
    hsn_sac: '',
    quantity: '',
    unit: '',
    price_per_unit: '',
    totalGst: {
      sGst: 0,
      cGst: 0,
      iGst: 0,
    },
  });
  const [addInvoiceItemError, setAddInvoiceItemError] = useState(false);
  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );
  const [contentdescription, setContentdescription] = useState('');
  const [invoiceType, setInvoiceType] = useState(true);
  const [invoiceTypeName, setInvoiceTypeName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const addNewProformaInvoiceFormHandler = () => {
    setAddNewProformaInvoice(true);
  };

  useEffect(() => {
    const invNo = 'INV-' + uniqueNumber();
    setProformaInvoiceDetails([
      {
        invNo,
        purchaseOrderID: '',
        invIssueDate: formattedValue,
        invDueDate: '',
        invState: '',
        invStateCode: '',
      },
    ]);
  }, [addNewProformaInvoice]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setSupplierDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails]);

  //image file change handler
  const imageFileChangeHandler = (event, columnId) => {
    const { name, files } = event.target;
    if (!files[0]) {
      setImageFile((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[columnId];
        return updatedData;
      });
      setTempImage((prevData) => {
        const updatedData = { ...prevData };
        updatedData[columnId] = ''; // Set a default image or empty string
        return updatedData;
      });
      return;
    }

    setImageFile((prevData) => ({
      ...prevData,
      [columnId]: files[0],
    }));
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setTempImage((prevData) => ({
        ...prevData,
        [columnId]: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const imageChangeHandler = (columnId) => {
    const fileInput = document.getElementById(columnId);
    fileInput.click();
  };

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  //proforma invoice details change handler
  const proformaInvoiceDetailsChangeHandler = (index, field, value) => {
    setProformaInvoiceDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  //Description change Handler
  const descriptionItemChangeHandler = (
    index,
    field,
    value,
    setDescription
  ) => {
    setDescription((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // add invoice item
  const invoiceItemDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    let updatedItem;
    if (name.includes('totalGst.')) {
      const gstName = name.split('.')[1];

      if (gstName === 'cGst' || gstName === 'sGst') {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            [gstName]: value !== '' ? parseFloat(value) : '',
            iGst: 0,
          },
        };
      } else {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            iGst: value !== '' ? parseFloat(value) : '',
            cGst: 0,
            sGst: 0,
          },
        };
      }
    } else {
      updatedItem = {
        ...invoiceItem,
        [name]:
          name === 'quantity' || name === 'price_per_unit'
            ? parseFloat(value)
            : value,
      };
    }
    setInvoiceItem(updatedItem);
  };

  const addInvoiceItemHandler = () => {
    const isObjectEmpty = (obj) =>
      Object.values(obj).some((value) => value === '');
    const isInvoiceItemEmpty = isObjectEmpty(invoiceItem);

    if (isInvoiceItemEmpty) {
      setAddInvoiceItemError(true);
      return;
    }
    setiInvoiceItemDataList([...invoiceItemDataList, invoiceItem]);
  };

  // delete invoice item
  const deleteItemHandler = (row, index) => {
    setiInvoiceItemDataList((prevDtata) => {
      const newDetails = [...prevDtata];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  //post request to save proforma invoice
  const saveProformaDetailsHandler = async () => {
    console.log('save proforma');

    setTimeout(() => {
      setFormSubmitError(false);
    }, 3000);

    const isObjectEmpty = (obj, excludedProperties) => {
      const emptyKeys = Object.entries(obj).filter(
        ([key, value]) => !excludedProperties.includes(key) && value === ''
      );
      return emptyKeys.length === 0;
    };

    const isItemEmpty = (item) =>
      Object.values(item).some(
        (value) =>
          value === '' ||
          (typeof value === 'number' && isNaN(value)) ||
          (typeof value === 'string' && value === 'Invalid Date')
      );

    const optiontionalProperty = ['buyer_cell2', 'buyer_website'];

    const isSupplierDetailsEmpty = !isObjectEmpty(
      supplierDetails,
      optiontionalProperty
    );

    const isProformaInvoiceDetailsEmpty =
      proformaInvoiceDetails.some(isItemEmpty);
    const isBuyerDetailsEmpty = isItemEmpty(buyerDetails);
    const istransportDetailsEmpty = isItemEmpty(transportDetails);
    const isBankDetailsEmpty = isItemEmpty(bankDetails);
    const isinvoiceItemDataListEmpty = invoiceItemDataList.length === 0;
    const iscontentdescriptionEmpty = contentdescription.length === 0;

    console.log('iscontentdescriptionEmpty', iscontentdescriptionEmpty);

    if (
      isProformaInvoiceDetailsEmpty ||
      isSupplierDetailsEmpty ||
      isBuyerDetailsEmpty ||
      istransportDetailsEmpty ||
      isBankDetailsEmpty ||
      isinvoiceItemDataListEmpty ||
      iscontentdescriptionEmpty
    ) {
      // Handle empty fields error, display a message or perform appropriate action
      setSaveButtonStatus(true);
      setFormSubmitError(true);
      return;
    }

    const data = {
      supplierDetails: {
        supplierName: supplierDetails.buyerName,
        sup_cell: {
          sup_cell1: supplierDetails.buyer_cell.buyer_cell1,
          sup_cell2: supplierDetails.buyer_cell.buyer_cell2,
        },
        sup_email: supplierDetails.buyer_email,
        sup_gstin: supplierDetails.buyer_gstin,
        sup_panNo: supplierDetails.buyer_panNo,
        supplieradressline1: supplierDetails.buyerAdressline1,
        supplieradressline2: supplierDetails.buyerAdressline2,
        supplieradressline3: supplierDetails.buyerAdressline3,
        sup_website: supplierDetails.buyer_website,
      },
      receiverDetails: {
        receiverName: buyerDetails.supplierName,
        receiverGstin: buyerDetails.sup_gstin,
        receiverPanNo: buyerDetails.sup_panNo,
        receiverEmail: buyerDetails.sup_email,
        receiverState: proformaInvoiceDetails[0].invState,
        receiverContactNo: buyerDetails.supplierContact,
        receiverAdressline1: buyerDetails.supplierAdressline1,
        receiverAdressline2: buyerDetails.supplierAdressline2,
        receiverAdressline3: buyerDetails.supplierAdressline3,
      },
      consigneeDetails: {
        consigneeName: buyerDetails.supplierName,
        consigneeGstin: buyerDetails.sup_gstin,
        consigneePanNo: buyerDetails.sup_panNo,
        consigneeState: proformaInvoiceDetails[0].invState,
        consigneeEmail: buyerDetails.sup_email,
        consigneeContactNo: buyerDetails.supplierContact,
        consigneeAdressline1: buyerDetails.supplierAdressline1,
        consigneeAdressline2: buyerDetails.supplierAdressline2,
        consigneeAdressline3: buyerDetails.supplierAdressline3,
      },
      invoiceDetails: proformaInvoiceDetails[0],
      transportDetails: transportDetails[0],
      isGstPayable: false,
      bankDetails: bankDetails[0],
      isOriginalForRecipient: invoiceTypeName ? invoiceTypeName : 'Tax Invoice',
      stateToShipDetails: {
        stateToshipName: buyerDetails.supplierState,
        stateToshipCode: buyerDetails.supplierStateCode,
      },
      pickUpDetails: {
        pickUpPlace: supplierDetails.buyerName,
        pickUp_gstin: supplierDetails.buyer_gstin,
        pickUpCell: buyerDetails.supplierContact,
        pickUpAdressline1: supplierDetails.buyerAdressline1,
        pickUpAdressline2: supplierDetails.buyerAdressline2,
        pickUpAdressline3: supplierDetails.buyerAdressline3,
      },
      category: 'Livestock',
      paymentMode: 'Online',
      discPercent: discount,
      deliveryType: 'free delivery',
      orderType: 'Buy order',
      items: invoiceItemDataList,
      termsConditions: [...termsConditions.map((obj) => obj.term)],
    };

    const contentInfo = {
      contentname: `${proformaInvoiceDetails[0].invNo}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: contentdescription,
      expirydate: proformaInvoiceDetails[0].invDueDate,
      assetdeveloperid: 'consumer2',
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('proformaInvoiceLogo', imageFile.logo || '');
    formData.append('sup_sign_Img', imageFile.sign || '');
    formData.append('qrCodeImg', imageFile.qrimage || '');

    console.log('click', contentInfo);
    const response = await MainService.createTaxInvoiceApi(
      `${parentId}/${username}`,
      orgid,
      suborgid,
      formData,
      username
    );

    const tempProformaOrderList = {
      viewLink: response.data.LocationUrl,
      invNo: response.data.contenId,
    };

    if (response.status === 200) {
      handleDownload(response.data.LocationUrl, response.data.contenId);
      setShowAlert(true);
      setProformaInvoiceList((prevList) => [
        ...prevList,
        tempProformaOrderList,
      ]);
      setTimeout(() => {
        cancelButtonHandler();
      }, 3000);
    } else {
      setFormSubmitError(true);
    }

    console.log(response);
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  const cancelButtonHandler = () => {
    setAddNewProformaInvoice(false);
    setShowAlert(false);
    setFormSubmitError(false);
    setSaveButtonStatus(false);
    setImageFile({});
    setTempImage({});
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setProformaInvoiceDetails(tempProformaInvoiceDetailsRowData);
    setTransportDetails(tempTranportDetails);
    setBankDetails(tempBankDetailsData);
    setiInvoiceItemDataList([]);
    setDiscount(0);
    setInvoiceItem({
      description: '',
      hsn_sac: '',
      quantity: '',
      unit: '',
      price_per_unit: '',
      totalGst: {
        sGst: 0,
        cGst: 0,
        iGst: 0,
      },
    });
    setAddInvoiceItemError(false);
    setTermsConditions(tempTermConditionValue);
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  const deleteTerrmHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    const tempInvoiceType = event.target.getAttribute('value');
    setInvoiceTypeName(event.target.getAttribute('value'));
    if (tempInvoiceType === 'Tax Invoice') {
      setInvoiceType(true);
    } else {
      setInvoiceType(false);
    }
    setAnchorEl(null);
  };

  return (
    <>
      {!addNewProformaInvoice && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Invoice Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempProformaInvoiceListDetails}
            rowDataList={proformaInvoiceList}
          />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewProformaInvoiceFormHandler}>
                Add Invoice
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {addNewProformaInvoice && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              {invoiceTypeName ? invoiceTypeName : 'Tax Invoice'}
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box>
            <Box>
              <CommanTable
                columnsData={tempProformaInvoiceDetailsColumn}
                rowsData={proformaInvoiceDetails}
                image={tempImage}
                imageChangeHandler={imageChangeHandler}
                imageFileChangeHandler={imageFileChangeHandler}
                inputFieldChangeHandler={proformaInvoiceDetailsChangeHandler}
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box
              component='form'
              sx={{
                // background: 'lightblue',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                p: '1rem',
                // width: { xs: '100%', md: '70%', lg: '50%' },
                mx: 'auto',
                mb: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > * ': {
                    background: '#fff',
                    flex: 1,
                  },
                  alignItems: 'center',
                  gap: '1.5rem',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    Proforma Description
                  </Typography>

                  <TextField
                    error={saveButtonStatus && contentdescription.length === 0}
                    id='contentdescription'
                    label='Please enter proforma description'
                    variant='standard'
                    sx={{ display: 'flex', flex: 1, '&>*': { width: '100%' } }}
                    onChange={(event) =>
                      setContentdescription(event.target.value)
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant='outlined'
                    value='invoice'
                  >
                    {invoiceTypeName || 'Invoice Type'}
                  </Button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleClose} value='Tax Invoice'>
                      Tax Invoice
                    </MenuItem>
                    <MenuItem onClick={handleClose} value='Bill of Supply'>
                      Bill of Supply
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
            <Box>
              <UserForm
                saveButtonStatus={saveButtonStatus}
                buyerDetails={supplierDetails}
                supplierDetails={buyerDetails}
                userInputBuyerChangehandler={(event) =>
                  formUserInputChangeHandler(event, setSupplierDetails)
                }
                userInputSupplierChangehandler={(event) =>
                  formUserInputChangeHandler(event, setBuyerDetails)
                }
                invoice={true}
              />
            </Box>
            <Box>
              <CommanTable
                columnsData={tempTranportDetailsLabel}
                rowsData={transportDetails}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setTransportDetails
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box>
              <CommanTable
                columnsData={tempBankdetailsLabel}
                rowsData={bankDetails}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setBankDetails
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box>
              <InvoiceItemForm
                invoiceItem={invoiceItem}
                invoiceItemDetailsChangeHandler={
                  invoiceItemDetailsChangeHandler
                }
                addInvoiceItemHandler={addInvoiceItemHandler}
                addInvoiceItemError={addInvoiceItemError}
                saveButtonStatus={saveButtonStatus}
                invoiceType={invoiceType}
              />
              <ItemDataTable
                discount={discount}
                invoiceItemDataList={invoiceItemDataList}
                deleteItemHandler={deleteItemHandler}
                setDiscount={setDiscount}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: '1rem', '&>*': { flex: 1 } }}>
              <CommanTable
                columnsData={tempTermConditionLabel}
                rowsData={termsConditions}
                tableType={'editable'}
                addItemHandler={addTermConditionHandler}
                inputFieldChangeHandler={termsConditionsChangeHandler}
                deleteItemHandler={(rowData, index) =>
                  deleteTerrmHandler(
                    index,
                    termsConditions,
                    setTermsConditions,
                    false
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
              <Box>
                <Box
                  component='form'
                  sx={{
                    padding: '1rem',
                    borderRadius: '1rem',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    mx: 'auto',
                    '& > *:not(:last-child)': { paddingBottom: '0.5rem' },
                    background: '#F6F4F3',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', md: 'row' },
                      '&>*': {
                        flex: 1,
                        height: '10rem',
                        borderRadius: '0.5rem',
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid',
                      }}
                    >
                      {tempImage['qrimage'] && (
                        <img
                          src={tempImage.qrimage}
                          style={{ width: '100%', height: '100%' }}
                          onClick={() => imageChangeHandler('qrimage')}
                        />
                      )}
                      {!tempImage['qrimage'] && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='qrimage'
                        >
                          Please choose qrcode
                        </label>
                      )}

                      <input
                        id='qrimage'
                        name='qrimage'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={(event) =>
                          imageFileChangeHandler(event, 'qrimage')
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid',
                      }}
                    >
                      {tempImage['sign'] && (
                        <img
                          src={tempImage.sign}
                          style={{ width: '100%', height: '100%' }}
                          onClick={() => imageChangeHandler('sign')}
                        />
                      )}
                      {!tempImage['sign'] && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='sign'
                        >
                          Please choose signature
                        </label>
                      )}

                      <input
                        id='sign'
                        name='sign'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={(event) =>
                          imageFileChangeHandler(event, 'sign')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {showAlert && (
              <Alert severity='success' sx={{ mt: '0.5rem' }}>
                Proforma Invoice successfully submitted.
              </Alert>
            )}
            {formSubmitError && (
              <Alert severity='error' sx={{ mt: '0.5rem' }}>
                Please fill the required field.
              </Alert>
            )}

            <Box>
              <Stack>
                <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={cancelButtonHandler}>Cancel</Button>
                  <Button onClick={saveProformaDetailsHandler}>Save</Button>
                </ListItem>
              </Stack>
            </Box>
            {/* <Box>
              <Typography>
                This Proforma Invoice is valid upto the date mentioned at top of
                the documnet.
              </Typography>
              <Typography>
                This proforma Invoice is just to estimate the value based on
                goods or services requested by buyer.
              </Typography>
              <Typography>
                Note: Value can be change in actual invoice as it is just the
                estimation.
              </Typography>
              <Typography>Thanks for the buisness</Typography>
            </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SellerInvoiceManagement;
