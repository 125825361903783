import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./LegacyAssetManagementComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { createTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Typography } from "@material-ui/core"; 
import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function LegacyAssetManagementComponent(props) {
  const classes = useStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [tagsValue, setTagsValue] = useState("");
  const [qrSizeValue, setQrSizeValue] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [assetVerticalIdValue, setAssetVerticalIdValue] = useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [categoryTypeValue, setCategoryTypeValue] = useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelIdValue, setModelIdValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelTypeValue, setModelTypeValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [mimeTypeValue, setMimeTypeValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [imageUrlValue, setImageUrlValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assetsListView, setAssetsListView] = useState(false);
  const [assetsGridView, setAssetsGridView] = useState(true);
  const [qrCodeFile, setQrCodeFile] = useState("");
  const [qrCodeFilePath, setQrCodeFilePath] = useState("");
  const [qrCodeFileDownload, setQrCodeFileDownload] = useState("");
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  

  /********** Asset Onboarding Info ****************/
  const [showOnboarding, setOnboarding] = useState(false);
  const [getViewAssetOnboarding, setViewAssetOnboardingList] = useState([]);

  const [showViewAssetOnboarding, setViewAssetOnboarding] = useState(false);

  const [getUpdateAssetOnboardingInfoPopup, setUpdateAssetOnboardingInfoPopup] =
    useState(false);
  const [getUpdateAssetId, setUpdateAssetId] = useState("");
  const [getWhiteLabelCost, setWhiteLabelCost] = useState("");
  const [getExistingAssetStatus, setExistingAssetStatus] = useState("");
  const [getUpdatedAssetStatus, setUpdatedAssetStatus] = useState("");
  /********** End Of Asset Onboarding Info ****************/
  /********** Birth ****************/
  const [showBirth, setBirth] = useState(false);
  const [showNewBirthInfoPopup, setNewBirthInfoPopup] = useState(false);
  const [getBirthInfos, setBirthInfos] = useState([]);

  const [getNewBirthAssetid, setNewBirthAssetid] = useState("");
  const [getNewBirthAssetFriendlyName, setNewBirthAssetFriendlyName] =
    useState("");
  const [getNewBirthUserid, setNewBirthUserid] = useState(username);
  const [getNewBirthUserName, setNewBirthUserName] = useState("");
  const [getNewBirthSiteid, setNewBirthSiteid] = useState("");
  const [getNewBirthSiteName, setNewBirthSiteName] = useState("");
  const [getNewBirthLotid, setNewBirthLotid] = useState("");
  const [getNewBirthLotName, setNewBirthLotName] = useState("");
  const [getNewBirthLegacyAssetType, setNewBirthLegacyAssetType] = useState("");
  const [getNewBirthModelid, setNewBirthModelid] = useState("");
  const [getNewBirthModelFriendlyName, setNewBirthModelFriendlyName] =
    useState("");
  const [getNewBirthModelType, setNewBirthModelType] = useState("");
  const [getNewBirthModelURL, setNewBirthModelURL] = useState("");
  const [getNewBirthDOB, setNewBirthDOB] = useState("");
  const [getNewBirthGender, setNewBirthGender] = useState("");
  const [getNewBirthMaleParent, setNewBirthMaleParent] = useState("");
  const [getNewBirthFemaleParent, setNewBirthFemaleParent] = useState("");
  const [getNewBirthParentModelid, setNewBirthParentModelid] = useState("");
  const [getNewBirthLatitude, setNewBirthLatitude] = useState("");
  const [getNewBirthLongitude, setNewBirthLongitude] = useState("");
  const [getNewBirthAltitude, setNewBirthAltitude] = useState("");
  const [getNewBirthDescription, setNewBirthDescription] = useState("");

  const [getDeleteBirthInfoPopup, setDeleteBirthInfoPopup] = useState(false);
  const [getDeleteBirthInfo, setDeleteBirthInfo] = useState("");

  const [showUpdateBirthInfoPopup, setUpdateBirthInfoPopup] = useState(false);
  const [getUpdateNewBirthAssetid, setUpdateNewBirthAssetid] = useState("");
  const [
    getUpdateNewBirthAssetFriendlyName,
    setUpdateNewBirthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthUserid, setUpdateUpdateNewBirthUserid] = useState("");
  const [getUpdateNewBirthUserName, setUpdateNewBirthUserName] = useState("");
  const [getUpdateNewBirthSiteid, setUpdateNewBirthSiteid] = useState("");
  const [getUpdateNewBirthSiteName, setUpdateNewBirthSiteName] = useState("");
  const [getUpdateNewBirthLotid, setUpdateNewBirthLotid] = useState("");
  const [getUpdateNewBirthLotName, setUpdateNewBirthLotName] = useState("");
  const [getUpdateNewBirthLegacyAssetType, setUpdateNewBirthLegacyAssetType] =
    useState("");
  const [getUpdateNewBirthModelid, setUpdateNewBirthModelid] = useState("");
  const [
    getUpdateNewBirthModelFriendlyName,
    setUpdateNewBirthModelFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthModelType, setUpdateNewBirthModelType] = useState("");
  const [getUpdateNewBirthModelURL, setUpdateNewBirthModelURL] = useState("");
  const [getUpdateNewBirthDOB, setUpdateNewBirthDOB] = useState("");
  const [getUpdateNewBirthGender, setUpdateNewBirthGender] = useState("");
  const [getUpdateNewBirthMaleParent, setUpdateNewBirthMaleParent] =
    useState("");
  const [getUpdateNewBirthFemaleParent, setUpdateNewBirthFemaleParent] =
    useState("");
  const [getUpdateNewBirthParentModelid, setUpdateNewBirthParentModelid] =
    useState("");
  const [getUpdateNewBirthLatitude, setUpdateNewBirthLatitude] = useState("");
  const [getUpdateNewBirthLongitude, setUpdateNewBirthLongitude] = useState("");
  const [getUpdateNewBirthAltitude, setUpdateNewBirthAltitude] = useState("");
  const [getUpdateNewBirthDescription, setUpdateNewBirthDescription] =
    useState("");
  /********** End Of Birth ****************/
  /********** Back Button ****************/
  const [getBackType, setBackType] = useState("");
  /********** End Of Back Button ****************/
  /********** Attendance Location ****************/
  const [attendancePopup, setAttendancePopup] = useState(false);
  const [attendanceLocationSelectedAssetId,setAttendanceLocationSelectedAssetId] = useState("");
  const [attendanceLocationSelectedAssetName,setAttendanceLocationSelectedAssetName] = useState("");
  const [attendanceLocationParentId,setAttendanceLocationParentId] = useState("");
  const [attendanceLocationUsername,setAttendanceLocationUsername] = useState("");
  const [attendanceLocationLatitudeValue,setAttendanceLocationLatitudeValue] = useState("");
  const [attendanceLocationLongitudeValue,setAttendanceLocationLongitudeValue] = useState("");
  const [attendanceLocationAltitudeValue,setAttendanceLocationAltitudeValue] = useState("");
  const [attendanceLocationDescriptionValue,setAttendanceLocationDescriptionValue] = useState("");

 

  /********** End Of Attendance Location ****************/
  /********** Health Record ****************/
  const [assetsHealthGridView, setAssetsHealthGridView] = useState(false);
  const [newHealthRecordPopup, setNewHealthRecordPopup] = useState(false);
  const [healthRecordsList, setHealthRecordsList] = useState([]);

  const [getNewHealthAssetid, setNewHealthAssetid] = useState("");
  const [getNewHealthAssetFriendlyName, setNewHealthAssetFriendlyName] =
    useState("");
  const [getNewHealthUserid, setNewHealthUserid] = useState(username);
  const [getNewHealthMedicineDate, setNewHealthMedicineDate] = useState("");
  const [getNewHealthVaccinationDate, setNewHealthVaccinationDate] =
    useState("");
  const [getNewHealthExamine, setNewHealthExamine] = useState("");
  const [getNewHealthSurgicalProcedure, setNewHealthSurgicalProcedure] =
    useState("");
  const [getNewHealthVaccinationType, setNewHealthVaccinationType] =
    useState("");
  const [getNewHealthMedicineType, setNewHealthMedicineType] = useState("");
  const [getNewHealthDiseaseType, setNewHealthDiseaseType] = useState("");
  const [getNewHealthTreatmentDate, setNewHealthTreatmentDate] = useState("");
  const [getNewHealthLatitude, setNewHealthLatitude] = useState("");
  const [getNewHealthLongitude, setNewHealthLongitude] = useState("");
  const [getNewHealthAltitude, setNewHealthAltitude] = useState("");
  const [getNewHealthDescription, setNewHealthDescription] = useState("");

  const [getDeleteHealthAssetid, setDeleteHealthAssetid] = useState("");
  const [showDeleteHealthAssetPopup, setDeleteHealthAssetPopup] =
    useState(false);

  const [showUpdateNewHealthRecordPopup, setUpdateNewHealthRecordPopup] =
    useState(false);
  const [getUpdateNewHealthAssetid, setUpdateNewHealthAssetid] = useState("");
  const [
    getUpdateNewHealthAssetFriendlyName,
    setUpdateNewHealthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewHealthUserid, setUpdateNewHealthUserid] = useState("");
  const [getUpdateNewHealthMedicineDate, setUpdateNewHealthMedicineDate] =
    useState("");
  const [getUpdateNewHealthVaccinationDate, setUpdateNewHealthVaccinationDate] =
    useState("");
  const [getUpdateNewHealthExamine, setUpdateNewHealthExamine] = useState("");
  const [
    getUpdateNewHealthSurgicalProcedure,
    setUpdateNewHealthSurgicalProcedure,
  ] = useState("");
  const [getUpdateNewHealthVaccinationType, setUpdateNewHealthVaccinationType] =
    useState("");
  const [getUpdateNewHealthMedicineType, setUpdateNewHealthMedicineType] =
    useState("");
  const [getUpdateNewHealthDiseaseType, setUpdateNewHealthDiseaseType] =
    useState("");
  const [getUpdateNewHealthTreatmentDate, setUpdateNewHealthTreatmentDate] =
    useState("");
  const [getUpdateNewHealthLatitude, setUpdateNewHealthLatitude] = useState("");
  const [getUpdateNewHealthLongitude, setUpdateNewHealthLongitude] =
    useState("");
  const [getUpdateNewHealthAltitude, setUpdateNewHealthAltitude] = useState("");
  const [getUpdateNewHealthDescription, setUpdateNewHealthDescription] =
    useState("");
  /********** End Of Health Record ****************/
  /********** Nutrition ****************/
  const [showNutrition, setNutrition] = useState(false);
  const [getNutritions, setNutritions] = useState([]);

  const [showAddNutritionPopup, setAddNutritionPopup] = useState(false);
  const [getAddNutritionAssetid, setAddNutritionAssetid] = useState("");
  const [getAddNutritionAssetFriendlyName, setAddNutritionAssetFriendlyName] =
    useState("");
  const [getAddNutritionUserid, setAddNutritionUserid] = useState(username);
  const [getAddNutritionFeedType, setAddNutritionFeedType] = useState("");
  const [getAddNutritionSchedule, setAddNutritionSchedule] = useState("");
  const [getAddNutritionLatitude, setAddNutritionLatitude] = useState("");
  const [getAddNutritionLongitude, setAddNutritionLongitude] = useState("");
  const [getAddNutritionAltitude, setAddNutritionAltitude] = useState("");
  const [getAddNutritionDescription, setAddNutritionDescription] = useState("");

  const [showDeleteNutrition, setDeleteNutrition] = useState(false);
  const [getDeleteNutritionAssetid, setDeleteNutritionAssetid] = useState("");

  const [showUpdateAddNutritionPopup, setUpdateAddNutritionPopup] =
    useState(false);
  const [getUpdateAddNutritionAssetid, setUpdateAddNutritionAssetid] =
    useState("");
  const [
    getUpdateAddNutritionAssetFriendlyName,
    setUpdateAddNutritionAssetFriendlyName,
  ] = useState("");
  const [getUpdateAddNutritionUserid, setUpdateAddNutritionUserid] =
    useState("");
  const [getUpdateAddNutritionFeedType, setUpdateAddNutritionFeedType] =
    useState("");
  const [getUpdateAddNutritionSchedule, setUpdateAddNutritionSchedule] =
    useState("");
  const [getUpdateAddNutritionLatitude, setUpdateAddNutritionLatitude] =
    useState("");
  const [getUpdateAddNutritionLongitude, setUpdateAddNutritionLongitude] =
    useState("");
  const [getUpdateAddNutritionAltitude, setUpdateAddNutritionAltitude] =
    useState("");
  const [getUpdateAddNutritionDescription, setUpdateAddNutritionDescription] =
    useState("");
  const [
    getUpdateAddNutritionExistingFeedType,
    setUpdateAddNutritionExistingFeedType,
  ] = useState("");
  /********** End Of Nutrition ****************/
  /********** Weighing ****************/
  const [showWeighing, setWeighing] = useState(false);
  const [getWeighingDetail, setWeighingDetail] = useState([]);
  const [showGenerateQrCodePopup, setShowGenerateQrCodePopup] = useState(false);
  const [showNewWeighingPopup, setNewWeighingPopup] = useState(false);
  const [getNewWeighingAssetid, setNewWeighingAssetid] = useState("");
  const [getNewWeighingAssetFriendlyName, setNewWeighingAssetFriendlyName] =
    useState("");
  const [getNewWeighingUserid, setNewWeighingUserid] = useState(username);
  const [getNewWeighingWeightAtBirth, setNewWeighingWeightAtBirth] =
    useState("");
  const [getNewWeighingWeaningAge, setNewWeighingWeaningAge] = useState("");
  const [getNewWeighingWeaningWeight, setNewWeighingWeaningWeight] =
    useState("");
  const [getNewWeighingAgeAtSlaughter, setNewWeighingAgeAtSlaughter] =
    useState("");
  const [getNewWeighingCarcassWeight, setNewWeighingCarcassWeight] =
    useState("");
  const [getNewWeighingGender, setNewWeighingGender] = useState("");
  const [getNewWeighingLatitude, setNewWeighingLatitude] = useState("");
  const [getNewWeighingLongitude, setNewWeighingLongitude] = useState("");
  const [getNewWeighingAltitude, setNewWeighingAltitude] = useState("");
  const [getNewWeighingDescription, setNewWeighingDescription] = useState("");
  const [getNewWeighingMimetype, setNewWeighingMimetype] = useState("");
  const [getNewWeighingWidth, setNewWeighingWidth] = useState("");
  const [getNewWeighingHeight, setNewWeighingHeight] = useState("");
  const [getNewWeighingDepth, setNewWeighingDepth] = useState("");
  const [getNewWeighingImage, setNewWeighingImage] = useState("");

  const [showWeighingDeletePopup, setWeighingDeletePopup] = useState(false);
  const [getWeighingDeleteAssetid, setWeighingDeleteAssetid] = useState("");

  const [showUpdateNewWeighingPopup, setUpdateNewWeighingPopup] =
    useState(false);
  const [getUpdateNewWeighingAssetid, setUpdateNewWeighingAssetid] =
    useState("");
  const [
    getUpdateNewWeighingAssetFriendlyName,
    setUpdateNewWeighingAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewWeighingUserid, setUpdateNewWeighingUserid] = useState("");
  const [getUpdateNewWeighingWeightAtBirth, setUpdateNewWeighingWeightAtBirth] =
    useState("");
  const [getUpdateNewWeighingWeaningAge, setUpdateNewWeighingWeaningAge] =
    useState("");
  const [getUpdateNewWeighingWeaningWeight, setUpdateNewWeighingWeaningWeight] =
    useState("");
  const [
    getUpdateNewWeighingAgeAtSlaughter,
    setUpdateNewWeighingAgeAtSlaughter,
  ] = useState("");
  const [getUpdateNewWeighingCarcassWeight, setUpdateNewWeighingCarcassWeight] =
    useState("");
  const [getUpdateNewWeighingGender, setUpdateNewWeighingGender] = useState("");
  const [getUpdateNewWeighingLatitude, setUpdateNewWeighingLatitude] =
    useState("");
  const [getUpdateNewWeighingLongitude, setUpdateNewWeighingLongitude] =
    useState("");
  const [getUpdateNewWeighingAltitude, setUpdateNewWeighingAltitude] =
    useState("");
  const [getUpdateNewWeighingDescription, setUpdateNewWeighingDescription] =
    useState("");
  const [getUpdateNewWeighingMimetype, setUpdateNewWeighingMimetype] =
    useState("");
  const [getUpdateNewWeighingWidth, setUpdateNewWeighingWidth] = useState("");
  const [getUpdateNewWeighingHeight, setUpdateNewWeighingHeight] = useState("");
  const [getUpdateNewWeighingDepth, setUpdateNewWeighingDepth] = useState("");
  const [getUpdateNewWeighingImage, setUpdateNewWeighingImage] = useState("");
  /********** End Of Weighing ****************/
  /********** Parturition ****************/
  const [showParturition, setParturition] = useState(false);
  const [getParturitionDetail, setParturitionDetail] = useState([]);

  const [showNewParturitionPopup, setNewParturitionPopup] = useState(false);
  const [getNewParturitionAssetid, setNewParturitionAssetid] = useState("");
  const [
    getNewParturitionAssetfriendlyName,
    setNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getNewParturitionUserid, setNewParturitionUserid] = useState(username);
  const [
    getNewParturitionWeightAtFirstMating,
    setNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getNewParturitionAgeAtFirstParturition,
    setNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getNewParturitionBodyConditionsIndex,
    setNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [getNewParturitionNumberOfMatings, setNewParturitionNumberOfMatings] =
    useState("");
  const [getNewParturitionSupermogram, setNewParturitionSupermogram] =
    useState("");
  const [getNewParturitionBreed, setNewParturitionBreed] = useState("");
  const [getNewParturitionBreedPercentage, setNewParturitionBreedPercentage] =
    useState("");
  const [getNewParturitionBreederId, setNewParturitionBreederId] = useState("");
  const [getNewParturitionBreederAge, setNewParturitionBreederAge] =
    useState("");
  const [getNewParturitionBreedingDate, setNewParturitionBreedingDate] =
    useState("");
  const [getNewParturitionNextBreeding, setNewParturitionNextBreeding] =
    useState("");
  const [
    getNewParturitionExpectedKiddingDate,
    setNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [getNewParturitionDeliveredDate, setNewParturitionDeliveredDate] =
    useState("");
  const [
    getNewParturitionTotalKidsDelivered,
    setNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getNewParturitionDisposal, setNewParturitionDisposal] = useState("");
  const [getNewParturitionLatitude, setNewParturitionLatitude] = useState("");
  const [getNewParturitionLongitude, setNewParturitionLongitude] = useState("");
  const [getNewParturitionAltitude, setNewParturitionAltitude] = useState("");
  const [getNewParturitionDescription, setNewParturitionDescription] =
    useState("");

  const [showParturitionDeletePopup, setParturitionDeletePopup] =
    useState(false);
  const [getParturitionDeleteAssetid, setParturitionDeleteAssetid] =
    useState("");

  const [showUpdateNewParturitionPopup, setUpdateNewParturitionPopup] =
    useState(false);
  const [getUpdateNewParturitionAssetid, setUpdateNewParturitionAssetid] =
    useState("");
  const [
    getUpdateNewParturitionAssetfriendlyName,
    setUpdateNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getUpdateNewParturitionUserid, setUpdateNewParturitionUserid] =
    useState("");
  const [
    getUpdateNewParturitionWeightAtFirstMating,
    setUpdateNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getUpdateNewParturitionAgeAtFirstParturition,
    setUpdateNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getUpdateNewParturitionBodyConditionsIndex,
    setUpdateNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [
    getUpdateNewParturitionNumberOfMatings,
    setUpdateNewParturitionNumberOfMatings,
  ] = useState("");
  const [
    getUpdateNewParturitionSupermogram,
    setUpdateNewParturitionSupermogram,
  ] = useState("");
  const [getUpdateNewParturitionBreed, setUpdateNewParturitionBreed] =
    useState("");
  const [
    getUpdateNewParturitionBreedPercentage,
    setUpdateNewParturitionBreedPercentage,
  ] = useState("");
  const [getUpdateNewParturitionBreederId, setUpdateNewParturitionBreederId] =
    useState("");
  const [getUpdateNewParturitionBreederAge, setUpdateNewParturitionBreederAge] =
    useState("");
  const [
    getUpdateNewParturitionBreedingDate,
    setUpdateNewParturitionBreedingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionNextBreeding,
    setUpdateNewParturitionNextBreeding,
  ] = useState("");
  const [
    getUpdateNewParturitionExpectedKiddingDate,
    setUpdateNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionDeliveredDate,
    setUpdateNewParturitionDeliveredDate,
  ] = useState("");
  const [
    getUpdateNewParturitionTotalKidsDelivered,
    setUpdateNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getUpdateNewParturitionDisposal, setUpdateNewParturitionDisposal] =
    useState("");
  const [getUpdateNewParturitionLatitude, setUpdateNewParturitionLatitude] =
    useState("");
  const [getUpdateNewParturitionLongitude, setUpdateNewParturitionLongitude] =
    useState("");
  const [getUpdateNewParturitionAltitude, setUpdateNewParturitionAltitude] =
    useState("");
  const [
    getUpdateNewParturitionDescription,
    setUpdateNewParturitionDescription,
  ] = useState("");
  /********** End Of Parturition ****************/
  /********** Mortality ****************/
  const [showMortality, setMortality] = useState(false);
  const [getMortalityDetails, setMortalityDetails] = useState([]);

  const [showNewMortalityPopuop, setNewMortalityPopuop] = useState(false);
  const [getNewMortalityAssetid, setNewMortalityAssetid] = useState("");
  const [getNewMortalityAssetFriendlyName, setNewMortalityAssetFriendlyName] =
    useState("");
  const [getNewMortalityUserid, setNewMortalityUserid] = useState(username);
  const [getNewMortalityModelid, setNewMortalityModelid] = useState("");
  const [getNewMortalityBreedPercentage, setNewMortalityBreedPercentage] =
    useState("");
  const [getNewMortalityGender, setNewMortalityGender] = useState("");
  const [getNewMortalityBirthDate, setNewMortalityBirthDate] = useState("");
  const [getNewMortalityExpiredDate, setNewMortalityExpiredDate] = useState("");
  const [getNewMortalityTotalAge, setNewMortalityTotalAge] = useState("");
  const [getNewMortalityCurrentValue, setNewMortalityCurrentValue] =
    useState("");
  const [getNewMortalityCauseOfDeath, setNewMortalityCauseOfDeath] =
    useState("");
  const [getNewMortalityLatitude, setNewMortalityLatitude] = useState("");
  const [getNewMortalityLongitude, setNewMortalityLongitude] = useState("");
  const [getNewMortalityAltitude, setNewMortalityAltitude] = useState("");
  const [getNewMortalityDescription, setNewMortalityDescription] = useState("");

  const [showMortalityDeletePopup, setMortalityDeletePopup] = useState(false);
  const [getMortalityDeleteAssetid, setMortalityDeleteAssetid] = useState("");

  const [showUpdateNewMortalityPopuop, setUpdateNewMortalityPopuop] =
    useState(false);
  const [getUpdateNewMortalityAssetid, setUpdateNewMortalityAssetid] =
    useState("");
  const [
    getUpdateNewMortalityAssetFriendlyName,
    setUpdateNewMortalityAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewMortalityUserid, setUpdateNewMortalityUserid] =
    useState(username);
  const [getUpdateNewMortalityModelid, setUpdateNewMortalityModelid] =
    useState("");
  const [
    getUpdateNewMortalityBreedPercentage,
    setUpdateNewMortalityBreedPercentage,
  ] = useState("");
  const [getUpdateNewMortalityGender, setUpdateNewMortalityGender] =
    useState("");
  const [getUpdateNewMortalityBirthDate, setUpdateNewMortalityBirthDate] =
    useState("");
  const [getUpdateNewMortalityExpiredDate, setUpdateNewMortalityExpiredDate] =
    useState("");
  const [getUpdateNewMortalityTotalAge, setUpdateNewMortalityTotalAge] =
    useState("");
  const [getUpdateNewMortalityCurrentValue, setUpdateNewMortalityCurrentValue] =
    useState("");
  const [getUpdateNewMortalityCauseOfDeath, setUpdateNewMortalityCauseOfDeath] =
    useState("");
  const [getUpdateNewMortalityLatitude, setUpdateNewMortalityLatitude] =
    useState("");
  const [getUpdateNewMortalityLongitude, setUpdateNewMortalityLongitude] =
    useState("");
  const [getUpdateNewMortalityAltitude, setUpdateNewMortalityAltitude] =
    useState("");
  const [getUpdateNewMortalityDescription, setUpdateNewMortalityDescription] =
    useState("");
  /********** End Of Mortality ****************/
  /********** Asset Operational Status ****************/
  const [showOperationalStatus, setOperationalStatus] = useState(false);
  const [getOperationalStatusDetails, setOperationalStatusDetails] = useState(
    []
  );

  const [showNewAssetOperationalPopup, setNewAssetOperationalPopup] =
    useState(false);
  const [getNewAssetOperationalAssetid, setNewAssetOperationalAssetid] =
    useState("");
  const [
    getNewAssetOperationalAssetFriendlyName,
    setNewAssetOperationalAssetFriendlyName,
  ] = useState("");
  const [getNewAssetOperationalUserid, setNewAssetOperationalUserid] =
    useState(username);
  const [getNewAssetOperationalStatus, setNewAssetOperationalStatus] =
    useState("");
  const [getNewAssetOperationalLatitude, setNewAssetOperationalLatitude] =
    useState("");
  const [getNewAssetOperationalLongitude, setNewAssetOperationalLongitude] =
    useState("");
  const [getNewAssetOperationalAltitude, setNewAssetOperationalAltitude] =
    useState("");
  const [getNewAssetOperationalDescription, setNewAssetOperationalDescription] =
    useState("");

  const [showAssetOperationalDeletePopup, setAssetOperationalDeletePopup] =
    useState(false);
  const [getAssetOperationalDeleteAssetid, setAssetOperationalDeleteAssetid] =
    useState("");

  const [
    showUpdateNewAssetOperationalPopup,
    setUpdateNewAssetOperationalPopup,
  ] = useState(false);
  const [
    getUpdateNewAssetOperationalAssetid,
    setUpdateNewAssetOperationalAssetid,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalAssetFriendlyName,
    setUpdateNewAssetOperationalAssetFriendlyName,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalUserid,
    setUpdateNewAssetOperationalUserid,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalStatus,
    setUpdateNewAssetOperationalStatus,
  ] = useState("");
  const [
    getUpdateExistingNewAssetOperationalStatus,
    setUpdateExistingNewAssetOperationalStatus,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalLatitude,
    setUpdateNewAssetOperationalLatitude,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalLongitude,
    setUpdateNewAssetOperationalLongitude,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalAltitude,
    setUpdateNewAssetOperationalAltitude,
  ] = useState("");
  const [
    getUpdateNewAssetOperationalDescription,
    setUpdateNewAssetOperationalDescription,
  ] = useState("");
  /********** End Of Asset Operational Status ****************/
  /**********  Asset Map UnMap Delete Update CrateAsset ****************/

  const [getShowDeletePopup, setShowDeletePopup] = useState(false);
  const [getMappedAssetDeleteAssetid, setMappedAssetDeleteAssetid] =
    useState("");
  const [getMappedAssetDeleteAssetType, setMappedAssetDeleteAssetType] =
    useState("");
  const [getMappedAssetDeleteAssetModelid, setMappedAssetDeleteAssetModelid] =
    useState("");
  const [
    getMappedAssetDeleteAssetVerticalid,
    setMappedAssetDeleteAssetVerticalid,
  ] = useState("");
  const [getMappedAssetDeleteAssetCategort, setMappedAssetDeleteAssetCategory] =
    useState("");

  const [getlegacyUpdatePopup, setLegacyUpdatePopup] = useState(false);

  /********** End Of Asset Map UnMap Delete Update CrateAsset ****************/
  /**************************** Create Asset ********************************/

  const [getCreateAssetPopup, setCreateAssetPopup] = useState(false);
  /********************************** End Of Create Asset***********************/
  /********************************** Selling Cost *************************/
  const [showSelling, setShowSelling] = useState(false);
  const [getSellingDetails, setSellingDetails] = useState([]);

  const [getAddSellingCostPopup, setAddSellingCostPopup] = useState(false);
  const [getNewSellingCostUserid, setNewSellingCostUserid] = useState(username);
  const [getNewSellingCostAssetid, setNewSellingCostAssetid] = useState("");
  const [
    getNewSellingCostAssetFriendlyName,
    setNewSellingCostAssetFriendlyName,
  ] = useState("");
  const [getNewSellingCostSellingCost, setNewSellingCostSellingCost] =
    useState("");
  /********************************** End Of Selling ***********************/
  /********** Patrolling ****************/
  const [showPatrolling, setShowPatrolling] = useState(false);
  const [getViewSitePatrolling, setViewSitePatrolling] = useState([]);

  const [showSitePatrolingPopup, getShowSitePatrolingPopup] = useState(false);
  const [getNewSitePatrolingSiteid, setNewSitePatrolingSiteid] = useState("");
  const [getNewSitePatrolingFriendlyName, setNewSitePatrolingFriendlyName] =
    useState("");
  const [getNewSitePatrolingUserid, setNewSitePatrolingUserid] =
    useState(username);
  const [getNewSitePatrolingMobileNumber, setNewSitePatrolingMobileNumber] =
    useState("");
  const [getNewSitePatrolingLatitude, setNewSitePatrolingLatitude] =
    useState("");
  const [getNewSitePatrolingLongitude, setNewSitePatrolingLongitude] =
    useState("");
  const [getNewSitePatrolingAltitude, setNewSitePatrolingAltitude] =
    useState("");
  const [getNewSitePatrolingDescription, setNewSitePatrolingDescription] =
    useState("");

  const [showUpdateSitePatrolingPopup, getUpdateShowSitePatrolingPopup] =
    useState(false);
  const [getUpdateNewSitePatrolingSiteid, setUpdateNewSitePatrolingSiteid] =
    useState("");
  const [
    getUpdateNewSitePatrolingFriendlyName,
    setUpdateNewSitePatrolingFriendlyName,
  ] = useState("");
  const [getUpdateNewSitePatrolingUserid, setUpdateNewSitePatrolingUserid] =
    useState(username);
  const [
    getUpdateNewSitePatrolingMobileNumber,
    setUpdateNewSitePatrolingMobileNumber,
  ] = useState("");
  const [getUpdateNewSitePatrolingLatitude, setUpdateNewSitePatrolingLatitude] =
    useState("");
  const [
    getUpdateNewSitePatrolingLongitude,
    setUpdateNewSitePatrolingLongitude,
  ] = useState("");
  const [getUpdateNewSitePatrolingAltitude, setUpdateNewSitePatrolingAltitude] =
    useState("");
  const [
    getUpdateNewSitePatrolingDescription,
    setUpdateNewSitePatrolingDescription,
  ] = useState("");

  const [showSitePatrolingDeletePopup, getShowSitePatrolingDeletePopup] =
    useState(false);
  const [getDeleteSiteid, setDeleteSiteid] = useState("");

  const [showAssetPatrolling, setShowAssetPatrolling] = useState(false);
  const [getViewAssetPatrolling, setViewAssetSitePatrolling] = useState([]);
  const [getAssetBackButtton, setAssetBackButtton] = useState("");

  const [getShowNewAssetPatrollingPopup, setShowNewAssetPatrollingPopup] =
    useState(false);
  const [getNewAssetPatrollingAssetid, setNewAssetPatrollingAssetid] =
    useState("");
  const [
    getNewAssetPatrollingAssetFriendlyName,
    setNewAssetPatrollingAssetFriendlyName,
  ] = useState("");
  const [getNewAssetPatrollingUserid, setNewAssetPatrollingUserid] =
    useState(username);
  const [getNewAssetPatrollingMobileNumber, setNewAssetPatrollingMobileNumber] =
    useState("");
  const [getNewAssetPatrollingLatitude, setNewAssetPatrollingLatitude] =
    useState("");
  const [getNewAssetPatrollingLongitude, setNewAssetPatrollingLongitude] =
    useState("");
  const [getNewAssetPatrollingAltitude, setNewAssetPatrollingAltitude] =
    useState("");
  const [getNewAssetPatrollingDescription, setNewAssetPatrollingDescription] =
    useState("");

  const [showAssetPatrolingDeletePopup, getShowAssetPatrolingDeletePopup] =
    useState(false);
  const [getDeleteAssetid, setDeleteAssetid] = useState("");

  const [
    getUpdateShowNewAssetPatrollingPopup,
    setUpdateShowNewAssetPatrollingPopup,
  ] = useState(false);
  const [
    getUpdateNewAssetPatrollingAssetid,
    setUpdateNewAssetPatrollingAssetid,
  ] = useState("");
  const [
    getUpdateNewAssetPatrollingAssetFriendlyName,
    setUpdateNewAssetPatrollingAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewAssetPatrollingUserid, setUpdateNewAssetPatrollingUserid] =
    useState(username);
  const [
    getUpdateNewAssetPatrollingMobileNumber,
    setUpdateNewAssetPatrollingMobileNumber,
  ] = useState("");
  const [
    getUpdateNewAssetPatrollingLatitude,
    setUpdateNewAssetPatrollingLatitude,
  ] = useState("");
  const [
    getUpdateNewAssetPatrollingLongitude,
    setUpdateNewAssetPatrollingLongitude,
  ] = useState("");
  const [
    getUpdateNewAssetPatrollingAltitude,
    setUpdateNewAssetPatrollingAltitude,
  ] = useState("");
  const [
    getUpdateNewAssetPatrollingDescription,
    setUpdateNewAssetPatrollingDescription,
  ] = useState("");
  /********** End Of Patrolling ****************/
  /********** Site Feedback ****************/
  const [showSiteFeedbackModel, setShowSiteFeedbackModel] = useState(false);
  const [getSiteFeedbackSiteid, setSiteFeedbackSiteid] = useState("");

  const [getSiteCustomerExperience, setSiteCustomerExperience] = useState(0);
  const [getSiteCustomerExperienceHover, setSiteCustomerExperienceHover] =
    useState(-1);
  const [getSiteFeedbackCustomerExperience, setSiteFeedbackCustomerExperience] =
    useState("");

  const [getSiteProduct, setSiteProduct] = useState(0);
  const [getSiteProductHover, setSiteProductHover] = useState(-1);
  const [getSiteFeedbackProduct, setSiteFeedbackProduct] = useState("");

  const [getSiteCustomerSupport, setSiteCustomerSupport] = useState(0);
  const [getSiteCustomerSupportHover, setSiteCustomerSupportHover] =
    useState(-1);
  const [getSiteFeedbackCustomerSupport, setSiteFeedbackCustomerSupport] =
    useState("");

  const [getSiteDelivery, setSiteDelivery] = useState(0);
  const [getSiteDeliveryHover, setSiteDeliveryHover] = useState(-1);
  const [getSiteFeedbackDelivery, setSiteFeedbackDelivery] = useState("");

  const [getSiteServiceOthers, setSiteServiceOthers] = useState(0);
  const [getSiteServiceOthersHover, setSiteServiceOthersHover] = useState(-1);
  const [getSiteFeedbackServiceOthers, setSiteFeedbackServiceOthers] =
    useState("");

  const [getSiteFeedbackRemark, setSiteFeedbackRemark] = useState("");
  /********** End Of Site Feedback ****************/
  /********** Asset Feedback ****************/
  const [assetFeedbackPopup, setAssetFeedbackPopup] = useState(false);
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState("");
  const [deliveryExp, setDeliveryExp] = useState("");
  const [productExp, setProductExp] = useState("");
  const [supportExp, setSupportExp] = useState("");
  const [othersExp, setOthersExp] = useState("");
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? "s" : ""}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? "s" : ""}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? "s" : ""}, ${
      labels[othersRate]
    }`;
  }

  /********** End Of Asset Feedback ****************/

  /*****************      Asset Info                  ***************/
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedAssetAssetVerticalId, setSelectedAssetAssetVerticalId] = useState("");
  const [selectedAssetCategory, setSelectedAssetCategory] = useState("");
  const [selectedAssetCategoryType, setSelectedAssetCategoryType] =
    useState("");
  const [selectedAssetDomain, setSelectedAssetDomain] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");
  const [selectedAssetFriendlyName, setSelectedAssetFriendlyName] =
    useState("");
  const [selectedAssetManufacturerId, setSelectedAssetManufacturerId] =
    useState("");
  const [selectedAssetManufacturer, setSelectedAssetManufacturer] =
    useState("");
  const [selectedAssetManufacturerURL, setSelectedAssetManufacturerURL] =
    useState("");
  const [selectedAssetModelId, setSelectedAssetModelId] = useState("");
  const [selectedAssetModelName, setSelectedAssetModelName] = useState("");
  const [selectedAssetModelType, setSelectedAssetModelType] = useState("");
  const [selectedAssetModelURL, setSelectedAssetModelURL] = useState("");
  const [selectedAssetSerialNumber, setSelectedAssetSerialNumber] =
    useState("");
  const [selectedAssetUdn, setSelectedAssetUdn] = useState("");
  const [selectedAssetUpc, setSelectedAssetUpc] = useState("");
  const [selectedAssetWhiteLabelCost, setSelectedAssetWhiteLabelCost] = useState("");
  const [selectedAssetSellingCost, setSelectedAssetSellingCost] = useState("");
  const [selectedAssetImageData, setSelectedAssetImageData] = useState("");
  const [selectedAssetStatus, setSelectedAssetStatus] = useState("");
  const [assetInfoPopup,setAssetInfoPopUp] = useState(false);

  /* Asset Issue */
  const [assetIssuesList, setAssetIssuesList] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [assetIssuesGridView, setAssetIssuesGridView] =useState(false);
  const [assetIssuePopup, setAssetIssuePopup] = useState(false);
  /* End of Asset Issue */

  /* Photo Upload */
  const [selectedCategoryName, setSelectedCategoryName] = useState("image");
  const [uploadPhotoPopup, setShowUploadPhotoPopup] = useState(false);
  const [uploadPhotoFile, setUploadPhotoFile] = useState(null);
  const [uploadPhotoFileDescription, setUploadPhotoFileDescription] = useState(null);
  const [uploadPhotoNameValue, setUploadPhotoNameValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [deleteSubcategoryDialog,setDeleteSubCategoryDialog] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  /* End of Photo Upload */

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    getCategory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if (!selectedCategoryId) return;
    getSubCategories();
  },[selectedCategoryId])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAssetVerticalsIdList();
    getWhiteLabelAssets();
  }, []);

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId,selectedCategory]);

  useEffect(() => {
    getModelsList();
  }, [selectedVerticalsId,selectedCategory,selectedAssertTypes]);

  useEffect(() => {
    getAllSites();
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
  ]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setVerticalsIdList(data);
        } else {
          setSelectedVerticalsId("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),"consumer2")
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");

            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getWhiteLabelAssets = () => {
    setIsLoading(true);

    MainService.getWhiteLabelAssetsForAD(username)
      .then((data) => {
        if (data) {
          setAssetsList(data);
          getFilterUsers(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  
  const assetFeedback = () => {};
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetverticalId.includes(e.target.value);
    });
    setAssetsList(filteredRows);
  };
  const getAllSites = (Type) => {
    if (Type === "List") {
      setAssetsListView(true);
      setAssetsGridView(false);
    } else if (Type === "Grid") {
      setAssetsListView(false);
      setAssetsGridView(true);
    }
    // setIsLoading(true);
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSite(data[0].siteid);
          setSitesList(data);
        } else {
          setSelectedSite("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: siteNameValue,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: modelFriendlyNameValue,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: "AssetDeveloper",
      consumerAdminId: "consumer2",
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    getWhiteLabelAssets();
    setShowAssetPopup(!assetPopup);
  };

  const assetCreateClcik = () => {
    setShowAssetPopup(!assetPopup);
  };
  /*Create Asset */
  const closeAgreementModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleAgreement = "Create Asset";
  const agreementBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetDeveloperId">AssetDeveloper ID:</InputLabel>
        <Input
          disabled
          id="assetDeveloperId"
          name="assetDeveloperId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="5533222"
          value={assetIdValue}
          onChange={(e) => {
            setAssetIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name :
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => {
            setLegacyAssetFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsId(e.target.value);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Sector not found</h4>
          )}
        </Select>
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetVerticalFriendlyName">
          Asset Vertical Friendly Name :
        </InputLabel>
        <Input
          id="assetVerticalFriendlyName"
          name="assetVerticalFriendlyName"
          type="text"
          value={assetVerticalFriendlyNameValue}
          onChange={(e) => {
            setAssetVerticalFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl> */}
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Vertical Friendly Name :</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={assetVerticalFriendlyNameValue}
          onChange={(e) => {
            setAssetVerticalFriendlyNameValue(e.target.value);
          }}
        >
          {verticalsIdList &&
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.friendlyName}>
                {idObj.friendlyName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Domain</InputLabel>
        <Select
          id="assetDomain"
          name="assetDomain"
          type="text"
          value={assetDomainValue}
          onChange={(e) => {
            setAssetDomainValue(e.target.value);
          }}
        >
          <MenuItem selected>Select Any</MenuItem>
          <MenuItem key="Farm" value="Farm">
            Farm
          </MenuItem>
          <MenuItem key="Field" value="Field">
            Field
          </MenuItem>
          <MenuItem key="Enterprise" value="Enterprise">
            Enterprise
          </MenuItem>
          <MenuItem key="Home" value="Home">
            Home
          </MenuItem>
          <MenuItem key="Personal" value="Personal">
            Personal
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSite(e.target.value);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
        <Input
          id="zone"
          name="zone"
          type="text"
          value={zoneValue}
          onChange={(e) => {
            setZoneValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
        <Input
          id="lotName"
          name="lotName"
          type="text"
          value={lotNameValue}
          onChange={(e) => {
            setLotNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerId"> Manufacturer Id *:</InputLabel>
        <Input
          disabled
          id="manufacturerId"
          name="manufacturerId"
          type="text"
          value={manufacturerIdValue}
          onChange={(e) => {
            setManufacturerIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerUrl"> Manufacturer Url:</InputLabel>
        <Input
          id="manufacturerUrl"
          name="manufacturerUrl"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => setSelectedModel(event.target.value)}
          label="Select Model"
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelId"> Model Id*:</InputLabel>
        <Input
          id="modelId"
          name="modelId"
          type="text"
          value={modelIdValue}
          onChange={(e)=>{
            setModelIdValue(e.target.value)
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelFriendlyName">  Model Friendly Name:</InputLabel>
        <Input
          disabled
          id="modelFriendlyName"
          name="modelFriendlyName"
          type="text"
          value={modelFriendlyNameValue}
          onChange={(e)=>{
            setModelFriendlyNameValue(e.target.value)
          }}
        />
      </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
        <Input
          disabled
          id="modelType"
          name="modelType"
          type="text"
          value={selectedModel}
          /* onChange={(e) => {
            setModelTypeValue(e.target.value);
          }} */
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
        <Input
          id="modelUrl"
          name="modelUrl"
          type="text"
          value={modelUrlValue}
          onChange={(e) => {
            setModelUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
        <Input
          id="serialNo"
          name="serialNo"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn"> UDN*:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnValue}
          onChange={(e) => {
            setUdnValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="upc"> UPC:</InputLabel>
        <Input
          id="upc"
          name="upc"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
        <Input
          id="motherBreed"
          name="motherBreed"
          type="text"
          value={motherBreedValue}
          onChange={(e) => {
            setMotherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
        <Input
          id="fatherBreed"
          name="fatherBreed"
          type="text"
          value={fatherBreedValue}
          onChange={(e) => {
            setFatherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="imageUrl"> Image Url:</InputLabel>
        <Input
          id="imageUrl"
          name="imageUrl"
          type="file"
          inputProps={{ accept: 'image/jpg, image/jpeg, image/png' }}
          value={imageUrlValue}
          onChange={(e) => {
            setImageUrlValue(e.target.value);
            setMimeTypeValue(imageUrlValue.split('.').pop());
            console.log("image url:",e.target.value)
            console.log("image url:",e.target.value.split('.').pop())
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mimeType"> Mime Type:</InputLabel>
        <Input
          id="mimeType"
          name="mimeType"
          type="text"
          value={mimeTypeValue}
          onChange={(e) => {
            setMimeTypeValue(imageUrlValue.split('.').pop());
          }}
        />
      </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="width"> Width:</InputLabel>
        <Input
          id="width"
          name="width"
          type="text"
          value={widthValue}
          onChange={(e) => {
            setWidthValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="height"> Height:</InputLabel>
        <Input
          id="height"
          name="height"
          type="text"
          value={heightValue}
          onChange={(e) => {
            setHeightValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="depth"> Depth:</InputLabel>
        <Input
          id="depth"
          name="depth"
          type="text"
          value={depthValue}
          onChange={(e) => {
            setDepthValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whiteLabelCost"> WhiteLabel Cost:</InputLabel>
        <Input
          id="whiteLabelCost"
          name="whiteLabelCost"
          type="text"
          value={whiteLabelCostValue}
          onChange={(e) => {
            setWhiteLabelCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
        <Input
          id="sellingCost"
          name="sellingCost"
          type="text"
          value={sellingCostValue}
          onChange={(e) => {
            setSellingCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude">Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* Mapping Functionality */

  const mapPopUpHandler = (assetId, categoryType) => {
    setMapPopUp(!mapPopUp);
    setMapAssetId(assetId);
    setMapCategoryType(categoryType);
  };
  const closeMapModal = (
    <span
      onClick={() => {
        setMapPopUp(!mapPopUp);
      }}
    >
      X
    </span>
  );
  const createMapping = () => {
    const siteIdData = {
      siteid: mapSiteId,
    };
    const dataSiteId = JSON.stringify(siteIdData);

    MainService.createMapping(username, mapAssetId, mapCategoryType, dataSiteId)
      .then((res) => {
        setMapPopUp(!mapPopUp);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const mapAgreement = "Map Model";
  const mapModelBody = (
    <div>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Id</InputLabel>
        <Select
          id="selectedModel"
          value={mapSiteId}
          onChange={(event) => setMapSiteId(event.target.value)}
          label="Select Model"
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
        <Input
          disabled
          id="assetId"
          name="assetId"
          type="text"
          value={mapAssetId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={mapCategoryType}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={createMapping}
        >
          Create Mapping
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setMapPopUp(!mapPopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Mapping Functionality */

  /* UnMap Asset Handler Functionality */
  const unMapAssetHandler = (assetId, categoryType, siteIds) => {
    const siteId = {
      siteid: siteIds,
    };
    const siteIdData = JSON.stringify(siteId);

    MainService.createUnMapping(assetId, categoryType, siteIdData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* End UnMap Asset Handler Functionality */

  /* Update Legacy Asset */
  const closeUpdateModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );

  const updateAssetHandler = () => {
    const formData = {
      status: updateAssetStatus,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(assetIdVal, statusData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const updateLegacyAssetHandler = (
    assetverticalId,
    assetType,
    assetId,
    friendlyName,
    modelId,
    categoryType,
    udn,
    whitelabelCost,
    sellingCost,
    assetStatus
  ) => {
    setLegacyUpdate(!legacyUpdate);
    setAssetVerticalId(assetverticalId);
    setAssetTypeVal(assetType);
    setAssetIdVal(assetId);
    setAssetFriName(friendlyName);
    setModelIdVal(modelId);
    setCatgeryVal(categoryType);
    setUdnVal(udn);
    setWhiteLabelVal(whitelabelCost);
    setSellingCostVal(sellingCost);
    setExAssetStatus(assetStatus);
  };

  /* Onboarding Api's */

  const onBoardingButtonClickHandler = (siteid, backType) => {
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    viewAssetOnboardingHandler();
  };

  const viewAssetOnboardingHandler = () => {
    setIsLoading(true);
    MainService.ViewAssetOnboardings(username)
      .then((res) => {
        setIsLoading(false);
        setViewAssetOnboardingList(res.readyForOnboardAssets);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const updateAssetOnboardingInfoPopup = (assets) => {
    setUpdateAssetId(assets.assetid);
    setWhiteLabelCost(assets.whitelabelcost);
    setExistingAssetStatus(assets.assetstatus);
    setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
  };

  const updateReadyForOnboardAssetStatusHandler = () => {
    const updateAsset = {
      assetid: getUpdateAssetId,
      whitelabelcost: getWhiteLabelCost,
      assetstatus: getUpdatedAssetStatus,
    };
    const updateData = JSON.stringify(updateAsset);

    MainService.updateReadyForOnboardAssetStatus(updateData)
      .then((res) => {
        setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
        NotificationManager.success("asset status updated");
        viewAssetOnboardingHandler();
      })
      .catch((err) => {
        return NotificationManager.console.error("Something Went to Wrong");
      });
  };

  /* End Of Onboarding Api's */

  /* Birth Api's*/

  const viewMappedAssetsDropdown = (siteid) => {
    /*  MainService.mappedLegacyAssets(siteid,username)
    .then(res=>{
      setMappedAssets(res.assets);
      setNewBirthLegacyAssetType(res.assets[0].assetType);
      setNewBirthLatitude(res.assets[0].latitude);
      setNewBirthLongitude(res.assets[0].longitude);
      setNewBirthModelid(res.assets[0].modelId);
    })
    .catch(err=>{
      return NotificationManager.error(err);
    }) */
  };

  const birthInfoButtonClickHandler = (assetid, assetType) => {
    setNewBirthAssetid(assetid);
    setAssetBackButtton(assetType);
    getBirthDetailsHandler();
    setBirth(true);
    setOnboarding(false);
  };

  const getBirthDetailsHandler = () => {
    setIsLoading(true);
    MainService.getBirthDetails(username)
      .then((res) => {
        setIsLoading(false);
        setBirthInfos(res.birthInfos);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const setNewBirthSiteidHandler = (e) => {
    setUpdateNewBirthSiteid(e.target.value);
    const siteidfilter = getAllSites.filter((sites) => {
      return sites.siteid === e.target.value;
    });
    setUpdateNewBirthSiteName(siteidfilter[0].sitename);
  };

  const createNewBirthInfo = () => {
    const birth = {
      assetid: getNewBirthAssetid,
      assetfriendlyname: getNewBirthAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewBirthUserid,
      username: getNewBirthUserName,
      siteid: getNewBirthSiteid,
      sitename: getNewBirthSiteName,
      lotid: getNewBirthLotid,
      lotname: getNewBirthLotName,
      legacyassettype: getNewBirthLegacyAssetType,
      modelid: getNewBirthModelid,
      modelfriendlyname: getNewBirthModelFriendlyName,
      modeltype: getNewBirthModelType,
      modelurl: getNewBirthModelURL,
      dob: getNewBirthDOB,
      gender: getNewBirthGender,
      maleparent: getNewBirthMaleParent,
      femaleparent: getNewBirthFemaleParent,
      parentmodelid: getNewBirthParentModelid,
      latitude: getNewBirthLatitude,
      longitude: getNewBirthLongitude,
      altitude: getNewBirthAltitude,
      description: getNewBirthDescription,
    };
    const birthinfo = JSON.stringify(birth);

    MainService.addBirthInfo(birthinfo)
      .then((res) => {
        setNewBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNewBirthInfoAssetId = (assetid) => {
    setDeleteBirthInfo(assetid);
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const deleteBirthInfoAsset = () => {
    MainService.deleteBirthInfo(getDeleteBirthInfo)
      .then((res) => {
        setDeleteBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateBirthInfoData = (assetinfo) => {
    setUpdateNewBirthAssetid(assetinfo.assetid);
    setUpdateNewBirthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateUpdateNewBirthUserid(assetinfo.userid);
    setUpdateNewBirthUserName(assetinfo.username);
    setUpdateNewBirthSiteid(assetinfo.siteid);
    setUpdateNewBirthSiteName(assetinfo.sitename);
    setUpdateNewBirthLotid(assetinfo.lotid);
    setUpdateNewBirthLotName(assetinfo.lotname);
    setUpdateNewBirthLegacyAssetType(assetinfo.legacyassettype);
    setUpdateNewBirthModelid(assetinfo.modelid);
    setUpdateNewBirthModelFriendlyName(assetinfo.modelfriendlyname);
    setUpdateNewBirthModelType(assetinfo.modeltype);
    setUpdateNewBirthModelURL(assetinfo.modelurl);
    setUpdateNewBirthDOB(assetinfo.dob);
    setUpdateNewBirthGender(assetinfo.gender);
    setUpdateNewBirthMaleParent(assetinfo.maleparent);
    setUpdateNewBirthFemaleParent(assetinfo.femaleparent);
    setUpdateNewBirthParentModelid(assetinfo.parentmodelid);
    setUpdateNewBirthLatitude(assetinfo.latitude);
    setUpdateNewBirthLongitude(assetinfo.longitude);
    setUpdateNewBirthAltitude(assetinfo.altitude);
    setUpdateNewBirthDescription(assetinfo.description);

    setUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
  };

  const updateNewBirthInfoHandler = () => {
    const updatebirth = {
      assetid: getUpdateNewBirthAssetid,
      assetfriendlyname: getUpdateNewBirthAssetFriendlyName,
      siteid: getUpdateNewBirthSiteid,
      sitename: getUpdateNewBirthSiteName,
      lotid: getUpdateNewBirthLotid,
      lotname: getUpdateNewBirthLotName,
      legacyassettype: getUpdateNewBirthLegacyAssetType,
      modelid: getUpdateNewBirthModelid,
      modelfriendlyname: getUpdateNewBirthModelFriendlyName,
      modeltype: getUpdateNewBirthModelType,
      modelurl: getUpdateNewBirthModelURL,
      dob: getUpdateNewBirthDOB,
      gender: getUpdateNewBirthGender,
      maleparent: getUpdateNewBirthMaleParent,
      femaleparent: getUpdateNewBirthFemaleParent,
      parentmodelid: getUpdateNewBirthParentModelid,
      latitude: getUpdateNewBirthLatitude,
      longitude: getUpdateNewBirthLongitude,
      altitude: getUpdateNewBirthAltitude,
      description: getUpdateNewBirthDescription,
    };
    const updatebirthinfo = JSON.stringify(updatebirth);
    MainService.updateBirthInfo(updatebirthinfo)
      .then((res) => {
        setUpdateBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Birth Api's*/

  /* Attendance Location Api's*/
  const attendanceLocationButtonClickHandler = (asset) => {
    setAttendanceLocationSelectedAssetId(asset.assetId);
    setAttendanceLocationSelectedAssetName(asset.friendlyName);
    setAttendanceLocationUsername(asset.id);
    setAttendancePopup(!attendancePopup);
  };
  const assetAttendanceSubmitClick = () => {
    let attendanceData = {
      assetid: attendanceLocationSelectedAssetId,
      assetfriendlyname: attendanceLocationSelectedAssetName,
      parentid: parentId,
      userid: attendanceLocationUsername,
      latitude: attendanceLocationLatitudeValue,
      longitude: attendanceLocationLongitudeValue,
      altitude: attendanceLocationAltitudeValue,
      description: attendanceLocationDescriptionValue,
    };
    const attendanceDataStringify = JSON.stringify(attendanceData);
    MainService.addAttendanceLocation(attendanceDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAttendancePopup(!attendancePopup);
  };
  

  
  /* End Of Attendance Location Api's*/

  /* Health Records Api's */

  const healthRecordButtonClickHandler = (assetid, assetType) => {
    healthRecordsHandler();
    setNewHealthAssetid(assetid);

    setAssetsListView(false);
    setAssetsGridView(false);
    setAssetsHealthGridView(true);
    
  };

  const healthRecordsHandler = () => {
    setIsLoading(true);
    MainService.getHealthRecords(username)
      .then((res) => {
        setIsLoading(false);
        console.log("health reacords:",res)
        setHealthRecordsList(res.healthRecord);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetHealthSubmitClick = () => {
    const addHealthRecord = {
      assetid: getNewHealthAssetid,
      assetfriendlyname: getNewHealthAssetid,
      parentid: parentId,
      userid: username,
      medicinedate: getNewHealthMedicineDate,
      vaccinationdate: getNewHealthVaccinationDate,
      examine: getNewHealthExamine,
      surgicalprocedure: getNewHealthSurgicalProcedure,
      vaccinationtype: getNewHealthVaccinationType,
      medicinetype: getNewHealthMedicineType,
      diseasetype: getNewHealthDiseaseType,
      treatmentdate: getNewHealthTreatmentDate,
      latitude: getNewHealthLatitude,
      longitude: getNewHealthLongitude,
      altitude: getNewHealthAltitude,
      description: getNewHealthDescription,
    };
    const healthRecord = JSON.stringify(addHealthRecord);

    MainService.addHealthRecords(healthRecord)
      .then((res) => {
        setNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteHealthRecordAssetId = (assetid) => {
    setDeleteHealthAssetid(assetid);
    setDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
  };

  const deleteHealthRecordAsset = () => {
    MainService.deleteHealthRecord(getDeleteHealthAssetid)
      .then((res) => {
        setDeleteHealthAssetPopup(false);
        healthRecordsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateHealthRecordInfo = (assetinfo) => {
    setUpdateNewHealthAssetid(assetinfo.assetid);
    setUpdateNewHealthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewHealthUserid(assetinfo.userid);
    setUpdateNewHealthMedicineDate(assetinfo.medicinedate);
    setUpdateNewHealthVaccinationDate(assetinfo.vaccinationdate);
    setUpdateNewHealthExamine(assetinfo.examine);
    setUpdateNewHealthSurgicalProcedure(assetinfo.surgicalprocedure);
    setUpdateNewHealthVaccinationType(assetinfo.vaccinationtype);
    setUpdateNewHealthMedicineType(assetinfo.medicinetype);
    setUpdateNewHealthDiseaseType(assetinfo.diseasetype);
    setUpdateNewHealthTreatmentDate(assetinfo.treatmentdate);
    setUpdateNewHealthLatitude(assetinfo.latitude);
    setUpdateNewHealthLongitude(assetinfo.longitude);
    setUpdateNewHealthAltitude(assetinfo.altitude);
    setUpdateNewHealthDescription(assetinfo.description);

    setUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup);
  };

  const updateHealthRecordHandler = () => {
    const updateHealthRecord = {
      assetid: getUpdateNewHealthAssetid,
      assetfriendlyname: getUpdateNewHealthAssetFriendlyName,
      medicinedate: getUpdateNewHealthMedicineDate,
      vaccinationdate: getUpdateNewHealthVaccinationDate,
      examine: getUpdateNewHealthExamine,
      surgicalprocedure: getUpdateNewHealthSurgicalProcedure,
      vaccinationtype: getUpdateNewHealthVaccinationType,
      medicinetype: getUpdateNewHealthMedicineType,
      diseasetype: getUpdateNewHealthDiseaseType,
      treatmentdate: getUpdateNewHealthTreatmentDate,
      latitude: getUpdateNewHealthLatitude,
      longitude: getUpdateNewHealthLongitude,
      altitude: getUpdateNewHealthAltitude,
      description: getUpdateNewHealthDescription,
    };
    const healthrecord = JSON.stringify(updateHealthRecord);

    MainService.updateHealthRecord(healthrecord)
      .then((res) => {
        setUpdateNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Worng");
      });
  };
  /* End Of Health Records Api's */

  /* Nutrition Api's */

  const nutritionButtonClickHandler = (assetid, assetType) => {
    getNutritionHandler();
    setAddNutritionAssetid(assetid);
    setAssetBackButtton(assetType);
    
    setNutrition(true);

    setOnboarding(false);
  };

  const getNutritionHandler = () => {
    setIsLoading(true);
    MainService.getNutrition(username)
      .then((res) => {
        setIsLoading(false);
        setNutritions(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const addNutritionHandler = () => {
    const addnutrition = {
      assetid: getAddNutritionAssetid,
      assetfriendlyname: getAddNutritionAssetFriendlyName,
      parentid: "consumer2",
      userid: getAddNutritionUserid,
      feedtype: getAddNutritionFeedType,
      schedule: getAddNutritionSchedule,
      latitude: getAddNutritionLatitude,
      longitude: getAddNutritionLongitude,
      altitude: getAddNutritionAltitude,
      description: getAddNutritionDescription,
    };
    const addnutritionstr = JSON.stringify(addnutrition);

    MainService.addNutrition(addnutritionstr)
      .then((res) => {
        setAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNutririonAssetid = (assetid) => {
    setDeleteNutritionAssetid(assetid);
    setDeleteNutrition(true);
  };

  const deleteNutritionHandler = () => {
    MainService.deleteNutrition(getDeleteNutritionAssetid)
      .then((res) => {
        setDeleteNutrition(false);
        getNutritionHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateNutririonInfo = (assetinfo) => {
    setUpdateAddNutritionAssetid(assetinfo.assetid);
    setUpdateAddNutritionAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateAddNutritionUserid(assetinfo.userid);
    setUpdateAddNutritionFeedType(assetinfo.feedtype);
    setUpdateAddNutritionSchedule(assetinfo.schedule);
    setUpdateAddNutritionLatitude(assetinfo.latitude);
    setUpdateAddNutritionLongitude(assetinfo.longitude);
    setUpdateAddNutritionAltitude(assetinfo.altitude);
    setUpdateAddNutritionDescription(assetinfo.description);
    setUpdateAddNutritionExistingFeedType(assetinfo.feedtype);

    setUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
  };

  const updateNutritionHandler = () => {
    const updateNutrition = {
      assetid: getUpdateAddNutritionAssetid,
      assetfriendlyname: getUpdateAddNutritionAssetFriendlyName,
      feedtype: getUpdateAddNutritionFeedType,
      schedule: getUpdateAddNutritionSchedule,
      latitude: getUpdateAddNutritionLatitude,
      longitude: getUpdateAddNutritionLongitude,
      altitude: getUpdateAddNutritionAltitude,
      description: getUpdateAddNutritionDescription,
    };
    const updateNutritionStr = JSON.stringify(updateNutrition);

    MainService.updateNutrition(updateNutritionStr)
      .then((res) => {
        setUpdateAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Nutrition Api's */

  /* Weighing Api's */

  const weighingButtonClickHandler = (siteid, backType) => {
    weighingDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    

    setWeighing(true);

    setOnboarding(false);
  };

  const weighingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getWeighingDetails(username)
      .then((res) => {
        setIsLoading(false);
        setWeighingDetail(res.weighingDetails);
      })
      .catch((err) => {
        return NotificationManager.eror(err);
      });
  };

  const addWeighingHandler = () => {
    const addweighing = {
      assetid: getNewWeighingAssetid,
      assetfriendlyname: getNewWeighingAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewWeighingUserid,
      weightatbirth: getNewWeighingWeightAtBirth,
      weaningage: getNewWeighingWeaningAge,
      weaningweight: getNewWeighingWeaningWeight,
      ageatslaughter: getNewWeighingAgeAtSlaughter,
      carcassweight: getNewWeighingCarcassWeight,
      gender: getNewWeighingGender,
      latitude: getNewWeighingLatitude,
      longitude: getNewWeighingLongitude,
      altitude: getNewWeighingAltitude,
      description: getNewWeighingDescription,
      mimetype: getNewWeighingMimetype,
      width: getNewWeighingWidth,
      height: getNewWeighingHeight,
      depth: getNewWeighingDepth,
      imagedata: getNewWeighingImage,
    };
    const weighingStr = JSON.stringify(addweighing);

    MainService.addWeighingDetails(weighingStr)
      .then((res) => {
        setNewWeighingPopup(false);
        weighingDetailsHandler();
        return NotificationManager.success("weighing details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something went Worng");
      });
  };

  const deleteWeighingAssetid = (assetid) => {
    setWeighingDeleteAssetid(assetid);
    setWeighingDeletePopup(true);
  };

  const deleteWeighingHandler = () => {
    MainService.deleteWeighingDetails(getWeighingDeleteAssetid)
      .then((res) => {
        setWeighingDeletePopup(false);
        weighingDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateWeighingInfo = (assetinfo) => {
    setUpdateNewWeighingAssetid(assetinfo.assetid);
    setUpdateNewWeighingAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewWeighingUserid(assetinfo.userid);
    setUpdateNewWeighingWeightAtBirth(assetinfo.weightatbirth);
    setUpdateNewWeighingWeaningAge(assetinfo.weaningage);
    setUpdateNewWeighingWeaningWeight(assetinfo.weaningweight);
    setUpdateNewWeighingAgeAtSlaughter(assetinfo.ageatslaughter);
    setUpdateNewWeighingCarcassWeight(assetinfo.carcassweight);
    setUpdateNewWeighingGender(assetinfo.gender);
    setUpdateNewWeighingLatitude(assetinfo.latitude);
    setUpdateNewWeighingLongitude(assetinfo.longitude);
    setUpdateNewWeighingAltitude(assetinfo.altitude);
    setUpdateNewWeighingDescription(assetinfo.description);

    setUpdateNewWeighingPopup(true);
  };

  const addUpdateWeighingHandler = () => {
    const updateweighing = {
      assetid: getUpdateNewWeighingAssetid,
      assetfriendlyname: getUpdateNewWeighingAssetFriendlyName,
      weightatbirth: getUpdateNewWeighingWeightAtBirth,
      weaningage: getUpdateNewWeighingWeaningAge,
      weaningweight: getUpdateNewWeighingWeaningWeight,
      ageatslaughter: getUpdateNewWeighingAgeAtSlaughter,
      carcassweight: getUpdateNewWeighingCarcassWeight,
      gender: getUpdateNewWeighingGender,
      latitude: getUpdateNewWeighingLatitude,
      longitude: getUpdateNewWeighingLongitude,
      altitude: getUpdateNewWeighingAltitude,
      description: getUpdateNewWeighingDescription,
      imagedata: getUpdateNewWeighingImage,
    };
    const weighingStr = JSON.stringify(updateweighing);

    MainService.updateWeighingDetails(weighingStr)
      .then((res) => {
        setUpdateNewWeighingPopup(false);
        weighingDetailsHandler();
        return NotificationManager.success("weighing details updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Weighing Api's */

  /* Parturition Api's */

  const parturitionButtonClickHandler = (assetid, assetType) => {
    getParturitionDetailsHandler();
    setNewParturitionAssetid(assetid);
    setAssetBackButtton(assetType);
    

    setParturition(true);

    setOnboarding(false);
  };

  const getParturitionDetailsHandler = () => {
    setIsLoading(true);
    MainService.getParturitionDetails(username)
      .then((res) => {
        setIsLoading(false);
        setParturitionDetail(res.parturitions);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addParturitionHandler = () => {
    const newparturition = {
      assetid: getNewParturitionAssetid,
      assetfriendlyname: getNewParturitionAssetfriendlyName,
      parentid: "consumer2",
      userid: getNewParturitionUserid,
      weightatfirstmating: getNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getNewParturitionBodyConditionsIndex,
      numberofmatings: getNewParturitionNumberOfMatings,
      supermogram: getNewParturitionSupermogram,
      breed: getNewParturitionBreed,
      breedpercentage: getNewParturitionBreedPercentage,
      breederid: getNewParturitionBreederId,
      breederage: getNewParturitionBreederAge,
      breedingdate: getNewParturitionBreedingDate,
      nextbreeding: getNewParturitionNextBreeding,
      expectedkiddingdate: getNewParturitionExpectedKiddingDate,
      delivereddate: getNewParturitionDeliveredDate,
      totalkidsdelivered: getNewParturitionTotalKidsDelivered,
      disposal: getNewParturitionDisposal,
      latitude: getNewParturitionLatitude,
      longitude: getNewParturitionLongitude,
      altitude: getNewParturitionAltitude,
      description: getNewParturitionDescription,
    };
    const newparturitionStr = JSON.stringify(newparturition);

    MainService.addParturitionDetails(newparturitionStr)
      .then((res) => {
        setNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteParturitionAssetid = (assetid) => {
    setParturitionDeleteAssetid(assetid);
    setParturitionDeletePopup(true);
  };

  const deleteParturitionHandler = () => {
    MainService.deleteParturitionDetails(getParturitionDeleteAssetid)
      .then((res) => {
        setParturitionDeletePopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateParturitionInfo = (ParturitionInfo) => {
    setUpdateNewParturitionAssetid(ParturitionInfo.assetid);
    setUpdateNewParturitionAssetfriendlyName(ParturitionInfo.assetfriendlyname);
    setUpdateNewParturitionUserid(ParturitionInfo.userid);
    setUpdateNewParturitionWeightAtFirstMating(
      ParturitionInfo.weightatfirstmating
    );
    setUpdateNewParturitionAgeAtFirstParturition(
      ParturitionInfo.ageatfirstparturition
    );
    setUpdateNewParturitionBodyConditionsIndex(
      ParturitionInfo.bodyconditionsindex
    );
    setUpdateNewParturitionNumberOfMatings(ParturitionInfo.numberofmatings);
    setUpdateNewParturitionSupermogram(ParturitionInfo.supermogram);
    setUpdateNewParturitionBreed(ParturitionInfo.breed);
    setUpdateNewParturitionBreedPercentage(ParturitionInfo.breedpercentage);
    setUpdateNewParturitionBreederId(ParturitionInfo.breederid);
    setUpdateNewParturitionBreederAge(ParturitionInfo.breederage);
    setUpdateNewParturitionBreedingDate(ParturitionInfo.breedingdate);
    setUpdateNewParturitionNextBreeding(ParturitionInfo.nextbreeding);
    setUpdateNewParturitionExpectedKiddingDate(
      ParturitionInfo.expectedkiddingdate
    );
    setUpdateNewParturitionDeliveredDate(ParturitionInfo.delivereddate);
    setUpdateNewParturitionTotalKidsDelivered(
      ParturitionInfo.totalkidsdelivered
    );
    setUpdateNewParturitionDisposal(ParturitionInfo.disposal);
    setUpdateNewParturitionLatitude(ParturitionInfo.latitude);
    setUpdateNewParturitionLongitude(ParturitionInfo.longitude);
    setUpdateNewParturitionAltitude(ParturitionInfo.altitude);
    setUpdateNewParturitionDescription(ParturitionInfo.description);

    setUpdateNewParturitionPopup(true);
  };

  const updateParturitionHandler = () => {
    const updateparturition = {
      assetid: getUpdateNewParturitionAssetid,
      assetfriendlyname: getUpdateNewParturitionAssetfriendlyName,
      weightatfirstmating: getUpdateNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getUpdateNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getUpdateNewParturitionBodyConditionsIndex,
      numberofmatings: getUpdateNewParturitionNumberOfMatings,
      supermogram: getUpdateNewParturitionSupermogram,
      breed: getUpdateNewParturitionBreed,
      breedpercentage: getUpdateNewParturitionBreedPercentage,
      breederid: getUpdateNewParturitionBreederId,
      breederage: getUpdateNewParturitionBreederAge,
      breedingdate: getUpdateNewParturitionBreedingDate,
      nextbreeding: getUpdateNewParturitionNextBreeding,
      expectedkiddingdate: getUpdateNewParturitionExpectedKiddingDate,
      delivereddate: getUpdateNewParturitionDeliveredDate,
      totalkidsdelivered: getUpdateNewParturitionTotalKidsDelivered,
      disposal: getUpdateNewParturitionDisposal,
      latitude: getUpdateNewParturitionLatitude,
      longitude: getUpdateNewParturitionLongitude,
      altitude: getUpdateNewParturitionAltitude,
      description: getUpdateNewParturitionDescription,
    };
    const updateparturitionstr = JSON.stringify(updateparturition);

    MainService.updateParturitionDetails(updateparturitionstr)
      .then((res) => {
        setUpdateNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Parturition Api's */

  /*  Mortality Api's */

  const mortalityButtonClickHandler = (assetid, assetType) => {
    getMortalityDetailsHandler();
    setNewMortalityAssetid(assetid);
    setAssetBackButtton(assetType);
    

    setMortality(true);

    setOnboarding(false);
  };

  const getMortalityDetailsHandler = () => {
    setIsLoading(true);
    MainService.getMortalityDetails(username)
      .then((res) => {
        setIsLoading(false);
        setMortalityDetails(res.mortality);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addMortalityHandler = () => {
    const addmortality = {
      assetid: getNewMortalityAssetid,
      assetfriendlyname: getNewMortalityAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewMortalityUserid,
      modelid: getNewMortalityModelid,
      breedpercentage: getNewMortalityBreedPercentage,
      gender: getNewMortalityGender,
      birthdate: getNewMortalityBirthDate,
      expireddate: getNewMortalityExpiredDate,
      totalage: getNewMortalityTotalAge,
      currentvalue: getNewMortalityCurrentValue,
      causeofdeath: getNewMortalityCauseOfDeath,
      latitude: getNewMortalityLatitude,
      longitude: getNewMortalityLongitude,
      altitude: getNewMortalityAltitude,
      description: getNewMortalityDescription,
    };
    const addmortalitystr = JSON.stringify(addmortality);

    MainService.addMortalityDetails(addmortalitystr)
      .then((res) => {
        setNewMortalityPopuop(false);
        getMortalityDetailsHandler();
        return NotificationManager.success("mortality details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteMortalityAssetid = (assetid) => {
    setMortalityDeleteAssetid(assetid);
    setMortalityDeletePopup(true);
  };

  const deleteMortalityHandler = () => {
    MainService.deleteMortalityDetails(getMortalityDeleteAssetid)
      .then((res) => {
        setMortalityDeletePopup(false);
        getMortalityDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateMortalityInfo = (mortalityinfo) => {
    setUpdateNewMortalityAssetid(mortalityinfo.assetid);
    setUpdateNewMortalityAssetFriendlyName(mortalityinfo.assetfriendlyname);
    setUpdateNewMortalityUserid(mortalityinfo.userid);
    setUpdateNewMortalityModelid(mortalityinfo.modelid);
    setUpdateNewMortalityBreedPercentage(mortalityinfo.breedpercentage);
    setUpdateNewMortalityGender(mortalityinfo.gender);
    setUpdateNewMortalityBirthDate(mortalityinfo.birthdate);
    setUpdateNewMortalityExpiredDate(mortalityinfo.expireddate);
    setUpdateNewMortalityTotalAge(mortalityinfo.totalage);
    setUpdateNewMortalityCurrentValue(mortalityinfo.currentvalue);
    setUpdateNewMortalityCauseOfDeath(mortalityinfo.causeofdeath);
    setUpdateNewMortalityLatitude(mortalityinfo.latitude);
    setUpdateNewMortalityLongitude(mortalityinfo.longitude);
    setUpdateNewMortalityAltitude(mortalityinfo.altitude);
    setUpdateNewMortalityDescription(mortalityinfo.description);

    setUpdateNewMortalityPopuop(true);
  };

  const updateMortalityHandler = () => {
    const updatemortality = {
      assetid: getUpdateNewMortalityAssetid,
      assetfriendlyname: getUpdateNewMortalityAssetFriendlyName,
      modelid: getUpdateNewMortalityModelid,
      breedpercentage: getUpdateNewMortalityBreedPercentage,
      gender: getUpdateNewMortalityGender,
      birthdate: getUpdateNewMortalityBirthDate,
      expireddate: getUpdateNewMortalityExpiredDate,
      totalage: getUpdateNewMortalityTotalAge,
      currentvalue: getUpdateNewMortalityCurrentValue,
      causeofdeath: getUpdateNewMortalityCauseOfDeath,
      latitude: getUpdateNewMortalityLatitude,
      longitude: getUpdateNewMortalityLongitude,
      altitude: getUpdateNewMortalityAltitude,
      description: getUpdateNewMortalityDescription,
    };
    const updatemortalitystr = JSON.stringify(updatemortality);
    MainService.updateMortalityDetails(updatemortalitystr)
      .then((res) => {
        setUpdateNewMortalityPopuop(false);
        getMortalityDetailsHandler();
        return NotificationManager.success("mortality info updated");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Mortality Api's */

  /*  Asset Operational Status  Api's */

  const assetOperationalStatusButtonClickHandler = (siteid, backType) => {
    getAssetOptionalStatusDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    

    setOperationalStatus(true);
    setOnboarding(false);
  };

  const getAssetOptionalStatusDetailsHandler = () => {
    setIsLoading(true);
    MainService.getAssetOptionalStatusDetails(username)
      .then((res) => {
        setIsLoading(false);
        setOperationalStatusDetails(res.assetOperationalStatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addAssetOperationalHandler = () => {
    const assetoperational = {
      assetid: getNewAssetOperationalAssetid,
      assetfriendlyname: getNewAssetOperationalAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewAssetOperationalUserid,
      status: getNewAssetOperationalStatus,
      latitude: getNewAssetOperationalLatitude,
      longitude: getNewAssetOperationalLongitude,
      altitude: getNewAssetOperationalAltitude,
      description: getNewAssetOperationalDescription,
    };
    const assetoperationalstr = JSON.stringify(assetoperational);

    MainService.addAssetOparationalStatusDetails(assetoperationalstr)
      .then((res) => {
        setNewAssetOperationalPopup(false);
        getAssetOptionalStatusDetailsHandler();
        return NotificationManager.success("asset operational status added");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const deleteAssetOperationalStatusAssetid = (assetid) => {
    setAssetOperationalDeleteAssetid(assetid);
    setAssetOperationalDeletePopup(true);
  };

  const deleteAssetOperationalHandler = () => {
    MainService.deleteAssetOperationalStatusDetails(
      getAssetOperationalDeleteAssetid
    )
      .then((res) => {
        setAssetOperationalDeletePopup(false);
        getAssetOptionalStatusDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAssetOperationalStatusInfo = (assetinfo) => {
    setUpdateNewAssetOperationalAssetid(assetinfo.assetid);
    setUpdateNewAssetOperationalAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewAssetOperationalUserid(assetinfo.userid);
    setUpdateNewAssetOperationalStatus(assetinfo.status);
    setUpdateExistingNewAssetOperationalStatus(assetinfo.status);
    setUpdateNewAssetOperationalLatitude(assetinfo.latitude);
    setUpdateNewAssetOperationalLongitude(assetinfo.longitude);
    setUpdateNewAssetOperationalAltitude(assetinfo.altitude);
    setUpdateNewAssetOperationalDescription(assetinfo.description);

    setUpdateNewAssetOperationalPopup(true);
  };

  const updateAssetOperationalHandler = () => {
    const updateasset = {
      assetid: getUpdateNewAssetOperationalAssetid,
      assetfriendlyname: getUpdateNewAssetOperationalAssetFriendlyName,
      status: getUpdateNewAssetOperationalStatus,
      latitude: getUpdateNewAssetOperationalLatitude,
      longitude: getUpdateNewAssetOperationalLongitude,
      altitude: getUpdateNewAssetOperationalAltitude,
      description: getUpdateNewAssetOperationalDescription,
    };
    const updateassetstr = JSON.stringify(updateasset);

    MainService.updateAssetOperationalStatusDetails(updateassetstr)
      .then((res) => {
        setUpdateNewAssetOperationalPopup(false);
        getAssetOptionalStatusDetailsHandler();
        return NotificationManager.success(
          "asset operational status info updated"
        );
      })
      .catch((err) => {
        return NotificationManager.eror("Something Went Wrong");
      });
  };
  /* End Of Asset Operational Status  Api's */
  /* Selling Cost  Api's */
  const sellingCostButtonClickHandler = (siteid, backType) => {
    sellingCostDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setBackType(backType);
    

    setOnboarding(false);
    setShowSelling(true);
  };

  const sellingCostDetailsHandler = () => {
    setIsLoading(true);
    MainService.getSellingCostDetails(username)
      .then((res) => {
        setIsLoading(false);
        setSellingDetails(res.sellingCost);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addSellingCostHandler = () => {
    const sellingDetails = {
      assetid: getNewSellingCostAssetid,
      assetfriendlyname: getNewSellingCostAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewSellingCostUserid,
      sellingcost: getNewSellingCostSellingCost,
    };
    const data = JSON.stringify(sellingDetails);
    MainService.addSellingCostDetails(data)
      .then((res) => {
        sellingCostDetailsHandler();
        setAddSellingCostPopup(!getAddSellingCostPopup);
        return NotificationManager.success("selling cost added");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Selling  Api's */

  /* Patrolling Api's */
  const sitePatrollingButtonClickHandler = (siteid, backType) => {
    sitePatrollingDetailsHandler();
    viewMappedAssetsDropdown(siteid);
    setNewSitePatrolingSiteid(siteid);
    setBackType(backType);
    

    setOnboarding(false);

    setShowPatrolling(true);
  };

  const sitePatrollingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getSitePatrollingDetails(username)
      .then((res) => {
        setIsLoading(false);
        setViewSitePatrolling(res.patrollings);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addSitePatrolingHandler = () => {
    const sitepatrolingdetails = {
      siteid: getNewSitePatrolingSiteid,
      friendlyname: getNewSitePatrolingFriendlyName,
      parentid: "consumer2",
      userid: getNewSitePatrolingUserid,
      mobilenumber: getNewSitePatrolingMobileNumber,
      latitude: getNewSitePatrolingLatitude,
      longitude: getNewSitePatrolingLongitude,
      altitude: getNewSitePatrolingAltitude,
      description: getNewSitePatrolingDescription,
    };
    const data = JSON.stringify(sitepatrolingdetails);

    MainService.addSitePatrolingDetails(data)
      .then((res) => {
        getShowSitePatrolingPopup(!showSitePatrolingPopup);
        sitePatrollingDetailsHandler();
        return NotificationManager.success("patrolling details added");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const deleteSitePatrolingInfo = (siteid) => {
    setDeleteSiteid(siteid);
    getShowSitePatrolingDeletePopup(!showSitePatrolingDeletePopup);
  };

  const deleteSitePatrolingHandler = () => {
    MainService.deleteSitePatrolingDetails(getDeleteSiteid)
      .then((res) => {
        sitePatrollingDetailsHandler();
        getShowSitePatrolingDeletePopup(!showSitePatrolingDeletePopup);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateSitePatrolingInfo = (sitepatrolinginfo) => {
    setUpdateNewSitePatrolingSiteid(sitepatrolinginfo.siteid);
    setUpdateNewSitePatrolingFriendlyName(sitepatrolinginfo.friendlyname);
    setUpdateNewSitePatrolingMobileNumber(sitepatrolinginfo.mobilenumber);
    setUpdateNewSitePatrolingLatitude(sitepatrolinginfo.latitude);
    setUpdateNewSitePatrolingLongitude(sitepatrolinginfo.longitude);
    setUpdateNewSitePatrolingAltitude(sitepatrolinginfo.altitude);
    setUpdateNewSitePatrolingDescription(sitepatrolinginfo.description);
    getUpdateShowSitePatrolingPopup(!showUpdateSitePatrolingPopup);
  };

  const updateSitePatrolingHandler = () => {
    const updatepatrolinginfo = {
      siteid: getUpdateNewSitePatrolingSiteid,
      friendlyname: getUpdateNewSitePatrolingFriendlyName,
      mobilenumber: getUpdateNewSitePatrolingMobileNumber,
      latitude: getUpdateNewSitePatrolingLatitude,
      longitude: getUpdateNewSitePatrolingLongitude,
      altitude: getUpdateNewSitePatrolingAltitude,
      description: getUpdateNewSitePatrolingDescription,
    };
    const data = JSON.stringify(updatepatrolinginfo);
    MainService.updateSitePatrolingDetails(data)
      .then((res) => {
        sitePatrollingDetailsHandler();
        getUpdateShowSitePatrolingPopup(!showUpdateSitePatrolingPopup);
        return NotificationManager.success("patrolling info updated");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const AssetPatrollingButtonClickHandler = (assetid, assetType) => {
    setNewAssetPatrollingAssetid(assetid);
    setAssetBackButtton(assetType);
    getAssetPatrollingDetailsHandler();

    setOnboarding(false);

    setShowAssetPatrolling(true);
  };

  const getAssetPatrollingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getAssetPatrollingDetails(username)
      .then((res) => {
        setIsLoading(false);
        setViewAssetSitePatrolling(res.patrollings);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const addAssetPatrolingHandler = () => {
    const assetpatrolling = {
      assetid: getNewAssetPatrollingAssetid,
      assetfriendlyname: getNewAssetPatrollingAssetFriendlyName,
      parentid: "consumer2",
      userid: getNewAssetPatrollingUserid,
      mobilenumber: getNewAssetPatrollingMobileNumber,
      latitude: getNewAssetPatrollingLatitude,
      longitude: getNewAssetPatrollingLongitude,
      altitude: getNewAssetPatrollingAltitude,
      description: getNewAssetPatrollingDescription,
    };
    const data = JSON.stringify(assetpatrolling);

    MainService.addAssetPatrolingDetails(data)
      .then((res) => {
        getAssetPatrollingDetailsHandler();
        setShowNewAssetPatrollingPopup(!getShowNewAssetPatrollingPopup);
        return NotificationManager.success("patrolling details added");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const deleteAssetPatrolingInfo = (assetid) => {
    setDeleteAssetid(assetid);
    getShowAssetPatrolingDeletePopup(!showAssetPatrolingDeletePopup);
  };

  const deleteAssetPatrolingHandler = () => {
    MainService.deleteAssetPatrolingDetails(getDeleteAssetid)
      .then((res) => {
        getAssetPatrollingDetailsHandler();
        getShowAssetPatrolingDeletePopup(!showAssetPatrolingDeletePopup);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAssetPatrolingInfo = (assetinfo) => {
    setUpdateNewAssetPatrollingAssetid(assetinfo.assetid);
    setUpdateNewAssetPatrollingAssetFriendlyName(assetinfo.friendlyname);
    setUpdateNewAssetPatrollingMobileNumber(assetinfo.mobilenumber);
    setUpdateNewAssetPatrollingLatitude(assetinfo.latitude);
    setUpdateNewAssetPatrollingLongitude(assetinfo.longitude);
    setUpdateNewAssetPatrollingAltitude(assetinfo.altitude);
    setUpdateNewAssetPatrollingDescription(assetinfo.description);
    setUpdateShowNewAssetPatrollingPopup(!getUpdateShowNewAssetPatrollingPopup);
  };

  const addUpdateAssetPatrolingHandler = () => {
    const updateasset = {
      assetid: getUpdateNewAssetPatrollingAssetid,
      assetfriendlyname: getUpdateNewAssetPatrollingAssetFriendlyName,
      mobilenumber: getUpdateNewAssetPatrollingMobileNumber,
      latitude: getUpdateNewAssetPatrollingLatitude,
      longitude: getUpdateNewAssetPatrollingLongitude,
      altitude: getUpdateNewAssetPatrollingAltitude,
      description: getUpdateNewAssetPatrollingDescription,
    };
    const data = JSON.stringify(updateasset);
    MainService.updateAssetPatrolingDetails(data)
      .then((res) => {
        getAssetPatrollingDetailsHandler();
        setUpdateShowNewAssetPatrollingPopup(
          !getUpdateShowNewAssetPatrollingPopup
        );
        return NotificationManager.success("patrolling info updated");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Patrolling Api's */

  /* Asset Info */
  const assetInfoDialogClick = (assetid) => {
    MainService.getAssetInfo(username, assetid)
      .then((res) => {
        console.log("asset info:", res);
        setSelectedAssetId(res.assetId);
        setSelectedAssetAssetVerticalId(res.assetverticalId);
        setSelectedAssetCategory(res.category);
        setSelectedAssetCategoryType(res.assetCategoryType);
        setSelectedAssetDomain(res.assetDomain);
        setSelectedAssetType(res.assetType);
        setSelectedAssetFriendlyName(res.friendlyName);
        setSelectedAssetManufacturerId(res.manufacturerId);
        setSelectedAssetManufacturer(res.manufacturer);
        setSelectedAssetManufacturerURL(res.manufacturerURL);
        setSelectedAssetModelId(res.modelId);
        setSelectedAssetModelName(res.modelName);
        setSelectedAssetModelType(res.modelType);
        setSelectedAssetModelURL(res.modelURL);
        setSelectedAssetSerialNumber(res.serialNumber);
        setSelectedAssetUdn(res.udn);
        setSelectedAssetUpc(res.upc);
        setSelectedAssetWhiteLabelCost(res.whitelabelcost);
        setSelectedAssetSellingCost(res.sellingcost);
        setSelectedAssetImageData(res.imagedata);
        setSelectedAssetStatus(res.assetstatus);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
     // setAssetInfoPopUp(!assetInfoPopup);
  };
  const closeSitAssetInfoModal = (
    <span
      onClick={() => {
        setAssetInfoPopUp(!assetInfoPopup);
      }}
    >
      X
    </span>
  );
  const assetInfoHeader = "Asset Details";
  const assetInfoBody = (
    <div>
      <p>
        <b>Asset ID:</b>
        {selectedAssetId}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {selectedAssetAssetVerticalId}
      </p>
      <p>
        <b>Category:</b>
        {selectedAssetCategory}
      </p>
      <p>
        <b>Asset CategoryType:</b>
        {selectedAssetCategoryType}
      </p>
      <p>
        <b>AssetDomain:</b>
        {selectedAssetDomain}
      </p>
      <p>
        <b>Asset Type:</b>
        {selectedAssetType}
      </p>
      <p>
        <b>Friendly Name:</b>
        {selectedAssetFriendlyName}
      </p>
      <p>
        <b>Manufacturer Id:</b>
        {selectedAssetManufacturer}
      </p>
      <p>
        <b>Manufacturer:</b>
        {selectedAssetManufacturer}
      </p>
      <p>
        <b>Manufacturer URL:</b>
        {selectedAssetManufacturerURL}
      </p>
      <p>
        <b>ModelId:</b>
        {selectedAssetModelId}
      </p>
      <p>
        <b>Model Name:</b>
        {selectedAssetModelName}
      </p>
      <p>
        <b>Model Type:</b>
        {selectedAssetModelType}
      </p>
      <p>
        <b>Model URL:</b>
        {selectedAssetModelURL}
      </p>
      <p>
        <b>Serial Number:</b>
        {selectedAssetSerialNumber}
      </p>
      <p>
        <b>UDN:</b>
        {selectedAssetUdn}
      </p>
      <p>
        <b>UPC:</b>
        {selectedAssetUpc}
      </p>
      <p>
        <b>Whitelabel Cost:</b>
        {selectedAssetWhiteLabelCost}
      </p>
      <p>
        <b>Selling Cost:</b>
        {selectedAssetSellingCost}
      </p>
      <p>
        <b>Image Data:</b>
        {selectedAssetImageData}
      </p>
      <p>
        <b>Asset Status:</b>
        {selectedAssetStatus}
      </p>
    </div>
  );

  /* End of Asset Info */
  /* Update Legacy Asset */
  const updateLegacyAsset = "Asset Information";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input disabled id="udn" name="udn" type="text" value={udnVal} />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          disabled
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          disabled
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATED ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
          <MenuItem selected>Select Any</MenuItem>
          <MenuItem key="PrematureSale" value="PrematureSale">
            Premature Sale
          </MenuItem>
          <MenuItem key="Lost" value="Lost">
            Lost
          </MenuItem>
          <MenuItem key="Damaged" value="Damaged">
            Damaged
          </MenuItem>
          <MenuItem key="UnderMaintenance" value="UnderMaintenance">
            Under-Maintenance
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetHandler}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset */

  /* Asset Delete Handler */

  const addedAssetDeleteHandler = (
    assetIds,
    assetTypes,
    modelIds,
    assetverticalIds,
    categoryTypes
  ) => {
    if (window.confirm("Are you sure to delete?")) {
      MainService.assetDelete(
        assetIds,
        assetTypes,
        modelIds,
        assetverticalIds,
        categoryTypes,
        username
      )
        .then((res) => {
          // return NotificationManager.success(res);
          return NotificationManager.success("successfully deleted");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };
  /*End Asset Delete Handler */

/*Photo Upload */
const getCategory = () => {
    if(parentId !== "" && selectedCategoryName !== "")
    {
      MainService.getCategory("consumer2", selectedCategoryName)
      .then((data) => {
        if (data) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("error");
      });
    }
    
  };
const createSubCategoryClick = () => {
  setSubCategoryDialog(!subcategorydialog);
};
const deleteSubCategoryClick = () =>{
  setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
}
const submitSubCategoryDeleteClick = ()=>{
  MainService.deleteContentSubCategory(subCategoryNameValue.replace(" ",""))
  .then((res)=>{
    return NotificationManager.success(res);
  })
  .catch((e)=>{
    return NotificationManager.error(e);
  })
  setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
}
const handleClose = () => {
  setSubCategoryDialog(!subcategorydialog);
};
const submitSubCategoryClick = (evt) => {
  if (!subCategoryNameValue) {
    NotificationManager.warning("Please enter Sub-Category Name");
    return;
  }
  let subCategoryData = {
    categoryid: selectedCategoryId,
    categoryname: selectedCategoryName,
    contentsubcategoryname: subCategoryNameValue,
    parentid: "consumer2",
    contentsubcategorycreator: username,
  };
  const subCategoryDataString = JSON.stringify(subCategoryData);
  MainService.createContentSubCategory(subCategoryDataString)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
    getSubCategories();
    setSubCategoryDialog(!subcategorydialog);
  console.log("subCategoryData:", subCategoryDataString);
};

const getSubCategories = () => {
  MainService.getSubCategoriesForAD(username, selectedCategoryId)
    .then((data) => {
      if (data.length > 0) {
        setSelectedSubCategory(data[0].contentsubcategoryname);
        setsubCategoriesList(data);
        setIsLoading(false);
      } else {
        setsubCategoriesList(data);
        setSelectedSubCategory("");
        setIsLoading(false);
      }
    })
    .catch((e) => {
      console.error(e);
      return NotificationManager.error(e);
    });
};
const uploadPhotoClick = () => {
  setShowUploadPhotoPopup(!uploadPhotoPopup);
};
const submitUploadPhotoClick = () => {
if(uploadPhotoFile)
{
  const uploadPhotoData = new FormData();
  uploadPhotoData.append("file", uploadPhotoFile);
  uploadPhotoData.append("mimetype", uploadPhotoFile.type);
  uploadPhotoData.append("categoryid", selectedCategoryId);
  uploadPhotoData.append("categoryname", selectedCategoryName);
  uploadPhotoData.append("contentsubcategory", subCategoryNameValue);
  uploadPhotoData.append("contentname", uploadPhotoNameValue);
  uploadPhotoData.append("assetdeveloperid", parentId);
  uploadPhotoData.append("contentdescription", descriptionValue);
  uploadPhotoData.append("contentcreator", username);
  uploadPhotoData.append("contentformat", "DOC");
  uploadPhotoData.append("price", priceValue);
  uploadPhotoData.append("filedescription", uploadPhotoFileDescription);
  uploadPhotoData.append("contentownerid", username);
  uploadPhotoData.append("unit", "INR");
  uploadPhotoData.append("discountedprice", discountValue);
  uploadPhotoData.append("expirydate", expiryDateValue);
  uploadPhotoData.append("event", "none");
  console.log("create uploadPhoto data:", uploadPhotoData);
  MainService.createContent(uploadPhotoData,parentId)
    .then((res) => {
      return NotificationManager.success("UploadPhoto uploaded");
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
}else{
  return NotificationManager.error("please choose file");
}
  
  setShowUploadPhotoPopup(!uploadPhotoPopup);
};

const closeUploadPhotoModal = (
  <span
    onClick={() => {
      setShowUploadPhotoPopup(!uploadPhotoPopup);
    }}
  >
    X
  </span>
);
const titleUploadPhoto = "Upload Photo";
const uploadPhotoBody = (
  <div>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
      <Input
        disabled
        id="categoryName"
        name="categoryName"
        type="text"
        value={selectedCategoryName.toUpperCase()}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="subCategoryName">Sub-Category Name :</InputLabel>
      <Select
        id="subCategoryName"
        name="subCategoryName"
        type="text"
        value={subCategoryNameValue}
        onChange={(e) => {
          setSubCategoryNameValue(e.target.value);
        }}
      >
        {subCategoriesList &&
          subCategoriesList.map((idObj, index) => (
            <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
              {idObj.contentsubcategoryname}
            </MenuItem>
          ))}
      </Select>
    </FormControl>

    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="uploadPhotoName">UploadPhoto Name :</InputLabel>
      <Input
        id="uploadPhotoName"
        name="uploadPhotoName"
        type="text"
        value={uploadPhotoNameValue}
        onChange={(e) => {
          setUploadPhotoNameValue(e.target.value);
        }}
      />
    </FormControl>
    <FormControl
      variant="standard"
      fullWidth={true}
      className={classes.formControl}
    >
      <InputLabel htmlFor="contentOwner">Content Owner</InputLabel>
      <Input
        disabled
        id="contentOwner"
        name="contentOwner"
        type="text"
        value={username}
      ></Input>
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="contentCreator"> Content Creator:</InputLabel>
      <Input
        disabled
        id="contentCreator"
        name="contentCreator"
        type="text"
        value={username}
      />
    </FormControl>

    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
      <Input
        disabled
        id="contentApprover"
        name="contentApprover"
        type="text"
        value={parentId}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="doc">file:</InputLabel>
      <Input
        id="doc"
        name="doc"
        type="file"
        onChange={(e) => {
          setUploadPhotoFile(e.target.files[0]);
        }}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="description"> Description:</InputLabel>
      <Input
        id="description"
        name="description"
        type="text"
        value={descriptionValue}
        onChange={(e) => {
          setDescriptionValue(e.target.value);
        }}
      />
    </FormControl>
    {/* <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="price"> Price:</InputLabel>
      <Input
        id="price"
        name="price"
        type="text"
        value={priceValue}
        onChange={(e) => {
          setPriceValue(e.target.value);
        }}
      />
    </FormControl>
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="expiryDate"> Expiry Date:</InputLabel>
      <Input
        id="expiryDate"
        name="expiryDate"
        type="date"
        value={expiryDateValue}
        onChange={(e) => {
          setExpiryDateValue(e.target.value);
        }}
      />
    </FormControl> 
    <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="discount"> Discount:</InputLabel>
      <Input
        id="discount"
        name="discount"
        type="text"
        value={discountValue}
        onChange={(e) => {
          setDiscountValue(e.target.value);
        }}
      />
    </FormControl>
    
    {/* <FormControl fullWidth={true} className="loginFields">
      <InputLabel htmlFor="fileDescription">file Description:</InputLabel>
      <Input
        id="fileDescription"
        name="fileDescription"
        type="file"
        onChange={(e) => {
          setUploadPhotoFileDescription(e.target.files[0]);
        }}
      />
    </FormControl> */}
    <br />
    <FormControl>
      <Button
        variant="contained"
        color="primary"
        className="registerBtn"
        type="submit"
        onClick={() => {
          submitUploadPhotoClick();
        }}
      >
        Submit
      </Button>
    </FormControl>
    <FormControl
      fullWidth={true}
      className="forgotPwdLink"
      onClick={() => {
        setShowUploadPhotoPopup(!uploadPhotoPopup);
      }}
      style={{
        marginBottom: "20px",
        textAlign: "center",
        boxOrient: "0",
        display: "block",
        fontSize: "20",
      }}
    >
      Cancel
    </FormControl>
  </div>
);
/* end of photo upload */

  /* Asset Feedback */
  const assetFeedbackClick = (asset) => {
    console.log("assset info:", asset);
    setSelectedAssetId(asset.assetId);
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };
  const assetFeedbackSubmitClick = () => {
    let addFeedback = {
      assetdeveloperid: username,
      assetid: selectedAssetId,
      " how_would_you_rate_your_overall_customer_experience?": customerExp,
      " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
      "how_satisfied_were_you_with_the_product?": productExp,
      "how_satisfied_were_you_with_the_customer_support?": supportExp,
      "would_you_recommend_our_product_or_service_to_others?": othersExp,
      remark: remarks,
    };
    const addFeedbackData = JSON.stringify(addFeedback);
    MainService.addAssetFeedback(username, addFeedbackData)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetFeedbackPopup(!assetFeedbackPopup);
  };

  /* End of Asset Feedback */
  /* Asset Issue */

  const assetIssueClick = (asset) => {

    console.log("Asset dataa:", asset);
    setAssetIssuesGridView(true);
    setAssetsGridView(false);
    setAssetsListView(false);
    setSelectedAssetId(asset.assetId);
    setSelectedSiteId(asset.siteId);
    console.log("asset data:", asset);

    MainService.getAssetIssues(parentId, asset.siteId, asset.assetId)
      .then((res) => {
        console.log("get aset issues:", res);
        setAssetIssuesList(res);
        
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetIssueSubmitClick = () => {
    let issueData = {
      operatorid: username,
      solutionid: parentId,
      siteid: selectedSiteId,
      assetid: selectedAssetId,
      issuedescription: descriptionValue,
    };
    const issueDataStringify = JSON.stringify(issueData);
    MainService.createAssetIssue(username, issueDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      setAssetIssuePopup(!assetIssuePopup);
  };
  const backToHome = () => {
    setAssetIssuesGridView(false);
    setAssetsGridView(true);
  };
  /* End of Asset Issue */

  return (
    <>
      <div className="container" id="mainContentList">
        {assetsHealthGridView?(
          <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create Asset Health Record"
          className={classes.fab}
          onClick={() => {
            setNewHealthRecordPopup(!newHealthRecordPopup);
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        ):(
          <></>
        )}
        {assetIssuesGridView ? (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create Asset Issue"
              className={classes.fab}
              onClick={() => {
                setAssetIssuePopup(!assetIssuePopup);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          ) : (
            <></>
          )}
        {
          (assetsListView || assetsGridView)?(
            <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Asset"
          className={classes.fab}
        >
          <AddIcon onClick={() => assetCreateClcik()}></AddIcon>
        </Fab>
          ):(<></>)
        }
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
              defaultValue=""
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                defaultValue=""
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.categoryType
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.assetType
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.modelId.includes(event.target.value);
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
          <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                createSubCategoryClick();
              }}
            >
              Create Sub-Category
            </Button>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                deleteSubCategoryClick();
              }}
            >
              Delete Sub-Category
            </Button>
          </div>
        </div>
        <div className="MuiGrid-root">
          {/* Asset List */}
          {assetsListView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                    {assetsList.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={21}>
                                  Assets
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetType
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Friendly Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetverticalId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Blocking Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Category Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Gender
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  ModelId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Url
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Selling Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  SiteId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  UDN
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Weight
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Whitelabel Cost
                                </TableCell>
                                <TableCell align="center" colSpan={5}>
                                  View
                                </TableCell>
                                <TableCell align="center" colSpan={15}>
                                  Operate
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetsList.length > 0 ? (
                                assetsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((assets, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {assets.assetId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.friendlyName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetverticalId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.blockingStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.category}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.gender}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelUrl}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.sellingCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.siteId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.udn}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.weight}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.whitelabelCost}
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Asset Info
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Asset Monitoring
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            /* onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            } */
                                          >
                                            Asset Score
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.category
                                              )
                                            }
                                          >
                                            Map
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              unMapAssetHandler(
                                                assets.assetId,
                                                assets.category,
                                                assets.siteId
                                              )
                                            }
                                          >
                                            UnMap
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              updateLegacyAssetHandler(
                                                assets.assetverticalId,
                                                assets.assetType,
                                                assets.assetId,
                                                assets.friendlyName,
                                                assets.modelId,
                                                assets.category,
                                                assets.udn,
                                                assets.whitelabelCost,
                                                assets.sellingCost,
                                                assets.assetStatus
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              addedAssetDeleteHandler(
                                                assets.assetId,
                                                assets.assetType,
                                                assets.modelId,
                                                assets.assetverticalId,
                                                assets.category
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              attendanceLocationButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Asset Attendance
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              birthInfoButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Birth
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              nutritionButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Nutrition
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              mortalityButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Mortality
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              parturitionButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Parturition
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              healthRecordButtonClickHandler(
                                                assets.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            Health Record
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() => {
                                              assetFeedbackClick(assets);
                                            }}
                                          >
                                            Asset Feedback
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                          >
                                            Photo Upload
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() => {
                                              assetIssueClick(assets);
                                            }}
                                          >
                                            Asset Issue
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center" />
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Assets not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Assets not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}

          {assetsGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Assets
                  </h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            <p>
                              <b>Blocking Status:</b>
                              {assets.blockingStatus}
                            </p>
                            <p>
                              <b>Category Type:</b>
                              {assets.category}
                            </p>
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>
                            <p>
                              <b>Model Name:</b>
                              {assets.modelName}
                            </p>
                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>SiteId:</b>
                              {assets.siteId}
                            </p>
                            <p>
                              <b>UDN:</b>
                              {assets.udn}
                            </p>
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <h4 className="cardactiontitle">View</h4>
                            <div>
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetInfoDialogClick(assets.assetId)
                                  }
                                >
                                  Asset Info
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    mapPopUpHandler(
                                      assets.assetId,
                                      assets.categoryType
                                    )
                                  }
                                >
                                  Asset Monitoring
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                 
                                >
                                  Asset Score
                                </Button>
                              </div>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">Operate</h4>
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    mapPopUpHandler(
                                      assets.assetId,
                                      assets.category
                                    )
                                  }
                                >
                                  Map
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    unMapAssetHandler(
                                      assets.assetId,
                                      assets.category,
                                      assets.siteId
                                    )
                                  }
                                >
                                  UnMap
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateLegacyAssetHandler(
                                      assets.assetverticalId,
                                      assets.assetType,
                                      assets.assetId,
                                      assets.friendlyName,
                                      assets.modelId,
                                      assets.category,
                                      assets.udn,
                                      assets.whitelabelCost,
                                      assets.sellingCost,
                                      assets.assetStatus
                                    )
                                  }
                                >
                                  Update
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    addedAssetDeleteHandler(
                                      assets.assetId,
                                      assets.assetType,
                                      assets.modelId,
                                      assets.assetverticalId,
                                      assets.category
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    attendanceLocationButtonClickHandler(
                                      assets.assetId,
                                      "List"
                                    )
                                  }
                                >
                                  Asset Attendance
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    birthInfoButtonClickHandler(
                                      assets.assetId,
                                      "List"
                                    )
                                  }
                                >
                                  Birth
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    nutritionButtonClickHandler(
                                      assets.assetId,
                                      "List"
                                    )
                                  }
                                >
                                  Nutrition
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    mortalityButtonClickHandler(
                                      assets.assetId,
                                      "List"
                                    )
                                  }
                                >
                                  Mortality
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    parturitionButtonClickHandler(
                                      assets.assetId,
                                      "List"
                                    )
                                  }
                                >
                                  Parturition
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    healthRecordButtonClickHandler(
                                      assets.assetId,
                                      "Grid"
                                    )
                                  }
                                >
                                  Health Record
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    assetFeedbackClick(assets);
                                  }}
                                >
                                  Asset Feedback
                                </Button>
                                &nbsp;&nbsp;
                                <Button size="small" variant="contained"
                                onClick={()=>{uploadPhotoClick()}}>
                                  Photo Upload
                                </Button>
                                &nbsp;&nbsp;
                                <Button size="small" variant="contained"
                                onClick={() => {
                                  assetIssueClick(assets);
                                }}>
                                  Asset Issue
                                </Button>
                              </div>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Assets Found.</h4>
                  )}
                </div>
              )}
            </Box>
          ) : null}

          {/* Asset Issues */}
          {assetIssuesGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of Asset Issues</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className="cardStyleForDashboardItems">
                {assetIssuesList.length>0 ? (
                  <div className="cardStyleForDashboardItems">
                    {assetIssuesList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>AssetDeveloper Id:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>Parent Id:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Issues Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* Asset Health Records */}
          {assetsHealthGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of Asset Health Records</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <div className="cardStyleForDashboardItems">
                {healthRecordsList.length>0 ? (
                  <div className="cardStyleForDashboardItems">
                    {healthRecordsList.map((asset, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                        <p>
                            <b>Parent Id:</b>
                            {asset.parentid}
                          </p>
                          <p>
                            <b>User Id:</b>
                            {asset.userid}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {asset.assetid}
                          </p>
                          <p>
                            <b>Asset Friendlyname:</b>
                            {asset.assetfriendlyname}
                          </p>
                          
                          <p>
                            <b>Description:</b>
                            {asset.description}
                          </p>
                          <p>
                            <b>Diseasetype:</b>
                            {asset.diseasetype}
                          </p>
                          <p>
                            <b>Examine:</b>
                            {asset.examine}
                          </p>
                          <p>
                            <b>Longitude:</b>
                            {asset.longitude}
                          </p>
                          <p>
                            <b>Latitude:</b>
                            {asset.latitude}
                          </p>                          
                          <p>
                            <b>Altitude:</b>
                            {asset.altitude}
                          </p>
                          <p>
                            <b>Medicine Date:</b>
                            {asset.medicinedate}
                          </p>
                          <p>
                            <b>Medicine Type:</b>
                            {asset.medicinetype}
                          </p>
                          
                          <p>
                            <b>Description:</b>
                            {asset.description}
                          </p>
                          <p>
                            <b>Surgical Procedure:</b>
                            {asset.surgicalprocedure}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {asset.timestamp}
                          </p>
                          <p>
                            <b>Treatment Date:</b>
                            {asset.treatmentdate}
                          </p>
                          
                          <p>
                            <b>Vaccination Date:</b>
                            {asset.vaccinationdate}
                          </p>
                          <p>
                            <b>Vaccination Type:</b>
                            {asset.vaccinationtype}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Asset Health Records Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
        </div>
      </div>
      {/* Attendance Location */}
      <Dialog
            fullScreen={fullScreen}
            open={attendancePopup}
            onClose={() => {
              setAttendancePopup(!attendancePopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Add Attendance Location"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetId">Asset Id:</InputLabel>
                <Input
                  id="assetId"
                  name="assetId"
                  type="text"
                  value={attendanceLocationSelectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="operatorId">
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id="operatorId"
                  name="operatorId"
                  type="text"
                  value={attendanceLocationSelectedAssetName}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Parent Id:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">User Id:</InputLabel>
                <Input id="siteId" name="siteId" type="text" value={attendanceLocationUsername} />
              </FormControl>

              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude:</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={attendanceLocationLatitudeValue}
                  onChange={(e) => {
                    setAttendanceLocationLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude:</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={attendanceLocationLongitudeValue}
                  onChange={(e) => {
                    setAttendanceLocationLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude:</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={attendanceLocationAltitudeValue}
                  onChange={(e) => {
                    setAttendanceLocationAltitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="describe">Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={attendanceLocationDescriptionValue}
                  onChange={(e) => {
                    setAttendanceLocationDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  assetAttendanceSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setAttendancePopup(!attendancePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
      {/* Asset Health */}
      <Dialog
            fullScreen={fullScreen}
            open={newHealthRecordPopup}
            onClose={() => {
              setNewHealthRecordPopup(!newHealthRecordPopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Add Asset Health"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetId">Asset Id:</InputLabel>
                <Input
                  disabled
                  id="assetId"
                  name="assetId"
                  type="text"
                  value={getNewHealthAssetid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetFriendlyName">
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  disabled
                  id="assetFriendlyName"
                  name="assetFriendlyName"
                  type="text"
                  value={getNewHealthAssetid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetDeveloperId">
                  AssetDeveloper Id:
                </InputLabel>
                <Input
                  id="assetDeveloperId"
                  name="assetDeveloperId"
                  type="text"
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="medicineDate">Medicine Date:</InputLabel>
                <Input
                  id="medicineDate"
                  name="medicineDate"
                  type="date"
                  value={getNewHealthMedicineDate}
                  onChange={(e) => {
                    setNewHealthMedicineDate(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="vaccinationDate">
                  Vaccination Date:
                </InputLabel>
                <Input
                  id="vaccinationDate"
                  name="vaccinationDate"
                  type="date"
                  value={getNewHealthVaccinationDate}
                  onChange={(e) => {
                    setNewHealthVaccinationDate(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="treatmentDate">Treatment Date:</InputLabel>
                <Input
                  id="treatmentDate"
                  name="treatmentDate"
                  type="date"
                  value={getNewHealthTreatmentDate}
                  onChange={(e) => {
                    setNewHealthTreatmentDate(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="examine">Examine:</InputLabel>
                <Input
                  id="examine"
                  name="examine"
                  type="text"
                  value={getNewHealthExamine}
                  onChange={(e) => {
                    setNewHealthExamine(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="surgicalProcedure">
                  Surgical Procedure:
                </InputLabel>
                <Input
                  id="surgicalProcedure"
                  name="surgicalProcedure"
                  type="text"
                  value={getNewHealthSurgicalProcedure}
                  onChange={(e) => {
                    setNewHealthSurgicalProcedure(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="vaccinationType">
                  Vaccination Type:
                </InputLabel>
                <Input
                  id="vaccinationType"
                  name="vaccinationType"
                  type="text"
                  value={getNewHealthVaccinationType}
                  onChange={(e) => {
                    setNewHealthVaccinationType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="medicineType">Medicine Type:</InputLabel>
                <Input
                  id="medicineType"
                  name="medicineType"
                  type="text"
                  value={getNewHealthMedicineType}
                  onChange={(e) => {
                    setNewHealthMedicineType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="diseaseType">Disease Type:</InputLabel>
                <Input
                  id="diseaseType"
                  name="diseaseType"
                  type="text"
                  value={getNewHealthDiseaseType}
                  onChange={(e) => {
                    setNewHealthDiseaseType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude:</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getNewHealthLatitude}
                  onChange={(e) => {
                    setNewHealthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude:</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getNewHealthLongitude}
                  onChange={(e) => {
                    setNewHealthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude:</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getNewHealthAltitude}
                  onChange={(e) => {
                    setNewHealthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="describe">Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewHealthDescription}
                  onChange={(e) => {
                    setNewHealthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  assetHealthSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setNewHealthRecordPopup(!newHealthRecordPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
      {/* create sub-category */}
      <Dialog
          fullScreen={fullScreen}
          open={subcategorydialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Create Content Sub-Category"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetdeveloperid">
                {" "}
                Assetdeveloper Id:
              </InputLabel>
              <Input
                disabled
                id="assetdeveloperid"
                name="assetdeveloperid"
                type="text"
                value={username}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel htmlFor="category">Category</InputLabel>
              <Input
                disabled
                id="category"
                name="category"
                type="text"
                value={selectedCategoryId}
              ></Input>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subCategoryName">
                Sub-Category Name :
              </InputLabel>
              <Input
                id="subCategoryName"
                name="subCategoryName"
                type="text"
                value={subCategoryNameValue}
                onChange={(e) => {
                  setSubCategoryNameValue(e.target.value);
                }}
              ></Input>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={(e) => {
                submitSubCategoryClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setSubCategoryDialog(!subcategorydialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* delete sub-category */}
        <Dialog
          fullScreen={fullScreen}
          open={deleteSubcategoryDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Delete Content Sub-Category"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetdeveloperid">
                {" "}
                Assetdeveloper Id:
              </InputLabel>
              <Input
                disabled
                id="assetdeveloperid"
                name="assetdeveloperid"
                type="text"
                value={username}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel htmlFor="category">Category</InputLabel>
              <Input
                disabled
                id="category"
                name="category"
                type="text"
                value={selectedCategoryId}
              ></Input>
            </FormControl>
            <FormControl fullWidth={true} >
              <InputLabel
                
              >
                choose Sub-Category
              </InputLabel>
              <Select
                id="selectedSubCategory"
                value={subCategoryNameValue}
                onChange={(e) => {
                  setSubCategoryNameValue(e.target.value);
                }}
                label="Select Sub-Category"
              >
                {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>           
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={(e) => {
                submitSubCategoryDeleteClick();
              }}
            >
              Delete
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      {/* Asset Issue */}
      <Dialog
            fullScreen={fullScreen}
            open={assetIssuePopup}
            onClose={() => {
              setAssetIssuePopup(!assetIssuePopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Add Asset Issue"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetId">Parent Id:</InputLabel>
                <Input
                  id="assetId"
                  name="assetId"
                  type="text"
                  value={parentId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="operatorId">User Id:</InputLabel>
                <Input
                  id="operatorId"
                  name="operatorId"
                  type="text"
                  value={username}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Site Id:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={selectedSiteId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Asset Id:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={selectedAssetId}
                />
              </FormControl>

              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="describe">Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={descriptionValue}
                  onChange={(e) => {
                    setDescriptionValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  assetIssueSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setAssetIssuePopup(!assetIssuePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
           {/* Asset Feedback */}
           <Dialog
            fullScreen={fullScreen}
            open={assetFeedbackPopup}
            onClose={() => {
              setAssetFeedbackPopup(!assetFeedbackPopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Asset Feedback"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetId">Asset Id:</InputLabel>
                <Input
                  id="assetId"
                  name="assetId"
                  type="text"
                  value={selectedAssetId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="operatorId">
                  Asset Friendly Name:
                </InputLabel>
                <Input
                  id="operatorId"
                  name="operatorId"
                  type="text"
                  value={selectedAssetId}
                />
              </FormControl>
              <Typography variant="body2" component="span">
                how would you rate your overall experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="customer-feedback"
                    value={customerRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextCustomer}
                    onChange={(event, customerRate) => {
                      setCustomerRate(customerRate);
                      setcustomerExp(labels[customerRate]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setCustomerRateHover(customerRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {customerRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          customerRateHover !== -1
                            ? customerRateHover
                            : customerRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your product experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="product-feedback"
                    value={productRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextProduct}
                    onChange={(event, productRate) => {
                      setProductRate(productRate);
                      setProductExp(labels[productRate]);
                    }}
                    onChangeActive={(event, productRateHover) => {
                      setProductRateHover(productRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {productRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          productRateHover !== -1
                            ? productRateHover
                            : productRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your delivery experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="delivery-feedback"
                    value={value}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, value) => {
                      setValue(value);
                      setDeliveryExp(labels[value]);
                    }}
                    onChangeActive={(event, customerRateHover) => {
                      setHover(hover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your customer Support: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="support-feedback"
                    value={supportRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextSupport}
                    onChange={(event, supportRate) => {
                      setSupportRate(supportRate);
                      setSupportExp(labels[supportRate]);
                    }}
                    onChangeActive={(event, supportRateHover) => {
                      setSupportRateHover(supportRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {supportRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          supportRateHover !== -1
                            ? supportRateHover
                            : supportRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <Typography variant="body2" component="span">
                how would you rate your other experience: <br />
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="others-feedback"
                    value={othersRate}
                    defaultValue={0}
                    precision={0.5}
                    getLabelText={getLabelTextOthers}
                    onChange={(event, othersRate) => {
                      setOthersRate(othersRate);
                      setOthersExp(labels[othersRate]);
                    }}
                    onChangeActive={(event, othersRateHover) => {
                      setOthersRateHover(othersRateHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {othersRate !== null && (
                    <Box sx={{ ml: 2 }}>
                      {
                        labels[
                          othersRateHover !== -1 ? othersRateHover : othersRate
                        ]
                      }
                    </Box>
                  )}
                </Box>
              </Typography>
              <br />
              <Typography variant="body2" component="span">
                Remarks: <br />
                <TextareaAutosize
                  rowsMin={5}
                  aria-label="minimum height"
                  placeholder="Any remarks"
                  style={{ minWidth: "260px" }}
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  assetFeedbackSubmitClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setAssetFeedbackPopup(!assetFeedbackPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <CustomPopup
          closeButton={closeUploadPhotoModal}
          isOpen={uploadPhotoPopup}
          closeModal={uploadPhotoPopup}
          content={uploadPhotoBody}
          title={titleUploadPhoto}
        />
      <CustomPopup
        closeButton={closeSitAssetInfoModal}
        isOpen={assetInfoPopup}
        closeModal={assetInfoPopup}
        content={assetInfoBody}
        title={assetInfoHeader}
      />
      <CustomPopup
        closeButton={closeAgreementModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={agreementBody}
        title={titleAgreement}
      />
      <CustomPopup
        closeButton={closeMapModal}
        isOpen={mapPopUp}
        closeModal={mapPopUp}
        content={mapModelBody}
        title={mapAgreement}
      />
      <CustomPopup
        closeButton={closeUpdateModel}
        isOpen={legacyUpdate}
        closeModal={legacyUpdate}
        content={updateLegacyAssetData}
        title={updateLegacyAsset}
      />
    </>
  );
}
