import React, { useEffect, useState } from 'react';
import MatTable from '../../../../../Containers/Table/Table';
import SearchBar from '../../../../../Containers/SearchBar/SearchBar';
import MainService from '../../../Common/MainService';

import {
  Box,
  Button,
  Fab,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import AddIcon from '@material-ui/icons/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: 'white',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  color: 'black',
  p: 4,
};

const fields = [
  '',
  'Trip Id',
  'Driver ID',
  'Vehicle ID',
  'Route Id',
  'Trip State',
  'Start Time',
];

const TripManagement = ({ tripHandler1 }) => {
  const [data, setData] = useState([]);
  const [fromdate, setFromdate] = useState('');
  const [todate, setTodate] = useState('');
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(10);
  const [tripDetails, setTripDetails] = useState({ tripList: [] });
  const [searchBy, setSearchBy] = useState('');
  const [tripCurrentStatus, setTripCurrentStatus] = useState('');
  const [selected_items, setSelected_items] = useState('');
  const [tripStatus, setTripStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [confirm_delete, setConfirm_delete] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);

  const {
    user: { parentId, username },
  } = JSON.parse(atob(localStorage.getItem('CBN')));

  useEffect(() => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 1);
    var firstMonth = firstDay.getMonth() + 1;
    var lastMonth = lastDay.getMonth() + 1;
    setFromdate(
      firstDay.getDate() + '-' + firstMonth + '-' + firstDay.getFullYear()
    );
    setTodate(
      lastDay.getDate() + '-' + lastMonth + '-' + lastDay.getFullYear()
    );
    getTripInfo();
  }, []);

  const setDate = (val, type) => {
    const date = val.split('-').reverse().join('-');
    if (type === 'start') {
      setFromdate(date);
    } else {
      setTodate(date);
    }
  };

  const getSearchOption = (val) => {
    setSearchBy(val);
  };

  const getTripInfo = async () => {
    let urls = 'fromDate=01-01-2022&toDate=29-12-2090&start=0&limit=100';
    const { tripCount, tripList } = await MainService.getTripInfo(
      parentId,
      username,
      urls
    );
    setTripDetails({ tripCount, tripList });
  };

  const deleteItems = (items) => {
    setConfirm_delete(true);
  };

  const onSelect = (items) => {
    if (selected_items === items) {
      setSelected_items('');
    } else {
      setSelected_items(items);
    }
  };

  const getSearchResult = (searchValue) => {};

  const onClose = (val) => {
    if (val) {
    } else {
      this.setState({ confirm_delete: false });
      setConfirm_delete(false);
    }
  };

  const modifyTripStatus = () => {
    setTripStatus(true);
  };

  const closeTripModal = () => {
    setTripStatus(false);
  };

  const setCurrentStatus = (evt) => {
    setTripCurrentStatus(evt.target.value);
  };

  const loadMoreTrip = () => {
    setVisible(visible + 10);
  };

  const delTripHandler = async () => {
    let selecteditems = selected_items;
    const deleteTrip = await MainService.delTrip(
      parentId,
      username,
      selecteditems
    );

    if (deleteTrip) {
      findItem();
    }
  };

  const findItem = () => {
    const items = tripDetails?.tripList;
    const filterdata = items.filter((item) => item.tripId !== selected_items);
    setTripDetails({ tripList: filterdata });
    setOpen(false);
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === 'delete') {
      setOpen(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <SearchBar
          placeholder={'Search trip'}
          // onSubmit={this.getSearchLocation.bind(this)}
        />
        <Box>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='outlined'
            disabled={selected_items === ''}
          >
            ACTION
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <MatTable
          fields={fields}
          rows={tripDetails.tripList.slice(0, visible)}
          total={tripDetails.tripCount}
          keys={[
            'tripId',
            'driverId',
            'vehicleId',
            'routeId',
            'status',
            'startTime',
            'startDate',
          ]}
          unique='tripId'
          onSelect={onSelect}
          selected={selected_items}
          change_page={getTripInfo}
        />
      </Box>
      <Box
        sx={{
          marginTop: '1rem',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <Button
          onClick={loadMoreTrip}
          sx={{
            color: '#fff',
            background: '#1976d2',
            ':hover': {
              bgcolor: '#167add',
              color: '#fff',
            },
          }}
        >
          Load More{' '}
        </Button>
        <Fab
          color='primary'
          size={'large'}
          aria-label='add'
          cursor='pointer'
          title='Add Location'
          variant='outlined'
          onClick={() => tripHandler1()}
          // className={classes.myStyle}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Modal
        open={open}
        // onClose={this.setState({ open: false })}
        onClose={closeModelHandler}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />

          <Box sx={{ marginTop: '0.5rem', display: 'flex', gap: 1 }}>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={delTripHandler}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TripManagement;
