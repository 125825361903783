import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./AdvisoryComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import DeleteContentSubCategory from "../../Common/CommonContent/DeleteContentSubCategory";
import CreateContentSubCategory from "../../Common/CommonContent/CreateContentSubCategory";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function AdvisoryComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [advisoryPopup, setShowAdvisoryPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] =
    useState("ABN-ADVISORY");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [advisoryList, setAdvisoryList] = useState([]);
  const [filteredAdvisoryList, setFilteredAdvisoryList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [advisoryNameValue, setAdvisoryNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [advisoryFile, setAdvisoryFile] = useState(null);
  const [advisoryFileDescription, setAdvisoryFileDescription] = useState(null);
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* create advisory */
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [selectedVerticalsName, setSelectedVerticalsName] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [getAssetModel, setAssetModel] = useState([]);

  /* delete content */
  const [selectedContentId, setSelectedContentId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAssetVerticalsIdList();
  }, []);
  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    if (!selectedAssertTypes) return;
    getModelList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          setVerticalsIdList(data);
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalsName(data[0].assetVerticalFriendlyName);
        } else {
          setIsLoading(false);
          setVerticalsIdList(data);
          setSelectedVerticalsId("");
          setSelectedVerticalsName("");
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setCategoryTypesList(data);
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
            const newKeyValues = data.map((keyChnage) => ({
              assetmodel: keyChnage.assetType,
            }));
            setAssetModel(newKeyValues);
          } else {
            setAssertTypesList(data);
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };
  const getModelList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
            const newKeyValues = data.map((keyChnage) => ({
              assetmodel: keyChnage.modelid,
            }));
            setAssetModel((prevState) =>
              [...prevState, newKeyValues].flatMap((val) => val)
            );
          } else {
            setSelectedModel("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        if (data.length > 0) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSubCategory(data[0].contentsubcategoryname);
          setsubCategoriesList(data);
          setIsLoading(false);
        } else {
          setsubCategoriesList(data);
          setSelectedSubCategory("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getCategoryTender(parentId, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setIsLoading(false);
          setAdvisoryList(data);
          setFilteredAdvisoryList(data);
        } else {
          setAdvisoryList(data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!parentId) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };

  /*Delete Sub-Category */
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  /* EOD Delete Sub-Category */

  const createAdvisoryClick = () => {
    setShowAdvisoryPopup(!advisoryPopup);
  };
  const submitAdvisoryClick = () => {
    const advisoryData = new FormData();
    advisoryData.append("file", advisoryFile);
    advisoryData.append("mimetype", advisoryFile.type);
    advisoryData.append("categoryid", selectedCategoryId);
    advisoryData.append("categoryname", selectedCategoryName);
    advisoryData.append("contentsubcategory", subCategoryNameValue);
    advisoryData.append("contentname", advisoryNameValue);
    advisoryData.append("assetdeveloperid", parentId);
    advisoryData.append("contentdescription", descriptionValue);
    advisoryData.append("contentcreator", username);
    advisoryData.append("contentformat", "DOC");
    advisoryData.append("price", priceValue);
    advisoryData.append("filedescription", advisoryFileDescription);
    advisoryData.append("contentownerid", selectedModel);
    advisoryData.append("unit", "INR");
    advisoryData.append("discountedprice", discountValue);
    advisoryData.append("expirydate", expiryDateValue);
    advisoryData.append("event", "none");

    MainService.createContent(advisoryData, parentId)
      .then((res) => {
        getAllContentsList();
        return NotificationManager.success("Advisory uploaded");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setShowAdvisoryPopup(!advisoryPopup);
  };
  /* Delete Content */
  const deleteContentDialogClick = (advisory) => {
    if (advisory.contentcreatorid === username) {
      setSelectedContentId(advisory.contentid);
      setDeleteContentCreator(advisory.contentcreatorid);
      setDeleteContentCategory(advisory.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other creator content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */
  const viewAllAdvisoryClick = () => {
    getAllContentsList();
    setSubCategoryNameValue("");
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  /*Create Advisory */
  const closeAdvisoryModal = (
    <span
      onClick={() => {
        setShowAdvisoryPopup(!advisoryPopup);
      }}
    >
      X
    </span>
  );
  const titleAdvisory = "Create Advisory";
  const advisoryBody = (
    <Box sx={{ m: "10px" }}>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="subCategoryName">Sub-Category Name :</InputLabel>
        <Select
          id="subCategoryName"
          name="subCategoryName"
          type="text"
          value={subCategoryNameValue}
          onChange={(e) => {
            setSubCategoryNameValue(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {subCategoriesList &&
            subCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="advisoryName">Advisory Name :</InputLabel>
        <Input
          id="advisoryName"
          name="advisoryName"
          type="text"
          value={advisoryNameValue}
          onChange={(e) => {
            setAdvisoryNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth className="loginFields">
        <InputLabel htmlFor="selectedVerticalsId">Asset Vertical</InputLabel>
        <Select
          id="selectedVerticalsId"
          value={selectedVerticalsId}
          onChange={(event) => {
            setSelectedVerticalsId(event.target.value);
          }}
          label="Select Verticals"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {verticalsIdList.length !== 0 ? (
            verticalsIdList &&
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <p>Asset Verticals Not Found</p>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth className="loginFields">
        <InputLabel htmlFor="selectedCategory">Category</InputLabel>
        <Select
          id="selectedCategory"
          value={selectedCategory}
          onChange={(event) => {
            setSelectedCategory(event.target.value);
          }}
          label="Select Category"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {categoryTypesList.length !== 0 ? (
            categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))
          ) : (
            <p>Categories Not Found</p>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth className="loginFields">
        <InputLabel htmlFor="selectedAssertTypes">Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => {
            setSelectedAssertTypes(event.target.value);
          }}
          label="Select Type"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {assertTypesList.length !== 0 ? (
            assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))
          ) : (
            <p>Asset Types Not Found</p>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth className="loginFields">
        <InputLabel htmlFor="selectedModel">Content Owner / Model</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => {
            setSelectedModel(event.target.value);
          }}
          label="Select Model"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {getAssetModel.length !== 0 ? (
            getAssetModel &&
            getAssetModel.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetmodel}>
                {idObj.assetmodel}
              </MenuItem>
            ))
          ) : (
            <p>Owners Not Found</p>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentUploader"> Content Uploader:</InputLabel>
        <Input
          disabled
          id="contentUploader"
          name="contentUploader"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setAdvisoryFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <Box>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={() => {
            submitAdvisoryClick();
          }}
        >
          Submit
        </Button>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="error"
          onClick={() => {
            setShowAdvisoryPopup(!advisoryPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create Advisory"
          className={classes.fab}
          onClick={() => {
            createAdvisoryClick();
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              viewAllAdvisoryClick();
            }}
          >
            View All Advisory
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              createSubCategoryClick();
            }}
          >
            Create Sub-Category
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryClick();
            }}
          >
            Delete Sub-Category
          </Button>
          <FormControl variant="outlined" size="small" className="postdropdown">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Filter by Sub-Category
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
                const filteredRows = filteredAdvisoryList.filter((row) => {
                  return row.contentsubcategory
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                });
                setAdvisoryList(filteredRows);
              }}
              label="Select Sub-Category"
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          <h2>Advisory Details</h2>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Assetdeveloperid
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Uploader
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Organization
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Sub Category Name
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Description
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Download Document
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advisoryList.length > 0 ? (
                      advisoryList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((advisory, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {advisory.parentid}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.contentid}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.categoryname}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.categoryid}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.contentcreatorid}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.contentowner}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.contentsubcategory}
                              </TableCell>
                              <TableCell align="center">
                                {advisory.contentdescription}
                              </TableCell>
                              <TableCell align="center">
                                <a
                                  className="downloadDocument"
                                  href={advisory.locationurl}
                                  download
                                >
                                  Download Document
                                </a>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    deleteContentDialogClick(advisory);
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Advisory Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={advisoryList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  {advisoryList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {advisoryList.map((advisory, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Assetdeveloperid:</b>
                              {advisory.parentid}
                            </p>
                            <p>
                              <b>Content Id:</b>
                              {advisory.contentid}
                            </p>
                          </span>
                          <p>
                            <b>Category Id:</b>
                            {advisory.categoryid}
                          </p>
                          <p>
                            <b>Content Uploader:</b>
                            {advisory.contentcreatorid}
                          </p>
                          <p>
                            <b>Category:</b>
                            {advisory.categoryname}
                          </p>
                          <p>
                            <b>Organization:</b>
                            {advisory.contentowner}
                          </p>
                          <p>
                            <b>Sub Categoryname:</b>
                            {advisory.contentsubcategory}
                          </p>
                          <p>
                            <b>Description:</b>
                            {advisory.contentdescription}
                          </p>
                          <a
                            className="downloadDocument"
                            href={advisory.locationurl}
                            download
                          >
                            Download Document
                          </a>
                          <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                deleteContentDialogClick(advisory);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Box>
                      <h4>Advisory not found.</h4>
                    </Box>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <CreateContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          onOpen={subcategorydialog}
          onClose={createSubCategoryClick}
        />

        <DeleteContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          subCategoryList={subCategoriesList}
          onOpen={deleteSubcategoryDialog}
          onClose={deleteSubCategoryClick}
        />

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedContentId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <CustomPopup
          closeButton={closeAdvisoryModal}
          isOpen={advisoryPopup}
          closeModal={advisoryPopup}
          content={advisoryBody}
          title={titleAdvisory}
        />
      </div>
    </>
  );
}
