import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';

const InvoiceTable = ({ columnData, rowDataList }) => {
  const supplyList = useMemo(() => {
    return rowDataList || [];
  }, [rowDataList]);

  const renderTableRow = (rowData, index) => {

    // const openPdfHandler = (viewLink) => {
    //   if (viewLink) {
    //     const url = URL.createObjectURL(viewLink);
    //     window.open(url, '_blank');
    //   }
    // };

    const openPdfHandler = (url) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bill_PO.pdf');
  
        // Open the new tab
        const newTab = window.open('', '_blank');
        if (newTab) {
          newTab.document.body.appendChild(link);
          // Trigger the download
          link.click();
        } else {
          // Handle case where new tab couldn't be opened
          console.error('Failed to open new tab');
        }
      }, 100);
    };

    return (
      <ListItem
        key={index}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '0.7rem',
          mb: '0.7rem',
          borderBottom: '1px solid gray',
        }}
      >
        {/* Render item details */}
        {columnData.map((column, columnIndex) => {
          if (column.type === 'button') {
            return (
              <Typography
                sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}
                key={columnIndex}
              >
                <Button onClick={() => openPdfHandler(rowData[column.id])}>
                  View
                </Button>
              </Typography>
            );
          } else {
            return (
              <Typography key={columnIndex} sx={{ flex: '1', display: 'flex' }}>
                {rowData[column.id]}
              </Typography>
            );
          }
        })}
      </ListItem>
    );
  };

  return (
    <Box>
      <Stack spacing={2}>
        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {columnData.map((li, index) => (
            <Typography
              key={index}
              sx={{
                flex: '1',
                display: 'flex',
                justifyContent:
                  index === columnData.length - 1 ? 'flex-end' : 'flex-start',
                fontWeight: '700',
              }}
            >
              {li.label}
            </Typography>
          ))}
        </ListItem>

        <Divider />
      </Stack>

      <Stack>{supplyList.map(renderTableRow)}</Stack>
    </Box>
  );
};

export default InvoiceTable;
