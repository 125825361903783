import MainService from "../MainService";

export const getCategoryID = (categoryName) => {
  return new Promise((resolve, reject) => {
    MainService.getCategory("consumer2", categoryName)
      .then((data) => {
        return resolve(data);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};