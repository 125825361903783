import React, { useState, useEffect } from "react";
import "./CommunityComponent.css";
import { Button } from "@mui/material";
import ViewFarmersComponent from "../ViewFarmers/ViewFarmersComponent";
import ViewSHGComponent from "../SHG/SHGComponent";
import ViewFPOComponent from "../FPO/FPOComponent";
import ViewCSComponent from "../CS/CSComponent";
import ViewNGOComponent from "../NGO/NGOComponent";
import ViewTraderComponent from "../Trader/TraderComponent";
import ViewFFComponent from "../FF/FFComponent";
import ViewSiteOperatorComponent from "../SiteOperator/SiteOperatorComponent";
import ViewGBRStoreComponent from "../GBRStore/GBRStoreComponent";
import ViewTrainerComponent from "../Trainer/TrainerComponent";
import ViewSupplyShopComponent from "../SupplyShop/SupplyShopComponent";
import ViewInputRetailerComponent from "../InputRetailer/InputRetailer";
import CBNConsumerIAMComponent from "../CBNConsumerIAM/CBNConsumerIAMComponent";
import RoleManagementComponent from "../RoleManagement/RoleManagement";
import ViewTransportUserComponent from "../TransportUser/TransportUserComponent";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ViewTransportExecutiveComponent from "../TransportExecutive/TransportExecutiveComponent";
import ViewTransportManagerComponent from "../TransportManager/TransportManagerComponent";
import SuperRoleManagementComponent from "../SuperRoleManagement/SuperRoleManagement";

export default function CommunityComponent(props) {
  const [viewFarmers, setViewFarmers] = useState(true);
  const [viewShg, setViewShg] = useState(false);
  const [viewFpo, setViewFpo] = useState(false);
  const [viewCs, setViewCs] = useState(false);
  const [viewNgo, setViewNgo] = useState(false);
  const [viewTrader, setViewTrader] = useState(false);
  const [viewFf, setViewFf] = useState(false);
  const [viewSiteOperator, setViewSiteOperator] = useState(false);
  const [viewGbrStore, setViewGbrStore] = useState(false);
  const [viewTrainer, setViewTrainer] = useState(false);
  const [viewSupplyShop, setViewSupplyShop] = useState(false);
  const [viewInputRetailer, setViewInputRetailer] = useState(false);
  const [viewCBNConsumerIAM, setViewCBNConsumerIAM] = useState(false);
  const [viewRoleManagement, setViewRoleManagement] = useState(false);
  const [viewSuperRoleManagement, setViewSuperRoleManagement] = useState(false);
  const [viewTransportUser, setViewTransportUser] = useState(false);
  const [viewTransportExecutive, setViewTransportExecutive] = useState(false);
  const [viewTransportManager, setViewTransportManager] = useState(false);
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");

  useEffect(() => {
    getUserType();
  }, [userTypeValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const viewFarmersClick = () => {
    setViewFarmers(true);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };

  const viewSHGClick = () => {
    setViewFarmers(false);
    setViewShg(true);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewFPOClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(true);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewCSClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(true);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewNGOClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(true);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewTraderClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(true);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewFFClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(true);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewSiteOperatorClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(true);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewGbrStoreClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(true);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewTrainerClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(true);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewSupplyShopClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(true);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewInputRetailerClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(true);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewCBNConsumerIAMClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(true);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewRoleManagementClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(true);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };

  const viewTransportUserClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(true);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewTransportExecutiveClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(true);
    setViewTransportManager(false);
    setViewSuperRoleManagement(false);
  };
  const viewTransportManagerClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(true);
    setViewSuperRoleManagement(false);
  };
  const viewSuperRoleManagementClick = () => {
    setViewFarmers(false);
    setViewShg(false);
    setViewFpo(false);
    setViewCs(false);
    setViewNgo(false);
    setViewTrader(false);
    setViewFf(false);
    setViewSiteOperator(false);
    setViewGbrStore(false);
    setViewTrainer(false);
    setViewSupplyShop(false);
    setViewInputRetailer(false);
    setViewCBNConsumerIAM(false);
    setViewRoleManagement(false);
    setViewTransportUser(false);
    setViewTransportExecutive(false);
    setViewTransportManager(false);
    setViewSuperRoleManagement(true);
  };
  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewCBNConsumerIAMClick();
            }}
          >
            IAM Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleManagementClick();
            }}
          >
            Role Profile Management
          </Button>
          
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleManagementClick();
            }}
          >
            Role Management
          </Button>
          
        </div>
      </div>
      <div className="container" id="mainContentList">
        <h2>CBN Users</h2>
        {userTypeValue === "AssetDeveloper" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFarmersClick();
                  }}
                >
                  Farmers
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewSHGClick();
                  }}
                >
                  SHG
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFPOClick();
                  }}
                >
                  FPO
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewCSClick();
                  }}
                >
                  CS
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewNGOClick();
                  }}
                >
                  NGO
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTraderClick();
                  }}
                >
                  Trader
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFFClick();
                  }}
                >
                  FF
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewSiteOperatorClick();
                  }}
                >
                  SiteOperator
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewGbrStoreClick();
                  }}
                >
                  GBRStore
                </Button>
              </div>
            </div>
            &nbsp;
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTrainerClick();
                  }}
                >
                  Trainer
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewSupplyShopClick();
                  }}
                >
                  SupplyShop
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewInputRetailerClick();
                  }}
                >
                  InputRetailer
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportUserClick();
                  }}
                >
                  TransportUser
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportExecutiveClick();
                  }}
                >
                  TransportExecutive
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportManagerClick();
                  }}
                >
                  TransportManager
                </Button>
              </div>
            </div>
          </div>
        )}
        {userTypeValue === "FOOD-PROCESSOR" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFarmersClick();
                  }}
                >
                  Farmers
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewSHGClick();
                  }}
                >
                  SHG
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFPOClick();
                  }}
                >
                  FPO
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewCSClick();
                  }}
                >
                  CS
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewNGOClick();
                  }}
                >
                  NGO
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTraderClick();
                  }}
                >
                  Trader
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFFClick();
                  }}
                >
                  FF
                </Button>
              </div>
              
            </div>
          </div>
        )}
        {userTypeValue === "BROKER" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewFarmersClick();
                  }}
                >
                  Farmers
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTraderClick();
                  }}
                >
                  Trader
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewSiteOperatorClick();
                  }}
                >
                  SiteOperator
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportUserClick();
                  }}
                >
                  TransportUser
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportExecutiveClick();
                  }}
                >
                  TransportExecutive
                </Button>
              </div>
              &nbsp;
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportManagerClick();
                  }}
                >
                  TransportManager
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ paddingTop: "20px" }}>
        {viewCBNConsumerIAM && <CBNConsumerIAMComponent />}
        {viewRoleManagement && <RoleManagementComponent />}
        {viewFarmers && <ViewFarmersComponent />}
        {viewShg && <ViewSHGComponent />}
        {viewFpo && <ViewFPOComponent />}
        {viewCs && <ViewCSComponent />}
        {viewNgo && <ViewNGOComponent />}
        {viewTrader && <ViewTraderComponent />}
        {viewFf && <ViewFFComponent />}
        {viewSiteOperator && <ViewSiteOperatorComponent />}
        {viewGbrStore && <ViewGBRStoreComponent />}
        {viewTrainer && <ViewTrainerComponent />}
        {viewSupplyShop && <ViewSupplyShopComponent />}
        {viewInputRetailer && <ViewInputRetailerComponent />}
        {viewTransportUser && <ViewTransportUserComponent />}
        {viewTransportExecutive && <ViewTransportExecutiveComponent/>}
        {viewTransportManager && <ViewTransportManagerComponent/>}
        {viewSuperRoleManagement && <SuperRoleManagementComponent/>}
      </div>
    </>
  );
}
