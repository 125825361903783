import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

const Map = ({ getLocationHandler, routeLocationData }) => {
  const mapRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const [position, setPosition] = useState({ lat: '', lng: '' });
  const [locationData, setLocationData] = useState([]);
  const [liveLocation, setLiveLocation] = useState(routeLocationData);
  const onLoad = useCallback(
    (ref) => {
      setSearchBox(ref);
    },
    [locationData]
  );

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPlacesChanged = useCallback(() => {
    setPosition({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });

    getLocationHandler(searchBox.getPlaces());
  }, [searchBox]);

  const getBounds = (props) => {
    
    if (props?.geoLocation === undefined) {
      return;
    }
    const bounds = mapRef.current.getBounds();
    const position = new window.google.maps.LatLng(
      props?.geoLocation?.lat,
      props?.geoLocation?.lng
    );
    console.log("conatains props:",mapRef.current.getBounds());
    if (!bounds.contains(position)) {
      mapRef.current.panTo(position);
    }
  };

  useEffect(() => {
    setLiveLocation(routeLocationData);
    getBounds(routeLocationData[0]);
  }, [routeLocationData]);

  return (
    // <LoadScript
    //   googleMapsApiKey="AIzaSyBUU6cda6X52Et-RXcOQIJbFtykuHKwgs0"
    //   libraries={["places"]}
    // >
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={position.lat ? position : center}
      center={center}
      zoom={10}
      onLoad={mapLoad}
    >
      {/* <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="Search Location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox> */}
      {routeLocationData?.map((li) => console.log('345345', li.geoLocation))}
      {routeLocationData?.map((li, index) => (
        //console.log("li",li)
        <Marker key={li?.geoLocation?.lat + index} position={li?.geoLocation} />
        //<Marker key={li?.lat} position={{lat:parseFloat(li?.lat),lng:parseFloat(li?.lng)}} />
      ))}
    </GoogleMap>
    // </LoadScript>
  );
};

export default React.memo(Map);
