import React, { useState, useEffect, memo, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';
import MainService from '../../Common/MainService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: '#fff',
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

function DeleteSubCategoryOfContent({
  subCategories,
  modelShow,
  closeModel,
  reloadComponent,
  createSubCategoryModel,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { user } = MainService.getLocalData();
  const { username } = user;

  /* Delete Sub-Category */
  const deleteSubCategoryHandler = (subCatid) => {
    MainService.deleteContentSubCategory(username, subCatid)
      .then((res) => {
        reloadComponent();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* EOD Delete Sub-Category */

  return (
    <Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          <h2 className='cardactiontitle'>** LIST OF SUB-CATEGORIES **</h2>
        </DialogTitle>
        <DialogActions>
          <Button variant='contained' onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
        <DialogContent>
          <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
            <div className='cardStyleForDashboardItems'>
              {subCategories.length > 0 ? (
                <div className='cardStyleForDashboardItems'>
                  {subCategories &&
                    subCategories.map((subCat, index) => (
                      <div
                        key={index}
                        className='cardStyleForDashboardItemsBody'
                      >
                        <span>
                          <p>
                            <b>Category ID:</b>
                            {subCat.categoryid}
                          </p>
                          <p>
                            <b>Category:</b>
                            {subCat.categoryname}
                          </p>
                          <p>
                            <b>Subcategory ID:</b>
                            {subCat.contentsubcategoryid}
                          </p>
                          <p>
                            <b>Subcategory:</b>
                            {subCat.contentsubcategoryname}
                          </p>
                        </span>
                        <div style={{ margin: '5px', width: '100%' }}>
                          <Button
                            style={{ margin: '5px', width: '50%' }}
                            type='button'
                            variant='contained'
                            onClick={() => {
                              deleteSubCategoryHandler(
                                subCat.contentsubcategoryid
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <h4>SUB-CATEGORIES NOT FOUND.</h4>
              )}
            </div>
          </Box>
        </DialogContent>
        <div className='container' id='mainContentList'>
          <Fab
            color='primary'
            size={'large'}
            aria-label='add'
            title='Create Product'
            className={classes.fab}
          >
            <AddIcon onClick={createSubCategoryModel}></AddIcon>
          </Fab>
        </div>
      </Dialog>
    </Fragment>
  );
}

export default memo(DeleteSubCategoryOfContent);
