import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./ViewAssetOEMComponent.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import { Card, CardContent } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function ViewAssetOEMComponent(props) {
  const classes = useStyles();
  const [isLoading,setIsLoading] = useState(false);
  const [assetOEMsList, setAssetOEMsList] = useState([]);
  const [assetOEMInfoList, setAssetOEMInfoList] = useState([]);
  const [assetOEMUserId, setAssetOEMUserId] = useState("");
  const [assetOEMUserName, setAssetOEMUserName] = useState("");
  const [assetOEMAddress, setAssetOEMAddress] = useState("");
  const [assetOEMCity, setAssetOEMCity] = useState("");
  const [assetOEMFirstName, setAssetOEMFirstName] = useState("");
  const [assetOEMLastName, setAssetOEMLastName] = useState("");
  const [assetOEMPhone, setAssetOEMPhone] = useState("");
  const [assetOEMEnterpriceId, setAssetOEMEnterpriceId] = useState("");
  const [assetOEMUserType, setAssetOEMUserType] = useState("");
  const [assetOEMEmail, setAssetOEMEmail] = useState("");
  const [assetOEMDomain, setAssetOEMDomain] = useState("");
  const [assetOEMState, setAssetOEMState] = useState("");
  const [assetOEMNominee, setAssetOEMNominee] = useState("");
  const [assetOEMPancard, setAssetOEMPancard] = useState("");
  const [assetOEMLongitude, setAssetOEMLongitude] = useState("");
  const [assetOEMLatitude, setAssetOEMLatitude] = useState("");
  const [assetOEMMessage, setAssetOEMMessage] = useState("");
  const [assetOEMStatus, setAssetOEMStatus] = useState("");
  const [whiteLabelRequestsList, setWhiteLabelRequestsList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [assetOEMInfoDialog, setAssetOEMInfoDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailValue, setEmailValue] = React.useState("");
  const [messageValue, setMessageValue] = React.useState("");
  const [assetOEMMessageDialog, setAssetOEMMessageDialog] =
    React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [assetOEMComplaintsDialog, setAssetOEMComplaintsDialog] =
    React.useState(false);
  const [assetOEMComplaintsList, setAssetOEMComplaintsList] = React.useState(
    []
  );
  const [selectedAssetOEM, setSelectedAssetOEM] = React.useState("");
  const [whiteLabelUpdateDialog, setWhiteLabelUpdateDialog] =
    React.useState(false);
  const [selectedAssetOEMId, steSelectedAssetOEMId] = React.useState("");
  const [selectedWhiteLabelCost, setSelectedWhiteLabelCost] =
    React.useState("");
  const [selectedWhiteLabelStatus, setSelectedWhiteLabelStatus] =
    React.useState("");
  const [selectedModelId, setSelectedModelId] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetDeveloperId, setSelectedAssetDeveloperId] =
    React.useState("");
  const [currentdWhiteLabelStatus, setCurrentWhiteLabelStatus] =
    React.useState("");
  const [usernameValue, setUsernameValue] = React.useState("");
  const [firstNameValue, setFirstNameValue] = React.useState("");
  const [lastNameValue, setLastNameValue] = React.useState("");
  const [userTypeValue, setUserTypeValue] = React.useState("");
  const [phoneNumerValue, setPhoneNumerValue] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [createAssetOEMDialog, setCreateAssetOEMDialog] = React.useState(false);
  const [filteredAssetOEMsList, setFilteredAssetOEMsList] = useState([]);
  const [filteredWhiteLabelRequests, setFilteredWhiteLabelRequests] = useState(
    []
  );
  const [searched, setSearched] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);

  /* Feedback  start*/
  const [overallRate, setOverallRate] = React.useState(0);
  const [overallRateHover, setOverallRateHover] = React.useState(-1);
  const [deliveryRate, setDeliveryRate] = React.useState(0);
  const [deliveryRateHover, setDeliveryRateHover] = React.useState(-1);
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = React.useState(1);
  const [hover, setHover] = React.useState(-1);
  const [overallExp, setOverallExp] = useState("");
  const [customerExp, setCustomerExp] = useState("");
  const [deliveryExp, setDeliveryExp] = useState("");
  const [productExp, setProductExp] = useState("");
  const [supportExp, setSupportExp] = useState("");
  const [othersExp, setOthersExp] = useState("");
  const [executiveFeedbackDialog, setExecutiveFeedbackDialog] = useState(false);
  const [selectedExecutiveUserId, setSelectedExecutiveUserId] = useState("");
  const [selectedExecutiveName,setSelectedExecutiveName] = useState("");

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  function getLabelText(overallRate) {
    return `${overallRate} Star${overallRate !== 1 ? "s" : ""}, ${labels[overallRate]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? "s" : ""}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? "s" : ""}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? "s" : ""}, ${
      labels[othersRate]
    }`;
  }

  /* Feedback end */

  useEffect(() => {
    getAssetOEMSofConsumerAdmin();
    getAllWhiteLabelRequests();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All AssetOEMs */
  const getAssetOEMSofConsumerAdmin = () => {
    MainService.getAssetOEMSofConsumerAdmin()
      .then((res) => {
        setAssetOEMsList(res);
        setFilteredAssetOEMsList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End All AssetOEMs */
  /* view all whitelabel requests */
  const getAllWhiteLabelRequests = () => {
    MainService.getAllWhiteLabelRequests(username)
      .then((res) => {
        setWhiteLabelRequestsList(res);
        setFilteredWhiteLabelRequests(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* end of view all whitelabel requests */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* assetOEM info Dialog */
  const assetOEMInfoDialogClick = (assetOEM) => {
    MainService.getAssetOEMInfo(assetOEM)
      .then((res) => {
        setAssetOEMInfoList(res);
        setAssetOEMUserId(res[0].userid);
        setAssetOEMUserName(res[0].username);
        setAssetOEMFirstName(res[0].firstname);
        setAssetOEMLastName(res[0].lastname);
        setAssetOEMEnterpriceId(res[0].enterpriseid);
        setAssetOEMUserType(res[0].usertype);
        setAssetOEMEmail(res[0].email);
        setAssetOEMDomain(res[0].domain);
        setAssetOEMPhone(res[0].phone);
        setAssetOEMAddress(res[0].address);
        setAssetOEMCity(res[0].city);
        setAssetOEMState(res[0].state);
        setAssetOEMNominee(res[0].nominee);
        setAssetOEMPancard(res[0].pancard);
        setAssetOEMLongitude(res[0].longitude);
        setAssetOEMLatitude(res[0].latitude);
        setAssetOEMMessage(res[0].message);
        setAssetOEMStatus(res[0].status);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetOEMInfoDialog(!assetOEMInfoDialog);
  };
  const assetOEMMessageDialogClick = () => {
    setAssetOEMMessageDialog(!assetOEMMessageDialog);
  };
  const sendMessageClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setAssetOEMMessageDialog(!assetOEMMessageDialog);
  };

  const assetOEMComplaintsDialogClick = (assetOEM) => {
    setSelectedAssetOEM(assetOEM);
    MainService.getComplaints(assetOEM)
      .then((res) => {
        console.log("complaints:", res);
        setAssetOEMComplaintsList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetOEMComplaintsDialog(!assetOEMComplaintsDialog);
  };

  const whiteLabelUpdateDialogClick = (
    assetdeveloperid,
    assetId,
    modelid,
    prevStatus,
    cost,
    assetOEMId
  ) => {
    steSelectedAssetOEMId(assetOEMId);
    setSelectedWhiteLabelCost(cost);
    setSelectedWhiteLabelStatus(prevStatus);
    setSelectedModelId(modelid);
    setSelectedAssetId(assetId);
    setSelectedAssetDeveloperId(assetdeveloperid);

    setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
  };

  const whiteLabelUpdateClick = () => {
    if (!currentdWhiteLabelStatus) {
      NotificationManager.warning("Please Selct Current WhiteLabel Status");
      return;
    }
    MainService.updateWhiteLabelStatus(
      username,
      selectedAssetDeveloperId,
      selectedAssetId,
      selectedModelId,
      currentdWhiteLabelStatus
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
  };

  const createAssetOEMSubmit = () => {
    let assetOEMData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      password: password,
      usertype: userTypeValue,
      email: email,
      phone: phoneNumerValue,
    };
    const assetOEMDataString = JSON.stringify(assetOEMData);
    console.log("assetOEMData:", assetOEMData);
    MainService.createAssetOEM(assetOEMDataString)
      .then((res) => {
        return NotificationManager.success(res);
        getAssetOEMSofConsumerAdmin();
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCreateAssetOEMDialog(!createAssetOEMDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredAssetOEMsList.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetOEMsList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const requestSearchForWhiteLabelRequests = (searchedVal) => {
    const filteredRows = filteredWhiteLabelRequests.filter((row) => {
      return row.assetoemid.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setWhiteLabelRequestsList(filteredRows);
  };
  const cancelSearchForWhiteLabelRequests = () => {
    setSearched("");
    requestSearchForWhiteLabelRequests(searched);
  };
  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  /*Start of Feedback */

const feedbackDialogClick = (selectedUserId, selectedUserName) => {
  setSelectedExecutiveUserId(selectedUserId);
  setSelectedExecutiveName(selectedUserName);
  setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};
const executiveFeedbackClick = () => {
  let addFeedback = {
    executiveid: selectedExecutiveName,
    //  recorder: username,
    " how_would_you_rate_your_overall_customer_experience?": customerExp,
    " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
    "how_satisfied_were_you_with_the_product?": productExp,
    "how_satisfied_were_you_with_the_customer_support?": supportExp,
    "would_you_recommend_our_product_or_service_to_others?": othersExp,
    remark: remarks,
  };
  const addFeedbackData = JSON.stringify(addFeedback);
  MainService.assetDeveloperFeedback(addFeedbackData, username)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
    setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};

/* end of Feedback */


  return (
    <>
      <div className="container" id="mainContentList">
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="AssetOEM Options"
            >
              <Tab label="Agri-Investors/OEMs " {...a11yProps(0)} />

              <Tab label="Whitelabel Requests" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create AssetOEM"
              className={classes.fab}
              onClick={() => {
                setCreateAssetOEMDialog(!createAssetOEMDialog);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
            <Grid >
             <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listViewClick}
              ></Button>
              <Button              
                startIcon={<GridIcon />}
                size="large"
                onClick={gridViewClick}
              ></Button>
              </div>
             
            </Grid>
            <br/>
            <Grid container spacing={1}>
            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of AssetOEMs
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          View
                        </TableCell>
                        
                        <TableCell align="center" colSpan={2}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assetOEMsList.length > 0 ? (
                        assetOEMsList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((assetOEMS, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {assetOEMS.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {assetOEMS.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      assetOEMInfoDialogClick(
                                        assetOEMS.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      assetOEMComplaintsDialogClick(
                                        assetOEMS.username
                                      );
                                    }}
                                  >
                                    View Complaints
                                  </Button>
                                </TableCell>
                               
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      assetOEMMessageDialogClick();
                                    }}
                                  >
                                    Send Messages
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    feedbackDialogClick(
                                      assetOEMS.userid,
                                      assetOEMS.username
                                    );
                                  }}
                                >
                                  Feedback
                                </Button>
                              </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            AssetOEMs Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={assetOEMsList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {assetOEMsList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {assetOEMsList.map((assetOEM, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>User Id:</b>
                                {assetOEM.userid}
                              </p>
                            </span>
                            <p>
                              <b>User Name:</b>
                              {assetOEM.username}
                            </p>

                            <div>
                            <h4 className="cardactiontitle">View</h4>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  assetOEMInfoDialogClick(assetOEM.username);
                                }}
                              >
                                View Info
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  assetOEMComplaintsDialogClick(
                                    assetOEM.username
                                  );
                                }}
                              >
                                View Complaints
                              </Button>
                            </div>
                            <div>
                            <h4 className="cardactiontitle">Actions</h4>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  assetOEMMessageDialogClick();
                                }}
                              >
                                Send Messages
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    feedbackDialogClick(
                                      assetOEM.userid,
                                      assetOEM.username
                                    );
                                  }}
                                >
                                  Feedback
                                </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>AssetOEM not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
          <Grid >
             <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listViewClick}
              ></Button>
              <Button              
                startIcon={<GridIcon />}
                size="large"
                onClick={gridViewClick}
              ></Button>
              </div>
             
            </Grid>
            <br/>
            <Grid container spacing={1}>
            {listView &&
            <Paper sx={{ width: "100%" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) =>
                requestSearchForWhiteLabelRequests(searchVal)
              }
              onCancelSearch={() => cancelSearchForWhiteLabelRequests()}
            />
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={12}>
                      All Whitelabel Requests
                    </TableCell>
                  </TableRow>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={1}>
                      AssetOEM Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Solution Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Legacy Asset Type
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Model Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Asset Id
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Service Package
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Whitelabel Request Status
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {whiteLabelRequestsList.length > 0 ? (
                    whiteLabelRequestsList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((whitelabelrequests, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="center">
                              {whitelabelrequests.assetoemid}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.assetdeveloperid}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.legacyassettype}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.modelid}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.assetid}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.servicepackage}
                            </TableCell>
                            <TableCell align="center">
                              {whitelabelrequests.status}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  whiteLabelUpdateDialogClick(
                                    whitelabelrequests.assetdeveloperid,
                                    whitelabelrequests.assetid,
                                    whitelabelrequests.modelid,
                                    whitelabelrequests.status,
                                    whitelabelrequests.whitelabelcost,
                                    whitelabelrequests.assetoemid
                                  );
                                }}
                              >
                                Update Request
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        Whitelabel Requests Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={whiteLabelRequestsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
            }
            {gridView &&
             <div className="cardStyleForDashboardItems">
             {isLoading ? (
               <div className="cardStyleForDashboardItems">
                 <CircularProgress />
               </div>
             ) : (
               <div className="cardStyleForDashboardItems">
                 {whiteLabelRequestsList.length > 0 ? (
                   <div className="cardStyleForDashboardItems">
                     {whiteLabelRequestsList.map((whitelabelrequests, index) => (
                       <div
                         key={index}
                         className="cardStyleForDashboardItemsBody"
                       >
                         <span>
                           <p>
                             <b>AssetOEM Id:</b>
                             {whitelabelrequests.assetoemid}
                           </p>
                         </span>
                         <p>
                           <b>Solution Id:</b>
                           {whitelabelrequests.assetdeveloperid}
                         </p>
                         <p>
                          <b> Legacy Asset Type</b>
                          {whitelabelrequests.legacyassettype}
                         </p>
                         <p>
                          <b> Model Id</b>
                          {whitelabelrequests.modelid}
                         </p>
                         <p>
                          <b> Asset Id</b>
                          {whitelabelrequests.assetId}
                         </p>
                         <p>
                          <b> Service Package</b>
                          {whitelabelrequests.servicepackage}
                         </p>
                         <p>
                          <b> Whitelabel Request Status</b>
                          {whitelabelrequests.status}
                         </p>

                         <div>
                         <h4 className="cardactiontitle">Actions</h4>
                         <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  whiteLabelUpdateDialogClick(
                                    whitelabelrequests.assetdeveloperid,
                                    whitelabelrequests.assetid,
                                    whitelabelrequests.modelid,
                                    whitelabelrequests.status,
                                    whitelabelrequests.whitelabelcost,
                                    whitelabelrequests.assetoemid
                                  );
                                }}
                              >
                                Update Request
                              </Button>
                         </div>
                       </div>
                     ))}
                   </div>
                 ) : (
                   <h4>WhiteLabel Requests not found.</h4>
                 )}
               </div>
             )}
           </div>
            }
            </Grid>
          </TabPanel>
        </Box>
        <Dialog
          fullScreen={fullScreen}
          open={assetOEMComplaintsDialog}
          onClose={handleClose}
          aria-labelledby="assetOemComplaints"
        >
          <DialogTitle id="assetOemComplaints">
            List of {selectedAssetOEM} Complaints
          </DialogTitle>
          <DialogContent>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Operator Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Solution Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Site Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Complaint Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assetOEMComplaintsList.length > 0 ? (
                      assetOEMComplaintsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((assetOEMComplaints, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {assetOEMComplaints.operatorid}
                              </TableCell>
                              <TableCell align="center">
                                {assetOEMComplaints.solutionid}
                              </TableCell>
                              <TableCell align="center">
                                {assetOEMComplaints.siteid}
                              </TableCell>
                              <TableCell align="center">
                                {assetOEMComplaints.complaintdescription}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Complaints Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={assetOEMComplaintsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetOEMComplaintsDialog(!assetOEMComplaintsDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={assetOEMMessageDialog}
          onClose={handleClose}
          aria-labelledby="sendMessage"
        >
          <DialogTitle id="sendMessage">{"Message"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subject">Subject:</InputLabel>
              <Input
                id="subject"
                name="subject"
                type="text"
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendMessageClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetOEMMessageDialog(!assetOEMMessageDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={assetOEMInfoDialog}
          onClose={handleClose}
          aria-labelledby="viewInfoDialog"
        >
          <DialogTitle id="viewInfoDialog">{"View AssetOEM Info"}</DialogTitle>
          <DialogContent>
            <p>
              <b>User Id:</b>
              {assetOEMUserId}
            </p>
            <p>
              <b>User Name:</b>
              {assetOEMUserName}
            </p>
            <p>
              <b>First Name:</b>
              {assetOEMFirstName}
            </p>
            <p>
              <b>Last Name:</b>
              {assetOEMUserName}
            </p>
            <p>
              <b>Enterprise Id:</b>
              {assetOEMEnterpriceId}
            </p>
            <p>
              <b>UserType:</b>
              {assetOEMUserType}
            </p>
            <p>
              <b>Email:</b>
              {assetOEMEmail}
            </p>
            <p>
              <b>Domain:</b>
              {assetOEMDomain}
            </p>
            <p>
              <b>Phone:</b>
              {assetOEMPhone}
            </p>
            <p>
              <b>Address:</b>
              {assetOEMAddress}
            </p>
            <p>
              <b>City:</b>
              {assetOEMCity}
            </p>
            <p>
              <b>State:</b>
              {assetOEMState}
            </p>
            <p>
              <b>Nominee:</b>
              {assetOEMNominee}
            </p>
            <p>
              <b>Pancard:</b>
              {assetOEMPancard}
            </p>
            <p>
              <b>Longitude:</b>
              {assetOEMLongitude}
            </p>
            <p>
              <b>Latitude:</b>
              {assetOEMLatitude}
            </p>
            <p>
              <b>Message:</b>
              {assetOEMMessage}
            </p>
            <p>
              <b>Status:</b>
              {assetOEMStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetOEMInfoDialog(!assetOEMInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={whiteLabelUpdateDialog}
          onClose={handleClose}
          aria-labelledby="whiteLabelUpdateDialog"
        >
          <DialogTitle id="whiteLabelUpdateDialog">
            {"Update Whitelabel Request"}
          </DialogTitle>
          <DialogContent>
            <p>
              <b>AssetOEM ID:</b>
              {selectedAssetOEMId}
            </p>
            <p>
              <b>Solution Id:</b>
              {selectedAssetDeveloperId}
            </p>
            <p>
              <b>Model Id:</b>
              {selectedModelId}
            </p>
            <p>
              <b>Asset Id:</b>
              {selectedAssetId}
            </p>
            <p>
              <b>Whitelabel Cost:</b>
              {selectedWhiteLabelCost}
            </p>
            <p>
              <b>Existing Whitelabel Status:</b>
              {selectedWhiteLabelStatus}
            </p>
            <FormControl fullWidth>
              <InputLabel id="currentWhitelabelStatus:">
                Current Whitelabel Status:
              </InputLabel>
              <Select
                id="currentWhitelabelStatus"
                value={currentdWhiteLabelStatus}
                label="currentWhitelabelStatus"
                onChange={(e) => {
                  setCurrentWhiteLabelStatus(e.target.value);
                }}
              >
                <MenuItem value="Whitelabel-in-progress">
                  Whitelabel-in-progress
                </MenuItem>
                <MenuItem value="Whitelabeled">Whitelabeled</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Lost">Lost</MenuItem>
                <MenuItem value="Damaged">Damaged</MenuItem>
                <MenuItem value="Under-Maintenance">Under-Maintenance</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                whiteLabelUpdateClick();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={createAssetOEMDialog}
          onClose={handleClose}
          aria-labelledby="craete AssetOEM"
        >
          <DialogTitle id="craeteAssetOEM">{"Create AssetOEM"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name :</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="password">Password :</InputLabel>
              <Input
                id="password"
                name="password"
                type="text"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail :</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={userTypeValue}
                onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }}
              >
                <MenuItem selected value="AssetOEM">
                  Asset OEM
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createAssetOEMSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCreateAssetOEMDialog(!createAssetOEMDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Feedback */}
        <Dialog
          fullScreen={fullScreen}
          open={executiveFeedbackDialog}
          onClose={handleClose}
          aria-labelledby="sendFeedback"
        >
          <DialogTitle id="sendFeedback">Send Feedback</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {selectedExecutiveName} Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-feedback"
                      value={overallRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, overallRate) => {
                        setOverallRate(overallRate);
                        setOverallExp(labels[overallRate]);
                      }}
                      onChangeActive={(event, overallRateHover) => {
                        setOverallRateHover(overallRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {overallRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            overallRateHover !== -1
                              ? overallRateHover
                              : overallRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={deliveryRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, deliveryRate) => {
                        setDeliveryRate(deliveryRate);
                        setDeliveryExp(labels[deliveryRate]);
                      }}
                      onChangeActive={(event, deliveryRateHover) => {
                        setDeliveryRateHover(deliveryRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {deliveryRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[deliveryRateHover !== -1 ? hover : deliveryRate]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth: "260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br />
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => executiveFeedbackClick()}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setExecutiveFeedbackDialog(!executiveFeedbackDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
